import {
  COMPANY_LIST,
  ADD_COMPANY,
  UPDATE_COMPANY_ID,
  UPDATE_COMPANY_ACCESS,
  ACCESS_INFO,
  UPDATE_COMPANY_INFO,
  FILE_UPLOAD_STOCK_RESPONSE,
  FILE_UPLOAD_PERCENTAGE,
} from '../actions/scadmin';
function commonReducer(state = {
  company_list: [],
  add_company:[],
  update_company_id:[],
  update_company_access:[],
  access_info:[],
  update_company_info:[],
  file_upload_stock_response:[],
  file_upload_percentage:[]
}, action) {

  switch (action.type) {
    case COMPANY_LIST:
      return Object.assign({}, state, 
      {
        company_list: action.company_list
      })

      case ADD_COMPANY:
        return Object.assign({}, state, 
        {
          add_company: action.add_company
        })

        case UPDATE_COMPANY_ID:
          return Object.assign({}, state, 
          {
            update_company_id: action.update_company_id
          })

          
        case UPDATE_COMPANY_ACCESS:
          return Object.assign({}, state, 
          {
            update_company_access: action.update_company_access
          })

             
        case ACCESS_INFO:
          return Object.assign({}, state, 
          {
            access_info: action.access_info
          })

          case UPDATE_COMPANY_INFO:
            return Object.assign({}, state,
              {
                update_company_info: action.update_company_info
              })


              case FILE_UPLOAD_STOCK_RESPONSE:
                return Object.assign({}, state,
                  {
                    file_upload_stock_response: action.file_upload_stock_response
                  })

                  case FILE_UPLOAD_PERCENTAGE:
                    return Object.assign({}, state,
                      {
                        file_upload_percentage: action.file_upload_percentage
                      })
          
    default:
      return state
  }
}
export default commonReducer