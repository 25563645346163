import React, {Component} from "react";
import {Layout} from "antd";

import Sidebar from "../Sidebar/index";
import Topbar from "../Topbar/index";
import {getVersion} from "./../../actions/index";
import {connect} from 'react-redux';
import App from "routes/index";

const {Content, Footer} = Layout;


export class MainApp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      version:''
    }
  }
  componentDidMount() {
    const { dispatch } = this.props;  
      dispatch(getVersion());
  }
  

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevProps.get_version != this.props.get_version) {  
        if(this.props.get_version.status){
          this.setState({
            version:this.props.get_version.data.version
          })
        }
      }
    } catch (error) {
      
    }
      
  
  }  


  render() {
    const {match} = this.props;

    return (
      <Layout>
        <Sidebar/>

        <Layout>
          <Topbar/>
          <Content>
            <App match={match}/>
            <Footer>
            Copyright Spacecode © 2020, Version-{this.state.version}
            </Footer>
          </Content>
        </Layout>
      </Layout>
    )
  }
}



function mapStateToProps(state) {
  const { Common } = state
  const { get_version:get_version} = Common
  return { get_version
  }
}

const AppContainer = connect(
  mapStateToProps
)(MainApp);


export default AppContainer;
