import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import Resetpassword from "../ResetPassword";
import { setInitUrl } from "appRedux/actions/Auth";
import "./global";
import { LAYOUT_TYPE_BOXED, LAYOUT_TYPE_FRAMED, LAYOUT_TYPE_FULL } from "constants/ThemeSetting";
const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var cookies = require('browser-cookies');
const RestrictedRoute = ({ component: Component, rest, authUser, login_user_data }) =>

    <Route
        {...rest}
        render={props =>
            login_user_data
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: props.location }
                    }}
                />}
    />;


class App extends Component {
    componentWillMount() {
        let user = localStorage.getItem('type_user');
        let webtoken = cookies.get('wteoben');
        let curl = window.location.pathname;
        // console.log(user);
        if (curl != '/resetpassword') {
            if (user == null || user == "") {
                if (curl != '/signin') {
                    window.location.href = '/signin';
                }
            }

            if (webtoken == null || webtoken == "") {
                if (curl != '/signin') {
                    window.location.href = '/signin';
                }
            }
        }


    }

    setLayoutType = (layoutType) => {
        if (layoutType === LAYOUT_TYPE_FULL) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('full-layout');
        } else if (layoutType === LAYOUT_TYPE_BOXED) {
            document.body.classList.remove('full-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('boxed-layout');
        } else if (layoutType === LAYOUT_TYPE_FRAMED) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('full-layout');
            document.body.classList.add('framed-layout');
        }
    };

    render() {
        const { match, location, layoutType, locale, authUser, initURL, login_user_data } = this.props;

        if (location.pathname === '/') {
            if (authUser === null) {
                return (<Redirect to={'/signin'} />);
            } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
                //let locationto = localStorage.getItem('user_id');
                //console.log("locationto it more here"+locationto);
                return (<Redirect to={'/trayActivities'} />);
            } else {
                return (<Redirect to={initURL} />);
            }
        }
        this.setLayoutType(layoutType);

        const currentAppLocale = AppLocale[locale.locale];
        return (
            <LocaleProvider locale={currentAppLocale.antd}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}>

                    <Switch>
                        <Route exact path='/signin' component={SignIn} />
                        <Route exact path='/signup' component={SignUp} />
                        <Route exact path='/resetpassword' component={Resetpassword} />
                        <RestrictedRoute path={`${match.url}`} authUser={authUser}
                            component={MainApp}
                            login_user_data={login_user_data}
                        />
                    </Switch>

                </IntlProvider>
            </LocaleProvider>
        )
    }


}

const mapStateToProps = ({ settings, auth, Common }) => {
    const { locale, layoutType } = settings;
    const { authUser, initURL } = auth;
    const { login_user_data } = Common;
    return { locale, layoutType, authUser, initURL, login_user_data }
};
export default connect(mapStateToProps, { setInitUrl })(App);
