import Axios from 'axios';
const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var cookies = require('browser-cookies');

export const STOCKTALLY_DETAILS='STOCKTALLY_DETAILS'
export const TOP_SALES_PERSON_DETAILS='TOP_SALES_PERSON_DETAILS'
export const TOP_SALES_CATEGORY_DETAILS='TOP_SALES_CATEGORY_DETAILS'
export const TOP_SALES_DESIGN_DETAILS='TOP_SALES_DESIGN_DETAILS'
export const CUSTOMER_CONVERSATION_RATIO='CUSTOMER_CONVERSATION_RATIO'
export const DESIGN_ANALYTICS_DETAILS='DESIGN_ANALYTICS_DETAILS'
export const SALESPERSON_WISE_CONVERSATION_RATIO='SALESPERSON_WISE_CONVERSATION_RATIO'
export const ALL_SALES_PERSONS='ALL_SALES_PERSONS'
export const VIEW_DEMAND='VIEW_DEMAND'
export const TOTAL_DETAILS='TOTAL_DETAILS'

function getloginInfo(){
  let webtoken = cryptr.decrypt(cookies.get('wteoben'));
  let headers = {
    'Content-Type': 'application/json',
    'type': 'Web',
    'x-web-token': webtoken,
  }
  return headers;
}

function getStockTallyreport(page_no,fromDate,toDate,rootInfo,id) {
    // console.log(id);
    // console.log(`stockTally?from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}`);
    return dispatch => {
        return Axios.get(`stockTally?from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}`,
          { headers: getloginInfo()}
          )
            .then(response => {         
                if (response.data !== "") {             
                    dispatch(requestgetStockTallyreportDetails(response.data))                
                } else {
                    dispatch(requestgetStockTallyreportDetails([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestgetStockTallyreportDetails(response) {
    return {
        type: STOCKTALLY_DETAILS,
        stocktally_details: response
    }
  }
  
  export function getStockTally(page_no,fromDate,toDate,rootInfo,id) {
  
    return (dispatch) => {
        return dispatch(getStockTallyreport(page_no,fromDate,toDate,rootInfo,id))
    }
  }

  function getTopSalereport(page_no,fromDate,toDate,rootInfo,id,limit,status) {
    // console.log(id);
    // console.log(`sale/top?type=person&from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}`);
    if(status == undefined){
      status=0
    }else{
      status=1
    }
    return dispatch => {
        return Axios.get(`sale/top?type=person&from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}&limit=${limit}&status=${status}`,
          { headers: getloginInfo()}
          )
            .then(response => {         
                if (response.data !== "") {             
                    dispatch(requestgetTopSaleDetails(response.data))                
                } else {
                    dispatch(requestgetTopSaleDetails([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestgetTopSaleDetails(response) {
    return {
        type: TOP_SALES_PERSON_DETAILS,
        top_sales_person_details: response
    }
  }
  
  export function getTopSalePerson(page_no,fromDate,toDate,rootInfo,id,limit,status) {
  
    return (dispatch) => {
        return dispatch(getTopSalereport(page_no,fromDate,toDate,rootInfo,id,limit,status))
    }
  }
  
  function getTopCategoryWiseSalereport(page_no,fromDate,toDate,rootInfo,id,limit) {
    // console.log(id);
    // console.log(`sale/top?type=category&from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}`);
    return dispatch => {
        return Axios.get(`sale/top?type=category&from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}&limit=${limit}`,
          { headers: getloginInfo()}
          )
            .then(response => {         
                if (response.data !== "") {             
                    dispatch(requestTopCategoryWiseSaleDetails(response.data))                
                } else {
                    dispatch(requestTopCategoryWiseSaleDetails([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestTopCategoryWiseSaleDetails(response) {
    return {
        type: TOP_SALES_CATEGORY_DETAILS,
        top_sales_category_details: response
    }
  }
  
  export function getTopCategoryWiseSale(page_no,fromDate,toDate,rootInfo,id,limit) {
  
    return (dispatch) => {
        return dispatch(getTopCategoryWiseSalereport(page_no,fromDate,toDate,rootInfo,id,limit))
    }
  }

  function getTopDesignSalereport(page_no,fromDate,toDate,rootInfo,id,limit) {
    // console.log(id);
    // console.log(`sale/top?type=code&from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}`);
    return dispatch => {
        let url =`salePerformance/topDesigns?from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}&limit=10`; 
        if(limit===10){
           url =`sale/top?type=code&from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}&limit=10`; 
        }
        return Axios.get(url,
          { headers: getloginInfo()}
          )
            .then(response => {         
                if (response.data !== "") {             
                    dispatch(requestgetTopDesignSaleDetails(response.data))                
                } else {
                    dispatch(requestgetTopDesignSaleDetails([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestgetTopDesignSaleDetails(response) {
    return {
        type: TOP_SALES_DESIGN_DETAILS,
        top_sales_design_details: response
    }
  }
  
  export function getTopDesignSale(page_no,fromDate,toDate,rootInfo,id,limit) {
  
    return (dispatch) => {
        return dispatch(getTopDesignSalereport(page_no,fromDate,toDate,rootInfo,id,limit))
    }
  }

  function getCutomerConversationReport(page_no,fromDate,toDate,rootInfo,id) {
    // console.log(id);
    // console.log(`salePerformance/salesperson?&from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}`);
    return dispatch => {
        return Axios.get(`salePerformance/salesperson?&from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}`,
          { headers: getloginInfo()}
          )
            .then(response => {         
                if (response.data !== "") {             
                    dispatch(requestgetCutomerConversationDetails(response.data))                
                } else {
                    dispatch(requestgetCutomerConversationDetails([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestgetCutomerConversationDetails(response) {
    return {
        type: CUSTOMER_CONVERSATION_RATIO,
        customer_conversation_ratio: response
    }
  }
  
  export function getCutomerConversationRatio(page_no,fromDate,toDate,rootInfo,id) {
  
    return (dispatch) => {
        return dispatch(getCutomerConversationReport(page_no,fromDate,toDate,rootInfo,id))
    }
  }
  
  function getDesignAnalyticsReport(page_no,fromDate,toDate,rootInfo,id,type) {
    return dispatch => {
        return Axios.get(`salePerformance/topCategories?from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}&type=${type}&limit=10`,
          { headers: getloginInfo()}
          )
            .then(response => {         
                if (response.data !== "") {             
                    dispatch(requestDesignAnalyticsDetails(response.data))                
                } else {
                    dispatch(requestDesignAnalyticsDetails([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestDesignAnalyticsDetails(response) {
    return {
        type: DESIGN_ANALYTICS_DETAILS,
        design_analytics_details: response
    }
  }
  
  export function getDesignAnalytics(page_no,fromDate,toDate,rootInfo,id,type) {
  
    return (dispatch) => {
        return dispatch(getDesignAnalyticsReport(page_no,fromDate,toDate,rootInfo,id,type))
    }
  }

  function getCutomerConversationRatioPerticularSalesPersonWiseReport(page_no,fromDate,toDate,rootInfo,id,salesPerson) {
  
  //  console.log(`salePerformance/salesperson?from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}&salesPerson=${salesPerson}`);
    return dispatch => {
        return Axios.get(`salePerformance/salesperson?from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}&salesPerson=${salesPerson}`,
          { headers: getloginInfo()}
          )
            .then(response => {         
                if (response.data !== "") {             
                    dispatch(requestCutomerConversationRatioPerticularSalesPersonWise(response.data))                
                } else {
                    dispatch(requestCutomerConversationRatioPerticularSalesPersonWise([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestCutomerConversationRatioPerticularSalesPersonWise(response) {
    return {
        type: SALESPERSON_WISE_CONVERSATION_RATIO,
        salesperson_wise_conversation_ratio: response
    }
  }
  
  export function getCutomerConversationRatioPerticularSalesPersonWise(page_no,fromDate,toDate,rootInfo,id,salesPerson) {
  
    return (dispatch) => {
        return dispatch(getCutomerConversationRatioPerticularSalesPersonWiseReport(page_no,fromDate,toDate,rootInfo,id,salesPerson))
    }
  }

  function getAllSalesPersonReport(rootInfo,id) {
    
    // let root =  JSON.parse(cookies.get('root'));
    // if(rootInfo=='company'){
    //   rootInfo="branch";
    //   id=root.branchId;
    // }
    // console.log(`user?&rootInfo=${rootInfo}&id=${id}`);
    return dispatch => {
        return Axios.get(`salePerformance/user?&rootInfo=${rootInfo}&id=${id}`,
          { headers: getloginInfo()}
          )
            .then(response => {         
                if (response.data !== "") {             
                    dispatch(requestAllSalesPersonReport(response.data))                
                } else {
                    dispatch(requestAllSalesPersonReport([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestAllSalesPersonReport(response) {
    return {
        type: ALL_SALES_PERSONS,
        all_sales_persons: response
    }
  }
  
  export function getAllSalesPerson(rootInfo,id) {
  
    return (dispatch) => {
        return dispatch(getAllSalesPersonReport(rootInfo,id))
    }
  }

  function getViewDemandReport(page_no,fromDate,toDate,rootInfo,id,salesPerson){
    
    //  console.log(`salePerformance/viewdemand?from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}`);
    return dispatch => {
        return Axios.get(`salePerformance/viewdemand?limit=3&from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}&type=design_category`,
          { headers: getloginInfo()}
          )
            .then(response => {         
                if (response.data !== "") {             
                    dispatch(requestViewDemandReport(response.data))                
                } else {
                    dispatch(requestViewDemandReport([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestViewDemandReport(response) {
    return {
        type: VIEW_DEMAND,
        view_demand: response
    }
  }
  
  export function getViewDemand(page_no,fromDate,toDate,rootInfo,id,salesPerson) {
  
    return (dispatch) => {
        return dispatch(getViewDemandReport(page_no,fromDate,toDate,rootInfo,id,salesPerson))
    }
  }
  

  export function getAllTotal(rootInfo,id,fromDate,toDate) {
    return dispatch => {
      //  return Axios.get(`company/total?rootInfo=${rootInfo}&id=${id}`,
         return Axios.get(`salePerformance/dhead?rootInfo=${rootInfo}&id=${id}&from=${fromDate}&to=${toDate}`,
          { headers: getloginInfo()}
          )
            .then(response => {    
              // console.log("sdfsfsd");     
              // console.log(response);    
                if (response.data !== "") {             
                    dispatch(requestAllTotalDetails(response.data))                
                } else {
                    dispatch(requestAllTotalDetails([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestAllTotalDetails(response) {
    return {
        type: TOTAL_DETAILS,
        total_details: response
    }
  }
 