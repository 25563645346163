import React, { Component } from 'react';
import {connect} from "react-redux";
import {Card,Table,Icon,message,Popconfirm,Row,Col} from "antd";
import {getCustomerMaster,removeCustomerMaster} from "../../../actions/index";
import AddCutomerMasster from "./addCustomerMaster";
import EditCustomerMaster from "./editCustomerMaster";
import EditCust from "./editTest";

class customerMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          table_loading:true,
          pagination:'',
          remove_id:''
        };
      }
      componentDidMount()
      {
        const { dispatch } = this.props;  
        dispatch(getCustomerMaster());
      }
      componentDidUpdate(prevProps, prevState)
      {
        if (prevProps.customer_master != this.props.customer_master) {
            if(this.props.customer_master.status)
            {
              let json=this.props.customer_master.data_array;      
        
                this.setState({
                  data:json,
                })
              // console.log("json");
                // console.log(json);
            } 
            this.setState({
              table_loading:false
             })
             
          }
          
          if (prevProps.update_customer_master != this.props.update_customer_master) {
            if(this.props.update_customer_master.status)
            {
              message.success("Update cutomer type sucessfully");
              const {dispatch} = this.props;
              dispatch(getCustomerMaster()); 
            } 
          } 

          if (prevProps.remove_customer_master != this.props.remove_customer_master) {
            if(this.props.remove_customer_master.status)
            {
              message.success("cutomer type remove sucessfully");
              let data=this.state.data;
              this.setState({ data: data.filter(item => item._id !== this.state.remove_id) });
            } 
          } 
      }
     
    handleDelete = (_id) => {
        //const data = [...this.state.data];
        //this.setState({ data: data.filter(item => item._id !== _id) });
        const { dispatch } = this.props;
        dispatch(removeCustomerMaster(_id));
        this.setState({remove_id:_id});
      }
   

    render() {
        const {data} =this.state;
        
const columns = [  
    {
      title: 'CUSTOMER TYPE',
      dataIndex: 'customer_type',
      //width: '20%',
      render: (text, record) => {
        const { finger_index } = record;
        return (
          <span>
              {text}
          </span>
        );
      }
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'desc',
     // width: '50%',
      render: (text, record) => {
        const { Stocktally } = record;
        return (
          <span>
              {text}
          </span>
        );
      }
    },
    {
        title: 'STATUS',
        dataIndex: 'isactive',
       // width: '20%',
        render: (text, record) => {
          const { Stocktally } = record;
          return (
            <span>
                {text ? "Active":"Deactive"}
            </span>
          );
        },
      },
      {
        title: 'ACTION',
        dataIndex: 'status',
       // width: '10%',
        render: (text, record) => {
          const {_id,customer_type,desc,isactive} = record;
          return (
            <span>
            <EditCustomerMaster id={_id} cust_type={customer_type} desc={desc} isactive={isactive} />          
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record._id)}>
                <Icon type="delete" style={{fontSize:"18px",color:"red",paddingLeft:"15px",cursor:"pointer"}}/>  
              </Popconfirm>
              </span>
          );
        },
        
      },
    ];
        return (
            <div>
            <Row>
            <Col md={18} sm={24} xs={24}> 
                <h2 className="title gx-mb-4" style={{float:"left"}}>Customer Master
                </h2>
            </Col>
            <Col md={6} sm={24} xs={24}>
                 <AddCutomerMasster text="Add Customer Type"/>
             </Col>
            </Row>
            
           {/*  <EditCust/>*/}
            <Card bodyStyle={{padding:"10px 0px"}}>
                    <Table className="gx-table-responsive" 
                    loading={this.state.table_loading} 
                    columns={columns} 
                    dataSource={data}
                    size={"small"}
                    rowKey={record => record._id}
                    //pagination={this.state.pagination}
                    onChange={this.handleTableChange}    
                />
             </Card> 
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {Common } = state
    const {customer_master:customer_master,update_customer_master:update_customer_master,remove_customer_master:remove_customer_master} =  Common
    return {customer_master,update_customer_master,remove_customer_master}
}
const AppContainer = connect( 
mapStateToProps 
)(customerMaster);
export default AppContainer;