import React, {Component} from "react";
import {connect} from "react-redux";
import {getMedia, postMedia} from "../../../actions/index";
import {Button, Card, Col, Form, Input, message, Radio, Row, Select,} from "antd";

import "./style.css";

var cookies = require("browser-cookies");
const Option = Select.Option;

class ManageUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentWillMount() {
        let usertype = localStorage.getItem("type_user");
        if (usertype != "companyAdmin") {
            window.location.href = "/dashboard";
        }
    }

    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(getMedia());
    }

    componentDidUpdate(prevProps, prevState) {
        //get_media
        if (prevProps.get_media != this.props.get_media) {
            // console.log("this.props.get_media");
            // console.log(this.props.get_media);
            if (this.props.get_media.status) {
                if (this.props.get_media.data != null) {
                    let data = this.props.get_media.data
                    localStorage.setItem("imageMapping", this.props.get_media.data.imageMapping);
                    this.setState({
                        imageMapping: data.imageMapping,
                        imagePath: data.imagePath,
                    });
                }
            }
        }

        if (prevProps.post_media != this.props.post_media) {
            // console.log("this.props.get_media");
            // console.log(this.props.post_media);
            if (this.props.post_media.status) {
                localStorage.setItem("imageMapping", this.props.post_media.data.imageMapping);
                message.success("Update sucessfully");
            }
        }
    }


    editIcon = (e) => {
        // console.log(e);
        this.setState({
            showForm: true,
            updateObject: e,
            currentKey: e.key,
            currentValue: e.value,
        }); 
    };

    updateValue = (e) => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            // console.log(values);
            let root = JSON.parse(cookies.get('root'));
            values.companyId = root.companyId;
            const {dispatch} = this.props;
            dispatch(postMedia(values));
        });
    };

    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };

        return (
            <div>
                <div className="certain-category-search-wrapper"/>

                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}/>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <Card bordered={false} title="Image configration">
                            <Form layout="vertical" onSubmit={this.updateValue}>
                                <Form.Item label="Image mapping" className="customFormItem">
                                    {getFieldDecorator("imageMapping", {
                                        initialValue: this.state.imageMapping,
                                        rules: [
                                            {required: true, message: "Please input firstname !"},
                                        ],
                                    })(
                                        <Radio.Group>
                                            <Radio value="sku_number">Sku Number</Radio>
                                            <Radio value="design_code">Design Code</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item label="Image path" className="customFormItem">
                                    {getFieldDecorator("imagePath", {
                                        initialValue: this.state.imagePath,
                                        rules: [
                                            {required: true, message: "Please input image path !"},
                                        ],
                                    })(<Input/>)}
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        onClick={this.updateValue}
                                        style={{marginBottom: "-13px", marginTop: "10px"}}
                                    >
                                        Update
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

ManageUser = Form.create()(ManageUser);

function mapStateToProps(state) {
    const {Common} = state;
    const {get_media: get_media, post_media: post_media} = Common;
    return {get_media, post_media};
}

const AppContainer = connect(mapStateToProps)(ManageUser);

export default AppContainer;
