import { EXPORT_QUATATION_DETAILS,EXPORT_SALES_DETAILS } from "../actions/dataexport";
function datExportReducer(
  state = {
    export_quotation_details: [],
    export_sales_details:[]
  },
  action
) {
  switch (action.type) {
    case EXPORT_QUATATION_DETAILS:
      return Object.assign({}, state, {
        export_quotation_details: action.export_quotation_details
      });

    case EXPORT_SALES_DETAILS:
    return Object.assign({}, state, {
      export_sales_details: action.export_sales_details
    });

    default:
      return state;
  }
}
export default datExportReducer;
