import {updatePRNDetails} from '../../actions/index';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Button, Card, Form, Input, message} from 'antd';

class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {"prns": [{prnString: ''}]}
        };
    }

    componentDidUpdate(prevProps, prevState) {
        try {

            if (prevProps.update_prn_data != this.props.update_prn_data) {
                if (this.props.update_prn_data.status) {
                    message.success("Update prn sucessfully");
                } else {
                    message.error(this.props.update_prn_data.error.msg);
                }
            }
            if (prevProps.company_details != this.props.company_details) {
                // console.log(this.props.company_details);
                if (this.props.company_details.status) {
                    this.setState({
                        data: this.props.company_details.data,
                    })
                } else {
                    this.setState({
                        data: {"prns": [{prnString: ''}]}
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleConfirmBlur = e => {
        const value = e.target.value;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    };

    //   checkCurrentPassword = (rule, value, callback) => {
    //     const form = this.props.form;
    //     if (this.props.password && this.props.password !== form.getFieldValue('currentpassword')) {
    //       callback('Current Passwords do not match');
    //     } else {
    //       callback();
    //     }
    //   };

    checkPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords do not match');
        } else {
            callback();
        }
    };

    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (err) {
                return;
            }

            let prnString = values.prnString;
            let updateData = {
                "prns": [
                    {
                        "prnString": prnString,
                        "inUse": true
                    },
                    {
                        "prnString": "",
                        "inUse": false
                    }
                ]
            }
            const {dispatch} = this.props;
            dispatch(updatePRNDetails(updateData));
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Card title="PRN configration" style={{borderRadius: "7px"}} bodyStyle={{paddingBottom: "0px"}}>
                <Form layout="vertical" onSubmit={this.handleSubmit}>
                    <Form.Item label="PRN String" className="customFormItem">
                        {/* {console.log("prn data ---", this.state.data)}
                        {console.log("prn string ---", this.state.data.prns)} */}
                        {getFieldDecorator('prnString', {
                            initialValue: (this.state.data.prns !== null && this.state.data.prns !== undefined && this.state.data.prns.length > 0) ? this.state.data.prns[0].prnString : "",
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input prn string !',
                                },
                                // {
                                //     validator: this.checkCurrentPassword,
                                // }
                            ],
                        })(<Input size="default"/>)}
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}


UpdatePassword = Form.create()(UpdatePassword);

function mapStateToProps(state) {
    const {Common} = state
    const {user_data: user_data, update_prn_data: update_prn_data, company_details: company_details} = Common
    return {user_data, update_prn_data, company_details}
}

const AppContainer = connect(
    mapStateToProps
)(UpdatePassword);
export default AppContainer;
