import Axios from "axios";
import Proxyjson from "./../../package.json";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("myTotalySecretKey");
var cookies = require("browser-cookies");
var proxyPaths = Proxyjson.proxy;
export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const LOGOUT = "LOGOUT";
export const TRY_ACTIVITIES = "TRY_ACTIVITIES";
export const QUATATION_DETAILS = "QUATATION_DETAILS";
export const CUSTOMERS_DETAILS = "CUSTOMERS_DETAILS";
export const GET_STOCK = "GET_STOCK";
export const AGING_DETAILS = "AGING_DETAILS";
export const DELETE_ALL_STOCK = "DELETE_ALL_STOCK";
export const SALES_DETAILS = "SALES_DETAILS";
export const STOCKTALLY_DETAILS = "STOCKTALLY_DETAILS";
export const STOCKTALLY_EXPORTDETAILS = "STOCKTALLY_EXPORTDETAILS";
export const BRANCH_LIST = "BRANCH_LIST";
export const SECTION_LIST = "SECTION_LIST";
export const FLOOR_LIST = "FLOOR_LIST";
export const SKU_DATA_BY_BRANCH = "SKU_DATA_BY_BRANCH";
export const SKU_DATA = "SKU_DATA";
export const SKU_TRANSFER = "SKU_TRANSFER";
export const SKU_WEBTRANSFER = "SKU_WEBTRANSFER";
export const SKU_ITEM_DETAILS = "SKU_ITEM_DETAILS";
export const CUSTOMER_CONVERSATION_RATIO_VIEW =
  "CUSTOMER_CONVERSATION_RATIO_VIEW";
export const VIEW_ON_DEMAND_VIEW = "VIEW_ON_DEMAND_VIEW";
export const ALL_SALES_PERSONS = "ALL_SALES_PERSONS";
export const SALES_AUDIT = "SALES_AUDIT";
export const GET_SOlD_STOCK = "GET_SOlD_STOCK";
export const GET_SOLD_STOCK_EXCEL = "GET_SOLD_STOCK_EXCEL";
export const RFIDDEVICE_INFO_DETAILS = "RFIDDEVICE_INFO_DETAILS";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const FORGOT_PASS = "FORGOT_PASS";
export const RESET_PASS = "RESET_PASS";
export const MEMO_DETAILS = "MEMO_DETAILS";
export const ORDER_DETAILS = "ORDER_DETAILS";
export const DELETE_ALL_STOCK_ROOT_INFO = "DELETE_ALL_STOCK_ROOT_INFO";
export const FILE_UPLOAD_STOCK_RESPONSE = "FILE_UPLOAD_STOCK_RESPONSE";
export const REPLACE_STOCK = "REPLACE_STOCK";
export const CREATE_BRANCH = "CREATE_BRANCH";
export const CREATE_FLOOR = "CREATE_FLOOR";
export const CREATE_SECTION = "CREATE_SECTION";
export const CREATE_SUB_SECTION = "CREATE_SUB_SECTION";
export const SUB_SECTION_LIST = "SUB_SECTION_LIST";
export const USER_LIST = "USER_LIST";
export const CREATE_USER = "CREATE_USER";
export const CHECK_USER_DATA = "CHECK_USER_DATA";
export const USER_DATA = "USER_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_BRANCH_DATA = "UPDATE_BRANCH_DATA";
export const UPDATE_SECTION_DATA = "UPDATE_SECTION_DATA";
export const UPDATE_SUBSECTION_DATA = "UPDATE_SUBSECTION_DATA";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const ACTION_REPORT = "ACTION_REPORT";
export const DEAD_STOCKS = "DEAD_STOCKS";
export const MULTIPLE_SKU_ITEMS = "MULTIPLE_SKU_ITEMS";
export const PDF = "PDF";
/*Start Cutomer master */
export const CUSTOMER_MASTER = "CUSTOMER_MASTER";
export const ADD_CUSTOMER_MASTER = "ADD_CUSTOMER_MASTER";
export const UPDATE_CUSTOMER_MASTER = "UPDATE_CUpdfSTOMER_MASTER";
export const REMOVE_CUSTOMER_MASTER = "REMOVE_CUSTOMER_MASTER";
export const ADD_INCREMENT_MASTER = "ADD_INCREMENT_MASTER";
export const SEARCH_CUSTOMERS_DETAILS = "SEARCH_CUSTOMERS_DETAILS";
export const GET_CUTOMER_REPORT = "GET_CUTOMER_REPORT";
export const EMPLOYEE_DETAILS = "EMPLOYEE_DETAILS";
export const UPDATE_USER = "UPDATE_USER";
export const GET_EMPLOYEE_REPORT = "GET_EMPLOYEE_REPORT";
export const FILE_UPLOAD_STATUS = "FILE_UPLOAD_STATUS";
/*End Cutomer master */
/*Preferences */
export const CREATE_VANUE = "CREATE_VANUE";
export const GET_VANUE = "GET_VANUE";
export const PUT_VANUE = "PUT_VANUE";
export const CREATE_TAX = "CREATE_TAX";
export const GET_TAX = "GET_TAX";
export const PUT_TAX = "PUT_TAX";
export const CREATE_METAL = "CREATE_METAL";
export const GET_METAL = "GET_METAL";
export const GET_PURITY = "GET_PURITY";
export const PUT_METAL = "PUT_METAL";
export const CREATE_CURRANCY = "CREATE_CURRANCY";
export const GET_CURRANCY = "GET_CURRANCY";
export const PUT_CURRANCY = "PUT_CURRANCY";
export const GET_SYNC = "GET_SYNC";
export const SPIN_LOADING = "SPIN_LOADING";
export const GET_VERSION = "GET_VERSION";
export const DELETET_BRANCH = "DELETET_BRANCH";
export const DELETET_FLOOR = "DELETET_FLOOR";
export const DELETET_SECTIONDATA = "DELETET_SECTIONDATA";
export const DELETET_SUBSECTIONDATA = "DELETET_SUBSECTIONDATA";
export const FTP_SYNC = "FTP_SYNC";
export const FILE_UPLOAD_CUSTOMER_RESPONSE = "FILE_UPLOAD_CUSTOMER_RESPONSE";
export const CUSTOMER_REPLACE_DETAILS = "CUSTOMER_REPLACE_DETAILS";
export const COMPANY_DETAILS = "COMPANY_DETAILS";
export const SKULABEL_DETAILS = "SKULABEL_DETAILS";
export const DISPLAY_CONFIG_DETAILS = "DISPLAY_CONFIG_DETAILS";
export const POST_SKULABLE = "POST_SKULABLE";
export const GET_FILTTER = "GET_FILTTER";
export const POST_FILTTER = "POST_FILTTER";
export const GET_MEDIA = "GET_MEDIA";
export const POST_MEDIA = "POST_MEDIA";
export const SKU_UPDATE = "SKU_UPDATE";
export const UPDATE_PRN_DATA = "UPDATE_PRN_DATA";
export const GET_OTP_STOCK_DELETE = "GET_OTP_STOCK_DELETE";
export const VERIFY_OTP_STOCK_DELETE = "VERIFY_OTP_STOCK_DELETE";
export const ADD_SINGLE_STOCK = "ADD_SINGLE_STOCK";
export const MEDIA_CONFIG_DETAILS = "MEDIA_CONFIG_DETAILS";
export const BILLING_DETAILS = "BILLING_DETAILS";
export const TRANSFER_DETAILS = "TRANSFER_DETAILS";
export const GET_SETTINGS = "GET_SETTINGS";
export const POST_SETTINGS = "POST_SETTINGS";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const GET_SKU_MEDIA = "GET_SKU_MEDIA";
export const UPDATE_COMPANY_DATA = "UPDATE_COMPANY_DATA";
export const GET_SCAN_SESSION = "GET_SCAN_SESSION";
export const POST_SCAN_SESSION = "POST_SCAN_SESSION";
export const UPDATE_SCAN_SESSION = "UPDATE_SCAN_SESSION";
/*start user login */

function login(post) {
  // console.log(post);
  let headers = {
    "Content-Type": "application/json",
    type: "Web",
  };
  return (dispatch) => {
    return Axios.post(
      "login",
      post,
      { headers: headers }
      // ,{
      //   auth: {
      //   username: username,
      //   password: password,
      //   }}
    ).then((response) => {
      //  console.log("login resposnse");
      //  console.log(response);
      if (response.data != "") {
        dispatch(requestLogin(response.data));

        //window.location.href='/dashboard';
        //dispatch(push('/dashboard'));
      } else {
        dispatch(requestLogin([]));
        // console.log(error);
      }
    });
  };
}

function requestLogin(response) {
  return {
    type: RECEIVE_LOGIN,
    login_user_data: response,
  };
}

export function userLogin(post) {
  return (dispatch) => {
    return dispatch(login(post));
  };
}

function forgotPasswordInfo(post) {
  let headers = {
    "Content-Type": "application/json",
  };
  return (dispatch) => {
    return Axios.post("email", post, { headers: headers }).then((response) => {
      if (response.data != "") {
        dispatch(requestForgot(response.data));
      } else {
        dispatch(requestForgot([]));
        // console.log(error);
      }
    });
  };
}

function requestForgot(response) {
  return {
    type: FORGOT_PASS,
    forgot_pass: response,
  };
}

export function forgotPassword(post) {
  return (dispatch) => {
    return dispatch(forgotPasswordInfo(post));
  };
}

function resetPasswordInfo(post) {
  let headers = {
    "Content-Type": "application/json",
  };
  return (dispatch) => {
    return Axios.post("email/reset", post, { headers: headers }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestresetPassword(response.data));
        } else {
          dispatch(requestresetPassword([]));
          // console.log(error);
        }
      }
    );
  };
}

function requestresetPassword(response) {
  return {
    type: RESET_PASS,
    reset_pass: response,
  };
}

export function resetPassword(post) {
  return (dispatch) => {
    return dispatch(resetPasswordInfo(post));
  };
}

function logout() {
  return (dispatch) => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("randid");
    localStorage.removeItem("user_id");
    localStorage.removeItem("DetailView");
    //  let loginurl=localStorage.getItem('type_user');
    const INIT_STATE = {
      loader: false,
      alertMessage: "",
      showMessage: false,
      initURL: "signin",
      authUser: "",
    };
    dispatch(requestLogout(INIT_STATE));
  };
}

function requestLogout(response) {
  window.location.href = "/signin";
  return {
    type: LOGOUT,
    logout: response,
  };
}

export function userLogout() {
  return (dispatch) => {
    return dispatch(logout());
  };
}

/*end user login */

function getTryActivitiesreport(
  pageNo,
  fromDate,
  toDate,
  selectdSalePerson,
  selectedCustomer,
  selectdCtg,
  selectedItem,
  weight,
  price,
  time
) {
  return (dispatch) => {
    return Axios.get(
      `salePerformance/trayactivity?from=${fromDate}&to=${toDate}&salePersons=${selectdSalePerson}&customers=${selectedCustomer}&ctgs=${selectdCtg}&skus=${selectedItem}&ntWt=${weight}&price=${price}&time=${time}${getRootInfoWithId()}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestTryActivitiesDetails(response.data));
        } else {
          dispatch(requestTryActivitiesDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestTryActivitiesDetails(response) {
  return {
    type: TRY_ACTIVITIES,
    try_activities: response,
  };
}

export function getTryActivities(
  pageNo,
  fromDate,
  toDate,
  selectdSalePerson,
  selectedCustomer,
  selectdCtg,
  selectedItem,
  weight,
  price,
  time
) {
  return (dispatch) => {
    return dispatch(
      getTryActivitiesreport(
        pageNo,
        fromDate,
        toDate,
        selectdSalePerson,
        selectedCustomer,
        selectdCtg,
        selectedItem,
        weight,
        price,
        time
      )
    );
  };
}

function getloginInfo() {
  let webtoken = cryptr.decrypt(cookies.get("wteoben"));
  let usertype = localStorage.getItem("type_user");
  let rootType = "";
  if (usertype === "branchAdmin") {
    rootType = "branch";
  }
  if (usertype === "companyAdmin") {
    rootType = "company";
  }
  if (usertype === "floorAdmin") {
    rootType = "floor";
  }
  if (usertype === "sectionAdmin") {
    rootType = "section";
  }
  if (usertype === "subSectionAdmin") {
    rootType = "subSection";
  }
  let headers = {
    "Content-Type": "application/json",
    //'type': 'Web',
    "x-web-token": webtoken,
  };
  return headers;
}

function getRootInfo(root, id) {
  let usertype = localStorage.getItem("type_user");
  let rootType = "";
  if (root != "" && id != "") {
    return `&rootInfo=${root}&id=${id}`;
  }
  if (usertype === "branchAdmin") {
    rootType = "branch";
  }
  if (usertype === "companyAdmin") {
    rootType = "company";
  }
  if (usertype === "floorAdmin") {
    rootType = "floor";
  }
  if (usertype === "sectionAdmin") {
    rootType = "section";
  }
  if (usertype === "subSectionAdmin") {
    rootType = "subSection";
  }
  return `&type=Web&rootInfo=${rootType}`;
}

function getRootInfoWithId() {
  let usertype = localStorage.getItem("type_user");
  let root = JSON.parse(cookies.get("root"));
  let rootType = "";
  let id = "";
  if (usertype === "branchAdmin") {
    rootType = "branch";
    id = root.branchId;
  }
  if (usertype === "companyAdmin") {
    rootType = "company";
    id = root.companyId;
  }
  if (usertype === "floorAdmin") {
    rootType = "floor";
    id = root.floorId;
  }
  if (usertype === "sectionAdmin") {
    rootType = "section";
    id = root.sectionId;
  }
  if (usertype === "subSectionAdmin") {
    rootType = "subSection";
    id = root.subSectionId;
  }
  return `&rootInfo=${rootType}&id=${id}`;
}

function getRootInfoWithIdWihtoutand() {
  let usertype = localStorage.getItem("type_user");
  let root = JSON.parse(cookies.get("root"));
  let rootType = "";
  let id = "";
  if (usertype === "branchAdmin") {
    rootType = "branch";
    id = root.branchId;
  }
  if (usertype === "companyAdmin") {
    rootType = "company";
    id = root.companyId;
  }
  if (usertype === "floorAdmin") {
    rootType = "floor";
    id = root.floorId;
  }
  if (usertype === "sectionAdmin") {
    rootType = "section";
    id = root.sectionId;
  }
  if (usertype === "subSectionAdmin") {
    rootType = "subSection";
    id = root.subSectionId;
  }
  return `?rootInfo=${rootType}&id=${id}`;
}

function getQuotationreport(
  pageNo,
  id,
  rootInfo,
  fromDate,
  toDate,
  issuedBy,
  customers,
  orderkey,
  order,
  q
) {
  return (dispatch) => {
    if (order === "descend") {
      order = -1;
    } else {
      order = 1;
    }
    return Axios.get(
      `quote?page_no=${pageNo}&limit=10&id=${id}&rootInfo=${rootInfo}&from=${fromDate}&to=${toDate}&_issueBy=${issuedBy}&customers=${customers}&sortBy=${orderkey}&sort=${order}&q=${q}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetQuotationDetails(response.data));
        } else {
          dispatch(requestgetQuotationDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetQuotationDetails(response) {
  return {
    type: QUATATION_DETAILS,
    quotation_details: response,
  };
}

export function getQuotation(
  pageNo,
  id,
  rootinfo,
  fromDate,
  toDate,
  issuedBy,
  customers,
  orderkey,
  order,
  q
) {
  return (dispatch) => {
    return dispatch(
      getQuotationreport(
        pageNo,
        id,
        rootinfo,
        fromDate,
        toDate,
        issuedBy,
        customers,
        orderkey,
        order,
        q
      )
    );
  };
}

// Comman API
function getCustomerreport(
  page_no,
  colkey,
  order,
  q,
  limit,
  fromDate,
  toDate,
  id,
  root,
  salesperson
) {
  // console.log(
  //   page_no,
  //   colkey,
  //   order,
  //   q,
  //   limit,
  //   fromDate,
  //   toDate,
  //   root,
  //   id,
  //   salesperson
  // );

  if (order === "descend") {
    order = -1;
  } else {
    order = 1;
  }
  let url = `customer?&q=${getRootInfo("", "")}`;
  if (limit == 10) {
    url = `customer/new?page_no=${page_no}&limit=${limit}&sortBy=${colkey}&sort=${order}&from=${fromDate}&to=${toDate}&salesPersons=${salesperson}${getRootInfo(
      root,
      id
    )}`;
  }
  return (dispatch) => {
    return Axios.get(
      url,
      { headers: getloginInfo() }
      // }esle{
      //   return Axios.get(`customer/page?page_no=${page_no}&limit=10&sortBy=${colkey}&sort=${order}&q=${q}${getRootInfo()}`,
      //   { headers:getloginInfo()}
      // }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestCustomerDetails(response.data));
        } else {
          dispatch(requestCustomerDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestCustomerDetails(response) {
  return {
    type: CUSTOMERS_DETAILS,
    customer_details: response,
  };
}

export function getCustomer(
  page_no,
  colkey,
  order,
  q,
  limit,
  fromDate,
  toDate,
  root,
  id,
  salesperson
) {
  return (dispatch) => {
    return dispatch(
      getCustomerreport(
        page_no,
        colkey,
        order,
        q,
        limit,
        fromDate,
        toDate,
        root,
        id,
        salesperson
      )
    );
  };
}

export function getCustomerSearch(q) {
  // let url=`customer?page_no=${page_no}&limit=10&sortBy=${colkey}&sort=${order}&q=${q}${getRootInfo()}`;

  return (dispatch) => {
    return Axios.get(
      `customer/new?search=${q}${getRootInfoWithId()}`,
      { headers: getloginInfo() }
      // }esle{
      //   return Axios.get(`customer/page?page_no=${page_no}&limit=10&sortBy=${colkey}&sort=${order}&q=${q}${getRootInfo()}`,
      //   { headers:getloginInfo()}
      // }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestSlectedCustomerDetails(response.data));
        } else {
          dispatch(requestSlectedCustomerDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestSlectedCustomerDetails(response) {
  return {
    type: SEARCH_CUSTOMERS_DETAILS,
    search_customer_details: response,
  };
}

//Get Stock API

function getStockreport(
  id,
  rootInfo,
  page_no,
  limit,
  q,
  fromDate,
  toDate,
  orderkey,
  order,
  d_cat,
  counter
) {
 
  if (q === undefined) {
    q = "";
  }
  if (order === "descend") {
    order = -1;
  } else {
    order = 1;
  }
  if (d_cat === undefined) {
    d_cat = "";
  }
  if (counter === undefined) {
    counter = "";
  }
  
  return (dispatch) => {
    return Axios.get(
      `sku?id=${id}&rootInfo=${rootInfo}&page_no=${page_no}&limit=${limit}&q=${q}&from=${fromDate}&to=${toDate}&sortBy=${orderkey}&sort=${order}&d_cat=${d_cat}&counter=${counter}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestStockDetails(response.data));
        } else {
          dispatch(requestStockDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestStockDetails(response) {
  return {
    type: GET_STOCK,
    get_stock: response,
  };
}

export function getSKUByRootInfoAndId(
  id,
  rootInfo,
  page_no,
  limit,
  q,
  fromDate,
  toDate,
  orderkey,
  order,
  d_cat,
  category
) {
  return (dispatch) => {
    return dispatch(
      getStockreport(
        id,
        rootInfo,
        page_no,
        limit,
        q,
        fromDate,
        toDate,
        orderkey,
        order,
        d_cat,
        category
      )
    );
  };
}

function deleteAllStockR(id) {
  return (dispatch) => {
    return (
      Axios.post("sku/deletemultipleitem", id, { headers: getloginInfo() })
        // return Axios.post('rc/stocks/resetUserGroupStock',
        //   {headers: {'x-web-token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVjMWM3NWNkOTIyZjVmMTc2NDA1NmYyMiIsImlhdCI6MTU0NzAxNDQxMCwiZXhwIjoxNTUzMDE0NDEwfQ.Rhv884EVDyCHKE2wg-ddHSiRgXw71Cml7mwrkiCocKU'}},
        //   )
        .then((response) => {
          if (response.data !== "") {
            //   console.log(response.data);
            dispatch(requestdeleteAllStockR(response.data));
          } else {
            dispatch(requestdeleteAllStockR([]));
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              // dispatch(push('/'));
              window.location.href = "/";
            } else {
              console.log(error);
            }
          }
        })
    );
  };
}

function requestdeleteAllStockR(response) {
  return {
    type: DELETE_ALL_STOCK,
    delete_all_stock: response,
  };
}

export function deleteAllStock(id) {
  return (dispatch) => {
    return dispatch(deleteAllStockR(id));
  };
}

function deleteAllStockWithRootInfoR(id, rootInfo) {
  return (dispatch) => {
    // console.log("deleteAllStockWithRootInfoR", id + " , " + rootInfo);
    return (
      Axios.delete(`sku/resetUserGroupStock?id=${id}&rootInfo=${rootInfo}`, {
        headers: getloginInfo(),
      })
        // return Axios.post('rc/stocks/resetUserGroupStock',
        //   {headers: {'x-web-token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVjMWM3NWNkOTIyZjVmMTc2NDA1NmYyMiIsImlhdCI6MTU0NzAxNDQxMCwiZXhwIjoxNTUzMDE0NDEwfQ.Rhv884EVDyCHKE2wg-ddHSiRgXw71Cml7mwrkiCocKU'}},
        //   )
        .then((response) => {
          if (response.data !== "") {
            //   console.log(response.data);
            dispatch(requestdeleteAllStockWithRootInfoR(response.data));
          } else {
            dispatch(requestdeleteAllStockWithRootInfoR([]));
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              // dispatch(push('/'));
              window.location.href = "/";
            } else {
              console.log(error);
            }
          }
        })
    );
  };
}

function requestdeleteAllStockWithRootInfoR(response) {
  return {
    type: DELETE_ALL_STOCK_ROOT_INFO,
    delete_all_stock_root_info: response,
  };
}

export function deleteAllStockWithRootInfo(id, rootInfo) {
  // console.log("deleteAllStockWithRootInfo", id + " , " + rootInfo);
  return (dispatch) => {
    return dispatch(deleteAllStockWithRootInfoR(id, rootInfo));
  };
}

/*------- Api to Get Otp for Stock delete -------- */
function getOtpForStockDeleteDetails() {
  return (dispatch) => {
    return Axios.get(`otp`, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetOtpForStockDelete(response.data));
        } else {
          dispatch(requestgetOtpForStockDelete([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetOtpForStockDelete(response) {
  return {
    type: GET_OTP_STOCK_DELETE,
    otp_stock_delete: response,
  };
}

export function getOtpForStockDelete() {
  return (dispatch) => {
    return dispatch(getOtpForStockDeleteDetails());
  };
}

/*------- Api to Get Otp for Stock delete -------- */

/*------- Api to post Otp for Stock delete -------- */
function verifyOtpInfp(post) {
  return (dispatch) => {
    return Axios.post("otp", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestVerifyOtp(response.data));
        } else {
          dispatch(requestVerifyOtp([]));
        }
      }
    );
  };
}

function requestVerifyOtp(response) {
  return {
    type: VERIFY_OTP_STOCK_DELETE,
    verify_otp_stock_delete: response,
  };
}

export function verifyOtp(post) {
  return (dispatch) => {
    return dispatch(verifyOtpInfp(post));
  };
}

/*------- Api to Get Otp for Stock delete -------- */

function getSalereport(
  page_no,
  id,
  rootInfo,
  fromDate,
  toDate,
  issuedBy,
  customers,
  orderkey,
  order,
  q
) {
  return (dispatch) => {
    //console.log(`sale?page_no=${page_no}&limit=10&id=${id}&rootInfo=${rootInfo}`);
    if (order === "descend") {
      order = -1;
    } else {
      order = 1;
    }
    return Axios.get(
      `sale?page_no=${page_no}&limit=10&id=${id}&rootInfo=${rootInfo}&from=${fromDate}&to=${toDate}&_issueBy=${issuedBy}&customers=${customers}&sortBy=${orderkey}&sort=${order}&q=${q}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetSaleDetails(response.data));
        } else {
          dispatch(requestgetSaleDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetSaleDetails(response) {
  return {
    type: SALES_DETAILS,
    sales_details: response,
  };
}

export function getSale(
  page_no,
  id,
  rootInfo,
  fromDate,
  toDate,
  issuedBy,
  customers,
  orderkey,
  order,
  q
) {
  return (dispatch) => {
    return dispatch(
      getSalereport(
        page_no,
        id,
        rootInfo,
        fromDate,
        toDate,
        issuedBy,
        customers,
        orderkey,
        order,
        q
      )
    );
  };
}

/*------------------------ API to get billing data ---------------------------*/
function getBillingreport(
  page_no,
  id,
  rootInfo,
  fromDate,
  toDate,
  issuedBy,
  customers,
  orderkey,
  order,
  q
) {
  return (dispatch) => {
    //console.log(`sale?page_no=${page_no}&limit=10&id=${id}&rootInfo=${rootInfo}`);
    if (order === "descend") {
      order = -1;
    } else {
      order = 1;
    }
    return Axios.get(
      `bill?page_no=${page_no}&limit=10&id=${id}&rootInfo=${rootInfo}&from=${fromDate}&to=${toDate}&_issueBy=${issuedBy}&customers=${customers}&sortBy=${orderkey}&sort=${order}&q=${q}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetBillingDetails(response.data));
        } else {
          dispatch(requestgetBillingDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetBillingDetails(response) {
  return {
    type: BILLING_DETAILS,
    billing_details: response,
  };
}

export function getBilling(
  page_no,
  id,
  rootInfo,
  fromDate,
  toDate,
  issuedBy,
  customers,
  orderkey,
  order,
  q
) {
  return (dispatch) => {
    return dispatch(
      getBillingreport(
        page_no,
        id,
        rootInfo,
        fromDate,
        toDate,
        issuedBy,
        customers,
        orderkey,
        order,
        q
      )
    );
  };
}
/*------------------------ API to get transfer data ---------------------------*/
function getTransferreport(
  page_no,
  id,
  rootInfo,
  fromDate,
  toDate,
  orderkey,
  order,
  q
) {
  return (dispatch) => {
    //console.log(`sale?page_no=${page_no}&limit=10&id=${id}&rootInfo=${rootInfo}`);
    if (order === "descend") {
      order = -1;
    } else {
      order = 1;
    }
    return Axios.get(
      `transferHistory?page_no=${page_no}&limit=10&id=${id}&rootInfo=${rootInfo}&from=${fromDate}&to=${toDate}&sortBy=${orderkey}&sort=${order}&q=${q}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetTransferDetails(response.data));
        } else {
          dispatch(requestgetTransferDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetTransferDetails(response) {
  return {
    type: TRANSFER_DETAILS,
    transfer_details: response,
  };
}

export function getTransfer(
  page_no,
  id,
  rootInfo,
  fromDate,
  toDate,
  orderkey,
  order,
  q
) {
  return (dispatch) => {
    return dispatch(
      getTransferreport(
        page_no,
        id,
        rootInfo,
        fromDate,
        toDate,
        orderkey,
        order,
        q
      )
    );
  };
}

/*------------------------ API to get billing data ---------------------------*/

function getStockTallyreport(
    page_no,
    limit,
  fromDate,
  toDate,
  userId,
  columnKey,
  order
) {
  if (order === "descend") {
    order = -1;
  } else {
    order = 1;
  }
  return (dispatch) => {
    return Axios.get(
      `stockTally/page?page_no=${page_no}&limit=${limit}&from=${fromDate}&to=${toDate}&userId=${userId}&sortBy=${columnKey}&sort=${order}${getRootInfoWithId()}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetStockTallyreportDetails(response.data));
        } else {
          dispatch(requestgetStockTallyreportDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetStockTallyreportDetails(response) {
  return {
    type: STOCKTALLY_DETAILS,
    stocktally_details: response,
  };
}

export function getStockTally(
  page_no,
  fromDate,
  toDate,
  userId,
  columnKey,
  order
) {
  return (dispatch) => {
    return dispatch(
      getStockTallyreport(page_no, fromDate, toDate, userId, columnKey, order)
    );
  };
}



function requestgetStockTallyExportreportDetails(response) {
    return {
        type: STOCKTALLY_EXPORTDETAILS,
        stocktally_exportdetails: response,
    };
}

function getStockTallyExportreport(historyId) {
    return (dispatch) => {
        return Axios.post(
            'stockTally/export', { historyId }, { headers: getloginInfo() })
            .then((response) => {
                if (response.data !== "") {
                    dispatch(requestgetStockTallyExportreportDetails(response.data));
                } else {
                    dispatch(requestgetStockTallyExportreportDetails([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "/";
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

export function getStockTallyExport(historyId) {
    return (dispatch) => {
        return dispatch(getStockTallyExportreport(historyId));
    };
}




function getagingreport(
    startDays,
    endDays,
    skuNumber
) {
    return (dispatch) => {
        return Axios.get(
            /*  `sku/skureport/page?page_no=${page_no}&limit=10&from=${fromDate}&to=${toDate}&userId=${userId}&sortBy=${columnKey}&sort=${order}${getRootInfoWithId()}`,*/
            `sku/skureport?startDays=${startDays}&endDays=${endDays}&skuNumber=${skuNumber}`,
            { headers: getloginInfo() }
        )
            .then((response) => {
                if (response.data !== "") {
                    dispatch(requestgetagingreportDetails(response.data));
                } else {
                    dispatch(requestgetagingreportDetails([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // dispatch(push('/'));
                        window.location.href = "/";
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestgetagingreportDetails(response) {
    return {
        type: AGING_DETAILS,
        aging_details: response,
    };
}

export function getaging(
    startDays,
    endDays,
    skuNumber
) {
    return (dispatch) => {
        return dispatch(
            getagingreport(
                startDays,
                endDays,
                skuNumber
            )
        );
    };
}

function getAllBrachListDetails(idtype, id) {
  return (dispatch) => {
    //return Axios.get(`branch`,
    if (idtype === "byCompanyId") {
      return Axios.get(
        `branch?rootInfo=company&id=${id}`,
        // return Axios.get(`branch${getRootInfoWithIdWihtoutand()}`,
        { headers: getloginInfo() }
      )
        .then((response) => {
          if (response.data !== "") {
            dispatch(requestgetBranchDetails(response.data));
          } else {
            dispatch(requestgetBranchDetails([]));
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              // dispatch(push('/'));
              window.location.href = "/";
            } else {
              console.log(error);
            }
          }
        });
    } else {
      return Axios.get(`branch${getRootInfoWithIdWihtoutand()}`, {
        headers: getloginInfo(),
      })
        .then((response) => {
          if (response.data !== "") {
            dispatch(requestgetBranchDetails(response.data));
          } else {
            dispatch(requestgetBranchDetails([]));
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              // dispatch(push('/'));
              window.location.href = "/";
            } else {
              console.log(error);
            }
          }
        });
    }
  };
}

function requestgetBranchDetails(response) {
  return {
    type: BRANCH_LIST,
    branch_list: response,
  };
}

export function getAllBrachList(idtype, id) {
  return (dispatch) => {
    return dispatch(getAllBrachListDetails(idtype, id));
  };
}

function getAllSectionListDetails(idtype, id) {
  return (dispatch) => {
    if (idtype === "byFloorId") {
      return Axios.get(`section?rootInfo=floor&id=${id}`, {
        headers: getloginInfo(),
      })
        .then((response) => {
          // if (response.data !== "") {
            if (Object.keys(response.data).length !== 0) {
            dispatch(requestgetAllSectionListDetails(response.data));
          } else {
            dispatch(requestgetAllSectionListDetails([]));
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              // dispatch(push('/'));
              window.location.href = "/";
            } else {
              console.log(error);
            }
          }
        });
    } else {
      return Axios.get(`section${getRootInfoWithIdWihtoutand()}`, {
        headers: getloginInfo(),
      })
        .then((response) => {
          // if (response.data !== "") {
            if (Object.keys(response.data).length !== 0) {
            dispatch(requestgetAllSectionListDetails(response.data));
          } else {
            dispatch(requestgetAllSectionListDetails([]));
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              // dispatch(push('/'));
              window.location.href = "/";
            } else {
              console.log(error);
            }
          }
        });
    }
  };
}

function requestgetAllSectionListDetails(response) {
  return {
    type: SECTION_LIST,
    section_list: response,
  };
}

export function getAllSectionList(idtype, id) {
  return (dispatch) => {
    return dispatch(getAllSectionListDetails(idtype, id));
  };
}

function getAllFloorListDetails(idtype, id) {
  return (dispatch) => {
    if (idtype === "bybranchId") {
      return Axios.get(`floor?rootInfo=branch&id=${id}`, {
        headers: getloginInfo(),
      })
        .then((response) => {
          if (response.data !== "") {
            dispatch(requestgetAllFloorListDetails(response.data));
          } else {
            dispatch(requestgetAllFloorListDetails([]));
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              // dispatch(push('/'));
              window.location.href = "/";
            } else {
              console.log(error);
            }
          }
        });
    } else {
      return Axios.get(`floor${getRootInfoWithIdWihtoutand()}`, {
        headers: getloginInfo(),
      })
        .then((response) => {
          if (response.data !== "") {
            dispatch(requestgetAllFloorListDetails(response.data));
          } else {
            dispatch(requestgetAllFloorListDetails([]));
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              // dispatch(push('/'));
              window.location.href = "/";
            } else {
              console.log(error);
            }
          }
        });
    }
  };
}

function requestgetAllFloorListDetails(response) {
  return {
    type: FLOOR_LIST,
    floor_list: response,
  };
}

export function getAllFloorList(idtype, id) {
  return (dispatch) => {
    return dispatch(getAllFloorListDetails(idtype, id));
  };
}


export function stockTransfer(body) {
    return (dispatch) => {
        return Axios.post("transferHistory", body, {
            headers: getloginInfo(),
        }).then((response) => {
            if (response.data != "") {
                dispatch(requestTransferSku(response.data));
            } else {
                dispatch(requestTransferSku([]));
            }
        });
    };
    //   }
}
function requestTransferSku(response) {
    return {
        type: SKU_TRANSFER,
        sku_transfer: response,
    };

}

export function stockwebTransfer(body) {
    return (dispatch) => {
        return Axios.post("transferHistory/webtransfer", body, {
            headers: getloginInfo(),
        }).then((response) => {
            if (response.data != "") {
                dispatch(requestwebTransferSku(response.data));
            } else {
                dispatch(requestwebTransferSku([]));
            }
        });
    };
}

function requestwebTransferSku(response) {
    return {
        type: SKU_WEBTRANSFER,
        sku_webtransfer: response,
    };

}

export function getStockFromExcel(
    from,
    to,
    sort,
    d_cat,
    id,
    q,
    counter,
    skuNumbers
) {
    if (q === undefined) {
        q = "";
    }
    return (dispatch) => {
        return Axios.get(
            `sku?from=${from}&to=${to}&sortBy=${sort}&d_cat=${d_cat}&rootInfo=company&id=${id}&q=${q}&sort=1&counter=${counter}&sku_numbers=${skuNumbers}`,
            { headers: getloginInfo() }
        )
            .then((response) => {
                if (response.data !== "") {
                    dispatch(requestSkufromExcel(response.data));
                } else {
                    dispatch(requestSkufromExcel([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // dispatch(push('/'));
                        window.location.href = "/";
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}
function requestSkufromExcel(response) {
    return {
        type: SKU_DATA,
        sku_from_excel: response,
    };
}


export function getStockByBranch(from, to, sort, d_cat, id, q, counter) {
    if (q === undefined) {
        q = "";
    }
    return (dispatch) => {
        return Axios.get(
            `sku?from=${from}&to=${to}&sortBy=${sort}&d_cat=${d_cat}&rootInfo=branch&id=${id}&q=${q}&sort=1&counter=${counter}`,
            { headers: getloginInfo() }
        )
            .then((response) => {
                if (response.data !== "") {
                    dispatch(requestSkuByBranch(response.data));
                } else {
                    dispatch(requestSkuByBranch([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // dispatch(push('/'));
                        window.location.href = "/";
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}
function requestSkuByBranch(response) {
    return {
        type: SKU_DATA_BY_BRANCH,
        sku_by_branch: response,
    };
}

function getSKUbyIDDetails(id, dashbaord) {
  var url = window.location.href;
  var arr = url.split("/");
  var urlforsku = arr[0] + "//" + arr[2];
  var urlfinal = `${urlforsku}/sku?id=${id}&rootInfo=sku`;
  //${proxyPaths}
  return (dispatch) => {
    if (dashbaord == "dashbaord") {
      urlfinal = `salePerformance/mView?id=${id}`;
    }
    return Axios.get(`${urlfinal}`, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetSKUbyIDDetails(response.data));
        } else {
          dispatch(requestgetSKUbyIDDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetSKUbyIDDetails(response) {
  return {
    type: SKU_ITEM_DETAILS,
    sku_item_details: response,
  };
}

export function getSKUbyID(id, dashbaord) {
  return (dispatch) => {
    return dispatch(getSKUbyIDDetails(id, dashbaord));
  };
}

// Report API
function getCutomerConversationReport(
  page_no,
  fromDate,
  toDate,
  rootInfo,
  id,
  salesPersons
) {
  return (dispatch) => {
    return Axios.get(
      `salePerformance/salesperson?&from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&id=${id}&salesPersons=${salesPersons}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetCutomerConversationDetails(response.data));
        } else {
          dispatch(requestgetCutomerConversationDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetCutomerConversationDetails(response) {
  return {
    type: CUSTOMER_CONVERSATION_RATIO_VIEW,
    customer_conversation_ratio_view: response,
  };
}

export function getCutomerConversationRatio(
  page_no,
  fromDate,
  toDate,
  rootInfo,
  id,
  salesPersons
) {
  return (dispatch) => {
    return dispatch(
      getCutomerConversationReport(
        page_no,
        fromDate,
        toDate,
        rootInfo,
        id,
        salesPersons
      )
    );
  };
}

function getViewOnDemandReport(page_no, fromDate, toDate, cateogory) {
  return (dispatch) => {
    return Axios.get(
      `salePerformance/viewdemandreport?from=${fromDate}&to=${toDate}&type=design_category&cateogory=${cateogory}${getRootInfoWithId()}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetViewOnDemandDetails(response.data));
        } else {
          dispatch(requestgetViewOnDemandDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetViewOnDemandDetails(response) {
  return {
    type: VIEW_ON_DEMAND_VIEW,
    view_on_demand_view: response,
  };
}

export function getViewOnDemand(page_no, fromDate, toDate, cateogory) {
  return (dispatch) => {
    return dispatch(
      getViewOnDemandReport(page_no, fromDate, toDate, cateogory)
    );
  };
}

function getAllSalesPersonReport(rootInfo, id) {
  return (dispatch) => {
    return Axios.get(`salePerformance/user?type="web"${getRootInfoWithId()}`, {
      headers: getloginInfo(),
    })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestAllSalesPersonReport(response.data));
        } else {
          dispatch(requestAllSalesPersonReport([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestAllSalesPersonReport(response) {
  return {
    type: ALL_SALES_PERSONS,
    all_sales_persons: response,
  };
}

export function getAllSalesPerson(rootInfo, id) {
  return (dispatch) => {
    return dispatch(getAllSalesPersonReport(rootInfo, id));
  };
}


export function getSoldSKUExport(
    id,
    rootInfo,
    page_no,
    limit,
    q,
    fromDate,
    toDate,
    orderkey,
    order,
    d_cat,
    counter,
    location,
    batchno
) {
    if (q === undefined) {
        q = "";
    }
    if (order === "descend") {
        order = -1;
    } else {
        order = 1;
    }
    return (dispatch) => {
        return Axios.post(
            `excel/soldSkus?id=${id}&rootInfo=${rootInfo}&page_no=${page_no}&limit=${limit}&q=${q}&from=${fromDate}&to=${toDate}&sortBy=${orderkey}&sort=${order}&d_cat=${d_cat}&counter=${counter}&location=${location}&batchno=${batchno}`,
            {},
            { headers: getloginInfo(), responseType: "arraybuffer" }
        )
            .then((response) => {
                if (response.data !== "") {
                    dispatch(requestSoldStockExport(response.data));
                } else {
                    dispatch(requestSoldStockExport([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "/";
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestSoldStockExport(response) {
    return {
        type: GET_SOLD_STOCK_EXCEL,
        get_sold_stock_xl: response,
    };
}



export function getSoldSKUByRootInfoAndId(
    id,
    rootInfo,
    page_no,
    limit,
    q,
    fromDate,
    toDate,
    orderkey,
    order,
    d_cat,
    counter,
    location,
    batchno
) {
    if (q === undefined) {
        q = "";
    }
    if (order === "descend") {
        order = -1;
    } else {
        order = 1;
    }
    return (dispatch) => {
        return Axios.get(
            `sold?id=${id}&rootInfo=${rootInfo}&page_no=${page_no}&limit=${limit}&q=${q}&from=${fromDate}&to=${toDate}&sortBy=${orderkey}&sort=${order}&d_cat=${d_cat}&counter=${encodeURIComponent(
                counter
            )}&location=${location}&batchno=${batchno}`,
            { headers: getloginInfo() }
        )
            .then((response) => {
                if (response.data !== "") {
                    dispatch(requestSoldStockDetails(response.data));
                } else {
                    dispatch(requestSoldStockDetails([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        window.location.href = "/";
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestSoldStockDetails(response) {
    return {
        type: GET_SOlD_STOCK,
        get_sold_stock: response,
    };
}



function getSalesAuditReport(
  fromDate,
  toDate,
  selectdSalesPerson,
  selectdCtg,
  selectdQty
) {
  return (dispatch) => {
    return Axios.get(
      `sale/audit?from=${fromDate}&to=${toDate}&ctgs=${selectdCtg}&sName=${selectdSalesPerson}&qty=${selectdQty}${getRootInfoWithId()}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetSalesAuditDetails(response.data));
        } else {
          dispatch(requestgetSalesAuditDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetSalesAuditDetails(response) {
  return {
    type: SALES_AUDIT,
    sales_audit: response,
  };
}

export function getSalesAudit(
  fromDate,
  toDate,
  selectdSalesPerson,
  selectdCtg,
  selectdQty
) {
  return (dispatch) => {
    return dispatch(
      getSalesAuditReport(
        fromDate,
        toDate,
        selectdSalesPerson,
        selectdCtg,
        selectdQty
      )
    );
  };
}

function getRfidDevicereport(page_no, fromDate, toDate) {
  return (dispatch) => {
    return Axios.get(`device?rootInfo=company`, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetRfidDevicereportDetails(response.data));
        } else {
          dispatch(requestgetRfidDevicereportDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetRfidDevicereportDetails(response) {
  return {
    type: RFIDDEVICE_INFO_DETAILS,
    rfiddevice_info_details: response,
  };
}

export function getRfidDevice(page_no, fromDate, toDate) {
  return (dispatch) => {
    return dispatch(getRfidDevicereport(page_no, fromDate, toDate));
  };
}

function createCustomerinfo(post) {
  return (dispatch) => {
    return Axios.post("customer", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestCreateCustomer(response.data));
        } else {
          dispatch(requestCreateCustomer([]));
        }
      }
    );
  };
}

function requestCreateCustomer(response) {
  return {
    type: CREATE_CUSTOMER,
    create_customer: response,
  };
}

export function createCustomer(post) {
  return (dispatch) => {
    return dispatch(createCustomerinfo(post));
  };
}

function getMemosreport(
  page_no,
  id,
  rootInfo,
  fromDate,
  toDate,
  issuedBy,
  customers,
  orderkey,
  order,
  q
) {

  return (dispatch) => {
    if (order === "descend") {
      order = -1;
    } else {
      order = 1;
    }
    return Axios.get(
      `memos?page_no=${page_no}&limit=10&id=${id}&rootInfo=${rootInfo}&from=${fromDate}&to=${toDate}&_issueBy=${issuedBy}&customers=${customers}&sortBy=${orderkey}&sort=${order}&q=${q}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetMemosDetails(response.data));
        } else {
          dispatch(requestgetMemosDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetMemosDetails(response) {
  return {
    type: MEMO_DETAILS,
    memo_details: response,
  };
}

export function getMemos(
  page_no,
  id,
  rootInfo,
  fromDate,
  toDate,
  issuedBy,
  customers,
  orderkey,
  order,
  q
) {
  return (dispatch) => {
    return dispatch(
      getMemosreport(
        page_no,
        id,
        rootInfo,
        fromDate,
        toDate,
        issuedBy,
        customers,
        orderkey,
        order,
        q
      )
    );
  };
}

function getOrderreport(
  page_no,
  id,
  rootInfo,
  fromDate,
  toDate,
  issuedBy,
  customers,
  orderkey,
  order,
  q
) {
  return (dispatch) => {
    if (order === "descend") {
      order = -1;
    } else {
      order = 1;
    }
    return Axios.get(
      `order?page_no=${page_no}&limit=10&id=${id}&rootInfo=${rootInfo}&from=${fromDate}&to=${toDate}&_issueBy=${issuedBy}&customers=${customers}&sortBy=${orderkey}&sort=${order}&q=${q}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetOrderDetails(response.data));
        } else {
          dispatch(requestgetOrderDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetOrderDetails(response) {
  return {
    type: ORDER_DETAILS,
    order_details: response,
  };
}

export function getOrder(
  page_no,
  id,
  rootInfo,
  fromDate,
  toDate,
  issuedBy,
  customers,
  orderkey,
  order,
  q
) {
  return (dispatch) => {
    return dispatch(
      getOrderreport(
        page_no,
        id,
        rootInfo,
        fromDate,
        toDate,
        issuedBy,
        customers,
        orderkey,
        order,
        q
      )
    );
  };
}

function stockUploadFileResponseto(response) {
  return (dispatch) => {
    dispatch(requeststockUploadFileResponse(response));
    // return Axios.get('rc/stocks/allStock',
  };
}

function requeststockUploadFileResponse(response) {
  return {
    type: FILE_UPLOAD_STOCK_RESPONSE,
    file_upload_stock_response: response,
  };
}

export function stockUploadFileResponse(response) {
  return (dispatch) => {
    return dispatch(stockUploadFileResponseto(response));
  };
}

function stockUploadStatusbarResponse(response) {
  return {
    type: FILE_UPLOAD_STATUS,
    file_upload_status: response,
  };
}

export function stockUploadStatusbar(response) {
  return (dispatch) => {
    return dispatch(stockUploadStatusbarResponse(response));
  };
}

function replaceStockdata(id) {
  let webtoken = cryptr.decrypt(cookies.get("wteoben"));
  return (dispatch) => {
    return Axios.post("sku/replaceStock", id, {
      headers: { "x-web-token": webtoken },
    })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestreplaceStock(response));
        } else {
          dispatch(requestreplaceStock([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestreplaceStock(response) {
  return {
    type: REPLACE_STOCK,
    replace_stock: response,
  };
}

export function replaceStock(id) {
  return (dispatch) => {
    return dispatch(replaceStockdata(id));
  };
}

function addSingleStockData(post) {
  let webtoken = cryptr.decrypt(cookies.get("wteoben"));
  return (dispatch) => {
    return Axios.post("sku", post, { headers: { "x-web-token": webtoken } })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestaddSingleStock(response));
        } else {
          dispatch(requestaddSingleStock([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestaddSingleStock(response) {
  return {
    type: ADD_SINGLE_STOCK,
    add_single_stock: response,
  };
}

export function addSingleStock(post) {
  return (dispatch) => {
    return dispatch(addSingleStockData(post));
  };
}

function createBranchinfo(post) {
  return (dispatch) => {
    return Axios.post("branch", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestcreateBranch(response.data));
        } else {
          dispatch(requestcreateBranch([]));
        }
      }
    );
  };
}

function requestcreateBranch(response) {
  return {
    type: CREATE_BRANCH,
    create_branch: response,
  };
}

export function createBranch(post) {
  return (dispatch) => {
    return dispatch(createBranchinfo(post));
  };
}

function createFloorinfo(post) {
  return (dispatch) => {
    return Axios.post("floor", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestcreateFloor(response.data));
        } else {
          dispatch(requestcreateFloor([]));
        }
      }
    );
  };
}

function requestcreateFloor(response) {
  return {
    type: CREATE_FLOOR,
    create_floor: response,
  };
}

export function createFloor(post) {
  return (dispatch) => {
    return dispatch(createFloorinfo(post));
  };
}

function createSectioninfo(post) {
  return (dispatch) => {
    return Axios.post("section", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestcreateSection(response.data));
        } else {
          dispatch(requestcreateSection([]));
        }
      }
    );
  };
}

function requestcreateSection(response) {
  return {
    type: CREATE_SECTION,
    create_section: response,
  };
}

export function createSection(post) {
  return (dispatch) => {
    return dispatch(createSectioninfo(post));
  };
}

function createSubSectioninfo(post) {
  return (dispatch) => {
    return Axios.post("subsection", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestcreateSubSection(response.data));
        } else {
          dispatch(requestcreateSubSection([]));
        }
      }
    );
  };
}

function requestcreateSubSection(response) {
  return {
    type: CREATE_SUB_SECTION,
    create_sub_section: response,
  };
}

export function createSubSection(post) {
  return (dispatch) => {
    return dispatch(createSubSectioninfo(post));
  };
}

function getAllSubSectionListDetails(idtype, id) {
  return (dispatch) => {
    // if(idtype==="bysectionIdFromManager"){
    //   console.log("uSesr");
    //   return Axios.get(`subsection?rootInfo=section&id=${id}`,
    //   { headers: getloginInfo()}
    //   )
    //     .then(response => {
    //         if (response.data !== "") {
    //             dispatch(requestgetAllSectionListDetails(response.data))
    //         } else {
    //             dispatch(requestgetAllSectionListDetails([]))
    //         }
    //     })
    //     .catch(error => {
    //         if (error.response) {
    //             if (error.response.status === 403) {
    //               // dispatch(push('/'));
    //               window.location.href='/';
    //             } else {
    //                 console.log(error);
    //             }
    //         }
    //     });
    //   }else{
    //     console.log("uesrffff111");
    return Axios.get(`subsection?rootInfo=section&id=${id}`, {
      headers: getloginInfo(),
    })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetAllsubSectionListDetails(response.data));
        } else {
          dispatch(requestgetAllsubSectionListDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
    //   }
  };
}

function requestgetAllsubSectionListDetails(response) {
  return {
    type: SUB_SECTION_LIST,
    sub_section_list: response,
  };
}

export function getAllSubSectionList(idtype, id) {
  return (dispatch) => {
    return dispatch(getAllSubSectionListDetails(idtype, id));
  };
}

function getAllUserListDetails(idtype, id) {
  return (dispatch) => {
    return Axios.get(`user?rootInfo=${idtype}&id=${id}`, {
      headers: getloginInfo(),
    })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetAllUserDetails(response.data));
        } else {
          dispatch(requestgetAllUserDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetAllUserDetails(response) {
  return {
    type: USER_LIST,
    user_list: response,
  };
}

export function getAllUserList(idtype, id) {
  return (dispatch) => {
    return dispatch(getAllUserListDetails(idtype, id));
  };
}

function createUserinfo(post) {
  return (dispatch) => {
    return Axios.post("user", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestcreateUser(response.data));
        } else {
          dispatch(requestcreateUser([]));
        }
      }
    );
  };
}

function requestcreateUser(response) {
  return {
    type: CREATE_USER,
    create_user: response,
  };
}

export function createUser(post) {
  return (dispatch) => {
    return dispatch(createUserinfo(post));
  };
}

function CheckUserDetails(id) {
  return (dispatch) => {
    return Axios.get(`check?type=username&id=${id}`, {
      headers: getloginInfo(),
    })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestCheckUser(response.data));
        } else {
          dispatch(requestCheckUser([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestCheckUser(response) {
  return {
    type: CHECK_USER_DATA,
    check_user_data: response,
  };
}

export function checkUserName(id) {
  return (dispatch) => {
    return dispatch(CheckUserDetails(id));
  };
}

export function getUserDetails(username) {
  return (dispatch) => {
    return Axios.get(`user/byusername?username=${username}`, {
      headers: getloginInfo(),
    })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestUserData(response.data));
        } else {
          dispatch(requestUserData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestUserData(response) {
  return {
    type: USER_DATA,
    user_data: response,
  };
}

export function updateUserDetails(body) {
  return (dispatch) => {
    return Axios.put(`user`, body, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestUpdateUserData(response.data));
        } else {
          dispatch(requestUpdateUserData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestUpdateUserData(response) {
  return {
    type: UPDATE_USER_DATA,
    update_user_data: response,
  };
}

export function updatePRNDetails(body) {
  return (dispatch) => {
    return Axios.put(`/company/prn`, body, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestupdatePRNDetails(response.data));
        } else {
          dispatch(requestupdatePRNDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestupdatePRNDetails(response) {
  return {
    type: UPDATE_PRN_DATA,
    update_prn_data: response,
  };
}

export function updatebranchDetails(body) {
  return (dispatch) => {
    return Axios.put(`branch`, body, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestUpdateBranchData(response.data));
        } else {
          dispatch(requestUpdateBranchData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestUpdateBranchData(response) {
  return {
    type: UPDATE_BRANCH_DATA,
    update_branch_data: response,
  };
}

export function updateFloorDetails(body) {
  return (dispatch) => {
    return Axios.put(`floor`, body, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestUpdateBranchData(response.data));
        } else {
          dispatch(requestUpdateBranchData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        }
      });
  };
}

export function updateSectionDetails(body) {
  return (dispatch) => {
    return Axios.put(`section`, body, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestUpdateSectionData(response.data));
        } else {
          dispatch(requestUpdateSectionData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestUpdateSectionData(response) {
  return {
    type: UPDATE_SECTION_DATA,
    update_section_data: response,
  };
}

export function updateSubSectionDetails(body) {
  return (dispatch) => {
    return Axios.put(`subsection`, body, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestUpdateSubSectionData(response.data));
        } else {
          dispatch(requestUpdateSubSectionData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestUpdateSubSectionData(response) {
  return {
    type: UPDATE_SUBSECTION_DATA,
    update_subsection_data: response,
  };
}

export function updateCustomer(post) {
  return (dispatch) => {
    return Axios.put("customer", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestUpdateCustomer(response.data));
        } else {
          dispatch(requestUpdateCustomer([]));
        }
      }
    );
  };
}

function requestUpdateCustomer(response) {
  return {
    type: UPDATE_CUSTOMER,
    update_customer: response,
  };
}

export function deleteCustomer(id) {
  return (dispatch) => {
    return Axios.delete(`customer?rootInfo=customer&id=${id}`, {
      headers: getloginInfo(),
    }).then((response) => {
      if (response.data != "") {
        dispatch(requestDeleteCustomer(response.data));
      } else {
        dispatch(requestDeleteCustomer([]));
      }
    });
  };
}

function requestDeleteCustomer(response) {
  return {
    type: DELETE_CUSTOMER,
    deletet_customer: response,
  };
}

function getCustomerMasterDetails(id) {
  return (dispatch) => {
    return Axios.get("customerstypes", { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestCustomerMasterDetails(response.data));
        } else {
          dispatch(requestCustomerMasterDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestCustomerMasterDetails(response) {
  return {
    type: CUSTOMER_MASTER,
    customer_master: response,
  };
}

export function getCustomerMaster() {
  return (dispatch) => {
    return dispatch(getCustomerMasterDetails());
  };
}

function addCustomerMasterTypeDetails(post) {
  return (dispatch) => {
    return Axios.post("customerstypes", post, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestaddCustomerMasterTypeDetails(response.data));
        } else {
          dispatch(requestaddCustomerMasterTypeDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestaddCustomerMasterTypeDetails(response) {
  return {
    type: ADD_CUSTOMER_MASTER,
    add_customer_master: response,
  };
}

export function addCustomerMasterType(post) {
  return (dispatch) => {
    return dispatch(addCustomerMasterTypeDetails(post));
  };
}

function updateCustomerMasterTypeDetails(post) {
  return (dispatch) => {
    return Axios.put("customerstypes", post, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestupdateCustomerMasterTypeDetails(response.data));
        } else {
          dispatch(requestupdateCustomerMasterTypeDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestupdateCustomerMasterTypeDetails(response) {
  return {
    type: UPDATE_CUSTOMER_MASTER,
    update_customer_master: response,
  };
}

export function updateCustomerMasterType(post) {
  return (dispatch) => {
    return dispatch(updateCustomerMasterTypeDetails(post));
  };
}

function removeCustomerMasterDetails(post) {
  return (dispatch) => {
    return Axios.delete(`customerstypes?id=${post}`, {
      headers: getloginInfo(),
    })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestdeleteCustomerMasterTypeDetails(response.data));
        } else {
          dispatch(requestdeleteCustomerMasterTypeDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestdeleteCustomerMasterTypeDetails(response) {
  return {
    type: REMOVE_CUSTOMER_MASTER,
    remove_customer_master: response,
  };
}

export function removeCustomerMaster(post) {
  return (dispatch) => {
    return dispatch(removeCustomerMasterDetails(post));
  };
}

function addIncrementMasterPostInfo(id) {
  let webtoken = cryptr.decrypt(cookies.get("wteoben"));
  return (dispatch) => {
    return Axios.post("customerstypes/inc", id, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestIcrementMasterPostDetails(response.data));
        } else {
          dispatch(requestIcrementMasterPostDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestIcrementMasterPostDetails(response) {
  return {
    type: ADD_INCREMENT_MASTER,
    add_increment_master: response,
  };
}

export function addIncrementMasterPost(id) {
  return (dispatch) => {
    return dispatch(addIncrementMasterPostInfo(id));
  };
}

export function getActionReport(
  page_no,
  fromDate,
  toDate,
  rootInfo,
  id,
  cate,
  code,
  orderkey,
  order
) {
  if (order === "descend") {
    order = -1;
  } else {
    order = 1;
  }
  return (dispatch) => {
    return Axios.get(
      `salePerformance/viewvs?from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&ids=${id}&d_cat=${cate}&d_code=${code}&sortBy=${orderkey}&sort=${order}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetActionReportDetails(response.data));
        } else {
          dispatch(requestgetActionReportDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetActionReportDetails(response) {
  return {
    type: ACTION_REPORT,
    action_report: response,
  };
}

export function getDeacStock(
  page_no,
  fromDate,
  toDate,
  rootInfo,
  id,
  cate,
  code
) {
  return (dispatch) => {
    return Axios.get(
      `sku/deadstock?from=${fromDate}&to=${toDate}&rootInfo=${rootInfo}&ids=${id}&d_cat=${cate}&d_code=${code}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetDeadStockDetails(response.data));
        } else {
          dispatch(requestgetDeadStockDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetDeadStockDetails(response) {
  return {
    type: DEAD_STOCKS,
    dead_stocks: response,
  };
}

export function getMultipleSKUbyID(post) {
  return (dispatch) => {
    return Axios.post("sku/skulist", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestGetSuksByID(response.data));
        } else {
          dispatch(requestGetSuksByID([]));
        }
      }
    );
  };
}

function requestGetSuksByID(response) {
  return {
    type: MULTIPLE_SKU_ITEMS,
    multiple_sku_items: response,
  };
}

export function downloadPdf(voucher, id) {
  return (dispatch) => {
    return Axios.get(
      `voucher?${voucher}=${id}`,
      {
        headers: getloginInfo(),
        responseType: "blob",
        Accept: "application/pdf",
      }
      //  return Axios.get(`http://www.orimi.com/pdf-test.pdf`,
    ).then((response) => {
      if (response.data != "") {
        //   let blob = new Blob([response.data], {type: `application/pdf`});
        //    let url = URL.createObjectURL(blob);
        //  window.open(url,'_blank');

        // var file = new Blob([response.data], {type: 'application/pdf'});
        //  var fileURL = URL.createObjectURL(file);
        //  window.open(fileURL,'_blank');

        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        //   console.log("link");
        //  console.log(link);
        //  window.open(`${link}`,'_blank');
        //  window.open(`html.pdf`,'_blank');

        // var file = window.URL.createObjectURL(new Blob([response.data]),{type: 'application/pdf'});
        ///var fileURL = window.URL.createObjectURL(file);
        //window.open(file,'_blank');
      } else {
        // console.log(response);
        // dispatch(requestGetSuksByID([]))
      }
    });
  };
}

function requestPdfData(response) {
  return {
    type: PDF,
    pdf: response,
  };
}

export function getCustomerReport(code) {
  return (dispatch) => {
    return Axios.get(`salePerformance/customer?code=${code}`, {
      headers: getloginInfo(),
    }).then((response) => {
      if (response.data != "") {
        dispatch(requestGetCustomerReport(response.data));
      } else {
        dispatch(requestGetCustomerReport([]));
      }
    });
  };
}

function requestGetCustomerReport(response) {
  return {
    type: GET_CUTOMER_REPORT,
    get_cutomer_report: response,
  };
}

function getEmployeesreport(
  page_no,
  colkey,
  order,
  q,
  limit,
  fromDate,
  toDate,
  root,
  id,
  salesperson
) {
  if (order === "descend") {
    order = -1;
  } else {
    order = 1;
  }
  //&salesPersons=${salesperson}
  return (dispatch) => {
    return Axios.get(
      `User/new?page_no=${page_no}&limit=${limit}&search=${q}&sortBy=${colkey}&sort=${order}&from=${fromDate}&to=${toDate}${getRootInfo(
        root,
        id
      )}`,
      { headers: getloginInfo() }
    )
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestEmployeeDetails(response.data));
        } else {
          dispatch(requestEmployeeDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestEmployeeDetails(response) {
  return {
    type: EMPLOYEE_DETAILS,
    employee_details: response,
  };
}

export function getEmployees(
  page_no,
  colkey,
  order,
  q,
  limit,
  fromDate,
  toDate,
  root,
  id,
  salesperson
) {
  return (dispatch) => {
    return dispatch(
      getEmployeesreport(
        page_no,
        colkey,
        order,
        q,
        limit,
        fromDate,
        toDate,
        root,
        id,
        salesperson
      )
    );
  };
}

function updateEmployeeInfo(post) {
  return (dispatch) => {
    return Axios.put("user/deviceid", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestUpdateUser(response.data));
        } else {
          dispatch(requestUpdateUser([]));
        }
      }
    );
  };
}

function requestUpdateUser(response) {
  return {
    type: UPDATE_USER,
    update_user: response,
  };
}

export function updateEmployee(post) {
  return (dispatch) => {
    return dispatch(updateEmployeeInfo(post));
  };
}

export function getEmployeeReport(code) {
  console.log(code);
  return (dispatch) => {
    return Axios.get(`salePerformance/employee?username=${code}`, {
      headers: getloginInfo(),
    }).then((response) => {
      if (response.data != "") {
        dispatch(requestGetEmployeeReport(response.data));
      } else {
        dispatch(requestGetEmployeeReport([]));
      }
    });
  };
}

function requestGetEmployeeReport(response) {
  return {
    type: GET_EMPLOYEE_REPORT,
    get_employee_report: response,
  };
}

//Prefernaces
export function createVanue(code) {
  console.log(code);
  return (dispatch) => {
    return Axios.post("location", code, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestPostVanue(response.data));
        } else {
          dispatch(requestPostVanue([]));
        }
      }
    );
  };
}

function requestPostVanue(response) {
  return {
    type: CREATE_VANUE,
    create_vanue: response,
  };
}

export function getVanue(code) {
  return (dispatch) => {
    return Axios.get(`location`, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestGetVanue(response.data));
        } else {
          dispatch(requestGetVanue([]));
        }
      }
    );
  };
}

function requestGetVanue(response) {
  return {
    type: GET_VANUE,
    get_vanue: response,
  };
}

export function putVanue(code) {
  return (dispatch) => {
    return Axios.put("location", code, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestPutVanue(response.data));
        } else {
          dispatch(requestPutVanue([]));
        }
      }
    );
  };
}

function requestPutVanue(response) {
  return {
    type: PUT_VANUE,
    put_vanue: response,
  };
}

export function createTax(code) {
  return (dispatch) => {
    return Axios.post("tax", code, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestPostTax(response.data));
        } else {
          dispatch(requestPostTax([]));
        }
      }
    );
  };
}

function requestPostTax(response) {
  return {
    type: CREATE_TAX,
    create_tax: response,
  };
}

export function getTax(code) {
  return (dispatch) => {
    return Axios.get(`tax`, { headers: getloginInfo() }).then((response) => {
      if (response.data != "") {
        dispatch(requestGetTax(response.data));
      } else {
        dispatch(requestGetTax([]));
      }
    });
  };
}

function requestGetTax(response) {
  return {
    type: GET_TAX,
    get_tax: response,
  };
}

export function putTax(code) {
  return (dispatch) => {
    return Axios.put("tax", code, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestPutTax(response.data));
        } else {
          dispatch(requestPutTax([]));
        }
      }
    );
  };
}

function requestPutTax(response) {
  return {
    type: PUT_TAX,
    put_tax: response,
  };
}

export function createMetal(code) {
  return (dispatch) => {
    return Axios.post("metal", code, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestPostMetal(response.data));
        } else {
          dispatch(requestPostMetal([]));
        }
      }
    );
  };
}

function requestPostMetal(response) {
  return {
    type: CREATE_METAL,
    create_metal: response,
  };
}

export function getMetal(code) {
  return (dispatch) => {
    return Axios.get(`metal`, { headers: getloginInfo() }).then((response) => {
      if (response.data != "") {
        dispatch(requestGetMetal(response.data));
      } else {
        dispatch(requestGetMetal([]));
      }
    });
  };
}

function requestGetMetal(response) {
  return {
    type: GET_METAL,
    get_metal: response,
  };
}

export function getPurities(metalType) {
  return (dispatch) => {
    return Axios.get(`purity?metal_type=${metalType}`, {
      headers: getloginInfo(),
    }).then((response) => {
      if (response.data != "") {
        dispatch(requestGetPurity(response.data));
      } else {
        dispatch(requestGetPurity([]));
      }
    });
  };
}

function requestGetPurity(response) {
  return {
    type: GET_PURITY,
    get_purity: response,
  };
}

export function putMetal(code) {
  return (dispatch) => {
    return Axios.put("metal", code, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestPutMetal(response.data));
        } else {
          dispatch(requestPutMetal([]));
        }
      }
    );
  };
}

function requestPutMetal(response) {
  return {
    type: PUT_METAL,
    put_metal: response,
  };
}

export function createCurrancy(code) {
  return (dispatch) => {
    return Axios.post("currency", code, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestPostCurrancy(response.data));
        } else {
          dispatch(requestPostCurrancy([]));
        }
      }
    );
  };
}

function requestPostCurrancy(response) {
  return {
    type: CREATE_CURRANCY,
    create_currancy: response,
  };
}

export function getCurrancy(code) {
  return (dispatch) => {
    return Axios.get(`currency`, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestGetCurrancy(response.data));
        } else {
          dispatch(requestGetCurrancy([]));
        }
      }
    );
  };
}

function requestGetCurrancy(response) {
  return {
    type: GET_CURRANCY,
    get_currancy: response,
  };
}

export function putCurrancy(code) {
  return (dispatch) => {
    return Axios.put("currency", code, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestPutCurrancy(response.data));
        } else {
          dispatch(requestPutCurrancy([]));
        }
      }
    );
  };
}

function requestPutCurrancy(response) {
  return {
    type: PUT_CURRANCY,
    put_currancy: response,
  };
}

export function getSyncData(code) {
  return (dispatch) => {
    return Axios.get(`sync`, { headers: getloginInfo() }).then((response) => {
      if (response.data != "") {
        dispatch(requestGetSync(response.data));
      } else {
        dispatch(requestGetSync([]));
      }
    });
  };
}

function requestGetSync(response) {
  return {
    type: GET_SYNC,
    get_sync: response,
  };
}

export function setStateuploadingSpin(response) {
  return {
    type: SPIN_LOADING,
    spin_loading: response,
  };
}

export function getVersion(code) {
  return (dispatch) => {
    return Axios.get(`ver`, { headers: getloginInfo() }).then((response) => {
      if (response.data != "") {
        dispatch(requestGetVesrion(response.data));
      } else {
        dispatch(requestGetVesrion([]));
      }
    });
  };
}

function requestGetVesrion(response) {
  return {
    type: GET_VERSION,
    get_version: response,
  };
}

export function deleteBranch(id) {
  return (dispatch) => {
    return Axios.delete(`branch?_id=${id}`, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestDeleteBranch(response.data));
        } else {
          dispatch(requestDeleteBranch([]));
        }
      }
    );
  };
}

function requestDeleteBranch(response) {
  return {
    type: DELETET_BRANCH,
    deletet_branch: response,
  };
}

export function deleteFloor(id) {
  return (dispatch) => {
    return Axios.delete(`floor?_id=${id}`, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestDeleteFloor(response.data));
        } else {
          dispatch(requestDeleteFloor([]));
        }
      }
    );
  };
}

function requestDeleteFloor(response) {
  return {
    type: DELETET_FLOOR,
    deletet_floor: response,
  };
}

export function deleteSection(id) {
  return (dispatch) => {
    return Axios.delete(`section?_id=${id}`, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestDeleteSection(response.data));
        } else {
          dispatch(requestDeleteSection([]));
        }
      }
    );
  };
}

function requestDeleteSection(response) {
  return {
    type: DELETET_SECTIONDATA,
    deletet_SectionData: response,
  };
}

export function deleteSubSection(id) {
  return (dispatch) => {
    return Axios.delete(`subsection?_id=${id}`, {
      headers: getloginInfo(),
    }).then((response) => {
      if (response.data != "") {
        dispatch(requestDeleteSubSection(response.data));
      } else {
        dispatch(requestDeleteSubSection([]));
      }
    });
  };
}

function requestDeleteSubSection(response) {
  return {
    type: DELETET_SUBSECTIONDATA,
    deletet_SubSectionData: response,
  };
}

//   export function getUserAccess() {
//     //return dispatch => {
//       return Axios.get(`access`,{ headers: getloginInfo()}
//           )
//         .then(response => {
//           if(response.data!="")
//           {
//             if(response.data.status)
//             {
//             localStorage.setItem('userAccess',JSON.stringify(response.data.data));
//             }
//           //  dispatch(requestGetVesrion(response.data))
//           }
//           else {
//            // dispatch(requestGetVesrion([]))
//           }
//         })
//  // }
// }

export function FTPsyncInvetory(code) {
  return (dispatch) => {
    return Axios.post(`ftp`, code, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestFTPsyncInvetory(response.data));
        } else {
          dispatch(requestFTPsyncInvetory([]));
        }
      }
    );
  };
}

function requestFTPsyncInvetory(response) {
  return {
    type: FTP_SYNC,
    ftp_sync: response,
  };
}

function customerUploadFileResponseto(formData) {
  return (dispatch) => {
    return Axios.post("customer/file", formData, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestcustomerUploadFileResponse(response.data));
        } else {
          dispatch(requestcustomerUploadFileResponse([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            //window.location.href='/';
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestcustomerUploadFileResponse(response) {
  return {
    type: FILE_UPLOAD_CUSTOMER_RESPONSE,
    file_upload_customer_response: response,
  };
}

export function customerUploadFileResponse(response) {
  return (dispatch) => {
    return dispatch(customerUploadFileResponseto(response));
  };
}

function customerUploadReplacceResponseto(formData) {
  return (dispatch) => {
    return Axios.post("customer/replaceCustomer", formData, {
      headers: getloginInfo(),
    })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestcustomerReplace(response.data));
        } else {
          dispatch(requestcustomerReplace([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            //window.location.href='/';
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestcustomerReplace(response) {
  return {
    type: CUSTOMER_REPLACE_DETAILS,
    customer_replace_details: response,
  };
}

export function customerUploadReplacce(response) {
  return (dispatch) => {
    return dispatch(customerUploadReplacceResponseto(response));
  };
}

function getCompanyDetails() {
  return (dispatch) => {
    return Axios.get("company", { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestgetCompanyDetails(response.data));
        } else {
          dispatch(requestgetCompanyDetails([]));
        }
      }
    );
  };
  //   }
}

function requestgetCompanyDetails(response) {
  return {
    type: COMPANY_DETAILS,
    company_details: response,
  };
}

export function getSingleCompany() {
  return (dispatch) => {
    return dispatch(getCompanyDetails());
  };
}

export function updateCompanyDetails(body) {
  return (dispatch) => {
    return Axios.put(`company`, body, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestUpdateCompanyData(response.data));
        } else {
          dispatch(requestUpdateCompanyData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestUpdateCompanyData(response) {
  return {
    type: UPDATE_COMPANY_DATA,
    update_company_data: response,
  };
}

export function getSkuLabel() {
  //Post Api : http://192.168.0.105:8080/skuLabel
  return (dispatch) => {
    return Axios.get("skuLabel", { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestgetSkuLabel(response.data));
        } else {
          dispatch(requestgetSkuLabel([]));
        }
      }
    );
  };
  //   }
}

function requestgetSkuLabel(response) {
  return {
    type: SKULABEL_DETAILS,
    skulabel_details: response,
  };
}

function getDisplayConfigurationsDetails() {
  return (dispatch) => {
    return Axios.get(`displayConfig`, { headers: getloginInfo() })
      .then((response) => {
        if (response.data !== "") {
          dispatch(
            requestgetDisplayConfigurations(response.data.data.displayConfig)
          );
        } else {
          dispatch(requestgetDisplayConfigurations([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetDisplayConfigurations(response) {
  return {
    type: DISPLAY_CONFIG_DETAILS,
    display_config_details: response,
  };
}

export function getDisplayConfigurations() {
  return (dispatch) => {
    return dispatch(getDisplayConfigurationsDetails());
  };
}

function getCompanyMediaDetails(companyId) {
  return (dispatch) => {
    return Axios.get(`media?rootInfo=company&companyId=${companyId}`, {
      headers: getloginInfo(),
    })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetCompanyMedia(response.data));
        } else {
          dispatch(requestgetCompanyMedia([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestgetCompanyMedia(response) {
  return {
    type: MEDIA_CONFIG_DETAILS,
    media_config_details: response,
  };
}

export function getCompanyMedia(companyId) {
  return (dispatch) => {
    return dispatch(getCompanyMediaDetails(companyId));
  };
}

/*
export function getDisplayConfigurations() {
    return dispatch => {
        return Axios.get('',
            {headers: getloginInfo()}
        )
            .then(response => {
                if (response.data != "") {
                    dispatch(requestgetDisplayConfigurations(response.data))
                } else {
                    dispatch(requestgetDisplayConfigurations([]))
                }
            })
    }
}

function requestgetDisplayConfigurations(response) {
    return {
        type: DISPLAY_CONFIG_DETAILS,
        display_config_details: response
    }
}*/

export function updateSkuDetails(body) {
  return (dispatch) => {
    return Axios.put("sku/byId", body, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestPutForUpdateSku(response.data));
        } else {
          dispatch(requestPutForUpdateSku([]));
        }
      }
    );
  };
  //   }
}

function requestPutForUpdateSku(response) {
  return {
    type: SKU_UPDATE,
    sku_update: response,
  };
}

export function getMedia() {
  //Post Api : http://192.168.0.105:8080/skuLabel
  return (dispatch) => {
    return Axios.get("media", { headers: getloginInfo() }).then((response) => {
      if (response.data != "") {
        dispatch(requestgetMedia(response.data));
      } else {
        dispatch(requestgetMedia([]));
      }
    });
  };
  //   }
}

function requestgetMedia(response) {
  return {
    type: GET_MEDIA,
    get_media: response,
  };
}

export function getAppFiltter() {
  //Post Api : http://192.168.0.105:8080/skuLabel
  return (dispatch) => {
    return Axios.get("filter", { headers: getloginInfo() }).then((response) => {
      if (response.data != "") {
        dispatch(requestgetAppFiltter(response.data));
      } else {
        dispatch(requestgetAppFiltter([]));
      }
    });
  };
  //   }
}

function requestgetAppFiltter(response) {
  return {
    type: GET_FILTTER,
    get_filtter: response,
  };
}

export function postFiltter(post) {
  return (dispatch) => {
    return Axios.post("filter", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestpostFiltter(response.data));
        } else {
          dispatch(requestpostFiltter([]));
        }
      }
    );
  };
}

function requestpostFiltter(response) {
  return {
    type: POST_FILTTER,
    post_filtter: response,
  };
}

export function postMedia(post) {
  return (dispatch) => {
    return Axios.post("media", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestpostMedia(response.data));
        } else {
          dispatch(requestpostMedia([]));
        }
      }
    );
  };
}

function requestpostMedia(response) {
  return {
    type: POST_MEDIA,
    post_media: response,
  };
}

export function postSkuLabel(post) {
  return (dispatch) => {
    return Axios.post("skuLabel", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestpostSkuLabel(response.data));
        } else {
          dispatch(requestpostSkuLabel([]));
        }
      }
    );
  };
}

function requestpostSkuLabel(response) {
  return {
    type: POST_SKULABLE,
    post_skulable: response,
  };
}

export function getSettings() {
  return (dispatch) => {
    return Axios.get("settings", { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestgetSettings(response.data));
        } else {
          dispatch(requestgetSettings([]));
        }
      }
    );
  };
  //   }
}

function requestgetSettings(response) {
  return {
    type: GET_SETTINGS,
    get_settings: response,
  };
}

export function postSettings(post) {
  return (dispatch) => {
    return Axios.post("settings", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestpostSettings(response.data));
        } else {
          dispatch(requestpostSettings([]));
        }
      }
    );
  };
}

function requestpostSettings(response) {
  return {
    type: POST_SETTINGS,
    post_settings: response,
  };
}

export function updateSettings(post) {
  return (dispatch) => {
    return Axios.put("settings", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestUpdatetSettings(response.data));
        } else {
          dispatch(requestUpdatetSettings([]));
        }
      }
    );
  };
}

function requestUpdatetSettings(response) {
  return {
    type: UPDATE_SETTINGS,
    update_settings: response,
  };
}
export function getScanSession() {
  return (dispatch) => {
    return Axios.get("sfings", { headers: getloginInfo() }).then((response) => {
      if (response.data != "") {
        dispatch(requestgetScanSession(response.data));
      } else {
        dispatch(requestgetScanSession([]));
      }
    });
  };
  //   }
}

function requestgetScanSession(response) {
  return {
    type: GET_SCAN_SESSION,
    get_scan_session: response,
  };
}

export function postScanSession(post) {
  return (dispatch) => {
    return Axios.post("settfngs", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestpostScanSession(response.data));
        } else {
          dispatch(requestpostScanSession([]));
        }
      }
    );
  };
}

function requestpostScanSession(response) {
  return {
    type: POST_SCAN_SESSION,
    post_scan_session: response,
  };
}

export function updateScanSession(post) {
  return (dispatch) => {
    return Axios.put("ScfnSession", post, { headers: getloginInfo() }).then(
      (response) => {
        if (response.data != "") {
          dispatch(requestUpdatetScanSession(response.data));
        } else {
          dispatch(requestUpdatetScanSession([]));
        }
      }
    );
  };
}

function requestUpdatetScanSession(response) {
  return {
    type: UPDATE_SCAN_SESSION,
    update_scan_session: response,
  };
}

function requestgetSkuMedia(response) {
  return {
    type: GET_SKU_MEDIA,
    get_sku_media: response,
  };
}

function getSKUMediaDetails(skuNumber) {
  return (dispatch) => {
    return Axios.get(`sku/media?sku_number=${skuNumber}`, {
      headers: getloginInfo(),
    })
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetSkuMedia(response.data));
        } else {
          dispatch(requestgetSkuMedia([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // dispatch(push('/'));
            window.location.href = "/";
          } else {
            console.log(error);
          }
        }
      });
  };
}

export function getSKUMedia(skuNumber) {
  return (dispatch) => {
    return dispatch(getSKUMediaDetails(skuNumber));
  };
}
