 import {createSection,getAllSectionList} from '../../../actions/index';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Modal,Form,Table ,List,Col, Icon, Button,message, Row,Switch,Select,AutoComplete,Input} from 'antd';

import socketIOClient from "socket.io-client";
var cookies = require('browser-cookies');
message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});
const { TextArea } = Input;

class AddBranch extends Component {
      constructor(props) 
      {
        super(props);
        this.state = {
            data:[],
            loading: false,
        visible: false
          };
      
      }

      componentDidMount(){   }
      componentDidUpdate(prevProps, prevState) {

        if (prevProps.create_section != this.props.create_section) {
         try {
        
            if(this.props.create_section.status)
            {
                message.success("Add Section sucessfully");
                this.setState({
                    visible:false,
                })
                this.props.form.resetFields();
                const { dispatch } = this.props;  
                dispatch(getAllSectionList("byFloorId",this.props.floorId));
            }else{
                message.error(this.props.create_section.errors.msg);
            } 
              
            } catch (error) {
                console.log(error);
            }
           
        } 
    }
    showModal = () => {
      this.setState({
        visible: true,
      });
    }
   
    handleCancel = () => {
      this.setState({ visible: false });
      this.props.form.resetFields();
    }
  
    handleOk = (e) => {
      
         this.props.form.validateFields((err, values) => {          
           if (err) {
             return;
           }
           let root =  JSON.parse(cookies.get('root'));
           const {dispatch} = this.props;
           values.companyId=root.companyId;
           values.branchId=this.props.branchId;
           values.floorId=this.props.floorId;
          //  console.log(values);
          dispatch(createSection(values)); 
         });
       }


    render () {
        const { visible } = this.state;
        const { getFieldDecorator } = this.props.form;
      
        return (
            <div>
            {this.props.isDisable ?<Button type="primary"  style={{float:"right"}} onClick={this.showModal} disabled>Add Section</Button> :
            <Button type="primary"  style={{float:"right"}} onClick={this.showModal}>Add Section</Button>
          }
           
           
           <Modal
              title="Add Section"
              visible={visible}
              onCancel={this.handleCancel}
              footer={[
                <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                <Button key="submit" type="primary"  onClick={this.handleOk}>
                   Create
                </Button>,
              ]}
             // width={1250}
              style={{paddingBottom:"166px"}}
            >
            
            <Form layout="vertical" onSubmit={this.FormSubmit}>
                  <Form.Item label="Name" className="customFormItem">
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input name !' }],
                  })(
                    <Input />
                  )}
                </Form.Item>
                <Form.Item label="Contact No" className="customFormItem">
                {getFieldDecorator('phone', {
                  rules: [{ required: false, message: 'Please input contact number !' }],
                })(
                  <Input />
                )}
              </Form.Item>

           

          

            <Form.Item label="Address" className="customFormItem">
            {getFieldDecorator('address', {
              rules: [{ required: false, message: 'Please input address!' }],
            })(
                <TextArea rows={3} />
            )}
          </Form.Item>

  
             

         
             
            </Form>
            </Modal>
          </div>
        )
    }
}


AddBranch= Form.create()(AddBranch);
function mapStateToProps(state) {
    const {Common } = state
    const {create_section:create_section } =  Common
    return {create_section}
}
const AppContainer = connect( 
mapStateToProps 
)(AddBranch);
export default AppContainer;
