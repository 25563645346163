import socketIOClient from "socket.io-client";
import { Button, notification } from 'antd';
//import {getUserAccess} from '../../actions/index'
//export default socketIOClient;
var result=window.location.href;
var arr = result.split("/");
var url = arr[0]+"//"+arr[2];
const socket = socketIOClient(url,{autoConnect:true});
const cookies = require('browser-cookies');
const RETRY_INTERVAL = 10000;
let root =  JSON.parse(cookies.get('root'));
let usename= localStorage.getItem('usename');
let webtoken = cookies.get('wteoben');
const key = 'updatable';
notification.config({
    placement: 'bottomLeft',
});


 export function connectSocket() {
    if(webtoken != null){
        console.log("reconnect");
        socket.emit("addUser", JSON.stringify({"root": root,"username":usename,'type':'Web'}));
        socket.on('Notification', (col) => {
            try {
            //  getUserAccess();
           //    console.log(col);
            // if(col.data_code==="701"){
                notification.success({
                    key,
                    message: col.msg,
                  });
            // }
              } catch (e) {
                console.log("error", e);
              }
        });
        // socket.on('connect', () => {
        //     console.log("socket.id");
        //     console.log(socket); // true
        //     console.log(socket.id);
        //   });
        // socket.on('reconnect', (attemptNumber) => {
        // // ...
        // console.log("reconnect");
        // console.log(attemptNumber);
        // });
        socket.on('disconnect', (reason) => {
            console.log("reason");
            console.log(reason);

            RETRY_INTERVAL = RETRY_INTERVAL / 1000;
            retryConnectOnFailure(RETRY_INTERVAL);
           
          //    socket.socket.reconnect();
           
            if (reason === 'io server disconnect') {
              // the disconnection was initiated by the server, you need to reconnect manually
              //socket.connect();
            }
            // else the socket will automatically try to reconnect
         });




         var retryConnectOnFailure = function(retryInMilliseconds) {
          setTimeout(function() {
           connectSocket()
          }, retryInMilliseconds);
        }



    }
}
connectSocket();
// socket.export.connectSocket=connectSocket();
export default socket;

export function connectSocke1() {
    connectSocket();
}
