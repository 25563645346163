import React, {Component} from 'react'
import {connect} from "react-redux";
import {Form, Table} from "antd";
import Edit from "./edit";
import {bindActionCreators} from "redux";

import "./configurationDisplay.css";
import {listConfigurationDisplay} from "./ACTIONS/ListConfigurationDisplay";

class configurationDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            updateModal: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("display config ----- ", this.props.configurationList)
        this.getKeyaddedData(this.props.configurationList);
    }

    componentDidMount() {
        this.props.listConfigurationDisplayAction()
        // console.log("display config ----- ", this.props.configurationList)
        // console.log("object 1 ---- ",this.props.configurationList[0])
        // this.getKeyaddedData(this.props.configurationList);
    }

    getKeyaddedData = (data) => {

        data.map((x, index) => {
            // x.key = index;
            if (x.screen === "DetailView") {
                x.fields.sort((a, b) => (a.isSelected) ? -1 : ((b.isSelected) ? 1 : 0));
                // console.log("Fields -- ", x.fields);
                x.fields.sort((a, b) => a.key > b.key ? 1 : -1)
                // console.log("Fields ------ ", x.fields);
                localStorage.setItem(x.screen, JSON.stringify(x.fields));
            }
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {visible} = this.state;
        //  console.log("configurationList :",this.props.configurationList)


        const columns = [
            {
                title: 'Screen',
                dataIndex: 'screen',
                key: 'screen',
            },
            {
                title: 'Max Selected',
                dataIndex: 'maxSelected',
                key: 'maxSelected',
            },
            {
                title: 'Fields',
                dataIndex: 'fields',
                key: 'fields',
                height: '240',
                scroll: {y: 400},
                render: (text, record) => (text.map((item, index) => (item.isSelected === true ?
                    <span key={index} className="chip">{item.header}</span> : ""))),
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (text, record, index) => {
                    let {isActive} = record;
                    // console.log("index :",index)
                    //   if(this.state.pageNo === 3){
                    //     index = index+10;
                    //  //  record = record +1
                    //     console.log("in if :",index)
                    //   }
                    return (
                        <span>
                    <Edit record={record}/>
              </span>
                    );
                },
            }
        ];
        return (
            <div>
                <Table dataSource={this.props.configurationList} columns={columns} pagination={true}></Table>
            </div>
        )
    }
}

configurationDisplay = Form.create()(configurationDisplay);
const mapStateToProps = state => {
    return {
        configurationList: state.listConfigurationDisplay.configurationList
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            listConfigurationDisplayAction: listConfigurationDisplay
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(configurationDisplay);



