import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAllBrachList,
  getAllFloorList,
  getAllSalesPerson,
  getAllSectionList,
  getBilling,
  getCustomer,
} from "../../../actions/index";
import { getExportSale } from "../../../actions/dataexport";
import moment from "moment";
import SalesDetailsView from "./viewSalesDetails";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import ReactExport from "react-data-export";
import Gears from "../../../assets/images/gears.gif";
import CountUp from "react-countup";
import Socket from "./../../../containers/App/global";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import { sortDirections } from "../../../containers/App/commanFunction";

const cookies = require("browser-cookies");
const { RangePicker } = DatePicker;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Option } = Select;

class sale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tableLoading: true,
      totalData: 0,
      search: "",
      visible: false,
      dataforExport: [],
      branchList: [],
      textForSelect: "",
      sectionList: [],
      floorList: [],
      floorId: "",
      branchId: "",
      sectionId: "",
      allSalesPersons: [],
      btnsearch: true,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    //dispatch(getCustomer());
    let usertype = localStorage.getItem("type_user");
    let root = JSON.parse(cookies.get("root"));
    let page_no = 0;
    let limit = 1;
    dispatch(getCustomer(page_no, limit));
    if (usertype === "branchAdmin") {
      this.setState({
        rootInfo: "floor",
        rootInfoEqual: "branch",
        textForSelect: "Select Floor",
        id: root.branchId,
        selectedId: root.branchId,
        selectedRootInfoEqual: "branch",
      });
      dispatch(getAllFloorList("id"));
      dispatch(getAllSalesPerson("branch", root.branchId));
    }
    if (usertype === "companyAdmin") {
      this.setState({
        rootInfo: "branch",
        rootInfoEqual: "company",
        textForSelect: "Select Branch",
        id: root.companyId,
        selectedId: root.companyId,
        selectedRootInfoEqual: "company",
      });
      dispatch(getAllBrachList("id"));
      dispatch(getAllSalesPerson("company", root.companyId));
    }
    if (usertype === "floorAdmin") {
      this.setState({
        rootInfo: "section",
        rootInfoEqual: "floor",
        textForSelect: "Select Section",
        id: root.floorId,
        selectedId: root.floorId,
        selectedRootInfoEqual: "floor",
      });
      dispatch(getAllSectionList("id"));
      dispatch(getAllSalesPerson("floor", root.floorId));
    }

    Socket.on("Notification", (col) => {
      try {
        if (col.data_code === "1401") {
          let newData = col.data_obj;
          let Data = this.state.data;
          let skuitemarray = newData.skuItemsIssue;

          for (let index = 0; index < skuitemarray.length; index++) {
            newData.skuItemsIssue[index].uniqeId = index + 1;
          }
          Data.unshift(newData);
          let pagination = {};
          pagination.total = this.state.pagination.total + 1;
          this.setState({
            data: Data,
            totalData: this.state.totalData + 1,
            pagination,
          });
        }
      } catch (e) {
        console.log("error", e);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.billing_details != this.props.billing_details) {
      if (this.props.billing_details.status) {
        let json = this.props.billing_details.data_array;
        for (let index = 0; index < json.length; index++) {
          for (
            let index1 = 0;
            index1 < json[index].skuItemsIssue.length;
            index1++
          ) {
            const element = json[index].skuItemsIssue[index1];
            json[index].skuItemsIssue[index1].uniqeId = index1 + 1;
          }
        }
        let pagination = {};
        pagination.total = this.props.billing_details.data.totalBill;
        pagination.pageSize = 10;
        this.setState({
          data: json,
          pagination,
          totalData: this.props.billing_details.data.totalBill,
        });
        // console.log("json");
        // console.log(json);
      }
      this.setState({
        tableLoading: false,
      });
    }

    try {
      if (prevProps.branch_list != this.props.branch_list) {
        if (this.props.branch_list.status) {
          this.setState({
            branchId: this.props.branch_list.data_array[0]._id,
            branchList: this.props.branch_list.data_array,
          });
          if (this.props.branch_list.data_array.length >= 1) {
            const { dispatch } = this.props;
            let page_no = 0;
            dispatch(
              getBilling(
                0,
                this.state.id,
                this.state.rootInfoEqual,
                "",
                "",
                "",
                "",
                "",
                "",
                ""
              )
            );
            //dispatch(getSKUByRootInfoAndId(this.props.branch_list.data_array[0]._id,this.state.rootInfo));
          }
        } else {
          this.setState({
            branchList: [],
          });
        }
      }

      if (prevProps.floor_list != this.props.floor_list) {
        if (this.props.floor_list.status) {
          this.setState({
            floorId: this.props.floor_list.data_array[0]._id,
            floorList: this.props.floor_list.data_array,
          });
          if (this.props.floor_list.data_array.length >= 1) {
            const { dispatch } = this.props;
            dispatch(
              getBilling(
                0,
                this.state.id,
                this.state.rootInfoEqual,
                "",
                "",
                "",
                "",
                "",
                "",
                ""
              )
            );
          }
        } else {
          this.setState({
            floorList: [],
          });
        }
      }

      if (prevProps.section_list != this.props.section_list) {
        if (this.props.section_list.status) {
          this.setState({
            sectionId: this.props.section_list.data_array[0]._id,
            sectionList: this.props.section_list.data_array,
          });
          if (this.props.section_list.data_array.length >= 1) {
            const { dispatch } = this.props;
            dispatch(
              getBilling(
                0,
                this.state.id,
                this.state.rootInfoEqual,
                "",
                "",
                "",
                "",
                "",
                "",
                ""
              )
            );
          }
        } else {
          this.setState({
            sectionList: [],
          });
        }
      }

      if (prevProps.customer_details != this.props.customer_details) {
        if (this.props.customer_details.status) {
          let json = this.props.customer_details.data_array;
          let cOptionArray = [];
          for (let index1 = 0; index1 < json.length; index1++) {
            const element = json[index1];
            cOptionArray.push(
              <Option
                key={index1.toString(36) + index1 + "cust"}
                value={element.code}>
                {element.familyName} {element.firstName}
              </Option>
            );
          }
          this.setState({
            customer_details: json,
            cName: cOptionArray,
          });
        }
      }

      if (prevProps.all_sales_persons != this.props.all_sales_persons) {
        try {
          if (this.props.all_sales_persons.status) {
            this.setState({
              allSalesPersons: this.props.all_sales_persons.data_array,
            });
            //const { dispatch } = this.props;
            //dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,this.props.all_sales_persons.data_array[0].empId));
          }
          this.setState({
            tableLoading: false,
          });
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }

    if (prevProps.export_sales_details != this.props.export_sales_details) {
      //console.log(this.props.export_sales_details);
      try {
        if (this.props.export_sales_details.status) {
          let DataForSale = this.props.export_sales_details.data_array;
          // for (let index = 0; index < json.length; index++) {
          //   for (
          //     let index1 = 0;
          //     index1 < json[index].skuItemsIssue.length;
          //     index1++
          //   ) {
          //     const element = json[index].skuItemsIssue[index1];
          //     console.log(json);
          //     json[index].skuItemsIssue[index1].skuItem = JSON.parse(
          //       json[index].skuItemsIssue[index1].skuItem
          //     );
          //     json[index].skuItemsIssue[index1].uniqeId = index1 + 1;
          //   }
          // }

          this.setState({
            dataforExport: this.state.dataforExport.concat(DataForSale),
            loading: false,
          });

          if (
            this.props.export_sales_details.data.totalSale ==
            this.state.dataforExport.length
          ) {
            this.setState({
              loading: false,
            });
          } else {
            if (this.props.export_sales_details.data.totalSale > 100) {
              const { dispatch } = this.props;
              let page =
                parseInt(this.props.export_sales_details.data.currentPage) + 1;
              dispatch(getExportSale(page, 100));
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dispatch } = this.props;
        // let userdata=values;
      }
    });
  };

  onChangeBranchRadio = (e) => {
    //  this.setState({
    //   tableLoading:true
    //  })
    //   const { dispatch } = this.props;
    //   if(e==this.state.id)
    //   {
    //     dispatch(getSale(0, e, this.state.rootInfoEqual));
    //   }else{
    //     dispatch(getSale(0, e, this.state.rootInfo));
    //   }
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.setState({
      tableLoading: true,
    });
    this.props.form.validateFields((err, values) => {
      // console.log('values2',this.props.form);

      const { dispatch } = this.props;
      let fromDate = "";
      let toDate = "";
      if (values.date != undefined) {
        if (values.date.length > 0) {
          // fromDate=values.date[0].toISOString();
          // toDate=values.date[1].toISOString();
          let fromDate1 = moment(values.date[0])
            .utc()
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
          let toDate1 = moment(values.date[1])
            .utc()
            .set({
              hour: 23,
              minute: 59,
              second: 0,
              millisecond: 0,
            });
          fromDate = fromDate1.toISOString();
          toDate = toDate1.toISOString();
        }
      }
      this.setState({
        fromDate,
        toDate,
      });
      let issuedBy = values._issuedBy;
      let customers = values.customers;
      if (issuedBy === undefined) {
        issuedBy = "";
      }
      if (customers === undefined) {
        customers = "";
      }

      if (values.rootid == this.state.id) {
        dispatch(
          getBilling(
            pager.current - 1,
            values.rootid,
            this.state.rootInfoEqual,
            fromDate,
            toDate,
            issuedBy,
            customers,
            sorter.columnKey,
            sorter.order,
            this.state.search
          )
        );
      } else {
        dispatch(
          getBilling(
            pager.current - 1,
            values.rootid,
            this.state.rootInfo,
            fromDate,
            toDate,
            issuedBy,
            customers,
            sorter.columnKey,
            sorter.order,
            this.state.search
          )
        );
      }
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
    const { dispatch } = this.props;
    dispatch(getExportSale(0, 100));
  };

  openPdf = (record) => {
    window.open(record);
    // const { dispatch } = this.props;
    // dispatch(downloadPdf("sale",record));
  };

  btnFiltter = () => {
    this.setState({
      tableLoading: true,
    });

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const { dispatch } = this.props;
      let fromDate = "";
      let toDate = "";
      const pager = { ...this.state.pagination };
      pager.current = 1;
      pager.pageSize = 10;

      this.setState({
        pagination: pager,
        table_loading: true,
        pageSizelimit: 10,
        currantPage: pager.current - 1,
      });
      if (values.date != undefined) {
        if (values.date.length > 0) {
          // fromDate=values.date[0].toISOString();
          // toDate=values.date[1].toISOString();
          let fromDate1 = moment(values.date[0])
            .utc()
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
          let toDate1 = moment(values.date[1])
            .utc()
            .set({
              hour: 23,
              minute: 59,
              second: 0,
              millisecond: 0,
            });
          fromDate = fromDate1.toISOString();
          toDate = toDate1.toISOString();
        }
      }
      this.setState({
        fromDate,
        toDate,
      });
      let issuedBy = values._issuedBy;
      let customers = values.customers;
      if (issuedBy === undefined) {
        issuedBy = "";
      }
      if (customers === undefined) {
        customers = "";
      }
      if (values.rootid == this.state.id) {
        dispatch(
          getBilling(
            0,
            values.rootid,
            this.state.rootInfoEqual,
            fromDate,
            toDate,
            issuedBy,
            customers,
            "",
            "",
            this.state.search
          )
        );
      } else {
        dispatch(
          getBilling(
            0,
            values.rootid,
            this.state.rootInfo,
            fromDate,
            toDate,
            issuedBy,
            customers,
            "",
            "",
            this.state.search
          )
        );
      }
    });
  };

  searchOnchange = (e) => {
    this.setState({ search: e.target.value, btnsearch: false });
    if (e.target.value.length == 0) {
      this.setState({
        tableLoading: true,
        btnsearch: true,
      });
      this.props.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        const { dispatch } = this.props;
        let fromDate = "";
        let toDate = "";
        if (values.date != undefined) {
          if (values.date.length > 0) {
            // fromDate=values.date[0].toISOString();
            // toDate=values.date[1].toISOString();
            let fromDate1 = moment(values.date[0])
              .utc()
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              });
            let toDate1 = moment(values.date[1])
              .utc()
              .set({
                hour: 23,
                minute: 59,
                second: 0,
                millisecond: 0,
              });
            fromDate = fromDate1.toISOString();
            toDate = toDate1.toISOString();
          }
        }
        this.setState({
          fromDate,
          toDate,
        });
        let issuedBy = values._issuedBy;
        let customers = values.customers;
        if (issuedBy === undefined) {
          issuedBy = "";
        }
        if (customers === undefined) {
          customers = "";
        }
        this.setState({
          tableLoading: true,
        });

        if (values.rootid == this.state.id) {
          dispatch(
            getBilling(
              0,
              values.rootid,
              this.state.rootInfoEqual,
              fromDate,
              toDate,
              issuedBy,
              customers,
              "",
              "",
              ""
            )
          );
        } else {
          dispatch(
            getBilling(
              0,
              values.rootid,
              this.state.rootInfo,
              fromDate,
              toDate,
              issuedBy,
              customers,
              "",
              "",
              ""
            )
          );
        }
      });
    }
  };

  onSerach = (e) => {
    //this.setState({search: e.target.value})
    if (this.state.search.length > 1) {
      this.props.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        const { dispatch } = this.props;
        let fromDate = "";
        let toDate = "";
        if (values.date != undefined) {
          if (values.date.length > 0) {
            // fromDate=values.date[0].toISOString();
            // toDate=values.date[1].toISOString();
            let fromDate1 = moment(values.date[0])
              .utc()
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              });
            let toDate1 = moment(values.date[1])
              .utc()
              .set({
                hour: 23,
                minute: 59,
                second: 0,
                millisecond: 0,
              });
            fromDate = fromDate1.toISOString();
            toDate = toDate1.toISOString();
          }
        }
        this.setState({
          fromDate,
          toDate,
        });
        let issuedBy = values._issuedBy;
        let customers = values.customers;
        if (issuedBy === undefined) {
          issuedBy = "";
        }
        if (customers === undefined) {
          customers = "";
        }
        this.setState({
          tableLoading: true,
        });

        if (values.rootid == this.state.id) {
          dispatch(
            getBilling(
              0,
              values.rootid,
              this.state.rootInfoEqual,
              fromDate,
              toDate,
              issuedBy,
              customers,
              "",
              "",
              this.state.search
            )
          );
        } else {
          dispatch(
            getBilling(
              0,
              values.rootid,
              this.state.rootInfo,
              fromDate,
              toDate,
              issuedBy,
              customers,
              "",
              "",
              this.state.search
            )
          );
        }
      });
    }
  };

  onClearFiltter = (e) => {
    this.setState({
      tableLoading: true,
      search: "",
    });
    const { dispatch } = this.props;
    dispatch(
      getBilling(
        0,
        this.state.id,
        this.state.rootInfoEqual,
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      )
    );
    this.props.form.resetFields();
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      loading: true,
      dataforExport: [],
    });
  };

  handleChange = (value) => {
    let data = this.state.customer_details;
    data = data.filter((row) => {
      return (
        row.firstName.toUpperCase().includes(value.toUpperCase()) ||
        String(row.familyName.name)
          .toUpperCase()
          .includes(value.toUpperCase())
      );
    });

    let cOptionArray = [];
    let json = data;
    for (let index1 = 0; index1 < json.length; index1++) {
      const element = json[index1];
      cOptionArray.push(
        <Option
          key={index1.toString(36) + index1 + "cust"}
          value={element.code}>
          {element.familyName} {element.firstName}
        </Option>
      );
    }
    this.setState({
      cName: cOptionArray,
    });
  };
  // openPdf=(record)=>{
  //   let {_issueBy,root,saleId}=record;
  //   let username = _issueBy.split(',');
  //   let result=window.location.href;
  //   let arr = result.split("/");
  //   let domainname = arr[0]+"//"+arr[2];
  //   let url=`${domainname}/res/${root.companyId}-${username[0]}/${saleId}.pdf`;
  //   let win = window.open(url, '_blank');
  //   win.focus();
  // }
  render() {
    var heightd = window.innerHeight - 480;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    this.columns = [
      {
        title: "BILLING ID",
        dataIndex: "billId",
        sorter: (a, b) => {},
        width: "20%",
        render: (text, record) => {
          const { finger_index } = record;
          let textprint = "";
          try {
            var s = text;
            let lastlength = s.substring(0, s.lastIndexOf("/"));
            let lasti = s.lastIndexOf("/");
            let ans = s.substring(lasti + 1);
            if (isNaN(ans)) {
              textprint = lastlength;
            } else {
              textprint = text;
            }
          } catch (error) {
            console.log(error);
          }
          return <span>{textprint}</span>;
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "customer.familyName",
        sorter: (a, b) => {},
        width: "20%",
        render: (text, record) => {
          let { customer } = record;
          let { firstName, familyName } = customer;
          if (familyName === undefined) {
            familyName = "";
          }
          if (firstName === undefined) {
            firstName = "";
          }
          return <span>{familyName + " " + firstName}</span>;
        },
      },
      // {
      //   title: 'Discount',
      //   dataIndex: 'discount',
      //   width: '10%',
      //   render: (text, record) => {
      //     const { customer } = record;
      //     return (
      //       <span>
      //           {text[0]}
      //       </span>
      //     );
      //   }
      // },
      {
        title: "SALE DATE",
        dataIndex: "createdAt",
        sorter: (a, b) => {},
        width: "15%",
        ///moment(utcDate).isUtc();{moment(text).utc(false).format("DD-MM-YYYY")}
        render: (text, record) => {
          const { customer } = record;
          return <span>{moment(text).format("DD-MM-YYYY")}</span>;
        },
      },
      {
        title: "ISSUED BY",
        dataIndex: "empName",
        sorter: (a, b) => {},
        width: "20%",
        render: (text, record) => {
          // const { customer } = record;
          // let name = text.split(',');
          // return <span>{name[1]}</span>;
          return <span>{text}</span>;
        },
      },
      {
        title: "ACTION",
        dataIndex: "skuItemsIssue",
        width: "10%",
        align: "center",
        render: (text, record) => {
          let {
            billId,
            created_at,
            customer,
            pdfPath,
            description,
            _id,
            summary,
          } = record;
          var s = billId;
          s = s.substring(0, s.lastIndexOf("/"));
          // let billId = billId.substring(0, billId.lastIndexOf('/'));
          const qtyw = "Bill ID : " + s;
          const datef = moment(created_at).format("DD-MM-YYYY");
            let { firstName, familyName, companyName, email, contactNumber } = customer;
            if (familyName === undefined && firstName === undefined) {
                familyName = '';
                firstName = ''
            } else if (familyName === undefined) {
                familyName = ''
            } else if (firstName === undefined) {
                firstName = ''
            }
            if (pdfPath === "") {
                pdfPath = undefined
            }
          return (
            <span style={{ display: "inline" }}>
              <SalesDetailsView
                modeltitle={qtyw}
                created_at={datef}
                companyname={companyName}
                customer={customer}
                customername={familyName + " " + firstName}
                description={description}
                skuitemsissue={text}
                summary={summary}
                billId={billId}
                createdAt={created_at}
              />
              {/** <Icon type="file-pdf" onClick={()=>this.openPdf(record)}  style={{ cursor: 'pointer',fontSize: 20,paddingLeft:"10px"}}/>
               */}

              {/* {pdfPath != undefined ?
                                <Icon type="file-pdf" onClick={() => this.openPdf(pdfPath)}
                                      style={{cursor: 'pointer', fontSize: 20, paddingLeft: "10px"}}/>
                                : pdfPath === "" ? "" :
                                    <Icon type="file-pdf" style={{cursor: 'no-drop', fontSize: 20, paddingLeft: "10px"}}
                                          disabled={true}/>}*/}
            </span>
          );
        },
      },
    ];
    let data = this.state.data;
    // if (this.state.search) {
    //   data = data.filter(row => {
    //     let created_at_formate = moment(row.created_at).format("DD-MM-YYYY");
    //     return (
    //       row.saleId.toUpperCase().includes(this.state.search.toUpperCase()) ||
    //       String(row.customer.name)
    //         .toUpperCase()
    //         .includes(this.state.search.toUpperCase()) ||
    //       String(created_at_formate)
    //         .toUpperCase()
    //         .includes(this.state.search.toUpperCase()) ||
    //       String(row._issueBy)
    //         .toUpperCase()
    //         .includes(this.state.search.toUpperCase())
    //     );
    //     //row.name.toUpperCase().includes(this.state.search.toUpperCase())
    //   });
    // }
    const { dataforExport } = this.state;
    const fileGenerate = dataforExport.map(function(fname, index) {
      try {
        let fnamefile = fname.billId;
        fnamefile = fnamefile.substring(0, fnamefile.lastIndexOf("/"));
        fnamefile = fnamefile.replace(/[/]/g, "_");
        let persheetArray = fname.skuItemsIssue;
        var dataForSheet = [];
        // debugger
        for (let i = 0; i < persheetArray.length; i++) {
          let secondArray = [];
          if (persheetArray.length >= 1) {
            secondArray.push(persheetArray[i].sku_number);
            secondArray.push(persheetArray[i].design_code);
            secondArray.push(persheetArray[i].design_color);
            secondArray.push(persheetArray[i].diamond_quantity);
            secondArray.push(persheetArray[i].color_stone_weight);
            secondArray.push(persheetArray[i].gross_weight);
            secondArray.push(persheetArray[i].net_weight);
            secondArray.push(persheetArray[i].purity);
            secondArray.push(persheetArray[i].labour_value);
            secondArray.push(persheetArray[i].sales_value);
            dataForSheet.push(secondArray);
          } else {
            dataForSheet.push(persheetArray[i].sku_number);
            dataForSheet.push(persheetArray[i].design_code);
            dataForSheet.push(persheetArray[i].design_color);
            dataForSheet.push(persheetArray[i].diamond_quantity);
            dataForSheet.push(persheetArray[i].color_stone_quantity);
            dataForSheet.push(persheetArray[i].net_weight);
            dataForSheet.push(persheetArray[i].gross_weight);
            dataForSheet.push(persheetArray[i].purity);
            dataForSheet.push(persheetArray[i].labour_value);
            dataForSheet.push(persheetArray[i].sales_value);
          }
        }
        let customerName = [];
        let companyName = [];
        let Description = [];
        let QuatationId = [];
        let quart_name = fname.billId;
        quart_name = quart_name.substring(0, quart_name.lastIndexOf("/"));
        QuatationId.push(quart_name);
        companyName.push(fname.customer.name);
        companyName.push("");
        companyName.push("");
        companyName.push("");
        companyName.push(fname.customer.companyName);
        companyName.push("");
        companyName.push("");
        companyName.push("");
        companyName.push("");
        companyName.push(moment(fname.created_at).format("DD-MM-YYYY"));
        Description.push(fname.description);

        let datasetFor1 = [
          {
            xSteps: 4,
            ySteps: 0,
            columns: ["Bill ID"],
            data: [QuatationId],
          },
          {
            xSteps: 0,
            ySteps: 1,
            columns: [
              { title: "Customer Name", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "Company Name", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "Date", width: { wpx: 120 } },
            ],
            data: [companyName],
          },
          // {
          //   xSteps: 1,
          //   ySteps: 1,
          //   columns: [{title: "Description", width: {wpx: 120}}],
          //   data:[Description]
          // },
          {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            // wpx: 180,
            columns: [
              { title: "SKU Number", width: { wpx: 120 } },
              { title: "Design Code", width: { wpx: 120 } },
              { title: "Design Color", width: { wpx: 120 } },
              { title: "Diamond QTY", width: { wpx: 120 } },
              { title: "Color Stone Wt", width: { wpx: 140 } },
              { title: "Gross Wt", width: { wpx: 120 } },
              { title: "Net Wt", width: { wpx: 120 } },
              { title: "Purity", width: { wpx: 120 } },
              { title: "Labour Value", width: { wpx: 120 } },
              { title: "Sales Value", width: { wpx: 120 } },
            ],
            data: dataForSheet,
          },
        ];
        return (
          <ExcelSheet
            key={index}
            //data={fname.skuItemsIssue}
            name={`${fnamefile}`}
            dataSet={datasetFor1}
          />
        );
      } catch (error) {
        console.log(error);
      }
    });
    const formItemLayout = {
      //labelCol: { span: 24 },
      //   labalAlign:{marginLeft: "15px"},
      //wrapperCol: { span: 24 },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    return (
      <div>
        <Card
          className='transaction-top-menu-card'
          bodyStyle={{ padding: "0" }}
          bordered={false}>
          <Row>
            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}
              style={{
                textAlign: "center",
              }}>
              <p className='transaction-title'>Transactions</p>
              <h1 className='transaction-sub-title'>Billing</h1>
            </Col>

            <Col lg={12} md={12} sm={24} xs={24}>
              <Divider
                type='vertical'
                className='vertical-divider-transaction'
              />
              <h1 className='total-text'>
                <CountUp start={0} end={this.state.totalData} />
              </h1>
            </Col>

            <Col lg={8} md={8} sm={24} xs={24}>
              <p
                style={{ marginBottom: "5px" }}
                className='transaction-serach-text text-color-8c8c8c'>
                Search
              </p>
              <Input
                value={this.state.search}
                placeholder='-- Search Voucher'
                style={{ float: "left", width: "70%" }}
                //onChange={e => this.setState({ search: e.target.value })}
                onChange={this.searchOnchange}
              />
              <Button
                onClick={this.onSerach}
                style={{ marginLeft: "11px" }}
                type='primary'
                disabled={this.state.btnsearch}>
                Search
              </Button>
            </Col>
          </Row>
        </Card>

        <Form hideRequiredMark={true} colon={false} labelAlign={"left"}>
          <Card
            className='filtter-card'
            bordered={false}
            bodyStyle={{
              padding: "10px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}>
            <Row>
              <Col lg={2} md={2} sm={24} xs={24} className='text-aligin-center'>
                {/**   <Icon style={{ fontSize: '34px', color: '#aaaebe', paddingTop: "15px" }} type="filter" theme="filled" />*/}
                <img src={FilterIcon} className='filter-icon' />
              </Col>
              <Col lg={5} md={5} sm={24} xs={24}>
                <Form.Item label={`Date`} {...formItemLayout}>
                  {getFieldDecorator(`date`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <RangePicker
                      format='DD-MM-YYYY'
                      placeholder={["From ", "To"]}
                      separator='-'
                      style={{ minWidth: "100%" }}
                      allowClear={false}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={4} md={4} sm={24} xs={24}>
                {this.state.rootInfo === "branch" &&
                this.state.branchId != "" ? (
                  <Form.Item
                    label={this.state.textForSelect}
                    {...formItemLayout}>
                    {getFieldDecorator(`rootid`, {
                      initialValue: this.state.id,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        placeholder='Please select branch'
                        onChange={this.onChangeBranchRadio}
                        style={{ minWidth: "100%" }}>
                        <Option key={this.state.id} value={this.state.id}>
                          All Branch
                        </Option>
                        {this.state.branchList.map((data, index) => (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  ""
                )}

                {this.state.rootInfo === "floor" && this.state.floorId != "" ? (
                  <Form.Item
                    label={this.state.textForSelect}
                    {...formItemLayout}>
                    {getFieldDecorator(`rootid`, {
                      initialValue: this.state.id,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        placeholder='Please select floor'
                        onChange={this.onChangeBranchRadio}
                        style={{ minWidth: "100%" }}>
                        <Option key={this.state.id} value={this.state.id}>
                          All Floor
                        </Option>
                        {this.state.floorList.map((data, index) => (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  ""
                )}

                {this.state.rootInfo === "section" &&
                this.state.sectionId != "" ? (
                  <Form.Item
                    label={this.state.textForSelect}
                    {...formItemLayout}>
                    {getFieldDecorator(`rootid`, {
                      initialValue: this.state.id,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        placeholder='Please select section'
                        onChange={this.onChangeBranchRadio}
                        style={{ minWidth: "100%" }}>
                        <Option key={this.state.id} value={this.state.id}>
                          All section
                        </Option>
                        {this.state.sectionList.map((data, index) => (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  ""
                )}
              </Col>

              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label='Employee' {...formItemLayout}>
                  {getFieldDecorator(`_issuedBy`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      placeholder='- - Select - -'
                      style={{ minWidth: "100%" }}>
                      {this.state.allSalesPersons.map((user) => (
                        <Option key={user._id.empId} value={user._id.empId}>
                          {user._id.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label={`Customer`} {...formItemLayout}>
                  {getFieldDecorator(`customers`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={false}
                      placeholder='- - Select - -'
                      style={{ minWidth: "100%" }}
                      onSearch={this.handleChange}>
                      {this.state.cName}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col lg={1} md={1} sm={24} xs={24}></Col>
              <Col lg={4} md={4} sm={24} xs={24}>
                <div
                  style={{
                    marginTop: "31px",
                    paddingRight: "9px",
                    float: "right",
                  }}>
                  <Button
                    style={{ float: "left" }}
                    onClick={this.btnFiltter}
                    type='primary'>
                    Filter
                  </Button>
                  <Button
                    onClick={this.onClearFiltter}
                    className='transaction-clear-btn'
                    ghost>
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Form>
        {/**
                 <h2 className="title gx-mb-4">
                 Sales
                 </h2>*/}
        <Row>
          <Col md={24} sm={24} xs={24}>
            {/**   <div
                         className="certain-category-search-wrapper"
                         style={{ marginBottom: "8px" }}
                         >
                         <Input
                         value={this.state.search}
                         placeholder="Search .."
                         style={{ float: "left", width: "95%" }}
                         onChange={e => this.setState({ search: e.target.value })}
                         suffix={<Icon type="search" className="certain-category-icon" />}
                         />

                         <center>
                         <Icon
                         type="cloud-download"
                         onClick={this.showModal}
                         style={{ fontSize: "27px", cursor: "pointer" }}
                         />
                         </center>
                         </div>*/}
            <Card
              style={{ marginBottom: "0px" }}
              bodyStyle={{ padding: "10px 10px", paddingBottom: "0px" }}
              bordered={false}>
              <Table
                className='gx-table-responsive'
                loading={this.state.tableLoading}
                columns={this.columns}
                dataSource={data}
                size={"small"}
                scroll={{ y: heightd }}
                rowKey={(record) => record._id}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                sortDirections={sortDirections}
              />
            </Card>
          </Col>
          {/**  <Col md={6} sm={24} xs={24}>
                     <Card  size="small"style={{padding: "20px 20px;"}}
                     title="Filter">
                     <p>{this.state.textForSelect}</p>

                     {this.state.rootInfo==="branch" && this.state.branchId !='' ?

             <Select placeholder="Please select branch"   defaultValue={this.state.branchId}  onChange={this.onChangeBranchRadio} style={{minWidth:"200px"}} >         
             {this.state.branch_list.map((data, index) =>
             <Option key={data._id} value={data._id}>{data.name}</Option>         
               )}
           </Select>   
          
          :""}

                     {this.state.rootInfo==="floor" && this.state.floorId !='' ?
              
          <Select placeholder="Please select floor"   defaultValue={this.state.floorId}  onChange={this.onChangeBranchRadio} style={{minWidth:"200px"}}   >         
          {this.state.floor_list.map((data, index) =>
          <Option key={data._id} value={data._id}>{data.name}</Option>         
            )}
        </Select>  
        
        :""}

                     {this.state.rootInfo==="section" && this.state.sectionId !='' ?
        
        <Select placeholder="Please select section"   defaultValue={this.state.sectionId}  onChange={this.onChangeBranchRadio}  style={{minWidth:"200px"}} >         
        {this.state.section_list.map((data, index) =>
        <Option key={data._id} value={data._id}>{data.name}</Option>         
          )}
      </Select>   :""}

                     </Card>




                     </Col>*/}
        </Row>
        <Modal
          loading
          title={
            loading ? `Data is processing please wait ...` : `Sale File Created`
          }
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[
            <Button key='back' onClick={this.handleCancel}>
              Cancel
            </Button>,
            <ExcelFile
              key='tesingkey'
              filename={`Sale Details - ${new Date()}`}
              element={
                loading ? (
                  <Button
                    key='submit'
                    type='primary'
                    onClick={this.handleOk}
                    disabled>
                    Wait..{" "}
                  </Button>
                ) : (
                  <Button key='submit' type='primary' onClick={this.handleOk}>
                    {" "}
                    Download
                  </Button>
                )
              }>
              {fileGenerate}
              {/**   <ExcelSheet data={this.state.dataforExport} name="Qatation">
                             <ExcelColumn label="Name" value="name"/>
                             <ExcelColumn label="Contact Number" value="contactNumber"/>
                             <ExcelColumn label="E-mail" value="email"/>
                             <ExcelColumn label="GST" value="gst"/>
                             <ExcelColumn label="Company Name" value="companyName"/>
                             <ExcelColumn label="Pan No" value="pan"/>
                             <ExcelColumn label="Address" value="address"/>
                             </ExcelSheet>*/}
            </ExcelFile>,
          ]}>
          {loading ? (
            <div
              style={{ minHeight: "150px", backgroundImage: `url(${Gears})` }}
            />
          ) : (
            <Alert
              message='Success'
              description='Sale file is ready you can download.'
              type='success'
              showIcon
            />
          )}
        </Modal>
      </div>
    );
  }
}

sale = Form.create()(sale);

function mapStateToProps(state) {
  const { Common, Dataexport } = state;
  const {
    billing_details: billing_details,
    customer_details: customer_details,
    all_sales_persons: all_sales_persons,
    branch_list: branch_list,
    floor_list: floor_list,
    section_list: section_list,
  } = Common;
  const { export_sales_details: export_sales_details } = Dataexport;
  return {
    billing_details,
    customer_details,
    all_sales_persons,
    export_sales_details,
    branch_list,
    floor_list,
    section_list,
  };
}

const AppContainer = connect(mapStateToProps)(sale);

export default AppContainer;
