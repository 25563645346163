import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { getaging } from "../../../actions/index";
import { currencyConverter } from "../../../containers/App/commanFunction";
import moment from "moment";
import {
    Card,
    Table,
    Form,
    Row,
    Col,
    DatePicker,
    Badge,
    Input,
    Button,
    Icon,
    Divider,
    message,
    Select,
} from "antd";
import { sortDirections } from "../../../containers/App/commanFunction";
//import ViewItem from "./viewItem";
//import "./style.css";
import ReactExport from "react-data-export";
import socket from "./../../../containers/App/global";
import CountUp from "react-countup";
import ExportIcon from "./../../../assets/images/icons/export.svg";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";

const Option = Select.Option;
const { RangePicker } = DatePicker;
var cookies = require("browser-cookies");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columns = [
    {
        title: "SKU",
        dataIndex: "SKU.sku_number",
        width: "10%",
        sorter: (a, b) => { },
        render: (text, record) => {
            return <span>{text}</span>;
        },
    },
    {
        title: "DSG CODE",
        dataIndex: "SKU.design_code",
        width: "10%",
        sorter: (a, b) => { },
    },

    {
        title: "MATERIAL",
        dataIndex: "SKU.metal_type",
        width: "12%",
        sorter: (a, b) => { },
    },

    {
        title: "DESIGN CTG",
        dataIndex: "SKU.design_category",
        width: "12%",
        sorter: (a, b) => { },
    },
    {
        title: "DIA CT",
        dataIndex: "SKU.diamond_weight",
        width: "10%",
        sorter: (a, b) => { },
        render: (text, record) => {
            return (
                <span>
                    {text != null
                        ? !Number.isInteger(text)
                            ? text.toFixed(3)
                            : text
                        : ""}
                </span>
            );
        },
    },
    {
        title: "NET WGT",
        dataIndex: "SKU.net_weight",
        width: "10%",
        sorter: (a, b) => { },
        render: (text, record) => {
            return (
                <span>
                    {text != null
                        ? !Number.isInteger(text)
                            ? text.toFixed(3)
                            : text
                        : ""}
                </span>
            );
        },
    },
    {
        title: "PRICE",
        dataIndex: "SKU.sales_value",
        width: "11%",
        sorter: (a, b) => { },
        render: (text, record) => {
            return <span>{currencyConverter(text)}</span>;
        },
    },
    {
        title: "SKU QTY",
        dataIndex: "SKU.sku_quantity",
        width: "10%",
        sorter: (a, b) => { },
        render: (text, record) => {
            return <span>{text}</span>;
        },
    },
    {
        title: "DATE",
        dataIndex: "createdAt",
        width: "10%",
        sorter: (a, b) => { },
        render: (text, record) => {
            return <span>{moment(text).format("DD-MM-YYYY")}</span>;
        },
    },
    {
        title: "AGING (DAYS)",
        dataIndex: "DaysSinceCreation",
        width: "13%",
        sorter: (a, b) => { },
        render: (text, record) => {
            return <span>{text}</span>;
        },
    },
];

class AgingReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            table_loading: true,
            skuNumber: "",
            startDays: "",
            endDays: "",
            dataExport: [],
            scanSessionName: "",
            scanSessions: [],
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        //let now = new Date();
        //let starDate = (fromDate = moment(
        //    new Date(now.getFullYear(), now.getMonth(), now.getDate())
        //).subtract(30, "days"));
        //let fromDate = starDate.toISOString();
        //let toDate = new Date().toISOString();
        let startDays = "";
        let endDays = "";
        let skuNumber = "";
        this.setState({
            startDays: startDays,
            endDays: endDays,
        });
        //let page_no = 0;
        dispatch(getaging(
            startDays,
            endDays,
            skuNumber
        ));

        let usertype = localStorage.getItem("type_user");
        let root = JSON.parse(cookies.get("root"));
        //if (usertype === "branchAdmin") {
        //    dispatch(getAllUserList("branch", root.branchId));
        //} else if (usertype === "companyAdmin") {
        //    dispatch(getAllUserList("company", root.companyId));
        //} else if (usertype === "floorAdmin") {
        //    dispatch(getAllUserList("floor", root.floorId));
        //}
        socket.on("Notification", (col) => {
            try {
                if (col.data_code === "501") {
                    let newData = col.data_obj;
                    let Data = this.state.data;
                    Data.unshift(newData);
                    let pagination = {};
                    pagination.total = this.state.pagination.total + 1;
                    this.setState({
                        data: Data,
                        //totalData: this.state.totalData + 1,
                        pagination,
                    });
                }
            } catch (e) {
                console.log("error", e);
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.aging_details != this.props.aging_details) {
            // console.log("this.props.sales_details");
            // console.log(this.props.stocktally_details);
            console.log("ddd:", this.props.aging_details);
                let json = this.props.aging_details;
                let pagination = {};
                //pagination.total = this.props.aging_details.data.totalData;
                pagination.pageSize = 10;
                this.setState({
                    data: json,
                    pagination,
                });
                console.log("data:", json);
                //let dataExport = this.props.aging_details.data_array;
                //let dataExportArray = [];
                //for (let index = 0; index < dataExport.length; index++) {
                //    let element = dataExport[index];
                //    let date = moment(element.createdAt).format("DD-MM-YYYY HH:mm A");
                //    let skuItemsFound = element.skuItemsFound.join();
                //    let skuItemsNotFound = element.skuItemsNotFound.join();
                //    let extraTag = element.extraTag.join();
                //    let skuItemsSold = element.skuItemsSold.join();
                //    dataExportArray.push({
                //        date: date,
                //        skuItemsFound: skuItemsFound,
                //        skuItemsNotFound: skuItemsNotFound,
                //        extraTag: extraTag,
                //        skuItemsSold: skuItemsSold,
                //    });
                //}
                //this.setState({
                //    dataExportArray: dataExportArray,
                //});
            this.setState({
                table_loading: false,
            });
        }
    }

    searchOnchange = (e) => {
        this.setState({ skuNumber: e.target.value, btnsearch: false });

        if (e.target.value.length == 0) {
            this.setState({
                table_loading: true,
                btnsearch: true,
                skuNumber: e
            });
            this.props.form.validateFields(
                ["skuNumber"],
                (err, values) => {
                    if (err) {
                        return;
                    }
                    const { dispatch } = this.props;
                    let skuNumber = "";
                    let startDays = "";
                    let endDays = "";
                    if (values.skuNumber != undefined) {
                        skuNumber = values.skuNumber;
                    }
                        dispatch(getaging(
                            startDays,
                            endDays,
                            skuNumber
                            )
                    );
                }
            );
        }
    };

    onSerach = (e) => {
        this.setState({
            data: [],
            dataObj: {},
            skuNumber: this.state.skuNumber
        });
        try {
            if (this.state.skuNumber.length > 1) {
                this.props.form.validateFields(
                    ["startDays", "endDays","skuNumber"],
                    (err, values) => {
                        if (err) {
                            return;
                        }

                        const { dispatch } = this.props;
                        let startDays = "";
                        let endDays = "";
                        let skuNumber = this.state.skuNumber;
                        if (values.skuNumber != undefined) {
                            skuNumber = values.skuNumber;
                        }

                        this.setState({
                            table_loading: true,
                        });

                        dispatch(
                            getaging(
                                startDays,
                                endDays,
                                skuNumber
                            )
                        );
                        this.setState({
                            skuNumber: ""
                        });

                    }
                );
            }
        } catch (error) { }
    };

    handleEndDaysChange = (e) => {
        this.setState({ endDays: e.target.value });
    };

    handleStartDaysChange = (e) => {
        this.setState({ startDays: e.target.value });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            table_loading: true,
            pagination: pager,
        });
        const { dispatch } = this.props;
        dispatch(
            getaging(
                pager.current - 1,
                this.state.startDays,
                this.state.endDays,
                this.state.skuNumber
            )
        );

        // let tableViewdata=this.state.TableViewData;
        // if(order!=undefined){
        //   if(order==="descend"){
        //     order=-1;
        //     orderopp=1;
        //   }else{
        //     order=1;
        //     orderopp=-1;
        //   }
        //   tableViewdata.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
        // }else{
        //   const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
        //   shuffleArray(tableViewdata);
        // }
    };

    btnFiltter = () => {
        this.setState({
            table_loading: true,
            startDays: this.state.startDays,
            endDays: this.state.endDays,
            skuNumber: ""
        });

        this.props.form.validateFields((err, values) => {
            const { dispatch } = this.props;
            //const pager = { ...this.state.pagi

            this.setState({
                table_loading: true,
                startDays: this.state.startDays,
                endDays: this.state.endDays,
                skuNumber: ""
            });
            let startDays = this.state.startDays;
            let endDays = this.state.endDays;
            let skuNumber = "";

            dispatch(getaging(startDays, endDays, skuNumber));
            this.props.form.resetFields();
        });
    };

    onClearFiltter = (e) => {
        this.setState({
            table_loading: true,
            startDays: this.state.startDays,
            endDays: this.state.endDays,
            skuNumber: ""
        });
        const { dispatch } = this.props;
        let startDays = this.state.startDays;
        let endDays = this.state.endDays;
        let skuNumber = "";
        this.setState({
            startDays: this.state.startDays,
            endDays: this.state.endDays,
            skuNumber: "",
        });
        dispatch(getaging(startDays, endDays, skuNumber));
        this.props.form.resetFields();
    };

    render() {
        var heightd = window.innerHeight - 480;
        const { getFieldDecorator } = this.props.form;
        //const now = new Date();
        //const fromDate = moment(
        //    new Date(now.getFullYear(), now.getMonth(), now.getDate())
        //).subtract(30, "days");
        const formItemLayout = {
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        let data = this.state.data;
        //if (this.state.search) {
        //    data = data.filter((row) => {
        //        let datef = moment(row.createdAt).format("DD-MM-YYYY HH:mm A");
        //        return datef.toUpperCase().includes(this.state.search.toUpperCase());
        //        //row.name.toUpperCase().includes(this.state.search.toUpperCase())
        //    });
        //}
        return (
            <div>
                <Card className='custome-top-menu-card' bordered={false}>
                    <Row>
                        <Col
                            lg={5}
                            md={5}
                            sm={24}
                            xs={24}
                            style={{
                                textAlign: "center",
                            }}>
                            <p className='transaction-title'>Reports</p>
                            <h1 className='transaction-sub-title'>Aging Report</h1>
                        </Col>

                        <Col lg={1} md={1} sm={24} xs={24} style={{ textAlign: "center" }}>
                            <Divider type='vertical' className='vertical-divider-customer1' />
                        </Col>
                        <Col lg={6} md={6} sm={24} xs={24}>
                            <p
                                style={{ marginBottom: "5px" }}
                                className='transaction-serach-text text-color-8c8c8c'>
                                Search
                            </p>
                            <Input
                                value={this.state.skuNumber}
                                placeholder='-- Search SKU--'
                                style={{ float: "left", width: "60%" }}
                                onChange={this.searchOnchange}
                            />
                            <Button
                                onClick={this.onSerach}
                                style={{ marginLeft: "11px" }}
                                type='primary'
                                disabled={this.state.btnsearch}>
                                Search
                            </Button>
                        </Col>
                        <Col lg={7} md={7} sm={24} xs={24}></Col>

                        {/*<Col*/}
                        {/*    lg={5}*/}
                        {/*    md={5}*/}
                        {/*    sm={24}*/}
                        {/*    xs={24}*/}
                        {/*    style={{ textAlign: "center" }}*/}
                        {/*    className='cursor-pointer'>*/}
                        {/*    <ExcelFile*/}
                        {/*        element={<img className='export-icon' src={ExportIcon} />}>*/}
                        {/*        <ExcelSheet data={this.state.dataExportArray} name='AgingReport'>*/}
                        {/*            <ExcelColumn label='SKU' value='SKU' />*/}
                        {/*            <ExcelColumn label='DAYS AGO' value='DaysSinceCreation'/>*/}
                        {/*        </ExcelSheet>*/}
                        {/*    </ExcelFile>*/}
                        {/*    <h5 className='text-color-8c8c8c'>Export Report</h5>*/}
                        {/*</Col>*/}
                    </Row>
                </Card>

                <Form hideRequiredMark={true} colon={false} labelAlign={"left"}>
                    <Card
                        className='filtter-card'
                        bordered={false}
                        bodyStyle={{
                            padding: "10px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                        }}>
                        <Row>
                            <Col lg={4} md={4} sm={24} xs={24}>
                                <p
                                    style={{ marginBottom: "5px" }}
                                    className='transaction-serach-text text-color-8c8c8c'>
                                    Start Day
                                </p>
                                <Input
                                    value={this.state.startDays}
                                    placeholder='--start_days--'
                                    style={{ float: "left", width: "60%" }}
                                    onChange={this.handleStartDaysChange}
                                />
                            </Col>

                            <Col lg={4} md={4} sm={24} xs={24}>
                                <p
                                    style={{ marginBottom: "5px" }}
                                    className='transaction-serach-text text-color-8c8c8c'>
                                    End Day
                                </p>
                                <Input
                                    value={this.state.endDays}
                                    placeholder='--end_days--'
                                    style={{ float: "left", width: "60%" }}
                                    onChange={this.handleEndDaysChange}
                                />
                            </Col>

                            <Col lg={1} md={1} sm={24} xs={24}></Col>
                            <Col lg={1} md={1} sm={24} xs={24}></Col>
                            <Col lg={5} md={5} sm={24} xs={24}>
                                <div
                                    style={{
                                        marginTop: "31px",
                                        paddingRight: "9px",
                                        float: "right",
                                    }}>
                                    <Button
                                        style={{ float: "left" }}
                                        onClick={this.btnFiltter}
                                        type='primary'>
                                        Filter
                                    </Button>
                                    <Button
                                        onClick={this.onClearFiltter}
                                        className='transaction-clear-btn'
                                        ghost>
                                        Clear
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Form>

                <Card
                    bordered={false}
                    style={{ marginBottom: "0px" }}
                    bodyStyle={{ padding: "10px 10px", paddingBottom: "0px" }}>
                    <Table
                        className='gx-table-responsive'
                        loading={this.state.table_loading}
                        columns={columns}
                        dataSource={data}
                        size={"small"}
                        scroll={{ y: heightd }}
                        rowKey={(record) => record._id}
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        sortDirections={sortDirections}
                    />
                </Card>
            </div>
        );
    }
}

AgingReport = Form.create()(AgingReport);

function mapStateToProps(state) {
    const { Common } = state;
    const {
        aging_details: aging_details
    } = Common;
    return { aging_details };
}

const AppContainer = connect(mapStateToProps)(AgingReport);

export default AppContainer;
