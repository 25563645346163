import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Modal,Form,Radio ,List,Col, Icon, Button,message, Row,Switch,Select,AutoComplete,Input,Divider,Checkbox} from 'antd';
import {updateCustomerMasterType,getCustomerMaster}from "../../../actions/index";
import "./Custome.css";
const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Retailers','Wholesaler','Manufacturer'];
const { TextArea } = Input;
class editCustomerMaster extends Component {
    constructor(props) 
    {
      super(props);
        this.state = {
            loading: false,
            visible: false,
            copied: false,
            table_loading:false,
            goldValue: props.goldValue,
            diamondValue: props.diamondValue,
            colorStoneValue: props.colorStoneValue,
            findingValue:props.findingValue,
            labourValue: props.labourValue,
        }
  };
     
    componentDidMount() {
      const { dispatch } = this.props;
    }

    
    componentDidUpdate(prevProps, prevState)
    {
      if (prevProps.update_customer_master != this.props.update_customer_master) {
          if(this.props.update_customer_master.status)
          {
            this.setState({ visible: false });
            this.props.form.resetFields();
          } 
        } 
    }
 
    showModal = () => {
        this.setState({
          visible: true,
        });
      }
      handleOk = () => {

        this.props.form.validateFields((err, values) => {
          if (err) {
            return;
          }
          const {dispatch} = this.props;
          let id=values._id;
          delete values.cust_type;
          delete values._id;
         
          if(this.state.goldValue)
          {
            values.goldValue=1;
          }else{
            values.goldValue=0;
          }
          if(this.state.diamondValue)
          {
              values.diamondValue=1;
          }else{
            values.diamondValue=0;
          }  
          if(this.state.colorStoneValue)
          {
              values.colorStoneValue=1;
          }else{
            values.colorStoneValue=0;
          }  
          if(this.state.findingValue)
          {
              values.findingValue=1;
          }else{
            values.findingValue=0;
          }  
          if(this.state.labourValue)
          {
              values.labourValue=1;
          }else{
            values.labourValue=0;
          }  
          
         // console.log({_id:id,increment_master:[values]});
          dispatch(updateCustomerMasterType({_id:id,increment_master:[values]})); 
        });
      }
      handleCancel = () => {
        this.setState({ visible: false });
        this.props.form.resetFields();
      }
      onChangeGoldValue=(e)=> {
        if(e.target.id=="goldValue")
        {
          this.setState({ goldValue : e.target.checked });
          return
        }
        if(e.target.id=="diamondValue")
        {
          this.setState({ diamondValue : e.target.checked });
          return
        }
        if(e.target.id=="colorStoneValue")
        {
          this.setState({ colorStoneValue : e.target.checked });
          return
        }
        if(e.target.id=="findingValue")
        {
          this.setState({ findingValue : e.target.checked });
          return
        }
        if(e.target.id=="labourValue")
        {
          this.setState({ labourValue : e.target.checked });
          return
        }
      }
  
    render() {
        const {visible}= this.state; 
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
         <Icon type="edit" onClick={this.showModal} style={{fontSize:"18px",cursor:"pointer",float:"left"}}/>
          <Modal
            title="Update Incement Master"
            visible={visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
            // <Form.Item >
            //     <Button type="primary" htmlType="submit">Submit</Button>
            //  </Form.Item>
             <Button key="back" onClick={this.handleCancel}>Cancle</Button>,
             <Button key="white" type="primary" htmlType="submit" onClick={this.handleOk}>
               Submit
             </Button>
            ]}
           // width={500}
            style={{paddingBottom:"166px"}}
          >
        
            <Form layout="vertical" onSubmit={this.FormSubmit}>

            <Form.Item label="hide" style={{display:"none"}} >
            {getFieldDecorator('_id', {initialValue:this.props.id}, {
              rules: [{ required: true, message: 'Please input description !' }],
            })(<Input type="textarea" />)}


          </Form.Item>

          
          <Form.Item label="Customer Type" className="customFormItem">
          {getFieldDecorator('cust_type', {initialValue:this.props.cust_type},{
            rules: [{ required: true, message: 'Please input  value !' }],
          })(
            <Input disabled/>
          )}
        </Form.Item>
        <Form.Item label="Increment Per" className="customFormItem">
        {getFieldDecorator('incementPer', {initialValue:this.props.incementPer},{
          rules: [{ required: true, message: 'Please input increment per !' }],
        })(<Input type="number" />)}
      </Form.Item>
            
                    <Form.Item className="customFormItem">
                    {getFieldDecorator('goldValue',{
                      rules: [{ required: false, message: 'Please input gold value !' }],
                    })(
                        <Checkbox checked={this.state.goldValue} onChange={this.onChangeGoldValue}>Gold Value</Checkbox>
                    )}
                  </Form.Item>
                  <Form.Item className="customFormItem">
                    {getFieldDecorator('diamondValue',{
                      rules: [{ required: false, message: 'Please input dimonds value !' }],
                    })( <Checkbox checked={this.state.diamondValue} onChange={this.onChangeGoldValue}>Diamond Value</Checkbox>)}
                  </Form.Item>

                  <Form.Item  className="customFormItem">
                  {getFieldDecorator('colorStoneValue',{
                    rules: [{ required: false, message: 'Please input dimonds value !' }],
                  })(<Checkbox  checked={this.state.colorStoneValue} onChange={this.onChangeGoldValue}>Color Stone Value</Checkbox>)}
                </Form.Item>

                <Form.Item  className="customFormItem">
                {getFieldDecorator('findingValue',{
                  rules: [{ required: false, message: 'Please input finding Value !' }],
                })(<Checkbox checked={this.state.findingValue} onChange={this.onChangeGoldValue}>Finding Value</Checkbox>)}
              </Form.Item>
                <Form.Item  className="customFormItem">
                {getFieldDecorator('labourValue',{
                  rules: [{ required: false, message: 'Please input labour!' }],
                })(<Checkbox checked={this.state.labourValue} onChange={this.onChangeGoldValue}>Labour</Checkbox>)}
              </Form.Item>           
          </Form>
        </Modal>
        </div>
        )
    }
}

editCustomerMaster= Form.create()(editCustomerMaster);
function mapStateToProps(state) {
    const {Common } = state
    const {update_customer_master:update_customer_master} =  Common
    return {update_customer_master}
}
const AppContainer = connect( 
mapStateToProps 
)(editCustomerMaster);
export default AppContainer;