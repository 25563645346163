import Axios from 'axios';
const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var cookies = require('browser-cookies');

export const EXPORT_QUATATION_DETAILS='EXPORT_QUATATION_DETAILS';
export const EXPORT_SALES_DETAILS='EXPORT_SALES_DETAILS';

function getRootInfoWithId(){
  let usertype= localStorage.getItem('type_user');
  let root =  JSON.parse(cookies.get('root'));
  let rootType='';
  let id='';
  if(usertype==="branchAdmin")
  {
    rootType="branch";
    id=root.branchId;
  }
  if(usertype==="companyAdmin")
  {
    rootType="company"
    id=root.companyId;
  }
  if(usertype==="floorAdmin")
  {
    rootType="floor"
    id=root.floorId;
  }
  if(usertype==="sectionAdmin")
  {
    rootType="section"
    id=root.sectionId;
  }
  if(usertype==="subSectionAdmin")
  {
    rootType="subSection"
    id=root.subSectionId;
  } 
  return `&rootInfo=${rootType}&id=${id}`;
}

function getQuotationreport(page_no,limit) {
    let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    return dispatch => {
        return Axios.get(`quote/?page_no=${page_no}&limit=${limit}${getRootInfoWithId()}`,
          { headers: { 'x-web-token': webtoken }}
          )
            .then(response => {         
            
                if (response.data !== "") {             
                    dispatch(requestQuotationDetails(response.data))                
                } else {
                    dispatch(requestQuotationDetails([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestQuotationDetails(response) {
    return {
        type: EXPORT_QUATATION_DETAILS,
        export_quotation_details: response
    }
  }
  
  export function getExportQuotation(page_no,limit) {
  
    return (dispatch) => {
        return dispatch(getQuotationreport(page_no,limit))
    }
  }

  function getSalereport(page_no,limit) {
    let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    return dispatch => {
        return Axios.get(`sale?page_no=${page_no}&limit=${limit}${getRootInfoWithId()}`,
          { headers: { 'x-web-token': webtoken }}
          )
            .then(response => {         
            
                if (response.data !== "") {             
                    dispatch(requestgetSaleExportDetails(response.data))                
                } else {
                    dispatch(requestgetSaleExportDetails([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }


  }
  
  function requestgetSaleExportDetails(response) {
    return {
        type: EXPORT_SALES_DETAILS,
        export_sales_details: response
    }
  }
  
  export function getExportSale(page_no,limit) {
  
    return (dispatch) => {
        return dispatch(getSalereport(page_no,limit))
    }
  }



  