import { connect } from "react-redux";
import React, { Component } from "react";
import {
    Modal,
    Form,
    Table,
    List,
    Col,
    Icon,
    DatePicker,
    Card,
    Button,
    Radio,
    message,
    Row,
    Switch,
    Select,
    AutoComplete,
    Divider,
    Input,
} from "antd";
import socketIOClient from "socket.io-client";
import moment from "moment";

var cookies = require("browser-cookies");
message.config({
    top: 100,
    duration: 10,
    maxCount: 3,
});
const { TextArea } = Input;

class AddReceive extends Component {
    constructor(props) {
        super(props);
        let root = JSON.parse(cookies.get("root"));
        this.state = {
            data: [],
            loading: false,
            visible: false,
            username: root.companyId,
            isCountryIndia: false,
            customerType: true,
            isPrivateCustomer: false,
        };

        // let root =  JSON.parse(cookies.get('root'));
        // console.log("root.companyId");
        // console.log({"root": root,"username":'neha'});

        //  socket.emit("Notification", {"root": root});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.create_customer != this.props.create_customer) {
            try {
                if (this.props.create_customer.status) {
                    message.success("Add customer sucessfully");
                    this.setState({
                        visible: false,
                    });
                    this.props.form.resetFields();
                    this.props.createReceiveClose();
                    // const { dispatch } = this.props;
                    //  dispatch(getCustomer(0,10));
                } else {
                    message.error(this.props.create_customer.errors.msg);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = () => {
        this.setState({ visible: false });
        this.props.form.resetFields();
        this.props.createReceiveClose();
    };

    handleOk = (e) => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            let root = JSON.parse(cookies.get("root"));
            const { dispatch } = this.props;
            values.root = root;
            // console.log(values);
            values.isPrivateCustomer = this.state.isPrivateCustomer;
            //dispatch(createCustomer(values));
        });
    };

    handleOnChangeCountry = (country) => {
        // console.log(country);
        if (country === "India") {
            this.setState({
                isCountryIndia: true,
            });
        } else {
            this.setState({
                isCountryIndia: false,
            });
        }
    };

    onChangeCustomerType = (e) => {
        if (e.target.value === "Corporate") {
            this.setState({
                customerType: true,
                isPrivateCustomer: false,
            });
        } else {
            this.setState({
                customerType: false,
                isPrivateCustomer: true,
            });
        }
    };

    render() {
        const { visible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout1 = {
            labelCol: { span: 24 },
            //   labalAlign:{marginLeft: "15px"},
            wrapperCol: { span: 24 },
        };

        this.columns = [
            {
                title: "ISSUE ID",
                dataIndex: "sku_number",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: "ISSUED NET WGT",
                dataIndex: "net_weight",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return (
                        <span>
                            {text != null
                                ? !Number.isInteger(text)
                                    ? text.toFixed(3)
                                    : text
                                : ""}
                        </span>
                    );
                },
            },
            {
                title: "ISSUED GROSS WGT",
                dataIndex: "gross_weight",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return (
                        <span>
                            {text != null
                                ? !Number.isInteger(text)
                                    ? text.toFixed(3)
                                    : text
                                : ""}
                        </span>
                    );
                },
            },
            {
                title: "ISSUED SKU QUANTITY",
                dataIndex: "sku_quantity",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: "RECEIVED NET WGT",
                dataIndex: "net_weight",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return (
                        <span>
                            {text != null
                                ? !Number.isInteger(text)
                                    ? text.toFixed(3)
                                    : text
                                : ""}
                        </span>
                    );
                },
            },
            {
                title: "RECEIVED GROSS WGT",
                dataIndex: "gross_weight",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return (
                        <span>
                            {text != null
                                ? !Number.isInteger(text)
                                    ? text.toFixed(3)
                                    : text
                                : ""}
                        </span>
                    );
                },
            },
            {
                title: "RECEIVED SKU QUANTITY",
                dataIndex: "sku_quantity",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return <span>{text}</span>;
                },
            },
        ];
        let data = this.state.data;
        const formItemLayout = {
            //labelCol: { span: 24 },
            //   labalAlign:{marginLeft: "15px"},
            //wrapperCol: { span: 24 },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutHorizan = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };
        return (
            <React.Fragment>
                <Card
                    bodyStyle={{ padding: "0" }}
                    style={{ borderRadius: "4px" }}
                    bordered={false}>
                    <Card
                        style={{
                            borderLeft: "9px solid",
                            marginBottom: "5px",
                            color: "#4285F4",
                            borderRadius: "4px",
                        }}
                        bodyStyle={{ padding: "0" }}
                        bordered={false}>
                        <Row>
                            <Col
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                style={{ paddingLeft: "40px" }}>
                                <h6
                                    className='customer-report-title-back icon-charvlet-back'
                                    onClick={() => this.props.createReceiveClose()}>
                                    <Icon type='left' className='text-color-4285F4' />Back to Receive
                                </h6>
                                <h1 className='customer-report-title'>Add Receive</h1>
                            </Col>
                        </Row>
                    </Card>

                    <Row>
                        <Col lg={7} md={7} sm={24} xs={24} style={{ paddingLeft: "40px" }}>
                            <p
                                style={{ marginBottom: "5px" }}
                                className='transaction-serach-text text-color-8c8c8c'>
                                Search IsssueID
                            </p>
                            <Input
                                //value={this.state.search}
                                placeholder='-- Search IssueID--'
                                style={{ float: "left", width: "70%" }}
                            // onChange={e => this.setState({ search: e.target.value })}
                            //{this.searchOnchange}
                            />
                            <Button
                                //onClick={this.onSerach}
                                style={{ marginLeft: "11px" }}
                                type='primary'
                                disabled={this.state.btnsearch}>
                                Search
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <Card bodyStyle={{ padding: "10px 0px" }} bordered={false}>
                                <Table
                                    className='gx-table-responsive'
                                    //loading={this.state.table_loading}
                                    columns={this.columns}
                                    dataSource={data}
                                    size={"small"}
                                //rowKey={(record) => record._id}
                                //pagination={this.state.pagination}
                                //onChange={this.handleTableChange}
                                />
                                <Col lg={23} md={23} sm={24} xs={24}>
                                    <Button
                                        //onClick={this.onSerach}
                                        style={{ marginLeft: "11px", float: "right" }}
                                        type='primary'
                                        disabled={this.state.btnsearch}>
                                        Cancel
                                    </Button>
                                    <Button
                                        //onClick={this.onSerach}
                                        style={{ marginLeft: "11px", float: "right" }}
                                        type='primary'
                                        disabled={this.state.btnsearch}>
                                        Save
                                    </Button>
                                </Col>
                            </Card>
                        </Col>
                    </Row>

                </Card>
            </React.Fragment>
        );
    }
}

AddReceive = Form.create()(AddReceive);
function mapStateToProps(state) {
    const { Common } = state;
    const { create_customer: create_customer } = Common;
    return { create_customer };
}
const AppContainer = connect(mapStateToProps)(AddReceive);
export default AppContainer;
