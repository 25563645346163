import { getUserDetails,updateUserDetails } from '../../actions/index';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Card, Form, Button, message, Input, Row, Col,Radio, Upload, Icon ,Select} from 'antd';
import Vanue from "./vanue";
import Tax from "./tax";
import Currancy from "./currancy";
import Metal from "./metal";

const { Option } = Select;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
class perferences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            perferenceLocation:'metal',
        };
    }
    componentDidMount() {
        const { dispatch } = this.props;
        let usename= localStorage.getItem('usename');
        dispatch(getUserDetails(usename));
    }
    componentDidUpdate(prevProps, prevState) {
        try {
            if (prevProps.user_data != this.props.user_data) {                
                if (this.props.user_data.status) {
                    this.setState({
                        data: this.props.user_data.data,
                        currency_symbol:this.props.user_data.data.currency_symbol
                    })
                }
            }
            if (prevProps.update_user_data != this.props.update_user_data) {
                if (this.props.update_user_data.status) {
                    message.success("Update sucessfully");
                    localStorage.setItem('currancy',this.props.user_data.data.currency_symbol);
                    this.setState({                  
                        currency_symbol:this.props.user_data.data.currency_symbol
                    })
                } else {
                    message.error(this.props.update_user_data.error.msg);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (err) {
                return;
            }
            const { dispatch } = this.props;
            let data = this.state.data;
            data.currency_symbol = values.currency_symbol;
            dispatch(updateUserDetails(data));
        });
    };

    radioChangePrefrances=(e)=>{
        // console.log(e.target.value);
        // console.log("ee");
        this.setState({
            perferenceLocation:e.target.value
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
            <Row>

            <Col md={24}>
                <RadioGroup  
                defaultValue="metal"
                onChange={this.radioChangePrefrances}
                >                     
                <RadioButton  key={"metal"} value={"metal"}>Metal</RadioButton>
                <RadioButton  key={"tax"} value={"tax"}>Tax </RadioButton>
                {/*
                <RadioButton  key={"vanue"} value={"vanue"}>Venue | Location </RadioButton>
                <RadioButton  key={"currency"} value={"currency"}>Currency</RadioButton>
                <RadioButton  key={"authsign"} value={"authsign"}>Authsign</RadioButton>*/}
                </RadioGroup>
            </Col>
            </Row>

            {this.state.perferenceLocation === "vanue" ?<Vanue/>:""}
            {this.state.perferenceLocation === "tax" ?<Tax/>:""}
            {this.state.perferenceLocation === "metal" ?<Metal/>:""}
            {this.state.perferenceLocation === "currency" ?<Currancy/>:""}

           
            </React.Fragment>
        )
    }
}


perferences = Form.create()(perferences);
function mapStateToProps(state) {
    const { Common } = state
    const { user_data: user_data, update_user_data: update_user_data } = Common
    return { user_data, update_user_data }
}
const AppContainer = connect(
    mapStateToProps
)(perferences);
export default AppContainer;
