import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getSettings,
  postSettings,
  updateSettings,
  getUserDetails,
  updateUserDetails,
} from "../../../actions/index";
import {
  Button,
  Card,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Switch,
  Transfer,
} from "antd";
import moduleResponse from "./moduleResponse.json";

const cookies = require("browser-cookies");

class settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
        estimateFormat: "PDF",
        deleteFormat: "yes",
        showPcsCount: true,
        deleteAllExistingStock: false,
      showGoldRate: false,
      allowManualDetect: false,
      customertype: false,
        settingsData: null,
      userData: {},
      moduleAccess: moduleResponse.data_array,
      visible: false,
      mockData: [],
      targetKeys: [],
      leftData: [],
      isShowGrossWeight: true,
      isShowSalesValue: false,
      showBilledAsSold: false,
      oldTransactionDays: "",
    };
  }

  componentWillMount() {
    let usertype = localStorage.getItem("type_user");
    if (usertype != "companyAdmin") {
      window.location.href = "/dashboard";
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getSettings());
    let username = localStorage.getItem("usename");
    dispatch(getUserDetails(username));
  }

  componentDidUpdate(prevProps, prevState) {
    //get_settings
    if (prevProps.get_settings != this.props.get_settings) {
      // console.log("this.props.get_settings");
      // console.log(this.props.get_settings);
      if (this.props.get_settings.status) {
        if (this.props.get_settings.data != null) {
          let data = this.props.get_settings.data;
          this.setState({
            settingsData: data,
            estimateFormat: data.estimateFormat,
              deleteFormat: data.deleteFormat,
              showPcsCount: data.showPcsCount,
              deleteAllExistingStock: data.deleteAllExistingStock,
            showGoldRate: data.showGoldRate,
            allowManualDetect: data.allowManualDetect,
            customertype: data.customertype,
            isShowGrossWeight: data.isShowGrossWeight,
            isShowSalesValue: data.isShowSalesValue,
            showBilledAsSold: data.showBilledAsSold,
            oldTransactionDays: data.oldTransactionDays
          });
        }
      } else {
        this.setState({
          estimateFormat: "PDF",
            deleteFormat: "yes",
            showPcsCount: true,
            deleteAllExistingStock: false,
          showGoldRate: false,
          allowManualDetect: false,
          customertype: false,
          isShowGrossWeight: true,
          isShowSalesValue: false,
          showBilledAsSold: false,
        });
      }
    }

    if (prevProps.post_settings != this.props.post_settings) {
      // console.log("this.props.post_settings", this.props.post_settings);
      if (this.props.post_settings.status) {
        this.setState({
          settingsData: this.props.post_settings.data,
          estimateFormat: this.props.post_settings.data.estimateFormat,
            deleteFormat: this.props.post_settings.data.deleteFormat,
          showPcsCount: this.props.post_settings.data.showPcsCount,
          deleteAllExistingStock: this.props.post_settings.data.deleteAllExistingStock,
          showGoldRate: this.props.post_settings.data.showGoldRate,
          allowManualDetect: this.props.post_settings.data.allowManualDetect,
          customertype: this.props.post_settings.data.customertype,
          isShowGrossWeight: this.props.post_settings.data.isShowGrossWeight,
          isShowSalesValue: this.props.post_settings.data.isShowSalesValue,
          showBilledAsSold: this.props.post_settings.data.showBilledAsSold,
        });
        message.success("Post sucessfully");
      }
    }
    if (prevProps.update_settings != this.props.update_settings) {
      // console.log('this.props.update_settings', this.props.update_settings);
      if (this.props.update_settings.status) {
        this.setState({
          settingsData: this.props.update_settings.data,
          estimateFormat: this.props.update_settings.data.estimateFormat,
            deleteFormat: this.props.update_settings.data.deleteFormat,
          showPcsCount: this.props.update_settings.data.showPcsCount,
          deleteAllExistingStock: this.props.update_settings.data.deleteAllExistingStock,
          showGoldRate: this.props.update_settings.data.showGoldRate,
          allowManualDetect: this.props.update_settings.data.allowManualDetect,
          customertype: this.props.update_settings.data.customertype,
          isShowGrossWeight: this.props.update_settings.data.isShowGrossWeight,
          isShowSalesValue: this.props.update_settings.data.isShowSalesValue,
          showBilledAsSold: this.props.update_settings.data.showBilledAsSold,
        });
        message.success("Update sucessfully");
      }
    }

    if (prevProps.user_data != this.props.user_data) {
      // console.log("User Data --", this.props.user_data);
      if (this.props.user_data.status) {
        if (
          this.props.user_data.data.moduleAccess !== null &&
          this.props.user_data.data.moduleAccess.length > 0
        ) {
          //TODO: Use modduleAccess from user response to setup UI
          this.setState({
            userData: this.props.user_data.data,
            moduleAccess: this.props.user_data.data.moduleAccess,
          });
          this.getKeyaddedData(this.props.user_data.data.moduleAccess);
        } else {
          //TODO: In case, moduleAccess is not received from response, use default from local json
          // & setup UI
          this.setState({
            userData: this.props.user_data.data,
          });
          this.getKeyaddedData(this.state.moduleAccess);
        }
      }
      // console.log("User modules --", this.state.moduleAccess);
    }

    if (prevProps.update_user_data != this.props.update_user_data) {
      if (this.props.update_user_data.status) {
        //TODO: Show success message on api success & update state
        message.success("Updated successfully");
        this.setState({
          userData: this.props.user_data.data,
          moduleAccess: this.props.user_data.data.moduleAccess,
        });
        this.getKeyaddedData(this.state.moduleAccess);
      } else {
        message.error(this.props.update_user_data.error.msg);
      }
    }
  }

  updateValue = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log("Values on update click --", values);
      // console.log("Settings data --", this.state.settingsData);
      if (
        this.state.settingsData !== null &&
        this.state.settingsData !== undefined
      ) {
        let tempData = this.state.settingsData;
        tempData.estimateFormat = this.state.estimateFormat;
          tempData.deleteFormat = this.state.deleteFormat;
        tempData.showPcsCount = this.state.showPcsCount;
        tempData.deleteAllExistingStock = this.state.deleteAllExistingStock;
        tempData.showGoldRate = this.state.showGoldRate;
        tempData.allowManualDetect = this.state.allowManualDetect;
        tempData.customertype = this.state.customertype;
        tempData.isShowGrossWeight = this.state.isShowGrossWeight;
        tempData.isShowSalesValue = this.state.isShowSalesValue;
        tempData.showBilledAsSold = this.state.showBilledAsSold;
        tempData.oldTransactionDays = this.state.oldTransactionDays;
        // console.log("Requesting update ------", tempData);
        const { dispatch } = this.props;
        dispatch(updateSettings(tempData));
      } else {
        let root = JSON.parse(cookies.get("root"));
        values.companyId = root.companyId;
        // console.log("Requesting post ------", values);
        const { dispatch } = this.props;
        dispatch(postSettings(values));
      }
    });
    };

    switch = (checked) => {
        this.setState({
            deleteAllExistingStock: checked,
        },
             this.updateValue
        );
    };

  onPcsChange = (checked) => {
    this.setState(
      {
        showPcsCount: checked,
      },
      this.updateValue
    );
  };
  onGoldChange = (checked1) => {
    this.setState(
      {
        showGoldRate: checked1,
      },
      this.updateValue
    );
  };
  onAllowDetectChange = (checked1) => {
    this.setState(
      {
        allowManualDetect: checked1,
      },
      this.updateValue
    );
  };
  onAllowCustomerType = (checked1) => {
    this.setState(
      {
        customertype: checked1,
      },
      this.updateValue
    );
  };
  onPreviousTransactionDaysChange = (e) => {
    this.setState(
      {
        oldTransactionDays: e.target.value,
      },
    //   this.updateValue
    );
  };
  onShowGrossWeightChange = (checked1) => {
    this.setState(
      {
        isShowGrossWeight: checked1,
      },
      this.updateValue
    );
  };
  onShowSalesValueChange = (checked1) => {
    this.setState(
      {
        isShowSalesValue: checked1,
      },
      this.updateValue
    );
  };
  onShowBilledAsSold = (checked1) => {
    this.setState(
      {
        showBilledAsSold: checked1,
      },
      this.updateValue
    );
  };
  handleChange = (targetKeys, direction, moveKeys) => {
    let targetObj = {};
    // console.log("Direction-", direction + ", Mkeys -" + moveKeys)
    // console.log("Target Keys before -", targetKeys)
    if (direction === "left") {
      // console.log(moveKeys);
      moveKeys.map((i) => {
        this.setState((prevState) => ({
          leftData: [...prevState.leftData, i],
        }));
      });
    } /*else if (direction === "right") {
            console.log(moveKeys)
            moveKeys.map(i => {
                console.log("i", i)
                if (i === "2001") {
                    this.state.mockData.map(item => {
                        if (item.module_code === "2002" || item.module_code === "2003" || item.module_code === "2004" || item.module_code === "2005" || item.module_code === "2006") {
                            targetKeys.push(item.module_code);
                        }
                    })
                }
            })
            console.log("Target Keys-", targetKeys)
        }*/
    this.setState({ targetKeys, moveKeys });
  };

  renderItem = (module) => {
    const customLabel = (
      <span className="custom-item">{module.module_name}</span>
    );
    return {
      label: customLabel, // for displayed item
      value: module.module_code, // for title and filter matching
    };
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleCancel = () => {
    this.setState({ visible: false });
  };

  getKeyaddedData = (data) => {
    data.map((x, index) => {
      // console.log("x.key, index", x.key + ", " + index);
      if (x.key === null || x.key === undefined) x.key = x.module_code;
    });

    this.setState(
      {
        mockData: data,
        targetKeys: [],
      },
      () => {
        data.map((x) => {
          if (x.is_enabled !== true) {
            this.state.targetKeys.push(x.key);
          }
        });
      }
    ); // console.log("mockData -" + data + ", targetKeys- " + this.state.targetKeys + ", leftData - " + this.state.leftData))
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
    let selectedArray = [];
    this.state.mockData.map((item) => {
      if (this.state.targetKeys.includes(item.key)) {
        item.is_enabled = false;
        selectedArray.push(item);
      } else if (this.state.leftData.includes(item.key)) {
        item.is_enabled = true;
        selectedArray.push(item);
      } else {
        selectedArray.push(item);
      }
    });
    let updatedConfig = selectedArray;
    const { dispatch } = this.props;
    let data = this.state.userData;
    data.moduleAccess = updatedConfig;
    dispatch(updateUserDetails(data));
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div className="certain-category-search-wrapper" />
        <Row>
          <Col md={12} sm={24} type="flex">
            <Card bordered={false} title="Settings">
              <Form layout="vertical" onSubmit={this.updateValue}>
                <Form.Item label="Display Stock pcs" className="customFormItem">
                  {getFieldDecorator("showPcsCount", {
                    valuePropName: "checked",
                    initialValue: this.state.showPcsCount,
                  })(<Switch onChange={this.onPcsChange} />)}
                </Form.Item>
                <Form.Item label="Display Gold rate" className="customFormItem">
                  {getFieldDecorator("showGoldRate", {
                    valuePropName: "checked",
                    initialValue: this.state.showGoldRate,
                  })(<Switch onChange={this.onGoldChange} />)}
                </Form.Item>
                <Form.Item
                  label="Allow Manually Detect Tags"
                  className="customFormItem"
                >
                  {getFieldDecorator("allowManualDetect", {
                    valuePropName: "checked",
                    initialValue: this.state.allowManualDetect,
                  })(<Switch onChange={this.onAllowDetectChange} />)}
                </Form.Item>
                <Form.Item
                  label="Enable Customer Types"
                  className="customFormItem"
                >
                  {getFieldDecorator("customertype", {
                    valuePropName: "checked",
                    initialValue: this.state.customertype,
                  })(<Switch onChange={this.onAllowCustomerType} />)}
                </Form.Item>
                <Form.Item
                  label="Consider billed items as sold"
                  className="customFormItem"
                >
                  {getFieldDecorator("showBilledAsSold", {
                    valuePropName: "checked",
                    initialValue: this.state.showBilledAsSold,
                  })(<Switch onChange={this.onShowBilledAsSold} />)}
                </Form.Item>
                            <Form.Item
                                label="Delete All Previous Stock on New Upload Stock"
                                className="customFormItem"
                            >
                                {getFieldDecorator("deleteAllExistingStock", {
                                    valuePropName: "checked",
                                    initialValue: this.state.deleteAllExistingStock,
                                })(<Switch onChange={this.switch} />)}
                            </Form.Item>
              </Form>
            </Card>

                    <Card bordered={false} title="Delete Configuration">
                        <Form layout="vertical" onSubmit={this.updateValue}>
                            <Form.Item label="Delete Format" className="customFormItem">
                                {getFieldDecorator("deleteFormat", {
                                    initialValue: this.state.deleteFormat,
                                })(
                                    <Radio.Group>
                                        <Radio value="yes">With History</Radio>
                                        <Radio value="no">Without History</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    onClick={this.updateValue}
                                    style={{ marginBottom: "-13px", marginTop: "10px" }}
                                >
                                    Update
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>

            <Card bordered={false} title="Configure Module Access">
              <Row>
                <Col sm={22} type="flex">
                  <h5>Restricted Modules</h5>
                </Col>
                <Col type="flex">
                  <div>
                    <Icon
                      type="edit"
                      onClick={this.showModal}
                      style={{
                        fontSize: 20,
                        paddingLeft: "10px",
                        cursor: "pointer",
                        float: "left",
                      }}
                    />
                    <Modal
                      title="Select Modules to restrict"
                      visible={this.state.visible}
                      onCancel={this.handleCancel}
                      footer={[
                        <Button key="back" onClick={this.handleCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          onClick={this.handleOk}
                        >
                          Save
                        </Button>,
                      ]}
                      width={"46%"}
                      style={{ paddingBottom: "166px" }}
                    >
                      <Transfer
                        dataSource={this.state.mockData}
                        listStyle={{
                          width: 300,
                          height: 300,
                        }}
                        titles={["Allowed", "Restricted"]}
                        targetKeys={this.state.targetKeys}
                        onChange={this.handleChange}
                        render={this.renderItem}
                      />
                    </Modal>
                  </div>
                </Col>
              </Row>
              {this.state.moduleAccess !== undefined
                ? this.state.moduleAccess.map((module, index) =>
                    module.is_enabled !== true ? (
                      <span key={index} className="chip">
                        {module.module_name}
                      </span>
                    ) : (
                      ""
                    )
                  )
                : ""}
              </Card>
          </Col>
          <Col md={12} sm={24}>
            <Card bordered={false} title="Estimate File Format">
              <Form layout="vertical" onSubmit={this.updateValue}>
                <Form.Item label="File Format" className="customFormItem">
                  {getFieldDecorator("estimateFormat", {
                    initialValue: this.state.estimateFormat,
                  })(
                    <Radio.Group>
                      <Radio value="PDF">PDF</Radio>
                      <Radio value="TXT">Text</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.updateValue}
                    style={{ marginBottom: "-13px", marginTop: "10px" }}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Card>
            <Card bordered={false} title="Configure Inventory Scan Details">
              <Form layout="vertical">
                <Form.Item
                  label="Display Gross Weight"
                  className="customFormItem"
                >
                  {getFieldDecorator("isShowGrossWeight", {
                    valuePropName: "checked",
                    initialValue: this.state.isShowGrossWeight,
                  })(<Switch onChange={this.onShowGrossWeightChange} />)}
                </Form.Item>
                <Form.Item
                  label="Display Sales Value"
                  className="customFormItem"
                >
                  {getFieldDecorator("isShowSalesValue", {
                    valuePropName: "checked",
                    initialValue: this.state.isShowSalesValue,
                  })(<Switch onChange={this.onShowSalesValueChange} />)}
                </Form.Item>
              </Form>
            </Card>

            <Card
              bordered={false}
              title="How Many days Previous Transaction Records Needed in App"
            >
              <Form layout="vertical">
                <Form.Item className="customFormItem">
                  <Input
                    value={this.state.oldTransactionDays}
                    style={{ float: "left", width: "60%" }}
                    onChange={this.onPreviousTransactionDaysChange}
                  />

                  <Button
                    type="primary"
                    onClick={this.updateValue}
                    style={{ marginLeft: "20px" }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

settings = Form.create()(settings);

function mapStateToProps(state) {
  const { Common } = state;
  const {
    get_settings: get_settings,
    post_settings: post_settings,
    update_settings: update_settings,
    user_data: user_data,
    update_user_data: update_user_data,
  } = Common;
  return {
    get_settings,
    post_settings,
    update_settings,
    user_data,
    update_user_data,
  };
}

const AppContainer = connect(mapStateToProps)(settings);

export default AppContainer;
