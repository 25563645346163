import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import {
  getEmployees,
  getEmployeeReport,
  getCustomerSearch,
  getAllBrachList,
  getAllFloorList,
  getAllSectionList,
} from "../../actions/index";
import {
  Card,
  Table,
  Form,
  Row,
  Col,
  Modal,
  Select,
  Input,
  Button,
  Icon,
  Popconfirm,
  Alert,
  message,
  Divider,
  DatePicker,
} from "antd";
import ReactExport from "react-data-export";
import Gears from "../../assets/images/gears.gif";
import AddCustomer from "./add";
import socket from "./../../containers/App/global";
import Edit from "./edit";
import View from "./view";
import Report from "./report";
import CountUp from "react-countup";
import FilterIcon from "../../assets/images/icons/filter-icon.svg";
import moment from "moment";
import {
  currencyConverter,
  sortDirections,
} from "../../containers/App/commanFunction";
import "./custome.css";
const Option = Select.Option;
const { RangePicker } = DatePicker;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { TextArea } = Input;
const cookies = require("browser-cookies");

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      table_loading: true,
      search: "",
      visible: false,
      confirmLoading: false,
      loading: true,
      dataforExport: [],
      totalPage: 0,
      getPageFlagOn: false,
      total: 0,
      searchCustomerDetails: [],
      allSalesPersons: [],
      branchList: [],
      textForSelect: "",
      sectionList: [],
      floorList: [],
      floorId: "",
      branchId: "",
      sectionId: "",
      slecteID: "",
      fromDate: "",
      toDate: "",
      root: "",
      id: "",
      salesperson: "",
      showReportView: true,
      currentPage: 1,
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;
    //dispatch(getEmployees());
    let page_no = 0;
    let root = JSON.parse(cookies.get("root"));
    let usertype = localStorage.getItem("type_user");
    //   dispatch(getAllSalesPerson("company", root.companyId));
    dispatch(getEmployees(page_no, "", "", "", 10, "", "", "", "", ""));
    if (usertype === "branchAdmin") {
      this.setState({
        rootInfo: "floor",
        rootInfoEqual: "branch",
        rootLess: "section",
        textForSelect: "Select Floor",
        textForSelect1: "Select Section",
        id: root.branchId,
        selectedId: root.branchId,
        selectedRootInfoEqual: "branch",
        rootid: root.branchId,
      });
      dispatch(getAllFloorList("id"));
    }
    if (usertype === "companyAdmin") {
      this.setState({
        rootInfo: "branch",
        rootInfoEqual: "company",
        rootLess: "floor",
        textForSelect: "Select Branch",
        textForSelect1: "Select Floor",
        id: root.companyId,
        selectedId: root.companyId,
        rootid: root.companyId,
        selectedRootInfoEqual: "company",
      });
      dispatch(getAllBrachList("id"));
    }
    if (usertype === "floorAdmin") {
      this.setState({
        rootInfo: "section",
        rootInfoEqual: "floor",
        rootLess: "subsection",
        textForSelect: "Select Section",
        textForSelect1: "Select SubSection",
        id: root.floorId,
        rootid: root.floorId,
        selectedId: root.floorId,
        selectedRootInfoEqual: "floor",
      });
      dispatch(getAllSectionList("id"));
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.employee_details != this.props.employee_details) {
      // console.log(this.props.employee_details);
      if (this.props.employee_details.status) {
        let json = this.props.employee_details.data_array;
        let pagination = {};
        pagination.total = this.props.employee_details.data.totalUser;
        pagination.pageSize = 10;
        this.setState({
          data: json,
          pagination,
          total: this.props.employee_details.data.totalUser,
        });
        // console.log("json");
        // console.log(json);
      }
      this.setState({
        table_loading: false,
      });
    }

    if (prevProps.branch_list != this.props.branch_list) {
      if (this.props.branch_list.status) {
        this.setState({
          branchId: this.props.branch_list.data_array[0]._id,
          branchList: this.props.branch_list.data_array,
          slecteID: this.props.branch_list.data_array[0]._id,
        });
        if (this.props.branch_list.data_array.length >= 1) {
          const { dispatch } = this.props;
          let page_no = 0;
          //   dispatch(getQuotation(page_no, this.state.id, this.state.rootInfoEqual,"","","","","",""));
          //dispatch(getSKUByRootInfoAndId(this.props.branch_list.data_array[0]._id,this.state.rootInfo));
        }
      } else {
        this.setState({
          branchList: [],
        });
      }
    }

    if (
      prevProps.search_customer_details != this.props.search_customer_details
    ) {
      // console.log(this.props.employee_details);
      if (this.props.search_customer_details.status) {
        this.setState({
          searchCustomerDetails: this.props.search_customer_details.data_array,
        });
      }
    }

    if (prevProps.floor_list != this.props.floor_list) {
      if (this.props.floor_list.status) {
        this.setState({
          floorList: this.props.floor_list.data_array,
        });
      } else {
        this.setState({
          floorList: [],
        });
      }
    }

    if (prevProps.section_list != this.props.section_list) {
      if (this.props.section_list.status) {
        this.setState({
          sectionList: this.props.section_list.data_array,
        });
      }
    }

    if (
      prevProps.customer_details_export != this.props.customer_details_export
    ) {
      if (this.props.customer_details_export.status) {
        this.setState({
          dataforExport: this.state.dataforExport.concat(
            this.props.customer_details_export.data_array
          ),
          loading: false,
        });

        if (
          this.props.customer_details_export.data.totalCustomer ==
          this.state.dataforExport.length
        ) {
          this.setState({
            loading: false,
          });
        } else {
          if (this.props.customer_details_export.data.totalCustomer > 100) {
            const { dispatch } = this.props;
            let page =
              parseInt(this.props.customer_details_export.data.currentPage) + 1;
            // dispatch(getCustomerDataExport(page,100));
          }
        }
      }
    }

    if (prevProps.update_user != this.props.update_user) {
      try {
        if (this.props.update_user.status) {
          message.success("Update employee sucessfully");
          const { dispatch } = this.props;
          dispatch(getEmployees(0, "", "", "", 10, "", "", "", "", ""));
        } else {
          message.error(this.props.update_user.errors.msg);
        }
      } catch (error) {
        console.log(error);
      }
    }

    // if (prevProps.deletet_customer != this.props.deletet_customer) {
    //   try {

    //      if(this.props.deletet_customer.status)
    //      {
    //          message.success("Delete user sucessfully");
    //          const { dispatch } = this.props;
    //         dispatch(getEmployees(0,"","","",10,"","","","",""));
    //      }else{
    //          message.error(this.props.deletet_customer.errors.msg);
    //      }

    //      } catch (error) {
    //          console.log(error);
    //      }

    //  }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
    const { dispatch } = this.props;
    // dispatch(getCustomerDataExport(0,100));
  };

  showReportView = (code) => {
    // console.log(code);
    let pagination = this.state.pagination;
    pagination.current = this.state.currentPage;
    this.setState({
      showReportView: !this.state.showReportView,
      pagination,
    });
    const { dispatch } = this.props;
    if (code != undefined) {
      dispatch(getEmployeeReport(code));
    }
  };
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dispatch } = this.props;
        // let userdata=values;
      }
    });
  };

  deleteCutomer = (e) => {
    // console.log("e");
    // console.log(e);
    //const { dispatch } = this.props;
    //dispatch(deleteCustomer(e));
  };

  searchOnchange = (e) => {
    //console.log(e.target.value.length);
    this.setState({ search: e.target.value });
    if (e.target.value.length == 0) {
      this.setState({
        table_loading: true,
      });
      const { dispatch } = this.props;
      dispatch(
        getEmployees(
          0,
          "",
          "",
          "",
          10,
          "",
          "",
          this.state.root,
          this.state.id,
          ""
        )
      );
    } else {
      this.setState({
        table_loading: true,
      });
      const { dispatch } = this.props;
      dispatch(
        getEmployees(
          0,
          "",
          "",
          this.state.search,
          10,
          "",
          "",
          this.state.root,
          this.state.id
        )
      );
    }
  };

  handleChangeSearch = (e) => {
    const { dispatch } = this.props;
    dispatch(getCustomerSearch(e));
  };

  handleOnChangeSearch = (id) => {
    this.setState({
      showReportView: !this.state.showReportView,
      searchCustomerDetails: [],
    });
    const { dispatch } = this.props;
    dispatch(getEmployeeReport(id));
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      loading: true,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState({
      pagination: pager,
      table_loading: true,
      currentPage: pagination.current,
    });
    const { dispatch } = this.props;
    //dispatch(getEmployees(pager.current-1,sorter.columnKey,sorter.order,this.state.search,10));
    dispatch(
      getEmployees(
        pager.current - 1,
        sorter.columnKey,
        sorter.order,
        "",
        10,
        this.state.fromDate,
        this.state.toDate,
        this.state.root,
        this.state.id,
        this.state.salesperson
      )
    );
  };

  onChangeBranchRadio = (e) => {
    // console.log(e);
    const { dispatch } = this.props;
    if (this.state.rootInfoEqual === "company") {
      dispatch(getAllFloorList("bybranchId", e));
    } else if (this.state.rootInfoEqual === "branch") {
      dispatch(getAllSectionList("byFloorId", e));
    }
    this.props.form.resetFields("secondid");
  };
  onSerach = (e) => {
    // this.setState({search: e.target.value})
    if (this.state.search.length > 1) {
      this.setState({
        table_loading: true,
      });
      const { dispatch } = this.props;
      dispatch(
        getEmployees(
          0,
          "",
          "",
          this.state.search,
          10,
          "",
          "",
          this.state.root,
          this.state.id
        )
      );
    }
  };

  btnFiltter = () => {
    this.setState({
      table_loading: true,
    });
    try {
      this.props.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        const pager = { ...this.state.pagination };
        pager.current = 1;
        pager.pageSize = 10;

        this.setState({
          pagination: pager,
          table_loading: true,
          pageSizelimit: 10,
          currantPage: pager.current - 1,
        });
        let fromDate = "";
        let toDate = "";
        if (values.date != undefined) {
          if (values.date.length > 0) {
            let fromDate1 = moment(values.date[0])
              .utc()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            let toDate1 = moment(values.date[1])
              .utc()
              .set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
            fromDate = fromDate1.toISOString();
            toDate = toDate1.toISOString();
          }
        }

        let salesperson = values._issuedBy;
        let root = this.state.rootInfo;
        let id = (id = values.rootid);
        if (values.rootid === undefined) {
          root = "";
          id = "";
        }
        if (values.secondid != undefined) {
          id = values.secondid;
          root = this.state.rootLess;
        }
        if (values._issuedBy === undefined) {
          salesperson = "";
        }
        const { dispatch } = this.props;
        this.setState({
          fromDate: fromDate,
          toDate: toDate,
          root: root,
          id: id,
          salesperson: salesperson,
        });
        if (values.rootid == this.state.rootid) {
          //  root=this.state.rootInfoEqual;
          // id=values.rootid;
          this.setState({
            root: this.state.rootInfoEqual,
          });
          dispatch(
            getEmployees(
              0,
              "",
              "",
              "",
              10,
              fromDate,
              toDate,
              this.state.rootInfoEqual,
              values.rootid,
              salesperson
            )
          );
        } else {
          dispatch(
            getEmployees(
              0,
              "",
              "",
              "",
              10,
              fromDate,
              toDate,
              root,
              id,
              salesperson
            )
          );
        }
        //  dispatch(getEmployees(0,"","","",10,fromDate,toDate,root,id,salesperson));
      });
    } catch (error) {
      console.log(error);
    }
  };

  onClearFiltter = (e) => {
    const { dispatch } = this.props;
    dispatch(getEmployees(0, "", "", "", 10, "", "", "", "", ""));
    this.setState({
      fromDate: "",
      toDate: "",
      root: "",
      id: "",
      salesperson: "",
    });
    this.props.form.resetFields();
  };
  // 	if (this.state.search) {
  // 	data = data.filter(row => {
  //     return row.name.toUpperCase().includes(this.state.search.toUpperCase()) ||  String(row.contactNumber).includes(this.state.search)
  //     //row.name.toUpperCase().includes(this.state.search.toUpperCase())
  // 	})
  // }
  render() {
    var heightd = window.innerHeight - 480;
    const { getFieldDecorator } = this.props.form;
    let data = this.state.data;
    let { loading } = this.state;
    this.columns = [
      {
        title: "EMPLOYEE",
        dataIndex: "lastname",
        width: "20%",
        sorter: (a, b) => {},
        render: (text, record) => {
          let { firstname, lastname, username } = record;
          if (firstname === undefined) {
            firstname = "";
          }
          return (
            <span>
              <a
                style={{ color: "#545454" }}
                onClick={() => this.showReportView(username)}>
                {lastname + " " + firstname}
              </a>
            </span>
          );
        },
      },
      {
        title: "EMAIL",
        dataIndex: "email",
        width: "20%",
        sorter: (a, b) => {},
        // render: (text, record) => {
        //   const { Customer } = record;
        //   return (
        //     <span>
        //         {text}
        //     </span>
        //   );
        // },
      },
      {
        title: "BRANCH",
        dataIndex: "branchName",
        width: "15%",
        sorter: (a, b) => {},
        render: (text, record) => {
          const { Customer } = record;
          return <span>{text}</span>;
        },
      },
      {
        title: "DATE REGD.",
        dataIndex: "createdAt",
        width: "20%",
        render: (text, record) => {
          return (
            <span>
              {moment(text)
                .utc(false)
                .format("DD-MM-YYYY")}
            </span>
          );
        },
      },
      {
        title: "DE-REGD.",
        dataIndex: "firstname",
        width: "15%",
        align: "center",
        sorter: (a, b) => {},
        render: (text, record) => {
          return <span style={{ textAlign: "center" }}>-</span>;
        },
      },

      {
        title: "ACTION",
        dataIndex: "_id",
        width: "10%",

        render: (text, record) => {
          const { _id, username } = record;
          let arrayRecord = [record];
          return (
            <span>
              <div className='editable-row-operations'>
                {/**    <Popconfirm
              title="This report under development"
              onCancel={false}
              okText=""
              cancelText="Close"
            > 
            <Icon type="eye" theme="filled" style={{fontSize: 20,float:"left"}} />
              </Popconfirm> 
             <View  record={arrayRecord}/>
            
            */}
                <Icon
                  type='eye'
                  theme='filled'
                  onClick={() => this.showReportView(username)}
                  style={{ fontSize: 20, float: "left" }}
                />

                <Edit record={record} />
                {/**   <Popconfirm
              title="Are you sure delete customer ?"
              onConfirm={()=>this.deleteCutomer(_id)}
              //onCancel={cancel}
              okText="Yes"
              cancelText="No"
            > 
            <Icon type="delete" style={{color: "#f5222d",fontSize: 20,paddingLeft:"20px"}} />
              </Popconfirm>  */}
              </div>
            </span>
          );
        },
      },
    ];
    // if (this.state.search) {
    // 	data = data.filter(row => {
    //     return row.name.toUpperCase().includes(this.state.search.toUpperCase()) ||  String(row.contactNumber).includes(this.state.search)
    //     //row.name.toUpperCase().includes(this.state.search.toUpperCase())
    // 	})
    // }
    const formItemLayout = {
      //labelCol: { span: 24 },
      //   labalAlign:{marginLeft: "15px"},
      //wrapperCol: { span: 24 },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    return (
      <React.Fragment>
        {this.state.showReportView ? (
          <div>
            <Card
              className='customer-top-menu-card'
              bodyStyle={{ padding: "0" }}
              bordered={false}>
              <Row>
                <Col
                  lg={4}
                  md={4}
                  sm={24}
                  xs={24}
                  style={{
                    textAlign: "center",
                  }}>
                  <h1 className='customer-title'>Employees</h1>
                </Col>

                <Col
                  lg={3}
                  md={3}
                  sm={24}
                  xs={24}
                  style={{ textAlign: "center" }}>
                  <Divider
                    type='vertical'
                    className='vertical-divider-customer'
                  />
                  <h1 className='total-text'>
                    <CountUp start={0} end={this.state.total} />{" "}
                  </h1>
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={24}
                  xs={24}
                  style={{ textAlign: "center" }}>
                  <Divider
                    type='vertical'
                    className='vertical-divider-customer1'
                  />
                </Col>
                <Col lg={11} md={11} sm={24} xs={24}>
                  <p
                    style={{ marginBottom: "5px" }}
                    className='transaction-serach-text text-color-8c8c8c'>
                    Search
                  </p>
                  <Input
                    value={this.state.search}
                    placeholder='-- Search Empoyee Name --'
                    style={{ float: "left", width: "90%" }}
                    onChange={this.searchOnchange}
                  />
                  {/**  <Button onClick={this.onSerach} style={{ marginLeft: "11px" }} type="primary">
            Search
           </Button>       
        */}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={24}
                  xs={24}
                  style={{ textAlign: "center" }}>
                  <Divider
                    type='vertical'
                    className='vertical-divider-customer1'
                  />
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={24}
                  xs={24}
                  style={{ textAlign: "center" }}>
                  <AddCustomer root={this.state.root} id={this.state.id} />
                </Col>
              </Row>
            </Card>

            <Form hideRequiredMark={true} colon={false} labelAlign={"left"}>
              <Card
                className='filtter-card'
                bordered={false}
                bodyStyle={{
                  padding: "10px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}>
                <Row>
                  <Col
                    lg={2}
                    md={2}
                    sm={24}
                    xs={24}
                    className='text-aligin-center'>
                    <img src={FilterIcon} className='filter-icon' />
                  </Col>
                  <Col
                    lg={5}
                    md={5}
                    sm={24}
                    xs={24}
                    style={{ paddingLeft: "0px" }}>
                    <Form.Item label={`Registered Date`} {...formItemLayout}>
                      {getFieldDecorator(`date`, {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(
                        <RangePicker
                          format='DD-MM-YYYY'
                          placeholder={["From ", "To"]}
                          style={{ minWidth: "100%" }}
                          separator='-'
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={4} md={4} sm={24} xs={24}>
                    {this.state.rootInfo === "branch" ? (
                      <Form.Item
                        label={this.state.textForSelect}
                        {...formItemLayout}>
                        {getFieldDecorator(`rootid`, {
                          initialValue: this.state.rootid,
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(
                          <Select
                            placeholder='Please select branch'
                            onChange={this.onChangeBranchRadio}
                            style={{ minWidth: "100%" }}>
                            <Select.Option
                              key={this.state.rootid}
                              value={this.state.rootid}>
                              All Branch
                            </Select.Option>
                            {this.state.branchList.map((data, index) => (
                              <Select.Option key={data._id} value={data._id}>
                                {data.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    ) : (
                      ""
                    )}

                    {this.state.rootInfo === "floor" ? (
                      <Form.Item
                        label={this.state.textForSelect}
                        {...formItemLayout}>
                        {getFieldDecorator(`rootid`, {
                          initialValue: this.state.rootid,
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(
                          <Select
                            placeholder='Please select floor'
                            onChange={this.onChangeBranchRadio}
                            style={{ minWidth: "100%" }}>
                            <Select.Option
                              key={this.state.rootid}
                              value={this.state.rootid}>
                              All Floor
                            </Select.Option>
                            {this.state.floorList.map((data, index) => (
                              <Select.Option key={data._id} value={data._id}>
                                {data.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    ) : (
                      ""
                    )}

                    {this.state.rootInfo === "section" ? (
                      <Form.Item
                        label={this.state.textForSelect}
                        {...formItemLayout}>
                        {getFieldDecorator(`rootid`, {
                          initialValue: this.state.rootid,
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(
                          <Select
                            placeholder='Please select section'
                            onChange={this.onChangeBranchRadio}
                            style={{ minWidth: "100%" }}>
                            <Select.Option
                              key={this.state.rootid}
                              value={this.state.rootid}>
                              All section
                            </Select.Option>
                            {this.state.sectionList.map((data, index) => (
                              <Select.Option key={data._id} value={data._id}>
                                {data.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col lg={4} md={4} sm={24} xs={24}>
                    {this.state.rootInfo === "branch" ? (
                      <Form.Item
                        label={this.state.textForSelect1}
                        {...formItemLayout}>
                        {getFieldDecorator(`secondid`, {
                          // initialValue: this.state.id,
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(
                          <Select
                            placeholder='Please select floor'
                            style={{ minWidth: "100%" }}>
                            {this.state.floorList.map((data, index) => (
                              <Select.Option key={data._id} value={data._id}>
                                {data.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    ) : (
                      ""
                    )}

                    {this.state.rootInfo === "floor" ? (
                      <Form.Item
                        label={this.state.textForSelect1}
                        {...formItemLayout}>
                        {getFieldDecorator(`secondid`, {
                          // initialValue: this.state.id,
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(
                          <Select
                            placeholder='Please select section'
                            style={{ minWidth: "100%" }}>
                            {this.state.sectionList.map((data, index) => (
                              <Select.Option key={data._id} value={data._id}>
                                {data.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col lg={4} md={4} sm={24} xs={24}></Col>

                  <Col lg={5} md={5} sm={24} xs={24}>
                    <div
                      style={{
                        marginTop: "31px",
                        paddingRight: "9px",
                        float: "right",
                      }}>
                      <Button
                        style={{ float: "left" }}
                        onClick={this.btnFiltter}
                        type='primary'>
                        Filter
                      </Button>
                      <Button
                        onClick={this.onClearFiltter}
                        className='transaction-clear-btn'
                        ghost>
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Form>

            {/** 
      <Icon type="download" style={{float:"right",fontSize:"27px",cursor:"pointer"}}  className="certain-category-icon" onClick={this.createCustomer}>this</Icon>
      */}

            <div className='certain-category-search-wrapper'></div>

            <Card
              style={{ marginBottom: "0px" }}
              bodyStyle={{ padding: "10px 10px", paddingBottom: "0px" }}
              bordered={false}>
              {" "}
              <Table
                className='gx-table-responsive'
                loading={this.state.table_loading}
                columns={this.columns}
                dataSource={data}
                size={"small"}
                scroll={{ y: heightd }}
                rowKey={(record) => record._id}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                sortDirections={sortDirections}
              />
            </Card>
          </div>
        ) : (
          <Report showReportView={() => this.showReportView()} />
        )}
      </React.Fragment>
    );
  }
}

Employees = Form.create()(Employees);
function mapStateToProps(state) {
  const { Common } = state;
  const {
    section_list: section_list,
    floor_list: floor_list,
    sales_details: sales_details,
    employee_details: employee_details,
    customer_details_export: customer_details_export,
    update_user: update_user,
    deletet_customer: deletet_customer,
    search_customer_details: search_customer_details,
    branch_list: branch_list,
  } = Common;
  return {
    section_list,
    floor_list,
    sales_details,
    employee_details,
    customer_details_export,
    update_user,
    deletet_customer,
    search_customer_details,
    branch_list,
  };
}

const AppContainer = connect(mapStateToProps)(Employees);

export default AppContainer;
