import React from "react";
import {Button, Checkbox, Form, Icon, Input, message} from "antd";
import {connect} from "react-redux";
import {Link,Redirect} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import {userLogin} from '../actions/index';
import Forgotpass from './Forgotpass';
//import {connectSocke1} from './App/global';
var cookies = require('browser-cookies');
const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');


const FormItem = Form.Item;

class SignIn extends React.Component {

  constructor() {
    super();
    this.state = {
      loader:false,
      cType:'',
      isForgatPass:false,
      username:''
    }
  }
  componentWillMount() {
    try {
      let url = window.location.href; 
      //let arr = url.split("/");
     // console.log(arr);
      let cType=url.split("?");
      if(cType.length>1){
        this.setState({
          cType:cType[1].toUpperCase()
        });
      }
      let webtoken = cryptr.decrypt(cookies.get('wteoben'));
      let usertype= localStorage.getItem('type_user');

    if(webtoken != "" && usertype != "")
    {
      if(usertype === "superAdmin")
      {
        this.props.history.push('/companyManagement');
      }else{
        this.props.history.push('/dashboard');
      }     
    }
    } catch (error) {
       // console.log(error);
    }
    
  }
  componentDidUpdate(prevProps, prevState) {
    try {
    
    if(prevProps.login_user_data!=this.props.login_user_data)
    {
       if(this.props.login_user_data.status)
       {
          let url = window.location.href; 
          localStorage.setItem('login_url', url);
          localStorage.setItem('currancy', this.props.login_user_data.data.currency_symbol);
          if(this.props.login_user_data.data.usertype=="MANAGER"){
             localStorage.setItem('type_user', "branchAdmin");
             localStorage.setItem('type_prifile_name', "Branch Manager");
             window.location.href='/dashboard';
          }else if(this.props.login_user_data.data.usertype=="ADMIN"){
               localStorage.setItem('type_user', "companyAdmin");
               localStorage.setItem('type_prifile_name', "Company Manager");
               window.location.href='/dashboard';
          }else if(this.props.login_user_data.data.usertype=="FLOOR MANAGER"){
            localStorage.setItem('type_user', "floorAdmin");
            localStorage.setItem('type_prifile_name', "Floor Manager");
            window.location.href='/dashboard';
          }else if(this.props.login_user_data.data.usertype=="superAdmin"){
            localStorage.setItem('type_user', "superAdmin");
            localStorage.setItem('type_prifile_name', "Spacecode Manager");
            window.location.href='/companyManagement';
          }else{
            this.setState({
              loader:false
             })      
            message.error("This user not allow to access web");
            return;
          }  
          if(this.props.login_user_data.data.usertype != "superAdmin"){
            localStorage.setItem('companyType', this.props.login_user_data.data.company.companyType);
          }

          //connectSocket();
          localStorage.setItem('userAccess',JSON.stringify(this.props.login_user_data.data.accessInfo));
          localStorage.setItem('usename', this.props.login_user_data.data.username);
          localStorage.setItem('name', this.props.login_user_data.data.lastname+" "+this.props.login_user_data.data.firstname);
          localStorage.setItem('isClient', this.props.login_user_data.data.isClient);
          //localStorage.setItem('showPreferences', this.props.login_user_data.data.showPreferences);
          localStorage.setItem('showPreferences', true);
          cookies.set('wteoben', cryptr.encrypt(this.props.login_user_data.data.web_token[this.props.login_user_data.data.web_token.length-1])
          // ,{secure: true,
          //httponly:true
          // } 
          ,{expires: 365}
        );
         cookies.set('root',JSON.stringify(this.props.login_user_data.data.root),{expires: 365});
          
          //this.props.history.push('/dashboard');
       }
       else
       {      
          this.setState({
            loader:false
          })        
          message.error(this.props.login_user_data.errors.msg);
       } 
      
    }      
      } catch (error) {
        //  console.log(error);
      }
  } 

  


  handleSubmit = (e) => {
    e.preventDefault();
    let url = window.location.href; 
    //console.log("url");
    //console.log(url);
    this.props.form.validateFields((err, values) => {
      if (!err) {    
        const {dispatch} = this.props;
         this.setState({
            username:values.username,
            password:values.password,
            loader:true
        })
        values.companyType=this.state.cType;
     //   console.log(values);
        dispatch(userLogin(values)); 
      }
    });
  };

  onChange=(e)=>{
    this.setState({
      username:e.target.value
    })
  }
  forgotpass=(e)=>{
    // this.props.form.validateFields((err, values) => {
    //   this.setState({
    //     username:values.username
    //   })
    //  });
    //  console.log(this.state.username);
    this.setState({
      isForgatPass:!this.state.isForgatPass
    })
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {loader} = this.state;

    return (
       <div className="gx-login-container" >
        <div className="gx-login-content" style={{boxShadow: "0 0 5px #bebebe"}}>
          <div className="gx-login-header gx-text-center">
                <div className="gx-app-logo" style={{marginTop:"5%",marginBottom:"10%"}}>
                <img alt="example" style={{width:"170px"}} src={require("assets/images/jewell-trace-logo.png")}/>
              </div>
          </div>
          {this.state.isForgatPass === true?
            <Forgotpass forgotpass={this.forgotpass} username={this.state.username}/>:
            <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0" hideRequiredMark={true} colon={false}>

              <FormItem
              label="User Name"
              hideRequiredMark={false}
              >
                {getFieldDecorator('username', {
                 // initialValue: "demo@example.com",
                  rules: [{
                    required: true, message: 'Please input your Username!',
                  }],
                })(
                  <Input placeholder="Enter your Username" onChange={this.onChange}/>
                )}
              </FormItem>
              <FormItem
              label="Password"
              >
                {getFieldDecorator('password', {
                 // initialValue: "demo#123",
                  rules: [{required: true, message: 'Please input your Password!'}],
                })(
                  <Input.Password type="password" placeholder="Enter your Password"/>
                )}
              </FormItem>        
              <FormItem>
                <Button type="primary" loading={loader} className="gx-mb-0 loginbtn" style={{width:"100%",borderRadius:"5px",minHeight:"40px",marginTop:"9px"}} htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn"/>
                </Button>
              </FormItem>
              <center style={{paddingBotton:"7%",marginBottom:"25px",marginTop:"40px"}}><a className="gx-login-form-forgot gx-text-center" onClick={this.forgotpass}>Forgot Password?</a></center>  
            </Form>  
                        
              }
        </div>
     
      {/**  <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content" >

          
            <div className="gx-app-logo-content">
            <h1><IntlMessages id="app.userAuth.signIn"/></h1>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/jewell-trace-logo.png")}/>
              </div>
            </div>

            <div className="gx-app-login-content" >
            {this.state.isForgatPass === true?
              <Forgotpass forgotpass={this.forgotpass} username={this.state.username}/>:
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('username', {
                   // initialValue: "demo@example.com",
                    rules: [{
                      required: true, message: 'Please input your Username!',
                    }],
                  })(
                    <Input placeholder="Username" onChange={this.onChange}/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                   // initialValue: "demo#123",
                    rules: [{required: true, message: 'Please input your Password!'}],
                  })(
                    <Input type="password" placeholder="Password"/>
                  )}
                </FormItem>   
                           
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                </FormItem>
                <a className="gx-login-form-forgot" onClick={this.forgotpass}>Forgot password</a>  
              </Form>              
                }
             

            </div>

            


            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}           
          </div>
        </div>
      </div>
       */}
       </div>
    );
  }
}

SignIn = Form.create()(SignIn);

function mapStateToProps(state) {
  const {Common } = state
  const {login_user_data:login_user_data  } =  Common
  return {login_user_data}
}

const AppContainer = connect( 
  mapStateToProps 
)(SignIn);


export default AppContainer;