import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Modal,Form,Table ,List,Col, Icon, Button,message, Row,Switch,Select,AutoComplete,Input} from 'antd';
import {EditCustomerType} from "./Actions/EditCustomerType"
import socketIOClient from "socket.io-client";
import { bindActionCreators } from "redux";
import { listCustomerType} from "./Actions/ListCustomerType"

message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});
const { TextArea } = Input;

class Update extends Component {
      constructor(props) 
      {
        super(props);
        this.state = {
            customerTypeObj : {
              
            }
          };
      
      }

    componentDidMount(){
      this.props.listCustomerTypeAction()
    }
    showModal = () => {
      this.setState({
        visible: true,
      });
    }
   
    handleCancel = () => {
      this.setState({ visible: false });
      this.props.form.resetFields();
    }
  
    handleOk = (e) => {
      this.setState({
        visible: false,
      });
      this.props.form.validateFields((err, values) => {   
        if (!err) {
          let customerObj = {};
        
          
          let customer = [...this.props.customerType.customerTypeList];
         
          customer.map(x => {
          if(x._id === this.props.record.id){
              customerObj = {
              "desc": values.description,
              "isactive": x.isactive,
              "increment_master": x.increment_master,
              "_id": x._id,
              "customer_type": values.customer_type,
              "created_at": x.created_at,
              "updated_at": x.updated_at,
              "__v": x.__v
              }
            }
          })
         this.props.EditCustomerTypeAction(customerObj);
        }else{
          return
        }
       
         
      });
      this.props.listCustomerTypeAction()
    
      }
       
    render () {
        
        const { visible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const {type,description}= this.props.record;
      
        return (
            <div>
            <Icon type="edit" onClick={this.showModal} style={{fontSize: 20,cursor:"pointer",float:"left"}}/>
            <Modal
              title="Update Customer Type"
              visible={visible}
              onCancel={this.handleCancel}
              footer={[
                <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                <Button key="submit" type="primary"  onClick={this.handleOk}>
                   Update
                </Button>,
              ]}
             // width={1250}
              style={{paddingBottom:"166px"}}
            >
       
                <Form layout="vertical" onSubmit={this.FormSubmit}>
                  <Form.Item label="customer_type" className="customFormItem">
                  {getFieldDecorator('customer_type', {initialValue:type,
                    
                    rules: [{ required: true, message: 'Please input Customer Type !' }],
                  })(
                    <Input/>
                  )}
                </Form.Item>
            <Form.Item label="description" className="customFormItem">
            {getFieldDecorator('description', {initialValue:description,
              rules: [{ required: false, message: 'Please input Description!' }],
            })(
                <TextArea rows={3}/>
            )}
          </Form.Item>
            </Form>
            </Modal>
          </div>
        )
    }
}


Update= Form.create()(Update);


const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      listCustomerTypeAction: listCustomerType,
      EditCustomerTypeAction:EditCustomerType
    },
    dispatch
  );
};
const mapStateToProps = state => {
  return {
    customerType: state.customerTypeListReducer
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Update);