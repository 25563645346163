import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Modal,Form,Radio ,List,Col, Icon, Button,message, Row,Switch,Select,AutoComplete,Input,Divider,Checkbox} from 'antd';
import {addCustomerMasterType,getCustomerMaster}from "../../../actions/index";
const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Retailers','Wholesaler','Manufacturer'];
const { TextArea } = Input;
message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});
class addCustomerMaster extends Component {
    constructor(props) 
    {
      super(props);
        this.state = {
            loading: false,
            visible: false,
            copied: false,
            table_loading:false
        }
  };
     
    componentDidMount() {
      const { dispatch } = this.props;
    }

    
    componentDidUpdate(prevProps, prevState)
    {
      if (prevProps.add_customer_master != this.props.add_customer_master) {
          if(this.props.add_customer_master.status)
          {
            message.success("Add cutomer type sucessfully");
            const {dispatch} = this.props;
            dispatch(getCustomerMaster()); 
            this.setState({ visible: false });
            this.props.form.resetFields();
          } 
        } 
    }
 
    showModal = () => {
        this.setState({
          visible: true,
        });
      }
      handleOk = () => {

        this.props.form.validateFields((err, values) => {
          if (err) {
            return;
          }
          const {dispatch} = this.props;
          // console.log(values);
          dispatch(addCustomerMasterType(values)); 
        });
      }
      handleCancel = () => {
        this.setState({ visible: false });
        this.props.form.resetFields();
      }
     
  
    render() {
        const {visible}= this.state; 
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
         <Button type="primary" style={{float:"right"}}  onClick={this.showModal}>{this.props.text}</Button>
          <Modal
            title="Add Customer Type"
            visible={visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
            // <Form.Item >
            //     <Button type="primary" htmlType="submit">Submit</Button>
            //  </Form.Item>
             <Button key="back" onClick={this.handleCancel}>Cancle</Button>,
             <Button key="white" type="primary" htmlType="submit" onClick={this.handleOk}>
               Submit
             </Button>
            ]}
           // width={500}
            style={{paddingBottom:"166px"}}
          >
        
            <Form layout="vertical" onSubmit={this.FormSubmit}>
            <Form.Item label="Customer Type">
              {getFieldDecorator('customer_type', {
                rules: [{ required: true, message: 'Please input cutomer type !' }],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator('desc', {
                rules: [{ required: true, message: 'Please input description !' }],
              })(<Input type="textarea" />)}
            </Form.Item>

            <Form.Item className="collection-create-form_last-form-item" label="Status">
              {getFieldDecorator('isactive', {
                initialValue: true,
              })(
                <Radio.Group>
                  <Radio value={true}>Active</Radio>
                  <Radio value={false}>Deactive</Radio>
                </Radio.Group>
              )}
            </Form.Item>
           
          </Form>
        </Modal>
        </div>
        )
    }
}

addCustomerMaster= Form.create()(addCustomerMaster);
function mapStateToProps(state) {
    const {Common } = state
    const {add_customer_master:add_customer_master} =  Common
    return {add_customer_master}
}
const AppContainer = connect( 
mapStateToProps 
)(addCustomerMaster);
export default AppContainer;