import React, { Component } from 'react';
import {Card, Table,Form,Row,Col,Modal,Select,Input,Button,Icon,Popconfirm,Alert,message,Divider,DatePicker} from "antd";
import {connect} from "react-redux";
import moment from 'moment';
import ExportIcon from "./../../assets/images/icons/download-xls-icon.svg";
import ReactExport from "react-data-export";
import {sortDirections} from "../../containers/App/commanFunction";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class CustomerReport extends Component {
    constructor(props) {
        super(props);
        this.state={
            total:0,
            data:{},
            dataArray:[],
            customerName:'',
            totalVisit:0,
            tableLoading:true,
            btn1:false,
            btn2:false,
            btn3:false,
            btn4:false,
            lastBtnClick:'',
            pagination:{}
        }
    }
    componentDidMount(){
        let pagination = {};
        pagination.current = 1;
        this.setState({
            pagination:pagination
        })
    }
    componentDidUpdate(prevProps, prevState){
        try {
        if (prevProps.get_employee_report != this.props.get_employee_report) {
            if (this.props.get_employee_report.status) {
              let data=this.props.get_employee_report.data_array;
              let newarray=[];
              for (let index = 0; index < data.length; index++) {
                const element = data[index];
                element.dateFormat=moment(element.date).format("DD-MM-YYYY");
                let fname=element.customer.familyName;
                let firstname1=element.customer.firstName;
                if(element.customer.familyName===undefined){
                  fname=''
                }
                if(element.customer.firstName===undefined){
                  firstname1=''
                }
                element.customerName=fname+" "+firstname1;
                element.saleList=element.action.saleList.length;
                element.memoList=element.action.memoList.length;
                element.quoteList=element.action.quoteList.length;
                element.sharedList=element.action.sharedList.length;
                element.orderList=element.action.orderList.length;
                newarray.push(element);
                
              }
             // data.sort((a, b) => (a[`date`] > b[`date`]) ? -1 : 1);
              let name=this.props.get_employee_report.data.employee.lastname+" "+this.props.get_employee_report.data.employee.firstname;
             let branchName=this.props.get_employee_report.data.employee.branchName;
             let floorName=this.props.get_employee_report.data.employee.floorName;
             if(floorName != "" && floorName != null && floorName != undefined && floorName != 'undefined' ){
                branchName=`${branchName} | ${floorName}`
             }else{
              branchName=`${branchName}`
             }
              this.setState({                
                dataArray: newarray,
                // allArray: data,
                 data: this.props.get_employee_report.data,
                 customerName:name,
                 branchName:branchName,
                // contactNumber:this.props.get_employee_report.data.customer.contactNumber,
                // email:this.props.get_employee_report.data.customer.email,
                // address:this.props.get_employee_report.data.customer.address,
                // totalVisit:this.props.get_employee_report.data["TOTAL VISITS"],
                tableLoading:false
              });      
            } else {
              this.setState({
                dataArray: [],     
                tableLoading:false           
              });
            }
          }
                
        } catch (error) {
            
        }
    }
    handleTableChange = (pagination, filters, sorter) => {
        let colome=sorter.columnKey;
        let order=sorter.order;
        let orderopp=0;
        let data=this.state.dataArray;
        if(order!=undefined){
          if(order==="descend"){
            order=-1;
            orderopp=1;
          }else{
            order=1;
            orderopp=-1;
          }
          data.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
        }else{
          const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
          shuffleArray(data);
        }
        let pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
        });
     };

     btnFiltter=(e)=>{
        // let pagination = {};
        // pagination.current = 1;
        //  this.setState({
        //     btn1:false,
        //     btn2:false,
        //     btn3:false,
        //     btn4:false,
        //     lastBtnClick:e,
        //     pagination:pagination
        // })
        // switch (e) {
        //     case "Viewed":
        //         this.setState({
        //             btn1:true
        //         })       
        //         break;
        //     case "Shared":
        //         this.setState({
        //             btn2:true
        //         })
        //         break;
        //     case "Purchased":
        //         this.setState({
        //             btn3:true
        //         })
        //         break;
        //     case "Quoted":
        //         this.setState({
        //             btn4:true
        //         })
        //         break;
        //     default:
        //         break;
        // }
        // let array=this.state.allArray;
        // this.setState({
        //     dataArray:array.filter(data => data.status === e)
        // });
        // if(this.state.lastBtnClick===e){
        //     let array=this.state.allArray;
        //     this.setState({
        //         dataArray:array,
        //         btn1:false,
        //         btn2:false,
        //         btn3:false,
        //         btn4:false,
        //         lastBtnClick:''
        //     });
        // }
     }

    render() {
        this.columns = [  
            // {
            //   title: 'STATUS',
            //   dataIndex: 'date',
            //   sorter: (a, b) => {a.date - b.date},    
            //   defaultSortOrder: 'descend',
            //   render: (text, record) => {
            //   //  var datef=moment(text).format("DD/MM/YYYY");
            //     return (
            //       <span>
            //           {datef}          
            //       </span>
            //     );
            //   }         
            // },
            {
              title: 'CUSTOMER',
              dataIndex: 'customer',
             // width: '10%',
             sorter: (a, b) => {},
             render: (text, record) => {
              let {firstName,familyName} = text;
                if(familyName===undefined){
                  familyName=''
                }
                if(firstName===undefined){
                  firstName=''
                }
                return (      
                  <span>
                      {familyName+" "+firstName}
                  </span>
                );
              }
            },
            {
              title: 'DATE',
              dataIndex: 'date',
              sorter: (a, b) => {},    
              render: (text, record) => {
              var datef=moment(text).utc(false).format("DD-MM-YYYY");
                return (
                  <span>
                      {datef}          
                  </span>
                );
              }         
            },
            {
                title: 'TIME WITH CUSTOMER',
                dataIndex: 'time',
                sorter: (a, b) => {},    
              },
              {
                title: 'SOLD',
                dataIndex: 'saleList',
              sorter: (a, b) => {},  
              //  render: (text, record) => {
              //   let {action} = record;
              //   console.log(action);
              //   let total=action.saleList.length;
              //   //total=temp1.saleList.length;
                
               
              //     return (
              //       <span>
              //           {total}          
              //       </span>
              //     );
              //   }           
              },
              {
                title: 'MEMO',
                dataIndex: 'memoList',
                sorter: (a, b) => {},    
              },
              {
                title: 'ORDERED',
                dataIndex: 'orderList',
                sorter: (a, b) => {},          
              },
              {
                title: 'SHARED',
                dataIndex: 'sharedList',
                sorter: (a, b) => {},          
              },
              {
                title: 'QUOTED',
                dataIndex: 'quoteList',
                sorter: (a, b) => {},    
              },
              // {
              //   title: 'MEMO',
              //   dataIndex: 'memoList',
              //   // sorter: (a, b) => {},  
              //    render: (text, record) => {
              //     let {memoList} = text;
              //       return (
              //         <span>
              //             {memoList.lenght}          
              //         </span>
              //       );
              //     }          
              // },
              // {
              //   title: 'ORDERED',
              //   dataIndex: 'orderList',
              //   // sorter: (a, b) => {},  
              //    render: (text, record) => {
              //     let {orderList} = text;
              //       return (
              //         <span>
              //             {orderList.lenght}          
              //         </span>
              //       );
              //     }         
              // },
              // {
              //   title: 'SHARED',
              //   dataIndex: 'orderList',
              //   // sorter: (a, b) => {},  
              //    render: (text, record) => {
              //     let {sharedList} = text;
              //       return (
              //         <span>
              //             {orderList.lenght}          
              //         </span>
              //       );
              //     }            
              // },
              // {
              //   title: 'QUOTED',
              //   dataIndex: 'quoteList',
              //   render: (text, record) => {
              //     let {action} = record;
              //     let {quoteList}=action;
              //     let total=quoteList.lenght;
              //       return (
              //         <span>
              //             {total}          
              //         </span>
              //       );
              //     }          
              // },
              // {
              //   title: 'CONVERSION RATE',
              //   dataIndex: 'design_category',
              //  sorter: (a, b) => {},    
              // },
              // {
              //   title: 'BAG NO.',
              //   dataIndex: 'sales_category',
              //  sorter: (a, b) => {},    
              // },
              // {
              //   title: 'DEGN-CODE',
              //   dataIndex: 'metal_type',
              //  sorter: (a, b) => {},    
              // },
            //   {
            //     title: 'CARATS',
            //     dataIndex: 'design_category',
            //    sorter: (a, b) => {},    
            //   },
            //   {
            //     title: 'GEMSTONES',
            //     dataIndex: 'design_category',
            //    sorter: (a, b) => {},    
            //   },
              // {
              //   title: 'CAT.',
              //   dataIndex: 'diamond_quantity',
              //  sorter: (a, b) => {},    
              // },
              // {
              //   title: 'SALES CAT.',
              //   dataIndex: 'sales_value',
              //  sorter: (a, b) => {},    
              // },
              // {
              //   title: 'BRAND',
              //   dataIndex: 'status',
              //  sorter: (a, b) => {},    
              // },
              // {
              //   title: 'PRICE',
              //   dataIndex: 'status',
              //  sorter: (a, b) => {},    
              // }
            ]
        return (
            <div>
           
            <Card className="customer-top-menu-card" bodyStyle={{ padding: "0" }} bordered={false}>
    
      <Row>
        <Col lg={4} md={4} sm={24} xs={24} style={{
          textAlign: "center"
        }}>
          <h6 className="customer-report-title-back icon-charvlet-back" onClick={()=>this.props.showReportView("back")}><Icon type="left" className="text-color-4285F4"/>Back to Employees</h6>
          <h1 className="customer-report-title">Reports</h1>
        </Col>
        
        
         
         {/** <Input
            value={this.state.search}
            placeholder="-- Search  Customer Name, Email,Contact No--"
            style={{ float: "left", width: "90%" }}
            onChange={this.searchOnchange}
          />
           {/** <Button onClick={this.onSerach} style={{ marginLeft: "11px" }} type="primary">
            Search
           </Button>   */}      
            
        <Col lg={1} md={1} sm={24} xs={24} style={{textAlign: "center"}}>
        <Divider type="vertical" className="vertical-divider-customer1" />
        </Col>
        <Col lg={5} md={5} sm={24} xs={24} style={{paddingLeft:"0px"}} >
          <h3 style={{marginTop:"bolder",marginTop:"11px", fontWeight:"bolder" }} className="capitalize">{this.state.customerName}</h3>
          <h6>Store: {this.state.branchName}</h6>
        </Col>
         {/**
        <Col lg={5} md={5} sm={24} xs={24}  >
        <h6 style={{marginTop:"11px"}} >Phone: {this.state.contactNumber}</h6>
        <h6 style={{marginTop:"11px"}} >E-mail: {this.state.email}</h6>
        </Col>
        <Col lg={9} md={9} sm={24} xs={24}  >
        <h6 style={{marginTop:"11px"}} >Address: {this.state.address}</h6>
        
        </Col>
         */}   
      </Row>
     </Card>
        <Card className="filtter-card" bordered={false} bodyStyle={{ padding: "10px", paddingBottom: "0px" }}>
           <Row>
            
            <Col lg={24} md={24} sm={24} xs={24} >    
                <Button className={this.state.btn1 ? "btn-for-total-text-emplyee-report-selected" :"btn-for-total-text-emplyee-report"} ghost onClick={()=> this.btnFiltter("Viewed")}>
                 <h1 className= {this.state.btn1 ? "total-btn-customer-report-selected" :"total-btn-customer-report"} >{this.state.data['ITEMS SHOWN']}</h1>
                 <h6 className={this.state.btn1 ? "text-color-h6-selected" :"text-color-h6-btn"}>ITEMS SHOWN</h6>
                </Button>
                <Button className={this.state.btn2 ? "btn-for-total-text-emplyee-report-selected" :"btn-for-total-text-emplyee-report"} ghost onClick={()=> this.btnFiltter("Shared")}>
                 <h1 className= {this.state.btn2 ? "total-btn-customer-report-selected" :"total-btn-customer-report"} >{this.state.data['SHARED']}</h1>
                  <h6 className={this.state.btn2 ? "text-color-h6-selected" :"text-color-h6-btn"}>SHARED</h6>
                </Button>
                <Button className={this.state.btn3 ? "btn-for-total-text-emplyee-report-selected" :"btn-for-total-text-emplyee-report"} ghost onClick={()=> this.btnFiltter("Purchased")}>
                <h1 className= {this.state.btn3 ? "total-btn-customer-report-selected" :"total-btn-customer-report"} >{this.state.data['QUOTED']}</h1>
                <h6  className={this.state.btn3 ? "text-color-h6-selected" :"text-color-h6-btn"} >QUOTED</h6>
                </Button>
                <Button className={this.state.btn4 ? "btn-for-total-text-emplyee-report-selected" :"btn-for-total-text-emplyee-report"} ghost onClick={()=> this.btnFiltter("Quoted")}>
                <h1 className= {this.state.btn4 ? "total-btn-customer-report-selected" :"total-btn-customer-report"} >{this.state.data['SOLD']} </h1>
                <h6 className={this.state.btn4 ? "text-color-h6-selected" :"text-color-h6-btn"}>SOLD</h6>
                </Button>

                <Button className="box-employee-report" ghost>
                   <h1 className="total-btn-customer-report" >{this.state.data['CUSTOMERS SERVED']} </h1>
                   <h6 className="text-color-h6-btn">CUSTOMERS SERVED</h6>
                </Button>
                <Button className="box-employee-report" ghost>
                    <h6 className="total-btn-customer-report" style={{fontSize:"24px"}} >{this.state.data['AVERAGE TIME WITH CUSTOMERS']} </h6>
                    <h6 className="text-color-h6-btn">AVERAGE TIME WITH CUSTOMERS</h6>
                </Button>
               


            </Col>
            

         
            </Row>
        </Card>
         <Row>
         <Col lg={24} md={24} sm={24} xs={24} style={{textAlign: "right"}}>   
         
         <ExcelFile filename="Employee Report"
            element={<React.Fragment><p style={{paddingTop:"3px",float:"right"}}>Download Report</p><img style={{height:"42px",width:"41px",paddingBottom:"15px",cursor:"pointer",float:"right"}} src={ExportIcon}/></React.Fragment> }
         >
         <ExcelSheet data={this.state.dataArray} name="Employee Report" >
               <ExcelColumn label="Customer" value="customerName"/>
               <ExcelColumn label="Date" value="dateFormat"/>
               <ExcelColumn label="Time With Customer" value="time"/>
               <ExcelColumn label="Sold" value="saleList"/>
               <ExcelColumn label="Memo" value="memoList"/>
               <ExcelColumn label="Quoted" value="quoteList"/>
               <ExcelColumn label="Shared" value="sharedList" />
               <ExcelColumn label="Ordered" value="orderList" />
           
         </ExcelSheet>
         </ExcelFile>

         </Col>
         </Row>
        <Card bodyStyle={{ padding: "10px 0px" }} bordered={false}>
         <Table className="gx-table-responsive"
                loading={this.state.tableLoading} 
                columns={this.columns} 
                dataSource={this.state.dataArray}
                size={"small"}
                rowKey={record => record._id}
                pagination={this.state.pagination}
               onChange={this.handleTableChange}    
               sortDirections={sortDirections}
            />
         </Card>


    </div>

        )
    }
}

CustomerReport = Form.create()(CustomerReport);
function mapStateToProps(state) {
  const {Common } = state
  const {get_employee_report:get_employee_report  } =  Common
  return {get_employee_report}
}

const AppContainer = connect( 
  mapStateToProps 
)(CustomerReport);


export default AppContainer;