import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Modal,Form,Radio ,List,Col, Icon, Button,message,Tabs , Row,Switch,Select,AutoComplete,Input,Checkbox} from 'antd';
import {addIncrementMasterPost,getCustomerMaster}from "../../../actions/index";
import "./Custome.css";
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const { TextArea } = Input;
class addIncrementMaster extends Component {
    constructor(props) 
    {
      super(props);
        this.state = {
            loading: false,
            visible: false,
            copied: false,
            table_loading:false
        }
  };
     
    componentDidMount() {
      const { dispatch } = this.props;
    }

    
    componentDidUpdate(prevProps, prevState)
    {
      if (prevProps.add_increment_master != this.props.add_increment_master) {
          if(this.props.add_increment_master.status)
          {
            // message.success("Add increment master sucessfully");
            // const {dispatch} = this.props;
            // dispatch(getCustomerMaster()); 
            // this.setState({ visible: false });
            this.props.form.resetFields();
          } 
        } 
    }
 
    showModal = () => {
        this.setState({
          visible: true,
        });
      }
      handleOk = () => {

        this.props.form.validateFields((err, values) => {
          // console.log("values ok sfysdy");
          // console.log(values);
          if (err) {
            return;
          }
          const {dispatch} = this.props;
          let ctype=values.ctype;
          delete values.ctype;
          if(values.goldValue==true)
          {
              values.goldValue=1;
          }else{
            values.goldValue=0;
          }  
          if(values.diamondValue==true)
          {
              values.diamondValue=1;
          }else{
            values.diamondValue=0;
          }  
          if(values.colorStoneValue==true)
          {
              values.colorStoneValue=1;
          }else{
            values.colorStoneValue=0;
          }  
          if(values.findingValue==true)
          {
              values.findingValue=1;
          }else{
            values.findingValue=0;
          }  
          if(values.labourValue==true)
          {
              values.labourValue=1;
          }else{
            values.labourValue=0;
          }  
        
          let increment_master=values;

          // console.log({ctype,increment_master});
         dispatch(addIncrementMasterPost({ctype,increment_master})); 
        });
      }

      handleCancel = () => {
        this.props.form.resetFields();
        this.props.onUpdate();
      }
     
    render() {
        const {visible}= this.state; 
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
            <Form layout="vertical">  
            <Form.Item label="Customer Type" className="customFormItem">
            {getFieldDecorator('ctype', {
              rules: [{ required: true, message: 'Please select customer type !' }],
            })(
                    <Select
                    showSearch
                    //style={{ width: 200 }}
                    placeholder="Select a customer type"
                    optionFilterProp="children"
                  //  onChange={handleChange}
                  //  onFocus={handleFocus}
                  //  onBlur={handleBlur}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                  {this.props.customer_type_array.map(city => <Option key={city.id}>{city.value}</Option>)}
                  </Select>
            )}
          </Form.Item>
          <Form.Item label="Increment Per" className="customFormItem">
          {getFieldDecorator('incementPer', {
            rules: [{ required: true, message: 'Please input increment per !' }],
          })(<Input type="number" />)}
        </Form.Item>
            <Form.Item className="customFormItem">
              {getFieldDecorator('goldValue', {
                rules: [
                    //{ required: true, message: 'Please input gold value !' }
                ],
              })(
                  <span>
                <Checkbox >Gold Value</Checkbox>
                </span>
              )}
            </Form.Item>
            <Form.Item className="customFormItem">
              {getFieldDecorator('diamondValue', {
                rules: [{ required: false, message: 'Please input dimonds value !' }],
              })(
                <Checkbox >Diamond Value</Checkbox>
              )}
            </Form.Item>

            <Form.Item className="customFormItem">
            {getFieldDecorator('colorStoneValue', {
              rules: [{ required: false, message: 'Please input dimonds value !' }],
            })(<Checkbox >Color Stone Value</Checkbox>)}
          </Form.Item>

          <Form.Item className="customFormItem">
          {getFieldDecorator('findingValue', {
            rules: [{ required: false, message: 'Please input finding value!' }],
          })(<Checkbox >Finding Value</Checkbox>)}
        </Form.Item>
          <Form.Item className="customFormItem">
          {getFieldDecorator('labourValue', {
            rules: [{ required: false, message: 'Please input labour!' }],
          })(<Checkbox >Labour</Checkbox>)}
        </Form.Item>

        

        <div className="ant-modal-footer">
         <Button key="back" onClick={this.handleCancel}>Cancle</Button>
        <Button key="white" type="primary" htmlType="submit" onClick={this.handleOk}>  Submit </Button>
        </div>         
        </Form>

        
        </div>
        )
    }
}

addIncrementMaster= Form.create()(addIncrementMaster);
function mapStateToProps(state) {
    const {Common } = state
    const {add_increment_master:add_increment_master} =  Common
    return {add_increment_master}
}
const AppContainer = connect( 
mapStateToProps 
)(addIncrementMaster);
export default AppContainer;