import axios from "axios";

const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var cookies = require('browser-cookies');

function getloginInfo(){
    let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    let headers = {
      'Content-Type': 'application/json',
      'type': 'Web',
      'x-access-token': webtoken,
    }
    return headers;
  }

// const host="http://api.jeweltrace.in"
export function DeleteCustomerType(id,DeleteCustomerTypeCallback) {
    let errorMessage = ""
    return dispatch => {
        // return axios.delete(host+"/customerstypes?id="+id,
        return axios.delete("/customerstypes?id="+id,
          { headers: getloginInfo()}
          )
            .then(response => {  
                if (
                    response.status === true &&
                    response.errors === {}
                  ) {
                    errorMessage = "";
                  }
                  DeleteCustomerTypeCallback(errorMessage);   
            })
            .catch(error => {
               if(error){
                   errorMessage = error.error.name
               }
               DeleteCustomerTypeCallback(errorMessage)
            });
    }
  }