import React, { Component } from 'react'
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import moment from 'moment';
import { Card, Table, Empty, Form, Row, Col, DatePicker, Select, InputNumber, Button, message, Spin, AutoComplete, Input, Icon, Divider, Radio, Slider, Modal } from "antd";
import { Link } from "react-router-dom";
import { getSKUbyID } from '../../../actions/index';
import nodataImage from "./../../../assets/images/nodata.png";
import Diomand from "./../../../assets/images/diomand.png";
import Stone from "./../../../assets/images/stone.png";

class Stocks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            table_loading: true,
            newtWetStart: 0,
            newtWetEnd: 0,
            GrossWTStart: 2,
            GrossWTEnd: 10,
            sizeStart: 10,
            sizeEnd: 20,
            gridview: false,
            rmdata: []
        };

    }
    componentDidMount() {
        // const { ViewInDetails } = this.props.match.params;
        // let skuId= localStorage.getItem('sku_id');
        //       this.setState({
        //         ViewInDetails:skuId
        //       })
        // const { dispatch } = this.props;     
        //  dispatch(getSKUbyID(skuId));
        // console.log("this.props.display1");
        // console.log(this.props.display);
        // this.setState({
        //   table_loading:this.props.display
        // })
    }
    // componentWillReceiveProps(nextProps){
    //   console.log("this.props.display");
    //   console.log(nextProps);
    //   console.log(this.props.display);
    //   this.setState({
    //     table_loading:this.props.display
    //   })

    // }

    componentDidUpdate(prevProps, prevState) {
        // window.onpopstate = this.onBackButtonEvent;
        // window.onpopstate = function () {
        //   console.log(location.href);
        //   let url = location.href; 
        //   let arr = url.split("/");
        //   console.log(arr[3]);
        //   if(arr[3]=="jtstockView"){
        //     history.go(1);
        //   }

        // };

        if (prevProps.sku_item_details != this.props.sku_item_details) {
            console.log(this.props.sku_item_details);
            try {
                if (this.props.sku_item_details.status) {
                    let dataArray = this.props.sku_item_details.data;
                    dataArray.gold_value = dataArray.gold_value.toFixed(2);
                    dataArray.platinum_rate = dataArray.platinum_rate.toFixed(2);
                    dataArray.silver_rate = dataArray.silver_rate.toFixed(2);
                    dataArray.diamond_value = dataArray.diamond_value.toFixed(2);
                    dataArray.finding_value = dataArray.finding_value.toFixed(2);
                    dataArray.labour_value = dataArray.labour_value.toFixed(2);
                    dataArray.sales_value = dataArray.sales_value.toFixed(2);
                    //dataArray.field_11=dataArray.field_11.toFixed(2);

                    this.setState({
                        data: dataArray,
                        rmdata: dataArray.rm
                    });
                    console.log("ok");
                } else {
                    this.setState({
                        table_loading: false,
                        emptyShow: true,
                        data: {},
                        rmdata: []
                    })
                    message.error(this.props.sku_item_details.errors.msg);
                }

            } catch (error) {
                console.log(error);
            }
        }
    }
    onBackButtonEvent = (e) => {
        // console.log(location.href);
        // let url = location.href; 
        // let arr = url.split("/");
        // console.log(arr[3]);
        // if(arr[3]=="jtstockView"){
        //   history.go(1);
        // }
        // history.pushState(null, null, location.href);
        // window.onpopstate = function () {
        //     console.log(location.href);
        //     let url = location.href; 
        //     let arr = url.split("/");
        //     if(arr[2]==="jtstockView"){
        //       history.go(1);
        //     }

        // };

    }
    onError = () => {
        //alert("error");
        // if (!this.state.errored) {
        let data = this.state.data;
        data.url = nodataImage;
        this.setState({
            data: data,
            table_loading: false
        });
        // }
    }

    handleImageLoaded = () => {
        let data = this.state.data;
        //   data.url=nodataImage;
        if (data.url != nodataImage) {
            this.setState({
                table_loading: false
            });
        }

    }
    handleTableChange = (pagination, filters, sorter) => {
        let colome = sorter.columnKey;
        let order = sorter.order;
        let orderopp = 0;
        let data = this.state.rmdata;
        if (order != undefined) {
            if (order === "descend") {
                order = -1;
                orderopp = 1;
            } else {
                order = 1;
                orderopp = -1;
            }
            data.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
        } else {
            const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
            shuffleArray(data);
        }
    };

    backttoStock = (e) => {
        // let data=this.state.data;
        //   data.url=nodataImage;
        let data = this.state.data;
        data.url = nodataImage;
        this.setState({
            table_loading: true,
            data: data,
            rmdata: []
        });
        this.props.backttoStock();
    }



    render() {
        const { getFieldDecorator } = this.props.form;
        this.columns = [
            {
                title: 'CTG',
                dataIndex: 'rm_ctg',
                sorter: (a, b) => { },
            },
            {
                title: 'CTG DESC.',
                dataIndex: 'international_code',
                sorter: (a, b) => { },
            },
            {
                title: 'SUB CTG',
                dataIndex: 'rm_sub_ctg',
                sorter: (a, b) => { },
            },
            {
                title: 'SUB CTG DESC.',
                dataIndex: 'breadth',
                sorter: (a, b) => { },
            },
            {
                title: 'WT',
                dataIndex: 'rm_wt',
                sorter: (a, b) => { },
                render: (text, record) => {
                    let reference = record.reference;
                    return <span>{text} {reference == 'C' ? 'ct' : ''}{reference == 'G' ? 'gm' : ''}</span>;
                }
            },
            {
                title: 'QTY',
                dataIndex: 'rm_qty',
                sorter: (a, b) => { },
            },


            {
                title: 'RATE',
                dataIndex: 'rm_rate',
                sorter: (a, b) => { },
                render: (text, record) => {
                    let rate = parseFloat(text).toFixed(2);
                    return <span>{rate}</span>;
                }
            },

            {
                title: 'PRICE',
                dataIndex: 'rm_value',
                //width: '11%',
                sorter: (a, b) => { },
                render: (text, record) => {
                    let rate = parseFloat(text).toFixed(2);
                    return <span>{rate}</span>;
                }
            },
            {
                title: '',
                dataIndex: 'set_value',
                //width: '11%',
                //sorter: (a, b) => {},
                render: (text, record) => {
                    return <span>{text === "D" ? <img src={Diomand} height="55px"></img> : ""} {text === "T" ? <img src={Stone} height="35px" style={{ marginLeft: "5px" }}></img> : ""} </span>;
                }
            },

        ];
        return (
            <div>

                <Card className="customer-top-menu-card" bodyStyle={{ padding: "0" }} bordered={false}>

                    <Row>
                        <Col lg={24} md={24} sm={24} xs={24} style={{ paddingLeft: "40px" }}>
                            <h6 className="customer-report-title-back icon-charvlet-back" onClick={this.backttoStock} ><Icon type="left" className="text-color-4285F4" />  Back to Sold Inventory</h6>
                            <h1 className="customer-report-title"><IntlMessages id="sidebar.Stocks" /> >> {this.state.data.sku_number}</h1>
                        </Col>
                    </Row>
                </Card>
                <Spin spinning={this.state.table_loading}>
                    {/**  <Row>
     
 
        <Col md={24} sm={24} xs={24}>
          <Radio.Group defaultValue="a" buttonStyle="solid" onChange={this.onChangeForView} defaultValue="list">
            <Radio.Button value="list"><Icon type="bars" /></Radio.Button>
            <Radio.Button value="gride"><Icon type="appstore" /></Radio.Button>
          </Radio.Group>
        </Col>
      </Row>*/}
                    {this.state.emptyShow === true ?
                        <Row>
                            <Card style={{ width: "100%" }}>
                                <Col md={24} sm={24} xs={24}>
                                    <Empty />
                                </Col>
                            </Card>
                        </Row>
                        :
                        <Row>
                            <Col md={24} sm={24} xs={24}>
                                <Card bordered={false}
                                // loading={this.state.table_loading}
                                >
                                    <Row>
                                        <Col md={8} sm={24} xs={24}>
                                            {/**  
                        <div className="gx-product-image">
                                  <div className="gx-grid-thumb-equal" style={{paddingBottom:"95%"}}>
                                    <a className="gx-grid-thumb-cover" style={{objectFit:"fill"}} href="javascript:void(0);">
                                      <img  src={this.state.data.url}  onError={this.onError} />                                                                                
                                    </a>
                                  </div>
                            </div>
                               <center> <h4 className="gx-text">{this.state.data.sku_number}</h4></center>
                         */}
                                            <img src={this.state.data.url} onLoad={this.handleImageLoaded} onError={this.onError} />
                                            <br />
                                        </Col>
                                        <Col md={8} sm={24} xs={24}>

                                            <h4 className="gx-text">Gross Weight : {parseFloat(this.state.data.gross_weight).toFixed(2)}</h4><Divider />
                                            <h4 className="gx-text">Metal Weight : {parseFloat(this.state.data.net_weight).toFixed(2)}</h4><Divider />
                                            <h4 className="gx-text">Precious Stone CTS : {this.state.data.Precious_Stones_CTS}</h4><Divider />
                                            <h4 className="gx-text">Total Diamond Carats : {this.state.data.Total_Dia_Cts}</h4><Divider />

                                        </Col>
                                        <Col md={8} sm={24} xs={24}>

                                            <h4 className="gx-text">Pearl Weight :  {this.state.data.Pearls_Weight}</h4><Divider />
                                            <h4 className="gx-text">Stone Description : {this.state.data.Stone_Description ? this.state.data.Stone_Description : '-'}</h4><Divider />
                                            <h4 className="gx-text">Semi-Precious Stone CTS :  {this.state.data.Semi_Precious_Stones_CTS}</h4><Divider />
                                            <h4 className="gx-text">Tag : {this.state.data.sales_value}</h4><Divider />

                                        </Col>
                                    </Row>
                                    {this.state.rmdata.length > 0 ?
                                        <Row>
                                            <Col md={24} sm={24} xs={24}>
                                                <Card
                                                    title="Raw Material"
                                                    style={{ borderRadius: "8px", marginTop: "10px" }}
                                                    bodyStyle={{ padding: 0 }}
                                                >

                                                    <Table loading={this.state.table_loading}
                                                        className="gx-table-responsive"
                                                        style={{ paddingTop: "7px" }}
                                                        columns={this.columns}
                                                        dataSource={this.state.rmdata}
                                                        size={"small"}

                                                        rowKey={record => record.ctg}
                                                        // pagination={this.state.pagination}
                                                        onChange={this.handleTableChange}
                                                    //scroll={{ y: 400 }}         
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                        : ""}
                                </Card>
                            </Col>
                        </Row>
                    }
                </Spin>

            </div>
        )
    }
}

Stocks = Form.create()(Stocks);
function mapStateToProps(state) {
    const { Common } = state
    const { sku_item_details: sku_item_details } = Common
    return { sku_item_details }
}

const AppContainer = connect(
    mapStateToProps
)(Stocks);


export default AppContainer;


