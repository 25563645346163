import React, { Component } from "react";
import { Button, Divider, Form, Icon, message, Modal, Select } from "antd";
import { connect } from "react-redux";
import { getSingleCompany } from "../../../actions/index";

const { Option } = Select;
message.config({
  duration: 1,
  maxCount: 3,
});

class tagPrintDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleStockDelete: false,
      data: { prns: [{ prnString: "" }] },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
     dispatch(getSingleCompany());
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.company_details != this.props.company_details) {
      if (this.props.company_details.status) {
        this.setState({
          data: this.props.company_details.data,
        });
      }
    }
  }

  showModalStockDelete = (e) => {
    this.setState({
      visibleStockDelete: true,
    });
    this.props.form.resetFields();
  };

  handleOk = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      let { rm } = this.props.record;
      let dropeDownArray = [];
      if (rm.length > 0) {
        for (let index = 0; index < rm.length; index++) {
          const element = rm[index];
          if (element.set_value === "D" || element.set_value === "d") {
            dropeDownArray.push(element.breadth);
          }
        }
      }
      // console.log([this.props.record],this.state.data.prns[0].prnString);
      if (dropeDownArray.length > 1) {
        try {
          let daimondBeath = values.daimond_clarity;
          let daimondBeathstr = daimondBeath.split("(")[1].replace(")", "");

          let sendData = this.props.record;
          sendData.dc = daimondBeathstr;
          window.writeToSelectedPrinter(
            [sendData],
            this.state.data.prns[0].prnString
          );
        } catch (error) {
          console.log(error);
        }
        // this.setState({
        //     visibleStockDelete:false,
        //    })
        //    this.props.form.resetFields();
      } else {
        console.log(
          "print..................",
          [this.props.record],
          this.state.data.prns[0].prnString
        );
        window.writeToSelectedPrinter(
          [this.props.record],
          this.state.data.prns[0].prnString
        );
      }

      this.setState({
        visibleStockDelete: false,
      });

      //   if(values.password==="spacecode"){
      //     this.setState({
      //       Spinloading:true,
      //     });
      //   }else{
      //     message.error("Password is wrong");
      //   }
    });
  };

  hideModalStockDelete = (e) => {
    this.setState({
      visibleStockDelete: false,
    });
    this.props.form.resetFields();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { sku_number, rm } = this.props.record;
    let dropeDownArray = [];
    if (rm.length > 0) {
      for (let index = 0; index < rm.length; index++) {
        const element = rm[index];
        if (element.set_value === "D" || element.set_value === "d") {
          dropeDownArray.push(element.breadth);
        }
      }
    }

    return (
      <React.Fragment>
        <Modal
          title={sku_number}
          visible={this.state.visibleStockDelete}
          bodyStyle={{ paddingBottom: "48px" }}
          //  onOk={this.handleOk}
          onCancel={this.hideModalStockDelete}
          // footer={[
          //   <Button key="back" onClick={this.hideModalStockDelete}>Cancel</Button>,
          //   <Button key="submit" type="primary"  onClick={this.handleOk}>
          //      Delete
          //   </Button>,
          // ]}
          footer={null}
        >
          Are you sure, you want to print {sku_number} tag number?
          <Form layout="vertical" onSubmit={this.handleOk}>
            <br />
            {dropeDownArray.length > 1 ? (
              <React.Fragment>
                <Form.Item className="customFormItem">
                  {getFieldDecorator("daimond_clarity", {
                    rules: [
                      { required: true, message: "Please daimond clarity !" },
                    ],
                  })(
                    <Select
                      placeholder="Please daimond clarity"
                      style={{ minWidth: "100%" }}
                    >
                      {dropeDownArray.map((data, index) => (
                        <Option key={data} value={data}>
                          {data}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Divider />
              </React.Fragment>
            ) : (
              ""
            )}

            <div style={{ float: "right" }}>
              <Button key="back" onClick={this.hideModalStockDelete}>
                Cancel
              </Button>
              <Button key="submit" type="primary" onClick={this.handleOk}>
                Print
              </Button>
            </div>
          </Form>
        </Modal>
        <Icon
          type="printer"
          onClick={this.showModalStockDelete}
        />
      </React.Fragment>
    );
  }
}
tagPrintDialog = Form.create()(tagPrintDialog);
function mapStateToProps(state) {
  const { Common } = state;
  const {
    company_details: company_details,
    delete_all_stock_root_info: delete_all_stock_root_info,
  } = Common;
  return { company_details, delete_all_stock_root_info };
}

const AppContainer = connect(mapStateToProps)(tagPrintDialog);

export default AppContainer;
