import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Modal,
  Progress,
  Row,
  Select,
  Spin,
  Tabs,
} from "antd";
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { currencyConverter } from "../../containers/App/commanFunction";
import createReactClass from "create-react-class";
// var createReactClass = require('create-react-class');
const Option = Select.Option;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <React.Fragment>
        {payload != null ? (
          <div
            style={{
              backgroundColor: "white",
              padding: "10px",
              border: "1px solid #e8e8e8",
            }}>
            <p>{`${label} : ${payload[0].payload.catCount}`}</p>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
  return null;
};

const CustomTooltipForAmount = ({ active, payload, label }) => {
  if (active) {
    return (
      <React.Fragment>
        {payload != null ? (
          <div
            style={{
              backgroundColor: "white",
              padding: "10px",
              border: "1px solid #e8e8e8",
            }}>
            <p>{`Amount : ${currencyConverter(payload[0].payload.amount)}`}</p>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
  return null;
};

const CustomizedLabel = createReactClass({
  render() {
    const { x, y, dx, fill, value } = this.props;
    // return <text
    //            x={x}
    //            y={y}
    //            dy={-4}
    //            fontSize='16'
    //            fontFamily='sans-serif'
    //            fill={fill}
    //            textAnchor="middle">{value}%</text>
    return (
      <text
        x={x + 20}
        y={y + 15}
        dx={dx}
        dy={0}
        // fontSize='16'
        fontFamily='sans-serif'
        fill={fill}
        textAnchor='middle'>
        {value}
      </text>
    );
  },
});

class branchChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allSalesPersons: [],
      selectedSales: "",
      showRangePicker: true,
      topDesign: "5",
      employeeSwitch: true,
      employeeLabel: "Amount",
      itemArray: [],
      sku_number: "",
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.all_sales_persons != this.props.all_sales_persons) {
      try {
        if (this.props.all_sales_persons.status) {
          this.setState({
            allSalesPersons: this.props.all_sales_persons.data_array,
            selectedSales: this.props.all_sales_persons.data_array[0]._id.empId,
          });
          //  console.log("this.props.selected sales");
          // console.log(this.state.selectedSales);
          //console.log(this.props.all_sales_persons.data_array[0].empId);
        }
        this.setState({
          table_loading: false,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  onMouseMoveDana = (e) => {
    // console.log("e");
    // console.log(e);
  };

  onClickStockTally = (e) => {
    let { history } = this.props;
    history.push({
      pathname: "/stocktallyView",
    });
  };

  onClickCustomerCoversation = (e) => {
    let { history } = this.props;
    history.push({
      pathname: "/conversionRatio",
    });
  };
  onClickViewDemand = (e) => {
    let { history } = this.props;
    history.push({
      pathname: "/viewDemand",
    });
  };

  showRangePicker = (e) => {
    this.setState({
      showRangePicker: true,
    });
  };

  handleEmployee = (e) => {
    // console.log("e");
    // console.log(e);
    if (e == "2") {
      e = false;
      this.setState({
        employeeLabel: "% Total Sales",
      });
      this.props.employeeChangeApi();
    } else if (e == "1") {
      e = true;
      this.setState({
        employeeLabel: "Amount",
      });
      this.props.employeeChangeApi();
    } else {
      e = false;
      this.setState({
        employeeLabel: "Conversion Ratio",
      });
      this.props.employeeChangeApi(1);
    }
    this.setState({
      employeeSwitch: e,
    });
  };

  handleChangeTopSalesDesign = (e) => {
    this.setState({
      topDesign: e,
    });
    this.props.handleChangeTopSalesDesign(e);
  };

  handleChangeCategory = (e) => {
    this.props.handleChangeCategory(e);
  };

  handleBarClick = (data, index) => {
    this.props.handleBarClick(data.skus);
  };

  info = (e) => {
    Modal.info({
      // title: 'This is a notification message',
      icon: false,
      width: 612,
      content: (
        <div style={{ minHeight: "290px" }}>
          <RangePicker
            open={true}
            onChange={this.props.onChangeRangepicker}></RangePicker>
        </div>
      ),
      onOk() {
        // console.log("ok");
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  render() {
    const dataTest = this.props.graphKey;
    var now = new Date();
    const renderCustomizedLabel = (props) => {
      const { x, y, width, height, value } = props;
      const radius = -15;

      return (
        <g>
          <text
            x={x + width}
            y={y - radius}
            fill='#545454'
            textAnchor='top'
            dominantBaseline='top'>
            {value}
          </text>
        </g>
      );
    };
    // .map(function(item, i){
    //   console.log('test');
    //   return<Bar dataKey={item} fill="#7d69ac" />
    // })
    // this.props.graphKey.map((item,i) =>  <Bar dataKey={item} fill="#7d69ac" />)
    // const searchResults = Object.keys(dataTest).map(key =>
    //   <Bar dataKey={dataTest[key]} fill="#7d69ac" />
    // );
    let { employeeSwitch } = this.state;
    const { getFieldDecorator } = this.props.form;
    function getProcess(e) {
      return (
        <React.Fragment>
          {/* {e.length === 0 ?  <React.Fragment><Col lg={24} md={24} style={{textAlign:"center"}}><div style={{paddingRight: "30%" }}>
                    <img src={ComingSoon} style={{height: "194px", position: "absolute"}}/>
                  </div></Col></React.Fragment>:<React.Fragment> */}
          {e.map((data, index) => (
            <React.Fragment>
              {index < 5 ? (
                <React.Fragment>
                  <Col lg={4} md={4} style={{ textAlign: "center" }}>
                    {employeeSwitch ? (
                      <React.Fragment>
                        <Progress
                          strokeColor='#76B9F0'
                          width={180}
                          style={{ marginBottom: "-13px" }}
                          type='dashboard'
                          format={() => currencyConverter(data.Amount)}
                          percent={data.percentage}
                        />
                        <h3 className='process-name-text'>{data.Name}</h3>
                        <h4 className='process-level-text capitalize'>
                          {data.level}
                        </h4>
                        <h4 className='process-name-amount-qty'>
                          {data.percentage}%
                        </h4>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Progress
                          strokeColor='#76B9F0'
                          width={180}
                          style={{ marginBottom: "-13px" }}
                          type='dashboard'
                          percent={data.percentage}
                          format={(e) => `${e}%`}
                        />
                        <h3 className='process-name-text'>{data.Name}</h3>
                        <h4 className='process-level-text capitalize'>
                          {data.level}
                        </h4>
                        <h4 className='process-name-amount-qty'>
                          {currencyConverter(data.Amount)}
                        </h4>
                      </React.Fragment>
                    )}
                  </Col>
                  <Col lg={1} md={1} style={{ textAlign: "center" }}></Col>
                </React.Fragment>
              ) : (
                ""
              )}
            </React.Fragment>
          ))}
          {/* </React.Fragment> }    */}
        </React.Fragment>
      );
    }
    return (
      <div>
        <Row>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Card
              className='gx-card dashbaord-card-white'
              title={
                <h2
                  className='text-color-4285F4'
                  style={{ textTransform: "capitalize" }}>
                  Top Designs
                </h2>
              }
              style={{ padding: "0" }}
              bodyStyle={{ padding: "0" }}
              // <Card  className="gx-card dashbaord-card-white" title={<h2 className="text-color-4285F4" style={{ textTransform: "capitalize" }}>Top {this.state.topDesign} Design Sales</h2>} style={{ padding: "0" }} bodyStyle={{ padding: "0" }}
            >
              {this.props.topSalesDesignDetails.length === 0 ? (
                <Empty style={{ paddingBottom: "15px" }} />
              ) : (
                <React.Fragment>
                  <ResponsiveContainer width='100%' height={400}>
                    <ComposedChart
                      layout='vertical'
                      width={600}
                      height={400}
                      data={this.props.topSalesDesignDetails}
                      margin={{ top: 20, right: 70, bottom: 20, left: 50 }}>
                      <CartesianGrid stroke='#f5f5f5' />
                      <XAxis type='number' />
                      <YAxis
                        dataKey='design_code'
                        type='category'
                        style={{ fontSize: "12px" }}
                      />
                      <Tooltip
                        content={<CustomTooltipForAmount />}
                        label={"amount"}
                      />
                      {/**<Legend />      */}
                      <Bar
                        dataKey='amount'
                        fill='#76B9F0'
                        onClick={this.handleBarClick}
                        style={{ cursor: "pointer" }}
                        top>
                        <LabelList
                          dataKey='Amountlable'
                          position='top'
                          content={renderCustomizedLabel}
                          onClick={this.handleBarClick}
                          style={{ cursor: "pointer" }}
                        />
                      </Bar>
                      {/* {this.props.topSalesDesignDetails.length > 0 && <Brush dataKey="_id" height={30} stroke="#1F628E"/> } */}
                    </ComposedChart>
                  </ResponsiveContainer>

                  <Card
                    style={{ marginBottom: "0px", cursor: "pointer" }}
                    bodyStyle={{ paddingBottom: "0px" }}
                    className='boder-only-top'>
                    <Row>
                      <Col
                        lg={18}
                        md={18}
                        sm={24}
                        xs={24}
                        style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                        <Tabs
                          onChange={this.handleChangeTopSalesDesign}
                          defaultActiveKey='1'
                          tabPosition='bottom'
                          style={{
                            marginTop: "-37px",
                            borderBottom: "0px",
                            marginBottom: "20px",
                          }}>
                          <TabPane tab='Viewed' key='view'></TabPane>
                          <TabPane tab='Sold' key='sold'></TabPane>
                        </Tabs>
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={24}
                        xs={24}
                        style={{ marginTop: "-12px", paddingRight: "0px" }}>
                        <Card
                          style={{ marginBottom: "0px", cursor: "pointer" }}
                          bodyStyle={{
                            padding: "0px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}>
                          {/** <Button className="float-left btn-4-stocktally-graph" style={{ backgroundColor: "#76B9F0" }}></Button>
                    <p className="float-left">Amount</p>       */}
                          <Row>
                            <Col span={24} style={{ textAlign: "center" }}>
                              <Button
                                className='btn-4-stocktally-graph'
                                style={{ backgroundColor: "#76B9F0" }}></Button>
                              Amount
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </React.Fragment>
              )}
            </Card>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Card
              className='gx-card dashbaord-card-white'
              title={
                <h2
                  className='text-color-4285F4'
                  style={{ textTransform: "capitalize" }}>
                  Category Analytics
                </h2>
              }
              style={{ padding: "0" }}
              bodyStyle={{ padding: "0" }}>
              <Spin spinning={this.props.categoryAnalyticsLoading}>
                {this.props.designAnalyticsDetails.length === 0 &&
                this.props.designCategoryForTopdesign === "view" ? (
                  <Empty style={{ paddingBottom: "15px" }} />
                ) : (
                  <React.Fragment>
                    <ResponsiveContainer width='100%' height={400}>
                      <BarChart
                        data={this.props.designAnalyticsDetails}
                        margin={{ top: 20, right: 20, bottom: 20, left: 10 }}>
                        <XAxis dataKey='_id' />
                        <YAxis width={80} />
                        <CartesianGrid strokeDasharray='3 3' />
                        <Tooltip
                          content={<CustomTooltip />}
                          label={"catCount"}
                        />
                        {/**<Legend />      */}
                        <Bar
                          dataKey='catCount'
                          fill='#1F628E'
                          width={200}
                          onClick={this.handleBarClick}
                          style={{ cursor: "pointer" }}>
                          {/*  <LabelList dataKey="id" position="top" interval={0} angle={90} dx={10} /> */}
                          <LabelList
                            dataKey='catCount'
                            position='insideTop'
                            fill='#fff'
                            interval={0}
                            angle={0}
                            dx={0}
                          />
                        </Bar>

                        {this.props.designAnalyticsDetails.length > 0 && (
                          <Brush dataKey='id' height={30} stroke='#76B9F0' />
                        )}
                      </BarChart>
                    </ResponsiveContainer>

                    <Card
                      style={{ marginBottom: "0px", cursor: "pointer" }}
                      bodyStyle={{ paddingBottom: "0px" }}
                      className='boder-only-top'>
                      <Row>
                        <Col
                          lg={18}
                          md={18}
                          sm={24}
                          xs={24}
                          style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                          <Tabs
                            onChange={this.handleChangeCategory}
                            defaultActiveKey='view'
                            tabPosition='bottom'
                            style={{
                              marginTop: "-37px",
                              borderBottom: "0px",
                              marginBottom: "20px",
                            }}>
                            <TabPane tab='Viewed' key='view'></TabPane>
                            <TabPane tab='Sold' key='sold'></TabPane>
                            <TabPane tab='Shared' key='share'></TabPane>
                          </Tabs>
                        </Col>
                        <Col
                          lg={6}
                          md={6}
                          sm={24}
                          xs={24}
                          style={{ marginTop: "-12px", paddingRight: "0px" }}>
                          <Card
                            style={{ marginBottom: "0px", cursor: "pointer" }}
                            bodyStyle={{
                              padding: "0px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}>
                            {/** <Button className="float-left btn-4-stocktally-graph" style={{ backgroundColor: "#76B9F0" }}></Button>
                <p className="float-left">Amount</p>       */}
                            <Row>
                              <Col span={24} style={{ textAlign: "center" }}>
                                <Button
                                  className='btn-4-stocktally-graph'
                                  style={{
                                    backgroundColor: "#1F628E",
                                  }}></Button>
                                Count
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </Card>
                  </React.Fragment>
                )}
              </Spin>
            </Card>

            {/**
                      <Card  className="gx-card dashbaord-card-white" title={<h2 className="text-color-4285F4" style={{ textTransform: "capitalize" }}>Category Analytics</h2>} style={{ padding: "0" }} bodyStyle={{ padding: "0" }}
          >
          
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={this.props.designAnalyticsDetails}
                  margin={{top: 20, right: 20, bottom: 20, left: 10}}>
                  <XAxis dataKey="id"
                  />
                  <YAxis width={80} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                   
                    <Bar dataKey="View" fill="#1F628E" width={20}>
                    <LabelList dataKey="View" position="insideTop"  fill="#fff" interval={0} angle={0} dx={0} /> 
                     
                  </Bar>
                   <Bar dataKey="Sold" fill="#76B9F0" >
                       <LabelList dataKey="Sold" position="insideTop"  fill="#fff" interval={0} angle={0} dx={0} /> 
                  </Bar>
                  <Bar dataKey="Share" fill="#9BD4E4">
                      <LabelList dataKey="Share" position="insideTop"  fill="#fff" interval={0} angle={0} dx={0} /> 
                 </Bar>
                  {this.props.stockTallyData.length > 0 && <Brush dataKey="id" height={30} stroke="#1F628E" />}
                </BarChart>
              </ResponsiveContainer>
          

            <Card style={{ marginBottom: "0px", cursor: "pointer" }} bodyStyle={{paddingBottom: "0px" }}  className="boder-only-top">
            <Row>
              <Col lg={10} md={10} sm={24} xs={24} style={{ paddingRight: "0px", paddingLeft: "0px" }}>
               
              </Col>
              <Col lg={14} md={14} sm={24} xs={24} style={{ marginTop:"-12px",paddingRight:"0px",float:"left" }}>
                <Card style={{ marginBottom: "10px", cursor: "pointer" }} bodyStyle={{ padding: "10px"}}>
                 {/*   <Button className="float-left btn-4-stocktally-graph" style={{ backgroundColor: "#1F628E" }}></Button>
                  <p className="float-left">View Count</p>       
                  <Button className="float-left btn-4-stocktally-graph" style={{ backgroundColor: "#76B9F0" }}></Button>
                  <p className="float-left">Sold</p>      
                  <Button className="float-left btn-4-stocktally-graph" style={{ backgroundColor: "#9BD4E4" }}></Button>
                  <p className="float-left">Share</p>       
                  
                  
                  <Row >
                  <Col span={8} style={{paddingRight:"0px",textAlign:"center"}} >
                      <Button className="btn-4-stocktally-graph" style={{ backgroundColor: "#1F628E" }}></Button>
                      Views
                      </Col>
                    <Col span={8} style={{paddingRight:"0px",textAlign:"center"}}>
                      <Button className="btn-4-stocktally-graph" style={{ backgroundColor: "#76B9F0" }}></Button>
                      Sold
                    </Col>
                    <Col span={8} style={{paddingRight:"0px",textAlign:"center"}}>
                      <Button className="btn-4-stocktally-graph" style={{ backgroundColor: "#9BD4E4" }}></Button>
                      Shared 
                    </Col>
                    
                  </Row>
                </Card>

                
              </Col>
            </Row>
          </Card>

          </Card>
          */}
          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <Card
              style={{ marginTop: "0px", padding: "0px" }}
              className='gx-card dashbaord-card-white'
              title={
                <h2
                  className='text-color-4285F4'
                  style={{ textTransform: "capitalize" }}>
                  Top 5 Employees
                </h2>
              }
              bodyStyle={{ padding: "0px" }}>
              <Spin spinning={this.props.employeeLoading}>
                {this.props.topSalesPersonDetails.length === 0 ? (
                  <Empty style={{ paddingBottom: "15px" }} />
                ) : (
                  <React.Fragment>
                    <Row style={{ padding: "24px" }}>
                      {/* //  <Col lg={24} md={24} style={{textAlign:"center",height: "194px"}}>
              //    <div style={{paddingRight: "30%" }}>
              //       <img src={ComingSoon} style={{height: "194px", position: "absolute"}}/>
              //     </div></Col> */}
                      {/* <Col lg={24} md={24} style={{textAlign:"center"}}>
              
              </Col> */}
                      {/* : */}
                      {getProcess(this.props.topSalesPersonDetails)}
                      {/* } */}
                    </Row>

                    {/* <Row style={{padding:"24px"}}>
            <Col lg={4} md={4} style={{textAlign:"center"}}>
            {this.state.employeeSwitch ?
              <React.Fragment> 
                <Progress  strokeColor="#76B9F0" width={200} style={{marginBottom:"-13px"}} type="dashboard" format={()=> currencyConverter(this.props.topSalesPersonDetails[0].Amount)} percent={this.props.topSalesPersonDetails[0].percentage}/>
                   <h3 className="process-name-text">{this.props.topSalesPersonDetails[0].Name}</h3> 
                   <h4 className="process-level-text capitalize">{this.props.topSalesPersonDetails[0].level}</h4> 
                   <h4  className="process-name-amount-qty">{this.props.topSalesPersonDetails[0].percentage}%</h4>                
              </React.Fragment>
               : 
               <React.Fragment>
                    <Progress  strokeColor="#76B9F0" width={200} style={{marginBottom:"-13px"}} type="dashboard" percent={this.props.topSalesPersonDetails[0].percentage} format={e => `${e}%`} />
                    <h3 className="process-name-text capitalize">{this.props.topSalesPersonDetails[0].Name}</h3> 
                    <h4 className="process-name-amount-qty" >{currencyConverter(this.props.topSalesPersonDetails[0].Amount)}</h4>            
               </React.Fragment>
            }  
               </Col>
            <Col lg={1} md={1} >
            
            </Col>
            <Col lg={4} md={4} style={{textAlign:"center"}}>
            {this.state.employeeSwitch ?
              <React.Fragment> 
                <Progress  strokeColor="#76B9F0" width={200} style={{marginBottom:"-13px"}} type="dashboard" format={()=> currencyConverter(this.props.topSalesPersonDetails[1].Amount)} percent={this.props.topSalesPersonDetails[1].percentage}/>
                   <h3 className="process-name-text">{this.props.topSalesPersonDetails[1].Name}</h3> 
                   <h4 className="process-level-text">{this.props.topSalesPersonDetails[1].level}</h4> 
                  <h4  className="process-name-amount-qty">{this.props.topSalesPersonDetails[1].percentage}%</h4>                
              </React.Fragment>
               : 
               <React.Fragment>
                    <Progress  strokeColor="#76B9F0" width={200} style={{marginBottom:"-13px"}} type="dashboard" percent={this.props.topSalesPersonDetails[1].percentage} format={e => `${e}%`}/>
                    <h3 className="process-name-text">{this.props.topSalesPersonDetails[1].Name}</h3> 
                    <h4 className="process-name-amount-qty" >{currencyConverter(this.props.topSalesPersonDetails[1].Amount)}</h4>                
               </React.Fragment>
            }
          
            </Col>
            <Col lg={1} md={1}>
            
            </Col>
            <Col lg={4} md={4} style={{textAlign:"center"}}>
            {this.state.employeeSwitch ?
              <React.Fragment> 
                <Progress  strokeColor="#76B9F0" width={200} style={{marginBottom:"-13px"}} type="dashboard" format={()=> currencyConverter(this.props.topSalesPersonDetails[2].Amount)} percent={this.props.topSalesPersonDetails[2].percentage}/>
                   <h3 className="process-name-text">{this.props.topSalesPersonDetails[2].Name}</h3> 
                  <h4  className="process-name-amount-qty">{this.props.topSalesPersonDetails[2].percentage}%</h4>                
              </React.Fragment>
               : 
               <React.Fragment>
                    <Progress  strokeColor="#76B9F0" width={200} style={{marginBottom:"-13px"}} type="dashboard" percent={this.props.topSalesPersonDetails[2].percentage} format={e => `${e}%`} />
                    <h3 className="process-name-text">{this.props.topSalesPersonDetails[2].Name}</h3> 
                    <h4 className="process-name-amount-qty" >{currencyConverter(this.props.topSalesPersonDetails[2].Amount)}</h4>                
               </React.Fragment>
            }
            </Col>
            <Col lg={1} md={1}>
            
            </Col>
            <Col lg={4} md={4} style={{textAlign:"center"}}>
            {this.state.employeeSwitch ?
              <React.Fragment> 
                <Progress  strokeColor="#76B9F0" width={200} style={{marginBottom:"-13px"}} type="dashboard" format={()=> currencyConverter(this.props.topSalesPersonDetails[3].Amount)} percent={this.props.topSalesPersonDetails[3].percentage}/>
                   <h3 className="process-name-text">{this.props.topSalesPersonDetails[3].Name}</h3> 
                  <h4  className="process-name-amount-qty">{this.props.topSalesPersonDetails[3].percentage}%</h4>                
              </React.Fragment>
               : 
               <React.Fragment>
                    <Progress  strokeColor="#76B9F0" width={200} style={{marginBottom:"-13px"}} type="dashboard" percent={this.props.topSalesPersonDetails[3].percentage} format={e => `${e}%`}/>
                    <h3 className="process-name-text">{this.props.topSalesPersonDetails[3].Name}</h3> 
                    <h4 className="process-name-amount-qty" >{currencyConverter(this.props.topSalesPersonDetails[3].Amount)}</h4>                
               </React.Fragment>
            }
            </Col>
            <Col lg={1} md={1}>
            
            </Col>
            <Col lg={4} md={4} style={{textAlign:"center"}}>
            {this.state.employeeSwitch ?
              <React.Fragment> 
                <Progress  strokeColor="#76B9F0" width={200} style={{marginBottom:"-13px",marginLeft: "-10px"}} type="dashboard" format={()=> currencyConverter(this.props.topSalesPersonDetails[4].Amount)} percent={this.props.topSalesPersonDetails[4].percentage}/>
                   <h3 className="process-name-text">{this.props.topSalesPersonDetails[4].Name}</h3> 
                  <h4  className="process-name-amount-qty">{this.props.topSalesPersonDetails[4].percentage}%</h4>                
              </React.Fragment>
               : 
               <React.Fragment>
                    <Progress  strokeColor="#76B9F0" width={200} style={{marginBottom:"-13px",marginLeft: "-10px"}} type="dashboard" percent={this.props.topSalesPersonDetails[4].percentage} format={e => `${e}%`} />
                    <h3 className="process-name-text">{this.props.topSalesPersonDetails[4].Name}</h3> 
                    <h4 className="process-name-amount-qty" >{currencyConverter(this.props.topSalesPersonDetails[4].Amount)}</h4>                
               </React.Fragment>
            }
            </Col>
            </Row> */}

                    <Card
                      className='dashbaord-card-white'
                      style={{ marginBottom: "0px", cursor: "pointer" }}
                      bodyStyle={{ padding: "0px" }}
                      className='boder-only-top'>
                      <Row>
                        <Col lg={20} md={20} sm={24} xs={24}>
                          <Tabs
                            onChange={this.handleEmployee}
                            defaultActiveKey='1'
                            tabPosition='bottom'
                            style={{
                              marginTop: "-17px",
                              borderBottom: "0px",
                              marginBottom: "20px",
                            }}>
                            <TabPane tab='Amount' key='1'></TabPane>
                            <TabPane tab='% Total Sales' key='2'></TabPane>
                            <TabPane tab='Conversion Ratio' key='3'></TabPane>
                          </Tabs>
                        </Col>
                        <Col
                          lg={4}
                          md={4}
                          sm={24}
                          xs={24}
                          style={{
                            marginTop: "8px",
                            paddingRight: "24px",
                            textAlign: "center",
                          }}>
                          <Card
                            style={{ marginBottom: "0px", cursor: "pointer" }}
                            bodyStyle={{
                              padding: "0px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}>
                            {/*     <Button className="float-left btn-4-stocktally-graph" style={{ backgroundColor: "#76B9F0" }}></Button>
                <p className="float-left">Amount</p>       */}
                            <Row>
                              <Col span={4}></Col>
                              <Col
                                span={20}
                                style={{
                                  paddingLeft: "0px",
                                  paddingRight: "30px",
                                  textAlign: "center",
                                }}>
                                <Button
                                  className='btn-4-stocktally-graph'
                                  style={{
                                    backgroundColor: "#76B9F0",
                                  }}></Button>
                                {this.state.employeeLabel}
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </Card>
                  </React.Fragment>
                )}
              </Spin>
            </Card>
          </Col>

          {/* --------- Customer Conversion Ratio Chart ----------*/}
          {/*   <Col lg={24} md={24} sm={24} xs={24}>
            <Card className="gx-card dashbaord-card-white" title={<h2 className="text-color-4285F4" style={{ textTransform: "capitalize" }}>Conversion Ratio</h2>} style={{ padding: "0" }} bodyStyle={{ padding: "0",textAlign:"center" }}>
                  <Spin spinning={this.props.conversionLoading} tip="Loading...">
                 {this.props.salespersonWiseConversationRatio.length >= 1 ?<React.Fragment>
  <img src={ComingSoon} style={{height: "194px", position: "absolute"}}/>
                    
                    // <div style={{paddingRight: "30%" }}> </div>

                <ResponsiveContainer width="100%" height={350}>
               
                <LineChart
                  //   data={data_particular_sales_person}
                  //onClick={this.onClickCustomerCoversation}
                  data={this.props.salespersonWiseConversationRatio}
                  margin={{ top: 10, right: 35, left: -15, bottom: 0 }}>
                  <XAxis dataKey="month" />
                  <YAxis width={80} />                  
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Line type="monotone" dataKey="Customer" stroke="#1F628E" activeDot={{r: 8}} strokeWidth={8}/>
                  <Line  dataKey="Conversion" stroke="#76B9F0" strokeWidth={8} />                

                </LineChart>
              </ResponsiveContainer>

              </React.Fragment>
                    :
                    <Empty  style={{paddingBottom:"15px"}}/>
                  }
                </Spin>
              <Card className="dashbaord-card-white" style={{ marginBottom: "0px", cursor: "pointer",paddingBottom:"25px" }} bodyStyle={{ padding: "10px", paddingBottom: "0px" }} className="boder-only-top">
                <Row>
                  <Col lg={19} md={19} sm={24} xs={24} style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                    
                    <Tabs   onChange={this.props.handleChangeParticularSalesPerson} defaultActiveKey={this.state.selectedSales}  tabPosition="bottom" style={{ marginTop: "-27px",borderBottom:"0px" }}>
                    
                    {this.state.allSalesPersons.map(user => (
                      <TabPane tab={user._id.name} key={user._id.empId}>
                      </TabPane>
                    ))}
                    
                   
                    
                    </Tabs>

                  </Col>
                  <Col lg={5} md={5} sm={24} xs={24} style={{ paddingRight: "13px", paddingLeft: "0px",textAlign:"center" }}>
                    <Card style={{ marginBottom: "-30px", cursor: "pointer" }} bodyStyle={{ padding: "10px", paddingBottom: "0px",paddingRight:"20px" }}  >
                          <Button className="float-left btn-4-stocktally-graph" style={{ backgroundColor: "#1F628E" }}></Button>
                      <p className="float-left">Customer</p>
                      <Button className="float-left btn-4-stocktally-graph" style={{ backgroundColor: "#76B9F0" }}></Button>
                      <p className="float-left">Conversion</p>

                     <Row >                 
                     <Col span={12} style={{paddingLeft:"0px",paddingRight:"0px"}}>
                       <Button className="btn-4-stocktally-graph" style={{ backgroundColor: "#1F628E",marginBottom:"10px" }}></Button>
                       Customer
                     </Col>
                     
                     <Col span={12} style={{paddingLeft:"0px",paddingRight:"0px"}}>
                       <Button className="btn-4-stocktally-graph" style={{ backgroundColor: "#76B9F0",marginBottom:"10px" }}></Button>
                       Conversion
                     </Col>
                   
                   </Row>

                    </Card>
                  </Col>
                </Row>
              </Card>


              
            </Card>
          </Col>*/}

          {/** <Col lg={24} md={24} sm={24} xs={24}>
          <Spin spinning={this.props.stokctallyLoading} tip="Loading...">
            <Card className="gx-card dashbaord-card-white" title={<h2 className="text-color-4285F4" style={{ textTransform: "capitalize" }}>Inventory Scan</h2>} style={{ padding: "0" }} bodyStyle={{ padding: "0" }}>
              
                <ResponsiveContainer width="100%" height={350}>
                  <BarChart
                    //data={data_stock_tally} 
                    // onClick={this.onClickStockTally}
                    data={this.props.stockTallyData}
                    margin={{ top: 10, right: 20, left: 0, bottom: 10 }}>
                    <XAxis dataKey="name" width={580} />
                    <YAxis width={80} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                  

                    <Bar dataKey="Found" fill="#1F628E" />
                    <Bar dataKey="NotFound" fill="#76B9F0" />
                    <Bar dataKey="Sold" fill="#9BD4E4" />
                    <Bar dataKey="Addition" fill="#CADEEF" />
                    {this.props.stockTallyData.length > 0 && <Brush dataKey="name" height={30} stroke="#1F628E" />}

                  </BarChart>
                </ResponsiveContainer>
            


              <Card className="dashbaord-card-white" style={{ marginBottom: "0px", cursor: "pointer" }} bodyStyle={{ padding: "10px", paddingBottom: "0px" }}  className="boder-only-top">
                <Row>
                  <Col lg={16} md={16} sm={24} xs={24} style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                    <Tabs onChange={this.props.handleChangeStockTally} defaultActiveKey="5" tabPosition="bottom" style={{ marginTop: "-27px",borderBottom:"0px" }}>
                      <TabPane tab="Today" key="1">
                      </TabPane>
                      <TabPane tab="Yesterday" key="2">
                      </TabPane>
                      <TabPane tab="Last week" key="3">
                      </TabPane>
                      <TabPane tab="Last 14 days" key="4">
                      </TabPane>
                      <TabPane tab="Last 30 days" key="5">
                      </TabPane>
                      <TabPane tab={<Button type="primary"  onClick={this.info} >Custome</Button>} key="6">                      
                         
                      </TabPane>  
                     
                     

                    </Tabs>
                   
                  </Col>
                  <Col lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "13px", paddingLeft: "0px" }}>
            
                    <Card  className="dashbaord-card-white" style={{ marginBottom: "-30px", cursor: "pointer" }} bodyStyle={{ padding: "10px" }}>
                        <Row >
                        <Col span={6} style={{paddingLeft:"5px",paddingRight:"0px",textAlign:"center"}} >
                            <Button className="btn-4-stocktally-graph" style={{ backgroundColor: "#1F628E" }}></Button>
                            Found
                            </Col>
                          <Col span={6} style={{paddingLeft:"0px",paddingRight:"0px",textAlign:"center"}}>
                            <Button className="btn-4-stocktally-graph" style={{ backgroundColor: "#76B9F0" }}></Button>
                            Not Found
                          </Col>
                          <Col span={6} style={{paddingLeft:"0px",paddingRight:"0px",textAlign:"center"}}>
                            <Button className="btn-4-stocktally-graph" style={{ backgroundColor: "#9BD4E4" }}></Button>
                          Sold
                          </Col>
                          <Col span={6} style={{paddingLeft:"0px",paddingRight:"0px"}}>
                            <Button className="btn-4-stocktally-graph" style={{ backgroundColor: "#CADEEF" }}></Button>
                            Addition
                          </Col>
                        </Row>
                   </Card>
                  </Col>
                </Row>
                
              </Card>


              
            </Card>
            </Spin>
          </Col>
  */}
          {/**
          <Col lg={12} md={12} sm={24} xs={24}>
            <Card className="gx-card" title="particular sales person">
              {this.state.selectedSales != "" ?
                <Select
                  showSearch
                  style={{ width: 200, marginTop: "-45px", float: "right" }}
                  placeholder="Select Sales person "
                  optionFilterProp="children"
                  defaultValue={`${this.state.selectedSales}`}
                  //  style={{marginLeft:"25px"}}
                  onChange={this.props.handleChangeParticularSalesPerson}
                //  onFocus={handleFocus}
                // onBlur={handleBlur}
                //  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >

                  {this.state.allSalesPersons.map(user => (
                    <Option key={user.empId} value={`${user.empId}`}>{user.firstname}</Option>
                  ))}
                </Select>

                : ""}

              <ResponsiveContainer width="100%" height={350}>
                <BarChart
                  //   data={data_particular_sales_person}
                  //onClick={this.onClickCustomerCoversation}
                  data={this.props.salespersonWiseConversationRatio}
                  margin={{ top: 10, right: 0, left: -15, bottom: 0 }}>
                  <XAxis dataKey="month" />
                  <YAxis width={80} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Customer" fill="#402157" />
                  <Bar dataKey="Conversion" fill="#7d69ac" />
                  {this.props.salespersonWiseConversationRatio.length > 0 && <Brush dataKey="month" height={30} stroke="#7d69ac" />}

                </BarChart>
              </ResponsiveContainer>
            </Card>
          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <Card className="gx-card" title=
              "Viewing Demand">
       
              <ResponsiveContainer width="100%" height={350}>

                <BarChart width={50} height={40}
                  //data={data_view_on_demand}
                  data={this.props.viewDemand}

                >
                  <Tooltip />
                  <Legend />
                  <YAxis width={100} onClick={this.onClickViewDemand} />
                  {this.props.graphKey.map((item, i) => <Bar dataKey={item.ctgname} fill={item.color} />)}
                  <XAxis dataKey="month" />
                  {this.props.stockTallyData.length > 0 && <Brush dataKey="month" height={30} stroke="#7d69ac" />}
                </BarChart>
              </ResponsiveContainer>
            </Card>
          </Col>
        */}
        </Row>
      </div>
    );
  }
}

branchChart = Form.create()(branchChart);
function mapStateToProps(state) {
  const { Dashboard } = state;
  const { all_sales_persons: all_sales_persons } = Dashboard;
  return { all_sales_persons };
}

const AppContainer = connect(mapStateToProps)(branchChart);

export default withRouter(AppContainer);
