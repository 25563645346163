import {
    RFIDDEVICE_INFO_DETAILS,FLOOR_LIST
} from '../../actions/Branch/index';
function branchReducer(state = {
    rfiddevice_info_details: [],floor_list:[]
}, action) {

    switch (action.type) {
        case RFIDDEVICE_INFO_DETAILS:
        return Object.assign({}, state,
            {
                rfiddevice_info_details: action.rfiddevice_info_details
            })
        case FLOOR_LIST:
        return Object.assign({}, state,
        {
                floor_list: action.floor_list
        })
      
        default:
            return state
    }
}
export default  branchReducer