import React, { Component } from 'react'
import {connect} from "react-redux";
//import {getStockByID} from '../../../actions/index';
import {Table,Row,Col,Button,Icon,Modal,List,Badge,Tag,Menu,Card,Divider,message} from "antd";
import ReactExport from "react-data-export";
import {getSKUbyID, getSKUMedia, SKU_ITEM_DETAILS} from '../../../actions/index';
import  nodataImage from "./../../../assets/images/nodata.png"
import  exportImg from "./../../../assets/images/icons/export.svg"

import Axios from 'axios';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var cookies = require('browser-cookies');

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function getlogin(){
  let webtoken = cryptr.decrypt(cookies.get('wteoben'));
  let headers = {
    'Content-Type': 'application/json',
    'type': 'Web',
    'x-web-token': webtoken,
  }
  return headers;
}

class ViewItem extends Component {

        constructor(props)
        {
            super(props);
            this.state={
                visible:false,
                itemArray: props.itemArray,
                itemview:'',
                loading:true,
                data:{url:nodataImage}
                
            }
          //  console.log(props.itemArray);
        }

    componentDidMount() {
        
        //dispatch(getCustomer());
      
      }
      

      componentDidUpdate(prevProps, prevState) {
        if (prevProps.sku_item_details != this.props.sku_item_details) {
          //  console.log( this.props.sku_item_details);
           try {
             
         
           if(this.props.sku_item_details.status)
           {
              this.setState({
                data:this.props.sku_item_details.data,
                loading:false
              })
           }else{
            this.setState({
              loading:false,   
              emptyShow:true,
              data:{url:nodataImage}
             })
             message.error(this.props.sku_item_details.errors.msg);
             
           }
          
        } catch (error) {
           console.log(error);  
        }
      }  
    }
      showModal = () => {
        this.setState({
          visible: true,
        });
        const { dispatch } = this.props;  
      //  dispatch(getStockByID(this.state.itemArray));
        dispatch(getSKUbyID(this.props.itemArray[0]));
        dispatch(getSKUMedia(this.props.itemArray[0]));
      }

      handleCancel = () => {
        this.setState({ visible: false });
      }
    
      
      itemClick = (item) => {
        // console.log(item);
        this.setState({
          itemview:item.post,
          loading:true
        })
        const { dispatch } = this.props;  
        dispatch(getSKUbyID(item.post));
        dispatch(getSKUMedia(item.post));
      }

      backlistview=()=>{
        this.setState({
          itemview:true
        })
      }
      onError = () => {
        //alert("error");
        // if (!this.state.errored) {
          let data=this.state.data;
          data.url=nodataImage;
          this.setState({
            data: data,
            table_loading:false
          });
        // }
      }
      
      handleImageLoaded=()=>{
        let data=this.state.data;
        //   data.url=nodataImage;
        if(data.url!=nodataImage){
          this.setState({
            table_loading:false
          });
        }
         
      }

  render() {
    const { visible } = this.state;
    const columns = [{
        title: 'SKU',
        dataIndex: 'sku_number',
      }, 
      {
        title: 'Dsg Code',
        dataIndex: 'design_code',
      }, 
      {
        title: 'DSG Ctg',
        dataIndex: 'design_category',
      }, 
      {
        title: 'Metal Type',
        dataIndex: 'metal_type',
      },
      {
        title: 'Design Color',
        dataIndex: 'design_color',
      },
      
      {
        title: 'Gross Wgt',
        dataIndex: 'gross_weight',
      },
      {
        title: 'Net Wgt',
        dataIndex: 'net_weight',
      },
      {
        title:'Sales Category',
        dataIndex:'sales_category'
      
      }
    ]
    return (
      <div style={{float:"left"}}>   
      {this.props.itemArray.length >=1 ? 
         <Badge count= {this.props.itemArray.length} style={this.props.stylu}  onClick={this.showModal} overflowCount={this.props.itemArray.length+1} showZero/>
         :<Badge count= {this.props.itemArray.length} style={this.props.stylu}  overflowCount={this.props.itemArray.length+1} showZero/>}
         {/**   <Tag color={this.props.color}>{this.props.itemArray.length}</Tag>
         <p style={{color:"#ed7875"}}>{this.props.itemArray.length}</p> */}  
         <Modal
         visible={visible}
         title={`${this.props.date}  -  ${this.props.name} (${this.props.itemArray.length})`}
       // title={`${this.props.name} (${<CountUp start={0} end={this.props.itemArray.length}/>})`}
         onOk={this.handleOk}
         onCancel={this.handleCancel}
         width={1250}
         footer={[
                      
          //    <ExcelFile element={<Button style={{float:"left"}}><Icon type="export" /> Export Data</Button>}>
           // <ExcelSheet dataSet={this.props.multiDataSet} name="stock">
           /// </ExcelSheet>
           // </ExcelFile>,    
            <Button key="submit" type="primary"onClick={this.handleCancel}>
              Close
            </Button>
          ]}
       //  width={1150}
        style={{paddingBottom: "0px"}}
       >
         <span>
           
         {/**    <Row>
             <Col span={8}>
             </Col>
             <Col span={16}>
                <Button style={{float:'right',marginTop:"5px"}} type="primary"   onClick={this.handleCancel}>Close</Button>
             </Col>
             </Row>
               <div style={{maxHeight:"350px",overflowY: "auto"}}>            
              {this.state.itemview ?
                <List
                size="small"
                bordered
                dataSource={this.props.itemArray}
                renderItem={item => (
                  <List.Item key={item} onClick={()=>this.itemClick({item})} style={{cursor:"pointer"}}>{item}</List.Item>
                  
                  )}
              />
                 :                
                 <a onClick={this.backlistview}>test</a>
                }
              </div>
             */}
             <Row>
             <Col lg={6} md={6} sm={24} xs={24} >
              <div style={{maxHeight:"417px",overflowY: "auto"}}> 

              <Menu className="ant-list-bordered" defaultSelectedKeys={[this.props.itemArray[0]]}>
                
              <Button style={{marginLeft:80 , marginTop:10}} onClick={() => {

                  let body = {}
                  const StrSku = this.props.itemArray
                  const StrType = this.props.name

                  body.skus = StrSku.toString()
                  body.type = StrType.toString()


                  Axios.post(`/sku/exportstockTally`,body, { headers: getlogin(), responseType: 'blob' }).then((response) => {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;

                    const fileName = this.state.itemview + '_' +this.props.date
                    link.setAttribute('download', `${fileName}.csv`);

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(`/sku/exportstockTally`,body);
                  })}}>Export</Button>

              {this.props.itemArray.map((post) =>                
                <Menu.Item className="ant-list-item" key={post} onClick={()=>this.itemClick({post})} >{post}</Menu.Item>
              )}
              </Menu>

              </div>
              </Col>
              <Col lg={18} md={18} sm={24} xs={24} >
                  <Card title={this.state.itemview}  loading={this.state.loading} >
                  <Row>
                  <Col md={8} sm={24} xs={24}>
                   {/**   <div className={`gx-product-item gx-product-vertical`}>
                      <div className="gx-product-image">
                            <div className="gx-grid-thumb-equal">
                              <a className="gx-grid-thumb-cover" href="javascript:void(0);">
                                  <object style={{maxHeight:"200px"}}  data={this.state.data.url} type="image/jpg">
                                    <img style={{maxHeight:"200px"}} src={nodataImage} />
                                  </object>
                              </a>
                             
                            </div>
                      </div>
                      <img  src={this.state.data.url}   onLoad={this.handleImageLoaded} onError={this.onError} />                                                                                
                      <center> <h4 className="gx-text">{this.state.data.sku_number}</h4></center>
                      
                      </div> */}
                      <img  src={this.state.data.url}   onLoad={this.handleImageLoaded} onError={this.onError} />                                                                                
                      <center> <h4 className="gx-text">{this.state.data.sku_number}</h4></center>
                  </Col>
                  <Col md={8} sm={24} xs={24}>                            
                      <h4 className="gx-text">Dsg Code :{this.state.data.design_code}</h4><Divider/>
                      <h4 className="gx-text">Gross Wt : {this.state.data.gross_weight}</h4><Divider/>
                      <h4 className="gx-text">Net Wt : {this.state.data.net_weight}</h4><Divider/>
                      <h4 className="gx-text">Metal Type :  {this.state.data.metal_type}</h4><Divider/>
                      <h4 className="gx-text">Design Color :  {this.state.data.design_code}</h4><Divider/>
                      <h4 className="gx-text">Gold Value : {this.state.data.gold_value}</h4><Divider/>
                      <h4 className="gx-text">Platinume Rate : {this.state.data.platinum_rate}</h4><Divider/>
                      <h4 className="gx-text">Silver Rate : {this.state.data.silver_rate}</h4>
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                      <h4 className="gx-text">Design Category : {this.state.data.design_category}</h4><Divider/>
                      <h4 className="gx-text">Customer Design Category : {this.state.data.customer_design_category}</h4><Divider/>                           
                      <h4 className="gx-text">Color Stone Quantity : {this.state.data.color_stone_quantity}</h4><Divider/>
                      <h4 className="gx-text">Diamond Quantity : {this.state.data.diamond_quantity}</h4><Divider/>
                      <h4 className="gx-text">Finding Weight : {this.state.data.finding_weight}</h4><Divider/>
                      <h4 className="gx-text">Finding Value : {this.state.data.finding_value}</h4><Divider/>
                      <h4 className="gx-text">Labour Value  : {this.state.data.labour_value}</h4><Divider/>
                      <h4 className="gx-text">Sales Value : {this.state.data.sales_value}</h4>        
                  </Col>
              </Row>     
                  </Card>
              </Col>
              </Row>
           
         </span>
       </Modal> 
      </div>
    )
  }
}

function mapStateToProps(state){
    const {Common } = state
    const {sku_item_details:sku_item_details} =  Common
     return {sku_item_details}
}
export default connect(mapStateToProps)(ViewItem)