import Axios from 'axios';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var cookies = require('browser-cookies');
export const COMPANY_LIST = 'COMPANY_LIST'
export const ADD_COMPANY = 'ADD_COMPANY'
export const UPDATE_COMPANY_ID = 'UPDATE_COMPANY_ID'
export const UPDATE_COMPANY_ACCESS = 'UPDATE_COMPANY_ACCESS'
export const ACCESS_INFO = 'ACCESS_INFO'
export const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO'
export const FILE_UPLOAD_STOCK_RESPONSE = 'FILE_UPLOAD_STOCK_RESPONSE'
export const FILE_UPLOAD_PERCENTAGE = 'FILE_UPLOAD_PERCENTAGE'

function getloginInfo() {
    let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    let headers = {
        'Content-Type': 'application/json',
        'x-web-token': webtoken,
    }
    return headers;
}

function getCompanylist(filterdata) {

    // if(filterdata != undefined)
    // {
    //   let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    //   return dispatch => {
    //       return Axios.post(`rc/companies/filter`,filterdata,
    //       { headers: getloginInfo()}
    //         )
    //       .then(response => {
    //         console.log(response.data)
    //         if(response.data!="")
    //         {

    //           dispatch(requestgetCompanylist(response.data))
    //         }
    //         else {
    //           dispatch(requestgetCompanylist([]))
    //         }
    //       })
    //   }
    // }else{
    //     let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    return dispatch => {
        return Axios.get('company/all',
            {headers: getloginInfo()}
        )
            .then(response => {
                if (response.data != "") {

                    dispatch(requestgetCompanylist(response.data))
                } else {
                    dispatch(requestgetCompanylist([]))
                }
            })
    }
    //   }

}

function requestgetCompanylist(response) {
    return {
        type: COMPANY_LIST,
        company_list: response
    }
}

export function getCompany(filterdata) {
    return (dispatch) => {
        return dispatch(getCompanylist(filterdata))
    }
}


function AddCompanyPost(info) {
    return dispatch => {
        return Axios.post('company', info,
            {headers: getloginInfo()}
        )
            .then(response => {
                if (response.data != "") {
                    dispatch(requestCompanyInfo(response.data))
                } else {
                    dispatch(requestCompanyInfo([]))
                }
            })
    }
}

function requestCompanyInfo(response) {
    return {
        type: ADD_COMPANY,
        add_company: response
    }
}

export function AddCompany(info) {
    return (dispatch) => {
        return dispatch(AddCompanyPost(info))
    }
}


export function downloadTextFile(id) {
    return dispatch => {
        return Axios.get(`company/license?id=${id}`, {
                headers: getloginInfo(),
                responseType: 'blob',
                Accept: 'text/plain'
            }
            //  return Axios.get(`http://www.orimi.com/pdf-test.pdf`,
        )
            .then(response => {
                if (response.data != "") {
                    //   let blob = new Blob([response.data], {type: `application/pdf`});
                    //    let url = URL.createObjectURL(blob);
                    //  window.open(url,'_blank');

                    // var file = new Blob([response.data], {type: 'application/pdf'});
                    //  var fileURL = URL.createObjectURL(file);
                    //  window.open(fileURL,'_blank');

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'OfflineLicense.txt');
                    document.body.appendChild(link);
                    link.click();
                    //   console.log("link");
                    //  console.log(link);
                    //  window.open(`${link}`,'_blank');
                    //  window.open(`html.pdf`,'_blank');

                    // var file = window.URL.createObjectURL(new Blob([response.data]),{type: 'application/pdf'});
                    ///var fileURL = window.URL.createObjectURL(file);
                    //window.open(file,'_blank');
                } else {
                    // console.log(response);
                    // dispatch(requestGetSuksByID([]))
                }
            });
    }
}


export function UpdateCompanyMachineID(info) {
    return dispatch => {
        return Axios.put('company/mkey', info,
            {headers: getloginInfo()}
        )
            .then(response => {
                if (response.data != "") {
                    dispatch(requestUpdateCompanyMachineID(response.data))
                } else {
                    dispatch(requestUpdateCompanyMachineID([]))
                }
            })
    }
}

function requestUpdateCompanyMachineID(response) {
    return {
        type: UPDATE_COMPANY_ID,
        update_company_id: response
    }
}


export function UpdateCompanyAccess(info) {
    return dispatch => {
        return Axios.put('access', info,
            {headers: getloginInfo()}
        )
            .then(response => {
                if (response.data != "") {
                    dispatch(requestUpdateCompanyAccess(response.data))
                } else {
                    dispatch(requestUpdateCompanyAccess([]))
                }
            })
    }
}

function requestUpdateCompanyAccess(response) {
    return {
        type: UPDATE_COMPANY_ACCESS,
        update_company_access: response
    }
}


export function getAccessInfo(info) {
    return dispatch => {
        return Axios.get(`access?phone=${info}`,
            {headers: getloginInfo()}
        )
            .then(response => {
                if (response.data != "") {
                    dispatch(requestgetAccessInfo(response.data))
                } else {
                    dispatch(requestgetAccessInfo([]))
                }
            })
    }
}

function requestgetAccessInfo(response) {
    return {
        type: ACCESS_INFO,
        access_info: response
    }
}


export function UpdateCompany(info) {
    return dispatch => {
        return Axios.put('company', {"company": info},
            {headers: getloginInfo()}
        )
            .then(response => {
                if (response.data != "") {
                    dispatch(requestUpdateCompanyInfo(response.data))
                } else {
                    dispatch(requestUpdateCompanyInfo([]))
                }
            })
    }
}

function requestUpdateCompanyInfo(response) {
    return {
        type: UPDATE_COMPANY_INFO,
        update_company_info: response
    }
}

function stockUploadFileResponseto(formData, isLogoImage, isZip) {
    let root = cookies.get('root');
    root = JSON.parse(root);
    // console.log(root);
    let urlupload = `https://fs.jeweltrace.in/images?id=${isZip}`;
    console.log(urlupload);
    let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    // urlupload=`/images?id=${root.companyId}&isCompanyLogo=${isLogoImage}&webtoken=${webtoken}`;

    let headers = {
        'id': isZip
    }
    if (isLogoImage == true) {
        headers = {
            'id': isZip,
            'isCompanyLogo': isLogoImage,
            'webtoken': webtoken
        }
    }
    return dispatch => {
        return Axios.post(`${urlupload}`, formData,
            {
                headers: headers,
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent);
                    this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    // console.log(this.uploadPercentage);
                    dispatch(requestfileUploadPercentage(this.uploadPercentage - 1));
                }
            })
            //    return Axios.post('v1/sku/file',formData, getAhuthanticication())
            .then(response => {

                if (response.data !== "") {
                    dispatch(requeststockUploadFileResponse(response.data))
                } else {
                    dispatch(requeststockUploadFileResponse([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // dispatch(push('/'));
                        //window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
}

function requeststockUploadFileResponse(response) {
    return {
        type: FILE_UPLOAD_STOCK_RESPONSE,
        file_upload_stock_response: response
    }
}

export function imageUpload(response, isLogoImage, isZip) {
    return (dispatch) => {
        return dispatch(stockUploadFileResponseto(response, isLogoImage, isZip))
    }
}

function requestfileUploadPercentage(response) {
    return {
        type: FILE_UPLOAD_PERCENTAGE,
        file_upload_percentage: [response]
    }
}