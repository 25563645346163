import React, { Component } from "react";
import { Button, Form, message, Modal, Row, Icon, Col, Select, Spin, Table } from "antd";
import * as XLSX from "xlsx";
import {
  getAllBrachList,
  getAllFloorList,
  getAllSectionList,
  getAllSubSectionList,
  getSKUByRootInfoAndId,
  getStockByBranch,
  getStockFromExcel,
    stockTransfer,
    stockwebTransfer,
} from "../../../actions/index";
import { connect } from "react-redux";
import ImportIcon from "./../../../assets/images/icons/import.svg";

message.config({
  duration: 2,
  maxCount: 3,
});
const { Option } = Select;
var cookies = require("browser-cookies");

var result = window.location.href;
var arr = result.split("/");

class TransferStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      uploadvisible: false,
      dublicate_data: [],
      uploadingspin: false,
      selected_id: "",
      filelist: true,
      branch_list: [],
      branch_id: "",
      floor_list: [],
      fromfloor_id: "",
        fromsection_id: "",
        tofloor_id: "",
        tosection_id: "",
      section_list: [],
      sub_section_list: [],
        fromsub_section_id: "",
        tosub_section_id: "",
      firstlevel: true,
      companyId: "",
      usertype: "",
      isUploadLoading: false,
      lastlevel: false,
      currancyCode: "USD",
      fileuploadlevel: false,
      formData: {},
      dataUpload: {},
      stockData: {},
      skuDataFromExcel: [],
      skuDataByBranch: [],
      root: {
        companyId: "",
        branchId: "",
        floorId: "",
        sectionId: "",
        subSectionId: "",
      },
      rootInfo: "",
      id: "",
      selectedRowKeys: [],
      selectedRows: [],
      fromBranch: "",
      fromCompany: "",
      fromFloor: "",
      fromSection: "",
      fromSubSection: "",
    };
  }

  componentDidMount() {
    let usertype = localStorage.getItem("type_user");
    let root = JSON.parse(cookies.get("root"));
    const { dispatch } = this.props;
    dispatch(getAllBrachList());
    // console.log("root");

    let newroot = {
      companyId: root.companyId,
      branchId: root.branchId,
      floorId: root.floorId,
      sectionId: root.sectionId,
      subSectionId: root.subSectionId,
    };

    this.setState({
      root: newroot,
      usertype: usertype,
    });
    if (usertype === "branchAdmin") {
      this.setState({
        rootInfo: "branch",
        textForSelect: "Select Floor",
        id: root.branchId,
      });

      // dispatch(getAllFloorList("id"));
      //dispatch(getAllBrachList("byCompanyId", root.companyId));
    }
    if (usertype === "companyAdmin") {
      this.setState({
        rootInfo: "company",
        textForSelect: "Select Branch",
        id: root.companyId,
      });
      //dispatch(getAllBrachList("id"));
    }
    if (usertype === "floorAdmin") {
      this.setState({
        rootInfo: "floor",
        textForSelect: "Select Section",
        id: root.floorId,
      });
      //dispatch(getAllSectionList("id"));
      //dispatch(getAllBrachList("byCompanyId", root.companyId));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevProps.sku_webtransfer != this.props.sku_webtransfer) {
        if (this.props.sku_webtransfer.status) {
          if (this.props.sku_webtransfer.data_array.length > 0) {
            this.setState({
              visible: true,
            });
          } else {
            message.success("Stock transferred successfully");
            this.setState({
              filelist: false,
              uploadvisible: false,
              firstlevel: true,
            });
            const { dispatch } = this.props;
            dispatch(
              getSKUByRootInfoAndId(
                this.state.id,
                this.state.rootInfo,
                0,
                10,
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                ""
              )
            );
          }
        } else {
          Modal.error({
            title: "error ",
            content: `${this.props.sku_webtransfer.errors.msg ||
              this.props.sku_webtransfer.errors}`,
          });
          // message.error(this.props.sku_transfer.errors.msg);
          this.setState({
            filelist: false,
            uploadvisible: false,
            firstlevel: true,
            fileuploadlevel: false,
            lastlevel: false,
          });
        }
      }

      if (prevProps.replace_stock != this.props.replace_stock) {
        console.log("replce -success");
        console.log(this.props.replace_stock);
        if (this.props.replace_stock.status) {
          message.error(this.props.replace_stock.errors.msg);
          const { dispatch } = this.props;
          dispatch(
            getSKUByRootInfoAndId(
              this.state.selected_id,
              this.state.rootInfo,
              0,
              10,
              "",
              "",
              "",
              "",
              "",
              ""
            )
          );
        } else {
          message.error(this.props.replace_stock.errors.msg);
        }
      }

      if (prevProps.file_upload_status != this.props.file_upload_status) {
        console.log("file_upload_status -success");
        console.log(this.props.file_upload_status);
        this.setState({
          isUploadLoading: this.props.file_upload_status.isUploadLoading,
        });
      }
      if (prevProps.sku_from_excel != this.props.sku_from_excel) {
        if (this.props.sku_from_excel.status) {
          this.setState({
            skuDataFromExcel: this.props.sku_from_excel.data_array,
            fromBranch: this.props.sku_from_excel.data_array[0].root.branchId,
            fromCompany: this.props.sku_from_excel.data_array[0].root.companyId,
            fromFloor: this.props.sku_from_excel.data_array[0].root.floorId,
            fromSection: this.props.sku_from_excel.data_array[0].root.sectionId,
            fromSubSection: this.props.sku_from_excel.data_array[0].root
              .subSectionId,
          });
          console.log(
            "fromBranch"
            // this.state.fromBranch,
            // this.state.fromCompany,
            // this.state.fromFloor,
            // this.state.fromSection,
            // this.state.fromSubSection
          );
        } else {
          this.setState({
            visible: false,
          });
          Modal.error({
            title: "error ",
            content: `${this.props.sku_from_excel.errors.msg}`,
          });
        }
      }
      if (prevState.skuDataFromExcel !== this.state.skuDataFromExcel) {
        this.setState({
          fromBranch: this.state.skuDataFromExcel[0].root.branchId,
          fromCompany: this.state.skuDataFromExcel[0].root.companyId,
          fromFloor: this.state.skuDataFromExcel[0].root.floorId,
          fromSection: this.state.skuDataFromExcel[0].root.sectionId,
          fromSubSection: this.state.skuDataFromExcel[0].root.subSectionId,
        });
        console.log(
          "fromBranch",
          this.state.fromBranch,
          this.state.fromCompany,
          this.state.fromFloor,
          this.state.fromSection,
          this.state.fromSubSection
        );

        var similar =
          this.state.skuDataFromExcel.length > 0 &&
          this.state.skuDataFromExcel.every(
            (x, i, a) => x.root.branchId === a[0].root.branchId
          );
        console.log("pp", this.state.skuDataFromExcel);
        if (!similar) {
          Modal.error({
            title: "error ",
            content: `Branches are not same. Please remove different branch.`,
          });
        }
      }
      if (prevState.selectedRowKeys !== this.state.selectedRowKeys) {
        console.log("selectedRowKeys", this.state.selectedRows);
      }
      if (prevProps.sku_by_branch != this.props.sku_by_branch) {
        if (this.props.sku_by_branch.status) {
          this.setState({
            skuDataByBranch: this.props.sku_by_branch.data_array,
          });
            console.log("skuda:", this.props.sku_by_branch.data_array)
        } else {
          this.setState({
            visible: false,
          });
          Modal.error({
            title: "error ",
            content: `${this.props.sku_by_branch.errors.msg}`,
          });
        }
      }

      if (prevProps.branch_list != this.props.branch_list) {
        if (this.props.branch_list.status) {
          console.log("lop", this.props.branch_list.data_array);
          this.setState({
            selected_id: this.props.branch_list.data_array[0]._id,
            branch_list: this.props.branch_list.data_array,
            branch_id: this.props.branch_list.data_array[0]._id,
          });
          const { dispatch } = this.props;

          if (this.state.usertype === "floorAdmin") {
            dispatch(getAllFloorList("bybranchId", this.state.root.branchId));
          }
          if (this.state.usertype === "branchAdmin") {
            dispatch(getAllFloorList("bybranchId", this.state.root.branchId));
          }
          if (this.state.usertype === "companyAdmin") {
            dispatch(
              getAllFloorList(
                "bybranchId",
                this.props.branch_list.data_array[0]._id
              )
            );
            // dispatch(
            //   getAllFloorList(
            //     "bybranchId",
            //     this.props.branch_list.data_array[0]._id
            //   )
            // );
          }
        }
      }

      if (prevProps.floor_list != this.props.floor_list) {
        if (this.props.floor_list.status) {
          this.setState({
            selected_id: this.props.floor_list.data_array[0]._id,
            floor_list: this.props.floor_list.data_array,
            floor_id: this.props.floor_list.data_array[0]._id,
          });
          const { dispatch } = this.props;

          // dispatch(getAllSectionList("byFloorId", this.state.floor_id));
          //dispatch(
          //  getAllSectionList(
          //    "byFloorId",
          //    this.props.floor_list.data_array[0]._id
          //  )
          //);
        }
      }

      if (prevProps.section_list != this.props.section_list) {
        //console.log("this.props.section_list");
        // console.log(this.props.section_list);
        if (this.props.section_list.status) {
          this.setState({
            selected_id: this.props.section_list.data_array[0]._id,
            section_list: this.props.section_list.data_array,
            section_id: this.props.section_list.data_array[0]._id,
          });
          const { dispatch } = this.props;
          //dispatch(
          //  getAllSubSectionList(
          //    "bySectionId",
          //    this.props.section_list.data_array[0]._id
          //  )
          //);
        }
      }

      if (prevProps.sub_section_list != this.props.sub_section_list) {
        //console.log(this.props.sub_section_list);
        if (this.props.sub_section_list.status) {
          this.setState({
            sub_section_list: this.props.sub_section_list.data_array,
          });

          if (this.props.sub_section_list.data_array.length >= 1) {
            this.setState({
              sub_section_id: this.props.sub_section_list.data_array[0]._id,
            });
            const { dispatch } = this.props;
            //dispatch(getAllUserList("subSection",this.props.sub_section_list.data_array[0]._id));
          }
        } else {
          this.setState({
            sub_section_list: [],
          });
        }
        this.setState({
          table_loading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleOk = () => {
    const { dispatch } = this.props;
    console.log(this.state.skuDataFromExcel, "this.state.skuDataFromExcel");

    const onlyInLeft =
      this.state.skuDataByBranch.length > 0 &&
      this.state.skuDataByBranch.filter((leftValue) =>
        this.state.skuDataFromExcel.some(
          (rightValue) => rightValue.sku_number === leftValue.sku_number
        )
      );
    console.log("filteredKeywords", onlyInLeft);
    if (this.state.skuDataByBranch.length < 0) {
      if (onlyInLeft.length > 0) {
        this.setState({
          visible: true,
        });
        Modal.error({
          title: "Error ",
          content: `SKU Numbers ${onlyInLeft.map(
            (r) => r.sku_number
          )} already present in this branch`,
        });
      } else {
        dispatch(stockwebTransfer(this.state.stockData));
        this.setState({
          visible: false,
        });
      }
    } else {
      dispatch(stockwebTransfer(this.state.stockData));
      this.setState({
        visible: false,
      });
    }
  };

  uploadhandleCancel = () => {
    //console.log('Clicked cancel button');
    //console.log(this.state.root);
    this.setState({
      uploadvisible: false,
      filelist: true,
      isUploadLoading: false,
      fileuploadlevel: false,
      firstlevel: true,
      lastlevel: false,
    });
  };

  uploadhandleOpen = () => {
    //console.log('Clicked cancel button');
    if (this.props.data.length > 0) {
      this.setState({
        uploadvisible: true,
        firstlevel: true,
        filelist: true,
        isUploadLoading: false,
      });
    }
  };
  handleNext = () => {
    //console.log('Clicked cancel button');
    if (this.state.ValidUservisible === true) {
      return;
    }

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      let companyId = values.companyId;
      let branchId = values.branchId;
      let floorId = values.floorId;
      let sectionId = values.sectionId;
      let subSectionId = values.subSectionId;
        //let frombranchId = values.frombranchId;
        //let fromfloorId = values.fromfloorId;
        //let fromsectionId = values.fromsectionId;
        //let fromsubSectionId = values.fromsubSectionId;
        //let tobranchId = values.tobranchId;
        //let tofloorId = values.tofloorId;
        //let tosectionId = values.tosectionId;
        //let tosubSectionId = values.tosubSectionId;
      console.log(this.state.root);
      if (this.state.usertype === "floorAdmin") {
        companyId = this.state.root.companyId;
        branchId = this.state.root.branchId;
        floorId = this.state.root.floorId;
      }
      if (this.state.usertype === "branchAdmin") {
        companyId = this.state.root.companyId;
        branchId = this.state.root.branchId;
      }
      if (this.state.usertype === "companyAdmin") {
        companyId = this.state.root.companyId;
      }

      let root = {
        companyId: companyId,
        branchId: branchId,
        floorId: floorId,
        sectionId: sectionId,
        subSectionId: subSectionId,
      };
      this.setState({
        firstlevel: false,
        lastlevel: true,
      });
      this.setState({
        fileuploadlevel: true,
      });
      console.log("this.state.root");
      console.log(values);
      if (
        values.k14 != undefined &&
        values.k18 != undefined &&
        values.k22 != undefined &&
        values.k24 != undefined &&
        values.Value_addition != undefined &&
        values.metal_type != undefined
      ) {
        let data = [
          {
            metal: {
              main_type: [
                {
                  kt: "14",
                  price: values.k14,
                },
                {
                  kt: "18",
                  price: values.k18,
                },
                {
                  kt: "22",
                  price: values.k22,
                },
                {
                  kt: "24",
                  price: values.k24,
                },
              ],
              metal_type: values.metal_type,
            },
            currency_code: values.currency_code,
            conversion_rate: values.conversion_rate,
            currency_symbol: values.symbol,
            valueAddition: `${values.Value_addition}`,
            companyId: this.state.root.companyId,
            branchId: this.state.root.branchId,
            floorId: this.state.root.floorId,
            sectionId: this.state.root.sectionId,
            subSectionId: this.state.root.subSectionId,
          },
          {
            metal: {
              main_type: [
                {
                  kt: "Pt",
                  price: values.pt,
                },
              ],
              metal_type: values.metal_type1,
            },
            currency_code: values.currency_code,
            conversion_rate: values.conversion_rate,
            currency_symbol: values.symbol,
            valueAddition: `${values.Value_addition}`,
            companyId: this.state.root.companyId,
            branchId: this.state.root.branchId,
            floorId: this.state.root.floorId,
            sectionId: this.state.root.sectionId,
            subSectionId: this.state.root.subSectionId,
          },
        ];
        console.log("data", data);

        let dataUpload = {
          metal: {
            main_type: [
              {
                kt: "14",
                price: values.k14,
              },
              {
                kt: "18",
                price: values.k18,
              },
              {
                kt: "22",
                price: values.k22,
              },
              {
                kt: "24",
                price: values.k24,
              },
            ],
            metal_type: values.metal_type,
          },
          currency_code: values.currency_code,
          conversion_rate: values.conversion_rate,
          currency_symbol: values.symbol,
          valueAddition: `${values.Value_addition}`,
          companyId: this.state.root.companyId,
          branchId: this.state.root.branchId,
          floorId: this.state.root.floorId,
          sectionId: this.state.root.sectionId,
          subSectionId: this.state.root.subSectionId,
        };

        //const {dispatch} = this.props;
        //console.log(data);
        //dispatch(updateTagPricing(data));
        this.setState({
          formData: data,
          dataUpload,
        });
      }
      console.log("fileuploadlevel");

      //dispatch(createUser(values));
    });
  };
  handleCancel = () => {
    //console.log('Clicked cancel button');

    this.setState({
      visible: false,
      filelist: true,
    });
    const { dispatch } = this.props;
    //  dispatch(getSKUByRootInfoAndId(this.props.selected_id,this.props.rootInfo,0,10,"","","","","",""));
    };


    onChangeBranchRadio = (e, branchType) => {
        console.log(`radio checked: ${e}`);
        const newState = {
            table_loading: true,
            floor_id: "",
            section_id: "",
            sub_section_id: "",
            floor_list: [],
            section_list: [],
            sub_section_list: [],
            data: [],
        };

        if (branchType === 'from') {
            this.setState({ frombranch_id: e, ...newState }, () => {
                const { dispatch } = this.props;
                dispatch(getAllFloorList("bybranchId", e)).then(() => {
                    // After fetching floor list, select the first floor automatically
                    if (this.state.floor_list.length > 0) {
                        const firstFloorId = this.state.floor_list[0]._id;
                        this.props.form.setFieldsValue({
                            fromfloorId: firstFloorId
                        });
                        this.setState({ fromfloor_id: firstFloorId }, () => {

                            dispatch(getAllFloorList("bybranchId", e)).then(() => {

                                if (this.state.section_list.length > 0) {
                                    const firstSectionId = this.state.section_list[0]._id;
                                    this.props.form.setFieldsValue({
                                        fromsectionId: firstSectionId
                                    });
                                    this.setState({ fromsection_id: firstSectionId }, () => {

                                        dispatch(getAllFloorList("bybranchId", e)).then(() => {

                                            if (this.state.sub_section_list.length > 0) {
                                                const firstSubSectionId = this.state.sub_section_list[0]._id;
                                                this.props.form.setFieldsValue({
                                                    fromsub_sectionId: firstSubSectionId
                                                });
                                                this.setState({ fromsub_section_id: firstSubSectionId });
                                            }
                                        });
                                    });
                                }
                            });
                        });
                    }
                });
            });
        } else if (branchType === 'to') {
            this.setState({ tobranch_id: e, ...newState }, () => {
                const { dispatch } = this.props;
                dispatch(getAllFloorList("bybranchId", e)).then(() => {

                    if (this.state.floor_list.length > 0) {
                        const firstFloorId = this.state.floor_list[0]._id;
                        this.props.form.setFieldsValue({
                            tofloorId: firstFloorId
                        });
                        this.setState({ tofloor_id: firstFloorId }, () => {

                            dispatch(getAllFloorList("bybranchId", e)).then(() => {

                                if (this.state.section_list.length > 0) {
                                    const firstSectionId = this.state.section_list[0]._id;
                                    this.props.form.setFieldsValue({
                                        tosectionId: firstSectionId
                                    });
                                    this.setState({ tosection_id: firstSectionId }, () => {

                                        dispatch(getAllFloorList("bybranchId", e)).then(() => {

                                            if (this.state.sub_section_list.length > 0) {
                                                const firstSubSectionId = this.state.sub_section_list[0]._id;
                                                this.props.form.setFieldsValue({
                                                   tosub_sectionId: firstSubSectionId
                                                });
                                                this.setState({ tosub_section_id: firstSubSectionId });
                                            }
                                        });
                                    });
                                }
                            });
                        });
                    }
                });
            });
        }
    };




  onChangefromFloor = (e) => {
    console.log(`radio floor:${e}`);
    this.setState({
      table_loading: true,
      fromfloor_id: e,
      section_id: "",
      sub_section_id: "",
      section_list: [],
      sub_section_list: [],
      data: [],
      skus: [],
    });
    //this.props.form.resetFields(['sectionId','subSectionId']);
    const { dispatch } = this.props;
    dispatch(getAllSectionList("byFloorId", e));
    };

    onChangetoFloor = (e) => {
        console.log(`radio floor:${e}`);
        this.setState({
            table_loading: true,
            tofloor_id: e,
            section_id: "",
            sub_section_id: "",
            section_list: [],
            sub_section_list: [],
            data: [],
            skus: [],
        });
        //this.props.form.resetFields(['sectionId','subSectionId']);
        const { dispatch } = this.props;
        dispatch(getAllSectionList("byFloorId", e));
    };

  onChangefromSection = (e) => {
    console.log(`radio section:${e}`);
    this.setState({
      table_loading: true,
      fromsection_id: e,
      sub_section_id: "",
      sub_section_list: [],
    });
    const { dispatch } = this.props;
    //this.props.form.resetFields(['subSectionId']);
    dispatch(getAllSubSectionList("bySectionId", e));
    };

    onChangetoSection = (e) => {
        console.log(`radio section:${e}`);
        this.setState({
            table_loading: true,
            tosection_id: e,
            sub_section_id: "",
            sub_section_list: [],
        });
        const { dispatch } = this.props;
        //this.props.form.resetFields(['subSectionId']);
        dispatch(getAllSubSectionList("bySectionId", e));
    };

  onChangefromSubSection = (e) => {
    console.log(`radio section:${e}`);
    this.setState({
      table_loading: true,
      fromsub_section_id: e,
    });
    };

    onChangetoSubSection = (e) => {
        console.log(`radio section:${e}`);
        this.setState({
            table_loading: true,
            tosub_section_id: e,
        });
    };

    onChangeFloor = (e) => {
        // console.log(`radio floor:${e}`);
        this.setState({
            table_loading: true,
            floor_id: e,
            section_id: '',
            sub_section_id: '',
            section_list: [],
            sub_section_list: [],
            data: []
        })
        //this.props.form.resetFields(['sectionId','subSectionId']);
        const { dispatch } = this.props;
        dispatch(getAllSectionList("byFloorId", e));
    }

    onChangeSection = (e) => {
        // console.log(`radio section:${e}`);
        this.setState({
            table_loading: true,
            section_id: e,
            sub_section_id: '',
            sub_section_list: [],
        })
        const { dispatch } = this.props;
        //this.props.form.resetFields(['subSectionId']);
        dispatch(getAllSubSectionList("bySectionId", e));
    }

  // before upload filed
  changeCurrancy = (e) => {
    let amt = "";
    if (e == "$") {
      amt = "USD";
    } else if (e == "₹") {
      amt = "INR";
    } else if (e == "€") {
      amt = "EUR";
    } else if (e == "£") {
      amt = "GBP";
    }
    this.setState({
      currancyCode: amt,
    });
  };
  onImportExcel = (file, addItem) => {
    let root = JSON.parse(cookies.get("root"));
    let newRoot = this.props.data.length > 0 && this.props.data[0].root;
    const { files } = file.target;
    let from = {};
    let to = {};
    if (this.state.usertype === "INCHARGE") {
      from.companyId = this.state.fromCompany;
      to.companyId = newRoot.companyId;
      from.branchId = this.state.fromBranch;
      to.branchId = newRoot.branchId;
      from.floorId = this.state.fromFloor;
      to.floorId = newRoot.floor_id;
      from.sectionId = this.state.fromSection;
      to.sectionId = newRoot.section_id;
      from.subSectionId = this.state.fromSubSection;
      to.subSectionId = this.state.sub_section_id;
    }
    if (this.state.usertype === "floorAdmin") {
      from.companyId = this.state.fromCompany;
      to.companyId = newRoot.companyId;
      from.branchId = this.state.fromBranch;
      to.branchId = newRoot.branchId;
      from.floorId = this.state.fromFloor;
      to.floorId = newRoot.floorId;
      from.sectionId = this.state.fromSection;
      to.sectionId = this.state.section_id;
      from.subSectionId = this.state.fromSubSection;
      to.subSectionId = this.state.sub_section_id;
    }
    if (this.state.usertype === "branchAdmin") {
      from.companyId = this.state.fromCompany;
      to.companyId = newRoot.companyId;
      from.branchId = this.state.fromBranch;
      to.branchId = newRoot.branchId;
      from.floorId = this.state.fromFloor;
      to.floorId = this.state.floor_id;
      from.sectionId = this.state.fromSection;
      to.sectionId = this.state.section_id;
      from.subSectionId = this.state.fromSubSection;
      to.subSectionId = this.state.sub_section_id;
    }
    if (this.state.usertype === "companyAdmin") {
      from.companyId = this.state.root.companyId;
      to.companyId = this.state.root.companyId;
      from.branchId = this.state.frombranch_id;
      to.branchId = this.state.tobranch_id;
      from.floorId = this.state.fromfloor_id;
      to.floorId = this.state.tofloor_id;
      from.sectionId = this.state.fromsection_id;
      to.sectionId = this.state.tosection_id;
      from.subSectionId = this.state.fromsub_section_id;
      to.subSectionId = this.state.tosub_section_id;
    }

    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      try {
        const { result } = event.target;

        const workbook = XLSX.read(result, { type: "binary" });
        console.log(workbook, "opop");
        let data = [];
        let dataObj = {};
        let sku_numbers = [];
        for (const Sheet in workbook.Sheets) {
          // var XL_row_object =
          XLSX.utils.sheet_to_row_object_array(workbook.Sheets[Sheet]);
          if (workbook.Sheets.hasOwnProperty(Sheet)) {
            data = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[Sheet]);
            data.forEach((x) => {
              sku_numbers.push(x.RFID_Number || x.sku_number || x.Sku_Number);
            });
          }
        }
        this.setState({ skus: sku_numbers });
        console.log("opop", root);
        this.setState({
          visible: true,
          uploadvisible: false,
          dublicate_data: data,
        });
        dataObj.comment = "";
        dataObj.issuedItemsList = sku_numbers;
        dataObj.itemsForceReceived = [];
        dataObj.receivedBy = "";
        dataObj.receivedItemsList = [];
        dataObj.status = "received";
        dataObj.fromDepartment = from;
        dataObj.toDepartment = to;
        let skus = sku_numbers.toString();
        this.props.dispatch(
          getStockFromExcel("", "", "", "", root.companyId, "", "", skus)
        );
        this.props.dispatch(
          getStockByBranch("", "", "", "", this.state.branch_id, "", "")
        );

        this.setState({ stockData: dataObj });

        // message.success("upload success!");
      } catch (e) {
        console.log("opop", root);
        // message = message.error("file type is incorrect!");
      }
    };
    fileReader.readAsBinaryString(files[0]);
  };

  removeSku(data) {
    let newData = data;
    let Data = this.state.skuDataFromExcel;
    for (let index = 0; index < newData.length; index++) {
      Data = Data.filter(function(obj) {
        return obj._id !== newData[index]._id;
      });
    }
    this.setState({
      skuDataFromExcel: Data,
      fromBranch: Data[0].root.branchId,
      fromCompany: Data[0].root.companyId,
      fromFloor: Data[0].root.floorId,
      fromSection: Data[0].root.sectionId,
      fromSubSection: Data[0].root.subSectionId,
    });
  }

  render() {
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys: selectedRowKeys,
          selectedRows: selectedRows,
        });
      },
      hideDefaultSelections: true,
      onSelection: this.onSelection,
    };
    const columns = [
      //{
      //  title: "Branch",
      //      render: (text, record) => {
      //          console.log("Branch List:", record.root.branchId);
      //    let branchName = this.state.branch_list.filter(
      //      (branch) => branch._id === record.root.branchId
      //    );
      //    return <span>{branchName.name}</span>;
      //  },
      //  },
        {
            title: "Branch",
            render: (text, record) => {
                console.log("Branch List:", this.state.branch_list);
                let branchName = this.state.branch_list.find(
                    (branch) => branch._id === record.root.branchId
                );
                return <span>{branchName ? branchName.name : ''}</span>;
            },
        },
      {
        title: "SKU",
        dataIndex: "sku_number",
      },
      {
        title: "Dsg Code",
        dataIndex: "design_code",
      },
      {
        title: "DSG Ctg",
        dataIndex: "design_category",
      },
      {
        title: "Metal Type",
        dataIndex: "metal_type",
      },

        {
            title: "NET WGT",
            dataIndex: "gross_weight",
            width: "11%",
            sorter: (a, b) => { },
            render: (text, record) => {
                let rate = parseFloat(text).toFixed(2);
                return <span>{rate}</span>;
            }
        },
        {
            title: "NET WGT",
            dataIndex: "net_weight",
            width: "11%",
            sorter: (a, b) => { },
            render: (text, record) => {
                let rate = parseFloat(text).toFixed(2);
                return <span>{rate}</span>;
            }
        },
    ];
    const { dispatch } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 24 },
      //   labalAlign:{marginLeft: "15px"},
      wrapperCol: { span: 24 },
    };

    return (
      <React.Fragment>
        <img
          className='import-icon'
          onClick={this.uploadhandleOpen}
          src={ImportIcon}
          style={{
            cursor: this.props.data.length === 0 ? "not-allowed" : "pointer",
          }}
        />
        <Modal
          title='Transfer Stock '
          visible={this.state.uploadvisible}
          onOk={this.uploadhandleCancel}
          //     confirmLoading={confirmLoading}
          style={{ paddingBottom: "0px" }}
          width={650}
          onCancel={this.uploadhandleCancel}
          footer={[
              <span style={{ marginBottom: "20px" }}>
                  <Icon
                      type="link"
                      style={{ marginRight: "30px", fontSize: "20px", cursor: "pointer" }}
                      //onClick={handleDownloadExcelTemplate}
                  />
              <Button key='back' onClick={this.uploadhandleCancel} ghost>
                Close
              </Button>
              {this.state.firstlevel === true ||
              this.state.fileuploadlevel === false ? (
                <Button key='submit' type='primary' onClick={this.handleNext}>
                  Next
                </Button>
              ) : (
                ""
              )}
            </span>,
          ]}>
          <Form layout='horizontal' onSubmit={this.FormSubmit}>
            {this.state.firstlevel === true &&
            this.state.usertype === "companyAdmin" ? (
              <React.Fragment>

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label='From Branch'
                                            className='customFormItem'
                                            {...formItemLayout}>
                                            {getFieldDecorator("frombranchId", {
                                                initialValue: this.state.frombranch_id,
                                                rules: [
                                                    { required: true, message: "Please select branch!" },
                                                ],
                                            })(
                                                <Select
                                                    placeholder='Please select branch'
                                                    onChange={(e) => this.onChangeBranchRadio(e, 'from')}>
                                                    {this.state.branch_list.length > 0 &&
                                                        this.state.branch_list.map((data, index) => (
                                                            <Select.Option
                                                                key={data._id}
                                                                value={data._id}
                                                            >
                                                                {data.name}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='To Branch'
                                            className='customFormItem'
                                            {...formItemLayout}>
                                            {getFieldDecorator("tobranchId", {
                                                initialValue: this.state.tobranch_id,
                                                rules: [
                                                    { required: true, message: "Please select branch!" },
                                                ],
                                            })(
                                                <Select
                                                    placeholder='Please select branch'
                                                    onChange={(e) => this.onChangeBranchRadio(e, 'to')}>
                                                    {this.state.branch_list.length > 0 &&
                                                        this.state.branch_list.map((data, index) => (
                                                            <Select.Option
                                                                key={data._id}
                                                                value={data._id}
                                                            >
                                                                {data.name}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label='From Floor'
                                            className='customFormItem'
                                            {...formItemLayout}>
                                            {getFieldDecorator("fromfloorId", {
                                                initialValue: this.state.fromfloor_id,
                                                rules: [
                                                    { required: true, message: "Please select floor!" },
                                                ],
                                            })(
                                                <Select
                                                    placeholder='Please select floor'
                                                    onChange={this.onChangefromFloor}
                                                >
                                                    {this.state.floor_list.map((data, index) => (
                                                        <Option key={data._id} value={data._id}>
                                                            {data.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='To Floor'
                                            className='customFormItem'
                                            {...formItemLayout}>
                                            {getFieldDecorator("tofloorId", {
                                                initialValue: this.state.tofloor_id,
                                                rules: [
                                                    { required: true, message: "Please select floor!" },
                                                ],
                                            })(
                                                <Select
                                                    placeholder='Please select floor'
                                                    onChange={this.onChangetoFloor}>
                                                    {this.state.floor_list.map((data, index) => (
                                                        <Option key={data._id} value={data._id}>
                                                            {data.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label='From Section'
                                            className='customFormItem'
                                            {...formItemLayout}>
                                            {getFieldDecorator("fromsectionId", {
                                                initialValue: this.state.fromsection_id,
                                                rules: [
                                                    { required: true, message: "Please select section!" },
                                                ],
                                            })(
                                                <Select
                                                    placeholder='Please select section'
                                                    onChange={this.onChangefromSection}
                                                >
                                                    {this.state.section_list.map((data, index) => (
                                                        <Option key={data._id} value={data._id}>
                                                            {data.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='To Section'
                                            className='customFormItem'
                                            {...formItemLayout}>
                                            {getFieldDecorator("tosectionId", {
                                                initialValue: this.state.tosection_id,
                                                rules: [
                                                    { required: true, message: "Please select section!" },
                                                ],
                                            })(
                                                <Select
                                                    placeholder='Please select section'
                                                    onChange={this.onChangetoSection}>
                                                    {this.state.section_list.map((data, index) => (
                                                        <Option key={data._id} value={data._id}>
                                                            {data.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label='From SubSection'
                                            className='customFormItem'
                                            {...formItemLayout}>
                                            {getFieldDecorator("fromsubSectionId", {
                                                initialValue: this.state.fromsub_section_id,
                                                rules: [
                                                    { required: true, message: "Please select subsection!" },
                                                ],
                                            })(
                                                <Select
                                                    placeholder='Please select subsection'
                                                    onChange={this.onChangefromSubSection}
                                                >
                                                    {this.state.sub_section_list.map((data, index) => (
                                                        <Option key={data._id} value={data._id}>
                                                            {data.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='To SubSection'
                                            className='customFormItem'
                                            {...formItemLayout}>
                                            {getFieldDecorator("tosubSectionId", {
                                                initialValue: this.state.tosub_section_id,
                                                rules: [
                                                    { required: true, message: "Please select subsection!" },
                                                ],
                                            })(
                                                <Select
                                                    placeholder='Please select subsection'
                                                    onChange={this.onChangetoSubSection}>
                                                    {this.state.sub_section_list.map((data, index) => (
                                                        <Option key={data._id} value={data._id}>
                                                            {data.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
              </React.Fragment>
            ) : (
              ""
            )}
            {this.state.firstlevel === true &&
            this.state.usertype === "branchAdmin" ? (
              <React.Fragment>
                <Form.Item
                  label='Select Floor'
                  className='customFormItem'
                  {...formItemLayout}>
                  {getFieldDecorator("floorId", {
                    initialValue: this.state.floor_id,
                    rules: [
                      { required: true, message: "Please select floor!" },
                    ],
                  })(
                    <Select
                      placeholder='Please select floor'
                      onChange={this.onChangeFloor}>
                          {this.state.floor_list.map((data, index) => (
                              <Option key={data._id} value={data._id}>
                                  {data.name}
                              </Option>
                          ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item
                  label='Select Section '
                  className='customFormItem'
                  {...formItemLayout}>
                  {getFieldDecorator("sectionId", {
                    initialValue: this.state.section_id,
                    rules: [
                      { required: true, message: "Please select section!" },
                    ],
                  })(
                    <Select
                      placeholder='Please select section'
                      onChange={this.onChangeSection}>
                      {this.state.section_list.map((data, index) => (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item
                  label='Select SubSection'
                  className='customFormItem'
                  {...formItemLayout}>
                  {getFieldDecorator("subSectionId", {
                    initialValue: this.state.sub_section_id,
                    rules: [
                      { required: true, message: "Please select subsection!" },
                    ],
                  })(
                    <Select
                      placeholder='Please select subsection'
                      onChange={this.onChangeSubSection}>
                      {this.state.sub_section_list.map((data, index) => (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </React.Fragment>
            ) : (
              ""
            )}
            {this.state.firstlevel === true &&
            this.state.usertype === "floorAdmin" ? (
              <React.Fragment>
                <Form.Item
                  label='Select Section '
                  className='customFormItem'
                  {...formItemLayout}>
                  {getFieldDecorator("sectionId", {
                    initialValue: this.state.section_id,
                    rules: [
                      { required: true, message: "Please select section!" },
                    ],
                  })(
                    <Select
                      placeholder='Please select section'
                      onChange={this.onChangeSection}>
                      {this.state.section_list.map((data, index) => (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item
                  label='Select SubSection'
                  className='customFormItem'
                  {...formItemLayout}>
                  {getFieldDecorator("subSectionId", {
                    initialValue: this.state.sub_section_id,
                    rules: [
                      { required: true, message: "Please select subsection!" },
                    ],
                  })(
                    <Select
                      placeholder='Please select subsection'
                      onChange={this.onChangeSubSection}>
                      {this.state.sub_section_list.map((data, index) => (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </React.Fragment>
            ) : (
              ""
            )}

            {this.state.fileuploadlevel === true &&
            this.state.firstlevel === false ? (
              <Spin
                tip='Please wait stock uploading...'
                spinning={this.state.isUploadLoading}>
                <input
                  className='file-uploader'
                  type='file'
                  accept='.xlsx, .xls, .csv'
                  onChange={this.onImportExcel}
                  value=''
                />
              </Spin>
            ) : (
              ""
            )}
          </Form>
        </Modal>
        <Modal
          title='Following Stock to transfer '
          visible={this.state.visible}
          onOk={this.handleOk}
          //     confirmLoading={confirmLoading}
          style={{ paddingBottom: "0px" }}
          width={1150}
          onCancel={this.handleCancel}
          footer={[
            <span style={{ marginBottom: "20px" }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  type='danger'
                  onClick={() => {
                    this.removeSku(this.state.selectedRows);
                  }}
                  ghost>
                  Remove
                </Button>
              )}
              <Button key='back' onClick={this.handleCancel} ghost>
                Cancel
              </Button>
              <Button key='submit' type='primary' onClick={this.handleOk}>
                Transfer
              </Button>
            </span>,
          ]}>
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={this.state.skuDataFromExcel}
            size={"small"}
            rowKey={(record) => record.id}
            style={{ marginBottom: "0px" }}
          />
        </Modal>
      </React.Fragment>
    );
  }
}
TransferStock = Form.create()(TransferStock);
function mapStateToProps(state) {
  const { Common } = state;
  const {
      sku_transfer: sku_transfer,
      sku_webtransfer: sku_webtransfer,
    branch_list: branch_list,
    floor_list: floor_list,
    section_list: section_list,
    sub_section_list: sub_section_list,
    sku_from_excel: sku_from_excel,
    sku_by_branch: sku_by_branch,
  } = Common;
  return {
      sku_transfer,
      sku_webtransfer,
    branch_list,
    floor_list,
    section_list,
    sub_section_list,
    sku_from_excel,
    sku_by_branch,
  };
}

const AppContainer = connect(mapStateToProps)(TransferStock);

export default AppContainer;
