// import {getStockfromRfid} from '../../actions/actions';
import { connect } from "react-redux";
import React, { Component } from "react";
import {
  Modal,
  Form,
  Table,
  Upload,
  Card,
  Col,
  Icon,
  InputNumber,
  Button,
  message,
  Row,
  Radio,
  Switch,
  Select,
  AutoComplete,
  Input,
  Divider,
  Checkbox,
  List,
  Typography
} from "antd";
import {
    UpdateCompanyMachineID,
    getCompany
} from "../../../actions/scadmin";
import "./style.css";
import moment from "moment";

message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});

const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

class EditComapany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            table_loading: false,
            ValidUservisible: false,
            ValidCompanyvisible: false
        }
    };

    componentDidMount() {
        const { dispatch } = this.props;
    }

    componentDidUpdate(prevProps, prevState) {
        var that = this;
    try {
    if (prevProps.update_company_id != that.props.update_company_id) {
      if (that.props.update_company_id.status) {
        message.success("Machine Id update successfully");  
        this.setState({
          visible: false
        });
        const { dispatch } = this.props;
        this.props.form.resetFields();
        dispatch(getCompany());

      }else {
        message.error(that.props.update_company_id.errors.msg);  
      }
    }
} catch (error) {
    
}
  }

  state = {
    loading: false,
    visible: false,
    copied: false,
    companyType: "Retailers",
    ValidUservisible: false,
    ValidCompanyvisible: false,
    moduleList: []
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };
  handleCancel = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
  };
 

  SingupSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        // const {dispatch} = this.props;
        // let userdata=values;

        let updateCom = {
          //    "name": values.compnayname,
          companyType: values.companyType,
          tax_no: values.tax_no,
          address: values.address,
          phone: values.phone,
          noOfUserAllowed: values.noOfUserAllowed,
          server_url: values.server_url,
          module: values.module,
          status: values.status
        };
        // console.log(creatCom);
        const { dispatch } = this.props;
        dispatch(UpdateCompanyMachineID(updateCom, values.id));
      }
    });
  };

  

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // const { keys, names } = values;
        // console.log('Received values of form: ', values);
        // console.log('Merged values:', keys.map(key => names[key]));
        // let mkey=keys.map(key => names[key]);
        // console.log(mkey);
        // if(JSON.stringify(mkey)==JSON.stringify(this.props.machineId)){
        //     message.error("Please add or update machine id");
        //     return
        // } 
        let result=this.props.machineId.includes(values.mkey);
        if(result){
            message.error("Machine key already exists")
            return 
        }

        const { dispatch } = this.props;
        dispatch(UpdateCompanyMachineID({mkey:values.mkey,phone:this.props.phone}));

      }
    });
  };

  render() {
    const { visible } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
      },
    };
   
    return (
      <div>
        {this.props.text}
        <Icon
          type="cluster"
          onClick={this.showModal}
          style={{ fontSize: 20,  cursor: "pointer",paddingLeft: "10px", float:"left" }}
        />

        <Modal
          title="Add machine key"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width={1000}
          style={{ paddingBottom: "166px" }}
        >
          <span>
         
          <Card bodyStyle={{paddingBottom:"0px"}}>
            <Form onSubmit={this.handleSubmit} formItemLayout="inline">
           
                         <FormItem
                            {...formItemLayout}
                            label="Machine Id"
                            >
                            {getFieldDecorator('mkey', {
                                rules: [{ required: true, message: 'Please input machine id!' }],
                            })(
                                <Input size="default"   placeholder="Machine Id"  />
                            )}
                        </FormItem>

                <Form.Item {...formItemLayoutWithOutLabel}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>
            </Form>
            </Card>
     
            <List
            header={null}
            footer={null}
            bordered
            dataSource={this.props.machineId}
            renderItem={(item,index) => (
                <List.Item>
                <Typography.Text mark> </Typography.Text>{index+1}.  {item}
                </List.Item>
            )}
          />
                    
                
          </span>
        </Modal>
      </div>
    );
  }
}

EditComapany = Form.create()(EditComapany);

function mapStateToProps(state) {
  const { scadmin } = state;
  const {
    update_company_id: update_company_id,
  } = scadmin;
  return { update_company_id};
}

const AppContainer = connect(mapStateToProps)(EditComapany);
export default AppContainer;
