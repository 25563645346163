import React, {Component} from 'react'
import {connect} from "react-redux";
import moment from 'moment';
import {Card, Col, DatePicker, Divider, Form, Icon, Input, Row, Select, Spin} from "antd";
import {
    getAllBrachList,
    getAllFloorList,
    getAllSectionList,
    getCompanyMedia,
    getDisplayConfigurations,
    getSKUbyID,
} from '../../actions/index';
import {
    getAllSalesPerson,
    getAllTotal,
    getCutomerConversationRatio,
    getCutomerConversationRatioPerticularSalesPersonWise,
    getDesignAnalytics,
    getStockTally,
    getTopCategoryWiseSale,
    getTopDesignSale,
    getTopSalePerson,
    getViewDemand,
} from '../../actions/dashboard';
import BranchChart from "./branchChart";
import ItemView from "./itemView";
import {currencyConverter} from "../../containers/App/commanFunction";
import CountUp from 'react-countup';

const InputGroup = Input.Group;
const {RangePicker} = DatePicker;
var cookies = require('browser-cookies');
const Option = Select.Option;

class dashboard extends Component {
    constructor(props) {
        super(props);
        var now = new Date();

        let fromDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(30, "days").toISOString();
        let toDate = new Date().toISOString();
        this.state = {
            textForSelect: '',
            branch_list: [],
            section_list: [],
            floor_list: [],
            fromDate: fromDate,
            toDate: toDate,
            stockStally: [],
            rootInfoEqual: 'company',
            id: '',
            selectedId: '',
            topSalesPersonDetails: [{Amount: 0, total: 0, Name: '', level: '', percentage: 0}, {
                Amount: 0,
                total: 0,
                Name: '',
                level: '',
                percentage: 0
            }, {Amount: 0, total: 0, Name: '', level: '', percentage: 0}, {
                Amount: 0,
                total: 0,
                Name: '',
                level: '',
                percentage: 0
            }, {Amount: 0, total: 0, Name: '', level: '', percentage: 0}],
            topSalesCategoryDetails: [],
            topSalesDesignDetails: [],
            customerConversationRatio: [],
            designAnalyticsDetails: [],
            salespersonWiseConversationRatio: [],
            viewDemand: [],
            graphKey: [],
            stokctallyLoading: true,
            selectedSalesPerson: '',
            limitTopSalesPerson: 5,
            limitTopCategoryWiseSale: 5,
            limitTopDesingnSale: 5,
            designCategoryForTopdesign: "view",
            //total_details:{memo: 0,order: 0,quote: 0,sale: 0,sku: 0},
            total_details: {
                box1lable: '',
                box12lable: '',
                box3lable: '',
                box4lable: '',
                box5lable: '',
                box6lable: '',
                box7lable: '',
                box1value: 0,
                box2value: 0,
                box3value: 0,
                box4value: 0,
                box5value: 0,
                box6value: 0,
                box7value: 0
            },
            showDateView: true,
            selectedTabTopDesignGraph: "view",
            viewVisibility: false,
            data_array_total_details: [],
            itemArray: [],
            heightForLablevalue4: 20,
            heightForLablevalue3: 20,
            callskuapi: false,
            employeeLoading: true,
            conversionLoading: true,
            categoryAnalyticsLoading: true
        }
    }

    componentDidMount() {
        const {dispatch} = this.props;
        let usertype = localStorage.getItem('type_user');
        let root = JSON.parse(cookies.get('root'));

        if (usertype === "branchAdmin") {
            this.setState({
                rootInfo: "floor",
                rootInfoEqual: "branch",
                textForSelect: "Select Floor",
                id: root.branchId,
                selectedId: root.branchId,
                selectedRootInfoEqual: "branch"
            });
            dispatch(getAllFloorList("id"));
            dispatch(getAllTotal("branch", root.branchId, this.state.fromDate, this.state.toDate));
            dispatch(getAllSalesPerson("branch", root.branchId));
        }
        if (usertype === "companyAdmin") {
            this.setState({
                rootInfo: "branch",
                rootInfoEqual: "company",
                textForSelect: "Select Branch",
                id: root.companyId,
                selectedId: root.companyId,
                selectedRootInfoEqual: "company"
            });
            dispatch(getAllBrachList("id"));
            dispatch(getAllTotal("company", root.companyId, this.state.fromDate, this.state.toDate));
            dispatch(getAllSalesPerson("company", root.companyId));
        }
        if (usertype === "floorAdmin") {
            this.setState({
                rootInfo: "section",
                rootInfoEqual: "floor",
                textForSelect: "Select Floor",
                id: root.floorId,
                selectedId: root.floorId,
                selectedRootInfoEqual: "floor"
            });
            dispatch(getAllSectionList("id"));
            dispatch(getAllTotal("floor", root.floorId, this.state.fromDate, this.state.toDate));
            dispatch(getAllSalesPerson("floor", root.floorId));
        }
        if (localStorage.getItem("DetailView") == null)
            dispatch(getDisplayConfigurations());

        if (localStorage.getItem("imageMapping") == null)
            dispatch(getCompanyMedia(root.companyId));

        //get all salespers

        this.setState({
            checkNick: false
        })

        //   socket.on('Notification', (col) => {
        //     try {
        //       if(col.data_code==="501"){
        //         let newData=col.data_obj;
        //         let Data=this.state.stockStally;
        //        // console.log(Data);
        //         let obj={"Found":newData.skuItemsFound.length,
        //         "NotFound":newData.skuItemsNotFound.length,
        //         "Sold":newData.skuItemsSold.length,
        //         "Addition":newData.extraTag.length,
        //         "name":moment(newData.createdAt).format("YYYY-MM-DD h:mm:ss A")
        //       }
        //         Data.unshift(obj);
        //         this.setState({
        //           stockStally:Data,
        //         })
        //      //   console.log(Data);
        //     //  const { dispatch } = this.props;
        //     //  dispatch(getStockTally(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
        //       }
        //       } catch (e) {
        //         console.log("error", e);
        //       }
        //    })
        //   // dispatch(getCustomer());
        //   //   dispatch(getQuotation());
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            // if (prevProps.stocktally_details != this.props.stocktally_details) {

            //    if(this.props.stocktally_details.status)
            //    {
            //     let array =this.props.stocktally_details.data_array;
            //     let stockArray=[];
            //     for (let index = 0; index < array.length; index++) {
            //       const element = array[index].skuItemsFound.length;
            //       let obj={"Found":array[index].skuItemsFound.length,
            //       "NotFound":array[index].skuItemsNotFound.length,
            //       "Sold":array[index].skuItemsSold.length,
            //       "Addition":array[index].extraTag.length,
            //       "name":moment(array[index].createdAt).format("YYYY-MM-DD h:mm:ss A")
            //     }
            //       stockArray.push(obj);
            //     }
            //       this.setState({
            //         stockStally:stockArray,
            //       })
            //    }
            //    this.setState({
            //     stokctallyLoading:false
            //    })
            //   }


            if (prevProps.all_sales_persons != this.props.all_sales_persons) {
                try {
                    if (this.props.all_sales_persons.status) {
                        this.setState({
                            allSalesPersons: this.props.all_sales_persons.data_array,
                            selectedSalesPerson: this.props.all_sales_persons.data_array[0].empId
                        });
                        const {dispatch} = this.props;
                        dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.props.all_sales_persons.data_array[0]._id.empId));
                    }
                    this.setState({
                        table_loading: false
                    })
                } catch (error) {
                    console.log(error);
                }
            }

            if (prevProps.branch_list != this.props.branch_list) {
                // console.log("this.props.branch_list");
                // console.log(this.props.branch_list);
                if (this.props.branch_list.status) {
                    const {dispatch} = this.props;
                    //get all salespers
                    //  dispatch(getAllTotal(this.state.selectedRootInfoEqual,this.state.selectedId));
                    // dispatch(getAllSalesPerson(this.state.selectedRootInfoEqual,this.state.selectedId));
                    //graph one Stock Scan
                    // dispatch(getStockTally(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                    //graph 4 Employees
                    dispatch(getTopSalePerson(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.limitTopSalesPerson));
                    //topSalesCategoryDetails
                    // dispatch(getTopCategoryWiseSale(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,this.state.limitTopCategoryWiseSale));
                    //graph 2  Top 5/10 Design Sales
                    dispatch(getTopDesignSale(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.limitTopDesingnSale));
                    //CUSTOMER CONVERSATION RATIO
                    //   dispatch(getCutomerConversationRatio(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                    //Top 5 Design - Analytics
                    dispatch(getDesignAnalytics(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.designCategoryForTopdesign));
                    // dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                    //Viewing Demand
                    // dispatch(getViewDemand(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                    this.setState({
                        branch_list: this.props.branch_list.data_array,
                    });
                } else {
                    this.setState({
                        branch_list: []
                    });
                }
            }

            if (prevProps.floor_list != this.props.floor_list) {
                const {dispatch} = this.props;
                //  dispatch(getAllSalesPerson(this.state.selectedRootInfoEqual,this.state.selectedId));

                //dispatch(getStockTally(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                dispatch(getTopSalePerson(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.limitTopSalesPerson));
                // dispatch(getTopCategoryWiseSale(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,this.state.limitTopCategoryWiseSale));
                dispatch(getTopDesignSale(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.limitTopDesingnSale));
                //CUSTOMER CONVERSATION RATIO
                // dispatch(getCutomerConversationRatio(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                dispatch(getDesignAnalytics(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.designCategoryForTopdesign));
                // dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                //  dispatch(getViewDemand(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                if (this.props.floor_list.status) {
                    this.setState({
                        floor_list: this.props.floor_list.data_array
                    });
                } else {
                    this.setState({
                        floor_list: []
                    });
                }
            }

            if (prevProps.section_list != this.props.section_list) {
                const {dispatch} = this.props;
                // console.log(this.props.section_list.data_array);
                // dispatch(getAllSalesPerson(this.state.selectedRootInfoEqual,this.state.selectedId));
                // dispatch(getStockTally(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                dispatch(getTopSalePerson(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.limitTopSalesPerson));
                // dispatch(getTopCategoryWiseSale(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,this.state.limitTopCategoryWiseSale));
                dispatch(getTopDesignSale(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.limitTopDesingnSale));
                //CUSTOMER CONVERSATION RATIO
                // dispatch(getCutomerConversationRatio(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                dispatch(getDesignAnalytics(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.designCategoryForTopdesign));
                // dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                //dispatch(getViewDemand(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
                if (this.props.section_list.status) {
                    this.setState({
                        section_list: this.props.section_list.data_array,
                    });
                } else {
                    this.setState({
                        section_list: []
                    });
                }
            }


            if (prevProps.top_sales_person_details != this.props.top_sales_person_details) {

                if (this.props.top_sales_person_details.status) {

                    let arrayData = this.props.top_sales_person_details.data_array;
                    let newArray = [];
                    let totalamount = 0;
                    this.setState({
                        topSalesPersonDetails: [{Amount: 0, total: 0, Name: '', level: '', percentage: 0}, {
                            Amount: 0,
                            total: 0,
                            Name: '',
                            level: '',
                            percentage: 0
                        }, {Amount: 0, total: 0, Name: '', level: '', percentage: 0}, {
                            Amount: 0,
                            total: 0,
                            Name: '',
                            level: '',
                            percentage: 0
                        }, {Amount: 0, total: 0, Name: '', level: '', percentage: 0}],
                    })

                    for (let index = 0; index < arrayData.length; index++) {
                        const element = arrayData[index];
                        let newa = element._id.split(',');
                        totalamount += element.Amount;
                        newArray.push({
                            Name: newa[1],
                            Amount: element.Amount,
                            total: totalamount,
                            per: element.per,
                            level: element.level
                        });

                    }
                    let finalArray = [];
                    for (let index = 0; index < newArray.length; index++) {
                        if (newArray.length > index) {
                            //let percentage=Math.round((newArray[index].Amount/newArray[newArray.length-1].total)*100).toFixed(2);
                            finalArray.push({
                                Name: newArray[index].Name,
                                Amount: newArray[index].Amount.toFixed(0),
                                percentage: newArray[index].per,
                                level: newArray[index].level,
                            });
                        } else {
                            finalArray.push({Amount: 0, total: 0, Name: '', level: '', percentage: 0});
                            // percentage:'',
                        }
                    }


                    //console.log(newArray[0].Amount/totalamount);
                    this.setState({
                        topSalesPersonDetails: finalArray,
                        stokctallyLoading: false,
                        employeeLoading: false
                    })
                }
                this.setState({
                    table_loading: false,
                    employeeLoading: false
                })
            }

            if (prevProps.top_sales_category_details != this.props.top_sales_category_details) {
                // console.log("this.props.top_sales_category_details");
                // console.log(this.props.top_sales_category_details);
                if (this.props.top_sales_category_details.status) {
                    this.setState({
                        topSalesCategoryDetails: this.props.top_sales_category_details.data_array,
                    })
                }
                this.setState({
                    table_loading: false,
                    stokctallyLoading: false
                })
            }

            if (prevProps.top_sales_design_details != this.props.top_sales_design_details) {
                try {

                    if (this.props.top_sales_design_details.status) {
                        let salesDesingData = this.props.top_sales_design_details.data;
                        let newArray = [];
                        let viewedList = salesDesingData.viewedList;
                        for (let index = 0; index < viewedList.length; index++) {
                            const element = viewedList[index];
                            if (element.amount > 0) {
                                element.Amountlable = currencyConverter(element.amount);
                            } else {
                                element.Amountlable = element.amount;
                            }
                            newArray.push(element);
                        }
                        let soldList = salesDesingData.soldList;
                        for (let index = 0; index < soldList.length; index++) {
                            const element = soldList[index];
                            if (element.amount > 0) {
                                element.Amountlable = currencyConverter(element.amount);
                            } else {
                                element.Amountlable = element.amount;
                            }
                            newArray.push(element);
                        }
                        // salesDesingData.soldList;
                        //  console.log(soldList)
                        //  if(soldList===undefined || soldList ===""){
                        //   soldList=[]
                        //  }
                        //  if(viewedList===undefined || viewedList ===""){
                        //   soldList=[]
                        //  }

                        this.setState({
                            soldList: soldList,
                            viewedList: viewedList
                        })
                        if (this.state.selectedTabTopDesignGraph === "view") {
                            this.setState({
                                topSalesDesignDetails: viewedList,
                            })
                        } else {
                            this.setState({
                                topSalesDesignDetails: soldList
                            })
                        }
                    }
                    this.setState({
                        table_loading: false,
                        stokctallyLoading: false
                    })


                } catch (error) {

                }
            }
            if (prevProps.view_demand != this.props.view_demand) {
                let array1 = this.props.view_demand.data_array;
                //  moment(element.month, 'YYYY-MM').format('MMMM-YYYY')

                if (this.props.view_demand.status) {
                    //console.log(Object.keys(array1));
                    let keyObject = [];
                    for (let index = 0; index < array1.length; index++) {
                        const element = array1[index];
                        let SecondArray = Object.keys(element);
                        keyObject = keyObject.concat(SecondArray);
                    }
                    // get key from array
                    let graphKey = new Set(keyObject);
                    graphKey = Array.from(graphKey);
                    const valueToRemove = 'month'
                    const filteredItems = graphKey.filter(item => item !== valueToRemove)
                    let newArray = [];
                    let color = ["#7d69ac", "#402157", "#9a1752"]
                    for (let index = 0; index < filteredItems.length; index++) {
                        const element = filteredItems[index];
                        let newObj = {
                            ctgname: element,
                            color: color[index]
                        }
                        newArray.push(newObj);
                    }
                    this.setState({
                        viewDemand: this.props.view_demand.data_array,
                        graphKey: newArray
                    })
                }
                this.setState({
                    table_loading: false,
                    stokctallyLoading: false
                })
            }


            if (prevProps.customer_conversation_ratio != this.props.customer_conversation_ratio) {


                if (this.props.customer_conversation_ratio.status) {
                    let array = this.props.customer_conversation_ratio.data_array;
                    let newArray = [];
                    for (let index = 0; index < array.length; index++) {
                        const element = array[index];

                        let newObj = {
                            Customer: element.convert,
                            month: moment(element.month, 'YYYY-MM').format('MMMM-YYYY')
                        }
                        newArray.push(newObj);
                    }
                    this.setState({
                        customerConversationRatio: newArray,
                    })
                }
                this.setState({
                    table_loading: false,
                    stokctallyLoading: false
                })
            }


            if (prevProps.design_analytics_details != this.props.design_analytics_details) {
                // console.log("this.props.customer_conversation_ratio");
                // console.log(this.props.design_analytics_details);
                if (this.props.design_analytics_details.status) {
                    this.setState({
                        designAnalyticsDetails: this.props.design_analytics_details.data.countList,
                        //     designAnalyticsDetails:[
                        //       {
                        //         "id": "Bangle",
                        //         "View": 100,
                        //         "Sold": 8
                        //       },
                        //      {
                        //         "id": "Pendant",
                        //         "View": 90,
                        //         "Sold": 8
                        //       },
                        //     {
                        //         "id": "Ring",
                        //         "View": 80,
                        //         "Sold": 1
                        //       },
                        //       {
                        //         "id": "Ring",
                        //         "View": 70,
                        //         "Sold": 1
                        //       },
                        //       {
                        //         "id": "RNG",
                        //         "View": 60,
                        //         "Sold": 3
                        //       },
                        //       {
                        //         "id": "SET",
                        //         "View": 50
                        //       },
                        //      {
                        //         "id": "CHAIN",
                        //         "View": 40,
                        //         "Sold": 1
                        //       },
                        //       {
                        //         "id": "KADLI",
                        //         "View": 30,
                        //         "Sold": 1
                        //       },
                        //       {
                        //         "id": "BROOCH",
                        //         "View": 20,
                        //         "Sold": 3
                        //       },
                        //       {
                        //         "id": "P.CHAIN",
                        //         "View": 10
                        //       }
                        //     ]
                    })
                }
                this.setState({
                    table_loading: false,
                    stokctallyLoading: false,
                    categoryAnalyticsLoading: false
                })
            }


            if (prevProps.salesperson_wise_conversation_ratio != this.props.salesperson_wise_conversation_ratio) {
                // console.log("salesperson_wise_conversation_ratio");
                // console.log(this.props.salesperson_wise_conversation_ratio);
                let array = this.props.salesperson_wise_conversation_ratio.data_array;
                let newArray = [];
                for (let index = 0; index < array.length; index++) {
                    const element = array[index];
                    let newObj = {
                        Customer: element.total,
                        month: moment(element.month, 'YYYY-MM').format('MMMM-YYYY'),
                        Conversion: element.convert
                    }
                    newArray.push(newObj);
                }
                if (this.props.salesperson_wise_conversation_ratio.status) {
                    this.setState({
                        salespersonWiseConversationRatio: newArray,
                    })
                }
                this.setState({
                    table_loading: false,
                    stokctallyLoading: false,
                    conversionLoading: false
                })
            }


            if (prevProps.total_details != this.props.total_details) {
                if (this.props.total_details.status) {
                    let Objekey = Object.keys(this.props.total_details.data);
                    let totalData = this.props.total_details.data;
                    // console.log(totalData[Objekey[2]]);
                    let totalDetails = {
                        box1lable: Objekey[0],
                        box2lable: Objekey[1],
                        box3lable: Objekey[2],
                        box4lable: Objekey[3],
                        box5lable: Objekey[4],
                        box6lable: Objekey[5],
                        box7lable: Objekey[6],
                        box1value: totalData[Objekey[0]],
                        box2value: totalData[Objekey[1]],
                        box3value: totalData[Objekey[2]],
                        box4value: totalData[Objekey[3]],
                        box5value: totalData[Objekey[4]],
                        box6value: totalData[Objekey[5]],
                        box7value: totalData[Objekey[6]]
                    };


                    let fontSizeForLablevalue4 = 25;
                    let customize = totalData[Objekey[3]].length;
                    let heightForLablevalue3 = 25;
                    let heightForLablevalue4 = 25;
                    if (customize <= 20) {
                        fontSizeForLablevalue4 = 17
                        heightForLablevalue3 = 25
                    } else if (customize > 20 && customize < 49) {
                        fontSizeForLablevalue4 = 14
                        heightForLablevalue3 = 25
                    } else {
                        fontSizeForLablevalue4 = 7
                        heightForLablevalue3 = 25
                    }


                    let fontSizeForLablevalue3 = 24;
                    let customize1 = totalData[Objekey[2]].length;
                    if (customize1 <= 20) {
                        fontSizeForLablevalue3 = 17
                            heightForLablevalue4 = 24
                    } else if (customize1 > 20 && customize1 < 49) {
                        fontSizeForLablevalue3 = 14
                        heightForLablevalue4 = 25
                    } else {
                        fontSizeForLablevalue3 = 7
                        heightForLablevalue4 = 25
                    }


                    this.setState({
                        total_details: totalDetails,
                        data_array_total_details: this.props.total_details.data_array,
                        fontSizeForLablevalue4,
                        fontSizeForLablevalue3,
                        heightForLablevalue3,
                        heightForLablevalue4
                    });
                }
                this.setState({
                    table_loading: false
                })
            }

            if (prevProps.display_config_details != this.props.display_config_details) {
                if (this.props.display_config_details != null) {
                    // console.log("display config ----- ", this.props.display_config_details)
                    this.props.display_config_details.map((x, index) => {
                        x.key = index;
                        if (x.screen === "DetailView") {
                            x.fields.sort((a, b) => (a.isSelected) ? -1 : ((b.isSelected) ? 1 : 0));
                            x.fields.sort((a, b) => a.key > b.key ? 1 : -1)
                            // console.log("Fields -- ", x.fields);
                            localStorage.setItem(x.screen, JSON.stringify(x.fields));
                        }
                    });
                }
            }

            if (prevProps.media_config_details != this.props.media_config_details) {
                if (this.props.media_config_details != null) {
                    // console.log("media config ----- ", this.props.media_config_details)
                    if (this.props.media_config_details.status && this.props.media_config_details.data.imageMapping != null) {
                        localStorage.setItem('imageMapping', this.props.media_config_details.data.imageMapping);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    onChangeBranchRadio = (e) => {
        //console.log(`radio checked:${e.target.value}`);
        this.setState({
            stokctallyLoading: true,
            selectedId: e,
        })
        const {dispatch} = this.props;
        // console.log("sdsd", e, this.state.id);
        // console.log(this.state.rootInfoEqual);
        // console.log(this.state.rootInfo);
        let selectedRootInfoEqual = this.state.rootInfo;
        if (e == this.state.id) {
            // console.log(0, this.state.fromDate, this.state.toDate, this.state.rootInfoEqual, e);
            //dispatch(getStockTally(0,this.state.fromDate,this.state.toDate,this.state.rootInfoEqual,e));
            this.setState({
                selectedRootInfoEqual: this.state.rootInfoEqual
            });
            selectedRootInfoEqual = this.state.rootInfoEqual;
        } else {
            this.setState({
                selectedRootInfoEqual: this.state.rootInfo
            });
            // console.log(0, this.state.fromDate, this.state.toDate, this.state.rootInfo, e);
            //dispatch(getStockTally(0,this.state.fromDate,this.state.toDate,this.state.rootInfo,e));
        }
        dispatch(getAllTotal(selectedRootInfoEqual, e, this.state.fromDate, this.state.toDate));
        dispatch(getTopSalePerson(0, this.state.fromDate, this.state.toDate, selectedRootInfoEqual, e, this.state.limitTopSalesPerson));
        dispatch(getTopCategoryWiseSale(0, this.state.fromDate, this.state.toDate, selectedRootInfoEqual, e, this.state.limitTopCategoryWiseSale));
        dispatch(getTopDesignSale(0, this.state.fromDate, this.state.toDate, selectedRootInfoEqual, e, this.state.limitTopDesingnSale));
        //CUSTOMER CONVERSATION RATIO
        dispatch(getCutomerConversationRatio(0, this.state.fromDate, this.state.toDate, selectedRootInfoEqual, e));
        dispatch(getDesignAnalytics(0, this.state.fromDate, this.state.toDate, selectedRootInfoEqual, e, this.state.designCategoryForTopdesign));
        dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0, this.state.fromDate, this.state.toDate, selectedRootInfoEqual, this.state.selectedId, this.state.selectedSalesPerson));
        dispatch(getViewDemand(0, this.state.fromDate, this.state.toDate, selectedRootInfoEqual, e));
    }
    itemClick = (item) => {
        // console.log(item);
        this.setState({
            sku_number: item
        });
    }
    getTimePeriod = (e) => {

        if (e === "v7") {
            this.setState({
                showDateView: false
            })
        } else {


            try {
                var now = new Date();
                let fromDates = e;
                var fromDate = fromDates.trim();
                var toDate = now.toISOString();
                this.setState({
                    fromDate: fromDate,
                    toDate: toDate,
                    stokctallyLoading: true
                });
                this.getdateChangeApi(fromDate, toDate);
                //  console.log(0,fromDate,toDate,this.state.selectedRootInfoEqual,this.state.selectedId);

            } catch (error) {
                console.log(error);
            }
        }
    }
    getTimePeriodWithbothdate = (e) => {

        try {
            var fromDate = '';
            var toDate = '';
            let fromDate1 = moment(e[0]).utc().set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0});
            let toDate1 = moment(e[1]).utc().set({'hour': 23, 'minute': 59, 'second': 0, 'millisecond': 0});
            fromDate = fromDate1.toISOString();
            toDate = toDate1.toISOString();

            this.setState({
                fromDate: fromDate,
                toDate: toDate,
                stokctallyLoading: true
            });
            this.getdateChangeApi(fromDate, toDate);
        } catch (error) {
            console.log(error);
        }

    }
    employeeChangeApi = (e) => {
        let topSalesPersonDetailsArray = this.state.topSalesPersonDetails;
        let scrollArray = [];
        for (let index = 0; index < topSalesPersonDetailsArray.length; index++) {
            let element = topSalesPersonDetailsArray[index];
            scrollArray.push({Amount: 0, total: 0, Name: element.Name, level: element.level, percentage: 0});
        }
        this.setState({
            topSalesPersonDetails: scrollArray,
            employeeLoading: true
        });
        const {dispatch} = this.props;
        dispatch(getTopSalePerson(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.limitTopSalesPerson, e));

        // dispatch(getTopDesignSale(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,this.state.limitTopDesingnSale,e));
    }
    getdateChangeApi = (fromDate, toDate) => {
        const {dispatch} = this.props;
        dispatch(getAllTotal(this.state.selectedRootInfoEqual, this.state.selectedId, fromDate, toDate));
        //dispatch(getStockTally(0,fromDate,toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
        dispatch(getTopSalePerson(0, fromDate, toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.limitTopSalesPerson));
        dispatch(getTopCategoryWiseSale(0, fromDate, toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.limitTopCategoryWiseSale));
        dispatch(getTopDesignSale(0, fromDate, toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.limitTopDesingnSale));
        //CUSTOMER CONVERSATION RATIO
        dispatch(getCutomerConversationRatio(0, fromDate, toDate, this.state.selectedRootInfoEqual, this.state.selectedId));
        dispatch(getDesignAnalytics(0, fromDate, toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.designCategoryForTopdesign));
        dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0, fromDate, toDate, this.state.selectedRootInfoEqual, this.state.selectedId, this.state.selectedSalesPerson));
        dispatch(getViewDemand(0, fromDate, toDate, this.state.selectedRootInfoEqual, this.state.selectedId));

    }
    closeDatePicker = (e) => {
        let now = new Date();
        let fromDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(30, "days").toISOString();
        let toDate = now.toISOString();
        this.setState({
            showDateView: !this.state.showDateView,
            fromDate: fromDate,
            toDate: toDate,
        });
        this.getdateChangeApi(fromDate, toDate);

    }

    handleChangeStockTally = (e) => {
        var now = new Date();
        var toDate = now.toISOString();
        var fromDate = now.toISOString();
        if (e === "1") {
            var fromDatee = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
            fromDate = fromDatee.toISOString();
        } else if (e === "2") {
            var fromDatee = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(1, "days");
            fromDate = fromDatee.toISOString();
        } else if (e === "3") {
            var fromDatee = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(7, "days")
            fromDate = fromDatee.toISOString();
        } else if (e === "4") {
            var fromDatee = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(14, "days")
            fromDate = fromDatee.toISOString();
        } else if (e === "5") {
            var fromDatee = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(30, "days")
            fromDate = fromDatee.toISOString();
        }
        //  var now = new Date();
        //  var fromDate=e.toISOString();
        //  var toDate=now.toISOString();
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            stokctallyLoading: true
        });
        const {dispatch} = this.props;
        // console.log(0, fromDate, toDate, this.state.selectedRootInfoEqual, this.state.selectedId);
        // dispatch(getStockTally(0,fromDate,toDate,this.state.selectedRootInfoEqual,this.state.selectedId));
    }

    handleSearch = (e) => {

        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {dispatch} = this.props;
                // let userdata=values;
            }
        });
    }


    handleChangeTopSalesPerson = (e) => {
        this.setState({
            limitTopSalesPerson: Number(e)
        })
        const {dispatch} = this.props;
        dispatch(getTopSalePerson(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, Number(e)));
    }

    handleChangeTopCategory = (e) => {
        this.setState({
            limitTopCategoryWiseSale: Number(e)
        })
        const {dispatch} = this.props;
        dispatch(getTopCategoryWiseSale(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, Number(e)));
    }

    handleChangeCategory = (e) => {
        const {dispatch} = this.props;
        this.setState({
            //  designAnalyticsDetails:[],
            designCategoryForTopdesign: e,
            categoryAnalyticsLoading: true
        });
        dispatch(getDesignAnalytics(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, e));
    }

    handleChangeTopSalesDesign = (e) => {
        if (e === "view") {
            this.setState({
                topSalesDesignDetails: this.state.viewedList,
                selectedTabTopDesignGraph: e,
                //      topSalesDesignDetails:[]
            })
        } else {
            this.setState({
                topSalesDesignDetails: this.state.soldList,
                selectedTabTopDesignGraph: e,
                //    topSalesDesignDetails:[]
            })
        }

        // this.setState({
        //   limitTopDesingnSale:Number(e),
        //   topSalesDesignDetails:
        // })


        //const { dispatch } = this.props;

        //dispatch(getTopDesignSale(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,Number(e)));
    }

    handleBarClick = (data, callskuapi) => {
        let uniqueSet = new Set(data);
        const backToArray = [...uniqueSet];
        if (backToArray.length > 0) {
            this.setState({
                itemArray: backToArray,
                viewVisibility: true,
                sku_number: backToArray[0],
            });
            const {dispatch} = this.props;
            if (callskuapi === 'callskuapi') {
                this.setState({
                    callskuapi: true
                })
                dispatch(getSKUbyID(backToArray[0]));
            } else {
                dispatch(getSKUbyID(backToArray[0], 'dashbaord'));
            }
        }
    }

    handleCancel = () => {
        this.setState({viewVisibility: false});
    }

    handleChangeParticularSalesPerson = (e) => {
        //console.log(e);
        this.setState({
            selectedSalesPerson: e,
            conversionLoading: true
        })
        const {dispatch} = this.props;
        dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0, this.state.fromDate, this.state.toDate, this.state.selectedRootInfoEqual, this.state.selectedId, e));
    }

    onChangeRangepicker = (e) => {
        var toDate = e[1].toISOString();
        var fromDate = e[0].toISOString();
        const {dispatch} = this.props;
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            stokctallyLoading: true
        });
        dispatch(getStockTally(0, fromDate, toDate, this.state.selectedRootInfoEqual, this.state.selectedId));
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let now = new Date();
        let today = `${moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).toISOString()}`
        let last7days = `${moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(7, "days").toISOString()}`;
        let last30days = ` ${moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(30, "days").toISOString()} `;
        let last180days = ` ${moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(180, "days").toISOString()} `;
        let last365days = ` ${moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(365, "days").toISOString()} `;
        let lifetime = ` ${new Date(2001)} `;
        return (
            <React.Fragment>

                <Card className="bashbaord-top-menu-card " bodyStyle={{padding: "0"}} bordered={false}>
                    <ItemView sku_number={this.state.sku_number} itemClick={this.itemClick}
                              itemArray={this.state.itemArray} viewVisibility={this.state.viewVisibility}
                              showModal={this.showModal} handleCancel={this.handleCancel}
                              callskuapi={this.state.callskuapi}/>
                    <Row>
                        <Col lg={6} md={6} sm={24} xs={24} style={{
                            textAlign: "center"
                        }}>
                            <h2 className="dashboard-title">Data Dashboard</h2>
                        </Col>
                        {/**
                         <Col lg={1} md={1} sm={24} >
                         <Divider type="vertical" className="vertical-divider-dashbaord" />
                         </Col> */}
                        <Col lg={10} md={10} sm={24}>
                            {this.state.showDateView ?
                                <InputGroup compact>
                                    <Select

                                        suffixIcon={<Icon type="calendar" style={{fontSize: "15px"}}/>}
                                        style={{minWidth: "39px", marginTop: "22px", backgroundColor: "white"}}
                                        disabled
                                    >
                                    </Select>

                                    <Select
                                        showSearch
                                        style={{minWidth: 300, marginTop: "22px"}}
                                        placeholder="Select time"
                                        optionFilterProp="children"
                                        //  style={{marginLeft:"25px"}}
                                        onChange={this.getTimePeriod}
                                        //  onFocus={handleFocus}
                                        // onBlur={handleBlur}
                                        defaultValue={last30days}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        <Option key="v1"
                                                value={today}><b>Today </b>({moment(new Date()).format("DD-MM-YYYY")})</Option>
                                        <Option key="v2" value={last7days}><b>Last 7
                                            days </b> ({moment(new Date()).subtract(7, "days").format("DD-MM-YYYY")} To {moment(new Date()).format("DD-MM-YYYY")})</Option>
                                        <Option key="v3" value={last30days}><b>Last 30
                                            days </b> ({moment(new Date()).subtract(30, "days").format("DD-MM-YYYY")} To {moment(new Date()).format("DD-MM-YYYY")})</Option>
                                        <Option key="v4" value={last180days}><b>Last 180
                                            days </b> ({moment(new Date()).subtract(180, "days").format("DD-MM-YYYY")} To {moment(new Date()).format("DD-MM-YYYY")})</Option>
                                        <Option key="v5" value={last365days}><b>Last 365
                                            days </b> ({moment(new Date()).subtract(365, "days").format("DD-MM-YYYY")} To {moment(new Date()).format("DD-MM-YYYY")})</Option>
                                        <Option key="v6" value={lifetime}><b>Life Time</b></Option>
                                        <Option key="v7" value="v7"><b>Select Custom date</b></Option>
                                    </Select>
                                </InputGroup>
                                :
                                <InputGroup compact>
                                    <Select
                                        suffixIcon={<Icon type="calendar" style={{fontSize: "15px"}}/>}
                                        style={{minWidth: "39px", marginTop: "22px", backgroundColor: "white"}}
                                        disabled
                                    ></Select>
                                    <RangePicker
                                        format="DD-MM-YYYY"
                                        placeholder={['From ', 'To']}
                                        style={{marginTop: "22px", maxWidth: 300}}
                                        separator="-"
                                        allowClear={false}
                                        onChange={this.getTimePeriodWithbothdate}
                                        suffixIcon={<Icon type="close" onClick={this.closeDatePicker}/>}
                                    />

                                </InputGroup>
                            }


                        </Col>
                        {/**  <Col lg={1} md={1} sm={24} >
                         <Divider type="vertical" className="vertical-divider-dashbaord" />
                         </Col>*/}
                        <Col lg={8} md={8} sm={24} style={{textAlign: "right"}}>
                            {this.state.rootInfo === "branch" ?


                                <Select placeholder="Please select branch"
                                        defaultValue={this.state.id}
                                        onChange={this.onChangeBranchRadio}
                                        style={{minWidth: 300, marginTop: "22px", marginRight: "5%"}}>
                                    <Option key={this.state.id} value={this.state.id}>All Branches</Option>
                                    {this.state.branch_list.map((data, index) =>
                                        <Option key={data._id} value={data._id}>{data.name}</Option>
                                    )}
                                </Select>

                                : ""}
                            {this.state.rootInfo === "floor" ?

                                <Select placeholder="Please select floor" defaultValue={this.state.id}
                                        onChange={this.onChangeBranchRadio}
                                        style={{minWidth: 300, marginTop: "22px", marginRight: "5%"}}>
                                    <Option key={this.state.id} value={this.state.id}>All Floor</Option>
                                    {this.state.floor_list.map((data, index) =>
                                        <Option key={data._id} value={data._id}>{data.name}</Option>
                                    )}
                                </Select>

                                : ""}
                            {this.state.rootInfo === "section" ?

                                <Select placeholder="Please select section" defaultValue={this.state.id}
                                        onChange={this.onChangeBranchRadio}
                                        style={{minWidth: 300, marginTop: "22px", marginRight: "5%"}}>
                                    <Option key={this.state.id} value={this.state.id}>All section</Option>
                                    {this.state.section_list.map((data, index) =>
                                        <Option key={data._id} value={data._id}>{data.name}</Option>
                                    )}
                                </Select>
                                : ""}

                        </Col>
                    </Row>
                </Card>
                <Spin spinning={this.state.stokctallyLoading} tip="Loading...">
                    <Card className="bashbaord-top-menu-card-2 gx-card" bodyStyle={{padding: "0", marginTop: "90px"}}
                          bordered={false}>
                        <Row>
                            <Col lg={3} md={3} sm={24} xs={24} style={{paddingLeft: "3%"}}>
                                <h1 className="total-text" style={{cursor: "pointer"}}
                                    onClick={() => this.handleBarClick(this.state.data_array_total_details.viewSkus)}>
                                    <CountUp start={0} end={this.state.total_details.box1value}/></h1>
                                <h6 className="dashboard-total-header">{this.state.total_details.box1lable}</h6>
                            </Col>

                            <Col lg={1} md={1} sm={24}>
                                <Divider type="vertical" className="vertical-divider-dashbaord"/>
                            </Col>

                            <Col lg={3} md={3} sm={24} className="xs-d-none gx-d-xs-block">
                                <h1 className="total-text" style={{cursor: "pointer"}}
                                    onClick={() => this.handleBarClick(this.state.data_array_total_details.soldSkus)}>
                                    <CountUp start={0} end={this.state.total_details.box2value}/></h1>
                                <h6 className="dashboard-total-header">{this.state.total_details.box2lable}</h6>
                            </Col>

                            <Col lg={1} md={1} sm={24}>
                                <Divider type="vertical" className="vertical-divider-dashbaord"/>
                            </Col>

                            <Col lg={3} md={3} sm={24} className="xs-d-none gx-d-xs-block">
                                <h1 className="total-text" style={{cursor: "pointer"}}
                                    onClick={() => this.handleBarClick(this.state.data_array_total_details.itemNotViewedSkus, "callskuapi")}>
                                    <CountUp start={0} end={this.state.total_details.box7value}/></h1>
                                <h6 className="dashboard-total-header">{this.state.total_details.box7lable}</h6>
                            </Col>
                            <Col lg={1} md={1} sm={24}>
                                <Divider type="vertical" className="vertical-divider-dashbaord"/>
                            </Col>
                            <Col lg={8} md={8} sm={24} xs={24} style={{textAlign: "left"}}>
                                {/* {this.state.total_details.box3value.length < 20 ?
            <h1 style={{color:"#4285F4",fontSize:"24px",height:"20px",lineHeight:"39px", cursor: "pointer"}}  onClick={()=>this.handleBarClick(this.state.data_array_total_details.topDesgSoldSkus)} >{this.state.total_details.box3value}</h1>
         :
         <React.Fragment>
         {this.state.total_details.box3value.length > 30 ?
            <h1 style={{color:"#4285F4",fontSize:"11px",height:"20px",lineHeight:"39px" , cursor: "pointer"}}  onClick={()=>this.handleBarClick(this.state.data_array_total_details.topDesgSoldSkus)}>{this.state.total_details.box3value}</h1>
          :
          <h1 style={{color:"#4285F4",fontSize:"11px",height:"20px",lineHeight:"39px", cursor: "pointer"}}  onClick={()=>this.handleBarClick(this.state.data_array_total_details.topDesgSoldSkus)}>{this.state.total_details.box3value}</h1>
        }
         </React.Fragment>
          
            
        } */}
                                <h1 style={{
                                    color: "#4285F4",
                                    fontSize: `${this.state.fontSizeForLablevalue3}px`,
                                    height: `${this.state.heightForLablevalue3}px`,
                                    lineHeight: "39px",
                                    cursor: "pointer"
                                }}
                                    onClick={() => this.handleBarClick(this.state.data_array_total_details.topDesgViewSkus)}>{this.state.total_details.box3value}</h1>
                                <h6 className="dashboard-total-header" style={{
                                    paddingTop: "5px",
                                    paddingBottom: "0px"
                                }}>{this.state.total_details.box3lable}</h6>
                                <h1 style={{
                                    color: "#4285F4",
                                    fontSize: `${this.state.fontSizeForLablevalue4}px`,
                                    height: `${this.state.heightForLablevalue4}px`,
                                    lineHeight: "39px",
                                    cursor: "pointer"
                                }}
                                    onClick={() => this.handleBarClick(this.state.data_array_total_details.topDesgSoldSkus)}>{this.state.total_details.box4value}</h1>
                                <h6 className="dashboard-total-header" style={{
                                    paddingTop: "5px",
                                    paddingBottom: "0px"
                                }}>{this.state.total_details.box4lable}</h6>


                            </Col>

                            <Col lg={1} md={1} sm={24}>
                                <Divider type="vertical" className="vertical-divider-dashbaord"/>
                            </Col>

                            <Col lg={3} md={3} sm={24} xs={24} style={{textAlign: "left"}}>
                                <h1 className="total-text"
                                    style={{fontSize: "24px", height: "24px", lineHeight: "39px"}}><CountUp start={0}
                                                                                                            end={this.state.total_details.box6value}/>
                                </h1>
                                <h6 className="dashboard-total-header" style={{
                                    paddingTop: "5px",
                                    paddingBottom: "0px"
                                }}>{this.state.total_details.box6lable}</h6>
                                <h1 className="total-text"
                                    style={{fontSize: "24px", height: "19px", lineHeight: "39px"}}><CountUp start={0}
                                                                                                            end={this.state.total_details.box5value}/>
                                </h1>
                                <h6 className="dashboard-total-header" style={{
                                    paddingTop: "5px",
                                    paddingBottom: "0px"
                                }}>{this.state.total_details.box5lable}</h6>
                            </Col>

                            {/**
                             <Row>
                             <Col lg={4} md={4} sm={24} xs={24} style={{paddingLeft:"3%"}}>
                             <h1 className="total-text"><CountUp start={0}  end={this.state.total_details.box1value}/></h1>
                             <h6 className="dashboard-total-header">{this.state.total_details.box1lable}</h6>
                             </Col>

                             <Col lg={1} md={1} sm={24} >
                             <Divider type="vertical" className="vertical-divider-dashbaord" />
                             </Col>

                             <Col lg={4} md={4} sm={24}  className="xs-d-none gx-d-xs-block">
                             <h1 className="total-text">  <CountUp start={0} end={this.state.total_details.box2value} /> </h1>
                             <h6  className="dashboard-total-header">{this.state.total_details.box2lable}</h6>
                             </Col>

                             <Col lg={1} md={1} sm={24} >
                             <Divider type="vertical" className="vertical-divider-dashbaord" />
                             </Col>

                             <Col lg={8} md={8} sm={24} xs={24} style={{textAlign:"left"}}>
                             {this.state.total_details.box3value.length < 20 ?
            <h1 style={{color:"#4285F4",fontSize:"33px",height:"78px",lineHeight:"120px"}}>{this.state.total_details.box3value}</h1>
         :
         <React.Fragment>
         {this.state.total_details.box3value.length > 30 ?
            <h1 style={{color:"#4285F4",fontSize:"13px",height:"78px",lineHeight:"120px"}}>{this.state.total_details.box3value}</h1>
          :
          <h1 style={{color:"#4285F4",fontSize:"23px",height:"78px",lineHeight:"120px"}}>{this.state.total_details.box3value}</h1>
        }
         </React.Fragment>
          
            
        }
                             <h6  className="dashboard-total-header" style={{paddingTop:"5px"}}>{this.state.total_details.box3lable}</h6>

                             </Col>

                             <Col lg={1} md={1} sm={24} >
                             <Divider type="vertical" className="vertical-divider-dashbaord" />
                             </Col>

                             <Col lg={5} md={5} sm={24} xs={24} style={{paddingRight:"5%",textAlign:"left"}}>
                             <h1 className="total-text"><CountUp start={0}  end={this.state.total_details.box4value}/></h1>
                             <h6  className="dashboard-total-header">{this.state.total_details.box4lable}</h6>
                             </Col>

                             */}

                            {/**    <Col lg={4} md={4} sm={24} xs={24} style={{paddingLeft:"3%"}}>
                             <h1 className="total-text"><CountUp start={0}  end={this.state.total_details.sku}/></h1>
                             <h6>TOTAL INVENTORY</h6>
                             </Col>
                             <Col lg={1} md={1} sm={24} >
                             <Divider type="vertical" className="vertical-divider-dashbaord" />
                             </Col>
                             <Col lg={4} md={4} sm={24}  className="xs-d-none gx-d-xs-block">
                             <h1 className="total-text">  <CountUp start={0} end={this.state.total_details.sale} /> </h1>
                             <h6>TOTAL SALES</h6>
                             </Col>
                             <Col lg={1} md={1} sm={24} >
                             <Divider type="vertical" className="vertical-divider-dashbaord" />
                             </Col>
                             <Col lg={4} md={4} sm={24} xs={24} >
                             <h1 className="total-text"><CountUp start={0}  end={this.state.total_details.memo}/></h1>
                             <h6>TOTAL MEMO</h6>
                             </Col>
                             <Col lg={1} md={1} sm={24} >
                             <Divider type="vertical" className="vertical-divider-dashbaord" />
                             </Col>
                             <Col lg={4} md={4} sm={24} xs={24} >
                             <h1 className="total-text"><CountUp start={0}  end={this.state.total_details.quote}/></h1>
                             <h6>TOTAL QUOTE</h6>
                             </Col>
                             <Col lg={1} md={1} sm={24} >
                             <Divider type="vertical" className="vertical-divider-dashbaord" />
                             </Col>
                             <Col lg={4} md={4} sm={24} xs={24} style={{paddingRight:"5%"}}>
                             <h1 className="total-text"><CountUp start={0}  end={this.state.total_details.order}/></h1>
                             <h6>TOTAL ORDER</h6>
                             </Col>
                             */}
                        </Row>
                    </Card>
                    {/** <Row>
                     {datas.map((data, index) =>
            <Col lg={8} md={12} sm={12} xs={24} key={index}>
              <TotalBranch data={data} />
            </Col> suffixIcon={<Icon type="calendar" />
          )}
        </Row>
                     */}
                    <BranchChart stockTallyData={this.state.stockStally}
                                 stokctallyLoading={this.state.stokctallyLoading}
                                 topSalesPersonDetails={this.state.topSalesPersonDetails}
                                 topSalesCategoryDetails={this.state.topSalesCategoryDetails}
                                 topSalesDesignDetails={this.state.topSalesDesignDetails}
                                 customerConversationRatio={this.state.customerConversationRatio}
                                 designAnalyticsDetails={this.state.designAnalyticsDetails}
                                 salespersonWiseConversationRatio={this.state.salespersonWiseConversationRatio}
                                 viewDemand={this.state.viewDemand}
                                 graphKey={this.state.graphKey}
                                 handleChangeTopSalesPerson={this.handleChangeTopSalesPerson}
                                 handleChangeTopCategory={this.handleChangeTopCategory}
                                 handleChangeTopSalesDesign={this.handleChangeTopSalesDesign}
                                 handleChangeParticularSalesPerson={this.handleChangeParticularSalesPerson}
                                 handleChangeStockTally={this.handleChangeStockTally}
                                 onChangeRangepicker={this.onChangeRangepicker}
                                 handleChangeCategory={this.handleChangeCategory}
                                 handleBarClick={this.handleBarClick}
                                 employeeChangeApi={this.employeeChangeApi}
                                 employeeLoading={this.state.employeeLoading}
                                 conversionLoading={this.state.conversionLoading}
                                 categoryAnalyticsLoading={this.state.categoryAnalyticsLoading}
                                 designCategoryForTopdesign={this.state.designCategoryForTopdesign}
                    />
                </Spin>
            </React.Fragment>
        )
    }
}

dashboard = Form.create()(dashboard);

function mapStateToProps(state) {
    const {Common, Dashboard} = state
    const {branch_list: branch_list, floor_list: floor_list, section_list: section_list, display_config_details: display_config_details, media_config_details: media_config_details} = Common
    const {
        stocktally_details: stocktally_details, top_sales_design_details: top_sales_design_details, top_sales_person_details: top_sales_person_details, top_sales_category_details: top_sales_category_details,
        customer_conversation_ratio: customer_conversation_ratio, design_analytics_details: design_analytics_details, salesperson_wise_conversation_ratio: salesperson_wise_conversation_ratio, all_sales_persons: all_sales_persons, view_demand: view_demand,
        total_details: total_details,
    } = Dashboard
    return {
        branch_list,
        floor_list,
        section_list,
        stocktally_details,
        top_sales_design_details,
        top_sales_person_details,
        top_sales_category_details,
        customer_conversation_ratio,
        design_analytics_details,
        salesperson_wise_conversation_ratio,
        all_sales_persons,
        view_demand,
        total_details,
        display_config_details,
        media_config_details,
    }
}

const AppContainer = connect(
    mapStateToProps
)(dashboard);


export default AppContainer;


