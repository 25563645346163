import React, { Component } from 'react'
import {connect} from "react-redux";
import {getAllFloorList,getAllBrachList,getAllSectionList, deleteSection} from '../../../actions/index';
import {Card, Table,Form,Row,Col,Modal,Select,Input,Button,Icon,Popconfirm,Radio,Alert,message} from "antd";
import ReactExport from "react-data-export";
import AddFloor from "./add";
import {Link} from "react-router-dom";
import Edit from "./edit";
import {userAccess} from "../../../containers/App/commanFunction";
const { TextArea } = Input;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Option } = Select;

class ManageSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      table_loading:true,
      search:'',
      visible: false,
      loading:true,
      branch_list:[],
      branch_id:'',
      floor_list:[],
      floor_id:'',
      isDisable:true
    };
  }
  componentWillMount(){  
    let usertype=localStorage.getItem('type_user');
    if(usertype!="companyAdmin"){
      window.location.href="/dashboard"
    }
   }
  componentDidMount() {
    const { dispatch } = this.props; 
    
    dispatch(getAllBrachList());   
  }
  componentDidUpdate(prevProps, prevState) {

 
    if (prevProps.update_section_data != this.props.update_section_data) {
      if(this.props.update_section_data.status)
      {
        const { dispatch } = this.props; 
         message.success("Update successfully",1);
         dispatch(getAllSectionList("byFloorId",this.state.floor_id));
      }else{
         message.error(this.props.update_section_data.errors.msg);
      } 
    } 


    if (prevProps.branch_list != this.props.branch_list) 
    {  
      if(this.props.branch_list.status)
      {  
       this.setState({        
        branch_list:this.props.branch_list.data_array
       });

       if(this.props.branch_list.data_array.length >= 1)
       {
        const { dispatch } = this.props;
        this.setState({
          branch_id:this.props.branch_list.data_array[0]._id,
        });
       
        dispatch(getAllFloorList("bybranchId",this.props.branch_list.data_array[0]._id));
       }
      }else{
        this.setState({
          branch_list:[]
         });
      }
    }

    if (prevProps.deletet_SectionData != this.props.deletet_SectionData) {
      // console.log(this.props.deletet_SectionData)
      if(this.props.deletet_SectionData.status)
      {
         message.success("Section delete  successfully");
         const { dispatch } = this.props; 
         dispatch(getAllSectionList("byFloorId",this.state.floor_id));
      }else{
         message.error(this.props.deletet_SectionData.errors.msg);
      } 
    } 
    

    if (prevProps.floor_list != this.props.floor_list) {
    //  console.log(this.props.floor_list);
      if(this.props.floor_list.status)
      {
          this.setState({
            floor_list:this.props.floor_list.data_array,            
          })

          if(this.props.floor_list.data_array.length >= 1)
          {
            this.setState({
            floor_id:this.props.floor_list.data_array[0]._id,
            isDisable:false
           })
           const { dispatch } = this.props; 
           dispatch(getAllSectionList("byFloorId",this.props.floor_list.data_array[0]._id));
          }else{
            this.setState({
              isDisable:true
             })
          }
      }else{
        this.setState({
          floor_list:[]
        })
      } 
      this.setState({
        table_loading:false
       })
       
    } 


    if (prevProps.section_list != this.props.section_list) {
      // console.log(this.props.section_list);
       if(this.props.section_list.status)
       {
           this.setState({
             data:this.props.section_list.data_array,            
           })         
          }else{
            this.setState({
              data:[]            
            })
          }
          this.setState({
            table_loading:false
           })

        } 

    
   
  }

  onChangeBranchRadio=(e)=> {
    // console.log(`radio checked:${e}`);
   this.setState({
    table_loading:true,
    branch_id:e
   })
   const {dispatch} = this.props;
   this.props.form.resetFields();
   this.setState({
    floor_id:'',
    section_id:'',
   })
   dispatch(getAllFloorList("bybranchId",e));
  }
  deleteActiveFloor = (e) => {
    //e.isActive= !e.isActive;
    // console.log("deletre branch");
    // console.log(e);
    const { dispatch } = this.props;
     dispatch(deleteSection(e._id));  
   }
  onChangeFloor=(e)=> {
    // console.log(`radio floor:${e}`);
   this.setState({
    table_loading:true,
    floor_id:e
   })
   const {dispatch} = this.props;
   dispatch(getAllSectionList("byFloorId",e));
  }
  

  render() {
    const { getFieldDecorator } = this.props.form;
    const userAccess1=userAccess("add","section");
    const columns = [  
      {
        title: 'NAME',
        dataIndex: 'name',
       // width: '10%',
        render: (text, record) => {
          const { finger_index } = record
          var s = text;
            //  s = s.substring(0, s.lastIndexOf('/'));
          return (
            <span>
                {s}
            </span>
          );
        }
      },
      {
        title: 'CONTACT NO',
        dataIndex: 'phone',
    //    width: '10%',
        render: (text, record) => {
          const { Stocktally } = record;
          return (
            <span>
                {text}
            </span>
          );
        },
        
      },
      {
        title: 'ADDRESS',
        dataIndex: 'address',
      //  width: '80%',
        render: (text, record) => {
          const { Stocktally } = record;
          return (
            <span style={{textAlign:"justify"}}>
            {text}
            </span>
          );
        }
      },
      {
        title: 'Action',
        dataIndex: '_id',
        width: '10%',
        render: (text, record) => {
          const { Haribhagat } = record;
          return (
            <span>          
              <div className="editable-row-operations" >
              {userAccess("edit","section") === 'true' ?
                <Edit record={record}/>
            :""}
            {userAccess("delete","section") === 'true' ?
              <Popconfirm
                title="Are you sure delete section ?"
                onConfirm={()=>this.deleteActiveFloor(record)}
                //onCancel={cancel}
                okText="Yes"
                cancelText="No"
              > 
                  {/* {isActive ?  <Icon type="check-circle" theme="filled" theme="twoTone" twoToneColor="#52c41a" style={{fontSize: 20,paddingLeft:"20px",cursor:"pointer"}} /> :
                  <Icon type="check-circle" style={{fontSize: 20,paddingLeft:"20px",cursor:"pointer"}} />
                  } */}
                  <Icon type="delete" theme="filled"  style={{fontSize: 20,paddingLeft:"20px",cursor:"pointer"}} />
                </Popconfirm>
                :""}
              </div>
            </span>
          );
        }
      },
    ];
    let data = this.state.data
    let {loading} =this.state;
		if (this.state.search) {
			data = data.filter(row => {
        return row.name.toUpperCase().includes(this.state.search.toUpperCase()) ||  String(row.phone).includes(this.state.search)
        //row.name.toUpperCase().includes(this.state.search.toUpperCase())
			})
    }
  
    return (
      <div>
      <Row>
      <Col lg={24} md={24} sm={24} xs={24}><h2 className="title gx-mb-4"  style={{float:"left",paddingRight:"12px"}}>Manage </h2>
      <RadioGroup  
      defaultValue="Section"
      >                     
        <RadioButton  key={"branch"} value={"branch"}><Link to="/manageBranch">Branch </Link></RadioButton>
        <RadioButton  key={"floor"} value={"floor"}><Link to="/manageFloor">Floor </Link> </RadioButton>
        <RadioButton  key={"Section"} value={"Section"}><Link to="/manageSection">Section </Link></RadioButton>
        <RadioButton  key={"SubSection"} value={"SubSection"}><Link to="/manageSubSection">SubSection </Link></RadioButton>
     </RadioGroup>
    </Col>
    </Row>
 
   
      <div className="certain-category-search-wrapper">   
      {userAccess1 == "true" ? 
      <Row>
      <Col lg={21} md={20} sm={24} xs={24}  style={{paddingBottom:"15px"}}>
      <Input 	value={this.state.search}  placeholder="Search .." style={{width:"100%",float:"left"}}
      onChange={e => this.setState({search: e.target.value})}  suffix={<Icon type="search" className="certain-category-icon"/>}/>
      
      </Col>
           <Col lg={3} md={4} sm={24} xs={24}>
           <AddFloor branchId={this.state.branch_id} floorId={this.state.floor_id} isDisable={this.state.isDisable}/>
            </Col>
          </Row>   
        :
        <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
        <Input 	value={this.state.search}  placeholder="Search .." style={{width:"100%",float:"left",paddingBottom:"15px"}}
        onChange={e => this.setState({search: e.target.value})}  suffix={<Icon type="search" className="certain-category-icon"/>}/>
        
        </Col>
            </Row>  
        }      
       </div>

        <Card bodyStyle={{ padding: "10px 10px" }}> 
        <Form colon={false}>
        <Row>
        <Col lg={8} md={8} sm={24} xs={24}>
                {this.state.branch_id !='' ?
                <Form.Item style={{marginBottom:"0px",marginLeft:"5px"}} label="Select Branch">
                {getFieldDecorator(`branchid`, {
                 initialValue: this.state.branch_id,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(  
              <Select onChange={this.onChangeBranchRadio} 
             // defaultValue={this.state.branch_id}  
              style={{ width: 200 }}>         
              {this.state.branch_list.map((data, index) =>
              <Option key={data._id} value={data._id}>{data.name}</Option>         
            )}
            </Select> 
            )}
            </Form.Item>
              :""}  
        </Col>        
        <Col lg={8} md={8} sm={24} xs={24}>
              {this.state.floor_id !='' ?
              
              <Form.Item style={{marginBottom:"0px"}} label="Select Floor">
              {getFieldDecorator(`floorId`, {
               initialValue: this.state.floor_id,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(  
            <Select onChange={this.onChangeFloor}
          //   defaultValue={this.state.floor_id} 
              style={{ width: 200 }}>         
            {this.state.floor_list.map((data, index) =>
            <Option key={data._id} value={data._id}>{data.name}</Option>         
          )}
          </Select>   
          )}
          </Form.Item>

            :""} 
        </Col>
        </Row>
        </Form>
           

          
         
           <Table className="gx-table-responsive"
                  loading={this.state.table_loading} 
                  columns={columns} 
                  dataSource={data}
                  size={"small"}
                  rowKey={record => record._id}
                  onChange={this.handleTableChange}    
                />
                </Card>
      </div>
    )
  }
}

ManageSection = Form.create()(ManageSection);
function mapStateToProps(state) {
  const {Common } = state
  const {floor_list:floor_list,branch_list:branch_list,section_list:section_list,update_section_data:update_section_data,deletet_SectionData:deletet_SectionData} =  Common
  return {floor_list,branch_list,section_list,update_section_data,deletet_SectionData}
}

const AppContainer = connect( 
  mapStateToProps 
)(ManageSection);


export default AppContainer;



