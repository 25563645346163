import React, { Component } from 'react'
import IntlMessages from "util/IntlMessages";
import {connect} from "react-redux";
import moment from 'moment';
import {Card,Modal,Table,Form,Row,Col,DatePicker,Select,InputNumber,Button,AutoComplete,Input,Icon,Divider,message} from "antd";
import {getDeacStock,getMultipleSKUbyID, getAllSalesPerson, getAllBrachList, getAllSectionList, getAllFloorList} from '../../../actions/index';
import CountUp from 'react-countup';
import {currencyConverter,sortDirections} from "../../../containers/App/commanFunction";
import FilterIcon from '../../../assets/images/icons/filter-icon.svg';
const Option = Select.Option;
const cookies = require('browser-cookies');
const {RangePicker } = DatePicker;






class DeacStock extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
       data:[
    ],     
    allSalesPersons: [],
       table_loading:true,
       totalData:0,
       branch_list: [],
       textForSelect: '',
       section_list: [],
       floor_list: [],
       floor_id: '',
       branch_id: '',
       section_id: '',
       slecteID: '',
       design_category:[],
       design_code:[],
       display:false,
       table_loading_skuview:true,
       sku_data:[],
       modelvisible:false,
       SearchData:[],
       btn1:true,
       btn2:false,
       notSoldView:false
    };
   
  }
  componentDidMount() {
    const { dispatch } = this.props;  
    let now = new Date();
    let starDate=fromDate=moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(30, "days");
    let fromDate= starDate.toISOString();
    let toDate= new Date().toISOString();
    this.setState({
      fromDate:fromDate,
      toDate:toDate
    })
    
    let usertype = localStorage.getItem('type_user');
    let root = JSON.parse(cookies.get('root'));
    let page_no = 0;
    let limit = 10;
    //dispatch(getCustomer(page_no, limit));
    if (usertype === "branchAdmin") {
      this.setState({
        rootInfo: "floor",
        rootInfoEqual: "branch",
        textForSelect: "Select Floor",
        id: root.branchId,
        selectedId: root.branchId,
        selectedRootInfoEqual: "branch"
      });
      dispatch(getAllFloorList("id"));
      //dispatch(getAllSalesPerson("branch", root.branchId));
    }
    if (usertype === "companyAdmin") {
      this.setState({
        rootInfo: "branch",
        rootInfoEqual: "company",
        textForSelect: "Select Branch",
        id: root.companyId,
        selectedId: root.companyId,
        selectedRootInfoEqual: "company"
      });
      dispatch(getAllBrachList("id"));
     // dispatch(getAllSalesPerson("company", root.companyId));
    }
    if (usertype === "floorAdmin") {
      this.setState({
        rootInfo: "section",
        rootInfoEqual: "floor",
        textForSelect: "Select Section",
        id: root.floorId,
        selectedId: root.floorId,
        selectedRootInfoEqual: "floor"
      });
      dispatch(getAllSectionList("id"));
     // dispatch(getAllSalesPerson("floor", root.floorId));
    }
  }

  componentDidUpdate(prevProps, prevState){
    try {    
    if (prevProps.dead_stocks != this.props.dead_stocks) 
    {       
      if(this.props.dead_stocks.status)
      {  
       this.setState({
        data:this.props.dead_stocks.data_array[0].notViewed.data_array,
        totalData:this.props.dead_stocks.data_array[0].notViewed.data.total,
        design_category:this.props.dead_stocks.data_array[0].notViewed.data.catArray,
        design_code:this.props.dead_stocks.data_array[0].notViewed.data.codArray,
        SearchData:this.props.dead_stocks.data_array[0].notViewed.data_array,
        data2:this.props.dead_stocks.data_array[0].notViewed.data_array,
        totalData2:this.props.dead_stocks.data_array[0].notViewed.data.total,
        design_category2:this.props.dead_stocks.data_array[0].notViewed.data.catArray,
        design_code2:this.props.dead_stocks.data_array[0].notViewed.data.codArray,
        SearchData2:this.props.dead_stocks.data_array[0].notViewed.data_array
       });

       if(Object.entries(this.props.dead_stocks.data_array[1].notSold).length > 0 ){
         this.setState({
          data1:this.props.dead_stocks.data_array[1].notSold.data_array,
          totalData1:this.props.dead_stocks.data_array[1].notSold.data.total,
          design_category1:this.props.dead_stocks.data_array[1].notSold.data.catArray,
          design_code1:this.props.dead_stocks.data_array[1].notSold.data.codArray,
          SearchData1:this.props.dead_stocks.data_array[1].notSold.data_array,
          notSoldView:true
         });
            if(this.state.btn2){
              this.setState({
                data:this.props.dead_stocks.data_array[1].notSold.data_array,
                design_category:this.props.dead_stocks.data_array[1].notSold.data.catArray,
                design_code:this.props.dead_stocks.data_array[1].notSold.data.codArray,
                SearchData:this.props.dead_stocks.data_array[1].notSold.data_array,
              })
          
          }
       }

 
      //  data:this.props.dead_stocks.data_array,
      //  totalData:this.props.dead_stocks.data.total,
      //  design_category:this.props.dead_stocks.data.catArray,
      //  design_code:this.props.dead_stocks.data.codArray,
      //  SearchData:this.props.dead_stocks.data_array
      }else{
        this.setState({
          data:[]
         });
      }
      this.setState({
        table_loading:false
      })
    }


    if (prevProps.branch_list != this.props.branch_list) {
      if (this.props.branch_list.status) {
        this.setState({
          branch_id: this.props.branch_list.data_array[0]._id,
          branch_list: this.props.branch_list.data_array,
          slecteID: this.props.branch_list.data_array[0]._id
        });
        if (this.props.branch_list.data_array.length >= 1) {
          const { dispatch } = this.props;
          let page_no = 0;
          dispatch(getDeacStock(0, this.state.fromDate,this.state.toDate, this.state.rootInfoEqual,this.state.id,"","",""));
          //dispatch(getSKUByRootInfoAndId(this.props.branch_list.data_array[0]._id,this.state.rootInfo));
        }
      } else {
        this.setState({
          branch_list: []
        });
      }
    }

    if (prevProps.floor_list != this.props.floor_list) {
      if (this.props.floor_list.status) {
        this.setState({
          floor_id: this.props.floor_list.data_array[0]._id,
          floor_list: this.props.floor_list.data_array,
          slecteID: this.props.floor_list.data_array[0]._id
        });
        if (this.props.floor_list.data_array.length >= 1) {
          const { dispatch } = this.props;
          dispatch(getDeacStock(0, this.state.fromDate,this.state.toDate, this.state.rootInfoEqual,this.state.id,"","",""));
        }
      } else {
        this.setState({
          floor_list: []
        });
      }
    }

    if (prevProps.section_list != this.props.section_list) {
      //console.log("this.props.section_list");
      // console.log(this.props.section_list);
      if (this.props.section_list.status) {
        this.setState({
          section_id: this.props.section_list.data_array[0]._id,
          section_list: this.props.section_list.data_array,
          slecteID: this.props.section_list.data_array[0]._id
        });
        if (this.props.section_list.data_array.length >= 1) {
          const { dispatch } = this.props;
          dispatch(getDeacStock(0, this.state.fromDate,this.state.toDate, this.state.rootInfoEqual,this.state.id,"","",""));
        }
      } else {
        this.setState({
          section_list: []
        });
      }
    }


    if (prevProps.multiple_sku_items != this.props.multiple_sku_items) {
      // console.log( this.props.multiple_sku_items);
      try {
      
      if(this.props.multiple_sku_items.status)
      {
         this.setState({
           sku_data:this.props.multiple_sku_items.data_array,
           table_loading_skuview:false,
         })
      }else{
       this.setState({
        table_loading_skuview:false,   
        })
        message.error(this.props.multiple_sku_items.errors.msg);
        
      }
     
   } catch (error) {
      console.log(error);  
   }
 }  


  } catch (error) {
    console.log(error);
  }
  }

  handleSearch = (e) => {
     
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {dispatch} = this.props;
       // let userdata=values;
        
      }
    });
  }
   onChange=(date, dateString)=> {
    // this.setState({
    //   table_loading:true
    // })
    // if(date.length==2)
    // {
    //   try {
    //     console.log(date);
    //     const { dispatch } = this.props; 
    //     let fromDate=date[0].toISOString();
    //     let toDate=date[1].toISOString();
    //     dispatch(getDeacStock(0,fromDate,toDate));
    //    } catch (error) {
    //      console.log(error);
    //    }
    // }else{
    //   this.setState({
    //     data:[],
    //     table_loading:false
    //    });
    // }
     
   
  }

  setLocalText=(e)=>{
    const { dispatch } = this.props; 
    this.setState({modelvisible:true});    
    dispatch(getMultipleSKUbyID(e));
  }

  handleCancel = () => {
    this.setState({ modelvisible: false,sku_data:[],table_loading_skuview:true });
  }


  btnFiltter = () => {
    this.setState({
      table_loading: true,
    })

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const { dispatch } = this.props;
     
      let fromDate="";
      let toDate="";
      if(values.date.length>0){
         fromDate=values.date[0].toISOString();
         toDate=values.date[1].toISOString();
      }
     
     
      let d_code=values.d_code;
      let d_cate=values.d_cate;
      if(d_code==undefined){
        d_code=""
      }
      if(d_cate==undefined){
        d_cate=""
      }
      
      
      if (values.rootid == this.state.id) {
        dispatch(getDeacStock(0,fromDate,toDate, this.state.rootInfoEqual,values.rootid,d_cate,d_code));
       // dispatch(getQuotation(0, values.rootid, this.state.rootInfoEqual, date, values._issuedBy, values.customers));
      } else {
        dispatch(getDeacStock(0,fromDate,toDate, this.state.rootInfo,values.rootid,d_cate,d_code));
        //dispatch(getQuotation(0, values.rootid, this.state.rootInfo, date, values._issuedBy, values.customers));
      }
    });
  }


  btnFiltter1=(e)=>{
    // let pagination = {};
    // pagination.current = 1;
     this.setState({
        btn1:false,
        btn2:false,
    })
    switch (e) {
        case "NotViewed":
            this.setState({
                btn1:true,
                data:this.state.data2,
               // totalData:this.state.totalData2,
                design_category:this.state.design_category2,
                design_code:this.state.design_code2,
                SearchData:this.state.SearchData2
            })       
            break;
        case "NotSold":
            this.setState({
                btn2:true,
                data:this.state.data1,
               // totalData:this.state.totalData1,
                design_category:this.state.design_category1,
                design_code:this.state.design_code1,
                SearchData:this.state.SearchData1
            })
            break;
        default:
            break;
    }
    //this.props.form.resetFields();
    // let array=this.state.allArray;
    // this.setState({
    //     dataArray:array.filter(data => data.status === e)
    // });
    // if(this.state.lastBtnClick===e){
    //     let array=this.state.allArray;
    //     this.setState({
    //         dataArray:array,
    //         btn1:false,
    //         btn2:false,
    //         btn3:false,
    //         btn4:false,
    //         lastBtnClick:''
    //     });
    // }
 }

  onClearFiltter = (e) => {
 
    this.setState({
      table_loading: true,
      search:''
    })
    const { dispatch } = this.props;
    dispatch(getDeacStock(0, this.state.fromDate,this.state.toDate, this.state.rootInfoEqual,this.state.id,"","",""));
    this.props.form.resetFields();
  }
  
  handleTableChange = (pagination, filters, sorter) => {
    let colome=sorter.columnKey;
    let order=sorter.order;
    let orderopp=0;
    let data=this.state.data;
    if(order!=undefined){
      if(order==="descend"){
        order=-1;
        orderopp=1;
      }else{
        order=1;
        orderopp=-1;
      }
      // console.log(sorter.columnKey);
      data.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
    }else{
      const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
      shuffleArray(data);
    }
 };

  onSearch=()=>{
    let data = this.state.data
		if (this.state.search) {
      try {
        data = data.filter(row => {
          return row._id.toUpperCase().includes(this.state.search.toUpperCase())//||row.design_category.includes(this.state.search.toUpperCase())//||row.total.includes(this.state.search.toUpperCase()) 
          //row.name.toUpperCase().includes(this.state.search.toUpperCase())
        })
        this.setState({
          SearchData:data
        });
      } catch (error) {
        console.log(error);
      }
		
    }
  }

  searchOnchange=(e)=>{
    //console.log(e.target.value.length);
    this.setState({ search: e.target.value });
    if(e.target.value.length==0){
      this.setState({
        table_loading: true,
        search:''
      })
      const { dispatch } = this.props;
      dispatch(getDeacStock(0, this.state.fromDate,this.state.toDate, this.state.rootInfoEqual,this.state.id,"","","")); 
    }

  }

  
  
  render() {
    var heightd=window.innerHeight-480;
    const { getFieldDecorator } = this.props.form;
    const now = new Date();
    const fromDate=moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).subtract(30, "days");
    let data = this.state.SearchData
		// if (this.state.search) {
    //   try {
    //     data = data.filter(row => {
    //       return row._id.toUpperCase().includes(this.state.search.toUpperCase())//||row.design_category.includes(this.state.search.toUpperCase())//||row.total.includes(this.state.search.toUpperCase()) 
    //       //row.name.toUpperCase().includes(this.state.search.toUpperCase())
    //     })
    //   } catch (error) {
    //     console.log(error);
    //   }
		
    // }
    const formItemLayout = {
      //labelCol: { span: 24 },
      //   labalAlign:{marginLeft: "15px"},
      //wrapperCol: { span: 24 },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
    
    };
     this.columns = [  
      //   {
      //       title: 'Date',
      //       dataIndex: 'month',
      //       width: '10%',
      //       render: (text, record) => {
      //         const { finger_index } = record          
      //         return (
      //           <span>
      //               {text}
      //           </span>
      //         );
      //       }
      // },
      {
        title: 'DESIGN CODE',
        dataIndex: '_id',
        width: '10%',
        sorter: (a, b) => {},
        render: (text, record) => {
          const { mSku } = record;
          return <a onClick={()=>this.setLocalText(mSku)} >{text}</a>;
        },
        
      },
      {
        title: 'DESIGN CTG',
        dataIndex: 'design_category[0]',
        width: '10%',
        sorter: (a, b) => {},
        render: (text, record) => {
          const { finger_index } = record
          
          return (
            <span>
                {text}
            </span>
          );
        }
      },
    
      
      {
        title: 'QTY',
        dataIndex: 'sku_quantity',
        width: '10%',
        sorter: (a, b) => {},
        render: (text, record) => {
          const { customer } = record;
          return (
            <span>
                {text}
            </span>
          );
        }
      },
      {
        title: 'GROSS WT',
        dataIndex: 'gross_weight',
        width: '10%',
        sorter: (a, b) => {},
        render: (text, record) => {
          const { customer } = record;
          return (
            <span>
                {text.toFixed(3)}
            </span>
          );
        }
      },
      {
        title: 'NET WT',
        dataIndex: 'net_weight',
        width: '10%',
        sorter: (a, b) => {},
        render: (text, record) => {
          const { customer } = record;
          return (
            <span>
               {text.toFixed(3)}
            </span>
          );
        }
      },
      {
        title: 'PRICE',
        dataIndex: 'sales_value',
        width: '10%',
        sorter: (a, b) => {},
        render:(text,record)=>{
          return <span>{currencyConverter(text)}</span>;
        }
      },
      
      
    
      
    ];

    this.columns_for_SKUS= [{
      title: 'SKU NUMBER',
      dataIndex: 'sku_number',
      width: '12.5%',
    //  sorter: (a, b) => {},
      render: (text, record) => {     
       
        return (
          <span>
              {text}
          </span>
        );
      }
    }, 
    {
      title: 'DSG CODE',
      dataIndex: 'design_code',
      width: '12.5%',
     // sorter: (a, b) => {},
    }, 
    
    {
      title: 'METAL TYPE',
      dataIndex: 'metal_type',
      width: '12.5%',
      //sorter: (a, b) => {},
    },
    
    
    {
      title: 'DSG CTG',
      dataIndex: 'design_category',
      width: '12.5%',
     // sorter: (a, b) => {},
    }, 
    {
      title: 'GROSS WGT',
      dataIndex: 'gross_weight',
      width: '12.5%',
      //sorter: (a, b) => {},
      render: (text, record) => {
        
        return (
          <span>
              {text.toFixed(3)}
          </span>
        );
      }
    },
    {
      title: 'NET WGT',
      dataIndex: 'net_weight',
      width: '12.5%',
      //sorter: (a, b) => {},
      render: (text, record) => {
        
        return (
          <span>
            {text.toFixed(3)}
          </span>
        )
      }
    },
    {
      title:'SALES PRICE',
      dataIndex:'sales_value',
      width: '12.5%',
     // sorter: (a, b) => {},
      render:(text,record)=>{
          return <span>{currencyConverter(text)}</span>;
        }
      
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '12.5%',
 //     sorter: (a, b) => {},
      render: (text, record) => {
        return <span>{moment(text).format("DD-MM-YYYY")}</span>;
      }
    }
    // {
    //   title: 'Purity',
    //   dataIndex: 'purity',
    // },
    // {
    //   title:'Location',
    //   dataIndex:'Location',
    // }
    ];
    
    return (
      <div>
      <Card className="transaction-top-menu-card" bodyStyle={{ padding: "0" }} bordered={false}>
      <Row>
        <Col lg={6} md={6} sm={24} xs={24} style={{
          textAlign: "center"
        }}>
          <p className="transaction-title">Reports</p>
          <h1 className="transaction-sub-title">Find Dead Inventory</h1>
        </Col>

        <Col lg={10} md={10} sm={24} xs={24}>
          <Divider type="vertical" className="vertical-divider-transaction" />
          {/* <h1 className="total-text"><CountUp start={0} end={this.state.totalData} /> </h1> */}

              <Button style={{marginLeft:"10px",marginTop:"5px"}} className={this.state.btn1 ? "btn-for-total-text-customer-report-selected" :"btn-for-total-text-customer-report"} ghost onClick={()=> this.btnFiltter1("NotViewed")}>
                 <h1 className= {this.state.btn1 ? "total-btn-customer-report-selected" :"total-btn-customer-report"} >{this.state.totalData}</h1>
                 <h6 className={this.state.btn1 ? "text-color-h6-selected" :"text-color-h6-btn"}>Not Viewed</h6>
                </Button>
                {this.state.notSoldView === true ?
                <Button  style={{marginLeft:"10px"}}  className={this.state.btn2 ? "btn-for-total-text-customer-report-selected" :"btn-for-total-text-customer-report"} ghost onClick={()=> this.btnFiltter1("NotSold")}>
                 <h1 className= {this.state.btn2 ? "total-btn-customer-report-selected" :"total-btn-customer-report"} >{this.state.totalData1}</h1>
                  <h6 className={this.state.btn2 ? "text-color-h6-selected" :"text-color-h6-btn"}>Not Sold</h6>
                </Button>
                :""}
        </Col>

        <Col lg={8} md={8} sm={24} xs={24}>
          <p style={{ marginBottom: "5px" }} className="transaction-serach-text text-color-8c8c8c">Search</p>
          <Input
            value={this.state.search}
            placeholder="-- Search  Design Code -- "
            style={{ float: "left", width: "70%" }}
            onChange={this.searchOnchange}
            //onChange={e => this.setState({ search: e.target.value })}
          />
          <Button onClick={this.onSearch} style={{ marginLeft: "11px" }} type="primary">
            Search
     </Button>

        </Col>
      </Row>
    </Card>

    
    <Form hideRequiredMark={true} colon={false} labelAlign={"left"}>
      <Card className="filtter-card" bordered={false} bodyStyle={{ padding: "10px",paddingTop:"0px", paddingBottom: "0px" }}>
        <Row>
          <Col lg={2} md={2} sm={24} xs={24} className="text-aligin-center">
          <img  src={FilterIcon} className="filter-icon"/>
          </Col>
          <Col lg={5} md={5} sm={24} xs={24}  style={{paddingLeft:"0px"}} >

            <Form.Item label={`Date`}  {...formItemLayout} >
              {getFieldDecorator(`date`, {
                initialValue:[moment(fromDate, "DD-MM-YYYY"),moment(new Date(), "DD-MM-YYYY")],
                rules: [
                  {
                    required: false,
                  },
                ],
              })( <RangePicker
                // showTime={{ format: 'HH:mm' }}
                 format="DD-MM-YYYY"
                 placeholder={['From ', 'To']}
               //  defaultValue={[moment(fromDate, "DD-MM-YYYY"),moment(new Date(), "DD-MM-YYYY")]}
               //  size="small"
                // style={{width:"245px"}}
                separator="-" 
                style={{ minWidth: "100%" }}
              //   onChange={this.onChange}
                // onOk={onOk}
               />      )}
            </Form.Item>

          </Col>
          <Col lg={4} md={4} sm={24} xs={24} >
            {this.state.rootInfo === "branch" && this.state.branch_id != '' ?

              <Form.Item label={this.state.textForSelect} {...formItemLayout}>
                {getFieldDecorator(`rootid`, {
                  initialValue: this.state.id,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(<Select placeholder="Please select branch"  onChange={this.onChangeBranchRadio} style={{ minWidth: "100%" }} 
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                >
                <Option key={this.state.id} value={this.state.id}>All Branch</Option>
                {this.state.branch_list.map((data, index) =>
                    <Option key={data._id} value={data._id}>{data.name}</Option>
                  )}
                </Select>
                )}
              </Form.Item>


              : ""}

            {this.state.rootInfo === "floor" && this.state.floor_id != '' ?



              <Form.Item label={this.state.textForSelect} {...formItemLayout}>
                {getFieldDecorator(`rootid`, {
                  initialValue: this.state.id,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <Select placeholder="Please select floor"  
                  onChange={this.onChangeBranchRadio} style={{ minWidth: "100%" }}   
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  
                  >
                    <Option key={this.state.id} value={this.state.id}>All Floor</Option>
                    {this.state.floor_list.map((data, index) =>
                      <Option key={data._id} value={data._id}>{data.name}</Option>
                    )}
                  </Select>




                )}
              </Form.Item>


              : ""}

            {this.state.rootInfo === "section" && this.state.section_id != '' ?

              <Form.Item label={this.state.textForSelect}  {...formItemLayout} >
                {getFieldDecorator(`rootid`, {
                  initialValue: this.state.id,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <Select                  
                  placeholder="Please select section"  onChange={this.onChangeBranchRadio} style={{ minWidth: "100%" }} 
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  
                  >
                    <Option key={this.state.id} value={this.state.id}>All section</Option>
                    {this.state.section_list.map((data, index) =>
                      <Option key={data._id} value={data._id}>{data.name}</Option>
                    )}
                  </Select>

                )}
              </Form.Item>



              : ""}

          </Col>


          <Col lg={4} md={4} sm={24} xs={24} >
            <Form.Item label="Design Category"  {...formItemLayout} >
              {getFieldDecorator(`d_cate`, {
             
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Select mode="multiple" placeholder="- - Select - -" style={{ minWidth: "100%" }} >
           
                {this.state.design_category.map(user => (
                  <Option key={user} value={user}>{user}</Option>
                ))}

              </Select>)}
            </Form.Item>


          </Col>

          <Col lg={4} md={4} sm={24} xs={24} >
            <Form.Item label="Design Code"  {...formItemLayout} >
              {getFieldDecorator(`d_code`, {
               
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Select mode="multiple" 
              placeholder="- - Select - -" style={{ minWidth: "100%" }} 
              
              
              >
           
                {this.state.design_code.map(user => (
                  <Option key={user} value={user}>{user}</Option>
                ))}

              </Select>)}
            </Form.Item>


          </Col>
          
          <Col lg={5} md={5} sm={24} xs={24} >

            <div style={{ marginTop: "31px", paddingRight: "9px", float: "right" }}>
              <Button style={{ float: "left" }} onClick={this.btnFiltter} type="primary">
                Filter
                    </Button>
              <Button onClick={this.onClearFiltter} className="transaction-clear-btn" ghost>
                Clear
                </Button>
            </div>
          </Col>

        </Row>
      </Card>
    </Form>
 
       <Card style={{marginBottom:"0px"}} bodyStyle={{ padding: "10px 10px",paddingBottom:"0px" }} bordered={false}>  
                        <Table className="gx-table-responsive"
                            loading={this.state.table_loading} 
                            columns={this.columns} 
                            dataSource={data}
                            size={"small"}
                            scroll={{ y: heightd }}
                            rowKey={record => record.month}
                            onChange={this.handleTableChange}
                            sortDirections={sortDirections}
                            
                        />
                        </Card>

       
            

                        <Modal
                        visible={this.state.modelvisible}
                        title={"Stock Details"}
                        onOk={this.handleCancel}
                        onCancel={this.handleCancel}
                        footer={null}
                        width={1150}
                        style={{paddingBottom:"166px"}}
                        bodyStyle={{padding:0,paddingTop:"15px",paddingBottom:"15px"}}
                      >
                      <Table className="gx-table-responsive"
                          loading={this.state.table_loading_skuview} 
                          columns={this.columns_for_SKUS} 
                          dataSource={this.state.sku_data}
                          size={"small"}
                          rowKey={record => record._id}    
                          sortDirections={sortDirections}                      
                      />

                      </Modal>




        
                
      </div>
    )
  }
}

DeacStock = Form.create()(DeacStock);
function mapStateToProps(state) {
  const {Common } = state
  const {dead_stocks:dead_stocks,
    customer_details: customer_details, all_sales_persons: all_sales_persons,
    branch_list: branch_list, floor_list: floor_list, section_list: section_list,
    multiple_sku_items:multiple_sku_items
  } =  Common
  return {dead_stocks,customer_details, all_sales_persons, branch_list, floor_list, section_list,multiple_sku_items }
}

const AppContainer = connect( 
  mapStateToProps 
)(DeacStock);


export default AppContainer;


