import React, { Component } from 'react'
import IntlMessages from "util/IntlMessages";
import {connect} from "react-redux";
import {getCustomer,getCustomerReport,deleteCustomer,getCustomerSearch,getAllSalesPerson,getAllBrachList,getAllFloorList,getAllSectionList,getAllSubSectionList} from '../../actions/index';
import {Card, Table,Form,Row,Col,Modal,Select,Input,Button,Icon,Popconfirm,Alert,message,Divider,DatePicker} from "antd";
import ReactExport from "react-data-export";
import Gears from "../../assets/images/gears.gif";
import AddCustomer from "./add";
import socket from './../../containers/App/global';
import Edit from "./edit";
import View from "./view";
import Report from "./report";
import CountUp from 'react-countup';
import FilterIcon from '../../assets/images/icons/filter-icon.svg';
import moment from "moment";
import {currencyConverter,sortDirections} from "../../containers/App/commanFunction";
import AddCustomerImg from "./../../assets/images/icons/add-customer.svg";
import EditIcon from "../../assets/images/icons/edit-icon.svg";
import Upload from "./upload/index";
const Option = Select.Option;
const { RangePicker } = DatePicker;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { TextArea } = Input;
const cookies = require('browser-cookies');

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      table_loading:true,
      search:'',
      visible: false,
      confirmLoading: false,
      loading:true,
      dataforExport:[],
      totalPage:0,
      getPageFlagOn:false,
      total:0,
      searchCustomerDetails:[],
      allSalesPersons: [],
      branchList: [],
      textForSelect: '',
      sectionList: [],
      subsectionList:[],
      floorList: [],
      floorId: '',
      branchId: '',
      sectionId: '',
      slecteID: '',
      fromDate:"",
      toDate:"",
      root:"",
      id:"",
      salesperson:"",
      showReportView:true,
      currentPage:1,
      createCustomerVisible:false,
      viewEditCustomer:false,
      editCustomerData:[]
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;  
    //dispatch(getCustomer());
    let page_no=0;
    let root = JSON.parse(cookies.get('root'));
    let usertype = localStorage.getItem('type_user');
 //   dispatch(getAllSalesPerson("company", root.companyId));   
    dispatch(getCustomer(page_no,"","","",10,"","","","",""));
    if (usertype === "branchAdmin") {
      this.setState({
        rootInfo: "floor",
        rootInfoEqual: "branch",
        rootLess: "section",
        textForSelect: "Select Floor",
        textForSelect1: "Select Section",
        id: root.branchId,
        rootid: root.branchId,
        selectedId: root.branchId,
        selectedRootInfoEqual: "branch"
      });
      dispatch(getAllFloorList("id"));
      dispatch(getAllSalesPerson("branch", root.branchId));
    }
    if (usertype === "companyAdmin") {
      this.setState({
        rootInfo: "branch",
        rootInfoEqual: "company",
        rootLess: "floor",
        textForSelect: "Select Branch",
        textForSelect1: "Select Floor",
        id: root.companyId,
        rootid: root.companyId,
        selectedId: root.companyId,
        selectedRootInfoEqual: "company"
      });
      dispatch(getAllBrachList("id"));
      dispatch(getAllSalesPerson("company", root.companyId));
    }
    if (usertype === "floorAdmin") {
      this.setState({
        rootInfo: "section",
        rootInfoEqual: "floor",
        rootLess: "subsection",
        textForSelect: "Select Section",
        textForSelect1: "Select SubSection",
        id: root.floorId,
        rootid: root.floorId,
        selectedId: root.floorId,
        selectedRootInfoEqual: "floor"
      });
      dispatch(getAllSectionList("id"));
      dispatch(getAllSalesPerson("floor", root.floorId));
    }
    socket.on('Notification', (col) => {
      //console.log(col);
      if(col.data_code==="801"){
        let custdata=this.state.data;
        custdata.unshift(col.data_obj);
        let pagination = {};
        pagination.total = this.state.pagination.total+1;
        this.setState({
          data:custdata,
          total:this.state.total+1,
          pagination
        }) 
      }
      if(col.data_code==="802"){
        let custdata=this.state.data;
        for (let index = 0; index < custdata.length; index++) {
          if(col.data_obj._id===custdata[index]._id){
            custdata[index]=col.data_obj;
          }
        }
        this.setState({
          data:custdata,
        }) 
      }
     });
  }
  componentDidUpdate(prevProps, prevState) {

    
    if (prevProps.customer_details != this.props.customer_details) {
    // console.log(this.props.customer_details);
      if(this.props.customer_details.status)
      {
        let json=this.props.customer_details.data_array;      
        let pagination={};
        pagination.total = this.props.customer_details.data.totalRecord
        pagination.pageSize= 10 
          this.setState({
            data:json,
            pagination,
            total: this.props.customer_details.data.totalRecord
          })
        // console.log("json");
      	// console.log(json);
      } 
      this.setState({
        table_loading:false
       })
       
    } 

    if (prevProps.branch_list != this.props.branch_list) {
      if (this.props.branch_list.status) {
        this.setState({
          branchId: this.props.branch_list.data_array[0]._id,
          branchList: this.props.branch_list.data_array,
          slecteID: this.props.branch_list.data_array[0]._id
        });
        if (this.props.branch_list.data_array.length >= 1) {
          const { dispatch } = this.props;
          let page_no = 0;
       //   dispatch(getQuotation(page_no, this.state.id, this.state.rootInfoEqual,"","","","","",""));
          //dispatch(getSKUByRootInfoAndId(this.props.branch_list.data_array[0]._id,this.state.rootInfo));
        }
      } else {
        this.setState({
          branchList: []
        });
      }
    }
 
    if (prevProps.search_customer_details != this.props.search_customer_details) {
      // console.log(this.props.customer_details);
        if(this.props.search_customer_details.status)
        {
            this.setState({
              searchCustomerDetails:this.props.search_customer_details.data_array
            })
        } 
      } 

    
      if (prevProps.floor_list != this.props.floor_list) {
        if (this.props.floor_list.status) {
          this.setState({
            floorList: this.props.floor_list.data_array
          });      
        } else {
          this.setState({
            floorList: []
          });
        }
      }

      if (prevProps.section_list != this.props.section_list) 
      {  
        
        if(this.props.section_list.status)
        {  
         this.setState({
             sectionList:this.props.section_list.data_array,     
           });
        }else{
          this.setState({
            sectionList:[]    
          });
        }
      }

      if (prevProps.sub_section_list != this.props.sub_section_list) 
      {  
        
        if(this.props.sub_section_list.status)
        {  
         this.setState({
          subsectionList:this.props.sub_section_list.data_array,     
           });
        }else{
          this.setState({
            subsectionList:[]     
             });
        }
      }


      

    if (prevProps.customer_details_export != this.props.customer_details_export) {
      if(this.props.customer_details_export.status)
      {           
       
          this.setState({
            dataforExport:this.state.dataforExport.concat(this.props.customer_details_export.data_array),
            loading:false
          });
        
        if(this.props.customer_details_export.data.totalCustomer==this.state.dataforExport.length)
        {
          this.setState({
              loading:false
          });
        }else{
          if(this.props.customer_details_export.data.totalCustomer > 100 )
          {
            const { dispatch } = this.props;
            let page=parseInt(this.props.customer_details_export.data.currentPage)+1;
           // dispatch(getCustomerDataExport(page,100));
          }
        }
      }
    } 
  
    if (prevProps.update_customer != this.props.update_customer) {
      try {
     
         if(this.props.update_customer.status)
         {
             message.success("Update customer sucessfully");
             const { dispatch } = this.props;  
            dispatch(getCustomer(0,"","","",10,"","","","",""));
         }else{
             message.error(this.props.update_customer.errors.msg);
         } 
           
         } catch (error) {
             console.log(error);
         }
        
     } 

     
    if (prevProps.deletet_customer != this.props.deletet_customer) {
      try {
     
         if(this.props.deletet_customer.status)
         {
             message.success("Delete customer sucessfully");
             const { dispatch } = this.props;  
            dispatch(getCustomer(0,"","","",10,"","","","",""));
         }else{
             message.error(this.props.deletet_customer.errors.msg);
         } 
           
         } catch (error) {
             console.log(error);
         }
        
     } 

     
     if (prevProps.all_sales_persons != this.props.all_sales_persons) {
      try {

        if (this.props.all_sales_persons.status) {
          this.setState({
            allSalesPersons: this.props.all_sales_persons.data_array
          });
          //const { dispatch } = this.props;
          //dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,this.props.all_sales_persons.data_array[0].empId));
        }
        this.setState({
          tableLoading: false
        })
      } catch (error) {
        console.log(error);
      }
    }

     
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
    const { dispatch } = this.props;
   // dispatch(getCustomerDataExport(0,100));  
  }


  showCustomerEditModal=(record)=>{
    let pagination=this.state.pagination;
    pagination.current = this.state.currentPage
    this.setState({
      viewEditCustomer: !this.state.viewEditCustomer,
      editCustomerData:record,
      pagination
    });
  }
  

  showReportView = (code) => {
    //console.log(code);
    let pagination=this.state.pagination;
    pagination.current = this.state.currentPage
    this.setState({
      showReportView: !this.state.showReportView,
      pagination
    });
    const { dispatch } = this.props;
    if(code != undefined)
    {
      dispatch(getCustomerReport(code));  
    }   
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {dispatch} = this.props;
       // let userdata=values;
      
      }
    });
  }
  
  
  deleteCutomer = (e) => {
   console.log("e");
   console.log(e);
   const { dispatch } = this.props;
    dispatch(deleteCustomer(e));  
  }

  searchOnchange=(e)=>{
    //console.log(e.target.value.length);
    this.setState({ search: e.target.value });
    if(e.target.value.length==0){
      this.setState({
        table_loading:true
      })
      const { dispatch } = this.props;
      dispatch(getCustomer(0,"","","",10,"","","","",""));      
    }

  }

  handleChangeSearch=(e)=>{  
    console.log(e.length);
      const { dispatch } = this.props;
      this.setState({
        searchCustomerDetails:[]
      })
      if(e.length > 0){
        dispatch(getCustomerSearch(e)); 
      }
  }

  handleOnChangeSearch=(id)=>{
    this.setState({
      showReportView: !this.state.showReportView,
      searchCustomerDetails:[]
    });
    const { dispatch } = this.props;
    dispatch(getCustomerReport(id));     
  }


  handleCancel = () => {
    this.setState({
      visible: false,
      loading:true
    });
    
  }

  createCustomer=()=>{
    this.setState({
      createCustomerVisible: !this.state.createCustomerVisible,
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
   // console.log(sorter);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState({
      pagination: pager,
      table_loading:true,
      currentPage:pagination.current
    });
    const { dispatch } = this.props;
    //dispatch(getCustomer(pager.current-1,sorter.columnKey,sorter.order,this.state.search,10));  
    dispatch(getCustomer(pager.current-1,sorter.columnKey,sorter.order,"",10,this.state.fromDate,this.state.toDate,this.state.id,this.state.root,this.state.salesperson));
  }
  

  onChangeBranchRadio=(e)=> {
      const { dispatch } = this.props;
      if(this.state.rootInfoEqual==="company"){
        dispatch(getAllFloorList('bybranchId',e));
      }else
     if(this.state.rootInfoEqual==="branch"){
         dispatch(getAllSectionList('byFloorId',e));  
     }else 
     if(this.state.rootInfoEqual==="floor"){
      dispatch(getAllSubSectionList('bySectionId',e));  
     }
     this.props.form.resetFields("secondid"); 
  }
  onSerach=(e)=>{
    //this.setState({search: e.target.value})
    //   if(this.state.search.length >1){
    //     this.setState({
    //       table_loading:true
    //     })
    //     const { dispatch } = this.props;
    //     dispatch(getCustomer(0,"","",this.state.search,10));      
    // }
    
  }

  btnFiltter = () => {
    this.setState({
      table_loading: true,
    })
    try {
  this.props.form.validateFields((err, values) => {
         
    if (err) {
       return;
    }
    const pager = { ...this.state.pagination };
        pager.current = 1;
        pager.pageSize = 10;

        this.setState({
          pagination: pager,
          table_loading: true,
          pageSizelimit: 10,
          currantPage: pager.current - 1,
        });
    let fromDate="";
    let toDate="";
    if(values.date != undefined){
      if(values.date.length>0){
        //fromDate=values.date[0].toISOString();
       // toDate=values.date[1].toISOString();  
        let fromDate1=moment(values.date[0]).utc().set({'hour': 0, 'minute': 0,'second':0,'millisecond':0});
        let toDate1=moment(values.date[1]).utc().set({'hour': 23, 'minute': 59,'second':0,'millisecond':0});
        fromDate=fromDate1.toISOString();
        toDate=toDate1.toISOString();      
       }
    }
   
   
    let salesperson=values._issuedBy;
    let root=this.state.rootInfo;
    let id=id=values.rootid ;
    if(values.rootid === undefined){
      root="";
      id="" ;
    }
   
    if(values.secondid != undefined){
      id=values.secondid; 
      root=this.state.rootLess;
    } 
    if(values._issuedBy === undefined){
      salesperson=""
    } 
    const { dispatch } = this.props;

   // dispatch(getCustomer(0,fromDate,toDate,sales_person,customer,ctg,item,wt,price,time));
   if (values.rootid == this.state.rootid) {  
  //  root=this.state.rootInfoEqual;     
   // id=values.rootid; 
 
      dispatch(getCustomer(0,"","","",10,fromDate,toDate,values.rootid,this.state.rootInfoEqual,salesperson));
    } else {
         dispatch(getCustomer(0,"","","",10,fromDate,toDate,id,root,salesperson));    
      }  
      this.setState({
        fromDate:fromDate,
        toDate:toDate,
        root:root,
        id:id,
        salesperson:salesperson
      })
  })
} catch (error) {
  console.log(error);
}
}

onClearFiltter = (e) => {
  const { dispatch } = this.props;
  dispatch(getCustomer(0,"","","",10,"","","","",""));
  this.setState({
    fromDate:"",
    toDate:"",
    root:"",
    id:"",
    salesperson:"",
    subsectionList:[]
  })
  this.props.form.resetFields();

}
  	// 	if (this.state.search) {
		// 	data = data.filter(row => {
    //     return row.name.toUpperCase().includes(this.state.search.toUpperCase()) ||  String(row.contactNumber).includes(this.state.search)
    //     //row.name.toUpperCase().includes(this.state.search.toUpperCase())
		// 	})
		// }
  render() {
    var heightd=window.innerHeight-490;
    const { getFieldDecorator } = this.props.form;
    let data = this.state.data
    let {loading} =this.state;
    // this.columns1 = [
    //   {
    //     title: 'name',
    //     dataIndex: 'familyName',
    //     width: 100,
    //     fixed: true,
    //   },
    //   {
    //     title: 'No',
    //     dataIndex: 'contactNumber',
    //     width: 200,
    //     fixed: true,
    //   },
    //   {
    //     title: 'email',
    //     dataIndex: 'email',
    //     width: 100,
    //     fixed: true,
    //   },]

    this.columns = [  
      {
        title: 'NAME',
        dataIndex: 'familyName',
        width: '15%',
     //   ellipsis: true,
       //: (a, b) => {},
      // sortDirections:['descend','ascend'],
     // sorter:true,
        sorter: (a, b) => {},
        render: (text, record) => {
          let {firstName,familyName,code} = record;
          if(familyName===undefined){
            familyName=''
          }
          return (      
            <span>
            <a style={{color: "#545454"}} onClick={()=> this.showReportView(code)}>{familyName+" "+firstName}</a>
            </span>
          );
        }
      },
      {
        title: 'TEL.',
        dataIndex: 'contactNumber',
        //ellipsis: true,
        width: '10%',
        sorter: (a, b) => {},
        render: (text, record) => {
          const { Customer } = record;
          return (
            <span>
                {text}
            </span>
          );
        },
        
      },
      {
        title: 'EMAIL',
        dataIndex: 'email',
         width: '20%',
      //  ellipsis: true,
      //  sortOrder:['descend','ascend'],
        sorter: (a, b) => {},
        key:"email",
        // render: (text, record) => {
        //   const { Customer } = record;
        //   return (
        //     <span>
        //         {text}
        //     </span>
        //   );
        // },
        
      },
      // {
      //   title: 'GST',
      //   dataIndex: 'gst',
      //   width: '10%',
      //   render: (text, record) => {
      //     const { Customer } = record;
      //     return (
      //       <span>
      //           {text}
      //       </span>
      //     );
      //   }
      // },

      {
        title: 'COMPANY',
        dataIndex: 'companyName',
         width: '10%',
        sorter: (a, b) => {},
        render: (text, record) => {
          const { Customer } = record;
          return (
            <span>
            {text}
            </span>
          );
        }
        
       },
       {
        title: 'TOTAL NO. PURCHASES',
        dataIndex: 'itemTotal',
         width: '15%',
        // ellipsis: true,
        sorter: (a, b) => {},
        render: (text, record) => {
          return (
            <span>
            {text}
            </span>
          );
        }
       },
       {
        title: 'TOTAL PURCHASE VALUE',
        dataIndex: 'priceTotal',
         width: '20%',
        // ellipsis: true,
        sorter: (a, b) => {},
        render: (text, record) => {
          return (
            <span>
            {currencyConverter(text)}
            </span>
          );
        }
       },
      //  {
      //   title: 'GENDER',
      //   dataIndex: 'gender',
      //    width: '20%',
      //   // ellipsis: true,
      //   sorter: (a, b) => {},
      //   render: (text, record) => {
      //     return (
      //       <span>
      //       {text}
      //       </span>
      //     );
      //   }
      //  },
      // {
      //   title: 'PAN NO',
      //   dataIndex: 'pan',
      //   width: '8%',
      //   render: (text, record) => {
      //     const { Customer } = record;
      //     return (
      //       <span>
      //       {text}
      //       </span>
      //     );
      //   }
        
      // },  
      // {
      //   title: 'ADDRESS',
      //   dataIndex: 'address',
      //   //width: '14%',
      //   render: (text, record) => {
      //     const { Customer } = record;
      //     let length = 20;
      //     let trimmedString = text.length > length ? 
      //     text.substring(0, length - 3) + "..." : 
      //     text;
      //     return (
      //       <span>
      //       {trimmedString}
      //     </span>
      //     );
      //   }
      // },
      {
        title: 'ACTION',
        dataIndex: '_id',
         width: '10%',
    
        render: (text, record) => {
          const { _id,code } = record;
          let arrayRecord=[record];
          return (
            <span>          
              <div className="editable-row-operations" >
              <Icon type="eye" theme="filled" onClick={()=> this.showReportView(code)} style={{fontSize: 20,float:"left"}} />
              {/**<View  record={arrayRecord}/>
               */}
              <div style={{float:"left"}}>
              <img src={EditIcon} onClick={()=>this.showCustomerEditModal(record)} style={{paddingLeft:"15px",cursor:"pointer"}}></img>
              </div>
              <Popconfirm
              title="Are you sure delete customer ?"
              onConfirm={()=>this.deleteCutomer(_id)}
              //onCancel={cancel}
              okText="Yes"
              cancelText="No"
            > 
            <Icon type="delete" theme="filled" style={{fontSize: 20,paddingLeft:"20px"}} />
              </Popconfirm>
              </div>
            </span>
          );
        }
      },
    
    ];
		// if (this.state.search) {
		// 	data = data.filter(row => {
    //     return row.name.toUpperCase().includes(this.state.search.toUpperCase()) ||  String(row.contactNumber).includes(this.state.search)
    //     //row.name.toUpperCase().includes(this.state.search.toUpperCase())
		// 	})
    // }
    const formItemLayout = {
      //labelCol: { span: 24 },
      //   labalAlign:{marginLeft: "15px"},
      //wrapperCol: { span: 24 },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
    
    };
    return (
      <React.Fragment>
      {this.state.viewEditCustomer ?  <Edit record={this.state.editCustomerData} createCustomerClose={()=>this.showCustomerEditModal([])} /> : <React.Fragment>
       
      {this.state.createCustomerVisible ? <AddCustomer createCustomerClose={this.createCustomer} /> :<React.Fragment>
      {this.state.showReportView ? 
      <div>
      <Card className="customer-top-menu-card" bodyStyle={{ padding: "0" }} bordered={false}>
      <Row>
        <Col lg={4} md={4} sm={24} xs={24} style={{
          textAlign: "center"
        }}>
          <h1 className="customer-title">Customers</h1>
        </Col>
  
        <Col lg={3} md={3} sm={24} xs={24} style={{textAlign: "center"}}>
          <Divider type="vertical" className="vertical-divider-customer" />
          <h1 className="total-text"><CountUp start={0}  end={this.state.total}/> </h1>
        </Col>
        <Col lg={1} md={1} sm={24} xs={24} style={{textAlign: "center"}}>
             <Divider type="vertical" className="vertical-divider-customer1" />
         </Col>
        <Col lg={10} md={10} sm={24} xs={24}>
         
        
          <p style={{ marginBottom: "5px" }} className="transaction-serach-text text-color-8c8c8c">Search</p>
         {/** <Input
            value={this.state.search}
            placeholder="-- Search  Customer Name, Email,Contact No--"
            style={{ float: "left", width: "90%" }}
            onChange={this.searchOnchange}
          />
           {/** <Button onClick={this.onSerach} style={{ marginLeft: "11px" }} type="primary">
            Search
           </Button>   */}      
           <Select
           showSearch={true}
           //value={this.state.search}
           placeholder="-- Search  Customer Name --"
         //  style={this.props.style}
           defaultActiveFirstOption={true}
           showArrow={false}
           style={{ float: "left", width: "90%" }}
           filterOption={false}
           onSearch={this.handleChangeSearch}
           onChange={this.handleOnChangeSearch}
           notFoundContent={null}
         >
         {this.state.searchCustomerDetails.map((data, index) =>
               <Select.Option key={data._id} value={data.code}>{data.familyName} {data.firstName}</Select.Option>         
            )}
         </Select>   
        </Col>
        
        <Col lg={3} md={3} sm={24} xs={24} style={{textAlign:"center"}}  className="cursor-pointer">
      <Divider type="vertical" className="vertical-divider-stock-2" style={{marginTop: "14px!important"}}/>
     
     <React.Fragment> 
       <Upload />      
      {/* <h5 className="text-color-8c8c8c">Import Inventory</h5> */}
      
      </React.Fragment>
     </Col>
     {/* <Col lg={1} md={1} sm={24} xs={24} style={{textAlign: "center"}}>
        <Divider type="vertical" className="vertical-divider-customer1" />
        </Col> */}
        <Col lg={3} md={3} sm={24} xs={24} style={{textAlign: "center"}} >          
           <img className="add-customer-icon cursor-pointer" onClick={this.createCustomer} src={AddCustomerImg} />
           <h5 className="text-color-8c8c8c cursor-pointer" onClick={this.createCustomer}>Add Customer</h5>
        </Col>
      </Row>
    </Card>

      
    <Form hideRequiredMark={true} colon={false} labelAlign={"left"} >
    <Card className="filtter-card" bordered={false} bodyStyle={{ padding: "10px", paddingTop:"0px",paddingBottom: "0px" }}>
      <Row>
        <Col lg={2} md={2} sm={24} xs={24} className="text-aligin-center">
          <img  src={FilterIcon} className="filter-icon"/>
        </Col>
        <Col lg={5} md={5} sm={24} xs={24}  style={{paddingLeft:"0px"}}>

          <Form.Item label={`Date`}  {...formItemLayout} >
            {getFieldDecorator(`date`, {
              rules: [
                {
                  required: false,
                },
              ],
            })(
              <RangePicker
              format="DD-MM-YYYY"
              placeholder={['From ', 'To']}
              style={{ minWidth: "100%"}}
              separator="-" 
            /> 
              )}
          </Form.Item>

        </Col>
        <Col lg={4} md={4} sm={24} xs={24} >
          {this.state.rootInfo === "branch"  ?

            <Form.Item label={this.state.textForSelect} {...formItemLayout}>
              {getFieldDecorator(`rootid`, {
                initialValue: this.state.rootid,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Select placeholder="Please select branch"
                  onChange={this.onChangeBranchRadio} style={{ minWidth: "100%" }} >
                  <Option key={this.state.rootid} value={this.state.rootid}>All Branch</Option>
              {this.state.branchList.map((data, index) =>
                  <Select.Option key={data._id} value={data._id}>{data.name}</Select.Option>
                )}
              </Select>

              )}
            </Form.Item>


            : ""}

          {this.state.rootInfo === "floor" ?
            <Form.Item label={this.state.textForSelect} {...formItemLayout}>
              {getFieldDecorator(`rootid`, {
                initialValue: this.state.rootid,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Select placeholder="Please select floor"  onChange={this.onChangeBranchRadio} style={{ minWidth: "100%" }}   >
                <Option key={this.state.rootid} value={this.state.rootid}>All Floor</Option>                 
                  {this.state.floorList.map((data, index) =>
                    <Select.Option key={data._id} value={data._id}>{data.name}</Select.Option>
                  )}
                </Select>
              )}
            </Form.Item>
            : ""}

          {this.state.rootInfo === "section"  ?
            <Form.Item label={this.state.textForSelect}  {...formItemLayout} >
              {getFieldDecorator(`rootid`, {
               initialValue: this.state.rootid,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Select placeholder="Please select section"  onChange={this.onChangeBranchRadio} style={{ minWidth: "100%" }} >
                <Option key={this.state.rootid} value={this.state.rootid}>All section</Option>
                  {this.state.sectionList.map((data, index) =>
                    <Select.Option key={data._id} value={data._id}>{data.name}</Select.Option>
                  )}
                </Select>

              )}
            </Form.Item>
            : ""}

        </Col>

        <Col lg={4} md={4} sm={24} xs={24} >
        {this.state.rootInfo === "branch" ?
            <Form.Item label={this.state.textForSelect1} {...formItemLayout}>
            {getFieldDecorator(`secondid`, {
            // initialValue: this.state.id,
              rules: [
                {
                  required: false,
                },
              ],
            })(
              <Select placeholder="Please select floor"   style={{ minWidth: "100%" }}   >
                {this.state.floorList.map((data, index) =>
                  <Select.Option key={data._id} value={data._id}>{data.name}</Select.Option>
                )}
              </Select>
            )}
          </Form.Item>
        : ""}

        {this.state.rootInfo === "floor" ?
        <Form.Item label={this.state.textForSelect1}  {...formItemLayout} >
        {getFieldDecorator(`secondid`, {
          //initialValue: this.state.id,
          rules: [
            {
              required: false,
            },
          ],
        })(
          <Select placeholder="Please select section"  style={{ minWidth: "100%" }} >
           
            {this.state.sectionList.map((data, index) =>
              <Select.Option key={data._id} value={data._id}>{data.name}</Select.Option>
            )}
          </Select>

        )}
      </Form.Item>
        : ""}


        {this.state.rootInfo === "section"  ?
        <Form.Item label={this.state.textForSelect1}  {...formItemLayout} >
          {getFieldDecorator(`secondid`, {
          // initialValue: this.state.id,
            rules: [
              {
                required: false,
              },
            ],
          })(
            <Select placeholder="Please select sub section"  style={{ minWidth: "100%" }} >
            
              {this.state.subsectionList.map((data, index) =>
                <Select.Option key={data._id} value={data._id}>{data.name}</Select.Option>
              )}
            </Select>

          )}
        </Form.Item>
        : ""}
            

      </Col>


        <Col lg={4} md={4} sm={24} xs={24} >
          <Form.Item label="Employee"  {...formItemLayout} >
            {getFieldDecorator(`_issuedBy`, {
              rules: [
                {
                  required: false,
                },
              ],
            })(<Select placeholder="- - Select - -" style={{ minWidth: "100%" }} >
              {this.state.allSalesPersons.map(user => (
                <Select.Option key={user._id.empId} value={user._id.empId}>{user._id.name}</Select.Option>
              ))}

            </Select>)}
          </Form.Item>
        
        </Col>

      

        <Col lg={5} md={5} sm={24} xs={24} >

          <div style={{ marginTop: "31px", paddingRight: "9px", float: "right" }}>
            <Button style={{ float: "left" }} onClick={this.btnFiltter} type="primary">
              Filter
                  </Button>
            <Button onClick={this.onClearFiltter} className="transaction-clear-btn" ghost>
              Clear
              </Button>
          </div>
        </Col>

      </Row>
    </Card>
  </Form>

     
      {/** 
      <Icon type="download" style={{float:"right",fontSize:"27px",cursor:"pointer"}}  className="certain-category-icon" onClick={this.createCustomer}>this</Icon>
      */}
 
   
      <div className="certain-category-search-wrapper">   
     {/**  <Row>
      <Col lg={21} md={20} sm={24} xs={24}>
      <Input 	value={this.state.search}  placeholder="Search .." style={{width:"100%",float:"left"}}
      onChange={e => this.setState({search: e.target.value})}  suffix={<Icon type="search" className="certain-category-icon"/>}/>
      
      </Col>
           <Col lg={3} md={4} sm={24} xs={24}>
            
            </Col>
          </Row> */}
           
            {/** <Popconfirm title="Are you sure？" icon={<Icon type="question-circle-o" style={{ color: 'red' }} />} 
            okText=""
            icon={
              <ExcelFile filename={`Customer Details - ${new Date()}`}  fileExtension ={'txt'}>
                <ExcelSheet data={this.state.data} name="Customer">
                          <ExcelColumn label="Name" value="name"/>
                          <ExcelColumn label="Contact Number" value="contactNumber"/>
                          <ExcelColumn label="E-mail" value="email"/>
                          <ExcelColumn label="GST" value="gst"/>
                          <ExcelColumn label="Company Name" value="companyName"/>
                          <ExcelColumn label="Pan No" value="pan"/>
                          <ExcelColumn label="Address" value="address"/>                          
                  </ExcelSheet>
                </ExcelFile>
          }>
          <Icon type="cloud-download" style={{float: "right" ,fontSize: "27px", cursor: "pointer"}} />
      </Popconfirm>
  */}
        {/*  <Icon type="cloud-download" onClick={this.showModal} style={{float: "right" ,fontSize: "27px", cursor: "pointer"}} />
        
      */}<Modal
          title={loading ?`Data is processing please wait ...` :`Customer File Created`}
         visible={this.state.visible}
         //onOk={this.handleOk}
       //  confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        // style={{backgroundImage: `url('https://www.codeproject.com/KB/aspnet/1095434/gears.gif')`}}
          footer={[
            <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
            <ExcelFile filename={`Customer Details - ${new Date()}`} element={
              loading ? <Button key="submit" type="primary" onClick={this.handleOk} disabled>Wait..  </Button>:
              <Button key="submit" type="primary" onClick={this.handleOk} > Download</Button>
            } 
              >
            <ExcelSheet data={this.state.dataforExport} name="Customer">
                      <ExcelColumn label="Name" value="name"/>
                      <ExcelColumn label="Contact Number" value="contactNumber"/>
                      <ExcelColumn label="E-mail" value="email"/>
                      <ExcelColumn label="GST" value="gst"/>
                      <ExcelColumn label="Company Name" value="companyName"/>
                      <ExcelColumn label="Pan No" value="pan"/>
                      <ExcelColumn label="Address" value="address"/>                          
              </ExcelSheet>
            </ExcelFile>
            ,
          ]}
        >
        
        {loading ?
          <div style={{minHeight:"150px" ,backgroundImage: `url(${Gears})`}}>          
          </div>
          :
          <Alert
            message="Success"
            description="Customer file is ready you can download."
            type="success"
            showIcon
          />
         }
        </Modal>

       </div>



           <Card style={{paddingBottom:"0px"}} bodyStyle={{ padding: "10px 10px",paddingBottom:"0px" }}  bordered={false}> 
           <Table 
                  className="gx-table-responsive" 
                  loading={this.state.table_loading} 
                  columns={this.columns} 
                  dataSource={data}
                  size={"small"}
                  scroll={{ y: heightd,x:false}}
                  rowKey={record => record._id}
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}    
                 // ellipsis={true}
                  sortDirections={sortDirections}
                //  style={{ height:heightd,overflow: "auto"}}


                />

        {/* <Table
         // components={components}
          bordered
          dataSource={this.state.data}
          columns={this.columns1}
         // scroll={{ x: true }}
           //pagination={this.state.pagination}
               //   onChange={this.handleTableChange} 
          // rowClassName="editable-row"
          // pagination={{
          //   onChange: this.cancel,
          // }}
        /> */}
                </Card>
      </div>
      :
      <Report showReportView={()=>this.showReportView()}/>   
      }
      </React.Fragment>}
      </React.Fragment>}
      </React.Fragment>
    )
  }
}

Customer = Form.create()(Customer);
function mapStateToProps(state) {
  const {Common } = state
  const {sub_section_list:sub_section_list,section_list:section_list,floor_list:floor_list,sales_details:sales_details,customer_details:customer_details,customer_details_export:customer_details_export,update_customer:update_customer,deletet_customer:deletet_customer,search_customer_details:search_customer_details,all_sales_persons: all_sales_persons,branch_list:branch_list  } =  Common
  return {sub_section_list,section_list,floor_list,sales_details,customer_details,customer_details_export,update_customer,deletet_customer,search_customer_details,all_sales_persons,branch_list}
}

const AppContainer = connect( 
  mapStateToProps 
)(Customer);


export default AppContainer;



