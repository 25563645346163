import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import {
  getTryActivities,
  getCustomer,
  getAllSalesPerson,
} from "../../../actions/index";
import CountUp from "react-countup";
///import {getAllSalesPerson} from '../../actions/dashboard';
import moment from "moment";
import {
  Card,
  Table,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  InputNumber,
  Button,
  Switch,
  Input,
  Icon,
  AutoComplete,
  Slider,
  Divider,
} from "antd";
import {
  currencyConverter,
  sortDirections,
} from "../../../containers/App/commanFunction";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
const FormItem = Form.Item;
const { MonthPicker, RangePicker } = DatePicker;
const Option = Select.Option;
const columnsForTableView = [
  {
    title: "EMPLOYEE",
    dataIndex: "employee.lastname",
    key: "employee",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      let { employee } = record;

      let firstname = "";
      let lastname = "";
      try {
        firstname = employee.firstname;
        lastname = employee.lastname;
      } catch (error) {
        firstname = "";
        lastname = "";
      }

      // firstname,lastname=employee;
      if (firstname === undefined) {
        firstname = "";
      }
      if (lastname === undefined) {
        lastname = "";
      }
      return <span>{lastname + " " + firstname}</span>;
    },
  },
  {
    title: "CUSTOMER",
    dataIndex: "cName",
    width: "15%",
    key: "cName",
    sorter: (a, b) => {},
  },
  {
    title: "DESIGN CTG",
    dataIndex: "ctg",
    width: "10%",
    key: "ctg",
    sorter: (a, b) => {},
  },
  {
    title: "ITEM",
    dataIndex: "item",
    width: "15%",
    key: "item",
    sorter: (a, b) => {},
  },
  {
    title: "DIA CT.",
    dataIndex: "weight",
    width: "10%",
    key: "weight",
    sorter: (a, b) => {},
  },
  {
    title: "PRICE",
    dataIndex: "price",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      return <span>{currencyConverter(text)}</span>;
    },
    //key:'price',
  },
  // {
  //   title:'SOLD',
  //   dataIndex:'sold',
  //  // width:'15%',
  //   key:'sold',
  // },
  // {
  //   title:'TIMS(mins)',
  //   dataIndex:'time',
  //   width:'15%',
  //   key:'time',
  //   sorter: (a, b) => {},
  // },
  {
    title: "DATE",
    dataIndex: "date",
    width: "15%",
    sorter: (a, b) => {},
    render: (text, record) => {
      var datef = moment(text).format("DD-MM-YYYY");
      //  s = s.substring(0, s.lastIndexOf('/'));
      return <span>{datef}</span>;
    },
  },
];

class trayActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      table_loading: true,
      switchview: true,
      newtWetStart: 0,
      newtWetEnd: 0,
      priceStart: 0,
      priceEnd: 0,
      timeMinStart: 0,
      timeMinEnd: 0,
      TableViewData: [],
      fromDate: "",
      toDate: "",
      update: 1,
      maxValNt: 0,
      minValNt: 0,
      maxValPrice: 0,
      minValPrice: 0,
      maxValTime: 0,
      minValTime: 0,
      sName: [],
      cName: [],
      itemList: [],
      selectdSalePerson: "",
      selectedCustomer: "",
      selectdCtg: "",
      selectedItem: "",
      weight: "",
      price: "",
      time: "",
      allSalesPersons: [],
    };
  }
  componentDidMount() {
    // console.log("this.state.time");
    //   console.log(this.state.time);
    const { dispatch } = this.props;
    let now = new Date();
    let starDate = (fromDate = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ).subtract(30, "days"));
    let fromDate = starDate.toISOString();
    let toDate = new Date().toISOString();
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
    });
    dispatch(
      getTryActivities(
        0,
        fromDate,
        toDate,
        this.state.selectdSalePerson,
        this.state.selectedCustomer,
        this.state.selectdCtg,
        this.state.selectedItem,
        this.state.weight,
        this.state.price,
        this.state.time
      )
    );

    dispatch(getCustomer(0));
    dispatch(
      getAllSalesPerson(this.state.selectedRootInfoEqual, this.state.selectedId)
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.try_activities != this.props.try_activities) {
      // console.log("this.props.try_activities");
      // console.log(this.props.try_activities);
      //data_array
      try {
        if (this.props.try_activities.status) {
          let dataArray = this.props.try_activities.data_array;
          let category = [];
          let maxCountNt = [];
          let maxCountPrice = [];
          let maxCounttime = [];
          let sName = [];
          let cName = [];
          let itemList = [];
          for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            //console.log(element.sName);
            category.push(element.ctg);
            sName.push({ empId: element.empId, sName: element.sName });
            cName.push(element.cName);
            itemList.push(element.item);
            maxCountNt.push(element.weight);
            maxCountPrice.push(element.price);
            maxCounttime.push(element.time);
          }
          // console.log(category);
          let maxValNt = Math.max.apply(null, maxCountNt);
          let minValNt = Math.min.apply(null, maxCountNt);
          let maxValPrice = Math.max.apply(null, maxCountPrice);
          let minValPrice = Math.min.apply(null, maxCountPrice);
          let maxValTime = Math.max.apply(null, maxCounttime);
          let minValTime = Math.min.apply(null, maxCounttime);

          //remove dublicate

          let nsNamecategory = [...new Set(category)];
          let categoryOptionArray = [];
          for (let index1 = 0; index1 < nsNamecategory.length; index1++) {
            const element = nsNamecategory[index1];
            categoryOptionArray.push(
              <Option key={index1.toString(36) + index1} value={element}>
                {element}
              </Option>
            );
          }

          let nsName = [...new Set(sName)];
          let optionArray = [];
          for (let index1 = 0; index1 < nsName.length; index1++) {
            const element = nsName[index1];
            optionArray.push(
              <Option
                key={index1.toString(36) + index1 + "op"}
                value={element.empId}>
                {element.sName}
              </Option>
            );
          }
          let ncName = [...new Set(cName)];
          let cOptionArray = [];
          for (let index1 = 0; index1 < ncName.length; index1++) {
            const element = ncName[index1];
            cOptionArray.push(
              <Option key={index1.toString(36) + index1 + "op"} value={element}>
                {element}
              </Option>
            );
          }

          let itemListName = [...new Set(itemList)];
          // console.log(itemListName);
          let itemOptionArray = [];
          for (let index1 = 0; index1 < itemListName.length; index1++) {
            const element = itemListName[index1];
            itemOptionArray.push(
              <Option
                key={index1.toString(36) + index1 + "item"}
                value={element}>
                {element}
              </Option>
            );
          }

          if (this.state.update == 1) {
            this.setState({
              category: categoryOptionArray,
              maxValNt: maxValNt,
              minValNt: minValNt,
              maxValPrice: maxValPrice,
              minValPrice: minValPrice,
              maxValTime: maxValTime,
              minValTime: minValTime,
              sName: optionArray,
              // cName:cOptionArray,
              itemList: itemOptionArray,
            });
          }
          this.setState({
            TableViewData: this.props.try_activities.data_array,
            Withoutsorting: this.props.try_activities.data_array,
            update: 2,
          });
        }
        this.setState({
          table_loading: false,
        });
      } catch (error) {
        console.log(error);
      }
    }

    if (prevProps.customer_details != this.props.customer_details) {
      if (this.props.customer_details.status) {
        {
          /** let json=this.props.customer_details.data_array;   
         let cOptionArray=[];   
         for (let index1 = 0; index1 < json.length; index1++) {
          const element = json[index1];
          cOptionArray.push(<Option key={index1.toString(36) + index1+'cust'} value={element.code}>{element.firstName} {element.familyName}</Option>);
        }
        */
        }
        let cNameArray = this.props.customer_details.data_array;
        cNameArray.sort((a, b) => (a.familyName > b.familyName ? 1 : -1));
        this.setState({
          cName: cNameArray,
        });
      }
    }

    if (prevProps.all_sales_persons != this.props.all_sales_persons) {
      try {
        if (this.props.all_sales_persons.status) {
          let allSalesPersonsSort = this.props.all_sales_persons.data_array;
          allSalesPersonsSort.sort((a, b) =>
            a._id.name > b._id.name ? 1 : -1
          );
          this.setState({
            allSalesPersons: allSalesPersonsSort,
          });
          //const { dispatch } = this.props;
          //dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,this.props.all_sales_persons.data_array[0].empId));
        }
        this.setState({
          // table_loading:false
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
  chageView = (checked) => {
    // console.log(`switch to ${checked}`);
    this.setState({
      switchview: checked,
    });
  };

  onChangePrice = (value) => {
    this.setState({
      priceStart: value[0],
      priceEnd: value[1],
      price: `${value[0]},${value[1]}`,
    });
    let price = `${value[0]},${value[1]}`;
    //const { dispatch } = this.props;
    //dispatch(getTryActivities(0,this.state.fromDate,this.state.toDate,this.state.selectdSalePerson,this.state.selectedCustomer,this.state.selectdCtg,this.state.selectedItem,this.state.weight,price,this.state.time));
  };

  onChangeGrossWT = (value) => {
    this.setState({
      timeMinStart: value[0],
      timeMinEnd: value[1],
      time: `${value[0]},${value[1]}`,
    });
    let time = `${value[0]},${value[1]}`;
    // const { dispatch } = this.props;
    //dispatch(getTryActivities(0,this.state.fromDate,this.state.toDate,this.state.selectdSalePerson,this.state.selectedCustomer,this.state.selectdCtg,this.state.selectedItem,this.state.weight,this.state.price,time));
  };
  onChangeNetWT = (value) => {
    //alert("ok");
    this.setState({
      newtWetStart: value[0],
      newtWetEnd: value[1],
      //weight:`${value[0]},${value[1]}`
    });
    //let weight=`${value[0]},${value[1]}`;
    // const { dispatch } = this.props;
    // dispatch(getTryActivities(0,this.state.fromDate,this.state.toDate,this.state.selectdSalePerson,this.state.selectedCustomer,this.state.selectdCtg,this.state.selectedItem,weight,this.state.price,this.state.time));
  };
  onChange = (date, dateString) => {
    this.setState({
      table_loading: true,
    });
    if (date.length == 2) {
      try {
        // console.log(date);
        const { dispatch } = this.props;
        let fromDate = date[0].toISOString();
        let toDate = date[1].toISOString();
        this.setState({
          fromDate: fromDate,
          toDate: toDate,
        });

        dispatch(
          getTryActivities(
            0,
            fromDate,
            toDate,
            this.state.selectdSalePerson,
            this.state.selectedCustomer,
            this.state.selectdCtg,
            this.state.selectedItem,
            this.state.weight,
            this.state.price,
            this.state.time
          )
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      this.setState({
        data: [],
        table_loading: false,
      });
    }
  };

  handleChangePerson = (value) => {
    this.setState({
      table_loading: true,
      selectdSalePerson: value,
    });
    const { dispatch } = this.props;
    dispatch(
      getTryActivities(
        0,
        this.state.fromDate,
        this.state.toDate,
        value,
        this.state.selectedCustomer,
        this.state.selectdCtg,
        this.state.selectedItem,
        this.state.weight,
        this.state.price,
        this.state.time
      )
    );
  };

  handleChangeCutomer = (value) => {
    this.setState({
      table_loading: true,
      selectedCustomer: value,
    });
    const { dispatch } = this.props;
    dispatch(
      getTryActivities(
        0,
        this.state.fromDate,
        this.state.toDate,
        this.state.selectdSalePerson,
        value,
        this.state.selectdCtg,
        this.state.selectedItem,
        this.state.weight,
        this.state.price,
        this.state.time
      )
    );
  };
  handleChangeCtg = (value) => {
    this.setState({
      table_loading: true,
      selectdCtg: value,
    });
    const { dispatch } = this.props;
    dispatch(
      getTryActivities(
        0,
        this.state.fromDate,
        this.state.toDate,
        this.state.selectdSalePerson,
        this.state.selectedCustomer,
        value,
        this.state.selectedItem,
        this.state.weight,
        this.state.price,
        this.state.time
      )
    );
  };

  handleChangeItem = (value) => {
    this.setState({
      table_loading: true,
      selectedItem: value,
    });
    const { dispatch } = this.props;
    dispatch(
      getTryActivities(
        0,
        this.state.fromDate,
        this.state.toDate,
        this.state.selectdSalePerson,
        this.state.selectedCustomer,
        this.state.selectdCtg,
        value,
        this.state.weight,
        this.state.price,
        this.state.time
      )
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    // console.log(sorter);
    let colome = sorter.columnKey;
    let order = sorter.order;
    let orderopp = 0;
    let tableViewdata = this.state.TableViewData;
    if (order != undefined) {
      if (order === "descend") {
        order = -1;
        orderopp = 1;
      } else {
        order = 1;
        orderopp = -1;
      }

      tableViewdata.sort((a, b) =>
        a[`${colome}`] > b[`${colome}`] ? order : orderopp
      );
    } else {
      const shuffleArray = (arr) => arr.sort(() => Math.random() - 0.5);
      shuffleArray(tableViewdata);
    }

    //temp1.sort((a, b) => (a.cName > b.cName) ? -1 : 1);
  };
  onClearFiltter = (e) => {
    this.setState({
      table_loading: true,
      search: "",
      newtWetStart: 0,
      newtWetEnd: 0,
      priceStart: 0,
      priceEnd: 0,
      timeMinStart: 0,
      timeMinEnd: 0,
      // maxValNt:0,
      // minValNt:0,
      // maxValPrice:0,
      // minValPrice:0,
      // maxValTime:0,
      // minValTime:0,
    });
    const { dispatch } = this.props;
    dispatch(
      getTryActivities(
        0,
        this.state.fromDate,
        this.state.toDate,
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      )
    );
    this.props.form.resetFields();
  };

  onSearch = (e) => {
    // console.log("ok");
    let TableViewData = this.state.TableViewData;
    if (this.state.search.length > 1) {
      // console.log("inside");
      try {
        TableViewData = TableViewData.filter((row) => {
          let datef = moment(row.date).format("DD-MM-YYYY");
          return (
            datef.toUpperCase().includes(this.state.search.toUpperCase()) ||
            row.sName.toUpperCase().includes(this.state.search.toUpperCase()) ||
            row.ctg.toUpperCase().includes(this.state.search.toUpperCase()) ||
            row.item.toUpperCase().includes(this.state.search.toUpperCase()) ||
            row.cName.toUpperCase().includes(this.state.search.toUpperCase())
          );

          //|| row.ctg.includes(this.state.search.toUpperCase())||row.item.toUpperCase().includes(this.state.search.toUpperCase())
          //||row.total.includes(this.state.search.toUpperCase())
          //row.name.toUpperCase().includes(this.state.search.toUpperCase())
        });
        this.setState({
          TableViewData: TableViewData,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  btnFiltter = () => {
    this.setState({
      table_loading: true,
    });
    try {
      this.props.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        let fromDate = "";
        let toDate = "";
        const pager = { ...this.state.pagination };
        pager.current = 1;
        pager.pageSize = 10;

        this.setState({
          pagination: pager,
          table_loading: true,
          pageSizelimit: 10,
          currantPage: pager.current - 1,
        });
        if (values.date.length > 0) {
          fromDate = values.date[0].toISOString();
          toDate = values.date[1].toISOString();
          //  this.setState({
          //   fromDate:fromDate,
          //   toDate:toDate
          // })
        }
        // console.log("values");
        // console.log(values);
        let sales_person = values.sales_person;
        let customer = values.customer;
        let ctg = values.ctg;
        let item = values.item;
        let wt = values.wt;
        let price = values.price;
        let time = values.time;
        if (sales_person === undefined) {
          sales_person = "";
        }
        if (customer === undefined) {
          customer = "";
        }
        if (ctg === undefined) {
          ctg = "";
        }
        if (item === undefined) {
          item = "";
        }
        if ((wt[0] === 0 && wt[1] === 0) || wt === undefined) {
          wt = "";
        }
        if (price[0] === 0 && price[1] === 0) {
          price = "";
        }
        // if(time[0]===0 && time[1]===0 ){
        time = "";
        // }
        const { dispatch } = this.props;
        dispatch(
          getTryActivities(
            0,
            fromDate,
            toDate,
            sales_person,
            customer,
            ctg,
            item,
            wt,
            price,
            time
          )
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  searchOnchange = (e) => {
    this.setState({ search: e.target.value });
    if (e.target.value.length == 0) {
      try {
        this.setState({
          TableViewData: this.state.Withoutsorting,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let now = new Date();
    const fromDate = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ).subtract(30, "days");
    let data = this.state.TableViewData;
    var heightd = window.innerHeight - 550;
    const formItemLayout = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    // if (this.state.search) {
    //   try {
    //     data = data.filter(row => {
    //       let datef=moment(row.date).format("DD-MM-YYYY");
    //       return datef.toUpperCase().includes(this.state.search.toUpperCase()) || row.sName.toUpperCase().includes(this.state.search.toUpperCase()) || row.ctg.toUpperCase().includes(this.state.search.toUpperCase()) || row.item.toUpperCase().includes(this.state.search.toUpperCase()) || row.cName.toUpperCase().includes(this.state.search.toUpperCase())
    //       //||row.sName.includes(this.state.search.toUpperCase())||row.ctg.includes(this.state.search.toUpperCase())||row.item.toUpperCase().includes(this.state.search.toUpperCase())//||row.total.includes(this.state.search.toUpperCase())
    //       //row.name.toUpperCase().includes(this.state.search.toUpperCase())
    //     })
    //   } catch (error) {
    //     console.log(error);
    //   }

    // }
    return (
      <div>
        <Card
          className='transaction-top-menu-card'
          bodyStyle={{ padding: "0" }}
          bordered={false}>
          <Row>
            <Col
              lg={5}
              md={5}
              sm={24}
              xs={24}
              style={{
                textAlign: "center",
              }}>
              <p className='transaction-title'>Reports</p>
              <h1 className='transaction-sub-title'>Tray Activities</h1>
            </Col>

            <Col lg={11} md={11} sm={24} xs={24}>
              <Divider
                type='vertical'
                className='vertical-divider-transaction'
              />
              <h1 className='total-text'>
                <CountUp start={0} end={data.length} />{" "}
              </h1>
            </Col>

            <Col lg={8} md={8} sm={24} xs={24}>
              <p
                style={{ marginBottom: "5px" }}
                className='transaction-serach-text text-color-8c8c8c'>
                Search
              </p>
              <Input
                value={this.state.search}
                placeholder='-- Search  -- '
                style={{ float: "left", width: "70%" }}
                onChange={this.searchOnchange}
              />
              <Button
                onClick={this.onSearch}
                style={{ marginLeft: "11px" }}
                type='primary'>
                Search
              </Button>
            </Col>
          </Row>
        </Card>

        <Form hideRequiredMark={true} colon={false} labelAlign={"left"}>
          <Card
            className='filtter-card'
            bordered={false}
            bodyStyle={{
              padding: "10px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}>
            <Row>
              <Col lg={2} md={2} sm={24} xs={24} className='text-aligin-center'>
                <img src={FilterIcon} className='filter-icon' />
              </Col>
              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label={`Employee`} {...formItemLayout}>
                  {getFieldDecorator(`sales_person`, {
                    //initialValue:[moment(fromDate, "DD-MM-YYYY"),moment(new Date(), "DD-MM-YYYY")],
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      showSearch={false}
                      mode='multiple'
                      //showSearch={false}
                      placeholder='Please select employee'
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }>
                      {this.state.allSalesPersons.map((user) => (
                        <Option key={user._id.empId} value={user._id.empId}>
                          {user._id.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label={`Customer`} {...formItemLayout}>
                  {getFieldDecorator(`customer`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      showSearch={false}
                      mode='multiple'
                      placeholder='Please select customer'
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }>
                      {this.state.cName.map((element) => (
                        <Option key={element.code} value={element.code}>
                          {element.familyName + " " + element.firstName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label={`Design Category`} {...formItemLayout}>
                  {getFieldDecorator(`ctg`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      mode='multiple'
                      //  size={size}
                      placeholder='Please select design Category'
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }>
                      {this.state.category}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label={`Item`} {...formItemLayout}>
                  {getFieldDecorator(`item`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      mode='multiple'
                      //  size={size}
                      placeholder='Please select Item'
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }>
                      {this.state.itemList}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item label={`Date`} {...formItemLayout}>
                  {getFieldDecorator(`date`, {
                    initialValue: [
                      moment(fromDate, "DD-MM-YYYY"),
                      moment(new Date(), "DD-MM-YYYY"),
                    ],
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <RangePicker
                      format='DD-MM-YYYY'
                      placeholder={["From ", "To"]}
                      style={{ minWidth: "100%" }}
                      separator='-'
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginBottom: "-10px" }}>
              <Col lg={1} md={1} sm={24} xs={24}></Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label={`Dia Ct. ( From : ${this.state.newtWetStart} To : ${this.state.newtWetEnd} )`}
                  {...formItemLayout}>
                  {getFieldDecorator(`wt`, {
                    initialValue: [
                      this.state.newtWetStart,
                      this.state.newtWetEnd,
                    ],
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Slider
                      onChange={this.onChangeNetWT}
                      // defaultValue={[this.state.newtWetStart, this.state.newtWetEnd]}
                      min={0}
                      max={this.state.maxValNt}
                      range
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label={` Price ( From : ${this.state.priceStart} To : ${this.state.priceEnd} ) `}
                  {...formItemLayout}>
                  {getFieldDecorator(`price`, {
                    initialValue: [this.state.priceStart, this.state.priceEnd],
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Slider
                      onChange={this.onChangePrice}
                      min={0}
                      max={this.state.maxValPrice}
                      range
                    />
                  )}
                </Form.Item>
              </Col>

              <Col lg={6} md={6} sm={24} xs={24}>
                {/** 
        <Form.Item label={`Time(mins) From : ${this.state.timeMinStart} To : ${this.state.timeMinEnd}`}  {...formItemLayout} >
        {getFieldDecorator(`time`, 
        {
          initialValue:[this.state.timeMinStart, this.state.timeMinEnd],
          rules: [
            {
              required: false,
            },
          ],
        })(  <Slider  onChange={this.onChangeGrossWT}  min={this.state.minValTime}
          max={this.state.maxValTime} range /> )}
      </Form.Item>*/}
              </Col>

              <Col lg={5} md={5} sm={24} xs={24}>
                <div
                  style={{
                    marginTop: "31px",
                    paddingRight: "9px",
                    float: "right",
                  }}>
                  <Button
                    style={{ float: "left" }}
                    onClick={this.btnFiltter}
                    type='primary'>
                    Filter
                  </Button>
                  <Button
                    onClick={this.onClearFiltter}
                    className='transaction-clear-btn'
                    ghost>
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Form>

        <Row>
          <Col md={24} sm={24} xs={24}>
            {/**  <Icon type="cloud-download" style={{float:"right",fontSize:"27px",cursor:"pointer"}}  className="certain-category-icon" />*/}

            <Card
              bodyStyle={{ padding: "10px 10px", paddingBottom: "0px" }}
              style={{ marginBottom: "0px" }}
              bordered={false}>
              <Table
                className='gx-table-responsive'
                columns={columnsForTableView}
                // expandedRowRender={record => expandedRowRenderForSeller(record.sellerDetails)}
                dataSource={data}
                size={"small"}
                rowKey={"id"}
                loading={this.state.table_loading}
                onChange={this.handleTableChange}
                scroll={{ y: heightd }}
                sortDirections={sortDirections}
                //  scroll={{ x: 1650 }}
              />
            </Card>
          </Col>
          {/**    <Col md={6} sm={24} xs={24}> 
                  <Card  size="small"style={{padding: "20px 20px;"}} bordered={false}
                  title="Filter">
                  <p>By Sales Person</p> 
                    <Select
                   mode="multiple"
                  //  size={size}
                    placeholder="Please select sales person"
                  //  defaultValue={['Rajesh','Vishal']}
                  //  onChange={this.handleChangePerson}
                    style={{ width: '100%' }}
                  >
                  {this.state.allSalesPersons.map(user => (
                    <Option key={user._id.empId} value={user._id.empId}>{user._id.name}</Option>
                  ))}    
                  </Select>        
                  <p style={{marginTop:"14px",marginBottom:"7px"}}>By Customer</p> 
                  <Select
                 mode="multiple"
                //  size={size}
                  placeholder="Please select customer"
                 // defaultValue={['Mahesh','Niral']}
                   onChange={this.handleChangeCutomer}
                  style={{ width: '100%' }}
                >
                   {this.state.cName}       
                </Select>               
                <p style={{marginTop:"14px",marginBottom:"7px"}}>By CTG</p> 
                    <Select
                     mode="multiple"
                  //  size={size}
                       placeholder="Please select CTG"
                  //  defaultValue={['Mahesh','Niral']}
                     onChange={this.handleChangeCtg}
                       style={{ width: '100%' }}
                     >
                     {this.state.category}         
                      </Select>       
                      
                      <p style={{marginTop:"14px",marginBottom:"7px"}}>By Item</p> 
                      <Select
                         mode="multiple"
                      //  size={size}
                          placeholder="Please select Item"
                      //  defaultValue={['Mahesh','Niral']}
                      onChange={this.handleChangeItem}
                         style={{ width: '100%' }}
                       >
                         {this.state.itemList}
                        </Select>  
                        
                        <p  style={{marginTop:"14px",marginBottom:"7px"}}>By Net WT</p>                    
                        <Slider  onChange={this.onChangeNetWT}  min={this.state.minValNt}
                        max={this.state.maxValNt} range defaultValue={[this.state.newtWetStart, this.state.newtWetEnd]} />
                        <p>From : {this.state.newtWetStart} To : {this.state.newtWetEnd} </p>

                        <p>By Price</p> 
                        <Slider  onChange={this.onChangePrice}  min={0}
                        max={this.state.maxValPrice} range defaultValue={[this.state.priceStart, this.state.priceEnd]} />
                        <p>From : {this.state.priceStart} To : {this.state.priceEnd} </p>

                        <p>By Time(mins)</p> 
                        <Slider  onChange={this.onChangeGrossWT}  min={this.state.minValTime}
                        max={this.state.maxValTime} range defaultValue={[this.state.timeMinStart, this.state.timeMinEnd]} />
                        <p>From : {this.state.timeMinStart} To : {this.state.timeMinEnd} </p>

                      
                          <p>By Date</p> 
                          <RangePicker
                        // showTime={{ format: 'HH:mm' }}
                          format="DD-MM-YYYY"
                          placeholder={['From ', 'To']}
                          defaultValue={[moment(fromDate, "DD-MM-YYYY"),moment(new Date(), "DD-MM-YYYY")]}
                        //  size="small"
                          style={{width:"245px"}}
                          onChange={this.onChange}
                        // onOk={onOk}
                        />      
                        
                  </Card>
              </Col>*/}
        </Row>
      </div>
    );
  }
}

trayActivities = Form.create()(trayActivities);
function mapStateToProps(state) {
  const { Common } = state;
  const {
    try_activities: try_activities,
    customer_details: customer_details,
    all_sales_persons: all_sales_persons,
  } = Common;
  return { try_activities, customer_details, all_sales_persons };
}

const AppContainer = connect(mapStateToProps)(trayActivities);

export default AppContainer;

// sortaarray=temp1.sort(function(a,b){
//   return a.cName.localeCompare(b.cName);
// });

//temp1.sort((a, b) => (a.cName > b.cName) ? -1 : 1);
