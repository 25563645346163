//console.log(currancy);
function userAccess(screen, operationon) {
  let userAccess = JSON.parse(localStorage.getItem("userAccess"));
  let arrayOfScreen = userAccess[`${screen}`];
  let result = arrayOfScreen.includes(operationon);
  return `${result}`;
}
function currencyConverter(amt) {
  let currancy = localStorage.getItem("currancy");

  if (currancy == "$") {
    if (amt % 1 == 0) {
      amt = new Intl.NumberFormat("USD").format(amt);
    } else {
      amt = new Intl.NumberFormat("USD", {
        minimumFractionDigits: 3,
      }).format(amt);
    }
  } else if (currancy == "₹") {
    if (amt % 1 == 0) {
      amt = new Intl.NumberFormat("en-IN").format(amt);
    } else {
      amt = new Intl.NumberFormat("en-IN", {
        minimumFractionDigits: 3,
      }).format(amt);
    }
  } else if (currancy == "€") {
    if (amt % 1 == 0) {
      amt = new Intl.NumberFormat("de-DE").format(amt);
    } else {
      amt = new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 3,
      }).format(amt);
    }
  } else if (currancy == "£") {
    if (amt % 1 == 0) {
      amt = new Intl.NumberFormat("en-US").format(amt);
    } else {
      amt = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 3,
      }).format(amt);
    }
  }
  // console.log(parseInt(amt), "amt");
  return `${currancy} ${amt}`;
}

const sortDirections = ["descend", "ascend", "descend"];

export { currencyConverter, userAccess, sortDirections };
