import React, { Component } from "react";
import { connect } from "react-redux";
import {
  deleteAllStock,
  deleteAllStockWithRootInfo,
  FTPsyncInvetory,
  getAllBrachList,
  getAllFloorList,
  getAllSectionList,
  getAllSubSectionList,
  getAllUserList,
  getOtpForStockDelete,
  getSKUbyID,
  getSKUByRootInfoAndId,
  getSkuLabel,
  getSKUMedia,
  verifyOtp,
} from "../../../actions/index";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Icon,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import ReactExport from "react-data-export";
import Gears from "../../../assets/images/gears.gif";
import Uploa from "./../transferStock/index";
import Upload from "./../upload/index";
import ExportIcon from "./../../../assets/images/icons/export.svg";
import ResetPassWord from "./passwordResetFrom";
import CountUp from "react-countup";
import socket from "./../../../containers/App/global";
import moment from "moment";
import {
  currencyConverter,
  sortDirections,
} from "../../../containers/App/commanFunction";
import StockViewPage from "./stockviewInDetailsJT";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import ImportIcon from "./../../../assets/images/icons/import.svg";
import EditIcon from "../../../assets/images/icons/edit-icon.svg";
import SkuEdit from "./editSku";
import TagPrintDialog from "./tagPrintDailog";
// import "node_modules/react-image-gallery/styles/css/image-gallery.css";

const { RangePicker } = DatePicker;
var cookies = require("browser-cookies");
message.config({
  duration: 1,
  maxCount: 3,
});
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Option } = Select;
let usertype = localStorage.getItem("type_user");

class stockAllocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataObj: {},
      data: [],
      selectedRowKeys: [],
      selectAll: [],
      table_loading: true,
      Spinloading: false,
      pagination: {},
      total_stock: 0,
      totalPcs: 0,
      totalGrossWeight: 0.0,
      btndisabled: true,
      loading: true,
      dataforExport: [],
      selected_id: "",
      textForSelect: "",
      floor_id: "",
      branch_id: "",
      section_id: "",
      selection_const: true,
        visibleStockDelete: false,
        visiblestatus: false,
      display: false,
      serach: "",
      btnsearch: true,
      ctgArray: [],
        onlyCtgArray: [],
        branch_list: [],
        floor_list: [],
        section_list: [],
        sub_section_list: [],
      floorList: [],
      sectionList: [],
      subSectionList: [],
      userList: [],
      fromDate: "",
      toDate: "",
      d_cat: "",
        counter: "",
      syncInvetoryLoading: false,
      pageSizelimit: 10,
      currantPage: 0,
      uploadvisibleForSync: false,
      visibleForEditSku: false,
      record: {},
      otpFormVisible: false,
      isDeleteAllRequested: false,
      visibleForAddSku: true,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getSkuLabel());
    let usertype = localStorage.getItem("type_user");
    let root = JSON.parse(cookies.get("root"));

    if (usertype === "branchAdmin") {
      this.setState({
        rootInfo: "floor",
        rootInfoEqual: "branch",
        textForSelect: "Select Floor",
        textForSelect1: "Select Section",
        id: root.branchId,
        selected_id: root.branchId,
        selectedRootInfoEqual: "branch",
        rootLess: "section",
      });
      dispatch(getAllFloorList("bybranchId", root.branchId));
      dispatch(getAllUserList("branch", root.branchId));
    }
    if (usertype === "companyAdmin") {
      this.setState({
        rootInfo: "branch",
        rootInfoEqual: "company",
        textForSelect: "Select Branch",
        textForSelect1: "Select Floor",
        id: root.companyId,
        selected_id: root.companyId,
        rootLess: "floor",
        selectedRootInfoEqual: "company",
      });
        dispatch(getAllBrachList("id"));
      dispatch(getAllUserList("company", root.companyId));
    }
    if (usertype === "floorAdmin") {
      this.setState({
        rootInfo: "section",
        rootInfoEqual: "floor",
        rootLess: "subSection",
        textForSelect: "Select Section",
        textForSelect1: "Select SubSection",
        id: root.floorId,
        selected_id: root.floorId,
        selectedRootInfoEqual: "floor",
      });
      // console.log('getAllSectionList.....................................................')
      // dispatch(getAllSectionList("id"));
      dispatch(getAllUserList("floor", root.floorId));
    }

      socket.on("Notification", (col) => {
          console.log(col);
          try {
              if (
                  col.data_code === "101" ||
                  col.data_code === "102" ||
                  col.data_code === "103" ||
                  col.data_code === "104" ||
                  col.data_code === "105" ||
                  col.data_code === "106"
              ) {
                  const { dispatch } = this.props;
                  // dispatch(getSKUByRootInfoAndId(this.state.selected_id,this.state.rootInfo,0,10));
                  if (this.state.selected_id == this.state.id) {
                      console.log("log company");
                      dispatch(
                          getSKUByRootInfoAndId(
                              this.state.selected_id,
                              this.state.rootInfoEqual,
                              0,
                              10,
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              ""
                          )
                      );
                  } else {
                      dispatch(
                          getSKUByRootInfoAndId(
                              this.state.selected_id,
                              this.state.rootInfo,
                              0,
                              10,
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              ""
                          )
                      );
                  }
              } else if (col.data_code === "106") {
                  // let newData=col.data_array;
                  // let Data=this.state.data;
              }
          } catch (e) {
              console.log("error", e);
          }
      });
      let newFields = JSON.parse(localStorage.getItem("DetailView"));
      if (newFields !== null && newFields !== undefined) {
          newFields.filter((field) => {
              if (field.name === "rfid_number") {
                  field.isSelected = true;
              }
          });
      }
  }
  componentDidUpdate(prevProps, prevState) {
    try {
      // if (prevProps.get_stock != this.props.get_stock) {
      //   if(this.props.get_stock.status)
      //   {
      //       this.setState({
      //         data:this.props.get_stock.data_array,
      //       })
      //   }else{
      //     this.setState({
      //       data:[]
      //     })
      //     message.error(this.props.get_stock.errors.msg);
      //   }
      //   this.setState({
      //     table_loading:false
      //   })
      //   }

        if (prevProps.branch_list !== this.props.branch_list) {
        if (this.props.branch_list.status) {
          if (this.state.selection_const) {
            this.setState({
              branch_id: this.props.branch_list.data_array[0]._id,
              branch_list: this.props.branch_list.data_array,

              //selected_id:this.props.branch_list.data_array[0]._id,
            });
            if (usertype === "companyAdmin") {
              this.setState({
                selection_const: false,
              });
            }
            if (this.props.branch_list.data_array.length >= 1) {
              const { dispatch } = this.props;
              //dispatch(getSKUByRootInfoAndId(this.props.branch_list.data_array[0]._id,this.state.rootInfo,0,10));
              //  dispatch(getSKUByRootInfoAndId(this.state.id, this.state.rootInfoEqual,0,10));
              // console.log('dispatch(getSKUByRootInfoAndId...............................');
              dispatch(
                getSKUByRootInfoAndId(
                  this.state.id,
                  this.state.rootInfoEqual,
                  0,
                  10,
                  "",
                  "",
                  "",
                  "",
                  "",
                  ""
                )
              );
            }
          }
        } else {
          this.setState({
            branch_list: [],
          });
        }
      }

        if (prevProps.floor_list !== this.props.floor_list) {
        // console.log('prevProps.floor_list', prevProps.floor_list, 'this.props.floor_list', this.props.floor_list);
        if (this.props.floor_list.status) {
          if (this.state.selection_const) {
            this.setState({
              floor_id: this.props.floor_list.data_array[0]._id,
              floor_list: this.props.floor_list.data_array,
              //selected_id:this.props.floor_list.data_array[0]._id,
            });
              console.log("surat:", this.props.floor_list.data_array);
            if (usertype === "branchAdmin") {
              this.setState({
                selection_const: false,
              });
            }
            if (this.props.floor_list.data_array.length >= 1) {
              const { dispatch } = this.props;
              // dispatch(getSKUByRootInfoAndId(this.props.floor_list.data_array[0]._id,this.state.rootInfo,0,10));
              //dispatch(getSKUByRootInfoAndId(this.state.id, this.state.rootInfoEqual,0,10));
              // console.log('dispatch(getSKUByRootInfoAndId...............................');
              dispatch(
                getSKUByRootInfoAndId(
                  this.state.id,
                  this.state.rootInfoEqual,
                  0,
                  10,
                  "",
                  "",
                  "",
                  "",
                  "",
                  ""
                )
              );
            }
          }
          this.setState({
              floorList: this.props.floor_list.data_array,
              floor_list: this.props.floor_list.data_array,
          });
            console.log("floor:", this.props.floor_list.data_array);
        } else {
          this.setState({
            floor_list: [],
            floorList: [],
            ctgArray: "",
            onlyCtgArray: [],
          });
        }
      }

      if (prevProps.section_list != this.props.section_list) {
        if (this.props.section_list.status) {
          if (this.state.selection_const) {
            this.setState({
              section_id: this.props.section_list.data_array[0]._id,
              section_list: this.props.section_list.data_array,
              //selected_id:this.props.section_list.data_array[0]._id,
            });
              console.log("sect:", this.props.section_list.data_array);
            if (usertype === "floorAdmin") {
              this.setState({
                selection_const: false,
              });
            }
            if (this.props.section_list.data_array.length >= 1) {
              const { dispatch } = this.props;
              //  dispatch(getSKUByRootInfoAndId(this.props.section_list.data_array[0]._id,this.state.rootInfo,0,10));
              //dispatch(getSKUByRootInfoAndId(this.state.id, this.state.rootInfoEqual,0,10));
              dispatch(
                getSKUByRootInfoAndId(
                  this.state.id,
                  this.state.rootInfoEqual,
                  0,
                  10,
                  "",
                  "",
                  "",
                  "",
                  "",
                  ""
                )
              );
            }
          }
          this.setState({
              sectionList: this.props.section_list.data_array,
              section_list: this.props.section_list.data_array,
          });
        } else {
          this.setState({
            section_list: [],
            sectionList: [],
          });
        }
      }

      if (prevProps.sub_section_list != this.props.sub_section_list) {
        /*console.log("this.props.sub_section_list");
                console.log(this.props.sub_section_list);*/
        if (this.props.sub_section_list.status) {
          // console.log(this.props.sub_section_list.data_array);
          this.setState({
              subSectionList: this.props.sub_section_list.data_array,
              sub_section_list: this.props.sub_section_list.data_array,
          });
            console.log("sub:", this.props.sub_section_list.data_array);
        } else {
          this.setState({
              subSectionList: [],
              sub_section_list: [],
          });
        }
      }

      if (prevProps.user_list != this.props.user_list) {
        if (this.props.user_list.status) {
          this.setState({
            userList: this.props.user_list.data_array,
          });
        } else {
          this.setState({
            userList: [],
          });
        }
      }
      if (prevProps.get_stock != this.props.get_stock) {
        // console.log("yess003");
        if (this.props.get_stock.data_array.length > 0) {
          if (this.props.get_stock.status) {
            let stockData = this.props.get_stock.data;
            let stock = this.props.get_stock.data_array;
            let totalSkuCount = this.props.get_stock.data.totalSku;
            let totalPcs = this.props.get_stock.data.totalPcs;
            let totalGrossWeight = this.props.get_stock.data.totalGrossWeight;
            //  const filteredArr = stock.reduce((acc, current) => {
            //   const x = acc.find(item => item.sku_number === current.sku_number);
            //   if (!x) {
            //     return acc.concat([current]);
            //   } else {
            //     return acc;
            //   }
            // },[]);
            let selectedRowKeys = [];
            let pagination = {};
            // if(totalSkuCount != filteredArr.length){
            //   this.setState({

            //   })
            // }
            pagination.total = totalSkuCount;
            pagination.showQuickJumper = true;
            pagination.showSizeChanger = true;
            pagination.pageSize = this.state.pageSizelimit;
            pagination.pageSizeOptions = [
              "25",
              "50",
              "75",
              "100",
              "500",
              "1000",
              "2000",
            ];
            this.setState({
              ctgArray: this.props.get_stock.data.d_cat,
              onlyCtgArray: this.props.get_stock.data.counters || [],
              data: stock,
              dataObj: stockData,
              selectAll: selectedRowKeys,
              total_stock: totalSkuCount,
              totalPcs: totalPcs,
              totalGrossWeight: totalGrossWeight,
              pagination,
            });
          } else {
            this.setState({
              data: [],
              dataObj: {},
              total_stock: 0,
              totalPcs: 0,
              ctgArray: [],
              onlyCtgArray: [],
            });
          }
        } else {
          this.setState({
            data: [],
            dataObj: {},
            total_stock: 0,
            totalPcs: 0,
            // ctgArray: []
          });
        }
        this.setState({
          table_loading: false,
        });
      }

      if (prevProps.get_stock != this.props.get_stock) {
        if (this.props.get_stock.status) {
          this.setState({
            dataforExport: this.props.get_stock.data_array,
            loading: false,
          });
            console.log("export:", this.props.get_stock.data_array);
        }
      }

      if (prevProps.ftp_sync != this.props.ftp_sync) {
        /* console.log("this.props.ftp_syn")
                 console.log(this.props.ftp_sync)*/
        if (this.props.ftp_sync.status) {
          this.setState({ uploadvisibleForSync: false });
        } else {
          message.error(this.props.ftp_sync.errors.msg);
        }
        this.setState({ syncInvetoryLoading: false });
      }

      if (prevProps.delete_all_stock != this.props.delete_all_stock) {
        // console.log(this.props.delete_multiple_stock);
        if (this.props.delete_all_stock.status) {
          message.success("Delete inventory sucessfully");
          const { dispatch } = this.props;
          // dispatch(getSKUByRootInfoAndId(this.state.selected_id,this.state.rootInfo,0,10,"","","","","",""));
          this.setState({
            Spinloading: false,
            selectedRowKeys: [],
          });
          //this.btnFiltter();
        } else {
          try {
            message.error(this.props.delete_all_stock.errors.msg);
          } catch (error) {
            console.log(error);
          }
        }
      }

      if (
        prevProps.delete_all_stock_root_info !=
        this.props.delete_all_stock_root_info
      ) {
        // console.log(this.props.delete_all_stock_root_info);
        try {
          if (this.props.delete_all_stock_root_info.status) {
            message.success("Delete inventory sucessfully");
            // console.log("Call multiple delete stock");
            const { dispatch } = this.props;
            dispatch(
              getSKUByRootInfoAndId(
                this.state.selected_id,
                this.state.rootInfo,
                0,
                10,
                "",
                "",
                "",
                "",
                "",
                ""
              )
            );
            this.setState({
              Spinloading: false,
              selectedRowKeys: [],
              visibleStockDelete: false,
            });
          } else {
            message.error(this.props.delete_all_stock_root_info.errors.msg);
          }
        } catch (error) {
          console.log(error);
        }
      }

      if (
        prevProps.otp_stock_delete != this.props.otp_stock_delete &&
        !this.state.isDeleteAllRequested
      ) {
        this.setState({
          Spinloading: false,
        });
        if (this.props.otp_stock_delete.status) {
          message.success(this.props.otp_stock_delete.data.msg);
          this.setState({
            otpFormVisible: true,
          });
        } else {
          message.error(this.props.otp_stock_delete.data.msg);
        }
      }

      if (
        prevProps.verify_otp_stock_delete != this.props.verify_otp_stock_delete
      ) {
        try {
          this.setState({
            Spinloading: false,
          });
          if (this.props.verify_otp_stock_delete.status) {
            // deleteAllStockWithRootInfo(this.props.selected_id, this.props.rootInfo)
            // console.log("this.state.isDeleteAllRequested", this.state.isDeleteAllRequested);
            if (this.state.isDeleteAllRequested) {
              // console.log("this.state.isDeleteAllRequested", true);
              const { dispatch } = this.props;
              dispatch(
                deleteAllStockWithRootInfo(
                  this.state.selected_id,
                  this.state.rootInfo
                )
              );
            } else this.deleteStock_();
            this.setState({
              otpFormVisible: false,
            });
          } else message.error(this.props.otp_stock_delete.errors.msg);
        } catch (e) {
          console.log(e);
        }
      }
    } catch (error) {
      console.log(error);
    }
    }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
    if (selectedRowKeys.length > 0) {
      this.setState({ btndisabled: false });
    } else {
      this.setState({ btndisabled: true });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    // console.log(sorter);

    this.props.form.validateFields(
      ["rootid", "secondid", "date", "d_cat", "counter"],
      (err, values) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;

        this.setState({
          pagination: pager,
          selectedRowKeys: [],
          table_loading: true,
          d_cat: values.d_cat,
          counter: values.counter,
          pageSizelimit: pagination.pageSize,
          currantPage: pager.current - 1,
        });
        let fromDate = "";
        let toDate = "";
        let d_cat = "";
        let counter = "";
        let root = this.state.rootInfo;
        let id = (id = values.rootid);
        if (values.rootid === undefined) {
          root = "";
          id = "";
        }
        if (values.secondid != undefined) {
          id = values.secondid;
          root = this.state.rootLess;
        }

        if (values.d_cat != undefined) {
          d_cat = values.d_cat;
        }
        if (values.counter != undefined) {
          counter = values.counter;
        }

        if (values.date != undefined) {
          if (values.date.length > 0) {
            //  fromDate=values.date[0].toISOString();
            //toDate=values.date[1].toISOString();
            let fromDate1 = moment(values.date[0])
              .utc()
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              });
            let toDate1 = moment(values.date[1])
              .utc()
              .set({
                hour: 23,
                minute: 59,
                second: 0,
                millisecond: 0,
              });
            fromDate = fromDate1.toISOString();
            toDate = toDate1.toISOString();
          }
        }
        const { dispatch } = this.props;
        //  if(this.state.selected_id===this.state.id){

        //dispatch(getSKUByRootInfoAndId(values.rootid,this.state.rootInfoEqual,0,10,this.state.search,fromDate,toDate));
        dispatch(
          getSKUByRootInfoAndId(
            this.state.selected_id,
            this.state.selectedRootInfoEqual,
            pager.current - 1,
            pagination.pageSize,
            this.state.search,
            fromDate,
            toDate,
            sorter.columnKey,
            sorter.order,
            d_cat,
            counter
          )
        );
        // }else{

        //   dispatch(getSKUByRootInfoAndId(id,root,pager.current-1,pagination.pageSize,this.state.search,fromDate,toDate,sorter.columnKey,sorter.order,d_cat,counter));
        // }
      }
    );
  };

  deleteUserGroupStock = (e) => {};
  //stock export
  showModal = () => {
    //alert("ok");
    const { dispatch } = this.props;
    dispatch(
      getSKUByRootInfoAndId(
        this.state.selected_id,
        this.state.selectedRootInfoEqual,
        0,
        this.state.total_stock,
        "",
        this.state.fromDate,
        this.state.toDate,
        "",
        "",
        this.state.d_cat,
        this.state.counter
      )
      );
      this.setState({
          visible: true,
      });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
        loading: true,
    });
  };

    handleCance = () => {
        this.setState({
            visiblestatus: false,
            loading: true,
        });
    };


  updateSku = () => {};
  //end comment
  deleteStock = (e) => {
    let data = this.state.data;
    let skus = [];
    for (let index = 0; index < data.length; index++) {
      if (this.state.selectedRowKeys.includes(data[index]._id)) {
        skus.push(data[index].sku_number);
      }
    }
    const { dispatch } = this.props;
    // console.log(skus);
    dispatch(deleteAllStock({ sku_number: skus }));
  };

  deleteStock_ = () => {
    let data = this.state.data;
    let skus = [];
    for (let index = 0; index < data.length; index++) {
      if (this.state.selectedRowKeys.includes(data[index]._id)) {
        skus.push(data[index].sku_number);
      }
    }
    const { dispatch } = this.props;
    // console.log(skus);
    dispatch(deleteAllStock({ sku_number: skus }));
  };

  verifyOtp = (e) => {
    e.preventDefault();
    this.props.form.validateFields(["otp"], (err, values) => {
      // this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.setState({
        Spinloading: true,
      });
      const { dispatch } = this.props;
      dispatch(verifyOtp(values));

      /*const {receivedOtp} = this.state;
            if (values.otp === receivedOtp) {
                this.setState({
                    Spinloading: true,
                    otpFormVisible: false,
                });
                this.deleteStock_();
            } else {
                message.error("Otp is not verified");
            }*/
    });
  };

  handleDeleteAllRequested = (isRequested) => {
    this.setState({
      isDeleteAllRequested: isRequested,
    });
  };

  showOtpModal = (e) => {
    this.setState({
      Spinloading: true,
      isDeleteAllRequested: false,
    });
    const { dispatch } = this.props;
    dispatch(getOtpForStockDelete());

    this.props.form.resetFields();
  };

  hideModalOtpForm = (e) => {
    this.setState({
      otpFormVisible: false,
    });
    this.props.form.resetFields();
  };

  onSerach = (e) => {
    //this.setState({search: e.target.value})
    this.setState({
      data: [],
      dataObj: {},
    });
    try {
      if (this.state.search.length > 1) {
        this.props.form.validateFields(
          ["rootid", "secondid", "date", "d_cat", "counter"],
          (err, values) => {
            if (err) {
              return;
            }

            const { dispatch } = this.props;
            let fromDate = "";
            let toDate = "";
            let d_cat = "";
            let counter = "";
            let root = this.state.rootInfo;
            let id = (id = values.rootid);
            if (values.rootid === undefined) {
              root = "";
              id = "";
            }
            if (values.secondid != undefined) {
              id = values.secondid;
              root = this.state.rootLess;
            }

            if (values.date != undefined) {
              if (values.date.length > 0) {
                //fromDate=values.date[0].toISOString();
                //toDate=values.date[1].toISOString();
                let fromDate1 = moment(values.date[0])
                  .utc()
                  .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  });
                let toDate1 = moment(values.date[1])
                  .utc()
                  .set({
                    hour: 23,
                    minute: 59,
                    second: 0,
                    millisecond: 0,
                  });
                fromDate = fromDate1.toISOString();
                toDate = toDate1.toISOString();
              }
            }
            if (values.d_cat != undefined) {
              d_cat = values.d_cat;
            }
            if (values.counter != undefined) {
              counter = values.counter;
            }

            this.setState({
              table_loading: true,
            });

            // if (this.state.selected_id === this.state.id) {
            dispatch(
              getSKUByRootInfoAndId(
                this.state.selected_id,
                this.state.selectedRootInfoEqual,
                0,
                this.state.pageSizelimit,
                this.state.search,
                fromDate,
                toDate,
                "",
                "",
                ""
              )
            );
            //} else {
            // dispatch(getSKUByRootInfoAndId(id,root,0,this.state.pageSizelimit,this.state.search,fromDate,toDate,"","",""));
            //}
          }
        );
        // data = data.filter(row => {
        //   return row.sku_number.toUpperCase().includes(this.state.search.toUpperCase()) ||  String(row.design_code.toUpperCase()).includes(this.state.search.toUpperCase()) || row.metal_type.toUpperCase().includes(this.state.search.toUpperCase()) || row.design_color.toUpperCase().includes(this.state.search.toUpperCase()) //|| row.net_weight.includes(this.state.search)
        //   //|| String(row.net_weight.toUpperCase()).includes(this.state.search.toUpperCase()) || row.sales_category.toUpperCase().includes(this.state.search.toUpperCase())
        //   //row.name.toUpperCase().includes(this.state.search.toUpperCase())
        // })
      }
    } catch (error) {}
  };
  onChangeBranchRadio = (e) => {
    // this.setState({
    // table_loading:true,
    // selected_id:e
    //})

    const { dispatch } = this.props;

    if (this.state.rootInfoEqual === "company") {
      this.setState({
        currantPage: 0,
      });
      dispatch(getAllFloorList("bybranchId", e));
      // dispatch(
      //   getSKUByRootInfoAndId(e, "branch", 0, 10, "", "", "", "", "", "")
      // );
    } else if (this.state.rootInfoEqual === "branch") {
      dispatch(getAllSectionList("byFloorId", e));
    } else if (this.state.rootInfoEqual === "floor") {
      dispatch(getAllSubSectionList("bySectionId", e));
    }
    this.props.form.resetFields("secondid");
    };

    onChangeBranch = (e) => {
        this.setState({
            branch_id: e,
        })
        const { dispatch } = this.props;
        this.setState({
            floor_id: '',
            section_id: '',
            sub_section_id: '',
        })
        dispatch(getAllFloorList("bybranchId", e));
    }


    onChangeFloor = (e) => {
        this.setState({
            floor_id: e,
        })
        const { dispatch } = this.props;
        dispatch(getAllSectionList("byFloorId", e));
    }

    onChangeSection = (e) => {
        this.setState({
            section_id: e,
        })
        const { dispatch } = this.props;
        dispatch(getAllSubSectionList("bysectionId", e));
    }

    onChangeSubSection = (e) => {
        this.setState({
            sub_section_id: e,
        })
    };


  filterClear = (e) => {
    //alert("clrea");
    this.setState({
      selected_id: this.state.id,
      search: "",
      //floorList: [],
      //sectionList: [],
      //subSectionList: [],
      fromDate: "",
      toDate: "",
      pageSizelimit: 10,
      currantPage: 0,
      selectedRootInfoEqual: this.state.rootInfoEqual,
      d_cat: "",
        counter: "",
      visible: false
    });

    const pagination = this.state.pagination;
    pagination.current = 0;

    this.setState({
      pagination: pagination,
      selectedRowKeys: [],
    });

    const { dispatch } = this.props;
    dispatch(
      getSKUByRootInfoAndId(
        this.state.id,
        this.state.rootInfoEqual,
        0,
        10,
        "",
        "",
        "",
        "",
        "",
        ""
      )
    );
    this.props.form.resetFields();
    };

    showFilter = () => {
        this.setState({
            visibleFilter: true,
        });
    };

    handleCloseModal = () => {
        this.props.form.resetFields();
        this.setState({
            visibleFilter: false,
        });
    };

    handleClear = () => {
        this.props.form.resetFields();
        this.setState({
            //branch_id: '',
            floor_id: '',
            section_id: '',
            sub_section_id: '',
        });
    };

  //btnFiltter = () => {
  //  this.props.form.validateFields(
  //    ["rootid", "secondid", "date", "d_cat", "counter"],
  //    (err, values) => {
  //      if (err) {
  //        console.log("btn err", err);
  //        return;
  //      }

  //      const { dispatch } = this.props;
  //      let fromDate = "";
  //      let toDate = "";
  //      let d_cat = "";
  //      let counter = "";
  //      let root = this.state.rootInfoEqual;
  //      let id = this.state.id;
  //      const pager = { ...this.state.pagination };
  //      pager.current = 1;
  //      pager.pageSize = 10;

  //      this.setState({
  //        pagination: pager,
  //        table_loading: true,
  //        pageSizelimit: 10,
  //        currantPage: pager.current - 1,
  //      });

  //      if (values.rootid != this.state.id) {
  //        root = this.state.rootInfo;
  //        id = values.rootid;
  //      }
  //      if (values.secondid != undefined) {
  //        id = values.secondid;
  //        root = this.state.rootLess;
  //      }

        //if (values.date != undefined) {
        //  if (values.date.length > 0) {
        //    // fromDate=values.date[0].toISOString();
        //    // toDate=values.date[1].toISOString();
        //    let fromDate1 = moment(values.date[0])
        //      .utc()
        //      .set({
        //        hour: 0,
        //        minute: 0,
        //        second: 0,
        //        millisecond: 0,
        //      });
        //    let toDate1 = moment(values.date[1])
        //      .utc()
        //      .set({
        //        hour: 23,
        //        minute: 59,
        //        second: 0,
        //        millisecond: 0,
        //      });
        //    fromDate = fromDate1.toISOString();
        //    toDate = toDate1.toISOString();
        //  }
        //}
  //      if (values.d_cat != undefined) {
  //        d_cat = values.d_cat;
  //      }
  //      if (values.counter != undefined) {
  //        counter = values.counter;
  //      }

  //      this.setState({
  //        table_loading: true,
  //        fromDate,
  //        toDate,
  //        d_cat,
  //        counter,
  //      });
       
  //      this.setState({
  //        selectedRootInfoEqual: root,
  //          selected_id: id,
  //          visibleFilter: false
  //      });
  //      dispatch(
  //        getSKUByRootInfoAndId(
  //          id,
  //          root,
  //          this.state.currantPage,
  //          this.state.pageSizelimit,
  //          this.state.search,
  //          fromDate,
  //          toDate,
  //          "",
  //          "",
  //          d_cat,
  //          counter
  //        )
  //      );
  //    }
  //  );
  //  };


    btnFiltter = () => {
        this.props.form.validateFields((err, values) => {
            console.log("btn Fillter", values);
            const { dispatch } = this.props;
            let ata_status = values.ata_status;
            if (ata_status === undefined) {
                ata_status = "";
            }

            let fromDate = "";
            let toDate = "";
            let d_cat = "";
            let counter = "";
            let root = this.state.rootInfoEqual;
            let id = values.rootid !== undefined ? values.rootid : this.state.id;

            if (values.rootid !== undefined) {
                id = values.rootid;
                root = this.state.rootInfo;
            }
            if (values.secondid !== undefined) {
                id = values.secondid;
                root = this.state.rootLess;
            }

            if (values.date != undefined) {
                if (values.date.length > 0) {
                    let fromDate1 = moment(values.date[0])
                        .utc()
                        .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                        });
                    let toDate1 = moment(values.date[1])
                        .utc()
                        .set({
                            hour: 23,
                            minute: 59,
                            second: 0,
                            millisecond: 0,
                        });
                    fromDate = fromDate1.toISOString();
                    toDate = toDate1.toISOString();
                }
            }

            if (values.d_cat != undefined) {
                d_cat = values.d_cat;
            }
            if (values.counter != undefined) {
                counter = values.counter;
            }

            console.log("d_cat");
            console.log("counter");
            console.log(root, id);
            this.setState({
                table_loading: true,
                fromDate,
                toDate,
                d_cat,
                counter
            });

            if (values.rootid == this.state.id) {
                this.setState({
                    selectedRootInfoEqual: this.state.rootInfoEqual,
                    selected_id: values.rootid,
                });
                dispatch(
                    getSKUByRootInfoAndId(
                        values.rootid,
                        this.state.rootInfoEqual,
                        0,
                        10,
                        this.state.search,
                        fromDate,
                        toDate,
                        "",
                        "",
                        d_cat,
                        counter
                    )
                );
            } else {
                this.setState({
                    selectedRootInfoEqual: root,
                    selected_id: id,
                });
                dispatch(
                    getSKUByRootInfoAndId(
                        id,
                        root,
                        0,
                        10,
                        this.state.search,
                        fromDate,
                        toDate,
                        "",
                        "",
                        d_cat,
                        counter
                    )
                );
            }
            this.setState({ modalVisible: false });
        });
    };


  setLocalText = (e) => {
    const { dispatch } = this.props;
    this.setState({ display: true });
    localStorage.setItem("skuid", e);
    dispatch(getSKUbyID(e));
    dispatch(getSKUMedia(e));
  };

  closeStockView = (e) => {
    this.setState({ display: false });
  };

  syncInvetory = (e) => {
    this.setState({
      // syncInvetoryLoading:true,
      uploadvisibleForSync: true,
    });

    // const { dispatch } = this.props;
    // dispatch(FTPsyncInvetory());
  };

  uploadhandleSyncOk = (e) => {
    this.props.form.validateFields(["empName"], (err, values) => {
      if (err) {
        return;
      }
      const { dispatch } = this.props;
      this.setState({
        syncInvetoryLoading: true,
        // uploadvisibleForSync:false
      });
      dispatch(FTPsyncInvetory({ empName: values.empName }));
    });
  };
  uploadhandleCancel = (e) => {
    this.setState({
      syncInvetoryLoading: false,
      uploadvisibleForSync: false,
    });
  };

  searchOnchange = (e) => {
    // console.log(e.target.value.length);
    this.setState({ search: e.target.value, btnsearch: false });

    if (e.target.value.length == 0) {
      this.setState({
        table_loading: true,
        btnsearch: true,
      });
      this.props.form.validateFields(
        ["rootid", "secondid", "date", "d_cat", "counter"],
        (err, values) => {
          if (err) {
            return;
          }
          const { dispatch } = this.props;
          let fromDate = "";
          let toDate = "";
          let d_cat = "";
          let counter = "";
          if (values.date != undefined) {
            if (values.date.length > 0) {
              // fromDate=values.date[0].toISOString();
              // toDate=values.date[1].toISOString();
              let fromDate1 = moment(values.date[0])
                .utc()
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                });
              let toDate1 = moment(values.date[1])
                .utc()
                .set({
                  hour: 23,
                  minute: 59,
                  second: 0,
                  millisecond: 0,
                });
              fromDate = fromDate1.toISOString();
              toDate = toDate1.toISOString();
            }
          }
          if (values.d_cat != undefined) {
            d_cat = values.d_cat;
          }
          if (values.counter != undefined) {
            counter = values.counter;
          }

          if (values.rootid == this.state.id) {
            dispatch(
              getSKUByRootInfoAndId(
                values.rootid,
                this.state.rootInfoEqual,
                this.state.currantPage,
                this.state.pageSizelimit,
                "",
                fromDate,
                toDate,
                "",
                "",
                "",
                d_cat,
                counter
              )
            );
          } else {
            dispatch(
              getSKUByRootInfoAndId(
                values.rootid,
                this.state.rootInfo,
                this.state.currantPage,
                this.state.pageSizelimit,
                "",
                fromDate,
                toDate,
                "",
                "",
                "",
                d_cat,
                counter
              )
            );
          }
        }
      );
    }
  };
  editSku = (record) => {
    /*console.log("record============");
        console.log(record);*/
    this.setState({
      visibleForEditSku: true,
      record: record,
    });
  };

    updateStatus = (record) => {
        this.setState({
            visiblestatus: true,
            record: record,
        });
    };

  closeEditSku = (record) => {
    this.setState({
      visibleForEditSku: false,
    });
  };

  closeAddSku = (record) => {
    this.setState({
      visibleForAddSku: false,
    });
  };
  editSkuClose = (record) => {
    if (record === true) {
      const { dispatch } = this.props;
      dispatch(
        getSKUByRootInfoAndId(
          this.state.selected_id,
          this.state.rootInfo,
          0,
          10,
          "",
          "",
          "",
          "",
          "",
          ""
        )
      );
    }
    this.setState({
      visibleForEditSku: false,
    });
  };
  handleOk = (e) => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log(values);
      if (values.password === "spacecode") {
        this.setState({
          Spinloading: true,
        });
        const { dispatch } = this.props;
        //  dispatch(deleteAllStockWithRootInfo(this.state.selected_id,this.state.rootInfo));
      } else {
        message.error("Password is wrong");
      }
    });
  };

  render() {
    // var innerHeightL=window.innerHeight;
    // if(window.innerHeight < 900 ){
    //   let diff=900-window.innerHeight;
    //   console.log("diff");
    //   console.log(diff);
    // }
    var heightd = window.innerHeight - 490;
    const { selectedRowKeys, loading, search } = this.state;
    const { getFieldDecorator } = this.props.form;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      // selections: [{
      //   key: 'all-data',
      //   text: 'Select All Data',
      //   onSelect: () => {
      //     this.setState({
      //       selectedRowKeys: this.state.selectAll, // 0...45
      //     });
      //   },
      // },
      //  {
      //   key: 'odd',
      //   text: 'Select Odd Row',
      //   onSelect: (changableRowKeys) => {
      //     let newSelectedRowKeys = [];
      //     newSelectedRowKeys = changableRowKeys.filter((key, index) => {
      //       if (index % 2 !== 0) {
      //         return false;
      //       }
      //       return true;S
      //     });
      //     this.setState({selectedRowKeys: newSelectedRowKeys});
      //   },
      // },
      //  {
      //   key: 'all-data',
      //   text: 'UnSeelect All Data',
      //   onSelect: (changableRowKeys) => {
      //     this.setState({
      //       selectedRowKeys: [], // 0...45
      //     });
      //   },
      // }
      //],
      onSelection: this.onSelection,
    };
    // console.log("his.state.pagination", this.state.pagination);
    let data = this.state.data;
      const formItemLayout = {
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
      //labelCol: { span: 24 },
      //   labalAlign:{marginLeft: "15px"},
      //wrapperCol: { span: 24 },
      //wrapperCol: {
      //  xs: { span: 24 },
      //  sm: { span: 24 },
      //},
      //labelCol: {
      //  xs: { span: 24 },
      //  sm: { span: 24 },
      //},
    };
    this.columns = [
      {
        title: "SKU",
        dataIndex: "sku_number",
        width: "10%",
        sorter: (a, b) => {},
        render: (text, record) => {
          return (
            <a
              onClick={() => this.setLocalText(text)}
              to={`/StocksViewById/${text}`}>
              {text}
            </a>
          );
          // localStorage.setItem('sku_id',text);
          // return (
          //   <span>
          //       <Link to={`/StocksViewById/${text}`}>{text}</Link>

          //        {/**<SingleItemView itemId={text} test={this.test}/>*/}
          //   </span>
          // );
        },
      },
      {
        title: "DSG CODE",
        dataIndex: "design_code",
        width: "10%",
        sorter: (a, b) => {},
      },

      {
        title: "MATERIAL",
        dataIndex: "metal_type",
        width: "10%",
        sorter: (a, b) => {},
      },

      {
        title: "DESIGN CTG",
        dataIndex: "design_category",
        width: "10%",
        sorter: (a, b) => {},
      },
      {
        title: "DIA CT",
        dataIndex: "diamond_weight",
        width: "10%",
        sorter: (a, b) => {},
        render: (text, record) => {
          return (
            <span>
              {text != null
                ? !Number.isInteger(text)
                  ? text.toFixed(3)
                  : text
                : ""}
            </span>
          );
        },
      },
      {
        title: "NET WGT",
        dataIndex: "net_weight",
        width: "10%",
        sorter: (a, b) => {},
        render: (text, record) => {
          return (
            <span>
              {text != null
                ? !Number.isInteger(text)
                  ? text.toFixed(3)
                  : text
                : ""}
            </span>
          );
        },
      },
      {
        title: "PRICE",
        dataIndex: "sales_value",
        width: "10%",
        sorter: (a, b) => {},
        render: (text, record) => {
          return <span>{currencyConverter(text)}</span>;
        },
      },
      {
        title: "SKU QTY",
        dataIndex: "sku_quantity",
        width: "10%",
        sorter: (a, b) => {},
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "DATE",
        dataIndex: "createdAt",
        width: "10%",
        sorter: (a, b) => {},
        render: (text, record) => {
          return <span>{moment(text).format("DD-MM-YYYY")}</span>;
        },
      },

        {
            title: "ACTION",
            dataIndex: "_id",
            width: "10%",
            render: (text, record) => (
                <>
                    <TagPrintDialog record={record} />
                    <Icon type="edit"
                        style={{ marginLeft: 8, marginRight: 8, cursor: "pointer" }}
                        onClick={() => this.editSku(record)}
                    />
                    <Icon type="info-circle"
                        style={{ marginLeft: 4, cursor: "pointer" }}
                        onClick={() => this.updateStatus(record)}
                    />
                </>
            ),
        },

      // {
      //   title: 'Purity',
      //   dataIndex: 'purity',
      // },
      // {
      //   title:'Location',
      //   dataIndex:'Location',
      // }
    ];
    const companyType = localStorage.getItem("companyType");
    // console.log("Data --- " + this.state.dataObj);
    // console.log("Data gross weight --- " + this.state.dataObj.totalGrossWeight);
    return (
      <div>
        <div style={{ display: this.state.display ? "none" : "" }}>
          <Card className='custome-top-menu-card' bordered={false}>
            <Row>
              <Col
                lg={3}
                md={3}
                sm={24}
                xs={24}
                style={{
                  paddingLeft: "35px",
                }}>
                {/** <h1 className="stock-title">Total Inventory</h1>*/}
                <Row>
                  <Col
                    style={{
                      paddingLeft: "10px",
                    }}>
                    <p className='transaction-title'>Total</p>
                    <h1 className='transaction-sub-title'>Inventory</h1>
                    <h1 className='transaction-sub-title'>Gross Weight</h1>
                  </Col>
                </Row>
              </Col>

              <Col
                lg={3}
                md={3}
                sm={24}
                xs={24}
                style={{ textAlign: "center" }}>
                <Divider
                  type='vertical'
                  style={{
                    display: "inline-block",
                    width: "2px",
                    height: "80%",
                    margin: "5% 0px",
                    verticalAlign: "middle",
                    backgroundColor: "#e3e3e3",
                    float: "left",
                  }}
                />

                <Row>
                  <h1 className='total-text' style={{ fontSize: "40px" }}>
                    <CountUp start={0} end={this.state.total_stock} />
                    <span style={{ fontSize: "25px", color: "#7e7d7d" }}>
                      {" "}
                      Items
                    </span>
                  </h1>
                  {/*<Col lg={1} md={1} sm={24} xs={24} style={{textAlign: "center","padding-top":"25px"}}>
                                        <Divider type="vertical" className="vertical-divider-customer1"/>
                                    </Col>*/}
                  <h1
                    className='total-text'
                    style={{ fontSize: "20px", paddingLeft: "10px" }}>
                    <CountUp start={0} end={this.state.totalPcs} />
                    <span style={{ fontSize: "15px", color: "#7e7d7d" }}>
                      {" "}
                      Pcs
                    </span>
                  </h1>
                  {this.state.dataObj.totalGrossWeight !== undefined ? (
                    <h1
                      className='total-text'
                      style={{ fontSize: "20px", paddingLeft: "10px" }}>
                      {/* <CountUp
                        start={0.0}
                        end={this.state.dataObj.totalGrossWeight}
                        decimals={3}
                      /> */}
                      {/* {this.state.dataObj.totalGrossWeight} */}
                      {
                        this.state.dataObj.totalGrossWeight
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,3})?/)[0]
                      }
                      <span style={{ fontSize: "15px", color: "#7e7d7d" }}>
                        {" "}
                        gms
                      </span>
                    </h1>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>

              <Col lg={6} md={9} sm={24} xs={24}>
                            <Divider
                                type='vertical'
                                style={{
                                    display: "inline-block",
                                    width: "2px",
                                    height: "80%",
                                    margin: "5% 0px",
                                    verticalAlign: "middle",
                                    backgroundColor: "#e3e3e3",
                                    float: "left",
                                }}
                            />
                <p
                  style={{ marginBottom: "5px" }}
                  className='transaction-serach-text text-color-8c8c8c'>
                  Search
                </p>
                <Input
                  value={this.state.search}
                  placeholder='-- Search SKU--'
                  style={{ float: "left", width: "60%" }}
                  // onChange={e => this.setState({ search: e.target.value })}
                  onChange={this.searchOnchange}
                />
                <Button
                  onClick={this.onSerach}
                  style={{ marginLeft: "11px" }}
                  type='primary'
                  disabled={this.state.btnsearch}>
                  Search
                </Button>
                        </Col>

                        <Col
                            lg={3}
                            md={3}
                            sm={24}
                            xs={24}

                            style={{ textAlign: "center" }}
                            className='cursor-pointer'>
                            <Divider
                                type='vertical'
                                className='vertical-divider-stock-2'
                                style={{ marginTop: "14px!important" }}
                            />
                            <Uploa data={this.state.data} />
                            <h5 className='text-color-8c8c8c'>Transfer Stock</h5>
                        </Col>

              <Col
                lg={3}
                md={3}
                sm={24}
                xs={24}
                style={{ textAlign: "center" }}
                className='cursor-pointer'>
                <Divider
                  type='vertical'
                  className='vertical-divider-stock-2'
                  style={{ marginTop: "14px!important" }}
                />
                {companyType == 3 ? (
                  <React.Fragment>
                    <img
                      className='import-icon'
                      onClick={this.syncInvetory}
                      src={ImportIcon}
                    />
                    <h5 className='text-color-8c8c8c'> FTP Sync Inventory</h5>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Upload
                      popup_name={"Import Inventory"}
                      selected_id={this.state.selected_id}
                      rootInfo={this.state.rootInfo}
                      isModalOpen={false}
                      fromSingleUpload={false}
                    />
                    <h5 className='text-color-8c8c8c'>Import Inventory</h5>
                  </React.Fragment>
                )}
              </Col>

              <Col
                lg={3}
                md={3}
                sm={24}
                xs={24}
                style={{ textAlign: "center" }}
                className='cursor-pointer'>
                <Divider
                  type='vertical'
                  className='vertical-divider-stock-2'
                  style={{ marginTop: "14px!important" }}
                />
                {companyType == 3 ? (
                  ""
                ) : (
                  <React.Fragment>
                    <Upload
                      popup_name={"Add Inventory"}
                      selected_id={this.state.selected_id}
                      rootInfo={this.state.rootInfo}
                      visibleForAddSku={true}
                      closeAddSku={this.closeAddSku}
                      fromSingleUpload={true}
                    />
                    <h5 className='text-color-8c8c8c'>Add Inventory</h5>
                  </React.Fragment>
                )}
              </Col>

              <Col
                lg={3}
                md={3}
                sm={24}
                xs={24}
                style={{ textAlign: "center" }}
                className='cursor-pointer'>
                <Divider
                  type='vertical'
                  className='vertical-divider-stock-2'
                  style={{ marginTop: "14px!important" }}
                />
                <img
                  className='export-icon'
                  onClick={this.showModal}
                  src={ExportIcon}
                />
                <h4 className='text-color-8c8c8c'>Export Inventory</h4>
              </Col>
            </Row>
          </Card>

          <Form hideRequiredMark={true} colon={false} labelAlign={"left"}>
            <Card
              className='filtter-card'
              bordered={false}
              bodyStyle={{
                padding: "10px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}>
              <Row>
                {/*<Col*/}
                {/*  lg={2}*/}
                {/*  md={2}*/}
                {/*  sm={24}*/}
                {/*  xs={24}*/}
                {/*  className='text-aligin-center'>*/}
                {/*  <img src={FilterIcon} className='filter-icon' />*/}
                {/*</Col>*/}

                <Col
                  lg={4}
                  md={4}
                  sm={24}
                  xs={24}>
                  <Form.Item {...formItemLayout} label='Date'>
                    {getFieldDecorator(`date`, {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <RangePicker
                        format='DD-MM-YYYY'
                        placeholder={["From ", "To"]}
                        separator='-'
                        style={{ minWidth: "100%" }}
                        allowClear={false}
                      />
                    )}
                  </Form.Item>
                            </Col>

                            {this.state.rootInfo === "branch" &&
                                this.state.branch_id != "" ? (
                <Col lg={3.5} md={3} sm={24} xs={24}>
                    <Form.Item
                      label={this.state.textForSelect}
                      {...formItemLayout}>
                      {getFieldDecorator(`rootid`, {
                        initialValue: this.state.id,
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(
                        <Select
                          placeholder='Please select branch'
                          onChange={this.onChangeBranchRadio}
                          style={{ minWidth: "100%" }}>
                          <Option key={this.state.id} value={this.state.id}>
                            All Branches
                          </Option>
                          {this.state.branch_list.map((data, index) => (
                            <Option key={data._id} value={data._id}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                                </Col>
                            ) : (
                                ""
                            )}

                            {this.state.rootInfo === "floor" ? (
                            <Col lg={3.5} md={3} sm={24} xs={24}>
                    <Form.Item
                      label={this.state.textForSelect}
                      {...formItemLayout}>
                      {getFieldDecorator(`rootid`, {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(
                        <Select
                          placeholder='Please select floor'
                          style={{ minWidth: "100%" }}>
                          {this.state.floorList.map((data, index) => (
                            <Select.Option key={data._id} value={data._id}>
                              {data.name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                                    </Form.Item>
                                </Col>
                            ) : (
                                ""
                            )}
                         
                 

                {/*  {this.state.rootInfo === "floor" ? (*/}
                {/*    <Form.Item*/}
                {/*      label={this.state.textForSelect1}*/}
                {/*      {...formItemLayout}>*/}
                {/*      {getFieldDecorator(`secondid`, {*/}
                {/*        //initialValue: this.state.id,*/}
                {/*        rules: [*/}
                {/*          {*/}
                {/*            required: false,*/}
                {/*          },*/}
                {/*        ],*/}
                {/*      })(*/}
                {/*        <Select*/}
                {/*          placeholder='Please select section'*/}
                {/*          style={{ minWidth: "100%" }}>*/}
                {/*          {this.state.sectionList.map((data, index) => (*/}
                {/*            <Select.Option key={data._id} value={data._id}>*/}
                {/*              {data.name}*/}
                {/*            </Select.Option>*/}
                {/*          ))}*/}
                {/*        </Select>*/}
                {/*      )}*/}
                {/*    </Form.Item>*/}
                {/*  ) : (*/}
                {/*    ""*/}
                {/*  )}*/}

                {/*  {this.state.rootInfo === "section" ? (*/}
                {/*    <Form.Item*/}
                {/*      label={this.state.textForSelect1}*/}
                {/*      {...formItemLayout}>*/}
                {/*      {getFieldDecorator(`secondid`, {*/}
                {/*        // initialValue: this.state.id,*/}
                {/*        rules: [*/}
                {/*          {*/}
                {/*            required: false,*/}
                {/*          },*/}
                {/*        ],*/}
                {/*      })(*/}
                {/*        <Select*/}
                {/*          placeholder='Please select sub section'*/}
                {/*          style={{ minWidth: "100%" }}>*/}
                {/*          {this.state.subSectionList.map((data, index) => (*/}
                {/*            <Select.Option key={data._id} value={data._id}>*/}
                {/*              {data.name}*/}
                {/*            </Select.Option>*/}
                {/*          ))}*/}
                {/*        </Select>*/}
                {/*      )}*/}
                {/*    </Form.Item>*/}
                {/*  ) : (*/}
                {/*    ""*/}
                {/*  )}*/}
                {/*</Col>*/}

                <Col lg={3.5} md={3} sm={24} xs={24}>
                  <Form.Item label='Design Category' {...formItemLayout}>
                    {getFieldDecorator(`d_cat`, {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        //mode="tags"
                        placeholder='- - Select - -'
                        style={{ minWidth: "100%" }}>
                        {this.state.ctgArray &&
                          this.state.ctgArray.map((user) => (
                            <Option key={user} value={user}>
                              {user}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col lg={3.5} md={3} sm={24} xs={24}>
                  <Form.Item label='Counter' {...formItemLayout}>
                    {getFieldDecorator(`counter`, {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        //mode="tags"
                        placeholder='- - Select - -'
                        style={{ minWidth: "100%" }}>
                        {this.state.onlyCtgArray.length > 0 &&
                          this.state.onlyCtgArray.map((user) => (
                            <Option key={user} value={user}>
                              {user}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                            <Col lg={3.5} md={3} sm={24} xs={24}>
                                <Form.Item label='Stock Type' {...formItemLayout}>
                                    {getFieldDecorator(`stock_type`, {
                                        rules: [
                                            {
                                                required: false,
                                            },
                                        ],
                                    })(
                                        <Select
                                            placeholder='- - Select - -'
                                            style={{ minWidth: "100%" }}>
                                            <Option key="stock_repair" value="stock_repair">
                                                Stock Repair
                                            </Option>
                                            <Option key="stock_export" value="stock_export">
                                                Stock Import
                                            </Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>

                <Col lg={4} md={4} sm={24} xs={24}
                            style={{ paddingTop: '42px' }}
                            >
                    <Button
                      style={{ float: "left" }}
                      onClick={this.btnFiltter}
                      type='primary'>
                      Filter
                    </Button>
                    <Button
                      onClick={this.filterClear}
                      className='transaction-clear-btn'
                      ghost>
                      Clear
                    </Button>
                            </Col>


                            <Col
                                lg={4}
                                md={4}
                                sm={24}
                                xs={24}
                                className='text-aligin-center'>
                                <Divider
                                    type='vertical'
                                    className='vertical-divider-stock-2'
                                    style={{ marginTop: "10px!important" }}
                                />
                                <img src={FilterIcon} onClick={this.showFilter} className='filter-icon' style={{ height: '50px', width: '50px' }} />
                            </Col>

              </Row>
            </Card>
          </Form>


          <Card
            style={{ marginBottom: "0px" }}
            bodyStyle={{ padding: "10px 10px", paddingBottom: "0px" }}
            bordered={false}>
            <Spin spinning={this.state.Spinloading}>
              <Row>
                <Col span={15}>
                  <span style={{ float: "left", marginRight: "9px" }}>
                    {" "}
                    <h4 style={{ marginTop: "5px" }}>
                      {"Selected Inventory :  " +
                        this.state.selectedRowKeys.length}
                    </h4>
                  </span>
                  <span style={{ float: "left" }}>
                    {this.state.btndisabled ? (
                      <Button
                        style={{ marginBottom: "5px", marginRight: "7px" }}
                        disabled={this.state.btndisabled}
                        type='dashed'
                        ghost>
                        Delete
                      </Button>
                    ) : (
                      <Popconfirm
                        title='Are you sure delete selected inventory ?'
                        onConfirm={this.showOtpModal}
                        //onCancel={cancel} borderColor: "#f5222d",color: "#f5222d"
                        okText='Yes'
                        cancelText='No'>
                        <Button
                          style={{ marginBottom: "5px", marginRight: "7px" }}
                          // disabled={this.state.btndisabled}
                          type='dashed'
                          ghost>
                          Delete
                        </Button>
                      </Popconfirm>
                    )}
                    {/* <Button style={{marginBottom: "5px"}} onClick={this.showModalStockDelete} ghost>Delete ALL</Button>*/}
                    <ResetPassWord
                      selected_id={this.state.selected_id}
                      rootInfo={this.state.rootInfo}
                      onDeleteAllRequested={this.handleDeleteAllRequested}
                    />
                  </span>
                </Col>
                <Col span={9}>
                  <select
                    id='selected_device'
                    style={{ float: "right" }}
                    className='ant-select-selection ant-select-selection--single'
                    onChange={() => window.onDeviceSelected(this)}></select>
                </Col>
                {/**  <Col span={9}>
                                 <Icon  type="cloud-download" onClick={this.showModal} style={{float: "right" ,fontSize: "27px",marginLeft:"9px" ,cursor: "pointer"}} />
                                 <span style={{float:"right"}}> <h4   style={{marginTop:"5px"}}>{"Total stock :  "+this.state.total_stock}</h4> </span>
                                 </Col> */}
              </Row>
              <Table
                loading={this.state.table_loading}
                className='gx-table-responsive'
                rowSelection={rowSelection}
                columns={this.columns}
                dataSource={data}
                size={"small"}
                rowKey={(record) => record._id}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                //scroll={{ y: 'max-content' }}
                scroll={{ y: heightd }}
                sortDirections={sortDirections}
                //scroll={{ y: 400 }}
              />
            </Spin>
          </Card>
        </div>

        <Modal
          title='Verify Otp'
          className='delete-stock-otp'
          visible={this.state.otpFormVisible}
          bodyStyle={{ paddingBottom: "48px" }}
          onCancel={this.hideModalOtpForm}
          footer={null}>
          <Form layout='vertical' onSubmit={this.verifyOtp}>
            <Form.Item label='Otp' className='customFormItem'>
              {getFieldDecorator("otp", {
                rules: [{ required: true, message: "Please input otp !" }],
              })(<Input autoFocus={true} />)}
            </Form.Item>
            <Divider />
            <div style={{ float: "right" }}>
              <Button key='back' onClick={this.hideModalOtpForm}>
                Cancel
              </Button>
              <Button key='submit' type='primary' onClick={this.verifyOtp}>
                Submit
              </Button>
            </div>
          </Form>
        </Modal>

        <Modal
          title='Select employee '
          visible={this.state.uploadvisibleForSync}
          onOk={this.uploadhandleSyncOk}
          //     confirmLoading={confirmLoading}
          style={{ paddingBottom: "0px" }}
          //width={650}
          onCancel={this.uploadhandleCancel}
          footer={[
            <span style={{ marginBottom: "20px" }}>
              <Button key='back' onClick={this.uploadhandleCancel} ghost>
                Close
              </Button>
              <Button
                key='submit'
                type='primary'
                onClick={this.uploadhandleSyncOk}
                loading={this.state.syncInvetoryLoading}>
                Sync Invetory
              </Button>
            </span>,
          ]}>
          <Form layout='vertical' onSubmit={this.FormSubmit}>
            <Form.Item label='Select employee' className='customFormItem'>
              {getFieldDecorator("empName", {
                //initialValue: this.state.branch_id,
                rules: [{ required: true, message: "Please Select employee!" }],
              })(
                <Select placeholder='Please Select employee'>
                  {this.state.userList.map((data, index) => (
                    <Option key={data.username} value={data.username}>
                      {data.lastname} {data.firstname}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Form>
            </Modal>

            <Modal
                title="Filter"
                visible={this.state.visibleFilter}
                onCancel={this.handleCloseModal}
                footer={[
                    <Button key="clear" onClick={this.handleClear}>
                        Clear
                    </Button>,
                    <Button key="filter" type="primary" onClick={this.btnFilter}>
                        Filter
                    </Button>,
                ]}
                width={800}
                style={{ maxHeight: '80vh' }}
            >
                <Form layout="vertical" onSubmit={this.FormSubmit}>

                    {this.state.rootInfo === "branch" &&
                        this.state.branch_id != "" ? (

                    <React.Fragment>
                            <Form.Item label="Select Branch" className="customFormItem">
                               {getFieldDecorator(`branchid`, {
                                initialValue: this.state.branch_id,
                                })(
                                    <Select placeholder="Please select branch" onChange={this.onChangeBranch}>
                                        {this.state.branch_list.map((data, index) =>
                                            <Option key={data._id} value={data._id}>{data.name}</Option>
                                        )}
                                    </Select>
                                )}
                            </Form.Item>

                            <Form.Item label="Select Floor" className="customFormItem">
                                {getFieldDecorator(`floorId`, {
                                    initialValue: this.state.floor_id,
                                })(
                                    <Select placeholder="Please select floor" onChange={this.onChangeFloor}>
                                        {this.state.floor_list.map((data, index) =>
                                            <Option key={data._id} value={data._id}>{data.name}</Option>
                                        )}
                                    </Select>
                                )}
                            </Form.Item>

                            <Form.Item label="Select Section " className="customFormItem">
                                {getFieldDecorator(`sectionId`, {
                                    initialValue: this.state.section_id,
                                })(
                                    <Select placeholder="Please select section" onChange={this.onChangeSection}>
                                        {this.state.section_list.map((data, index) =>
                                            <Option key={data._id} value={data._id}>{data.name}</Option>
                                        )}
                                    </Select>
                                )}
                            </Form.Item>


                            <Form.Item label="Select SubSection" className="customFormItem">
                                {getFieldDecorator('subSectionId', {
                                    initialValue: this.state.sub_section_id,
                                })(
                                    <Select placeholder="Please select subsection"
                                        onChange={this.onChangeSubSection}>
                                        {this.state.sub_section_list.map((data, index) =>
                                            <Option key={data._id} value={data._id}>{data.name}</Option>
                                        )}
                                    </Select>
                                )}
                            </Form.Item>

                        </React.Fragment>

                    ) : (
                        ""
                    )}

                    {this.state.rootInfo === "floor" ? (

                        <React.Fragment>

                            <Form.Item label="Select Floor" className="customFormItem">
                                {getFieldDecorator(`floorId`, {
                                    initialValue: this.state.floor_id,
                                })(
                                    <Select placeholder="Please select floor" onChange={this.onChangeFloor}>
                                        {this.state.floor_list.map((data, index) =>
                                            <Option key={data._id} value={data._id}>{data.name}</Option>
                                        )}
                                    </Select>
                                )}
                            </Form.Item>

                            <Form.Item label="Select Section " className="customFormItem">
                                {getFieldDecorator(`sectionId`, {
                                    initialValue: this.state.section_id,
                                })(
                                    <Select placeholder="Please select section" onChange={this.onChangeSection}>
                                        {this.state.section_list.map((data, index) =>
                                            <Option key={data._id} value={data._id}>{data.name}</Option>
                                        )}
                                    </Select>
                                )}
                            </Form.Item>


                            <Form.Item label="Select SubSection" className="customFormItem">
                                {getFieldDecorator('subSectionId', {
                                    initialValue: this.state.sub_section_id,
                                })(
                                    <Select placeholder="Please select subsection"
                                        onChange={this.onChangeSubSection}>
                                        {this.state.sub_section_list.map((data, index) =>
                                            <Option key={data._id} value={data._id}>{data.name}</Option>
                                        )}
                                    </Select>
                                )}
                            </Form.Item>

                        </React.Fragment>

                    ) : (
                        ""
                    )}

                </Form>
            </Modal>


        <Modal
          title={
            loading
              ? `Data is processing please wait ...`
              : `Inventory File Created`
          }
          visible={this.state.visible}
          //onOk={this.handleOk}
          //  confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
          // style={{backgroundImage: `url('https://www.codeproject.com/KB/aspnet/1095434/gears.gif')`}}
          footer={[
            <Button key='back' onClick={this.handleCancel} ghost>
              Cancel
            </Button>,
            <ExcelFile
              filename={`Inventory - ${new Date()}`}
              element={
                loading ? (
                  <Button key='submit1' type='primary' disabled>
                    Wait..{" "}
                  </Button>
                ) : (
                  <Button type='primary'>Download</Button>
                )
              }>
                <ExcelSheet data={this.state.dataforExport} name='Inventory'>
                <ExcelColumn label='RFID_Number' value='rfid_number' />
                <ExcelColumn label='Bag_Number' value='sku_number' />
                <ExcelColumn label='Dsg_Code' value='design_code' />
                <ExcelColumn label='Suffix' value='suffix' />
                <ExcelColumn label='Size' value='sku_size' />
                <ExcelColumn
                  label='Cust_Dsg_Cd'
                  value='customer_design_category'
                />
                <ExcelColumn label='Dsg_Ctg' value='design_category' />
                <ExcelColumn label='Dsg_Col' value='design_color' />
                <ExcelColumn label='Bag_Qty' value='sku_quantity' />
                <ExcelColumn label='Sales_Ctg' value='sales_category' />
                <ExcelColumn label='Set_Code' value='set_code' />
                <ExcelColumn label='Collection' value='sku_collection' />
                <ExcelColumn label='Project' value='project' />
                <ExcelColumn label='Metal_Type' value='metal_type' />
                <ExcelColumn label='Karatage' value='purity' />
                <ExcelColumn
                  label='Unit_Of_Measurement'
                  value='unit_of_measurement'
                />
                <ExcelColumn label='Gross_Wgt' value='gross_weight' />
                <ExcelColumn label='Net_Wgt' value='net_weight' />
                <ExcelColumn label='Dia_Wgt' value='diamond_weight' />
                <ExcelColumn label='Dia_Qty' value='diamond_quantity' />
                <ExcelColumn label='Col_Stn_Wgt' value='color_stone_weight' />
                <ExcelColumn label='Col_Stn_Qty' value='color_stone_quantity' />
                <ExcelColumn label='Fnd_Wgt' value='finding_weight' />
                <ExcelColumn label='Fnd_Qty' value='finding_quantity' />
                <ExcelColumn label='Sales_Value' value='sales_value' />
                <ExcelColumn label='Dia_Value' value='diamond_value' />
                <ExcelColumn label='Col_Stn_Value' value='color_stone_value' />
                <ExcelColumn label='Gold_Value' value='gold_value' />
                <ExcelColumn label='Finding_Value' value='finding_value' />
                <ExcelColumn label='Labour_Value' value='labour_value' />
                <ExcelColumn label='Gold_Rate' value='gold_rate' />
                <ExcelColumn label='Silver_Rate' value='silver_rate' />
                <ExcelColumn label='Platinum_Rate' value='platinum_rate' />
                <ExcelColumn label='Counter' value='counter' />
                <ExcelColumn label='Tray_Number' value='tray_number' />
                <ExcelColumn
                  label='Retail_Dia_Value'
                  value='retail_diamond_value'
                />
                <ExcelColumn label='Retail_Value' value='retail_value' />
                <ExcelColumn label='Asking_Value' value='asking_value' />
                <ExcelColumn
                  label='Asking_Dia_Value'
                  value='asking_diamond_value'
                />
                <ExcelColumn label='field_1' value='field_1' />
                <ExcelColumn label='field_2' value='field_2' />
                <ExcelColumn label='field_3' value='field_3' />
                <ExcelColumn label='field_4' value='field_4' />
                <ExcelColumn label='field_5' value='field_5' />
                <ExcelColumn label='field_6' value='field_6' />
                <ExcelColumn label='field_7' value='field_7' />
                <ExcelColumn label='field_8' value='field_8' />
                <ExcelColumn label='field_9' value='field_9' />
                <ExcelColumn label='field_10' value='field_10' />
                <ExcelColumn label='field_11' value='field_11' />
                <ExcelColumn label='field_12' value='field_12' />
                <ExcelColumn label='field_13' value='field_13' />
                <ExcelColumn label='field_14' value='field_14' />
                    <ExcelColumn label='field_15' value='field_15' />
                    {/*<ExcelColumn*/}
                    {/*    label='Branch'*/}
                    {/*    value={(row) => {*/}
                    {/*        const branch = this.state.branch_list.find(branch => branch._id === row.root.branchId);*/}
                    {/*        return branch ? branch.name : '';*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<ExcelColumn*/}
                    {/*    label='Floor'*/}
                    {/*    value={(row) => {*/}
                    {/*        const floor = this.state.floor_list.find(floor => floor._id === row.root.floorId);*/}
                    {/*        return floor ? floor.name : '';*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<ExcelColumn*/}
                    {/*    label='Section'*/}
                    {/*    value={(row) => {*/}
                    {/*        const section = this.state.section_list.find(section => section._id === row.root.sectionId);*/}
                    {/*        return section ? section.name : '';*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<ExcelColumn*/}
                    {/*    label='SubSection'*/}
                    {/*    value={(row) => {*/}
                    {/*        const subSection = this.state.sub_section_list.find(subSection => subSection._id === row.root.subSectionId);*/}
                    {/*        return subSection ? subSection.name : '';*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<ExcelColumn label='Branch' value={(row) => row.root.branchId} />*/}
                    {/*<ExcelColumn label='Floor' value={(row) => row.root.floorId} />*/}
                    {/*<ExcelColumn label='Section' value={(row) => row.root.sectionId} />*/}
                    {/*<ExcelColumn label='SubSection' value={(row) => row.root.subSectionId} />*/}
              </ExcelSheet>
            </ExcelFile>,
          ]}>
          {loading ? (
            <div
              style={{
                minHeight: "150px",
                backgroundImage: `url(${Gears})`,
              }}></div>
          ) : (
            <Alert
              message='Success'
              description='Inventory file is ready you can download.'
              type='success'
              showIcon
            />
          )}
        </Modal>

            <Modal
                loading
                title={`Update Status`}
                visible={this.state.visiblestatus}
                onCancel={this.handleCance}
                footer={[
                    <Button key='back' onClick={this.handleCance}>
                        Cancel
                    </Button>,
                    <Button key='submit' type='primary' onClick={this.handleOk}>
                        {" "}
                        Save
                    </Button>
                ]}>
                <Form layout='horizontal' onSubmit={this.FormSubmit}>
                    <React.Fragment>

                        <Form.Item
                            label='Status Name'
                            className='customFormItem'
                            {...formItemLayout}>
                            {getFieldDecorator("statusname", {
                                rules: [
                                    { required: true, message: "Please select statusname!" },
                                ],
                            })(<Input />)}
                        </Form.Item>

                        <Form.Item
                            label='Status'
                            className='customFormItem'
                            {...formItemLayout}>
                            {getFieldDecorator("status", {
                                rules: [
                                    { required: true, message: "Please select status!" },
                                ],
                            })(
                                <Select
                                    placeholder="- - Select - -"
                                    style={{ minWidth: "100%" }}
                                >
                                    <Option value="Active">ACTIVE</Option>
                                    <Option value="Inactive">INACTIVE</Option>
                                </Select>
                            )}
                        </Form.Item>

                    </React.Fragment>

                </Form>
            </Modal>



        <div style={{ display: this.state.display ? "" : "none" }}>
          <StockViewPage
            backttoStock={this.closeStockView}
            display={this.state.display}
          />
        </div>

        {/* visibleForEditSku */}

        <div style={{ display: this.state.visibleForEditSku ? "" : "none" }}>
          <SkuEdit
            visibleForEditSku={this.state.visibleForEditSku}
            closeEditSku={this.closeEditSku}
            editRecord={this.state.record}
          />
        </div>
      </div>
    );
  }
}

stockAllocation = Form.create()(stockAllocation);

function mapStateToProps(state) {
  const { Common } = state;
  const {
    user_list: user_list,
    ftp_sync: ftp_sync,
    sub_section_list: sub_section_list,
    get_stock: get_stock,
    delete_all_stock: delete_all_stock,
    delete_all_stock_root_info: delete_all_stock_root_info,
    get_stock_export: get_stock_export,
    delete_multiple_stock: delete_multiple_stock,
    branch_list: branch_list,
    floor_list: floor_list,
    section_list: section_list,
    otp_stock_delete: otp_stock_delete,
    verify_otp_stock_delete: verify_otp_stock_delete,
  } = Common;
  return {
    user_list,
    ftp_sync,
    sub_section_list,
    get_stock,
    delete_all_stock,
    delete_all_stock_root_info,
    get_stock_export,
    delete_multiple_stock,
    branch_list,
    floor_list,
    section_list,
    otp_stock_delete,
    verify_otp_stock_delete,
  };
}

const AppContainer = connect(mapStateToProps)(stockAllocation);

export default AppContainer;
