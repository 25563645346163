import React, { Component } from 'react'
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import moment from 'moment';
import { Card, Table, Form, Row, Col, DatePicker, Select, InputNumber, Button, AutoComplete, Input, Icon } from "antd";
import EditAllocation from './editAllocation';
import { getRfidDevice } from '../../../actions/index';


const OptGroup = AutoComplete.OptGroup;

const { MonthPicker, RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;
const CustomerList = [];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const columnsForAllocation = [
  {
    title: 'Device Type',
    dataIndex: 'device_type',
    width: '10%',
    sorter: (a, b) => a.name - b.name,
    render: (text, record) => {

      return (
        <span>
          {text}
        </span>
      );
    }
  },
  {
    title: 'Allocated',
    dataIndex: 'Allocated',
    width: '10%',
    sorter: (a, b) => a.name - b.name,
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
          {text}
        </span>
      );
    },

  },
  {
    title: 'FL01',
    dataIndex: 'FL01',
    width: '10%',
    sorter: (a, b) => a.name - b.name,
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
          {text}
        </span>
      );
    }
  },
  {
    title: 'FL02',
    dataIndex: 'FL02',
    width: '10%',
    sorter: (a, b) => a.name - b.name,
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
          {text}
        </span>
      );
    }

  },
  {
    title: 'FL03',
    dataIndex: 'FL03',
    width: '10%',
    sorter: (a, b) => a.name - b.name,
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
          {text}
        </span>
      );
    }
  },
  {
    title: 'FL04',
    dataIndex: 'FL04',
    width: '10%',
    sorter: (a, b) => a.name - b.name,
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
          {text}
        </span>
      );
    }
  },
  {
    title: 'FL05',
    dataIndex: 'FL05',
    width: '10%',
    sorter: (a, b) => a.name - b.name,
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
          {text}
        </span>
      );
    }
  }


];
const columns = [
  // {
  //   title: 'Sr No',
  //   dataIndex: 'sr_no',
  //   width: '10%',
  //   sorter: (a, b) => a.name - b.name,
  //   render: (text, record) => {

  //     return (
  //       <span>
  //           {text}
  //       </span>
  //     );
  //   }
  // },
  {
    title: 'NAME',
    dataIndex: 'name',
    width: '10%',
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
          {text}
        </span>
      );
    },

  },
  {
    title: 'TYPE',
    dataIndex: 'typeName',
    width: '10%',
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
          {text}
        </span>
      );
    }
  },
  {
    title: 'MAC Address',
    dataIndex: 'mac',
    width: '10%',
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
          {text}
        </span>
      );
    }

  },
  {
    title: 'Serial Number',
    dataIndex: 'serialNumber',
    width: '10%',
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
          {text}
        </span>
      );
    }
  },

];
class RFIDDevices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
      ],
      table_loading: false,
      floorListDetails: []
    };

  }
  componentDidMount() {
    const { dispatch } = this.props;

    // this.setState({
    //   data:json
    // })
    dispatch(getRfidDevice());
    //dispatch(getFloorList());
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rfiddevice_info_details != this.props.rfiddevice_info_details) {
      // console.log(this.props.rfiddevice_info_details);
      // console.log(this.props.floor_list);
      try {
        if (this.props.rfiddevice_info_details.status) {
          this.setState({
            data: this.props.rfiddevice_info_details.data_array
          });
        }

      } catch (error) {
        console.log(error);
      }
    }
  }

  handleSearch = (e) => {

    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dispatch } = this.props;
        // let userdata=values;

      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let data = this.state.data;
    if (this.state.search) {
      data = data.filter(row => {
        return (
          String(row.mac)
            .toUpperCase()
            .includes(this.state.search.toUpperCase()) ||
          String(row.name)
            .toUpperCase()
            .includes(this.state.search.toUpperCase()) ||
          String(row.typeName)
            .toUpperCase()
            .includes(this.state.search.toUpperCase()) ||
          String(row.serialNumber)
            .toUpperCase()
            .includes(this.state.search.toUpperCase())
        );
        //row.name.toUpperCase().includes(this.state.search.toUpperCase())
      });
    }
    return (
      <div>
        <h2 className="title gx-mb-4"><IntlMessages id="sidebar.rfidDevices" /></h2>

        {/** <Row>
            <Col md={20} sm={24} xs={24}>  
                <Card> 
                        <Table className="gx-table-responsive"
                        title={() => 'Allocation'}
                        loading={this.state.table_loading} 
                        columns={columnsForAllocation} 
                        dataSource={this.state.dataForAllocation}
                        size={"small"}
                        rowKey={record => record.name}
                />
                </Card>
            </Col>    
            <Col md={4} sm={24} xs={24}>
            <EditAllocation/>
           
            </Col>       
        </Row>
      */}

      <div
      className="certain-category-search-wrapper"
      style={{ marginBottom: "60px" }}
    >
      <Input
        value={this.state.search}
        placeholder="Search .."
        style={{ float: "left", width: "100%" }}
        onChange={e => this.setState({ search: e.target.value })}
        suffix={<Icon type="search" className="certain-category-icon" />}
      />

      
    </div>

        <Card bodyStyle={{ padding: "10px 0px"}}>
          <Table className="gx-table-responsive"
            loading={this.state.table_loading}
            columns={columns}
            dataSource={data}
            size={"small"}
            rowKey={record => record.name}
          />
        </Card>

      </div>
    )
  }
}

RFIDDevices = Form.create()(RFIDDevices);
function mapStateToProps(state) {
  const { Common } = state
  const { rfiddevice_info_details: rfiddevice_info_details } = Common
  return { rfiddevice_info_details }
}
const AppContainer = connect(
  mapStateToProps
)(RFIDDevices);


export default AppContainer;


