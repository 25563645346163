import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Menu, Popover} from "antd";
import Personicon from '../../assets/images/personIcon.png';
import {Link} from "react-router-dom";

const {SubMenu} = Menu;

var cookies = require('browser-cookies');

class UserInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            displaynone: true,
            usertype: localStorage.getItem('type_user')
        };
    }


    LogoutClick = (e) => {
        localStorage.removeItem("authUser");
        localStorage.removeItem("randid");
        localStorage.removeItem("user_id");
        localStorage.removeItem("type_user");
        localStorage.removeItem("name");
        localStorage.removeItem("usename");
        localStorage.removeItem("type_profile_name");
        localStorage.removeItem('isClient');
        localStorage.removeItem('companyType');
        localStorage.removeItem('showPreferences');
        localStorage.removeItem("DetailView");
        localStorage.removeItem("imageMapping");
        cookies.erase('wteoben');
        cookies.erase('root');

        const {dispatch} = this.props;
        let loginUrl = localStorage.getItem('login_url');
        window.location.href = loginUrl;
        // let { history } = this.props;
        //   history.push({
        //    pathname: '/stocktallyView',
        // });
        // dispatch(userLogout());
    }

    componentWillMount() {
        try {

            let name = localStorage.getItem("name");
            let showPreferences = localStorage.getItem("showPreferences");
            // console.log("name & Pref", name + " " + showPreferences);
            var length = 20;
            var trimmedString = name.length > length ?
                name.substring(0, length - 3) + "..." :
                name;
            this.setState({name: trimmedString, showpref: showPreferences});
        } catch (error) {
            console.log(error);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (prevProps.update_user_data !== this.props.update_user_data) {

                if (this.props.update_user_data.status) {
                    let name1 = this.props.update_user_data.data.lastname + " " + this.props.update_user_data.data.firstname;
                    localStorage.setItem("name", name1);
                    let showPreferences = localStorage.getItem("showPreferences");
                    // console.log("name & Pref", name1 + " " + showPreferences);
                    let length = 20;
                    let newName = name1.length > length ?
                        name1.substring(0, length - 3) + "..." : name1;
                    this.setState({
                        name: newName,
                        showpref: showPreferences
                    });

                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    onHoverManager = (e) => {
        // console.log("onHoverManager");
        this.setState({
            displaynone: !this.state.displaynone
        })
    }

    render() {

        const userMenuOptions = (
          <span>
            {this.state.usertype === 'companyAdmin' ? (
              <Menu style={{ border: 'none' }}>
                <SubMenu
                  title='Manage'
                  style={{ borderRadius: '10px !important' }}
                  rootPrefixCls='Test'>
                  <Menu.Item style={{ height: '34px' }}>
                    <Link to='/profile'>Profile</Link>
                  </Menu.Item>
                  {this.state.showpref === 'true' ? (
                    <Menu.Item style={{ height: '34px' }}>
                      <Link to='/perferences'>Preferences </Link>
                    </Menu.Item>
                  ) : (
                    ''
                  )}
                  <Menu.Item style={{ height: '34px' }}>
                    <Link to='/manageBranch'>Branch</Link>
                  </Menu.Item>
                  <Menu.Item style={{ height: '34px' }}>
                    <Link to='/manageFloor'>Floor</Link>
                  </Menu.Item>
                  <Menu.Item style={{ height: '34px' }}>
                    <Link to='/manageSection'>Section</Link>
                  </Menu.Item>
                  <Menu.Item style={{ height: '34px' }}>
                    <Link to='/manageSubsection'>Sub Section</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to='/manageUser'>User</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  title='Configration'
                  style={{ borderRadius: '10px !important' }}
                  rootPrefixCls='Test'>
                  <Menu.Item>
                    <Link to='/displayConfigration'>Inventory display</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to='/filterConfigration'>Filter</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to='/imageConfigration'>Image</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to='/customertypeConfiguration'>Customer Type</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to='/configurationDisplay'>
                      Configuration Display
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item>
                    <Link to='/inventoryScan'>
                      Inventory Scan
                    </Link>
                  </Menu.Item> */}
                  {/* <Menu.Item>
                    <Link to='/diskUsage'>
                      Disk Usage
                    </Link>
                  </Menu.Item> */}
                </SubMenu>
                <Menu.Item>
                  <Link to='/settings'>Settings</Link>
                </Menu.Item>
                {/** <SubMenu title="Customer Master" style={{borderRadius:"10px !important"}}>
               <Menu.Item style={{height: "34px"}}><Link to="/customerMaster">Customer Master</Link></Menu.Item>
               <Menu.Item ><Link to="/incrementMaster">Increment Master</Link></Menu.Item>
               </SubMenu>
               */}
                <Menu.Item onClick={this.LogoutClick}>Logout</Menu.Item>
              </Menu>
            ) : (
              <ul className='gx-user-popover'>
                <li>
                  <Link to='/profile' style={{ color: '#545454' }}>
                    View Profile
                  </Link>
                </li>
                <li onClick={this.LogoutClick}>Logout</li>
              </ul>
            )}
          </span>
        );

        const userMenuOptions1 = (
            <ul className="gx-user-popover">
                {/*<li>My Account</li>
        <li>Connections</li> 
        
        
        <span>
        <li onClick={this.onHoverManager}>Manage
        </li>
        <ul style={{display:this.state.displaynone?"none":""}}>
        
        <Link to="/manageBranch">
        <li >Branch</li>        
        </Link>  
     
        <Link to="/manageFloor">
        <li >Floor</li>        
        </Link>  

        <Link to="/manageSection">
        <li >Section</li>        
        </Link> 

        <Link to="/manageSubsection">
        <li >Sub Section</li>        
        </Link> 
        </ul></span>*/}

                <li
                    // onClick={() => this.props.userLogout()}

                    onClick={this.LogoutClick}
                >Logout
                </li>


            </ul>

        );
        let typePrifileName = localStorage.getItem("type_prifile_name");
        return (
            <React.Fragment>
      <span style={{float: "left"}}>
      <Popover placement="bottomRight" content={userMenuOptions} trigger="hover">
      <Avatar src={Personicon}
              className="gx-size-40 gx-pointer" alt="" style={{float: "left"}}/>
              <p style={{
                  paddingTop: "13px",
                  minWidth: "136px",
                  paddingLeft: "3pc",
                  textTransform: "capitalize",
                  lineHeight: "0px",
                  fontWeight: "bolder"
              }}>{this.state.name}</p>
              <p style={{paddingLeft: "3pc", fontSize: "12px"}}>{typePrifileName}
                      </p> 
                      
     </Popover>    
  
     </span>
                <Popover placement="bottomRight" content={userMenuOptions} trigger="hover">
                    <i className="icon icon-charvlet-down" style={{lineHeight: "41px", paddingLeft: "10px"}}></i>
                </Popover>
            </React.Fragment>
        )

    }
}

function mapStateToProps(state) {
    const {Common} = state
    const {logout: logout, update_user_data: update_user_data} = Common
    return {logout, update_user_data}
}

const AppContainer = connect(
    mapStateToProps
)(UserInfo);


export default AppContainer;
