import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import {
  getQuotation,
  downloadPdf,
  getCustomer,
  getAllSalesPerson,
  getAllBrachList,
  getAllSectionList,
  getAllFloorList,
} from "../../../actions/index";
import { getExportQuotation } from "../../../actions/dataexport";
import moment from "moment";
import ViewQuatationDetails from "../quotation/viewQuatationDetails";
import {
  Card,
  Table,
  Form,
  Row,
  Col,
  Radio,
  DatePicker,
  Select,
  InputNumber,
  Input,
  Button,
  Icon,
  Modal,
  Alert,
  Divider,
  Popconfirm,
} from "antd";
import CountUp from "react-countup";
import ReactExport from "react-data-export";
import Gears from "../../../assets/images/gears.gif";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import Socket from "./../../../containers/App/global";
import { sortDirections } from "../../../containers/App/commanFunction";
// import PDFViewer from "mgr-pdf-viewer-react";
// import * as ReactDOM from 'react-dom';
// import { PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
// ThumbnailView, Print, TextSelection, TextSearch, Annotation, Inject } from '@syncfusion/ej2-react-pdfviewer';
// import { RouteComponentProps } from 'react-router';

const cookies = require("browser-cookies");
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { MonthPicker, RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;
const CustomerList = [];
// const Cryptr = require('cryptr');
// const cryptr = new Cryptr('myTotalySecretKey');

// const webtoken = cryptr.decrypt(cookies.get('wteoben'));
// const  ExamplePDFViewer=(url1) =>{
//   console.log("url1");
//   console.log(url1.data);
//   return (<PDFViewer document={{
//    // url: url1.data
//    file:url1.data
//   }} />);
// }

class quotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tableLoading: true,
      totalQuatation: 0,
      visible: false,
      dataforExport: [],
      branchList: [],
      textForSelect: "",
      sectionList: [],
      floorList: [],
      floorId: "",
      branchId: "",
      sectionId: "",
      slecteID: "",
      allSalesPersons: [],
      // endpoint: "localhost:3005",
      search: "",
      color: "white",
      visiblepdf: false,
      pdfdo: false,
      btnsearch: true,
      //  pdffile:'',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    //dispatch(getCustomer());
    let usertype = localStorage.getItem("type_user");
    let root = JSON.parse(cookies.get("root"));
    let page_no = 0;
    dispatch(getCustomer(page_no, "", "", "", ""));
    if (usertype === "branchAdmin") {
      this.setState({
        rootInfo: "floor",
        rootInfoEqual: "branch",
        textForSelect: "Select Floor",
        id: root.branchId,
        selectedId: root.branchId,
        selectedRootInfoEqual: "branch",
      });
      dispatch(getAllFloorList("id"));
      dispatch(getAllSalesPerson("branch", root.branchId));
    }
    if (usertype === "companyAdmin") {
      this.setState({
        rootInfo: "branch",
        rootInfoEqual: "company",
        textForSelect: "Select Branch",
        id: root.companyId,
        selectedId: root.companyId,
        selectedRootInfoEqual: "company",
      });
      dispatch(getAllBrachList("id"));
      dispatch(getAllSalesPerson("company", root.companyId));
    }
    if (usertype === "floorAdmin") {
      this.setState({
        rootInfo: "section",
        rootInfoEqual: "floor",
        textForSelect: "Select Section",
        id: root.floorId,
        selectedId: root.floorId,
        selectedRootInfoEqual: "floor",
      });
      dispatch(getAllSectionList("id"));
      dispatch(getAllSalesPerson("floor", root.floorId));
    }

    Socket.on("Notification", (col) => {
      try {
        if (col.data_code === "701") {
          // console.log("col Notification67 ");
          // console.log(col);
          let newQuatation = col.data_obj;
          let quotationData = this.state.data;
          let skuitemarray = newQuatation.skuItemsIssue;
          for (let index = 0; index < skuitemarray.length; index++) {
            // const Skuelement = skuitemarray[index].skuItem;
            // console.log(skuitemarray[index].skuItem);
            // let itemArray=[];
            // for (let index = 0; index < Skuelement.length; index++) {
            //   const element = Skuelement[index];
            //   itemArray=JSON.parse(element);
            // }
            try {
              newQuatation.skuItemsIssue[index].skuItem = JSON.parse(
                skuitemarray[index].skuItem
              );
            } catch (error) {
              newQuatation.skuItemsIssue[index].skuItem =
                skuitemarray[index].skuItem;
            }

            //JSON.parse(skuitemarray[index].skuItem);
            newQuatation.skuItemsIssue[index].uniqeId = index + 1;
          }

          quotationData.unshift(newQuatation);
          let pagination = {};
          pagination.total = this.state.pagination.total + 1;
          this.setState({
            data: quotationData,
            totalQuatation: this.state.totalQuatation + 1,
            pagination,
          });
        }
      } catch (e) {
        console.log("error", e);
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    //get Customer list

    if (prevProps.quotation_details != this.props.quotation_details) {
      // console.log(this.props.quotation_details);
      try {
        if (this.props.quotation_details.status) {
          let json = this.props.quotation_details.data_array;
          for (let index = 0; index < json.length; index++) {
            for (
              let index1 = 0;
              index1 < json[index].skuItemsIssue.length;
              index1++
            ) {
              const element = json[index].skuItemsIssue[index1];
              json[index].skuItemsIssue[index1].skuItem = JSON.parse(
                json[index].skuItemsIssue[index1].skuItem
              );
              json[index].skuItemsIssue[index1].uniqeId = index1 + 1;
            }
          }
          let pagination = {};
          pagination.total = this.props.quotation_details.data.totalQuotes;
          pagination.pageSize = 10;
          this.setState({
            data: json,
            pagination,
            totalQuatation: this.props.quotation_details.data.totalQuotes,
          });
          //  console.log("json");
          //  console.log(json);
        }
      } catch (e) {
        console.log("error", e);
      }
      this.setState({
        tableLoading: false,
      });
    }

    if (
      prevProps.export_quotation_details != this.props.export_quotation_details
    ) {
      // console.log("this.state.dataforExport");
      //  console.log(this.props.export_quotation_details);
      if (this.props.export_quotation_details.status) {
        let json = this.props.export_quotation_details.data_array;
        for (let index = 0; index < json.length; index++) {
          for (
            let index1 = 0;
            index1 < json[index].skuItemsIssue.length;
            index1++
          ) {
            const element = json[index].skuItemsIssue[index1];
            json[index].skuItemsIssue[index1].skuItem = JSON.parse(
              json[index].skuItemsIssue[index1].skuItem
            );
            json[index].skuItemsIssue[index1].uniqeId = index1 + 1;
          }
        }

        this.setState({
          dataforExport: this.state.dataforExport.concat(json),
          loading: false,
        });
        //  console.log("this.state.dataforExport111");
        // console.log(this.state.dataforExport);

        if (
          this.props.export_quotation_details.data.totalQuote ==
          this.state.dataforExport.length
        ) {
          this.setState({
            loading: false,
          });
        } else {
          if (this.props.export_quotation_details.data.totalQuote > 100) {
            const { dispatch } = this.props;
            let page =
              parseInt(this.props.export_quotation_details.data.currentPage) +
              1;
            dispatch(getExportQuotation(page, 100));
          }
        }
      }
    }
    try {
      if (prevProps.branch_list != this.props.branch_list) {
        if (this.props.branch_list.status) {
          this.setState({
            branchId: this.props.branch_list.data_array[0]._id,
            branchList: this.props.branch_list.data_array,
            slecteID: this.props.branch_list.data_array[0]._id,
          });
          if (this.props.branch_list.data_array.length >= 1) {
            const { dispatch } = this.props;
            let page_no = 0;
            dispatch(
              getQuotation(
                page_no,
                this.state.id,
                this.state.rootInfoEqual,
                "",
                "",
                "",
                "",
                "",
                "",
                ""
              )
            );
            //dispatch(getSKUByRootInfoAndId(this.props.branch_list.data_array[0]._id,this.state.rootInfo));
          }
        } else {
          this.setState({
            branchList: [],
          });
        }
      }

      if (prevProps.floor_list != this.props.floor_list) {
        //console.log("this.props.floor_list");
        //  console.log(this.props.floor_list);
        if (this.props.floor_list.status) {
          this.setState({
            floorId: this.props.floor_list.data_array[0]._id,
            floorList: this.props.floor_list.data_array,
            slecteID: this.props.floor_list.data_array[0]._id,
          });
          if (this.props.floor_list.data_array.length >= 1) {
            const { dispatch } = this.props;
            dispatch(
              getQuotation(
                0,
                this.state.id,
                this.state.rootInfoEqual,
                "",
                "",
                "",
                "",
                "",
                "",
                ""
              )
            );
          }
        } else {
          this.setState({
            floorList: [],
          });
        }
      }

      if (prevProps.section_list != this.props.section_list) {
        //console.log("this.props.section_list");
        // console.log(this.props.section_list);
        if (this.props.section_list.status) {
          this.setState({
            sectionId: this.props.section_list.data_array[0]._id,
            sectionList: this.props.section_list.data_array,
            slecteID: this.props.section_list.data_array[0]._id,
          });
          if (this.props.section_list.data_array.length >= 1) {
            const { dispatch } = this.props;
            dispatch(
              getQuotation(
                0,
                this.state.id,
                this.state.rootInfoEqual,
                "",
                "",
                "",
                "",
                "",
                "",
                ""
              )
            );
          }
        } else {
          this.setState({
            sectionList: [],
          });
        }
      }

      if (prevProps.pdf != this.props.pdf) {
        // this.setState({
        //   pdffile:this.props.pdf.data,
        //   pdfdo:true
        // })
        const url = window.URL.createObjectURL(new Blob([this.props.pdf]));
        // console.log("url");
        //console.log(url);
        const link = document.createElement("a");
        //   // window.open(link, "_blank");
        link.href = url;
        //console.log(link);
        link.setAttribute("download", "file.pdf");
        document.body.appendChild(link);
        link.click();
        window.open(`${url}`);
        //  window.open(url, "_blank");
      }

      if (prevProps.customer_details != this.props.customer_details) {
        if (this.props.customer_details.status) {
          let json = this.props.customer_details.data_array;
          let cOptionArray = [];
          for (let index1 = 0; index1 < json.length; index1++) {
            const element = json[index1];
            cOptionArray.push(
              <Option
                key={index1.toString(36) + index1 + "cust"}
                value={element.code}>
                {element.familyName} {element.firstName}
              </Option>
            );
          }
          this.setState({
            cName: cOptionArray,
            customer_details: json,
          });
        }
      }

      if (prevProps.all_sales_persons != this.props.all_sales_persons) {
        try {
          if (this.props.all_sales_persons.status) {
            this.setState({
              allSalesPersons: this.props.all_sales_persons.data_array,
            });
            //const { dispatch } = this.props;
            //dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,this.props.all_sales_persons.data_array[0].empId));
          }
          this.setState({
            tableLoading: false,
          });
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dispatch } = this.props;
        // let userdata=values;
      }
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    // console.log(sorter);
    this.setState({
      tableLoading: true,
    });
    this.props.form.validateFields((err, values) => {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
        pagination: pager,
      });
      const { dispatch } = this.props;
      let fromDate = "";
      let toDate = "";
      if (values.date != undefined) {
        if (values.date.length > 0) {
          // fromDate=values.date[0].toISOString();
          // toDate=values.date[1].toISOString();
          let fromDate1 = moment(values.date[0])
            .utc()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
          let toDate1 = moment(values.date[1])
            .utc()
            .set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
          fromDate = fromDate1.toISOString();
          toDate = toDate1.toISOString();
        }
      }
      this.setState({
        fromDate,
        toDate,
      });

      if (values.rootid == this.state.id) {
        dispatch(
          getQuotation(
            pager.current - 1,
            values.rootid,
            this.state.rootInfoEqual,
            fromDate,
            toDate,
            values._issuedBy,
            values.customers,
            sorter.columnKey,
            sorter.order,
            this.state.search
          )
        );
      } else {
        dispatch(
          getQuotation(
            pager.current - 1,
            values.rootid,
            this.state.rootInfo,
            fromDate,
            toDate,
            values._issuedBy,
            values.customers,
            sorter.columnKey,
            sorter.order,
            this.state.search
          )
        );
      }
    });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
    const { dispatch } = this.props;
    dispatch(getExportQuotation(0, 100));
  };

  onChangeBranchRadio = (e) => {
    // this.setState({
    //   //tableLoading:true,
    //   selected_id:e
    // })
    // const { dispatch } = this.props;
    // if(e==this.state.id)
    // {
    //   dispatch(getQuotation(0, e, this.state.rootInfoEqual));
    // }else{
    //   dispatch(getQuotation(0, e, this.state.rootInfo));
    // }
  };

  btnFiltter = () => {
    this.setState({
      tableLoading: true,
    });

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const { dispatch } = this.props;
      const pager = { ...this.state.pagination };
      pager.current = 1;
      pager.pageSize = 10;

      this.setState({
        pagination: pager,
        table_loading: true,
        pageSizelimit: 10,
        currantPage: pager.current - 1,
      });
      // let date=values.date;
      // if(date===undefined || date=== null){
      //   date=""
      // }else
      // {
      //  date=moment(values.date).format("YYYY-MM-DD")
      // }
      let fromDate = "";
      let toDate = "";
      if (values.date != undefined) {
        if (values.date.length > 0) {
          // fromDate=values.date[0].toISOString();
          // toDate=values.date[1].toISOString();
          let fromDate1 = moment(values.date[0])
            .utc()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
          let toDate1 = moment(values.date[1])
            .utc()
            .set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
          fromDate = fromDate1.toISOString();
          toDate = toDate1.toISOString();
        }
      }
      this.setState({
        fromDate,
        toDate,
      });
      let issuedBy = values._issuedBy;
      let customers = values.customers;
      if (issuedBy === undefined) {
        issuedBy = "";
      }
      if (customers === undefined) {
        customers = "";
      }
      if (values.rootid == this.state.id) {
        dispatch(
          getQuotation(
            0,
            values.rootid,
            this.state.rootInfoEqual,
            fromDate,
            toDate,
            issuedBy,
            customers,
            "",
            "",
            this.state.search
          )
        );
      } else {
        dispatch(
          getQuotation(
            0,
            values.rootid,
            this.state.rootInfo,
            fromDate,
            toDate,
            issuedBy,
            customers,
            "",
            "",
            this.state.search
          )
        );
      }
    });
  };

  searchOnchange = (e) => {
    this.setState({ search: e.target.value, btnsearch: false });
    if (e.target.value.length == 0) {
      this.setState({
        tableLoading: true,
        btnsearch: true,
      });
      this.props.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        const { dispatch } = this.props;
        let fromDate = "";
        let toDate = "";
        if (values.date != undefined) {
          if (values.date.length > 0) {
            // fromDate=values.date[0].toISOString();
            // toDate=values.date[1].toISOString();
            let fromDate1 = moment(values.date[0])
              .utc()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            let toDate1 = moment(values.date[1])
              .utc()
              .set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
            fromDate = fromDate1.toISOString();
            toDate = toDate1.toISOString();
          }
        }
        this.setState({
          fromDate,
          toDate,
        });
        let issuedBy = values._issuedBy;
        let customers = values.customers;
        if (issuedBy === undefined) {
          issuedBy = "";
        }
        if (customers === undefined) {
          customers = "";
        }
        this.setState({
          tableLoading: true,
        });

        if (values.rootid == this.state.id) {
          dispatch(
            getQuotation(
              0,
              values.rootid,
              this.state.rootInfoEqual,
              fromDate,
              toDate,
              issuedBy,
              customers,
              "",
              "",
              ""
            )
          );
        } else {
          dispatch(
            getQuotation(
              0,
              values.rootid,
              this.state.rootInfo,
              fromDate,
              toDate,
              issuedBy,
              customers,
              "",
              "",
              ""
            )
          );
        }
      });
    }
  };

  onSerach = (e) => {
    //this.setState({search: e.target.value})
    if (this.state.search.length > 1) {
      this.props.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        const { dispatch } = this.props;
        let fromDate = "";
        let toDate = "";
        if (values.date != undefined) {
          if (values.date.length > 0) {
            // fromDate=values.date[0].toISOString();
            // toDate=values.date[1].toISOString();
            let fromDate1 = moment(values.date[0])
              .utc()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            let toDate1 = moment(values.date[1])
              .utc()
              .set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
            fromDate = fromDate1.toISOString();
            toDate = toDate1.toISOString();
          }
        }
        this.setState({
          fromDate,
          toDate,
        });
        let issuedBy = values._issuedBy;
        let customers = values.customers;
        if (issuedBy === undefined) {
          issuedBy = "";
        }
        if (customers === undefined) {
          customers = "";
        }
        this.setState({
          tableLoading: true,
        });

        if (values.rootid == this.state.id) {
          dispatch(
            getQuotation(
              0,
              values.rootid,
              this.state.rootInfoEqual,
              fromDate,
              toDate,
              issuedBy,
              customers,
              "",
              "",
              this.state.search
            )
          );
        } else {
          dispatch(
            getQuotation(
              0,
              values.rootid,
              this.state.rootInfo,
              fromDate,
              toDate,
              issuedBy,
              customers,
              "",
              "",
              this.state.search
            )
          );
        }
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      loading: true,
      dataforExport: [],
    });
  };

  openPdf = (record) => {
    const { dispatch } = this.props;
    window.open(record);
    // this.setState({
    //   visiblepdf:true
    // })
    // dispatch(downloadPdf("quote",record));
  };

  visiblepdfhandleOk = (e) => {
    this.setState({
      visiblepdf: false,
    });
  };

  handleChange = (value) => {
    let data = this.state.customer_details;
    data = data.filter((row) => {
      return (
        row.firstName.toUpperCase().includes(value.toUpperCase()) ||
        String(row.familyName.name)
          .toUpperCase()
          .includes(value.toUpperCase())
      );
    });

    let cOptionArray = [];
    let json = data;
    for (let index1 = 0; index1 < json.length; index1++) {
      const element = json[index1];
      cOptionArray.push(
        <Option
          key={index1.toString(36) + index1 + "cust"}
          value={element.code}>
          {element.firstName} {element.familyName}
        </Option>
      );
    }
    this.setState({
      cName: cOptionArray,
    });
  };

  onClearFiltter = (e) => {
    this.setState({
      tableLoading: true,
      search: "",
    });
    const { dispatch } = this.props;
    dispatch(
      getQuotation(
        0,
        this.state.id,
        this.state.rootInfoEqual,
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      )
    );
    this.props.form.resetFields();
  };

  render() {
    var heightd = window.innerHeight - 480;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    const { dataforExport } = this.state;
    const fileGenerate = dataforExport.map(function(fname, index) {
      try {
        let fnamefile = fname.quoteId;
        fnamefile = fnamefile.substring(0, fnamefile.lastIndexOf("/"));
        fnamefile = fnamefile.replace(/[/]/g, "_");
        let persheetArray = fname.skuItemsIssue;
        var dataForSheet = [];
        for (let i = 0; i < persheetArray.length; i++) {
          let secondArray = [];
          if (persheetArray.length >= 1) {
            secondArray.push(persheetArray[i].skuItem.sku_number);
            secondArray.push(persheetArray[i].skuItem.design_code);
            secondArray.push(persheetArray[i].skuItem.design_color);
            secondArray.push(persheetArray[i].skuItem.diamond_quantity);
            secondArray.push(persheetArray[i].skuItem.color_stone_weight);
            secondArray.push(persheetArray[i].skuItem.net_weight);
            secondArray.push(persheetArray[i].skuItem.gross_weight);
            secondArray.push(persheetArray[i].skuItem.purity);
            secondArray.push(persheetArray[i].skuItem.labour_value);
            secondArray.push(persheetArray[i].skuItem.sales_value);
            dataForSheet.push(secondArray);
          } else {
            dataForSheet.push(persheetArray[i].skuItem.sku_number);
            dataForSheet.push(persheetArray[i].skuItem.design_code);
            dataForSheet.push(persheetArray[i].skuItem.design_color);
            dataForSheet.push(persheetArray[i].skuItem.diamond_quantity);
            dataForSheet.push(persheetArray[i].skuItem.color_stone_weight);
            secondArray.push(persheetArray[i].skuItem.net_weight);
            dataForSheet.push(persheetArray[i].skuItem.gross_weight);
            dataForSheet.push(persheetArray[i].skuItem.purity);
            dataForSheet.push(persheetArray[i].skuItem.labour_value);
            dataForSheet.push(persheetArray[i].skuItem.sales_value);
          }
        }
        let customerName = [];
        let companyName = [];
        let Description = [];
        let QuatationId = [];
        let quart_name = fname.quoteId;
        quart_name = quart_name.substring(0, quart_name.lastIndexOf("/"));
        QuatationId.push(quart_name);
        companyName.push(fname.customer.name);
        companyName.push("");
        companyName.push("");
        companyName.push("");
        companyName.push(fname.customer.companyName);
        companyName.push("");
        companyName.push("");
        companyName.push("");
        companyName.push("");
        companyName.push(moment(fname.created_at).format("DD-MM-YYYY"));
        Description.push(fname.description);

        let datasetFor1 = [
          {
            xSteps: 4,
            ySteps: 0,
            columns: ["Quotation ID"],
            data: [QuatationId],
          },
          {
            xSteps: 0,
            ySteps: 1,
            columns: [
              { title: "Customer Name", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "Company Name", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "", width: { wpx: 120 } },
              { title: "Date", width: { wpx: 120 } },
            ],
            data: [companyName],
          },
          // {
          //   xSteps: 1,
          //   ySteps: 1,
          //   columns: [{title: "Description", width: {wpx: 120}}],
          //   data:[Description]
          // },
          {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            // wpx: 180,
            columns: [
              { title: "SKU Number", width: { wpx: 120 } },
              { title: "Design Code", width: { wpx: 120 } },
              { title: "Design Color", width: { wpx: 120 } },
              { title: "Diamond QTY", width: { wpx: 120 } },
              { title: "Color Stone Wt", width: { wpx: 140 } },
              { title: "Gross Wt", width: { wpx: 120 } },
              { title: "Net Wt", width: { wpx: 120 } },
              { title: "Purity", width: { wpx: 120 } },
              { title: "Labour Value", width: { wpx: 120 } },
              { title: "Sales Value", width: { wpx: 120 } },
            ],
            data: dataForSheet,
          },
        ];
        return (
          <ExcelSheet
            key={index}
            //data={fname.skuItemsIssue}
            name={`${fnamefile}`}
            dataSet={datasetFor1}
          />
        );
      } catch (error) {}
    });
    let data = this.state.data;
    this.columns = [
      {
        title: "QUOTE ID",
        dataIndex: "quoteId",
        //defaultSortOrder:"descend",
        width: "15%",
        sorter: (a, b) => {},

        render: (text, record) => {
          const { finger_index } = record;
          let textprint = "";
          try {
            var s = text;
            let lastlength = s.substring(0, s.lastIndexOf("/"));
            let lasti = s.lastIndexOf("/");
            let ans = s.substring(lasti + 1);
            if (isNaN(ans)) {
              textprint = lastlength;
            } else {
              textprint = text;
            }
          } catch (error) {
            //   console.log(error);
          }
          return <span>{textprint}</span>;
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "customer.familyName",
        width: "20%",
        sorter: (a, b) => {},
        //  width: "10%",
        render: (text, record) => {
          let { customer } = record;
          let { firstName, familyName } = customer;
          if (familyName === undefined) {
            familyName = "";
          }
          if (firstName === undefined) {
            firstName = "";
          }
          return <span>{familyName + " " + firstName}</span>;
        },
      },
      // {
      //   title: 'Discount',
      //   dataIndex: 'discount',
      //   width: '10%',
      //   render: (text, record) => {
      //     const { customer } = record;
      //     return (
      //       <span>
      //           {text[0]}
      //       </span>
      //     );
      //   }
      // },
      {
        title: "QUOTATION DATE",
        dataIndex: "createdAt",
        sorter: (a, b) => {},
        width: "15%",
        render: (text, record) => {
          // const { customer,created_at } = record;
          // var datef=moment(text).utc(false).format("DD-MM-YYYY hh:mm A");
          // var datef1=moment(text).format("DD-MM-YYYY hh:mm A");
          // var datef2=moment(created_at).utc(false).format("DD-MM-YYYY hh:mm A");
          // var datef3=moment(created_at).format("DD-MM-YYYY hh:mm A");

          // return <span> UTC:-{datef}  || Local {datef1} || created_at UTC : {datef2} || created_at Local : {datef3} </span>;
          return <span>{moment(text).format("DD-MM-YYYY")}</span>;
        },
      },
      {
        title: "ISSUED BY",
        dataIndex: "empName",
        width: "20%",
        sorter: (a, b) => {},
        render: (text, record) => {
          // let name = text.split(',');
          // if (name.length > 1) {
          //   return <span>{name[1]}</span>;
          // } else {
          return <span>{text}</span>;
          // }
        },
      },
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        sorter: (a, b) => {},
        width: "20%",
        render: (text, record) => {
          const { customer } = record;
          return <span>{text}</span>;
        },
      },

        {
            title: "ACTION",
            dataIndex: "skuItemsIssue",
            //   width: "10%",
            render: (text, record) => {
                let {
                    quoteId,
                    created_at,
                    customer = {},
                    description,
                    pdfPath,
                    _id,
                    summary,
                } = record;
                try {
                    var s = quoteId;

                    s = s.substring(0, s.lastIndexOf("/"));
                } catch (error) {
                    console.log(error);
                }

                // let quoteId = quoteId.substring(0, quoteId.lastIndexOf('/'));
                const qtyw = "Quote ID : " + s;
                const datef = moment(created_at).format("DD-MM-YYYY");
                let {
                    firstName = "",
                    familyName = "",
                    email = "",
                    contactNumber = "",
                } = customer;
                if (familyName === undefined && firstName === undefined) {
                    familyName = "";
                    firstName = "";
                } else if (familyName === undefined) {
                    familyName = "";
                } else if (firstName === undefined) {
                    firstName = "";
                }
                if (pdfPath === "") {
                    pdfPath = undefined;
                }
                return (
                    <span style={{ display: "inline" }}>
                        <ViewQuatationDetails
                            modeltitle={qtyw}
                            created_at={datef}
                            customername={familyName + " " + firstName}
                            customeremail={email}
                            customermobno={contactNumber}
                            description={description}
                            skuitemsissue={text}
                            summary={summary}
                        />
                        {pdfPath != undefined ? (
                            <Icon
                                type="file-pdf"
                                onClick={() => this.openPdf(pdfPath)}
                                style={{
                                    cursor: "pointer",
                                    fontSize: 20,
                                    paddingLeft: "10px",
                                }}
                            />
                        ) : (
                            <Icon
                                type="file-pdf"
                                style={{
                                    cursor: "no-drop",
                                    fontSize: 20,
                                    paddingLeft: "10px",
                                }}
                                disabled={true}
                            />
                        )}
                        {/**<Popconfirm
              title="Do you want to re-create PDF?"
              onConfirm={() => this.openEditPdf(_id)}
             // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Icon type="sync"
              // onClick={() => this.openEditPdf(_id)} 
              style={{ cursor: 'pointer', fontSize: 20, paddingLeft: "10px" }} />
            </Popconfirm>*/}
                    </span>
                );
            },
        },
    ];

    // if (this.state.search) {
    //   data = data.filter(row => {
    //     let created_at_formate = moment(row.created_at).format("DD-MM-YYYY");
    //     //console.log("row.discount_count");
    //     //console.log(row.discount);
    //     //let discount_count=row.discount_count;
    //     return (
    //       row.quoteId.toUpperCase().includes(this.state.search.toUpperCase()) ||
    //       String(row.customer.name.toUpperCase()).includes(
    //         this.state.search.toUpperCase()
    //       ) ||
    //       String(created_at_formate.toUpperCase()).includes(
    //         this.state.search.toUpperCase()
    //       ) ||
    //       String(row._issueBy.toUpperCase()).includes(
    //         this.state.search.toUpperCase()
    //       ) ||
    //       String(row.description.toUpperCase()).includes(
    //         this.state.search.toUpperCase()
    //       )
    //     );
    //     //row.name.toUpperCase().includes(this.state.search.toUpperCase())
    //   });
    // }
    const formItemLayout = {
      //labelCol: { span: 24 },
      //   labalAlign:{marginLeft: "15px"},
      //wrapperCol: { span: 24 },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    return (
      <div>
        {/*  <Modal
              visible={this.state.visiblepdf}
             //title={this.props.modeltitle}
              onOk={this.visiblepdfhandleOk}
              onCancel={this.visiblepdfhandleCancel}
              //footer={null}
              width={1150}
              style={{paddingBottom:"166px"}}
            >
            {this.state.pdfdo===true?
            <ExamplePDFViewer data={this.state.pdffile}/>:""}
           {/* <div className='control-section'>
             Render the PDF Viewer 
            <PdfViewerComponent id="container" documentPath={'https://arxiv.org/pdf/quant-ph/0410100.pdf'} serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer" style={{ 'height': '640px' }}>
                <Inject services={[Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation]} />
            </PdfViewerComponent>
          </div>

            
      </Modal> */}
        <Card
          className='transaction-top-menu-card'
          bodyStyle={{ padding: "0" }}
          bordered={false}>
          <Row>
            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}
              style={{
                textAlign: "center",
              }}>
              <p className='transaction-title'>Transactions</p>
              <h1 className='transaction-sub-title'>Quotation</h1>
            </Col>

            <Col lg={12} md={12} sm={24} xs={24}>
              <Divider
                type='vertical'
                className='vertical-divider-transaction'
              />
              <h1 className='total-text'>
                <CountUp start={0} end={this.state.totalQuatation} />{" "}
              </h1>
            </Col>

            <Col lg={8} md={8} sm={24} xs={24}>
              <p
                style={{ marginBottom: "5px" }}
                className='transaction-serach-text text-color-8c8c8c'>
                Search
              </p>
              <Input
                value={this.state.search}
                placeholder='-- Search Voucher'
                style={{ float: "left", width: "70%" }}
                //onChange={e => this.setState({ search: e.target.value })}
                onChange={this.searchOnchange}
              />
              <Button
                onClick={this.onSerach}
                style={{ marginLeft: "11px" }}
                type='primary'
                disabled={this.state.btnsearch}>
                Search
              </Button>
            </Col>
          </Row>
        </Card>
        <Form hideRequiredMark={true} colon={false} labelAlign={"left"}>
          <Card
            className='filtter-card'
            bordered={false}
            bodyStyle={{
              padding: "10px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}>
            <Row>
              <Col lg={2} md={2} sm={24} xs={24} className='text-aligin-center'>
                {/**   <Icon style={{ fontSize: '34px', color: '#aaaebe', paddingTop: "15px" }} type="filter" theme="filled" />*/}
                <img src={FilterIcon} className='filter-icon' />
              </Col>
              <Col lg={5} md={5} sm={24} xs={24}>
                <Form.Item label={`Date`} {...formItemLayout}>
                  {getFieldDecorator(`date`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    //<DatePicker style={{ minWidth: "100%" }} placeholder="- - Select - -" />
                    <RangePicker
                      format='DD-MM-YYYY'
                      placeholder={["From ", "To"]}
                      separator='-'
                      style={{ minWidth: "100%" }}
                      allowClear={false}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={4} md={4} sm={24} xs={24}>
                {this.state.rootInfo === "branch" &&
                this.state.branchId != "" ? (
                  <Form.Item
                    label={this.state.textForSelect}
                    {...formItemLayout}>
                    {getFieldDecorator(`rootid`, {
                      initialValue: this.state.id,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        placeholder='Please select branch'
                        onChange={this.onChangeBranchRadio}
                        style={{ minWidth: "100%" }}>
                        <Option key={this.state.id} value={this.state.id}>
                          All Branch
                        </Option>
                        {this.state.branchList.map((data, index) => (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  ""
                )}

                {this.state.rootInfo === "floor" && this.state.floorId != "" ? (
                  <Form.Item
                    label={this.state.textForSelect}
                    {...formItemLayout}>
                    {getFieldDecorator(`rootid`, {
                      initialValue: this.state.id,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        placeholder='Please select floor'
                        onChange={this.onChangeBranchRadio}
                        style={{ minWidth: "100%" }}>
                        <Option key={this.state.id} value={this.state.id}>
                          All Floor
                        </Option>
                        {this.state.floorList.map((data, index) => (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  ""
                )}

                {this.state.rootInfo === "section" &&
                this.state.sectionId != "" ? (
                  <Form.Item
                    label={this.state.textForSelect}
                    {...formItemLayout}>
                    {getFieldDecorator(`rootid`, {
                      initialValue: this.state.id,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        placeholder='Please select section'
                        onChange={this.onChangeBranchRadio}
                        style={{ minWidth: "100%" }}>
                        <Option key={this.state.id} value={this.state.id}>
                          All section
                        </Option>
                        {this.state.sectionList.map((data, index) => (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  ""
                )}
              </Col>

              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label='Employee' {...formItemLayout}>
                  {getFieldDecorator(`_issuedBy`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      placeholder='- - Select - -'
                      style={{ minWidth: "100%" }}>
                      {this.state.allSalesPersons.map((user) => (
                        <Option key={user._id.empId} value={user._id.empId}>
                          {user._id.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label={`Customer`} {...formItemLayout}>
                  {getFieldDecorator(`customers`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={false}
                      placeholder='- - Select - -'
                      style={{ minWidth: "100%" }}
                      onSearch={this.handleChange}
                      // filterOption={(input, option) =>
                      //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      // }
                    >
                      {this.state.cName}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col lg={1} md={1} sm={24} xs={24}></Col>
              <Col lg={4} md={4} sm={24} xs={24}>
                <div
                  style={{
                    marginTop: "31px",
                    paddingRight: "9px",
                    float: "right",
                  }}>
                  <Button
                    style={{ float: "left" }}
                    onClick={this.btnFiltter}
                    type='primary'>
                    Filter
                  </Button>
                  <Button
                    onClick={this.onClearFiltter}
                    className='transaction-clear-btn'
                    ghost>
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Form>
        {/*
        <h2 className="title gx-mb-4">
          <IntlMessages id="sidebar.quotationPage" />
        </h2>
         <Row>
      <Col md={24} sm={24} xs={24}>
      <Form
      style={{  
       // marginLeft:"10px",
        marginBottom:"8px",
        paddingTop:"20px",
        paddingBottom:"10px",
       // paddingRight : "20px",
        background: "#fbfbfb",
        border: "1px solid #d9d9d9",
        borderRadius: "6px"}}
          //className="ant-advanced-search-form"
          onSubmit={this.handleSearch}
        >
        <Row>
            <Col md={1} ></Col>
            <Col md={10}>            
                <FormItem
                      {...formItemLayout}
                      label={<IntlMessages id="sidebar.quotationPselectcustomer"/>}
                    >
                      {getFieldDecorator('customer', 
                      {
                        rules: [{ required: false, message: 'Please select customer!' }],
                      }
                      )(
                        <Select
                            mode="multiple"
                          // style={{ width: '100%' }}
                            placeholder={<IntlMessages id="sidebar.quotationPselectcustomer"/>}
                            
                          >
                            {CustomerList}
                          </Select>
                      )}                            
                </FormItem>
            </Col>
           
              <Col md={10} >
                    <FormItem
                    {...formItemLayout}
                    label="Date"
                  >
                    {getFieldDecorator('date', 
                    {
                      rules: [{ type: 'array', required: false, message: 'Please select date!' }],
                    }
                    )(
                      <RangePicker showTime format="DD-MM-YYYY HH:mm:ss" />
                    )}
                </FormItem>
            
              </Col>
              <Col md={1} ></Col>                    
            
          </Row> 
             <Row >
              <Col md={1} ></Col>
              <Col md={10} >
                          <FormItem
                          {...formItemLayout}
                          label="Select Sales Person"
                        >
                          {getFieldDecorator('sales_person', 
                          {
                            rules: [{ required: false, message: 'Please Select Sales Person' }],
                          }
                          )(
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Select Sales Person"
                                // defaultValue={['a10', 'c12']}
                              //  onChange={handleChange}
                              >
                              <Option key="rajesh">Rajesh</Option >
                              </Select>
                          )}                            
                    </FormItem>
                   
              </Col> 
              <Col md={2} ></Col>
              <Col md={9}>
                  <div style={{float:"right"}}>
                    <Button style={{width:"90px"}} type="primary" htmlType="submit">Search</Button>
                    <Button style={{ marginLeft: 8 ,width:"90px"}} onClick={this.handleReset}>
                      Clear
                    </Button> 
                  </div> 
              </Col>   
              <Col md={2}></Col>              
            </Row>  
                </Form>              
            </Col>
      </Row> */}
        {/**/}{" "}
        <Row>
          <Col md={24} sm={24} xs={24}>
            {/**
        <div style={{ marginBottom: "8px" }}>
          <div className="certain-category-search-wrapper">
            <Input
              value={this.state.search}
              placeholder="Search .."
              style={{ float: "left", width: "95%" }}
              onChange={e => this.setState({ search: e.target.value })}
              suffix={<Icon type="search" className="certain-category-icon" />}
            />
          </div>
          <center>
            <Icon
              type="cloud-download"
              onClick={this.showModal}
              style={{ fontSize: "27px", cursor: "pointer" }}
            />
          </center>
        </div>
*/}
            <Card
              style={{ marginBottom: "0px" }}
              bodyStyle={{ padding: "10px 10px", paddingBottom: "0px" }}
              bordered={false}>
              <Table
                className='gx-table-responsive'
                loading={this.state.tableLoading}
                columns={this.columns}
                dataSource={data}
                size={"small"}
                scroll={{ y: heightd }}
                rowKey={(record) => record._id}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                sortDirections={sortDirections}
              />
            </Card>
          </Col>
        </Row>
        {/**Export data  */}
        <Modal
          loading
          title={
            loading
              ? `Data is processing please wait ...`
              : `Quatation File Created`
          }
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[
            <Button key='back' onClick={this.handleCancel}>
              Cancel
            </Button>,
            <ExcelFile
              key='tesingkey'
              filename={`Quatation Details - ${new Date()}`}
              element={
                loading ? (
                  <Button
                    key='submit'
                    type='primary'
                    onClick={this.handleOk}
                    disabled>
                    Wait..{" "}
                  </Button>
                ) : (
                  <Button key='submit' type='primary' onClick={this.handleOk}>
                    {" "}
                    Download
                  </Button>
                )
              }>
              {fileGenerate}
              {/**   <ExcelSheet data={this.state.dataforExport} name="Qatation">
                  <ExcelColumn label="Name" value="name"/>
                  <ExcelColumn label="Contact Number" value="contactNumber"/>
                  <ExcelColumn label="E-mail" value="email"/>
                  <ExcelColumn label="GST" value="gst"/>
                  <ExcelColumn label="Company Name" value="companyName"/>
                  <ExcelColumn label="Pan No" value="pan"/>
                  <ExcelColumn label="Address" value="address"/>                          
          </ExcelSheet>*/}
            </ExcelFile>,
          ]}>
          {loading ? (
            <div
              style={{ minHeight: "150px", backgroundImage: `url(${Gears})` }}
            />
          ) : (
            <Alert
              message='Success'
              description='Quatation file is ready you can download.'
              type='success'
              showIcon
            />
          )}
        </Modal>
      </div>
    );
  }
}

quotation = Form.create()(quotation);
function mapStateToProps(state) {
  const { Common, Dataexport } = state;
  const {
    quotation_details: quotation_details,
    customer_details: customer_details,
    all_sales_persons: all_sales_persons,
    pdf: pdf,
    branch_list: branch_list,
    floor_list: floor_list,
    section_list: section_list,
  } = Common;
  const { export_quotation_details: export_quotation_details } = Dataexport;
  return {
    quotation_details,
    customer_details,
    all_sales_persons,
    pdf,
    export_quotation_details,
    branch_list,
    floor_list,
    section_list,
  };
}

const AppContainer = connect(mapStateToProps)(quotation);

export default AppContainer;
