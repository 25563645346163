import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import {
    getCustomer,
    downloadPdf,
    getAllSalesPerson,
    getMemos,
    getAllBrachList,
    getAllSectionList,
    getAllFloorList,
} from "../../../actions/index";
import AddIssue from "./add";
import ExportIcon from "./../../../assets/images/icons/export.svg";
import AddCustomerImg from "./../../../assets/images/icons/add-customer.svg";
import { getExportSale } from "../../../actions/dataexport";
import moment from "moment";
//import SalesDetailsView from "./viewSalesDetails";
import {
    Card,
    Table,
    Form,
    Row,
    Col,
    DatePicker,
    Select,
    Alert,
    Button,
    Input,
    Icon,
    Modal,
    Radio,
    Divider,
} from "antd";
import ReactExport from "react-data-export";
import Gears from "../../../assets/images/gears.gif";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import socket from "./../../../containers/App/global";
import CountUp from "react-countup";
const { RangePicker } = DatePicker;

const cookies = require("browser-cookies");
const Option = Select.Option;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Issue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            table_loading: true,
            totalData: 0,
            search: "",
            visible: false,
            dataforExport: [],
            branch_list: [],
            textForSelect: "",
            section_list: [],
            floor_list: [],
            floor_id: "",
            branch_id: "",
            section_id: "",
            allSalesPersons: [],
            createIssueVisible: false,
            btnsearch: true,
        };
    }
    componentDidMount() {
        const { dispatch } = this.props;
        //dispatch(getCustomer());
        let usertype = localStorage.getItem("type_user");
        let root = JSON.parse(cookies.get("root"));
        let page_no = 0;
        let limit = 10;
        dispatch(getCustomer(page_no, limit));
        if (usertype === "branchAdmin") {
            this.setState({
                rootInfo: "floor",
                rootInfoEqual: "branch",
                textForSelect: "Select Floor",
                id: root.branchId,
                selectedId: root.branchId,
                selectedRootInfoEqual: "branch",
            });
            dispatch(getAllFloorList("id"));
            dispatch(getAllSalesPerson("branch", root.branchId));
        }
        if (usertype === "companyAdmin") {
            this.setState({
                rootInfo: "branch",
                rootInfoEqual: "company",
                textForSelect: "Select Branch",
                id: root.companyId,
                selectedId: root.companyId,
                selectedRootInfoEqual: "company",
            });
            dispatch(getAllBrachList("id"));
            dispatch(getAllSalesPerson("company", root.companyId));
        }
        if (usertype === "floorAdmin") {
            this.setState({
                rootInfo: "section",
                rootInfoEqual: "floor",
                textForSelect: "Select Section",
                id: root.floorId,
                selectedId: root.floorId,
                selectedRootInfoEqual: "floor",
            });
            dispatch(getAllSectionList("id"));
            dispatch(getAllSalesPerson("floor", root.floorId));
        }

        socket.on("Notification", (col) => {
            try {
                if (col.data_code === "201") {
                    let newData = col.data_obj;
                    let Data = this.state.data;
                    let skuitemarray = newData.skuItemsIssue;
                    for (let index = 0; index < skuitemarray.length; index++) {
                        newData.skuItemsIssue[index].uniqeId = index + 1;
                    }
                    Data.unshift(newData);
                    let pagination = {};
                    pagination.total = this.state.pagination.total + 1;
                    this.setState({
                        data: Data,
                        totalData: this.state.totalData + 1,
                        pagination,
                    });
                }
            } catch (e) {
                console.log("error", e);
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.memo_details != this.props.memo_details) {
            // console.log("this.props.memo_details");
            // console.log(this.props.memo_details);
            if (this.props.memo_details.status) {
                let json = this.props.memo_details.data_array;
                for (let index = 0; index < json.length; index++) {
                    for (
                        let index1 = 0;
                        index1 < json[index].skuItemsIssue.length;
                        index1++
                    ) {
                        const element = json[index].skuItemsIssue[index1];
                        json[index].skuItemsIssue[index1].uniqeId = index1 + 1;
                    }
                }
                json.map((j) => {
                    return j.skuItemsIssue.map((sku) => {
                        return (sku.memo_status = "Pending");
                    });
                });

                if (json.map((j) => j.skuItemsReceived.length > 0)) {
                    json.map((d) => {
                        return d.skuItemsIssue.filter((el) => {
                            return d.skuItemsReceived.some((f) => {
                                if (f.sku_number === el.sku_number) {
                                    return (el.memo_status = "Received");
                                }
                            });
                        });
                    });
                }

                let pagination = {};
                pagination.total = this.props.memo_details.data.totalMemo;
                pagination.pageSize = 10;
                this.setState({
                    data: json,
                    pagination,
                    totalData: this.props.memo_details.data.totalMemo,
                });
                // console.log("json", json);
                // console.log(json);
            }
            this.setState({
                table_loading: false,
            });
        }

        try {
            if (prevProps.branch_list != this.props.branch_list) {
                if (this.props.branch_list.status) {
                    this.setState({
                        branch_id: this.props.branch_list.data_array[0]._id,
                        branch_list: this.props.branch_list.data_array,
                    });
                    if (this.props.branch_list.data_array.length >= 1) {
                        const { dispatch } = this.props;
                        let page_no = 0;
                        dispatch(
                            getMemos(
                                page_no,
                                this.state.id,
                                this.state.rootInfoEqual,
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                ""
                            )
                        );
                        //dispatch(getSKUByRootInfoAndId(this.props.branch_list.data_array[0]._id,this.state.rootInfo));
                    }
                } else {
                    this.setState({
                        branch_list: [],
                    });
                }
            }

            if (prevProps.floor_list != this.props.floor_list) {
                // console.log("this.props.floor_list");
                // console.log(this.props.floor_list);
                if (this.props.floor_list.status) {
                    this.setState({
                        floor_id: this.props.floor_list.data_array[0]._id,
                        floor_list: this.props.floor_list.data_array,
                    });
                    if (this.props.floor_list.data_array.length >= 1) {
                        const { dispatch } = this.props;
                        dispatch(
                            getMemos(
                                0,
                                this.state.id,
                                this.state.rootInfoEqual,
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                ""
                            )
                        );
                    }
                } else {
                    this.setState({
                        floor_list: [],
                    });
                }
            }

            if (prevProps.section_list != this.props.section_list) {
                //console.log("this.props.section_list");
                // console.log(this.props.section_list);
                if (this.props.section_list.status) {
                    this.setState({
                        section_id: this.props.section_list.data_array[0]._id,
                        section_list: this.props.section_list.data_array,
                    });
                    if (this.props.section_list.data_array.length >= 1) {
                        const { dispatch } = this.props;
                        dispatch(
                            getMemos(
                                0,
                                this.state.id,
                                this.state.rootInfoEqual,
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                ""
                            )
                        );
                    }
                } else {
                    this.setState({
                        section_list: [],
                    });
                }
            }

            if (prevProps.customer_details != this.props.customer_details) {
                if (this.props.customer_details.status) {
                    let json = this.props.customer_details.data_array;
                    let cOptionArray = [];
                    for (let index1 = 0; index1 < json.length; index1++) {
                        const element = json[index1];
                        cOptionArray.push(
                            <Option
                                key={index1.toString(36) + index1 + "cust"}
                                value={element.code}>
                                {element.firstName} {element.familyName}
                            </Option>
                        );
                    }
                    this.setState({
                        cName: cOptionArray,
                    });
                }
            }

            if (prevProps.all_sales_persons != this.props.all_sales_persons) {
                try {
                    if (this.props.all_sales_persons.status) {
                        this.setState({
                            allSalesPersons: this.props.all_sales_persons.data_array,
                        });
                        //const { dispatch } = this.props;
                        //dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,this.props.all_sales_persons.data_array[0].empId));
                    }
                    this.setState({
                        table_loading: false,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
        }

        if (prevProps.export_sales_details != this.props.export_sales_details) {
            // console.log("this.state.dataforExport");
            // console.log(this.state.dataforExport);
            //console.log(this.props.export_sales_details);
            try {
                if (this.props.export_sales_details.status) {
                    let DataForSale = this.props.export_sales_details.data_array;
                    // for (let index = 0; index < json.length; index++) {
                    //   for (
                    //     let index1 = 0;
                    //     index1 < json[index].skuItemsIssue.length;
                    //     index1++
                    //   ) {
                    //     const element = json[index].skuItemsIssue[index1];
                    //     console.log(json);
                    //     json[index].skuItemsIssue[index1].skuItem = JSON.parse(
                    //       json[index].skuItemsIssue[index1].skuItem
                    //     );
                    //     json[index].skuItemsIssue[index1].uniqeId = index1 + 1;
                    //   }
                    // }
                    // console.log(DataForSale);

                    this.setState({
                        dataforExport: this.state.dataforExport.concat(DataForSale),
                        loading: false,
                    });
                    // console.log("this.state.dataforExport");
                    // console.log(this.state.dataforExport);

                    if (
                        this.props.export_sales_details.data.totalSale ==
                        this.state.dataforExport.length
                    ) {
                        this.setState({
                            loading: false,
                        });
                    } else {
                        if (this.props.export_sales_details.data.totalSale > 100) {
                            const { dispatch } = this.props;
                            let page =
                                parseInt(this.props.export_sales_details.data.currentPage) + 1;
                            dispatch(getExportSale(page, 100));
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { dispatch } = this.props;
                // let userdata=values;
            }
        });
    };

    onChangeBranchRadio = (e) => {
        //  this.setState({
        //   table_loading:true
        //  })
        //  const {dispatch} = this.props;
        //   //dispatch(getMemos(0,e,this.state.rootInfo));
        //   if(e==this.state.id)
        //   {
        //     dispatch(getMemos(0, e, this.state.rootInfoEqual));
        //   }else{
        //     dispatch(getMemos(0, e, this.state.rootInfo));
        //   }
    };
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.setState({
            table_loading: true,
        });
        this.props.form.validateFields((err, values) => {
            // console.log(values);

            const pager = { ...this.state.pagination };
            pager.current = pagination.current;
            this.setState({
                pagination: pager,
            });
            const { dispatch } = this.props;
            let date = undefined;
            if (values.date != undefined) {
                date = moment(values.date).format("YYYY-MM-DD");
            }

            if (values.rootid == this.state.id) {
                dispatch(
                    getMemos(
                        pager.current - 1,
                        values.rootid,
                        this.state.rootInfoEqual,
                        date,
                        "",
                        values._issuedBy,
                        values.customers,
                        sorter.columnKey,
                        sorter.order
                    )
                );
            } else {
                dispatch(
                    getMemos(
                        pager.current - 1,
                        values.rootid,
                        this.state.rootInfo,
                        date,
                        "",
                        values._issuedBy,
                        values.customers,
                        sorter.columnKey,
                        sorter.order
                    )
                );
            }
        });
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
        //const { dispatch } = this.props;
        //dispatch(getExportSale(0, 100));
    };

    createIssue = () => {
        this.setState({
            createIssueVisible: true,
        });
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            loading: true,
            dataforExport: [],
        });
    };

    openPdf = (record) => {
        let { _issueBy, root, memoId } = record;
        const { dispatch } = this.props;
        dispatch(downloadPdf("memo", memoId));
        // let username = _issueBy.split(',');
        // let result=window.location.href;
        // let arr = result.split("/");
        // let domainname = arr[0]+"//"+arr[2];
        // let url=`${domainname}/res/${root.companyId}-${username[0]}/${memoId}.pdf`;
        // let win = window.open(url, '_blank');
        // win.focus();
    };

    btnFiltter = () => {
        this.setState({
            table_loading: true,
        });

        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            const { dispatch } = this.props;
            const pager = { ...this.state.pagination };
            pager.current = 1;
            pager.pageSize = 10;

            this.setState({
                pagination: pager,
                table_loading: true,
                pageSizelimit: 10,
                currantPage: pager.current - 1,
            });
            let fromDate = '';
            let toDate = '';
            if (fromDate === undefined || fromDate === null) {
                fromDate = "";
            } else {
                // date = moment(values.date).format("YYYY-MM-DD");
                let date1 = moment(values.date)
                    .utc()
                    .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                    });
                fromDate = date1.toISOString();
                let date2 = moment(values.date)
                    .utc()
                    .set({
                        hour: 23,
                        minute: 59,
                        second: 0,
                        millisecond: 0,
                    });
                toDate = date2.toISOString();
            }
            let issuedBy = values._issuedBy;
            let customers = values.customers;
            if (issuedBy === undefined) {
                issuedBy = "";
            }
            if (customers === undefined) {
                customers = "";
            }
            if (values.rootid == this.state.id) {
                dispatch(
                    getMemos(
                        0,
                        values.rootid,
                        this.state.rootInfoEqual,
                        fromDate,
                        toDate,
                        issuedBy,
                        customers,
                        "",
                        "",
                        this.state.search
                    )
                );
            } else {
                dispatch(
                    getMemos(
                        0,
                        values.rootid,
                        this.state.rootInfo,
                        fromDate,
                        toDate,
                        issuedBy,
                        customers,
                        "",
                        "",
                        this.state.search
                    )
                );
            }
        });
    };

    onClearFiltter = (e) => {
        this.setState({
            table_loading: true,
            search: "",
        });
        const { dispatch } = this.props;
        dispatch(
            getMemos(
                0,
                this.state.id,
                this.state.rootInfoEqual,
                "",
                "",
                "",
                "",
                "",
                "",
                ""
            )
        );
        this.props.form.resetFields();
    };

    searchOnchange = (e) => {
        this.setState({ search: e.target.value, btnsearch: false });
        if (e.target.value.length == 0) {
            this.setState({
                table_loading: true,
                btnsearch: true,
            });
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                const { dispatch } = this.props;
                let date = values.date;
                if (date === undefined || date === null) {
                    date = "";
                } else {
                    date = moment(values.date).format("YYYY-MM-DD");
                }
                let issuedBy = values._issuedBy;
                let customers = values.customers;
                if (issuedBy === undefined) {
                    issuedBy = "";
                }
                if (customers === undefined) {
                    customers = "";
                }
                if (values.rootid == this.state.id) {
                    dispatch(
                        getMemos(
                            0,
                            values.rootid,
                            this.state.rootInfoEqual,
                            date,
                            "",
                            issuedBy,
                            customers,
                            "",
                            "",
                            ""
                        )
                    );
                } else {
                    dispatch(
                        getMemos(
                            0,
                            values.rootid,
                            this.state.rootInfo,
                            date,
                            "",
                            issuedBy,
                            customers,
                            "",
                            "",
                            ""
                        )
                    );
                }
            });
        }
    };

    onSerach = (e) => {
        //this.setState({search: e.target.value})
        if (this.state.search.length > 1) {
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                const { dispatch } = this.props;
                let date = values.date;
                if (date === undefined || date === null) {
                    date = "";
                } else {
                    date = moment(values.date).format("YYYY-MM-DD");
                }
                let issuedBy = values._issuedBy;
                let customers = values.customers;
                if (issuedBy === undefined) {
                    issuedBy = "";
                }
                if (customers === undefined) {
                    customers = "";
                }
                this.setState({
                    table_loading: true,
                });

                if (values.rootid == this.state.id) {
                    dispatch(
                        getMemos(
                            0,
                            values.rootid,
                            this.state.rootInfoEqual,
                            date,
                            "",
                            issuedBy,
                            customers,
                            "",
                            "",
                            this.state.search
                        )
                    );
                } else {
                    dispatch(
                        getMemos(
                            0,
                            values.rootid,
                            this.state.rootInfo,
                            date,
                            "",
                            issuedBy,
                            customers,
                            "",
                            "",
                            this.state.search
                        )
                    );
                }
            });
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { loading } = this.state;

        this.columns = [
            {
                title: "ISSUE ID",
                dataIndex: "issuedId",
                sorter: (a, b) => { },
                render: (text, record) => {
                    const { finger_index } = record;
                    let textprint = "";
                    try {
                        var s = text;
                        let lastlength = s.substring(0, s.lastIndexOf("/"));
                        let lasti = s.lastIndexOf("/");
                        let ans = s.substring(lasti + 1);
                        if (isNaN(ans)) {
                            textprint = lastlength;
                        } else {
                            textprint = text;
                        }
                    } catch (error) {
                        console.log(error);
                    }
                    return <span>{textprint}</span>;
                },
            },
            {
                title: "GOLD SMITH",
                dataIndex: "customer",
                sorter: (a, b) => { },
                //  width: "10%",
                render: (text, record) => {
                    let { customer } = record;
                    let { firstName, familyName } = customer;
                    if (familyName === undefined && firstName === undefined) {
                        familyName = "";
                        firstName = "";

                    } else if (familyName === undefined) {
                        familyName = "";
                    } else if (firstName === undefined) {
                        firstName = "";
                    }
                    return <span>{firstName + " " + familyName}</span>;
                },
            },
            {
                title: "TOTAL GROSS WT",
                dataIndex: "created_at",
                sorter: (a, b) => { },
                //width: "10%",
                render: (text, record) => {
                    const { customer } = record;
                    return <span>{moment(text).format("DD-MM-YYYY")}</span>;
                },
            },
            {
                title: "TOTAL NET WT",
                dataIndex: "created_at",
                sorter: (a, b) => { },
                //width: "10%",
                render: (text, record) => {
                    const { customer } = record;
                    return <span>{moment(text).format("DD-MM-YYYY")}</span>;
                },
            },
            {
                title: "QUANTITY",
                dataIndex: "_issueBy",
                sorter: (a, b) => { },
                render: (text, record) => {
                    const { customer } = record;
                    let name = text.split(",");
                    return <span>{name[1]}</span>;
                },
            },
        ];
        let data = this.state.data;
        // if (this.state.search) {
        //   data = data.filter(row => {
        //     let created_at_formate = moment(row.created_at).format("DD-MM-YYYY");
        //     return (
        //       row.saleId.toUpperCase().includes(this.state.search.toUpperCase()) ||
        //       String(row.customer.name)
        //         .toUpperCase()
        //         .includes(this.state.search.toUpperCase()) ||
        //       String(created_at_formate)
        //         .toUpperCase()
        //         .includes(this.state.search.toUpperCase()) ||
        //       String(row._issueBy)
        //         .toUpperCase()
        //         .includes(this.state.search.toUpperCase())
        //     );
        //     //row.name.toUpperCase().includes(this.state.search.toUpperCase())
        //   });
        // }
        const { dataforExport } = this.state;
        const fileGenerate = dataforExport.map(function (fname, index) {
            try {
                let fnamefile = fname.saleId;
                fnamefile = fnamefile.substring(0, fnamefile.lastIndexOf("/"));
                fnamefile = fnamefile.replace(/[/]/g, "_");
                let persheetArray = fname.skuItemsIssue;
                var dataForSheet = [];
                // debugger
                for (let i = 0; i < persheetArray.length; i++) {
                    let secondArray = [];
                    if (persheetArray.length >= 1) {
                        secondArray.push(persheetArray[i].sku_number);
                        secondArray.push(persheetArray[i].design_code);
                        secondArray.push(persheetArray[i].design_color);
                        secondArray.push(persheetArray[i].diamond_quantity);
                        secondArray.push(persheetArray[i].color_stone_weight);
                        secondArray.push(persheetArray[i].gross_weight);
                        secondArray.push(persheetArray[i].net_weight);
                        secondArray.push(persheetArray[i].purity);
                        secondArray.push(persheetArray[i].labour_value);
                        secondArray.push(persheetArray[i].sales_value);
                        dataForSheet.push(secondArray);
                    } else {
                        dataForSheet.push(persheetArray[i].sku_number);
                        dataForSheet.push(persheetArray[i].design_code);
                        dataForSheet.push(persheetArray[i].design_color);
                        dataForSheet.push(persheetArray[i].diamond_quantity);
                        dataForSheet.push(persheetArray[i].color_stone_quantity);
                        dataForSheet.push(persheetArray[i].net_weight);
                        dataForSheet.push(persheetArray[i].gross_weight);
                        dataForSheet.push(persheetArray[i].purity);
                        dataForSheet.push(persheetArray[i].labour_value);
                        dataForSheet.push(persheetArray[i].sales_value);
                    }
                }
                let customerName = [];
                let companyName = [];
                let Description = [];
                let QuatationId = [];
                let quart_name = fname.saleId;
                quart_name = quart_name.substring(0, quart_name.lastIndexOf("/"));
                QuatationId.push(quart_name);
                companyName.push(fname.customer.name);
                companyName.push("");
                companyName.push("");
                companyName.push("");
                companyName.push(fname.customer.companyName);
                companyName.push("");
                companyName.push("");
                companyName.push("");
                companyName.push("");
                companyName.push(moment(fname.created_at).format("DD-MM-YYYY"));
                Description.push(fname.description);

                let datasetFor1 = [
                    {
                        xSteps: 4,
                        ySteps: 0,
                        columns: ["Memo ID"],
                        data: [QuatationId],
                    },
                    {
                        xSteps: 0,
                        ySteps: 1,
                        columns: [
                            { title: "Customer Name", width: { wpx: 120 } },
                            { title: "", width: { wpx: 120 } },
                            { title: "", width: { wpx: 120 } },
                            { title: "", width: { wpx: 120 } },
                            { title: "Company Name", width: { wpx: 120 } },
                            { title: "", width: { wpx: 120 } },
                            { title: "", width: { wpx: 120 } },
                            { title: "", width: { wpx: 120 } },
                            { title: "", width: { wpx: 120 } },
                            { title: "Date", width: { wpx: 120 } },
                        ],
                        data: [companyName],
                    },
                    // {
                    //   xSteps: 1,
                    //   ySteps: 1,
                    //   columns: [{title: "Description", width: {wpx: 120}}],
                    //   data:[Description]
                    // },
                    {
                        xSteps: 0, // Will start putting cell with 1 empty cell on left most
                        ySteps: 2, //will put space of 5 rows,
                        // wpx: 180,
                        columns: [
                            { title: "SKU Number", width: { wpx: 120 } },
                            { title: "Design Code", width: { wpx: 120 } },
                            { title: "Design Color", width: { wpx: 120 } },
                            { title: "Diamond QTY", width: { wpx: 120 } },
                            { title: "Color Stone Wt", width: { wpx: 140 } },
                            { title: "Gross Wt", width: { wpx: 120 } },
                            { title: "Net Wt", width: { wpx: 120 } },
                            { title: "Purity", width: { wpx: 120 } },
                            { title: "Labour Value", width: { wpx: 120 } },
                            { title: "Sales Value", width: { wpx: 120 } },
                        ],
                        data: dataForSheet,
                    },
                ];
                return (
                    <ExcelSheet
                        key={index}
                        //data={fname.skuItemsIssue}
                        name={`${fnamefile}`}
                        dataSet={datasetFor1}
                    />
                );
            } catch (error) {
                console.log(error);
            }
        });
        const formItemLayout = {
            labelCol: { span: 24 },
            //   labalAlign:{marginLeft: "15px"},
            wrapperCol: { span: 24 },
        };
        return (
            <div>
                {this.state.createIssueVisible && (
                    <AddIssue createIssueClose={() => this.setState({ createIssueVisible: false })} />
                )}
                {!this.state.createIssueVisible && (
                <Card
                    className='transaction-top-menu-card'
                    bodyStyle={{ padding: "0" }}
                    bordered={false}>
                    <Row>
                        <Col
                            lg={4}
                            md={4}
                            sm={24}
                            xs={24}
                            style={{
                                textAlign: "center",
                            }}>
                            <p className='transaction-title'>Transactions</p>
                            <h1 className='transaction-sub-title'>Issued</h1>
                        </Col>

                        <Col lg={4} md={4} sm={24} xs={24}>
                            <Divider
                                type='vertical'
                                className='vertical-divider-transaction'
                            />
                            <h1 className='total-text'>
                                <CountUp start={0} end={this.state.totalData} />{" "}
                            </h1>
                        </Col>

                        <Col lg={7} md={7} sm={24} xs={24}>
                            <p
                                style={{ marginBottom: "5px" }}
                                className='transaction-serach-text text-color-8c8c8c'>
                                Search
                            </p>
                            <Input
                                value={this.state.search}
                                placeholder='-- Search Voucher--'
                                style={{ float: "left", width: "70%" }}
                                // onChange={e => this.setState({ search: e.target.value })}
                                onChange={this.searchOnchange}
                            />
                            <Button
                                onClick={this.onSerach}
                                style={{ marginLeft: "11px" }}
                                type='primary'
                                disabled={this.state.btnsearch}>
                                Search
                            </Button>
                        </Col>

                        <Col lg={4} md={4} sm={24} xs={24} style={{ textAlign: "center" }}>
                            <Divider
                                type='vertical'
                                className='vertical-divider-transaction'
                            />
                            <img className="add-customer-icon cursor-pointer" onClick={this.createIssue} src={AddCustomerImg} />
                            <h5 className="text-color-8c8c8c cursor-pointer" onClick={this.createIssue}>Add Issue</h5>
                        </Col>

                        <Col
                            lg={5}
                            md={5}
                            sm={24}
                            xs={24}
                            style={{ textAlign: "center" }}
                            className='cursor-pointer'>
                            <img
                                className="add-customer-icon cursor-pointer" 
                                onClick={this.showModal}
                                src={ExportIcon}
                            />
                            <h5 className='text-color-8c8c8c'>Export Inventory</h5>
                        </Col>

                    </Row>
                </Card>
                )}

                {!this.state.createIssueVisible && (
                <Form hideRequiredMark={true} colon={false}>
                    <Card
                        className='filtter-card'
                        bordered={false}
                        bodyStyle={{ padding: "10px", paddingBottom: "0px" }}>
                        <Row>
                            <Col lg={2} md={2} sm={24} xs={24} className='text-aligin-center'>
                                <img src={FilterIcon} className='filter-icon' />
                            </Col>

                            <Col lg={4} md={4} sm={24} xs={24}>
                                <Form.Item {...formItemLayout} label='Date'>
                                    {getFieldDecorator(`date`, {
                                        rules: [
                                            {
                                                required: false,
                                            },
                                        ],
                                    })(
                                        <RangePicker
                                            format='DD-MM-YYYY'
                                            placeholder={["From ", "To"]}
                                            separator='-'
                                            style={{ minWidth: "100%" }}
                                            allowClear={false}
                                        />
                                    )}
                                </Form.Item>
                            </Col>

                            <Col lg={4} md={4} sm={24} xs={24}>
                                {this.state.rootInfo === "branch" &&
                                    this.state.branch_id != "" ? (
                                    <Form.Item
                                        label={this.state.textForSelect}
                                        {...formItemLayout}>
                                        {getFieldDecorator(`rootid`, {
                                            initialValue: this.state.id,
                                            rules: [
                                                {
                                                    required: false,
                                                },
                                            ],
                                        })(
                                            <Select
                                                placeholder='Please select branch'
                                                onChange={this.onChangeBranchRadio}
                                                style={{ minWidth: "100%" }}>
                                                <Option key={this.state.id} value={this.state.id}>
                                                    All Branch
                                                </Option>
                                                {this.state.branch_list.map((data, index) => (
                                                    <Option key={data._id} value={data._id}>
                                                        {data.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                ) : (
                                    ""
                                )}

                                {this.state.rootInfo === "floor" &&
                                    this.state.floor_id != "" ? (
                                    <Form.Item
                                        label={this.state.textForSelect}
                                        {...formItemLayout}>
                                        {getFieldDecorator(`rootid`, {
                                            initialValue: this.state.id,
                                            rules: [
                                                {
                                                    required: false,
                                                },
                                            ],
                                        })(
                                            <Select
                                                placeholder='Please select floor'
                                                onChange={this.onChangeBranchRadio}
                                                style={{ minWidth: "100%" }}>
                                                <Option key={this.state.id} value={this.state.id}>
                                                    All Floor
                                                </Option>
                                                {this.state.floor_list.map((data, index) => (
                                                    <Option key={data._id} value={data._id}>
                                                        {data.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                ) : (
                                    ""
                                )}

                                {this.state.rootInfo === "section" &&
                                    this.state.section_id != "" ? (
                                    <Form.Item
                                        label={this.state.textForSelect}
                                        {...formItemLayout}>
                                        {getFieldDecorator(`rootid`, {
                                            initialValue: this.state.id,
                                            rules: [
                                                {
                                                    required: false,
                                                },
                                            ],
                                        })(
                                            <Select
                                                placeholder='Please select section'
                                                onChange={this.onChangeBranchRadio}
                                                style={{ minWidth: "100%" }}>
                                                <Option key={this.state.id} value={this.state.id}>
                                                    All section
                                                </Option>
                                                {this.state.section_list.map((data, index) => (
                                                    <Option key={data._id} value={data._id}>
                                                        {data.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                ) : (
                                    ""
                                )}
                            </Col>

                            <Col lg={4} md={4} sm={24} xs={24}>
                                <Form.Item label='Employee' {...formItemLayout}>
                                    {getFieldDecorator(`_issuedBy`, {
                                        rules: [
                                            {
                                                required: false,
                                            },
                                        ],
                                    })(
                                        <Select
                                            placeholder='- - Select - -'
                                            style={{ minWidth: "100%" }}>
                                            {this.state.allSalesPersons.map((user) => (
                                                <Option key={user._id.empId} value={user._id.empId}>
                                                    {user._id.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>

                            <Col lg={4} md={4} sm={24} xs={24}>
                                <Form.Item label={`Customer`} {...formItemLayout}>
                                    {getFieldDecorator(`customers`, {
                                        rules: [
                                            {
                                                required: false,
                                            },
                                        ],
                                    })(
                                        <Select
                                            placeholder='- - Select - -'
                                            style={{ minWidth: "100%" }}>
                                            {this.state.cName}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>

                            <Col lg={1} md={1} sm={24} xs={24}></Col>
                            <Col lg={5} md={5} sm={24} xs={24}>
                                <div
                                    style={{
                                        marginTop: "31px",
                                        paddingRight: "9px",
                                        float: "right",
                                    }}>
                                    <Button
                                        style={{ float: "left" }}
                                        onClick={this.btnFiltter}
                                        type='primary'>
                                        Filter
                                    </Button>
                                    <Button
                                        onClick={this.onClearFiltter}
                                        className='transaction-clear-btn'
                                        ghost>
                                        Clear
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Form>
                )}

                {!this.state.createIssueVisible && (
                    <Row>
                        <Col md={24} sm={24} xs={24}>

                            <Card bodyStyle={{ padding: "10px 0px" }} bordered={false}>
                                <Table
                                    className='gx-table-responsive'
                                    loading={this.state.table_loading}
                                    columns={this.columns}
                                    dataSource={data}
                                    size={"small"}
                                    rowKey={(record) => record._id}
                                    pagination={this.state.pagination}
                                    onChange={this.handleTableChange}
                                />
                            </Card>
                        </Col>
                    </Row>
                )}


                <Modal
                    loading
                    title={
                        loading ? `Data is processing please wait ...` : `Memo File Created`
                    }
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key='back' onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <ExcelFile
                            key='tesingkey'
                            filename={`Memo Details - ${new Date()}`}
                            element={
                                loading ? (
                                    <Button
                                        key='submit'
                                        type='primary'
                                        onClick={this.handleOk}
                                        disabled>
                                        Wait..{" "}
                                    </Button>
                                ) : (
                                    <Button key='submit' type='primary' onClick={this.handleOk}>
                                        {" "}
                                        Download
                                    </Button>
                                )
                            }>
                            {fileGenerate}
                            {/**   <ExcelSheet data={this.state.dataforExport} name="Qatation">
                  <ExcelColumn label="Name" value="name"/>
                  <ExcelColumn label="Contact Number" value="contactNumber"/>
                  <ExcelColumn label="E-mail" value="email"/>
                  <ExcelColumn label="GST" value="gst"/>
                  <ExcelColumn label="Company Name" value="companyName"/>
                  <ExcelColumn label="Pan No" value="pan"/>
                  <ExcelColumn label="Address" value="address"/>                          
          </ExcelSheet>*/}
                        </ExcelFile>,
                    ]}>
                    {loading ? (
                        <div
                            style={{ minHeight: "150px", backgroundImage: `url(${Gears})` }}
                        />
                    ) : (
                        <Alert
                            message='Success'
                            description='Memo file is ready you can download.'
                            type='success'
                            showIcon
                        />
                    )}
                </Modal>
            </div>
        );
    }
}

Issue = Form.create()(Issue);
function mapStateToProps(state) {
    const { Common, Dataexport } = state;
    const {
        memo_details: memo_details,
        customer_details: customer_details,
        all_sales_persons: all_sales_persons,
        branch_list: branch_list,
        floor_list: floor_list,
        section_list: section_list,
    } = Common;
    const { export_sales_details: export_sales_details } = Dataexport;
    return {
        memo_details,
        customer_details,
        all_sales_persons,
        export_sales_details,
        branch_list,
        floor_list,
        section_list,
    };
}

const AppContainer = connect(mapStateToProps)(Issue);

export default AppContainer;
