import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import moment from "moment";
import {
  Card,
  Table,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  InputNumber,
  Button,
  AutoComplete,
  Input,
  Icon,
  Divider,
} from "antd";
import {
  getCutomerConversationRatio,
  getCustomer,
  getAllSalesPerson,
  getAllBrachList,
  getAllSectionList,
  getAllFloorList,
} from "../../../actions/index";
import CountUp from "react-countup";
import {
  currencyConverter,
  sortDirections,
} from "../../../containers/App/commanFunction";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
const Option = Select.Option;
const cookies = require("browser-cookies");
const { RangePicker } = DatePicker;
const columns = [
  //   {
  //       title: 'Date',
  //       dataIndex: 'month',
  //       width: '10%',
  //       render: (text, record) => {
  //         const { finger_index } = record
  //         return (
  //           <span>
  //               {text}
  //           </span>
  //         );
  //       }
  // },
  {
    title: "MONTH",
    dataIndex: "month",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { finger_index } = record;

      return <span>{text}</span>;
    },
  },
  {
    title: "TOTAL NO. CUST.",
    dataIndex: "total",
    sorter: (a, b) => {},
    width: "10%",
    render: (text, record) => {
      const { customer } = record;
      return <span>{text}</span>;
    },
  },

  {
    title: "CONVERTED CUSTOMERS",
    dataIndex: "convert",
    sorter: (a, b) => {},
    width: "10%",
    render: (text, record) => {
      const { customer } = record;
      return <span>{text}</span>;
    },
  },
  {
    title: "TOTAl TIME",
    dataIndex: "totalTime",
    sorter: (a, b) => {},
    width: "10%",
    render: (text, record) => {
      const { customer } = record;
      return <span>{text}</span>;
    },
  },
  {
    title: "AVG. TIME",
    dataIndex: "avgTime",
    sorter: (a, b) => {},
    width: "10%",
    render: (text, record) => {
      const { customer } = record;
      return <span>{text}</span>;
    },
  },
  {
    title: "TOTAl SALES VAL.",
    dataIndex: "totalValue",
    sorter: (a, b) => {},
    width: "10%",
    render: (text, record) => {
      const { customer } = record;
      return <span>{currencyConverter(text)}</span>;
    },
  },
  {
    title: "AVG. SALES VAL.",
    dataIndex: "avgValue",
    sorter: (a, b) => {},
    width: "10%",
    render: (text, record) => {
      const { customer } = record;
      return <span>{currencyConverter(Math.round(text).toFixed(0))}</span>;
    },
  },
  {
    title: "CONVERSION RATIO",
    dataIndex: "per",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { customer } = record;
      return <span>{text}</span>;
    },
  },
];

class Sales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      allSalesPersons: [],
      table_loading: true,
      totalData: 0,
      branch_list: [],
      textForSelect: "",
      section_list: [],
      floor_list: [],
      floor_id: "",
      branch_id: "",
      section_id: "",
      slecteID: "",
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;
    let now = new Date();
    let starDate = (fromDate = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ).subtract(30, "days"));
    let fromDate = starDate.toISOString();
    let toDate = new Date().toISOString();
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
    });

    let usertype = localStorage.getItem("type_user");
    let root = JSON.parse(cookies.get("root"));
    let page_no = 0;
    let limit = 10;
    dispatch(getCustomer(page_no, limit));
    if (usertype === "branchAdmin") {
      this.setState({
        rootInfo: "floor",
        rootInfoEqual: "branch",
        textForSelect: "Select Floor",
        id: root.branchId,
        selectedId: root.branchId,
        selectedRootInfoEqual: "branch",
      });
      dispatch(getAllFloorList("id"));
      dispatch(getAllSalesPerson("branch", root.branchId));
    }
    if (usertype === "companyAdmin") {
      this.setState({
        rootInfo: "branch",
        rootInfoEqual: "company",
        textForSelect: "Select Branch",
        id: root.companyId,
        selectedId: root.companyId,
        selectedRootInfoEqual: "company",
      });
      dispatch(getAllBrachList("id"));
      dispatch(getAllSalesPerson("company", root.companyId));
    }
    if (usertype === "floorAdmin") {
      this.setState({
        rootInfo: "section",
        rootInfoEqual: "floor",
        textForSelect: "Select Section",
        id: root.floorId,
        selectedId: root.floorId,
        selectedRootInfoEqual: "floor",
      });
      dispatch(getAllSectionList("id"));
      dispatch(getAllSalesPerson("floor", root.floorId));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (
        prevProps.customer_conversation_ratio_view !=
        this.props.customer_conversation_ratio_view
      ) {
        if (this.props.customer_conversation_ratio_view.status) {
          this.setState({
            data: this.props.customer_conversation_ratio_view.data_array,
            totalData: this.props.customer_conversation_ratio_view.data_array
              .length,
          });
        } else {
          this.setState({
            data: [],
          });
        }
        this.setState({
          table_loading: false,
        });
      }

      if (prevProps.branch_list != this.props.branch_list) {
        if (this.props.branch_list.status) {
          this.setState({
            branch_id: this.props.branch_list.data_array[0]._id,
            branch_list: this.props.branch_list.data_array,
            slecteID: this.props.branch_list.data_array[0]._id,
          });
          if (this.props.branch_list.data_array.length >= 1) {
            const { dispatch } = this.props;
            let page_no = 0;
            dispatch(
              getCutomerConversationRatio(
                0,
                this.state.fromDate,
                this.state.toDate,
                this.state.rootInfoEqual,
                this.state.id,
                ""
              )
            );
            //dispatch(getSKUByRootInfoAndId(this.props.branch_list.data_array[0]._id,this.state.rootInfo));
          }
        } else {
          this.setState({
            branch_list: [],
          });
        }
      }

      if (prevProps.floor_list != this.props.floor_list) {
        if (this.props.floor_list.status) {
          this.setState({
            floor_id: this.props.floor_list.data_array[0]._id,
            floor_list: this.props.floor_list.data_array,
            slecteID: this.props.floor_list.data_array[0]._id,
          });
          if (this.props.floor_list.data_array.length >= 1) {
            const { dispatch } = this.props;
            dispatch(
              getCutomerConversationRatio(
                0,
                this.state.fromDate,
                this.state.toDate,
                this.state.rootInfoEqual,
                this.state.id,
                ""
              )
            );
          }
        } else {
          this.setState({
            floor_list: [],
          });
        }
      }

      if (prevProps.section_list != this.props.section_list) {
        //console.log("this.props.section_list");
        // console.log(this.props.section_list);
        if (this.props.section_list.status) {
          this.setState({
            section_id: this.props.section_list.data_array[0]._id,
            section_list: this.props.section_list.data_array,
            slecteID: this.props.section_list.data_array[0]._id,
          });
          if (this.props.section_list.data_array.length >= 1) {
            const { dispatch } = this.props;
            dispatch(
              getCutomerConversationRatio(
                0,
                this.state.fromDate,
                this.state.toDate,
                this.state.rootInfoEqual,
                this.state.id,
                ""
              )
            );
          }
        } else {
          this.setState({
            section_list: [],
          });
        }
      }

      if (prevProps.customer_details != this.props.customer_details) {
        if (this.props.customer_details.status) {
          let json = this.props.customer_details.data_array;
          let cOptionArray = [];
          for (let index1 = 0; index1 < json.length; index1++) {
            const element = json[index1];
            cOptionArray.push(
              <Option
                key={index1.toString(36) + index1 + "cust"}
                value={element._id.toString()}>
                {element.name}
              </Option>
            );
          }
          this.setState({
            cName: cOptionArray,
          });
        }
      }

      if (prevProps.all_sales_persons != this.props.all_sales_persons) {
        try {
          if (this.props.all_sales_persons.status) {
            let allSalesPersonsSort = this.props.all_sales_persons.data_array;
            allSalesPersonsSort.sort((a, b) =>
              a._id.name > b._id.name ? 1 : -1
            );
            this.setState({
              allSalesPersons: allSalesPersonsSort,
            });
            //const { dispatch } = this.props;
            //dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,this.props.all_sales_persons.data_array[0].empId));
          }
          // this.setState({
          //   table_loading: false
          // })
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dispatch } = this.props;
        // let userdata=values;
      }
    });
  };
  onChange = (date, dateString) => {
    // this.setState({
    //   table_loading:true
    // })
    // if(date.length==2)
    // {
    //   try {
    //     console.log(date);
    //     const { dispatch } = this.props;
    //     let fromDate=date[0].toISOString();
    //     let toDate=date[1].toISOString();
    //     dispatch(getCutomerConversationRatio(0,fromDate,toDate));
    //    } catch (error) {
    //      console.log(error);
    //    }
    // }else{
    //   this.setState({
    //     data:[],
    //     table_loading:false
    //    });
    // }
  };

  btnFiltter = () => {
    this.setState({
      table_loading: true,
    });

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const { dispatch } = this.props;
      // console.log(values);
      const pager = { ...this.state.pagination };
      pager.current = 1;
      pager.pageSize = 10;

      this.setState({
        pagination: pager,
        table_loading: true,
        pageSizelimit: 10,
        currantPage: pager.current - 1,
      });

      let fromDate = "";
      let toDate = "";
      if (values.date.length > 0) {
        fromDate = values.date[0].toISOString();
        toDate = values.date[1].toISOString();
      }

      if (values.rootid == this.state.id) {
        dispatch(
          getCutomerConversationRatio(
            0,
            fromDate,
            toDate,
            this.state.rootInfoEqual,
            values.rootid,
            values._issuedBy
          )
        );
        // dispatch(getQuotation(0, values.rootid, this.state.rootInfoEqual, date, values._issuedBy, values.customers));
      } else {
        dispatch(
          getCutomerConversationRatio(
            0,
            fromDate,
            toDate,
            this.state.rootInfo,
            values.rootid,
            values._issuedBy
          )
        );
        //dispatch(getQuotation(0, values.rootid, this.state.rootInfo, date, values._issuedBy, values.customers));
      }
    });
  };

  onClearFiltter = (e) => {
    this.setState({
      table_loading: true,
      search: "",
    });
    const { dispatch } = this.props;
    dispatch(
      getCutomerConversationRatio(
        0,
        this.state.fromDate,
        this.state.toDate,
        this.state.rootInfoEqual,
        this.state.id,
        ""
      )
    );
    this.props.form.resetFields();
  };

  handleTableChange = (pagination, filters, sorter) => {
    let colome = sorter.columnKey;
    let order = sorter.order;
    let orderopp = 0;
    let data = this.state.data;
    if (order != undefined) {
      if (order === "descend") {
        order = -1;
        orderopp = 1;
      } else {
        order = 1;
        orderopp = -1;
      }
      data.sort((a, b) => (a[`${colome}`] > b[`${colome}`] ? order : orderopp));
    } else {
      const shuffleArray = (arr) => arr.sort(() => Math.random() - 0.5);
      shuffleArray(data);
    }
  };

  render() {
    var heightd = window.innerHeight - 550;
    const { getFieldDecorator } = this.props.form;
    const now = new Date();
    const fromDate = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ).subtract(30, "days");
    let data = this.state.data;
    if (this.state.search) {
      try {
        data = data.filter((row) => {
          return (
            row.month.toUpperCase().includes(this.state.search.toUpperCase()) ||
            row.per.includes(this.state.search.toUpperCase())
          ); //||row.total.includes(this.state.search.toUpperCase())
          //row.name.toUpperCase().includes(this.state.search.toUpperCase())
        });
      } catch (error) {
        console.log(error);
      }
    }
    const formItemLayout = {
      //labelCol: { span: 24 },
      //   labalAlign:{marginLeft: "15px"},
      //wrapperCol: { span: 24 },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    return (
      <div>
        <Card
          className='transaction-top-menu-card'
          bodyStyle={{ padding: "0" }}
          bordered={false}>
          <Row>
            <Col
              lg={5}
              md={5}
              sm={24}
              xs={24}
              style={{
                textAlign: "center",
              }}>
              <p className='transaction-title'>Reports</p>
              <h1 className='transaction-sub-title'>Conversion Ratio</h1>
            </Col>

            <Col lg={11} md={11} sm={24} xs={24}>
              <Divider
                type='vertical'
                className='vertical-divider-transaction'
              />
              <h1 className='total-text'>
                <CountUp start={0} end={data.length} />{" "}
              </h1>
            </Col>

            <Col lg={8} md={8} sm={24} xs={24}>
              <p
                style={{ marginBottom: "5px" }}
                className='transaction-serach-text text-color-8c8c8c'>
                Search
              </p>
              <Input
                value={this.state.search}
                placeholder='-- Search --'
                style={{ float: "left", width: "70%" }}
                onChange={(e) => this.setState({ search: e.target.value })}
              />
              <Button
                onClick={this.handleReset}
                style={{ marginLeft: "11px" }}
                type='primary'>
                Search
              </Button>
            </Col>
          </Row>
        </Card>

        <Form hideRequiredMark={true} colon={false} labelAlign={"left"}>
          <Card
            className='filtter-card'
            bordered={false}
            bodyStyle={{
              padding: "10px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}>
            <Row>
              <Col lg={2} md={2} sm={24} xs={24} className='text-aligin-center'>
                <img src={FilterIcon} className='filter-icon' />
              </Col>
              <Col lg={5} md={5} sm={24} xs={24} style={{ paddingLeft: "0px" }}>
                <Form.Item label={`Date`} {...formItemLayout}>
                  {getFieldDecorator(`date`, {
                    initialValue: [
                      moment(fromDate, "DD-MM-YYYY"),
                      moment(new Date(), "DD-MM-YYYY"),
                    ],
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <RangePicker
                      // showTime={{ format: 'HH:mm' }}
                      format='DD-MM-YYYY'
                      placeholder={["From ", "To"]}
                      //  defaultValue={[moment(fromDate, "DD-MM-YYYY"),moment(new Date(), "DD-MM-YYYY")]}
                      //  size="small"
                      // style={{width:"245px"}}
                      separator='-'
                      style={{ minWidth: "100%" }}
                      //   onChange={this.onChange}
                      // onOk={onOk}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={5} md={5} sm={24} xs={24}>
                {this.state.rootInfo === "branch" &&
                this.state.branch_id != "" ? (
                  <Form.Item
                    label={this.state.textForSelect}
                    {...formItemLayout}>
                    {getFieldDecorator(`rootid`, {
                      initialValue: this.state.id,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        placeholder='Please select branch'
                        onChange={this.onChangeBranchRadio}
                        style={{ minWidth: "180px" }}>
                        <Option key={this.state.id} value={this.state.id}>
                          All Branch
                        </Option>
                        {this.state.branch_list.map((data, index) => (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  ""
                )}

                {this.state.rootInfo === "floor" &&
                this.state.floor_id != "" ? (
                  <Form.Item
                    label={this.state.textForSelect}
                    {...formItemLayout}>
                    {getFieldDecorator(`rootid`, {
                      initialValue: this.state.id,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        placeholder='Please select floor'
                        onChange={this.onChangeBranchRadio}
                        style={{ minWidth: "180px" }}>
                        <Option key={this.state.id} value={this.state.id}>
                          All Floor
                        </Option>
                        {this.state.floor_list.map((data, index) => (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  ""
                )}

                {this.state.rootInfo === "section" &&
                this.state.section_id != "" ? (
                  <Form.Item
                    label={this.state.textForSelect}
                    {...formItemLayout}>
                    {getFieldDecorator(`rootid`, {
                      initialValue: this.state.id,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        placeholder='Please select section'
                        onChange={this.onChangeBranchRadio}
                        style={{ minWidth: "180px" }}>
                        <Option key={this.state.id} value={this.state.id}>
                          All section
                        </Option>
                        {this.state.section_list.map((data, index) => (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  ""
                )}
              </Col>

              <Col lg={5} md={5} sm={24} xs={24}>
                <Form.Item label='By Employee' {...formItemLayout}>
                  {getFieldDecorator(`_issuedBy`, {
                    initialValue: "",
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      placeholder='- - Select - -'
                      style={{ minWidth: "180px" }}>
                      <Option key='' value=''>
                        All Employees
                      </Option>
                      {this.state.allSalesPersons.map((user) => (
                        <Option key={user._id.empId} value={user._id.empId}>
                          {user._id.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col lg={2} md={2} sm={24} xs={24}></Col>
              <Col lg={5} md={5} sm={24} xs={24}>
                <div
                  style={{
                    marginTop: "31px",
                    paddingRight: "9px",
                    float: "right",
                  }}>
                  <Button
                    style={{ float: "left" }}
                    onClick={this.btnFiltter}
                    type='primary'>
                    Filter
                  </Button>
                  <Button
                    onClick={this.onClearFiltter}
                    className='transaction-clear-btn'
                    ghost>
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Form>

        <Card
          style={{ marginBottom: "0px" }}
          bodyStyle={{ padding: "10px 10px", paddingBottom: "0px" }}
          bordered={false}>
          <Table
            className='gx-table-responsive'
            loading={this.state.table_loading}
            columns={columns}
            dataSource={data}
            size={"small"}
            scroll={{ y: heightd }}
            rowKey={(record) => record.month}
            onChange={this.handleTableChange}
            sortDirections={sortDirections}
          />
        </Card>
      </div>
    );
  }
}

Sales = Form.create()(Sales);
function mapStateToProps(state) {
  const { Common } = state;
  const {
    customer_conversation_ratio_view: customer_conversation_ratio_view,
    customer_details: customer_details,
    all_sales_persons: all_sales_persons,
    branch_list: branch_list,
    floor_list: floor_list,
    section_list: section_list,
  } = Common;
  return {
    customer_conversation_ratio_view,
    customer_details,
    all_sales_persons,
    branch_list,
    floor_list,
    section_list,
  };
}

const AppContainer = connect(mapStateToProps)(Sales);

export default AppContainer;
