let initialState = {
    configurationList: [],
  };
  
  export default function listConfigurationDisplay(state = initialState, action) {
    if (action.type === "LIST_CONFIGURATION_DISPLAY"){
      return { ...state, configurationList: action.data };
    }else {
        return state;
    }
  }