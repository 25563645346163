import Axios from 'axios';
const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var cookies = require('browser-cookies');

export const RFIDDEVICE_INFO_DETAILS='RFIDDEVICE_INFO_DETAILS';
export const FLOOR_LIST='FLOOR_LIST';
function getRfidDevicereport(page_no,fromDate,toDate) {
    let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    return dispatch => {
        return Axios.get(`rc/rfiddevice/v1`,
        
          { headers: { 'x-web-token': webtoken }}
          )
            .then(response => {         
                if (response.data !== "") {             
                    dispatch(requestgetRfidDevicereportDetails(response.data))                
                } else {
                    dispatch(requestgetRfidDevicereportDetails([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestgetRfidDevicereportDetails(response) {
    return {
        type: RFIDDEVICE_INFO_DETAILS,
        rfiddevice_info_details: response
    }
  }
  
  export function getRfidDevice(page_no,fromDate,toDate) {
  
    return (dispatch) => {
        return dispatch(getRfidDevicereport(page_no,fromDate,toDate))
    }
  }


  function getFloorListreport(page_no,fromDate,toDate) {
    let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    return dispatch => {
        //return Axios.get(`rc/rfiddevice/v1`,
        return Axios.get(`rc/v2/floors`,
        
          { headers: { 'x-web-token': webtoken }}
          )
            .then(response => {         
                if (response.data !== "") {             
                    dispatch(requestgetFLOORlistDetails(response.data))                
                } else {
                    dispatch(requestgetFLOORlistDetails([]))
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      // dispatch(push('/'));
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }
  
  function requestgetFLOORlistDetails(response) {
    return {
        type: FLOOR_LIST,
        floor_list: response
    }
  }
  
  export function getFloorList() {
  
    return (dispatch) => {
        return dispatch(getFloorListreport())
    }
  }