import React, { Component } from 'react';
import { Alert, Button, Form, Icon, message, Modal, Select, Spin, Table, Typography, Upload } from 'antd';
import {
    getAllBrachList,
    getAllFloorList,
    getAllSectionList,
    getAllSubSectionList,
    getSKUByRootInfoAndId,
    replaceStock,
    stockUploadFileResponse,
    stockUploadStatusbar
} from '../../../actions/index';
import { connect } from 'react-redux';
import ImportIcon from "./../../../assets/images/icons/import.svg";
import SkuAdd from "../Stocks/AddSku";
import ReactExport from "react-data-export";
import SampleDataExport from './SampleJTStock.json'

message.config({
    duration: 2,
    maxCount: 3,
});
const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
const { Option } = Select;
var cookies = require('browser-cookies');
var uploadingspin = false;
var result = window.location.href;
var arr = result.split("/");
var urlupload = arr[0] + "//" + arr[2];
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// console.log(urlupload);
const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {

        console.log(file);
        console.log("ok");
    }, 0);
};

class StockUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file_upload: [],
            visible: false,
            uploadvisible: false,
            dublicate_data: [],
            uploadingspin: false,
            selected_id: '',
            filelist: true,
            branch_list: [],
            branch_id: '',
            floor_list: [],
            floor_id: '',
            section_id: '',
            section_list: [],
            sub_section_list: [],
            sub_section_id: '',
            firstlevel: true,
            companyId: '',
            usertype: '',
            isUploadLoading: false,
            root: {
                companyId: '',
                branchId: '',
                floorId: '',
                sectionId: '',
                subSectionId: '',
                isUploadStockFaild: false,
                isUploadStockFaildMsg: ''
            },
            visibleForAddSku: this.props.visibleForAddSku,
            fromSingleUpload: this.props.fromSingleUpload,
            isModalOpen: this.props.isModalOpen,
            isFromSingleAddResponse: false,
            // closeAddSku: this.props.closeAddSku,
            dataforExport:[]
        }
            ;
    }

    componentDidMount() {
        let usertype = localStorage.getItem('type_user');
        let root = JSON.parse(cookies.get('root'));
        const { dispatch } = this.props;
        // console.log("root");
        // console.log(root);
        let newroot = {
            companyId: root.companyId,
            branchId: root.branchId,
            floorId: root.floorId,
            sectionId: '',
            subSectionId: '',
        }
        this.setState({
            root: newroot,
            usertype: usertype,
            dataforExport:SampleDataExport
        });
        if (usertype === "branchAdmin") {
            this.setState({
                rootInfo: "floor",
                textForSelect: "Select Floor"
            });

            // dispatch(getAllFloorList("id"));
            // dispatch(getAllFloorList("bybranchId",  root.branchId));
            // dispatch(getAllBrachList("byCompanyId", root.companyId));
        }
        if (usertype === "companyAdmin") {
            this.setState({
                rootInfo: "branch",
                textForSelect: "Select Branch"
            });
            //dispatch(getAllBrachList("id"));
        }
        if (usertype === "floorAdmin") {
            this.setState({
                rootInfo: "section",
                textForSelect: "Select Section"
            });
            // console.log('getAllSectionList.....................................................', usertype)
            // dispatch(getAllSectionList("id"));
            //dispatch(getAllBrachList("byCompanyId", root.companyId));
        }

    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (prevProps.file_upload_stock_response !== this.props.file_upload_stock_response) {
                // console.log("success");
                //   uploadingspin=false;
                //this.setState({uploadingspin:false})

                if (this.props.file_upload_stock_response.status) {
                    if (this.props.file_upload_stock_response.data_array.length > 0) {
                        // console.log("this.props.isModalOpen", this.state.isModalOpen)
                        if (this.state.isModalOpen === false) {
                            // console.log("prevProps.file_upload_stock_response", prevProps.file_upload_stock_response);
                            // console.log("this.props.file_upload_stock_response", this.props.file_upload_stock_response);
                            this.setState({ isModalOpen: true }, this.openModal)
                        }
                    } else {
                        message.success("Inventory upload successfully");
                        // console.log(this.props.file_upload_stock_response);
                        this.setState({
                            filelist: false,
                            uploadvisible: false,
                            firstlevel: true,
                        });
                        const { dispatch } = this.props;
                        dispatch(getSKUByRootInfoAndId(this.props.selected_id, "company", 0, 10, "", "", "", "", "", ""));
                    }
                } else {
                    this.setState({
                        isUploadLoading: false,
                        isUploadStockFaild: true,
                        isUploadStockFaildMsg: this.props.file_upload_stock_response.errors.msg
                    });
                    //message.error(this.props.file_upload_stock_response.errors.msg);
                }

            }

            if (prevProps.replace_stock !== this.props.replace_stock) {
                // console.log("replce -success");
                // console.log(this.props.replace_stock);
                if (this.props.replace_stock.status) {
                    message.error(this.props.replace_stock.errors.msg);
                    const { dispatch } = this.props;
                    dispatch(getSKUByRootInfoAndId(this.state.selected_id, this.state.rootInfo, 0, 10, "", "", "", "", "", ""));
                } else {
                    message.error(this.props.replace_stock.errors.msg);
                }
            }

            if (prevProps.file_upload_status !== this.props.file_upload_status) {
                // console.log("file_upload_status -success");
                // console.log(this.props.file_upload_status);
                this.setState({
                    isUploadLoading: this.props.file_upload_status.isUploadLoading
                })
            }

            if (prevProps.branch_list !== this.props.branch_list) {
            // if (Object.keys(prevProps.branch_list).length !== Object.keys(this.props.branch_list).length) {
                // console.log('prevProps.branch_list', prevProps.branch_list, 'this.props.branch_list', this.props.branch_list);
                if (this.props.branch_list.status) {
                    this.setState({
                        selected_id: this.props.branch_list.data_array[0]._id,
                        branch_list: this.props.branch_list.data_array,
                        branch_id: this.props.branch_list.data_array[0]._id,
                        floor_list: [],
                        floor_id: '',
                    });
                    const { dispatch } = this.props;
                    dispatch(getAllFloorList("bybranchId", this.props.branch_list.data_array[0]._id));
                }
            }

            if (prevProps.floor_list !== this.props.floor_list) {
                if (this.props.floor_list.status) {
                    this.setState({
                        selected_id: this.props.floor_list.data_array[0]._id,
                        floor_list: this.props.floor_list.data_array,
                        floor_id: this.props.floor_list.data_array[0]._id,
                        section_list: [],
                        section_id: ''
                    });
                    const { dispatch } = this.props;
                    dispatch(getAllSectionList("byFloorId", this.props.floor_list.data_array[0]._id));
                }
            }

            if (prevProps.section_list !== this.props.section_list) {
                //console.log("this.props.section_list");
                // console.log(this.props.section_list);
                if (this.props.section_list.status) {
                    // console.log('this.props.section_list.status', this.props.section_list, 'prevProps.section_list', prevProps.section_list);
                    this.setState({
                        selected_id: this.props.section_list.data_array[0]._id,
                        section_list: this.props.section_list.data_array,
                        section_id: this.props.section_list.data_array[0]._id,
                        sub_section_id: []
                    });
                    const {dispatch} = this.props;
                    // console.log('getAllSubSectionList.....................................................');
                    dispatch(getAllSubSectionList("bySectionId", this.props.section_list.data_array[0]._id));
                }
            }

            // if (Object.keys(prevProps.section_list).length !== Object.keys(this.props.section_list).length) {
            //     // console.log('Object.keys(prevProps.section_list).length', Object.keys(prevProps.section_list).length, 'Object.keys(this.props.section_list).length', Object.keys(this.props.section_list).length);
            //     if (this.props.section_list.status) {
            //         this.setState({
            //             selected_id: this.props.section_list.data_array[0]._id,
            //             section_list: this.props.section_list.data_array,
            //             section_id: this.props.section_list.data_array[0]._id,
            //         });
            //         const { dispatch } = this.props;
            //         dispatch(getAllSubSectionList("bySectionId", this.props.section_list.data_array[0]._id));
            //     }
            // }


            if (prevProps.sub_section_list !== this.props.sub_section_list) {
                // console.log(this.props.sub_section_list);
                if (this.props.sub_section_list.status) {
                    this.setState({
                        sub_section_list: this.props.sub_section_list.data_array,
                    })

                    if (this.props.sub_section_list.data_array.length >= 1) {
                        this.setState({
                            sub_section_id: this.props.sub_section_list.data_array[0]._id,
                        })
                        const { dispatch } = this.props;
                        //dispatch(getAllUserList("subSection",this.props.sub_section_list.data_array[0]._id));
                    }
                } else {
                    this.setState({
                        sub_section_list: []
                    })
                }
                this.setState({
                    table_loading: false
                })

            }

        } catch (error) {
            console.log(error);
        }
    }

    openModal = () => {
        this.setState({
            visible: true,
            uploadvisible: false,
            dublicate_data: this.props.file_upload_stock_response.data_array,
        })
    }

    handleOk = () => {
        this.setState({
            visible: false,
        });
        const { dispatch } = this.props;
        // console.log(this.state.dublicate_data);
        dispatch(replaceStock(this.state.dublicate_data));
    }

    handleCancel = () => {
        //console.log('Clicked cancel button');

        this.setState({
            visible: false,
            filelist: true,
        });
        const { dispatch } = this.props;
        //  dispatch(getSKUByRootInfoAndId(this.props.selected_id,this.props.rootInfo,0,10,"","","","","",""));
    }


    Upload = (e) => {
        // console.log(document.URL);
        let result = { status: 'error' };
        return result;
    }

    onFileDropefile = (e) => {
        // console.log('file upload ff');
        // this.setState({selectedRowKeys});
    };

    //   beforeUploadForState=(e)=>{
    //     console.log(document.URL);
    // }
    beforeUpload(params) {
        // console.log("before ");
        //dispatch(stockUploadFileResponse(params));
    }

    uploadhandleCancel = () => {
        //console.log('Clicked cancel button');
        //console.log(this.state.root);
        this.setState({
            uploadvisible: false,
            filelist: true,
            isUploadLoading: false,
            isUploadStockFaild: false,
            floor_id: '',
            section_id: '',
            sub_section_id: '',
        });


    }

    uploadhandleOpen = () => {
        //console.log('Clicked cancel button');
        let isSingle = false;
        if (this.state.fromSingleUpload)
            isSingle = true;
        this.setState({
            uploadvisible: true,
            firstlevel: true,
            filelist: true,
            isUploadLoading: false,
            isUploadStockFaildMsg: '',
            visibleForAddSku: isSingle,
        });
    }


    handleNext = () => {
        //console.log('Clicked cancel button');
        if (this.state.ValidUservisible == true) {
            return;
        }
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            let companyId = values.companyId;
            let branchId = values.branchId;
            let floorId = values.floorId;
            let sectionId = values.sectionId;
            let subSectionId = values.subSectionId;
            // console.log(this.state.root);
            if (this.state.usertype === "floorAdmin") {
                companyId = this.state.root.companyId
                branchId = this.state.root.branchId
                floorId = this.state.root.floorId
            }
            if (this.state.usertype === "branchAdmin") {
                companyId = this.state.root.companyId
                branchId = this.state.root.branchId
            }
            if (this.state.usertype === "companyAdmin") {
                companyId = this.state.root.companyId
            }

            let root = {
                companyId: companyId,
                branchId: branchId,
                floorId: floorId,
                sectionId: sectionId,
                subSectionId: subSectionId
            }

            this.setState({
                root: root,
                firstlevel: false,
            });
            // console.log(this.state.root);
            //dispatch(createUser(values)); 
        });

    }

    closeAddSku = (record) => {
        if (this.state.isFromSingleAddResponse)
            this.setState({
                uploadvisible: false,
            });
        else
            this.setState({
                visibleForAddSku: false,
                uploadvisible: false,
            });
    }

    closeAddFromResponse = (record) => {
        this.setState({
            uploadvisible: false,
        });

    }

    alertOnClose = (e) => {
        this.setState({
            isUploadStockFaild: false
        })
    }

    onChangeBranchRadio = (e) => {
        // console.log(`radio checked:${e}`);
        this.setState({
            table_loading: true,
            branch_id: e,
            floor_id: '',
            section_id: '',
            sub_section_id: '',
            floor_list: [],
            section_list: [],
            sub_section_list: [],
            data: []
        })
        //this.props.form.resetFields(['floorId','sectionId','subSectionId']);
        const { dispatch } = this.props;
        dispatch(getAllFloorList("bybranchId", e));
    }

    onChangeFloor = (e) => {
        // console.log(`radio floor:${e}`);
        this.setState({
            table_loading: true,
            floor_id: e,
            section_id: '',
            sub_section_id: '',
            section_list: [],
            sub_section_list: [],
            data: []
        })
        //this.props.form.resetFields(['sectionId','subSectionId']);
        const { dispatch } = this.props;
        dispatch(getAllSectionList("byFloorId", e));
    }

    onChangeSection = (e) => {
        // console.log(`radio section:${e}`);
        this.setState({
            table_loading: true,
            section_id: e,
            sub_section_id: '',
            sub_section_list: [],
        })
        const { dispatch } = this.props;
        //this.props.form.resetFields(['subSectionId']);
        dispatch(getAllSubSectionList("bySectionId", e));
    }

    render() {
        // const {visibleForAddSku, closeAddSku, fromSingleUpload} = this.props;
        const { popup_name } = this.props;
        const columns = [{
            title: 'SKU',
            dataIndex: 'sku_number',
        },
        {
            title: 'Dsg Code',
            dataIndex: 'design_code',
        },
        {
            title: 'DSG Ctg',
            dataIndex: 'design_category',
        },
        {
            title: 'Metal Type',
            dataIndex: 'metal_type',
        },
        {
            title: 'Design Color',
            dataIndex: 'design_color',
        },

        {
            title: 'Gross Wgt',
            dataIndex: 'gross_weight',
        },
        {
            title: 'Net Wgt',
            dataIndex: 'net_weight',
        },
        {
            title: 'Sales Category',
            dataIndex: 'sales_category'

        }
        ];
        let dublicate_array;
        const { dispatch } = this.props;

        function FileUploadSuccess(params) {
            dispatch(stockUploadFileResponse(params));
        }

        function uploadStatus(file) {
            dispatch(stockUploadStatusbar({ isUploadLoading: true }));
        }

        const Dragger = Upload.Dragger;
        const webtoken = cryptr.decrypt(cookies.get('wteoben'));
        const props = {
            name: 'file',
            //data:function(file)
            // data: this.datadisplay(file),
            //  type:'.xlsx',

            accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
            listType: 'picture',
            onFileDrop(e) {
            },
            onRemove(UploadFile) {
            },
            onError(UploadFile) {
                // console.log("==================errror=======================");
                // console.log(UploadFile);
            },
            multiple: false,
            //  action:${urlupload},
            //  action:'http://localhost:3001/rc/stocks/stock',headers: { 'x-web-token': webtoken },
            action: `${urlupload}/sku/file`,
            headers: { 'x-web-token': webtoken, 'root': { 'companyId': this.state.root.companyId } },
            data: this.state.root,
            beforeUpload(file) {
                //  this.beforeUpload(file);
                // this.state.uploadingspin=true
                // this.setState({uploadingspin:true});
            },
            onChange(info) {
                // uploadingspin=true;
                const status = info.file.status;
                uploadStatus(info);
                if (status !== 'uploading') {
                }
                if (status === 'done') {
                    // console.log(info.file.response);
                    FileUploadSuccess(info.file.response);
                    // uploadingspin=false;
                    /// this.setState({uploadingspin:false});
                    if (info.file.response.status) {
                    } else {
                    }

                } else if (status === 'error') {
                    //uploadingspin=false;
                    // this.setState({uploadingspin:false})
                }
            },
            //multiple:false,
            showUploadList: this.state.filelist
            // onSuccess(){

            // }

            // onStart(file){

            //   console.log(file.type);
            //   if(file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type == "application/vnd.ms-excel" || file.type == ".csv")
            //   {
            //     console.log("=============accept===================");
            //     //this.onChange(info);
            //   }else{
            //     message.error("Only accept excel or csv file");
            //   }


            // },

            //data:uploadfile(name),
        };
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
                <img className="import-icon" onClick={this.uploadhandleOpen} src={ImportIcon} />

                <Modal
                    title={popup_name}//"Import Inventory "
                    visible={this.state.uploadvisible}
                    onOk={this.uploadhandleCancel}
                    //     confirmLoading={confirmLoading}
                    style={{ paddingBottom: "0px" }}
                    width={650}
                    onCancel={this.uploadhandleCancel}
                    footer={[
                        <span style={{ marginBottom: "20px" }}>

                            <Button key="back" onClick={this.uploadhandleCancel} ghost>Close</Button>
                            {this.state.firstlevel === true ?
                                <Button key="submit" type="primary" onClick={this.handleNext}>
                                    Next
                                </Button> : ""}
                        </span>
                    ]}

                >
                    {this.state.isUploadStockFaild ?
                        <React.Fragment>
                            <Alert
                                message="Inventory upload error"
                                description={this.state.isUploadStockFaildMsg}
                                type="error"
                                closable
                                onClose={this.alertOnClose}
                            />
                            <br />
                        </React.Fragment>
                        : ""}

                    <Form layout="vertical" onSubmit={this.FormSubmit}>
                        {this.state.firstlevel === true && this.state.usertype === "companyAdmin" ?

                            <React.Fragment>
                                <Form.Item label="Select Branch" className="customFormItem">
                                    {getFieldDecorator('branchId', {
                                        initialValue: this.state.branch_id,
                                        rules: [{ required: true, message: 'Please select branch!' }],
                                    })(
                                        <Select placeholder="Please select branch" onChange={this.onChangeBranchRadio}>
                                            {this.state.branch_list.map((data, index) =>
                                                <Option key={data._id} value={data._id}>{data.name}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Select Floor" className="customFormItem">
                                    {getFieldDecorator('floorId', {
                                        initialValue: this.state.floor_id,
                                        rules: [{ required: true, message: 'Please select floor!' }],
                                    })(
                                        <Select placeholder="Please select floor" onChange={this.onChangeFloor}>
                                            {this.state.floor_list.map((data, index) =>
                                                <Option key={data._id} value={data._id}>{data.name}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Select Section " className="customFormItem">
                                    {getFieldDecorator('sectionId', {
                                        initialValue: this.state.section_id,
                                        rules: [{ required: true, message: 'Please select section!' }],
                                    })(
                                        <Select placeholder="Please select section" onChange={this.onChangeSection}>
                                            {this.state.section_list.map((data, index) =>
                                                <Option key={data._id} value={data._id}>{data.name}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>


                                <Form.Item label="Select SubSection" className="customFormItem">
                                    {getFieldDecorator('subSectionId', {
                                        initialValue: this.state.sub_section_id,
                                        rules: [{ required: true, message: 'Please select subsection!' }],
                                    })(
                                        <Select placeholder="Please select subsection"
                                            onChange={this.onChangeSubSection}>
                                            {this.state.sub_section_list.map((data, index) =>
                                                <Option key={data._id} value={data._id}>{data.name}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>

                            </React.Fragment>

                            : ""}
                        {this.state.firstlevel === true && this.state.usertype === "branchAdmin" ?

                            <React.Fragment>
                                <Form.Item label="Select Floor" className="customFormItem">
                                    {getFieldDecorator('floorId', {
                                        initialValue: this.state.floor_id,
                                        rules: [{ required: true, message: 'Please select floor!' }],
                                    })(
                                        <Select placeholder="Please select floor" onChange={this.onChangeFloor}>
                                            {/* {console.log('floor', this.state.floor_list)} */}
                                            {this.state.floor_list.map((data, index) =>
                                                <Option key={data._id} value={data._id}>{data.name}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Select Section " className="customFormItem">
                                    {getFieldDecorator('sectionId', {
                                        initialValue: this.state.section_id,
                                        rules: [{ required: true, message: 'Please select section!' }],
                                    })(
                                        <Select placeholder="Please select section" onChange={this.onChangeSection}>
                                            {this.state.section_list.map((data, index) =>
                                                <Option key={data._id} value={data._id}>{data.name}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>


                                <Form.Item label="Select SubSection" className="customFormItem">
                                    {getFieldDecorator('subSectionId', {
                                        initialValue: this.state.sub_section_id,
                                        rules: [{ required: true, message: 'Please select subsection!' }],
                                    })(
                                        <Select placeholder="Please select subsection"
                                            onChange={this.onChangeSubSection}>
                                            {this.state.sub_section_list.map((data, index) =>
                                                <Option key={data._id} value={data._id}>{data.name}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>

                            </React.Fragment>
                            : ""}
                        {this.state.firstlevel === true && this.state.usertype === "floorAdmin" ?
                            <React.Fragment>
                                <Form.Item label="Select Section " className="customFormItem">
                                    {getFieldDecorator('sectionId', {
                                        initialValue: this.state.section_id,
                                        rules: [{ required: true, message: 'Please select section!' }],
                                    })(
                                        <Select placeholder="Please select section" onChange={this.onChangeSection}>
                                            {this.state.section_list.map((data, index) =>
                                                <Option key={data._id} value={data._id}>{data.name}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Select SubSection" className="customFormItem">
                                    {getFieldDecorator('subSectionId', {
                                        initialValue: this.state.sub_section_id,
                                        rules: [{ required: true, message: 'Please select subsection!' }],
                                    })(
                                        <Select placeholder="Please select subsection"
                                            onChange={this.onChangeSubSection}>
                                            {this.state.sub_section_list.map((data, index) =>
                                                <Option key={data._id} value={data._id}>{data.name}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>
                            </React.Fragment>
                            : ""}

                        {this.state.firstlevel === true ? ("")
                            : (this.state.fromSingleUpload === true ?
                                <SkuAdd rootInfo={this.state.root}
                                    visibleForAddSku={this.state.visibleForAddSku}
                                    closeAddSku={this.closeAddSku}
                                    closeAddFromResponse={this.closeAddFromResponse} />
                                :
                                <Spin tip="Please wait Inventory uploading..."
                                    spinning={this.state.isUploadLoading}>
                                    <Dragger {...props} >
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="file" />
                                        </p>
                                        <p className="ant-upload-text">Click Or Drag File here</p>
                                    </Dragger>
                                </Spin>
                            )}

                        {/* {this.state.firstlevel === true ? "" :
                            <Spin tip="Please wait Inventory uploading..." spinning={this.state.isUploadLoading}>
                                <Dragger {...props} >
                                    <p className="ant-upload-drag-icon">
                                        <Icon type="file"/>
                                    </p>
                                    <p className="ant-upload-text">Click Or Drag File here</p>
                                </Dragger>
                            </Spin>
                        }*/}
                    </Form>
                    <Typography style={{ display: 'flex', flexDirection: "row-reverse" }}>
                       
                        <ExcelFile
                            filename={`Inventory - ${new Date()}`}
                            element={
                                <a>Click here to download Import template</a>
                            }>
                            <ExcelSheet data={this.state.dataforExport} name='Inventory'>
                                <ExcelColumn label='RFID_Number' value='rfid_number' />
                                <ExcelColumn label='Bag_Number' value='sku_number' />
                                <ExcelColumn label='Dsg_Code' value='design_code' />
                                <ExcelColumn label='Suffix' value='suffix' />
                                <ExcelColumn label='Size' value='sku_size' />
                                <ExcelColumn
                                    label='Cust_Dsg_Cd'
                                    value='customer_design_category'
                                />
                                <ExcelColumn label='Dsg_Ctg' value='design_category' />
                                <ExcelColumn label='Dsg_Col' value='design_color' />
                                <ExcelColumn label='Bag_Qty' value='sku_quantity' />
                                <ExcelColumn label='Sales_Ctg' value='sales_category' />
                                <ExcelColumn label='Set_Code' value='set_code' />
                                <ExcelColumn label='Collection' value='sku_collection' />
                                <ExcelColumn label='Project' value='project' />
                                <ExcelColumn label='Metal_Type' value='metal_type' />
                                <ExcelColumn label='Karatage' value='purity' />
                                <ExcelColumn
                                    label='Unit_Of_Measurement'
                                    value='unit_of_measurement'
                                />
                                <ExcelColumn label='Gross_Wgt' value='gross_weight' />
                                <ExcelColumn label='Net_Wgt' value='net_weight' />
                                <ExcelColumn label='Dia_Wgt' value='diamond_weight' />
                                <ExcelColumn label='Dia_Qty' value='diamond_quantity' />
                                <ExcelColumn label='Col_Stn_Wgt' value='color_stone_weight' />
                                <ExcelColumn label='Col_Stn_Qty' value='color_stone_quantity' />
                                <ExcelColumn label='Fnd_Wgt' value='finding_weight' />
                                <ExcelColumn label='Fnd_Qty' value='finding_quantity' />
                                <ExcelColumn label='Sales_Value' value='sales_value' />
                                <ExcelColumn label='Dia_Value' value='diamond_value' />
                                <ExcelColumn label='Col_Stn_Value' value='color_stone_value' />
                                <ExcelColumn label='Gold_Value' value='gold_value' />
                                <ExcelColumn label='Finding_Value' value='finding_value' />
                                <ExcelColumn label='Labour_Value' value='labour_value' />
                                <ExcelColumn label='Gold_Rate' value='gold_rate' />
                                <ExcelColumn label='Silver_Rate' value='silver_rate' />
                                <ExcelColumn label='Platinum_Rate' value='platinum_rate' />
                                <ExcelColumn label='Counter' value='counter' />
                                <ExcelColumn label='Tray_Number' value='tray_number' />
                                <ExcelColumn
                                    label='Retail_Dia_Value'
                                    value='retail_diamond_value'
                                />
                                <ExcelColumn label='Retail_Value' value='retail_value' />
                                <ExcelColumn label='Asking_Value' value='asking_value' />
                                <ExcelColumn
                                    label='Asking_Dia_Value'
                                    value='asking_diamond_value'
                                />
                                <ExcelColumn label='field_1' value='field_1' />
                                <ExcelColumn label='field_2' value='field_2' />
                                <ExcelColumn label='field_3' value='field_3' />
                                <ExcelColumn label='field_4' value='field_4' />
                                <ExcelColumn label='field_5' value='field_5' />
                                <ExcelColumn label='field_6' value='field_6' />
                                <ExcelColumn label='field_7' value='field_7' />
                                <ExcelColumn label='field_8' value='field_8' />
                                <ExcelColumn label='field_9' value='field_9' />
                                <ExcelColumn label='field_10' value='field_10' />
                                <ExcelColumn label='field_11' value='field_11' />
                                <ExcelColumn label='field_12' value='field_12' />
                                <ExcelColumn label='field_13' value='field_13' />
                                <ExcelColumn label='field_14' value='field_14' />
                                <ExcelColumn label='field_15' value='field_15' />
                            </ExcelSheet>
                        </ExcelFile>
                    </Typography>
                </Modal>
                <Modal
                    title="Following Duplicate Inventory found"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    //     confirmLoading={confirmLoading}
                    style={{ paddingBottom: "0px" }}
                    width={1150}
                    onCancel={this.handleCancel}
                    footer={[
                        <span style={{ marginBottom: "20px" }}>
                            <Button key="back" onClick={this.handleCancel} ghost>Skip</Button>
                            <Button key="submit" type="primary" onClick={this.handleOk}>
                                Replace
                            </Button>
                        </span>
                    ]}

                >
                    <Table
                        columns={columns}
                        dataSource={this.state.dublicate_data}
                        size={"small"}
                        rowKey={record => record.id}
                        style={{ marginBottom: "0px" }}
                    />
                </Modal>


            </React.Fragment>
        )


    }
}

StockUpload = Form.create()(StockUpload);

function mapStateToProps(state) {
    const { Common } = state
    const { file_upload_status: file_upload_status, file_upload_stock_response: file_upload_stock_response, replace_stock: replace_stock, branch_list: branch_list, floor_list: floor_list, section_list: section_list, sub_section_list: sub_section_list } = Common
    return {
        file_upload_status,
        file_upload_stock_response,
        replace_stock,
        branch_list,
        floor_list,
        section_list,
        sub_section_list
    }
}

const AppContainer = connect(
    mapStateToProps
)(StockUpload);

export default AppContainer;

