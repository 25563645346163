import React, { Component } from 'react'
import {connect} from "react-redux";
import {getAllBrachList,deleteBranch} from '../../../actions/index';
import {Card, Table,Form,Row,Col,Radio,Select,Input,Button,Icon,Popconfirm,Alert, message} from "antd";
import {Link} from "react-router-dom";
import AddCustomer from "./add";
import Edit from "./edit";
import {userAccess} from "../../../containers/App/commanFunction";
message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

class ManageBranch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      table_loading:true,
      search:'',
      visible: false,
      loading:true,

    };
  }
  componentDidMount() {
    const { dispatch } = this.props; 
    dispatch(getAllBrachList());
  }
  componentDidUpdate(prevProps, prevState) {

    try {
    
    if (prevProps.branch_list != this.props.branch_list) {
      if(this.props.branch_list.status)
      {
          this.setState({
            data:this.props.branch_list.data_array
          })
      } 
      this.setState({
        table_loading:false
       })
       
    } 

    
    if (prevProps.deletet_branch != this.props.deletet_branch) {
      if(this.props.deletet_branch.status)
      {
         message.success("branch delete  successfully");
         const { dispatch } = this.props; 
         dispatch(getAllBrachList());
      }else{
         message.error(this.props.deletet_branch.errors.msg);
      } 
    } 

    if (prevProps.update_branch_data != this.props.update_branch_data) {
       if(this.props.update_branch_data.status)
       {
          message.success("Update successfully");
       }else{
          message.error(this.props.update_branch_data.errors.msg);
       } 
     } 
    
  
    } catch (error) {
      console.log(error);
    }
   
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
    const { dispatch } = this.props;
   // dispatch(getCustomerDataExport(0,100));  
  }

  deleteActiveBranch = (e) => {
    //e.isActive= !e.isActive;
    const { dispatch } = this.props;
     dispatch(deleteBranch(e._id));  
   }
   
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {dispatch} = this.props;
       // let userdata=values;
       
      
      }
    });
  }
  

  handleCancel = () => {
    this.setState({
      visible: false,
      loading:true
    });
    
  }

  

  render() {
    const { getFieldDecorator } = this.props.form;
    let data = this.state.data
    let {loading} =this.state;
    this.columns = [  
      {
        title: 'NAME',
        dataIndex: 'name',
       // width: '10%',
        render: (text, record) => {
          const { finger_index } = record
          var s = text;
            //  s = s.substring(0, s.lastIndexOf('/'));
          return (
            <span>
                {s}
            </span>
          );
        }
      },
      {
        title: 'CONTACT NO',
        dataIndex: 'phone',
    //    width: '10%',
        render: (text, record) => {
          const { Stocktally } = record;
          return (
            <span>
                {text}
            </span>
          );
        },
        
      },
      {
        title: 'ADDRESS',
        dataIndex: 'address',
      //  width: '80%',
        render: (text, record) => {
          const { Stocktally } = record;
          return (
            <span style={{textAlign:"justify"}}>
            {text}
            </span>
          );
        }
      },
      // {
      //   title: 'Action',
      //   dataIndex: '_id',
      //   width: '10%',
      //   render: (text, record) => {
      //     const { Haribhagat } = record;
      //     return (
      //       <span>          
      //         <div className="editable-row-operations" >
      //         <Edit record={record}/>
      //         </div>
      //       </span>
      //     );
      //   }
      // },
      {
        title: 'Action',
    //    width: '10%',
        render: (text, record) => {
          let {isActive }=record;
          return (
            <span>
            {userAccess("edit","branch") === 'true' ?
                <Edit record={record}/>
            :""}
            {userAccess("delete","branch") === 'true' ?
            <Popconfirm
            title="Are you sure delete branch ?"
            onConfirm={()=>this.deleteActiveBranch(record)}
            //onCancel={cancel}
            okText="Yes"
            cancelText="No"
          > 
              {/* {isActive ?  <Icon type="check-circle" theme="filled" theme="twoTone" twoToneColor="#52c41a" style={{fontSize: 20,paddingLeft:"20px",cursor:"pointer"}} /> :
              <Icon type="check-circle" style={{fontSize: 20,paddingLeft:"20px",cursor:"pointer"}} />
              } */}
              <Icon type="delete" theme="filled"  style={{fontSize: 20,paddingLeft:"20px",cursor:"pointer"}} />
            </Popconfirm>
            :""}
            </span>
          );
        },
        
      },
    
    ];
		if (this.state.search) {
			data = data.filter(row => {
        return row.name.toUpperCase().includes(this.state.search.toUpperCase()) ||  String(row.phone).includes(this.state.search)
        //row.name.toUpperCase().includes(this.state.search.toUpperCase())
			})
    }
    const userAccess1=userAccess("add","branch");
    return (
      <div>
      <Row>
      <Col lg={24} md={24} sm={24} xs={24}><h2 className="title gx-mb-4"  style={{float:"left",paddingRight:"12px"}}>Manage </h2>
      <RadioGroup  
      defaultValue="branch"
      >                     
        <RadioButton  key={"branch"} value={"branch"}><Link to="/manageBranch">Branch </Link></RadioButton>
        <RadioButton  key={"floor"} value={"floor"}><Link to="/manageFloor">Floor </Link> </RadioButton>
        <RadioButton  key={"Section"} value={"Section"}><Link to="/manageSection">Section </Link></RadioButton>
        <RadioButton  key={"SubSection"} value={"SubSection"}><Link to="/manageSubSection">SubSection </Link></RadioButton>
     </RadioGroup>
    </Col>
    </Row>
      {/** 
      <Icon type="download" style={{float:"right",fontSize:"27px",cursor:"pointer"}}  className="certain-category-icon" onClick={this.createCustomer}>this</Icon>
      */}
 
   
      <div className="certain-category-search-wrapper">  
      {userAccess1 == "true" ? 
      <Row>
      <Col lg={21} md={20} sm={24} xs={24}>
      <Input 	value={this.state.search}  placeholder="Search .." style={{width:"100%",float:"left"}}
      onChange={e => this.setState({search: e.target.value})}  suffix={<Icon type="search" className="certain-category-icon"/>}/>
      
      </Col>
           <Col lg={3} md={4} sm={24} xs={24}>
            <AddCustomer/>
            </Col>
          </Row>  
        :
        <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{paddingBottom:"15px"}}>
              <Input 	value={this.state.search}  placeholder="Search .." style={{width:"100%",float:"left"}}
              onChange={e => this.setState({search: e.target.value})}  suffix={<Icon type="search" className="certain-category-icon"/>}/>
            </Col>
        </Row>
        }
                  
       </div>



           <Card bodyStyle={{ padding: "10px 0px" }}> <Table className="gx-table-responsive"
                  loading={this.state.table_loading} 
                  columns={this.columns} 
                  dataSource={data}
                  size={"small"}
                  rowKey={record => record._id}
                  onChange={this.handleTableChange}    
                />
                </Card>
      </div>
    )
  }
}

ManageBranch = Form.create()(ManageBranch);
function mapStateToProps(state) {
  const {Common } = state
  const {branch_list:branch_list,update_branch_data:update_branch_data,deletet_branch:deletet_branch} =  Common
  return {branch_list,update_branch_data,deletet_branch}
}

const AppContainer = connect( 
  mapStateToProps 
)(ManageBranch);


export default AppContainer;



