import {
    ACTION_REPORT,
    ADD_CUSTOMER_MASTER,
    ADD_INCREMENT_MASTER,
    ADD_SINGLE_STOCK,
    ALL_SALES_PERSONS,
    BILLING_DETAILS,
    TRANSFER_DETAILS,
    BRANCH_LIST,
    CHECK_USER_DATA,
    COMPANY_DETAILS,
    CREATE_BRANCH,
    CREATE_CURRANCY,
    CREATE_CUSTOMER,
    CREATE_FLOOR,
    CREATE_METAL,
    CREATE_SECTION,
    CREATE_SUB_SECTION,
    CREATE_TAX,
    CREATE_USER,
    CREATE_VANUE,
    CUSTOMER_CONVERSATION_RATIO_VIEW,
    CUSTOMER_MASTER,
    CUSTOMER_REPLACE_DETAILS,
    CUSTOMERS_DETAILS,
    DEAD_STOCKS,
    DELETE_ALL_STOCK,
    DELETE_ALL_STOCK_ROOT_INFO,
    DELETE_CUSTOMER,
    DELETET_BRANCH,
    DELETET_FLOOR,
    DELETET_SECTIONDATA,
    DELETET_SUBSECTIONDATA,
    DISPLAY_CONFIG_DETAILS,
    EMPLOYEE_DETAILS,
    FILE_UPLOAD_CUSTOMER_RESPONSE,
    FILE_UPLOAD_STATUS,
    FILE_UPLOAD_STOCK_RESPONSE,
    FLOOR_LIST,
    FORGOT_PASS,
    FTP_SYNC,
    GET_CURRANCY,
    GET_CUTOMER_REPORT,
    GET_EMPLOYEE_REPORT,
    GET_FILTTER,
    GET_MEDIA,
    GET_METAL,
    GET_OTP_STOCK_DELETE,
    GET_PURITY,
    GET_STOCK,
    AGING_DETAILS,
    GET_SYNC,
    GET_TAX,
    GET_VANUE,
    GET_VERSION,
    LOGOUT,
    MEDIA_CONFIG_DETAILS,
    MEMO_DETAILS,
    MULTIPLE_SKU_ITEMS,
    ORDER_DETAILS,
    PDF,
    POST_FILTTER,
    POST_MEDIA,
    POST_SKULABLE,
    PUT_CURRANCY,
    PUT_METAL,
    PUT_TAX,
    PUT_VANUE,
    QUATATION_DETAILS,
    RECEIVE_LOGIN,
    REMOVE_CUSTOMER_MASTER,
    REPLACE_STOCK,
    RESET_PASS,
    RFIDDEVICE_INFO_DETAILS,
    SALES_AUDIT,
    SALES_DETAILS,
    SEARCH_CUSTOMERS_DETAILS,
    SECTION_LIST,
    SKU_ITEM_DETAILS,
    SKU_UPDATE,
    SKULABEL_DETAILS,
    SPIN_LOADING,
    STOCKTALLY_DETAILS,
    STOCKTALLY_EXPORTDETAILS,
    SUB_SECTION_LIST,
    TRY_ACTIVITIES,
    UPDATE_BRANCH_DATA,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_MASTER,
    UPDATE_PRN_DATA,
    UPDATE_SECTION_DATA,
    UPDATE_SUBSECTION_DATA,
    UPDATE_USER,
    UPDATE_USER_DATA,
    USER_DATA,
    USER_LIST,
    VERIFY_OTP_STOCK_DELETE,
    VIEW_ON_DEMAND_VIEW,
    GET_SOlD_STOCK,
    GET_SOLD_STOCK_EXCEL,
    SKU_DATA_BY_BRANCH,
    SKU_DATA,
    SKU_TRANSFER,
    SKU_WEBTRANSFER,
    GET_SETTINGS,
    POST_SETTINGS,
    UPDATE_SETTINGS,
    GET_SCAN_SESSION,
    POST_SCAN_SESSION,
    UPDATE_SCAN_SESSION,
    GET_SKU_MEDIA,
    UPDATE_COMPANY_DATA,
} from "../actions/index";

function commonReducer(
    state = {
        login_user_data: [],
        logout: [],
        quotation_details: [],
        //comman
        customer_details: [],
        get_stock: [],
        aging_details: [],
        delete_all_stock: [],
        sales_details: [],
        stocktally_details: [],
        stocktally_exportdetails: [],
        branch_list: [],
        section_list: [],
        floor_list: [],
        sku_item_details: [],
        customer_conversation_ratio_view: [],
        view_on_demand_view: [],
        get_sold_stock_xl: [],
        get_sold_stock: [],
        sku_transfer: [],
        sku_webtransfer: [],
        sku_from_excel: [],
        sku_by_branch: [],
        all_sales_persons: [],
        sales_audit: [],
        rfiddevice_info_details: [],
        create_customer: [],
        forgot_pass: [],
        reset_pass: [],
        memo_details: [],
        order_details: [],
        delete_all_stock_root_info: [],
        file_upload_stock_response: [],
        replace_stock: [],
        create_branch: [],
        create_floor: [],
        create_section: [],
        create_sub_section: [],
        sub_section_list: [],
        user_list: [],
        create_user: [],
        check_user_data: [],
        user_data: [],
        update_user_data: [],
        update_branch_data: [],
        update_section_data: [],
        update_subsection_data: [],
        update_customer: [],
        deletet_customer: [],
        customer_master: [],
        add_customer_master: [],
        update_customer_master: [],
        remove_customer_master: [],
        increment_master_info: [],
        add_increment_master: [],
        action_report: [],
        dead_stocks: [],
        multiple_sku_items: [],
        pdf: [],
        search_customer_details: [],
        get_cutomer_report: [],
        employee_details: [],
        update_user: [],
        get_employee_report: [],
        get_vanue: [],
        create_vanue: [],
        put_vanue: [],
        get_tax: [],
        create_tax: [],
        put_tax: [],
        get_metal: [],
        create_metal: [],
        put_metal: [],
        get_currancy: [],
        create_currancy: [],
        put_currancy: [],
        get_sync: [],
        spin_loading: '',
        get_version: [],
        file_upload_status: [],
        deletet_branch: [],
        deletet_floor: [],
        deletet_SectionData: [],
        deletet_SubSectionData: [],
        ftp_sync: [],
        file_upload_customer_response: [],
        customer_replace_details: [],
        company_details: [],
        skulabel_details: [],
        post_skulable: [],
        get_filtter: [],
        post_filtter: [],
        get_media: [],
        post_media: [],
        sku_update: [],
        update_prn_data: [],
        otp_stock_delete: [],
        verify_otp_stock_delete: [],
        display_config_details: [],
        add_single_stock: [],
        media_config_details: [],
        billing_details: [],
        transfer_details: [],
        get_purtiy: [],
        get_settings: [],
        post_settings: [],
        update_settings: [],
        get_scan_session: [],
        post_scan_session: [],
        update_scan_session: [],
        get_sku_media: [],
        update_company_data: [],
    },
    action
) {
    switch (action.type) {
        case RECEIVE_LOGIN:
            return Object.assign({}, state, {
                login_user_data: action.login_user_data,
            });

        case LOGOUT:
            return Object.assign({}, state, {
                logout: action.logout,
            });

        case TRY_ACTIVITIES:
            return Object.assign({}, state, {
                try_activities: action.try_activities,
            });

        case QUATATION_DETAILS:
            return Object.assign({}, state, {
                quotation_details: action.quotation_details,
            });

        case CUSTOMERS_DETAILS:
            return Object.assign({}, state, {
                customer_details: action.customer_details,
            });

        case GET_STOCK:
            return Object.assign({}, state, {
                get_stock: action.get_stock,
            });

        case AGING_DETAILS:
            return Object.assign({}, state, {
                aging_details: action.aging_details,
            });

        case DELETE_ALL_STOCK:
            return Object.assign({}, state, {
                delete_all_stock: action.delete_all_stock,
            });

        case SALES_DETAILS:
            return Object.assign({}, state, {
                sales_details: action.sales_details,
            });

        case STOCKTALLY_DETAILS:
            return Object.assign({}, state, {
                stocktally_details: action.stocktally_details,
            });

        case STOCKTALLY_EXPORTDETAILS:
            return Object.assign({}, state, {
                stocktally_exportdetails: action.stocktally_exportdetails,
            });

        case BRANCH_LIST:
            return Object.assign({}, state, {
                branch_list: action.branch_list,
            });

        case SECTION_LIST:
            return Object.assign({}, state, {
                section_list: action.section_list,
            });

        case FLOOR_LIST:
            return Object.assign({}, state, {
                floor_list: action.floor_list,
            });

        case SKU_ITEM_DETAILS:
            return Object.assign({}, state, {
                sku_item_details: action.sku_item_details,
            });

        case CUSTOMER_CONVERSATION_RATIO_VIEW:
            return Object.assign({}, state, {
                customer_conversation_ratio_view:
                action.customer_conversation_ratio_view,
            });

        case VIEW_ON_DEMAND_VIEW:
            return Object.assign({}, state, {
                view_on_demand_view: action.view_on_demand_view,
            });

        case GET_SOlD_STOCK:
            return Object.assign({}, state, {
                get_sold_stock: action.get_sold_stock,
            });

        case GET_SOLD_STOCK_EXCEL:
            return Object.assign({}, state, {
                get_sold_stock_xl: action.get_sold_stock_xl,
            });

        case SKU_TRANSFER:
            return Object.assign({}, state, {
                sku_transfer: action.sku_transfer,
            });

        case SKU_WEBTRANSFER:
            return Object.assign({}, state, {
                sku_webtransfer: action.sku_webtransfer,
            });

        case SKU_DATA:
            return Object.assign({}, state, {
                sku_from_excel: action.sku_from_excel,
            });

        case SKU_DATA_BY_BRANCH:
            return Object.assign({}, state, {
                sku_by_branch: action.sku_by_branch,
            });

        case ALL_SALES_PERSONS:
            return Object.assign({}, state, {
                all_sales_persons: action.all_sales_persons,
            });

        case SALES_AUDIT:
            return Object.assign({}, state, {
                sales_audit: action.sales_audit,
            });

        case RFIDDEVICE_INFO_DETAILS:
            return Object.assign({}, state, {
                rfiddevice_info_details: action.rfiddevice_info_details,
            });

        case CREATE_CUSTOMER:
            return Object.assign({}, state, {
                create_customer: action.create_customer,
            });

        case FORGOT_PASS:
            return Object.assign({}, state, {
                forgot_pass: action.forgot_pass,
            });

        case RESET_PASS:
            return Object.assign({}, state, {
                reset_pass: action.reset_pass,
            });

        case MEMO_DETAILS:
            return Object.assign({}, state, {
                memo_details: action.memo_details,
            });

        case ORDER_DETAILS:
            return Object.assign({}, state, {
                order_details: action.order_details,
            });

        case DELETE_ALL_STOCK_ROOT_INFO:
            return Object.assign({}, state, {
                delete_all_stock_root_info: action.delete_all_stock_root_info,
            });

        case FILE_UPLOAD_STOCK_RESPONSE:
            return Object.assign({}, state, {
                file_upload_stock_response: action.file_upload_stock_response,
            });

        case REPLACE_STOCK:
            return Object.assign({}, state, {
                replace_stock: action.replace_stock,
            });

        case CREATE_BRANCH:
            return Object.assign({}, state, {
                create_branch: action.create_branch,
            });

        case CREATE_FLOOR:
            return Object.assign({}, state, {
                create_floor: action.create_floor,
            });

        case CREATE_SUB_SECTION:
            return Object.assign({}, state, {
                create_sub_section: action.create_sub_section,
            });

        case CREATE_SECTION:
            return Object.assign({}, state, {
                create_section: action.create_section,
            });

        case SUB_SECTION_LIST:
            return Object.assign({}, state, {
                sub_section_list: action.sub_section_list,
            });
        case USER_LIST:
            return Object.assign({}, state, {
                user_list: action.user_list,
            });

        case CREATE_USER:
            return Object.assign({}, state, {
                create_user: action.create_user,
            });

        case CHECK_USER_DATA:
            return Object.assign({}, state, {
                check_user_data: action.check_user_data,
            });

        case USER_DATA:
            return Object.assign({}, state, {
                user_data: action.user_data,
            });

        case UPDATE_USER_DATA:
            return Object.assign({}, state, {
                update_user_data: action.update_user_data,
            });

        case UPDATE_BRANCH_DATA:
            return Object.assign({}, state, {
                update_branch_data: action.update_branch_data,
            });

        case UPDATE_SECTION_DATA:
            return Object.assign({}, state, {
                update_section_data: action.update_section_data,
            });

        case UPDATE_SUBSECTION_DATA:
            return Object.assign({}, state, {
                update_subsection_data: action.update_subsection_data,
            });

        case UPDATE_CUSTOMER:
            return Object.assign({}, state, {
                update_customer: action.update_customer,
            });

        case DELETE_CUSTOMER:
            return Object.assign({}, state, {
                deletet_customer: action.deletet_customer,
            });

        case CUSTOMER_MASTER:
            return Object.assign({}, state, {
                customer_master: action.customer_master,
            });
        case ADD_CUSTOMER_MASTER:
            return Object.assign({}, state, {
                add_customer_master: action.add_customer_master,
            });
        case UPDATE_CUSTOMER_MASTER:
            return Object.assign({}, state, {
                update_customer_master: action.update_customer_master,
            });
        case REMOVE_CUSTOMER_MASTER:
            return Object.assign({}, state, {
                remove_customer_master: action.remove_customer_master,
            });
        case ADD_INCREMENT_MASTER:
            return Object.assign({}, state, {
                add_increment_master: action.add_increment_master,
            });

        case ACTION_REPORT:
            return Object.assign({}, state, {
                action_report: action.action_report,
            });

        case DEAD_STOCKS:
            return Object.assign({}, state, {
                dead_stocks: action.dead_stocks,
            });

        case MULTIPLE_SKU_ITEMS:
            return Object.assign({}, state, {
                multiple_sku_items: action.multiple_sku_items,
            });
        case PDF:
            return Object.assign({}, state, {
                pdf: action.pdf,
            });
        case SEARCH_CUSTOMERS_DETAILS:
            return Object.assign({}, state, {
                search_customer_details: action.search_customer_details,
            });

        case GET_CUTOMER_REPORT:
            return Object.assign({}, state, {
                get_cutomer_report: action.get_cutomer_report,
            });
        case EMPLOYEE_DETAILS:
            return Object.assign({}, state, {
                employee_details: action.employee_details,
            });

        case UPDATE_USER:
            return Object.assign({}, state, {
                update_user: action.update_user,
            });
        case GET_EMPLOYEE_REPORT:
            return Object.assign({}, state, {
                get_employee_report: action.get_employee_report,
            });

        case CREATE_VANUE:
            return Object.assign({}, state, {
                create_vanue: action.create_vanue,
            });
        case GET_VANUE:
            return Object.assign({}, state, {
                get_vanue: action.get_vanue,
            });

        case PUT_VANUE:
            return Object.assign({}, state, {
                put_vanue: action.put_vanue,
            });

        case CREATE_TAX:
            return Object.assign({}, state, {
                create_tax: action.create_tax,
            });
        case GET_TAX:
            return Object.assign({}, state, {
                get_tax: action.get_tax,
            });

        case PUT_TAX:
            return Object.assign({}, state, {
                put_tax: action.put_tax,
            });

        case CREATE_METAL:
            return Object.assign({}, state, {
                create_metal: action.create_metal,
            });
        case GET_METAL:
            return Object.assign({}, state, {
                get_metal: action.get_metal,
            });

        case PUT_METAL:
            return Object.assign({}, state, {
                put_metal: action.put_metal,
            });
        case CREATE_CURRANCY:
            return Object.assign({}, state, {
                create_currancy: action.create_currancy,
            });
        case GET_CURRANCY:
            return Object.assign({}, state, {
                get_currancy: action.get_currancy,
            });

        case PUT_CURRANCY:
            return Object.assign({}, state, {
                put_currancy: action.put_currancy,
            });

        case GET_SYNC:
            return Object.assign({}, state, {
                get_sync: action.get_sync,
            });

        case SPIN_LOADING:
            return Object.assign({}, state, {
                spin_loading: action.spin_loading,
            });

        case GET_VERSION:
            return Object.assign({}, state, {
                get_version: action.get_version,
            });

        case FILE_UPLOAD_STATUS:
            return Object.assign({}, state, {
                file_upload_status: action.file_upload_status,
            });

        case DELETET_BRANCH:
            return Object.assign({}, state, {
                deletet_branch: action.deletet_branch,
            });

        case DELETET_FLOOR:
            return Object.assign({}, state, {
                deletet_floor: action.deletet_floor,
            });
        case DELETET_SECTIONDATA:
            return Object.assign({}, state, {
                deletet_SectionData: action.deletet_SectionData,
            });
        case DELETET_SUBSECTIONDATA:
            return Object.assign({}, state, {
                deletet_SubSectionData: action.deletet_SubSectionData,
            });

        case FTP_SYNC:
            return Object.assign({}, state, {
                ftp_sync: action.ftp_sync,
            });

        case FILE_UPLOAD_CUSTOMER_RESPONSE:
            return Object.assign({}, state, {
                file_upload_customer_response: action.file_upload_customer_response,
            });

        case CUSTOMER_REPLACE_DETAILS:
            return Object.assign({}, state, {
                customer_replace_details: action.customer_replace_details,
            });

        case COMPANY_DETAILS:
            return Object.assign({}, state, {
                company_details: action.company_details,
            });

        case SKULABEL_DETAILS:
            return Object.assign({}, state, {
                skulabel_details: action.skulabel_details,
            });

        case DISPLAY_CONFIG_DETAILS:
            return Object.assign({}, state, {
                display_config_details: action.display_config_details,
            });

        case MEDIA_CONFIG_DETAILS:
            return Object.assign({}, state, {
                media_config_details: action.media_config_details,
            });

        case POST_SKULABLE:
            return Object.assign({}, state, {
                post_skulable: action.post_skulable,
            });

        case GET_FILTTER:
            return Object.assign({}, state, {
                get_filtter: action.get_filtter,
            });

        case POST_FILTTER:
            return Object.assign({}, state, {
                post_filtter: action.post_filtter,
            });

        case GET_MEDIA:
            return Object.assign({}, state, {
                get_media: action.get_media,
            });

        case POST_MEDIA:
            return Object.assign({}, state, {
                post_media: action.post_media,
            });

        case SKU_UPDATE:
            return Object.assign({}, state, {
                sku_update: action.sku_update,
            });

        case UPDATE_PRN_DATA:
            return Object.assign({}, state, {
                update_prn_data: action.update_prn_data,
            });

        case GET_OTP_STOCK_DELETE:
            return Object.assign({}, state, {
                otp_stock_delete: action.otp_stock_delete,
            });

        case VERIFY_OTP_STOCK_DELETE:
            return Object.assign({}, state, {
                verify_otp_stock_delete: action.verify_otp_stock_delete,
            });

        case ADD_SINGLE_STOCK:
            return Object.assign({}, state, {
                add_single_stock: action.add_single_stock,
            });

        case BILLING_DETAILS:
            return Object.assign({}, state, {
                billing_details: action.billing_details,
            });
        case TRANSFER_DETAILS:
            return Object.assign({}, state, {
                transfer_details: action.transfer_details,
            });

        case GET_PURITY:
            return Object.assign({}, state, {
                get_purity: action.get_purity,
            });

        case GET_SETTINGS:
            return Object.assign({}, state, {
                get_settings: action.get_settings,
            });

        case POST_SETTINGS:
            return Object.assign({}, state, {
                post_settings: action.post_settings,
            });

        case UPDATE_SETTINGS:
            return Object.assign({}, state, {
                update_settings: action.update_settings,
            });
        case GET_SCAN_SESSION:
            return Object.assign({}, state, {
                get_scan_session: action.get_scan_session,
            });

        case POST_SCAN_SESSION:
            return Object.assign({}, state, {
                post_scan_session: action.post_scan_session,
            });

        case UPDATE_SCAN_SESSION:
            return Object.assign({}, state, {
                update_scan_session: action.update_scan_session,
            });

        case GET_SKU_MEDIA:
            return Object.assign({}, state, {
                get_sku_media: action.get_sku_media,
            });
        case UPDATE_COMPANY_DATA:
            return Object.assign({}, state, {
                update_company_data: action.update_company_data,
            });

        default:
            return state;
    }
}

export default commonReducer;
