import React, {Component} from 'react'
import IntlMessages from "util/IntlMessages";
import {connect} from "react-redux";
import {Button, Card, Col, Divider, Empty, Form, Icon, message, Row, Spin, Table,} from "antd";
import {getSKUbyID, getSKUMedia} from '../../../actions/index';
import nodataImage from "./../../../assets/images/nodata.png";
import EditIcon from "../../../assets/images/icons/edit-icon.svg";
import {currencyConverter} from "../../../containers/App/commanFunction";
import SkuEdit from "./editRM";
import SkuEditDetail from "./editSku";
import TagPrintDialog from "./tagPrintDailog";

// ***************** Commented media view *********************** //
// import '../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import ReactPlayer from "react-player";
// ***************** Commented media view *********************** //
// import ImageGallery from 'react-image-gallery';
// import "../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";

// import "node_modules/react-image-gallery/styles/css/image-gallery.css";

// ***************** Commented media view *********************** //
const isImageUrl = require('is-image-url');

// ***************** Commented media view *********************** //

class Stocks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {rm: []},
            table_loading: true,
            newtWetStart: 0,
            newtWetEnd: 0,
            GrossWTStart: 2,
            GrossWTEnd: 10,
            sizeStart: 10,
            sizeEnd: 20,
            gridview: false,
            rmdata: [],
            imaage_loading: true,
            visibleForEditSku: false,
            visibleForEditSkud: false,
            displayConfig: {},
            Lablekeys: [],
            // ***************** Commented media view *********************** //
            showVideo: {},
            selectedMedia: 0,
            medias: []//["https://html5demos.com/assets/dizzy.mp4", "https://www.youtube.com/watch?v=o9aaoiyJlcM", "https://images-na.ssl-images-amazon.com/images/I/61RgdKLdwDL._UY395_.jpg", "https://storage.googleapis.com/exoplayer-test-media-1/gen-3/screens/dash-vod-single-segment/video-vp9-360.webm", "http://techslides.com/demos/sample-videos/small.ogv"]
            // ***************** Commented media view *********************** //
        }
        ;
        // medias: ["https://i.pinimg.com/originals/af/48/7f/af487f88743830a0690ad71ae44ec439.jpg", "https://images.indianexpress.com/2020/05/gold-jewellery_1200-Copy.jpg", "https://images-na.ssl-images-amazon.com/images/I/61RgdKLdwDL._UY395_.jpg", "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ0GqLbzJQzLYyUfuu_gtyQjN6nmpNagWwN1Q&usqp=CAU"]
        // medias: ["https://html5demos.com/assets/dizzy.mp4", "https://www.youtube.com/watch?v=o9aaoiyJlcM", "https://images-na.ssl-images-amazon.com/images/I/61RgdKLdwDL._UY395_.jpg", "https://storage.googleapis.com/exoplayer-test-media-1/gen-3/screens/dash-vod-single-segment/video-vp9-360.webm", "http://techslides.com/demos/sample-videos/small.ogv"]

    }

    componentDidMount() {
        // const { ViewInDetails } = this.props.match.params;
        // let skuId= localStorage.getItem('sku_id');
        //       this.setState({
        //         ViewInDetails:skuId
        //       })
        // const { dispatch } = this.props;
        //  dispatch(getSKUbyID(skuId));
        // console.log("this.props.display1");
        // console.log(this.props.display);
        // this.setState({
        //   table_loading:this.props.display
        // })
    }

    // componentWillReceiveProps(nextProps){
    //   console.log("this.props.display");
    //   console.log(nextProps);
    //   console.log(this.props.display);
    //   this.setState({
    //     table_loading:this.props.display
    //   })

    // }

    componentDidUpdate(prevProps, prevState) {
        // window.onpopstate = this.onBackButtonEvent;
        // window.onpopstate = function () {
        //   console.log(location.href);
        //   let url = location.href;
        //   let arr = url.split("/");
        //   console.log(arr[3]);
        //   if(arr[3]=="jtstockView"){
        //     history.go(1);
        //   }

        // };

        if (prevProps.sku_item_details != this.props.sku_item_details) {
            // console.log(this.props.sku_item_details);
            try {
                if (this.props.sku_item_details.status) {
                    let dataArray = this.props.sku_item_details.data;
                    // let media = ["https://html5demos.com/assets/dizzy.mp4", "https://www.youtube.com/watch?v=o9aaoiyJlcM", "https://images-na.ssl-images-amazon.com/images/I/61RgdKLdwDL._UY395_.jpg", "https://storage.googleapis.com/exoplayer-test-media-1/gen-3/screens/dash-vod-single-segment/video-vp9-360.webm"];
                    // dataArray.media = media;
                    this.setState({
                        table_loading: false,
                        data: dataArray,
                        rmdata: dataArray.rm,
                        displayConfig: this.props.sku_item_details.data_array[0].display
                    });
                    console.log("ok");
                } else {
                    this.setState({
                        table_loading: false,
                        emptyShow: true,
                        data: {rm: []},
                        rmdata: [],
                        imaage_loading: false
                    })
                    message.error(this.props.sku_item_details.errors.msg);
                }

            } catch (error) {
                console.log(error);
            }

        }

        if (prevProps.skulabel_details != this.props.skulabel_details) {
            // console.log("this.props.skulabel_details");
            // console.log(this.props.skulabel_details);
            if (this.props.skulabel_details.status) {
                this.setState({
                    Lablekeys: this.props.skulabel_details.data.labels
                })
            }

        }

        if (prevProps.get_sku_media != this.props.get_sku_media) {
            // console.log("SKu Media ", this.props.get_sku_media);
            if (this.props.skulabel_details.status) {
                this.setState({medias: this.props.get_sku_media.data_array});
            }

        }
    }

    onBackButtonEvent = (e) => {
        // console.log(location.href);
        // let url = location.href;
        // let arr = url.split("/");
        // console.log(arr[3]);
        // if(arr[3]=="jtstockView"){
        //   history.go(1);
        // }
        // history.pushState(null, null, location.href);
        // window.onpopstate = function () {
        //     console.log(location.href);
        //     let url = location.href;
        //     let arr = url.split("/");
        //     if(arr[2]==="jtstockView"){
        //       history.go(1);
        //     }

        // };

    }
    onError = () => {
        //alert("error");
        // if (!this.state.errored) {
        let data = this.state.data;
        data.url = nodataImage;
        this.setState({
            data: data,
            table_loading: false,
            imaage_loading: false
        });
        // }
    }

    handleImageLoaded = () => {
        let data = this.state.data;
        //   data.url=nodataImage;
        if (data.url != nodataImage) {
            this.setState({
                table_loading: false,
                imaage_loading: false
            });
        }

    }
    handleTableChange = (pagination, filters, sorter) => {
        let colome = sorter.columnKey;
        let order = sorter.order;
        let orderopp = 0;
        let data = this.state.rmdata;
        if (order != undefined) {
            if (order === "descend") {
                order = -1;
                orderopp = 1;
            } else {
                order = 1;
                orderopp = -1;
            }
            data.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
        } else {
            const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
            shuffleArray(data);
        }
    };

    editSku = (record) => {
        this.setState({
            visibleForEditSku: true,
        });
    }

    editSkud = (record) => {
        this.setState({
            visibleForEditSkud: true,
        });
    }

    closeEditSku = (record) => {
        if (record === true) {
            const {dispatch} = this.props;
            // console.log("CAlling getsku apis from -- closeEditSku");
            dispatch(getSKUbyID(localStorage.getItem("skuid")));
            dispatch(getSKUMedia(localStorage.getItem("skuid")));
        }
        this.setState({
            visibleForEditSku: false,
        });
    }


    closeEditSkud = (record) => {
        if (record === true) {
            const {dispatch} = this.props;
            // console.log("CAlling getsku apis from -- closeEditSkud");
            dispatch(getSKUbyID(localStorage.getItem("skuid")));
            dispatch(getSKUMedia(localStorage.getItem("skuid")));
        }
        this.setState({
            visibleForEditSkud: false,
        });
    }


    backttoStock = (e) => {
        // let data=this.state.data;
        //   data.url=nodataImage;
        let data = this.state.data;
        data.url = nodataImage;
        this.setState({
            table_loading: true,
            data: data,
            rmdata: [],
            imaage_loading: true
        });
        this.props.backttoStock();
    }

    // ***************** Commented media view *********************** //
    _getImageView(url, index) {
        return (
            <div key={index}>
                <img alt="" src={url} style={{objectFit: 'scale-down', backgroundColor: '#ffffff'}} height="300px"/>
            </div>
        );
    }

    _getVideoView(url, index) {
        return (
            <div
                key={index}
            >
                <ReactPlayer
                    width="100%"
                    url={url}
                    playing={(this.state.selectedItem === index)}
                    controls
                    light={true}
                    height="300px"
                />

                {/**/}
                {/*<iframe title="Legend 0" src={url}/>*/}
            </div>
        )
    }

    // ***************** Commented media view *********************** //

    getConfigurableProps = () => ({
        showArrows: true,
        showStatus: true,
        showIndicators: true,
        infiniteLoop: true,
        showThumbs: true,
        useKeyboardArrows: true,
        autoPlay: true,
        stopOnHover: true,
        swipeable: true,
        dynamicHeight: true,
        emulateTouch: true,
        thumbWidth: 100,
        selectedItem: 0,
        interval: 3000,
        transitionTime: 150,
        swipeScrollTolerance: 5,
    });

    render() {
        const {getFieldDecorator} = this.props.form;

        // ***************** Commented media view *********************** //
        // this.medias = ["https://html5demos.com/assets/dizzy.mp4", "https://www.youtube.com/watch?v=o9aaoiyJlcM", "https://images-na.ssl-images-amazon.com/images/I/61RgdKLdwDL._UY395_.jpg", "https://storage.googleapis.com/exoplayer-test-media-1/gen-3/screens/dash-vod-single-segment/video-vp9-360.webm", "http://techslides.com/demos/sample-videos/small.ogv"];


        const customRenderThumb = () => (this.state.data.medias === undefined) ? "" :
            this.state.data.medias.map((item) => {
                const thumbUrl = isImageUrl(item) ? item : nodataImage;//  getVideoId(item.props.url);
                return <img src={thumbUrl}/>;
            });
        // ***************** Commented was implemented to check with dummy data *********************** //
        /*const customRenderThumb = () => (this.state.data.media === undefined) ?
            this.state.medias.map((item) => {
                const thumbUrl = isImageUrl(item) ? item : nodataImage;//  getVideoId(item.props.url);
                return <img src={thumbUrl}/>;
            })
            :
            this.state.data.media.map((item) => {
                const thumbUrl = isImageUrl(item) ? item : nodataImage;//  getVideoId(item.props.url);
                return <img src={thumbUrl}/>;
            });*/
        // ***************** Commentedwas implemented to check with dummy data *********************** //

        /* const customRenderThumb = (children) =>
             children.map((item) => {
                 const thumbUrl = isImageUrl(item.props.url) ? item.props.url : nodataImage;//  getVideoId(item.props.url);
                 return <img src={thumbUrl}/>;
             });*/

        const mediaItems = (this.state.data.media === undefined) ?
            <div>
                {/* {console.log("State media is undefined")} */}
                {
                    this.state.medias.map((url, index) => {
                        (isImageUrl(url) ? this._getImageView(url, index) : this._getVideoView(url, index))
                    })
                }
            </div>
            :
            <div>
                {/* {console.log("State media has values")} */}
                {
                    this.state.data.media.map((url, index) => {
                        (isImageUrl(url) ? this._getImageView(url, index) : this._getVideoView(url, index))
                    })
                }
            </div>
        // ***************** Commented media view *********************** //


        this.columns = [
            {
                title: 'Reference',
                dataIndex: 'reference',
                sorter: (a, b) => {
                },
            },
            {
                title: 'Weight.',
                dataIndex: 'rm_wt',
                sorter: (a, b) => {
                },
            },
            // {
            //   title: 'CTG',
            //   dataIndex: 'rm_ctg',
            //   sorter: (a, b) => {},
            // },
            // {
            //   title: 'CTG DESC.',
            //   dataIndex: 'international_code',
            //   sorter: (a, b) => {},
            // },
            // {
            //   title: 'SUBJET',
            //   dataIndex: 'rm_sub_ctg',
            //   sorter: (a, b) => {},
            // },
            // {
            //   title: 'SUBJET DESC.',
            //   dataIndex: 'breadth',
            //   sorter: (a, b) => {},
            // },
            // {
            //   title:'WT',
            //   dataIndex:'rm_wt',
            //   sorter: (a, b) => {},
            //   render: (text, record) => {
            //     let reference=record.reference;
            //     return <span>{text} {reference=='C'?'ct':''}{reference=='G'?'gm':''}</span>;
            //   }
            // },
            // {
            //   title: 'QTY',
            //   dataIndex: 'rm_qty',
            //   sorter: (a, b) => {},
            // },


            // {
            //   title: 'RATE',
            //   dataIndex: 'rm_rate',
            //   sorter: (a, b) => {},
            //   render: (text, record) => {
            //     return <span>{currencyConverter(text)}</span>;
            //   }
            // },

            // {
            //   title: 'PRICE',
            //   dataIndex: 'rm_value',
            //   //width: '11%',
            //   sorter: (a, b) => {},
            //   render: (text, record) => {
            //     return <span>{currencyConverter(text)}</span>;
            //   }
            // },
            // {
            //   title: '',
            //   dataIndex: 'set_value',
            //   //width: '11%',
            //   //sorter: (a, b) => {},
            //   render: (text, record) => {
            //     return <span>{text==="D" ?<img src={Diomand} height="55px"></img>:""} {text==="T" ?<img src={Stone} height="35px" style={{marginLeft:"5px"}}></img>:""} </span>;
            //   }
            // },
            // {
            //   title: 'ACTION',
            //   dataIndex: '_id',
            //   width: '6.5%',
            //   render: (text, record) => {
            //     return (
            //         <center>
            //           <img src={EditIcon} onClick={()=>this.editSku(record)} style={{cursor:"pointer"}}></img>
            //         </center>
            //     );
            //   }
            // },

        ];
        const {displayConfig, Lablekeys} = this.state;
        // var tifOptions = [];
        // Object.keys(this.state.displayConfig).forEach(function(key) {
        //  // tifOptions.push(<option value={key}>{tifs[key]}</option>);
        // });
        var tifOptions = Object.keys(displayConfig).map(function (key) {
            var lableRetur = '';
            Lablekeys.map((k, index) => {

                if (k.label === key) {
                    if (key === "gold_value" || key === "platinum_rate" || key === "silver_rate" || key === "sales_value" || key === "finding_value" || key === "labour_value") {
                        lableRetur = <React.Fragment><h4
                            className="gx-text">{k.labelvalue}: {currencyConverter(displayConfig[key])}</h4>
                            <Divider/></React.Fragment>
                    } else {
                        lableRetur =
                            <React.Fragment><h4 className="gx-text">{k.labelvalue}: {displayConfig[key]}</h4><Divider/></React.Fragment>
                    }
                }
            })
            return lableRetur;

        });

        return (
            <div>
                <Card className="customer-top-menu-card" bodyStyle={{padding: "0"}} bordered={false}>

                    <Row>
                        <Col lg={24} md={24} sm={24} xs={24} style={{paddingLeft: "40px"}}>
                            <h6 className="customer-report-title-back icon-charvlet-back" onClick={this.backttoStock}>
                                <Icon type="left" className="text-color-4285F4"/> Back to Inventory</h6>
                            <h1 className="customer-report-title">
                                <IntlMessages id="sidebar.Stocks"/> 
                                {this.state.data.sku_number}
                                <img src={EditIcon} onClick={() => this.editSkud()}
                                     style={{cursor: "pointer", float: "right", paddingRight: "25px"}}/>
                                <TagPrintDialog record={this.state.data}/>
                            </h1>
                        </Col>
                    </Row>
                </Card>
                <Spin spinning={this.state.table_loading}>
                    {/**  <Row>


                     <Col md={24} sm={24} xs={24}>
                     <Radio.Group defaultValue="a" buttonStyle="solid" onChange={this.onChangeForView} defaultValue="list">
                     <Radio.Button value="list"><Icon type="bars" /></Radio.Button>
                     <Radio.Button value="gride"><Icon type="appstore" /></Radio.Button>
                     </Radio.Group>
                     </Col>
                     </Row>*/}
                    {this.state.emptyShow === true ?
                        <Row>
                            <Card style={{width: "100%"}}>
                                <Col md={24} sm={24} xs={24}>
                                    <Empty/>
                                </Col>
                            </Card>
                        </Row>
                        :
                        <Row>
                            <Col md={24} sm={24} xs={24}>
                                <Card bordered={false}
                                    // loading={this.state.table_loading}
                                >
                                    <Row>
                                        <Col md={8} sm={24} xs={24}>
                                            {/**
                                             <div className="gx-product-image">
                                             <div className="gx-grid-thumb-equal" style={{paddingBottom:"95%"}}>
                                             <a className="gx-grid-thumb-cover" style={{objectFit:"fill"}} href="javascript:void(0);">
                                             <img  src={this.state.data.url}  onError={this.onError} />
                                             </a>
                                             </div>
                                             </div>
                                             <center> <h4 className="gx-text">{this.state.data.sku_number}</h4></center>
                                             */}
                                            {/*<Spin spinning={this.state.imaage_loading}>
                                                <img src={this.state.data.url}
                                                     onLoad={this.handleImageLoaded}
                                                     onError={this.onError}/>
                                            </Spin>*/}

                                            {/*// ***************** Commented media view *********************** //*/}
                                            {(this.state.medias === undefined || this.state.medias === null || (this.state.medias != null && this.state.medias.length <= 0)) ?
                                                <Spin spinning={this.state.imaage_loading}>
                                                    <img src={this.state.data.url}
                                                         onLoad={this.handleImageLoaded}
                                                         onError={this.onError}/>
                                                </Spin>
                                                :
                                                <Carousel renderThumbs={customRenderThumb}
                                                          selectedItem={this.state.selectedItem}
                                                          onChange={(index => {
                                                              this.setState({selectedItem: index})
                                                          })}
                                                          {...this.getConfigurableProps} >
                                                    {this.state.medias.map((url, index) => (
                                                        isImageUrl(url) ?
                                                            this._getImageView(url, index)
                                                            :
                                                            this._getVideoView(url, index)
                                                    ))}
                                                </Carousel>}

                                            {/*// ***************** Commented media view *********************** //*/}
                                            <br/>
                                        </Col>
                                        <Col md={8} sm={24} xs={24}>
                                            {tifOptions}
                                        </Col>
                                        <Col md={8} sm={24} xs={24}>
                                            {/* <Card
                         title={<React.Fragment><p>Raw Material <Button style={{cursor:"pointer",float:"right"}} onClick={()=>this.editSku()}  className="transaction-clear-btn" ghost>Update Raw Material Details </Button></p></React.Fragment>}
                         style={{borderRadius:"8px",marginTop:"10px"}}
                         bodyStyle={{padding:0}}
                         className="Rowmatiraltype"
                    >
                
                    <Table  
                    loading={this.state.table_loading} 
                    className="gx-table-responsive" 
                    style={{paddingTop:"7px"}}
                    columns={this.columns}
                    dataSource={this.state.rmdata}
                    size={"small"}
                    rowKey={record => record.ctg}
                   // pagination={this.state.pagination}
                    onChange={this.handleTableChange}     
                    //scroll={{ y: 400 }}         
                    />       
                    </Card> */}
                                        </Col>

                                        {/* <Col md={8} sm={24} xs={24}>
                            <h4 className="gx-text">Dsg Code :{this.state.data.design_code}</h4><Divider/>
                            <h4 className="gx-text">Gross Wt : {this.state.data.gross_weight}</h4><Divider/>
                            <h4 className="gx-text">Net Wt : {this.state.data.net_weight}</h4><Divider/>
                            <h4 className="gx-text">Metal Type :  {this.state.data.metal_type}</h4><Divider/>
                            <h4 className="gx-text">Design Color :  {this.state.data.design_code}</h4><Divider/>
                            <h4 className="gx-text">Gold Value : {currencyConverter(this.state.data.gold_value)}</h4><Divider/>
                            <h4 className="gx-text">Platinume Rate : {currencyConverter(this.state.data.platinum_rate)}</h4><Divider/>
                            <h4 className="gx-text">Silver Rate : {currencyConverter(this.state.data.silver_rate)}</h4>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                            <h4 className="gx-text">Design Category : {this.state.data.design_category}</h4><Divider/>
                            <h4 className="gx-text">Customer Design Category : {this.state.data.customer_design_category}</h4><Divider/>                           
                            <h4 className="gx-text">Color Stone Quantity : {this.state.data.color_stone_quantity}</h4><Divider/>
                            <h4 className="gx-text">Diamond Quantity : {this.state.data.diamond_quantity}</h4><Divider/>
                            <h4 className="gx-text">Finding Weight : {this.state.data.finding_weight}</h4><Divider/>
                            <h4 className="gx-text">Finding Value : {currencyConverter(this.state.data.finding_value)}</h4><Divider/>
                            <h4 className="gx-text">Labour Value  : {currencyConverter(this.state.data.labour_value)}</h4><Divider/>
                            <h4 className="gx-text">Sales Value : {currencyConverter(this.state.data.sales_value)}</h4>        
                        </Col> */}
                                    </Row>
                                    {this.state.rmdata.length > 0 ?
                                        <Row>
                                            <Col md={24} sm={24} xs={24}>
                                                <Card
                                                    title={<React.Fragment><p>Raw Material <Button
                                                        style={{cursor: "pointer", float: "right"}}
                                                        onClick={() => this.editSku()}
                                                        className="transaction-clear-btn"
                                                        ghost>Update Raw Material Details </Button></p>
                                                    </React.Fragment>}
                                                    style={{borderRadius: "8px", marginTop: "10px"}}
                                                    bodyStyle={{padding: 0}}
                                                    className="Rowmatiraltype"
                                                >

                                                    <Table
                                                        loading={this.state.table_loading}
                                                        className="gx-table-responsive"
                                                        style={{paddingTop: "7px"}}
                                                        columns={this.columns}
                                                        dataSource={this.state.rmdata}
                                                        size={"small"}
                                                        rowKey={record => record.ctg}
                                                        // pagination={this.state.pagination}
                                                        onChange={this.handleTableChange}
                                                        //scroll={{ y: 400 }}
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                        : ""}
                                </Card>
                            </Col>
                        </Row>

                    }
                </Spin>
                <SkuEdit visibleForEditSku={this.state.visibleForEditSku} closeEditSku={this.closeEditSku}
                         editRecord={this.state.data}/>
                <SkuEditDetail visibleForEditSku={this.state.visibleForEditSkud} closeEditSku={this.closeEditSkud}
                               editRecord={this.state.data}/>
            </div>
        )
    }
}

Stocks = Form.create()(Stocks);

function mapStateToProps(state) {
    const {Common} = state
    const {sku_item_details: sku_item_details, skulabel_details: skulabel_details, get_sku_media: get_sku_media} = Common
    return {sku_item_details, skulabel_details, get_sku_media}
}

const AppContainer = connect(
    mapStateToProps
)(Stocks);


export default AppContainer;


