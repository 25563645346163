import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Button, Icon, Modal, Transfer} from 'antd';
import "./configurationDisplay.css";
import {bindActionCreators} from "redux";
import {editDisplayConfig} from "./ACTIONS/EditDisplayConfig";
import {listConfigurationDisplay} from "./ACTIONS/ListConfigurationDisplay"
import {message} from "antd/lib/index";

class Update extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            mockData: [],
            targetKeys: [],
            leftData: [],
        };

    }

    componentDidMount() {
        this.getKeyaddedData(this.props.record.fields);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.record !== this.props.record) {
            this.getKeyaddedData(this.props.record.fields);
        }

    }

    handleChange = (targetKeys, direction, moveKeys) => {
        let targetObj = {};

        if (direction === "left") {
            // console.log(moveKeys)
            moveKeys.map(i => {
                this.setState(prevState => ({
                    leftData: [...prevState.leftData, i]
                }))
            })
        }

        //  mockData.map(item => {

        //      if(targetKeys.includes(item)){
        //        targetObj.key = item.key;
        //        targetObj.isSelected = "true";
        //        targetObj.name = item.name;
        //        targetObj.header = item.header;
        //        console.log("targetObj :",targetObj)
        //      }
        //      targetKeys.push(targetObj)

        //  })

        //  console.log("targetKeys :",targetKeys)
        //   console.log(targetKeys, direction, moveKeys);


        if (targetKeys.length > this.props.record.maxSelected) {
            message.error("You are not allowed to add more than " + this.props.record.maxSelected + " counts of data");
            document.getElementsByClassName("anticon-right")[0].disabled = true;
            return;
        }
        this.setState({targetKeys, moveKeys});
    };

    renderItem = item => {
        const customLabel = (
            <span className="custom-item">
          {item.header}
        </span>
        );
        return {
            label: customLabel, // for displayed item
            value: item.name, // for title and filter matching
        };
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    }
    handleCancel = () => {
        this.setState({visible: false});
    }
    getKeyaddedData = (data) => {
        data.map((x, index) => {
            // console.log("x.key, index", x.key + ", " + index);
            if (x.key === null || x.key === undefined)
                x.key = index
        })

        this.setState({
            mockData: data,
            targetKeys: []
        }, () => {
            data.map(x => {
                if (x.isSelected === true) {
                    this.state.targetKeys.push(x.key)
                }
            })
        })

    }

    handleOk = () => {
        this.setState({
            visible: false,
        });
        let selectedArray = [];
        let record = this.props.record;
        //  console.log("record :",record);
        this.state.mockData.map(item => {
            // console.log("item :", item)
            if (this.state.targetKeys.includes(item.key)) {
                item.isSelected = true;
                selectedArray.push(item);
            } else if (this.state.leftData.includes(item.key)) {
                item.isSelected = false;
                selectedArray.push(item)
            } else {
                selectedArray.push(item);
            }
        })
        let displayConfig = {
            screen: record.screen,
            maxSelected: record.maxSelected,
            fields: selectedArray
        };

        this.props.editDisplayConfigAction(displayConfig);
        this.props.listConfigurationDisplayAction();

        // console.log("selectedArray :",selectedArray)
    }

    render() {

        // console.log("this.state.targetKeys :",this.state.targetKeys);
        // let configurationList = this.props.configurationList;

        let record = this.props.record;
        let dataKey = []
        // if(this.props.record){
        //   dataKey = this.getKeyaddedData(this.props.record.fields);
        //   console.log(dataKey)
        // }
        const {visible} = this.state;
        return (
            <div>
                <Icon type="edit" onClick={this.showModal}
                      style={{fontSize: 20, paddingLeft: "10px", cursor: "pointer", float: "left"}}/>
                <Modal
                    title={this.props.record.screen}
                    visible={visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            Save
                        </Button>,
                    ]}
                    width={"46%"}
                    style={{paddingBottom: "166px"}}
                >
                    <Transfer
                        dataSource={this.state.mockData}
                        listStyle={{
                            width: 300,
                            height: 300,
                        }}
                        targetKeys={this.state.targetKeys}
                        onChange={this.handleChange}
                        render={this.renderItem}
                    />

                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        configurationList: state.listConfigurationDisplay.configurationList
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            editDisplayConfigAction: editDisplayConfig,
            listConfigurationDisplayAction: listConfigurationDisplay
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Update);