import {updateUserDetails } from '../../actions/index';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Card, Form, Button, message, Input, Row, Col, Upload, Icon } from 'antd';

class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
  

    handleConfirmBlur = e => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
      };

    //   checkCurrentPassword = (rule, value, callback) => {
    //     const form = this.props.form;
    //     if (this.props.password && this.props.password !== form.getFieldValue('currentpassword')) {
    //       callback('Current Passwords do not match');
    //     } else {
    //       callback();
    //     }
    //   };

      checkPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
          callback('Passwords do not match');
        } else {
          callback();
        }
      };

    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (err) {
                return;
            }
            if(values.currentpassword != this.props.password){
                message.error("Current Passwords do not match",1);
                return;
            }
            const { dispatch } = this.props;
            let data = this.props.userdata;
            // console.log(this.props.password);
            // console.log(data);
            data.password = values.confirm;
            dispatch(updateUserDetails(data));
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
                <Card title="Change Password" style={{ borderRadius: "7px"}} bodyStyle={{paddingBottom:"0px"}}>
                         <Form layout="vertical" onSubmit={this.handleSubmit}>
                                <Form.Item  label="Current Password" className="customFormItem">
                                {getFieldDecorator('currentpassword', {
                                rules: [
                                    {
                                    required: true,
                                    message: 'Please input your current password!',
                                    },
                                    // {
                                    //     validator: this.checkCurrentPassword,
                                    // }
                                ],
                                })(<Input.Password type="password" />)}
                            </Form.Item>
                            <Form.Item  label="New Password" className="customFormItem">
                            {getFieldDecorator('password', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Please input your new password!',
                                }
                              ],
                            })(<Input.Password type="password" className="customFormItem"/>)}
                          </Form.Item>
                          <Form.Item  label="Confirm New Password" >
                            {getFieldDecorator('confirm', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Please confirm your password!',
                                },
                                {
                                  validator: this.checkPassword,
                                },
                              ],
                            })(<Input.Password type="password" onBlur={this.handleConfirmBlur} />)}
                          </Form.Item>
                                <Form.Item >
                                    <Button type="primary" htmlType="submit">
                                        Update
                                    </Button>
                                </Form.Item>
                            </Form>
                </Card>
        )
    }
}


UpdatePassword = Form.create()(UpdatePassword);
function mapStateToProps(state) {
    const { Common } = state
    const { user_data: user_data, update_user_data: update_user_data } = Common
    return { user_data, update_user_data }
}
const AppContainer = connect(
    mapStateToProps
)(UpdatePassword);
export default AppContainer;
