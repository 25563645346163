import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import moment from "moment";
import { getSalesAudit, getAllSalesPerson } from "../../../actions/index";
import CountUp from "react-countup";
import {
  Card,
  Table,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  InputNumber,
  Button,
  AutoComplete,
  Input,
  Icon,
  Slider,
  Divider,
} from "antd";
import {
  currencyConverter,
  sortDirections,
} from "../../../containers/App/commanFunction";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
const { MonthPicker, RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;
const CustomerList = [];

const columns = [
  {
    title: "DATE",
    dataIndex: "createdAt",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      var datef = moment(text).format("DD-MM-YYYY");
      const { finger_index } = record;
      return <span>{datef}</span>;
    },
  },

  {
    title: "EMPLOYEE",
    dataIndex: "sName",
    width: "20%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { finger_index } = record;

      return <span>{text}</span>;
    },
  },
  {
    title: "DSG CODE",
    dataIndex: "design_code",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { finger_index } = record;

      return <span>{text}</span>;
    },
  },
  {
    title: "DESIGN CTG",
    dataIndex: "design_category",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { customer } = record;
      return <span>{text}</span>;
    },
  },
  {
    title: "QTY",
    dataIndex: "qty",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { customer } = record;
      return <span>{text}</span>;
    },
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { customer } = record;
      return <span>{currencyConverter(text)}</span>;
    },
  },
];

class Sales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      table_loading: true,
      newtWetStart: 0,
      newtWetEnd: 0,
      priceStart: 0,
      priceEnd: 500,
      selectdCtg: "",
      selectdSalesPerson: "",
      selectdQty: "",
      update: 1,
      salesPerson: [],
      category: [],
      allSalesPersons: [],
      maxVal: 0,
      minVal: 0,
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;
    let now = new Date();
    let starDate = (fromDate = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ).subtract(30, "days"));
    let fromDate = starDate.toISOString();
    let toDate = new Date().toISOString();
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
    });
    dispatch(getAllSalesPerson());
    dispatch(
      getSalesAudit(
        fromDate,
        toDate,
        this.state.selectdSalesPerson,
        this.state.selectdCtg,
        this.state.selectdQty
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevProps.sales_audit != this.props.sales_audit) {
        // console.log(this.props.sales_audit.data_array);
        if (this.props.sales_audit.status) {
          let dataArray = this.props.sales_audit.data_array;
          let category = [];
          let maxCount = [];
          let sName = [];
          for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            category.push(element.design_category);
            sName.push({ empId: element.sId, sName: element.sName });
            maxCount.push(element.qty);
          }

          let nsNamecategory = [...new Set(category)];
          let categoryOptionArray = [];
          for (let index1 = 0; index1 < nsNamecategory.length; index1++) {
            const element = nsNamecategory[index1];
            categoryOptionArray.push(
              <Option key={index1.toString(36) + index1} value={element}>
                {element}
              </Option>
            );
          }

          let nsName = [...new Set(sName)];
          let optionArray = [];
          for (let index1 = 0; index1 < nsName.length; index1++) {
            const element = nsName[index1];
            optionArray.push(
              <Option
                key={index1.toString(36) + index1 + "op"}
                value={element.empId}>
                {element.sName}
              </Option>
            );
          }
          let maxVal = Math.max.apply(null, maxCount);
          let minVal = Math.min.apply(null, maxCount);

          if (this.state.update == 1) {
            this.setState({
              category: categoryOptionArray,
              salesPerson: optionArray,
              maxVal: maxVal,
              minVal: minVal,
              update: 2,
            });
          }

          this.setState({
            data: this.props.sales_audit.data_array,
          });
        } else {
          this.setState({
            data: [],
          });
        }
        this.setState({
          table_loading: false,
        });
      }
      if (prevProps.all_sales_persons != this.props.all_sales_persons) {
        try {
          if (this.props.all_sales_persons.status) {
            let allSalesPersonsSort = this.props.all_sales_persons.data_array;
            allSalesPersonsSort.sort((a, b) =>
              a._id.name > b._id.name ? 1 : -1
            );
            this.setState({
              allSalesPersons: allSalesPersonsSort,
            });
            //const { dispatch } = this.props;
            //dispatch(getCutomerConversationRatioPerticularSalesPersonWise(0,this.state.fromDate,this.state.toDate,this.state.selectedRootInfoEqual,this.state.selectedId,this.props.all_sales_persons.data_array[0].empId));
          }
          this.setState({
            table_loading: false,
          });
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dispatch } = this.props;
        // let userdata=values;
      }
    });
  };
  onChangeNetWT = (value) => {
    // console.log("ljklk", value);
    this.setState({
      newtWetStart: value[0],
      newtWetEnd: value[1],
      selectdQty: `${value[0]},${value[1]}`,
    });
    //let selectdQty=`${value[0]},${value[1]}`;
    //const { dispatch } = this.props;
    // dispatch(getSalesAudit(this.state.fromDate,this.state.toDate,this.state.selectdSalesPerson,this.state.selectdCtg,selectdQty));
  };
  handleChangeCtg = (value) => {
    this.setState({
      //  table_loading:true,
      //selectdCtg:value
    });
    //  const { dispatch } = this.props;
    //    dispatch(getSalesAudit(this.state.fromDate,this.state.toDate,this.state.selectdSalesPerson,value,this.state.selectdQty));
  };

  onChangePrice = (value) => {
    // this.setState({
    //   priceStart: value[0],
    //   priceEnd: value[1]
    // });
  };

  handleChangePerson = (value) => {
    // console.log(value);
    // this.setState({
    //   table_loading:true,
    //   selectdSalePerson:value
    // });
    //const { dispatch } = this.props;
    // dispatch(getSalesAudit(this.state.fromDate,this.state.toDate,value,this.state.selectdCtg,this.state.selectdQty));
  };

  onChange = (date, dateString) => {
    // this.setState({
    //   table_loading:true
    // })
    // if(date.length==2)
    // {
    //   try {
    //     console.log(date);
    //     const { dispatch } = this.props;
    //     let fromDate=date[0].toISOString();
    //     let toDate=date[1].toISOString();
    //     this.setState({
    //       fromDate:fromDate,
    //       toDate:toDate
    //     });
    //     dispatch(getSalesAudit(fromDate,toDate,this.state.selectdSalesPerson,this.state.selectdCtg,this.state.selectdQty));
    //    } catch (error) {
    //      console.log(error);
    //    }
    // }else{
    //   this.setState({
    //     data:[],
    //     table_loading:false
    //    });
    // }
  };

  btnFiltter = () => {
    this.setState({
      table_loading: true,
      data: [],
    });

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const { dispatch } = this.props;
      const pager = { ...this.state.pagination };
      pager.current = 1;
      pager.pageSize = 10;

      this.setState({
        pagination: pager,
        table_loading: true,
        pageSizelimit: 10,
        currantPage: pager.current - 1,
      });
      let fromDate = "";
      let toDate = "";
      if (values.date.length > 0) {
        fromDate = values.date[0].toISOString();
        toDate = values.date[1].toISOString();
      }
      let ctg = values.ctg;
      let sName = values.sName;
      if (ctg === undefined) {
        ctg = "";
      }
      if (sName === undefined) {
        sName = "";
      }
      dispatch(getSalesAudit(fromDate, toDate, sName, ctg, values.qty));
    });
  };

  onClearFiltter = (e) => {
    this.setState({
      table_loading: true,
      search: "",
    });
    const { dispatch } = this.props;
    dispatch(getSalesAudit(this.state.fromDate, this.state.toDate, "", "", ""));
    this.props.form.resetFields();
  };

  handleTableChange = (pagination, filters, sorter) => {
    let colome = sorter.columnKey;
    let order = sorter.order;
    let orderopp = 0;
    let data = this.state.data;
    if (order != undefined) {
      if (order === "descend") {
        order = -1;
        orderopp = 1;
      } else {
        order = 1;
        orderopp = -1;
      }
      data.sort((a, b) => (a[`${colome}`] > b[`${colome}`] ? order : orderopp));
    } else {
      const shuffleArray = (arr) => arr.sort(() => Math.random() - 0.5);
      shuffleArray(data);
    }
  };

  render() {
    var heightd = window.innerHeight - 480;
    const { getFieldDecorator } = this.props.form;
    let now = new Date();
    const fromDate = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ).subtract(30, "days");
    let data = this.state.data;
    if (this.state.search) {
      try {
        data = data.filter((row) => {
          let datef = moment(row.createdAt).format("DD-MM-YYYY");
          return (
            datef.toUpperCase().includes(this.state.search.toUpperCase()) ||
            row.sName.toUpperCase().includes(this.state.search.toUpperCase()) ||
            row.design_code.includes(this.state.search.toUpperCase()) ||
            row.design_category.includes(this.state.search.toUpperCase())
          ); //||row.total.includes(this.state.search.toUpperCase())
          //row.name.toUpperCase().includes(this.state.search.toUpperCase())
        });
      } catch (error) {
        console.log(error);
      }
    }
    const formItemLayout = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    return (
      <div>
        <Card
          className='transaction-top-menu-card'
          bodyStyle={{ padding: "0" }}
          bordered={false}>
          <Row>
            <Col
              lg={5}
              md={5}
              sm={24}
              xs={24}
              style={{
                textAlign: "center",
              }}>
              <p className='transaction-title'>Reports</p>
              <h1 className='transaction-sub-title'>Sales Audit</h1>
            </Col>

            <Col lg={11} md={11} sm={24} xs={24}>
              <Divider
                type='vertical'
                className='vertical-divider-transaction'
              />
              <h1 className='total-text'>
                <CountUp start={0} end={data.length} />{" "}
              </h1>
            </Col>

            <Col lg={8} md={8} sm={24} xs={24}>
              <p
                style={{ marginBottom: "5px" }}
                className='transaction-serach-text text-color-8c8c8c'>
                Search
              </p>
              <Input
                value={this.state.search}
                placeholder='-- Search --'
                style={{ float: "left", width: "70%" }}
                onChange={(e) => this.setState({ search: e.target.value })}
              />
              <Button
                onClick={this.handleReset}
                style={{ marginLeft: "11px" }}
                type='primary'>
                Search
              </Button>
            </Col>
          </Row>
        </Card>

        <Form hideRequiredMark={true} colon={false} labelAlign={"left"}>
          <Card
            className='filtter-card'
            bordered={false}
            bodyStyle={{
              padding: "10px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}>
            <Row>
              <Col lg={2} md={2} sm={24} xs={24} className='text-aligin-center'>
                <img src={FilterIcon} className='filter-icon' />
              </Col>
              <Col lg={5} md={5} sm={24} xs={24} style={{ paddingLeft: "0px" }}>
                <Form.Item label={`Date`} {...formItemLayout}>
                  {getFieldDecorator(`date`, {
                    initialValue: [
                      moment(fromDate, "DD-MM-YYYY"),
                      moment(new Date(), "DD-MM-YYYY"),
                    ],
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <RangePicker
                      // showTime={{ format: 'HH:mm' }}
                      format='DD-MM-YYYY'
                      placeholder={["From ", "To"]}
                      //  defaultValue={[moment(fromDate, "DD-MM-YYYY"),moment(new Date(), "DD-MM-YYYY")]}
                      //  size="small"
                      // style={{width:"245px"}}
                      style={{ minWidth: "100%" }}
                      separator='-'
                      //   onChange={this.onChange}
                      // onOk={onOk}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label={"By Design Category"} {...formItemLayout}>
                  {getFieldDecorator(`ctg`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      mode='multiple'
                      //  size={size}
                      placeholder='Please select Design Category'
                      //  defaultValue={this.state.category}
                      //  onChange={this.handleChangeCtg}
                      style={{ width: "100%" }}>
                      {this.state.category}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label={"By Employee"} {...formItemLayout}>
                  {getFieldDecorator(`sName`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      mode='multiple'
                      //  size={size}
                      placeholder='Please select employee'
                      // defaultValue={['Rajesh','Mahesh']}
                      onChange={this.handleChangePerson}
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }>
                      {this.state.allSalesPersons.map((user) => (
                        <Option key={user._id.empId} value={user._id.empId}>
                          {user._id.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label={"By Qty"} {...formItemLayout}>
                  {getFieldDecorator(`qty`, {
                    initialValue: [
                      this.state.newtWetStart,
                      this.state.newtWetEnd,
                    ],
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Slider
                      onChange={this.onChangeNetWT}
                      min={0}
                      max={this.state.maxVal}
                      range
                    />
                  )}
                </Form.Item>
              </Col>

              <Col lg={5} md={5} sm={24} xs={24}>
                <div
                  style={{
                    marginTop: "31px",
                    paddingRight: "9px",
                    float: "right",
                  }}>
                  <Button
                    style={{ float: "left" }}
                    onClick={this.btnFiltter}
                    type='primary'>
                    Filter
                  </Button>
                  <Button
                    onClick={this.onClearFiltter}
                    className='transaction-clear-btn'
                    ghost>
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Form>

        <Card
          style={{ marginBottom: "0px" }}
          bodyStyle={{ padding: "10px 10px", paddingBottom: "0px" }}
          bordered={false}>
          <Table
            className='gx-table-responsive'
            loading={this.state.table_loading}
            columns={columns}
            dataSource={data}
            size={"small"}
            scroll={{ y: heightd }}
            //rowKey={record => record.createdAt}
            onChange={this.handleTableChange}
            sortDirections={sortDirections}
          />
        </Card>

        {/**
                <Col md={6} sm={24} xs={24}> 
                    <Card  size="small"style={{padding: "20px 20px;"}}
                    title="Filter">

                    <p>By sales type</p> 
                    <Select
                   //mode="multiple"
                  //  size={size}
                    placeholder="Please Sales type "
                    //defaultValue={['Rajesh','Mahesh']}
                    // onChange={handleChange}
                    style={{ width: '100%' }}
                  >
                      <Option key="Top 5 Sales Person">Top 5 Sales Person</Option>
                      <Option key="Top 5 Sales - Design wise">Top 5 Sales - Design </Option>      
                      <Option key="Category wise Sales">Top 5 Sales Category</Option>                
                  </Select>    
           
                    <p style={{marginTop:"14px",marginBottom:"7px"}}>By Sales Person</p> 
                    <Select
                   mode="multiple"
                  //  size={size}
                    placeholder="Please select sales person"
                   // defaultValue={['Rajesh','Mahesh']}
                   onChange={this.handleChangePerson}
                    style={{ width: '100%' }}
                  >
                  {this.state.allSalesPersons.map(user => (
                    <Option key={user._id.empId} value={user._id.empId}>{user._id.name}</Option>
                  ))}               
                  </Select>    

               

                    <p  style={{marginTop:"14px",marginBottom:"7px"}}>By CTG</p> 
                    <Select
                   mode="multiple"
                //  size={size}
                     placeholder="Please select CTG"
                //  defaultValue={['Mahesh','Niral']}
                       onChange={this.handleChangeCtg}
                     style={{ width: '100%' }}
                   >
                      {this.state.category}        
                    </Select>     
                    
                    <p>By Date</p> 
                    <RangePicker
                   // showTime={{ format: 'HH:mm' }}
                    format="DD-MM-YYYY"
                    placeholder={['From ', 'To']}
                    defaultValue={[moment(fromDate, "DD-MM-YYYY"),moment(new Date(), "DD-MM-YYYY")]}
                  //  size="small"
                    style={{width:"245px"}}
                    onChange={this.onChange}
                   // onOk={onOk}
                  />      

                    <p  style={{marginTop:"14px",marginBottom:"7px"}}>By Qty</p>                    
                    <Slider  
                    onChange={this.onChangeNetWT}  min={this.state.minVal}
                    max={this.state.maxVal} range defaultValue={[this.state.newtWetStart, this.state.newtWetEnd]} />
                    <p>From : {this.state.newtWetStart} To : {this.state.newtWetEnd} </p>

                 </Card>
             </Col>  
            */}
      </div>
    );
  }
}

Sales = Form.create()(Sales);
function mapStateToProps(state) {
  const { Common } = state;
  const {
    sales_audit: sales_audit,
    all_sales_persons: all_sales_persons,
  } = Common;
  return { sales_audit, all_sales_persons };
}

const AppContainer = connect(mapStateToProps)(Sales);

export default AppContainer;
