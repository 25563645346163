import React, { Component } from 'react';
import { Upload, Card, Icon, message, Modal, Table, Button, Select, Checkbox, Alert, Row, Form, Spin, Col, Divider, Typography } from 'antd';
import {
  customerUploadFileResponse,
  //stockUploadStatusbar,getInventory 
  customerUploadReplacce, getCustomer
} from '../../../actions/index';
// import SucessIcon from "../../../assets/images/icon/icon-success.svg";
import { connect } from 'react-redux';
import UploadIcon from "./../../../assets/images/icons/import.svg";
// import DragIcon from "../../../assets/images/icon/drag-xls.svg";
import ReactExport from "react-data-export";
import sampleExportData from './sampleJson.json'
import ErrorIcon from "./../../../assets/images/icons/icon-error.svg";
import WarningIcon from "./../../../assets/images/icons/import.svg";

message.config({
  duration: 2,
  maxCount: 3,
});
const { Option } = Select;
var cookies = require('browser-cookies');
var result = window.location.href;
var arr = result.split("/");
var urlupload = arr[0] + "//" + arr[2];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// console.log(urlupload);
function getAhuthanticication() {

  let login = localStorage.getItem('login');
  let passwordS = cookies.get('wteoben');
  let auth = {
    username: login,
    password: passwordS,
  }
  return { auth: auth }
}
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {

    // console.log(file);
    // console.log("ok");
  }, 0);
};

class CustomerUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileUploadSuccessfully: false,
      file_upload: [],
      visible: false,
      fileList: [],
      uploadvisible: false,
      dublicate_data: [],
      selected_id: '',
      filelist: [],
      firstlevel: false,
      isUploadLoading: false,
      isUploadStockFaild: false,
      replaceCustomerBtnLoading: false,
      fileUploadSuccessfullymsg: '',
      customerUploadError: '',
      dataforExport: []
    };
  }

  componentDidMount() {
    this.setState({
      dataforExport:sampleExportData
  });
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevProps.file_upload_customer_response != this.props.file_upload_customer_response) {
        // console.log("success");
        // console.log(this.props.file_upload_customer_response);

        if (this.props.file_upload_customer_response.status) {
          this.setState({
            fileUploadSuccessfully: true,
            filelist: false,
            dublicate_data: this.props.file_upload_customer_response.data_array,
            fileUploadSuccessfullymsg: 'Customer Uploaded Successfully!'
          });


          if (this.props.file_upload_customer_response.data_array.length == 0) {
            const { dispatch } = this.props;
            dispatch(getCustomer(0, "", "", "", 10, "", "", "", "", ""));
          }


        } else {
          this.setState({
            isUploadLoading: false,
            isUploadStockFaild: true,
            filelist: false,
            isUploadStockFaildMsg: this.props.file_upload_customer_response.errors.msg,
            fileUploadSuccessfullymsg: '',
          });
        }

      }


      if (prevProps.customer_replace_details != this.props.customer_replace_details) {
        // console.log("success");
        // console.log(this.props.customer_replace_details);

        if (this.props.customer_replace_details.status) {
          this.setState({
            // visible: false,
            filelist: true,
            // uploadvisible:false,
            fileList: [],
            dublicate_data: [],
            replaceCustomerBtnLoading: false,
            fileUploadSuccessfullymsg: this.props.customer_replace_details.data.msg

          });
          // const { dispatch } = this.props;
          //  dispatch(getInventory());  
          //message.success(this.props.customer_replace_details.data.msg);
          const { dispatch } = this.props;
          dispatch(getCustomer(0, "", "", "", 10, "", "", "", "", ""));
        }
        // else{
        //   this.setState({
        //     isUploadLoading:false,  
        //     isUploadStockFaild:true,
        //     filelist:false,
        //     isUploadStockFaildMsg:this.props.customer_replace_details.errors.msg
        //   });
        // }

      }


    } catch (error) {

    }

  }


  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('file', file);
    });

    this.setState({
      uploading: true,
    });
    const { dispatch } = this.props;
    dispatch(customerUploadFileResponse(formData));
  }

  handleCancel = () => {
    //console.log('Clicked cancel button');

    this.setState({
      visible: false,
      filelist: true,
      uploadvisible: false,
      fileList: []
    });
  }


  Upload = (e) => {
    // console.log(document.URL);
    let result = { status: 'error' };
    return result;
  }

  onFileDropefile = (e) => {
    console.log('file upload ff');
  };

  beforeUpload(params) {
    console.log("before ");
  }

  uploadhandleCancel = () => {
    this.setState({
      uploadvisible: false,
      filelist: true,
      isUploadLoading: false,
      isUploadStockFaild: false,
      fileUploadSuccessfully: false,
      fileList: [],
      replaceCustomerBtnLoading: false
    });


  }

  uploadhandleOpen = () => {
    this.setState({
      uploadvisible: true,
      firstlevel: false,
      filelist: true,
      isUploadLoading: false,
      isUploadStockFaildMsg: ''
    });
  }


  alertOnClose = (e) => {
    this.setState({
      isUploadStockFaild: false
    })
  }

  retryToUpload = (e) => {
    this.setState({
      //uploadvisible: false,
      filelist: true,
      isUploadLoading: false,
      isUploadStockFaild: false,
      fileUploadSuccessfully: false,
      fileList: [],
      replaceCustomerBtnLoading: false,
      isUploadStockFaildMsg: ''
    })
  }


  replaceCustomer = (e) => {
    this.setState({
      replaceCustomerBtnLoading: true
    })
    const { dispatch } = this.props;
    dispatch(customerUploadReplacce(this.state.dublicate_data));
  }

  render() {
    const { uploading, fileList } = this.state;
    const { dispatch } = this.props;

    const Dragger = Upload.Dragger;
    const columns = [
      {
        title: 'NAME',
        dataIndex: 'familyName',
        width: '15%',
        //sorter: (a, b) => {},
        render: (text, record) => {
          let { firstName, familyName, code } = record;
          if (familyName === undefined) {
            familyName = ''
          }
          return (
            <span>
              <a style={{ color: "#545454" }} onClick={() => this.showReportView(code)}>{familyName + " " + firstName}</a>
            </span>
          );
        }
      },
      {
        title: 'TEL.',
        dataIndex: 'contactNumber',
        //ellipsis: true,
        width: '10%',
        //sorter: (a, b) => {},
        render: (text, record) => {
          const { Customer } = record;
          return (
            <span>
              {text}
            </span>
          );
        },

      },
      {
        title: 'EMAIL',
        dataIndex: 'email',
        width: '20%',
        //  ellipsis: true,
        //  sortOrder:['descend','ascend'],
        //sorter: (a, b) => {},
        key: "email",
      },

      {
        title: 'COMPANY',
        dataIndex: 'companyName',
        width: '10%',
        //sorter: (a, b) => {},
        render: (text, record) => {
          const { Customer } = record;
          return (
            <span>
              {text}
            </span>
          );
        }
      },
    ]
    const props = {
      multiple: false,
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [file],
        }));
        return false;
      },
      showUploadList: false,
      fileList,
    };
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <img className="import-icon" onClick={this.uploadhandleOpen} src={UploadIcon} />
        <h5 className="text-color-8c8c8c">Import Customer</h5>
        <Modal
          title="Import Customer "
          visible={this.state.uploadvisible}
          onOk={this.uploadhandleCancel}
          //     confirmLoading={confirmLoading}
          style={{ paddingBottom: "0px" }}
          bodyStyle={{ paddingBottom: "0px" }}
          width={650}
          onCancel={this.uploadhandleCancel}
          footer={null}
        // footer={[ 
        // <Button key="back" onClick={this.uploadhandleCancel} style={{ minWidth: "77px" }} ghost>CANCEL</Button>,
        // <Button type="primary"  onClick={this.handleUpload} disabled={this.state.fileList.length > 0 ? false : true}>UPLOAD</Button>]}

        >

          {this.state.isUploadStockFaild ?
            <React.Fragment>
              <div style={{ textAlign: "center" }}>
                <p style={{ color: "#4C4C4C", fontSize: "20px" }}>
                  {/* <img src={ErrorIcon} style={{paddingRight:"10px"}}></img> */}
                  Customer Upload Error! </p>
                <br /><br />
                <p style={{ color: "#4C4C4C", fontSize: "16px" }}>
                  <img src={ErrorIcon} style={{ paddingRight: "10px" }}></img>  {this.state.isUploadStockFaildMsg}
                </p>
              </div>
              <br />
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Divider style={{ marginBottom: "16px", marginTop: "50px" }} />
                  <Button key="back" onClick={this.uploadhandleCancel} style={{ minWidth: "77px" }} ghost>Cancel</Button>
                  <Button type="primary" style={{ minWidth: "77px" }} onClick={this.retryToUpload}>Retry</Button>
                </Col>
              </Row>
            </React.Fragment>
            :
            <React.Fragment>
              {this.state.fileUploadSuccessfully ?
                <div style={{ textAlign: "center" }}>
                  {this.state.dublicate_data.length === 0 ?
                    <p style={{ color: "#4C4C4C", fontWeight: "bold", fontSize: "24px", paddingTop: "15px", paddingBottom: "15px" }}>
                      {/* <img src={SucessIcon} style={{paddingRight:"5px"}}></img> */}
                      <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />  &nbsp; &nbsp;{this.state.fileUploadSuccessfullymsg} </p>
                    : ""}
                  {this.state.dublicate_data.length > 0 ?
                    <Table
                      columns={columns}
                      dataSource={this.state.dublicate_data}
                      size={"small"}
                      rowKey={record => record.code}
                      style={{ marginBottom: "0px" }}
                    />
                    : ""}

                  <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                      <Divider style={{ marginBottom: "16px", marginTop: "50px" }} />
                      {this.state.dublicate_data.length === 0 ?
                        <Button type="primary" onClick={this.uploadhandleCancel} style={{ minWidth: "77px" }}>Ok</Button>
                        :
                        <React.Fragment>
                          <Button key="back" onClick={this.uploadhandleCancel} style={{ minWidth: "77px" }} ghost>Cancel</Button>
                          <Button type="primary" onClick={this.replaceCustomer} style={{ minWidth: "77px" }} loading={this.state.replaceCustomerBtnLoading}>Replace Customer</Button>
                        </React.Fragment>
                      }
                    </Col>
                  </Row>


                </div>

                :

                <Form layout="vertical" onSubmit={this.FormSubmit}>
                  {this.state.firstlevel === true ? "" :
                    <Spin tip="Please wait Customer uploading..." spinning={this.state.isUploadLoading}>
                      <Dragger {...props} >
                        {/* <p className="ant-upload-drag-icon" style={{color:"#A4A4A4",fontSize: "24px"}}>
                            {this.state.fileList.length> 0 ?
                              <React.Fragment>
                              {this.state.fileList.map((value, index) => {
                                  return <p style={{color:"#245F9D",fontSize: "16px"}} key={index}>../{value.name}</p>
                                })} 
                              </React.Fragment>
                              :
                              <React.Fragment>
                                &nbsp; Drag File here
                              </React.Fragment>
                              }
                        </p> */}
                        <p className="ant-upload-drag-icon">
                          <Icon type="file" />
                        </p>
                        <p className="ant-upload-text">
                          {this.state.fileList.length > 0 ?
                            <React.Fragment>
                              {this.state.fileList.map((value, index) => {
                                return <p style={{ color: "#245F9D", fontSize: "16px" }} key={index}>../{value.name}</p>
                              })}
                            </React.Fragment>
                            :
                            <React.Fragment>
                              &nbsp; Click Or Drag File here
                            </React.Fragment>
                          }
                        </p>
                        {/* <p className="ant-upload-text">Click Or Drag File here</p>                         */}
                      </Dragger>

                      {/* <br/><br/>


                          <Row>
                          <Col span={24} style={{ textAlign: "center" }}>
                          <Upload {...props}>
                            <p>or&nbsp; <Button style={{marginTop: "11px",paddingLeft:"5px",paddingRight:"10px"}}>&nbsp; Browse</Button></p>  
                            </Upload>

                          </Col>
                        </Row> */}

                      {this.state.fileList.length > 0 ?
                        <React.Fragment>
                          <br /><br />
                          <Row style={{ textAlign: "center" }}>
                            <p><Icon type="warning" />&nbsp; By clicking upload you will remove the current customer list and replace it.</p>
                          </Row>
                        </React.Fragment>
                        : ""}

                      <Typography style={{ display: 'flex', flexDirection: "row-reverse", marginTop: 10 }}>

                        <ExcelFile
                          filename={`Inventory - ${new Date()}`}
                          element={
                            <a>Click here to download Import template</a>
                          }>
                          <ExcelSheet data={this.state.dataforExport} name='Inventory'>
                            <ExcelColumn label='RFID_Number' value='rfid_number' />
                            <ExcelColumn label='Bag_Number' value='sku_number' />
                            <ExcelColumn label='Dsg_Code' value='design_code' />
                            <ExcelColumn label='Suffix' value='suffix' />
                            <ExcelColumn label='Size' value='sku_size' />
                            <ExcelColumn
                              label='Cust_Dsg_Cd'
                              value='customer_design_category'
                            />
                            <ExcelColumn label='Dsg_Ctg' value='design_category' />
                            <ExcelColumn label='Dsg_Col' value='design_color' />
                            <ExcelColumn label='Bag_Qty' value='sku_quantity' />
                            <ExcelColumn label='Sales_Ctg' value='sales_category' />
                            <ExcelColumn label='Set_Code' value='set_code' />
                            <ExcelColumn label='Collection' value='sku_collection' />
                            <ExcelColumn label='Project' value='project' />
                            <ExcelColumn label='Metal_Type' value='metal_type' />
                            <ExcelColumn label='Karatage' value='purity' />
                            <ExcelColumn
                              label='Unit_Of_Measurement'
                              value='unit_of_measurement'
                            />
                            <ExcelColumn label='Gross_Wgt' value='gross_weight' />
                            <ExcelColumn label='Net_Wgt' value='net_weight' />
                            <ExcelColumn label='Dia_Wgt' value='diamond_weight' />
                            <ExcelColumn label='Dia_Qty' value='diamond_quantity' />
                            <ExcelColumn label='Col_Stn_Wgt' value='color_stone_weight' />
                            <ExcelColumn label='Col_Stn_Qty' value='color_stone_quantity' />
                            <ExcelColumn label='Fnd_Wgt' value='finding_weight' />
                            <ExcelColumn label='Fnd_Qty' value='finding_quantity' />
                            <ExcelColumn label='Sales_Value' value='sales_value' />
                            <ExcelColumn label='Dia_Value' value='diamond_value' />
                            <ExcelColumn label='Col_Stn_Value' value='color_stone_value' />
                            <ExcelColumn label='Gold_Value' value='gold_value' />
                            <ExcelColumn label='Finding_Value' value='finding_value' />
                            <ExcelColumn label='Labour_Value' value='labour_value' />
                            <ExcelColumn label='Gold_Rate' value='gold_rate' />
                            <ExcelColumn label='Silver_Rate' value='silver_rate' />
                            <ExcelColumn label='Platinum_Rate' value='platinum_rate' />
                            <ExcelColumn label='Counter' value='counter' />
                            <ExcelColumn label='Tray_Number' value='tray_number' />
                            <ExcelColumn
                              label='Retail_Dia_Value'
                              value='retail_diamond_value'
                            />
                            <ExcelColumn label='Retail_Value' value='retail_value' />
                            <ExcelColumn label='Asking_Value' value='asking_value' />
                            <ExcelColumn
                              label='Asking_Dia_Value'
                              value='asking_diamond_value'
                            />
                            <ExcelColumn label='field_1' value='field_1' />
                            <ExcelColumn label='field_2' value='field_2' />
                            <ExcelColumn label='field_3' value='field_3' />
                            <ExcelColumn label='field_4' value='field_4' />
                            <ExcelColumn label='field_5' value='field_5' />
                            <ExcelColumn label='field_6' value='field_6' />
                            <ExcelColumn label='field_7' value='field_7' />
                            <ExcelColumn label='field_8' value='field_8' />
                            <ExcelColumn label='field_9' value='field_9' />
                            <ExcelColumn label='field_10' value='field_10' />
                            <ExcelColumn label='field_11' value='field_11' />
                            <ExcelColumn label='field_12' value='field_12' />
                            <ExcelColumn label='field_13' value='field_13' />
                            <ExcelColumn label='field_14' value='field_14' />
                            <ExcelColumn label='field_15' value='field_15' />
                          </ExcelSheet>
                        </ExcelFile>
                      </Typography>

                      {/* <Row>
                          <Col span={24} >
                            <br/>
                            <div  style={{ textAlign: "left",float:"left" }}>  <Checkbox >Add to current Inventory</Checkbox></div>
                            <div  style={{ textAlign: "right" }}>
                            <a style={{textDecoration:"underline" }} href='https://filebin.net/rl0q4ao9djrrngf0/Stock-test-2.xlsx?t=khtvtdv5' target="_blank">Need XLS template? Download now!</a>
                            </div>
                            <br/>
                          </Col>
                        </Row> */}
                      <Row>
                        <Col span={24} style={{ textAlign: "right" }}>
                          <Divider style={{ marginBottom: "16px", marginTop: "50px" }} />
                          <Button key="back" onClick={this.uploadhandleCancel} style={{ minWidth: "77px" }} ghost>Cancel</Button>
                          <Button type="primary" onClick={this.handleUpload} disabled={this.state.fileList.length > 0 ? false : true}>Upload</Button>
                        </Col>
                      </Row>
                    </Spin>
                  }
                </Form>
              }
            </React.Fragment>
          }



        </Modal>
      </React.Fragment>
    )


  }
}
CustomerUpload = Form.create()(CustomerUpload);
function mapStateToProps(state) {
  const { Common } = state
  const { file_upload_customer_response: file_upload_customer_response, customer_replace_details: customer_replace_details } = Common
  return { file_upload_customer_response, customer_replace_details }
}

const AppContainer = connect(
  mapStateToProps
)(CustomerUpload);
export default AppContainer;

