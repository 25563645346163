import React from "react";
import {Route, Switch} from "react-router-dom";
import BranchSalesReport from "./Reports/sales/index";
import BranchStockReport from "./Reports/stockhistory/index";
import TrayActivitiesReport from "./Reports/trayActivities/index";
import ConversionRatio from "./Reports/conversionRatio/index";
import viewDemand from "./Reports/viewDemand/index";
import ActionReport from "./Reports/action/index";
import DeadStock from "./Reports/deadStock/index";
import SalesAudit from "./Reports/salesAudit/index";
import dashboard from "./dashboard/index";
import Branchquotation from "./transactions/quotation/index";
import BranchSales from "./transactions/sales/index";
import Status from "./Master/status/index";
import BranchBilling from "./transactions/billing/index";
import memo from './transactions/memo/index';
import Issue from './transactions/issuereport/index';
import Receive from './transactions/receivereport/index';
import Soldstock from './transactions/soldStock/index';
import order from './transactions/order/index';
import cutomerView from "./customer/index";
import stocktallyView from "./Reports/stocktally/index";
import agingreportView from "./Reports/agingreport/index";
import StocksViewByID from "./transactions/Stocks/stockviewInDetailsJT";
import support from "./support/index";
//import "./custome.css";
import "../styles/custom.less";
import BranchrfidDevices from "./transactions/rfidDevices/index";
import jtstockView from "./transactions/Stocks/index";
import Transfer from "./transactions/transfer/index";
import Managebranch from "./companyAdmin/branch/index";
import Managefloor from "./companyAdmin/floor/index";
import Managesection from "./companyAdmin/section/index";
import Managesubsection from "./companyAdmin/subsection/index";
import ManageUser from "./companyAdmin/user/index";
import customerMaster from "./companyAdmin/customermaster/index";
import incrementMaster from "./companyAdmin/incrementmaster/index";
import companyManagement from "./scAdmin/company/index";
import employees from "./employees/index";
import profile from "./profile/index";
import perferences from "./perferences/index";
import displayConfigration from "./companyAdmin/displayConfigration/index";
import filterConfigration from "./companyAdmin/filterConfigration/index";
import imageConfigration from "./companyAdmin/imageConfigration/index";
import InventoryScan from "./companyAdmin/inventoryScan/index";
import customertypeConfiguration from "./companyAdmin/customertypeConfiguration/index";
import configurationDisplay from "./companyAdmin/configurationDisplay/index";
import settings from "./companyAdmin/settings/index";
import DiskUsage from "./companyAdmin/diskUsage/index";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
        {/**   <Route path={`${match.url}trayActivities`} component={asyncComponent(() => import('./trayActivities'))}/>
         <Route path={`${match.url}quotation`} component={quotation}/>
        
        <Route path={`${match.url}branches`} component={branches}/>
        <Route path={`${match.url}company`} component={company}/>
        <Route path={`${match.url}rfidDevices`} component={rfidDevices}/>
        <Route path={`${match.url}licences`} component={licences}/>
        

        <Route path={`${match.url}Branchdashboard`} component={Branchdashboard}/> 
        <Route path={`${match.url}quotation`} component={quotation}/>
        <Route path={`${match.url}Stocks`} component={StocksBranchAdmin}/>
        <Route path={`${match.url}StocksViewInDetails/:ViewInDetails`} component={StocksViewInDetailsBranchAdmin}/>*/} 
        <Route path={`${match.url}rfidDevices`} component={BranchrfidDevices}/> 

       
        <Route path={`${match.url}BranchSalesReport`} component={BranchSalesReport}/>
        <Route path={`${match.url}BranchStockReport`} component={BranchStockReport}/>
        <Route path={`${match.url}TrayActivitiesReport`} component={TrayActivitiesReport}/>
        <Route path={`${match.url}conversionRatio`} component={ConversionRatio}/>
        <Route path={`${match.url}viewDemand`} component={viewDemand}/>
        <Route path={`${match.url}SalesAudit`} component={SalesAudit}/>
        <Route path={`${match.url}actionReport`} component={ActionReport}/>
        <Route path={`${match.url}deadStock`} component={DeadStock}/>
        

        <Route path={`${match.url}dashboard`} component={dashboard}/> 
        <Route path={`${match.url}quotationView`} component={Branchquotation}/>
        <Route path={`${match.url}saleView`} component={BranchSales}/>
        <Route path={`${match.url}billingView`} component={BranchBilling}/>
            <Route path={`${match.url}customerView`} component={cutomerView} />
            <Route path={`${match.url}stocktallyView`} component={stocktallyView} />
            <Route path={`${match.url}agingreportView`} component={agingreportView} />
        <Route path={`${match.url}StocksViewById/:ViewInDetails`} component={StocksViewByID}/>
        <Route path={`${match.url}support`} component={support}/> 
            <Route path={`${match.url}memoView`} component={memo} />
            <Route path={`${match.url}statusView`} component={Status} />
            <Route path={`${match.url}issueView`} component={Issue} />
            <Route path={`${match.url}receiveView`} component={Receive} />
            <Route path={`${match.url}soldstockView`} component={Soldstock} />
        <Route path={`${match.url}orderView`} component={order}/>
        <Route path={`${match.url}jtstockView`} component={jtstockView}/>
        <Route path={`${match.url}transferView`} component={Transfer}/>

        <Route path={`${match.url}manageBranch`} component={Managebranch}/>
        <Route path={`${match.url}manageFloor`} component={Managefloor}/>
        <Route path={`${match.url}manageSection`} component={Managesection}/>
        <Route path={`${match.url}manageSubsection`} component={Managesubsection}/>
        <Route path={`${match.url}manageUser`} component={ManageUser}/>
        <Route path={`${match.url}profile`} component={profile}/>
        <Route path={`${match.url}perferences`} component={perferences}/>        
        <Route path={`${match.url}customerMaster`} component={customerMaster}/> 
        <Route path={`${match.url}incrementMaster`} component={incrementMaster}/> 
        <Route path={`${match.url}employees`} component={employees}/> 
        <Route path={`${match.url}displayConfigration`} component={displayConfigration}/>
        <Route path={`${match.url}filterConfigration`} component={filterConfigration}/>
        <Route path={`${match.url}imageConfigration`} component={imageConfigration}/>
        <Route path={`${match.url}inventoryScan`} component={InventoryScan}/>
        <Route path={`${match.url}diskUsage`} component={DiskUsage}/>
        <Route path={`${match.url}customertypeConfiguration`} component={customertypeConfiguration}/>
        <Route path={`${match.url}configurationDisplay`} component={configurationDisplay}/>
        <Route path={`${match.url}settings`} component={settings}/>



        {/**         Sc Admin        */}
{/* imageConfigration */}
        <Route path={`${match.url}companyManagement`} component={companyManagement}/> 
        {/**       End  Sc Admin        */}  
        {/** */}
        
    </Switch>
  </div>
);

export default App;
