import {getSingleCompany, updateCompanyDetails} from '../../actions/index';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Card, Form, Button, message, Input, InputNumber, Row, Col, Upload, Icon, Select} from 'antd';
import {select} from 'redux-saga/effects';

const {TextArea} = Input;
const Option = Select.Option;

class UpdateCompanyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        };
    }


    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(getSingleCompany());
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (prevProps.company_details != this.props.company_details) {
                // console.log("company details --", this.props.company_details);
                if (this.props.company_details.status) {
                    this.setState({
                        data: this.props.company_details.data,
                    })
                }
            }
            if (prevProps.update_company_data != this.props.update_company_data) {
                message.success("Update successfully");
                // console.log("Updated company details --", this.props.update_company_data);
                if (this.props.company_details.status) {
                    this.setState({
                        data: this.props.company_details.data,
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (err) {
                return;
            }
            // console.log("Values -- ", values)
            const {dispatch} = this.props;
            let data = this.state.data;//this.props.userdata;
            data.address = values.address;
            data.companyType = values.companyType;
            data.noOfUserAllowed = values.noOfUserAllowed;
            data.server_url= values.server_url;
            data.phone = values.phone;
            data.tax_no = values.tax_no;

            let finalData = {};
            finalData.company = data;

            // console.log(this.props.password);
            // console.log("onsubmit --", finalData);

            dispatch(updateCompanyDetails(finalData));
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {name, address, _id, phone, tax_no, server_url, noOfUserAllowed, companyType, licenseDays, type} = this.state.data
        return (
            <Card title="Company Profile" style={{borderRadius: "7px"}} bodyStyle={{paddingBottom: "0px"}}>
                <Form layout="vertical" onSubmit={this.handleSubmit}>
                    <Form.Item label="Address" className="customFormItem">
                        {getFieldDecorator("address",
                            {initialValue: address},
                            {
                                rules: [
                                    {required: true, message: "Please input address!"}
                                ]
                            }
                        )(<TextArea rows={2} placeholder="Address"/>)}
                    </Form.Item>

                    <Form.Item label="Contact Number" className="customFormItem">
                        {getFieldDecorator(
                            "phone",
                            {initialValue: phone},
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input contact number!"
                                    }
                                ]
                            }
                        )(<Input size="default" placeholder="Contact Number"/>)}
                    </Form.Item>
                    <Form.Item label="TAX No">
                        {getFieldDecorator(
                            "tax_no",
                            {initialValue: tax_no},
                            {
                                rules: [
                                    {required: true, message: "Please input tax no!"}
                                ]
                            }
                        )(<Input size="default" placeholder="Tax no"/>)}
                    </Form.Item>
                    <Form.Item label="Company Type">
                        {getFieldDecorator('companyType',
                            {initialValue: parseInt(companyType)},
                            {
                                rules: [
                                    {required: true, message: "Please input company type!"}
                                ]
                            }
                        )(
                            <Select placeholder="Please select company type">
                                <Option value={0}>Normal</Option>
                                <Option value={1}>Offline no sync</Option>
                                <Option value={2}>Fully Online</Option>
                                <Option value={3}>FTP Sync</Option>
                                <Option value={4}>ERP Sync</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Server Ip">
                        {getFieldDecorator(
                            "server_url",
                            {initialValue: server_url},
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please enter server ip address!"
                                    }
                                ]
                            }
                        )(<Input size="default" placeholder="Server IP Address"/>)}
                    </Form.Item>
                    <Form.Item label="No of Users" style={{cursor: "not-allowed"}}>
                        {getFieldDecorator(
                            "noOfUserAllowed",
                            {initialValue: noOfUserAllowed},
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input no of users!"
                                    }
                                ]
                            }
                        )(
                            <InputNumber
                                size="default"
                                placeholder="No of Users"
                                style={{cursor: "not-allowed", width: "100%"}}
                                readOnly
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}


UpdateCompanyInfo = Form.create()(UpdateCompanyInfo);

function mapStateToProps(state) {
    const {Common} = state
    const {company_details: company_details, update_user_data: update_user_data, update_company_data: update_company_data} = Common
    return {company_details, update_user_data, update_company_data}
}

const AppContainer = connect(
    mapStateToProps
)(UpdateCompanyInfo);
export default AppContainer;
