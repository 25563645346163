// import {getStockfromRfid} from '../../actions/actions';
import React, { Component } from "react";
import { Button, Col, Icon, message, Modal, Row, Table } from "antd";
import { currencyConverter } from "../../../containers/App/commanFunction";
import moment from "moment";

message.config({
  top: 100,
  duration: 10,
  maxCount: 3,
});

class TransferDetails extends Component {
  state = {
    get_stock_from_rfid: null,
  };
  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    visible: false,
    copied: false,
    fromDepartment: "",
    toDepartment: "",
  };
  componentDidMount() {
    const { dispatch } = this.props;
    const {
      branch_list,
      floor_list,
      section_list,
      sub_section_list,
      fromDepartmentId,
      toDepartmentId,
    } = this.props;
    // console.log("section", section_list);
    let fromDepartment = "";
    let toDepartment = "";

    if (
      branch_list.length > 0 &&
      floor_list.length > 0 &&
      section_list.length > 0 &&
      sub_section_list.length > 0
    ) {
      if (fromDepartmentId.branchId === toDepartmentId.branchId) {
        if (fromDepartmentId.floorId === toDepartmentId.floorId) {
          if (fromDepartmentId.sectionId === toDepartmentId.sectionId) {
            if (fromDepartmentId.subSectionId === toDepartmentId.subSectionId) {
            } else {
              fromDepartment = sub_section_list.filter(
                (v) => v._id === fromDepartmentId.subSectionId
              );
              toDepartment = sub_section_list.filter(
                (v) => v._id === toDepartmentId.subSectionId
              );
            }
          } else {
            fromDepartment = section_list.filter(
              (v) => v._id === fromDepartmentId.sectionId
            );
            toDepartment = section_list.filter(
              (v) => v._id === toDepartmentId.sectionId
            );
          }
        } else {
          fromDepartment = floor_list.filter(
            (v) => v._id === fromDepartmentId.floorId
          );
          toDepartment = floor_list.filter(
            (v) => v._id === toDepartmentId.floorId
          );
        }
      } else {
        fromDepartment = branch_list.filter(
          (v) => v._id === fromDepartmentId.branchId
        );
        toDepartment = branch_list.filter(
          (v) => v._id === toDepartmentId.branchId
        );
      }
    }

    if (fromDepartment.length > 0 && toDepartment.length > 0) {
      this.setState({
        fromDepartment: fromDepartment[0].name,
        toDepartment: toDepartment[0].name,
      });
    } else {
      this.setState({
        fromDepartment: "--",
        toDepartment: "--",
      });
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };
  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;

    const columns = [
      {
        title: "Sr No",
        dataIndex: "uniqeId",
        key: "uniqeId",
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "Item Number",
        dataIndex: "sku_number",
        key: "sku_number,",
        width: "15%",
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "Design Code",
        dataIndex: "design_code",
        key: "design_code,",
        width: "15%",
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "Qty",
        dataIndex: "sku_quantity",
        key: "sku_quantity,",
        width: "15%",
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "Sales Value",
        dataIndex: "sales_value",
        key: "sales_value,",
        width: "15%",
        render: (text, record) => {
          return <span>{currencyConverter(text)}</span>;
        },
      },
      {
        title: "Status",
        dataIndex: "transfer_status",
        key: "transfer_status,",
        width: "15%",
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "Receive Date",
        dataIndex: "transferDate",
        render: (text, record) => {
          return <span>{moment(text).format("DD-MM-YYYY")}</span>;
        },
      },
    ];
    return (
      <div>
        {this.props.text}

        <Icon
          type='eye'
          theme='filled'
          style={{
            cursor: "pointer",
            color: "#595B62",
            fontSize: 20,
            float: "inherit",
          }}
          onClick={this.showModal}
        />
        <Modal
          visible={visible}
          title={this.props.modeltitle}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width={1150}
          style={{ paddingBottom: "166px" }}>
          <span>
            <Row>
              {/* <Col span={6}>
                <p style={{ marginTop: "-5" }}>
                  <span style={{ fontWeight: "bold" }}>Customer Name :</span> {"Sutariya Tina"}{" "}
                </p>
              </Col> */}
              <Col span={6}>
                <p style={{ marginTop: "-5" }}>
                  <span style={{ fontWeight: "bold" }}>Item Count :</span>{" "}
                  {this.props.itemCount}
                </p>
              </Col>
              <Col span={18}>
                <p style={{ marginTop: "-5" }}>
                  <span style={{ fontWeight: "bold" }}>Transfer Date :</span>{" "}
                  {this.props.transferDate}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <p>
                  <span style={{ fontWeight: "bold" }}>Transferred From :</span>{" "}
                  {this.state.fromDepartment}
                </p>
              </Col>
              <Col span={18}>
                <p>
                  <span style={{ fontWeight: "bold" }}>Transferred To :</span>{" "}
                  {this.state.toDepartment}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <p>
                  <span style={{ fontWeight: "bold" }}>Issued By :</span>{" "}
                  {this.props.issuedBy && this.props.issuedBy.length > 0
                    ? this.props.issuedBy.split(",")[0]
                    : "--"}{" "}
                </p>
              </Col>
              <Col span={18}>
                <p>
                  <span style={{ fontWeight: "bold" }}>Received By :</span>{" "}
                  {this.props.recievedBy && this.props.recievedBy.length > 0
                    ? this.props.recievedBy.split(",")[0]
                    : "--"}{" "}
                </p>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <p>
                  <span style={{ fontWeight: "bold" }}>Comments :</span>{" "}
                  {this.props.comment}{" "}
                </p>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={this.props.issuedItemsList}
                  // bordered
                  size={"small"}
                  rowKey={(record) => record.transferID}
                />
              </Col>
            </Row>

            <Row>
              <Col span={8}></Col>
              <Col span={16}>
                <Button
                  style={{ float: "right" }}
                  type='primary'
                  onClick={this.handleCancel}>
                  Close
                </Button>
              </Col>
            </Row>
          </span>
        </Modal>
      </div>
    );
  }
}

export default TransferDetails;
