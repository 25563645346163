import React, { Component } from 'react'
import {connect} from "react-redux";
import {getSkuLabel,postSkuLabel} from '../../../actions/index';
import {Card, Table,Form,Row,Col,Modal,Select,Input,Button,Icon,Divider,Popconfirm,Radio,Alert,message,List, Spin} from "antd";
var cookies = require('browser-cookies');
class ManageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      keys:[]
    };
  }
  componentWillMount(){  
    let usertype=localStorage.getItem('type_user');
    if(usertype!="companyAdmin"){
      window.location.href="/dashboard"
    }
   }
  componentDidMount() {
    const { dispatch } = this.props; 
    //getSkuLabel
    dispatch(getSkuLabel());
  }
  componentDidUpdate(prevProps, prevState) {
//skulabel_details
if (prevProps.skulabel_details != this.props.skulabel_details) {
   if(this.props.skulabel_details.status)
   {
       this.setState({
         loading:false,
         keys:this.props.skulabel_details.data.labels
       })
   } 
   
  }

  if (prevProps.post_skulable != this.props.post_skulable) {
     if(this.props.post_skulable.status)
     {
         this.setState({
           loading:true,
         })
         const { dispatch } = this.props; 
         //getSkuLabel
         dispatch(getSkuLabel());
     } 
    }
  }
  

  
  handleOk = (e) => {

 this.props.form.validateFields((err, values) => {          
   if (err) {
     return;
   }
  let root =  JSON.parse(cookies.get('root'));
  let labelArray=[];
  for (let [key, value] of Object.entries(values)) {
    
    labelArray.push({
      "label":key,
      "labelvalue":value
    });
  }
  let postBody={
    "labels":labelArray,
    "companyId":root.companyId
  }
  this.setState({
    loading:true,
  })
  const { dispatch } = this.props;  
  dispatch(postSkuLabel(postBody)); 
 });
}

  onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area

    const items = [...this.state.data];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    this.setState({ data: items });
  };
   
  handleCancel = () => {
     this.setState({ visible: false });
    // this.props.form.resetFields();
    // this.props.handleCancel();
   }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
   
    // const keys = getFieldValue('keys');
    // console.log("=======console.log(keys)-------------");
    // console.log(keys);
    const formItems = this.state.keys.map((k, index) => (
      // <Col span={12} style={{display:"block"}}>
      <Col span={12}>
      <Form.Item
        label={k.label}
        required={true}
        key={k}
        // style={{float:"left"}}
      >
        {getFieldDecorator(`${k.label}`, {
      //    validateTrigger: ['onChange', 'onBlur'],
          initialValue: k.labelvalue == '' ? k.label : k.labelvalue,
          rules: [
            {
              required: true,
          //    whitespace: true,
              message: `Please input ${k.label}`,
            },
          ],
        })(<Input placeholder={`Enter new filed name`}  />)}
      </Form.Item>
       </Col>
    ));

    return (
      <div>
   
  
      <div className="certain-category-search-wrapper">
   
       </div>

        <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
           <Card bordered={false} title="Inventory Label configuration"> 
           
           <Form  onSubmit={this.FormSubmit}  {...formItemLayout}>
             <Spin spinning={this.state.loading}>
             <Row gutter={24}>
               
                {formItems}
            
              </Row>
              <Row gutter={24}>
                <Divider/>
                <Col lg={24} md={24} sm={24} xs={24}>
                 <center> 
                <Button key="submit" type="primary"  onClick={this.handleOk}>
                   Update
                </Button>
                </center>
                </Col>
              </Row>
              </Spin>
            </Form>
            
               </Card> 

        </Col>
       
        {/* <Col lg={12} md={12} sm={24} xs={24}>
           <Card title="JewelTrace" bordered={false}> 
             
           <ReactDragListView
          nodeSelector=".ant-list-item.draggble"
          onDragEnd={this.onDragEnd}
        >
          <List
            size="small"
            bordered
            dataSource={this.state.data}
            renderItem={item => {
              const draggble =
                item !== "Racing car sprays burning fuel into crowd.";
              return (
                <List.Item
                  actions={["x"]} u
                  className={draggble ? "draggble" : ""}
                >
                  <List.Item.Meta title={item} 
                  // description={"<TestModal />"} 
                  />
                </List.Item>
              );
            }}
          />
        </ReactDragListView>
               </Card> 

        </Col> */}
       
        </Row>
        
        
      </div>
    )
  }
}

ManageUser = Form.create()(ManageUser);
function mapStateToProps(state) {
  const {Common } = state
  const {skulabel_details:skulabel_details,post_skulable:post_skulable} =  Common
  return {skulabel_details,post_skulable}
}

const AppContainer = connect( 
  mapStateToProps 
)(ManageUser);


export default AppContainer;



