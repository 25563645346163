import React, { Component } from 'react';
import { Card, Form, Button, message, Input, Row, Col,Radio, Upload, Icon ,Table,Select, Divider} from 'antd';
import { connect } from 'react-redux';
import {createVanue,getVanue, putVanue} from '../../actions/index';
var cookies = require('browser-cookies');
const { Option } = Select;


class vanue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataArray:[],
            loading:false
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getVanue());
    }
    componentDidUpdate(prevProps, prevState) {
        try {
            if (prevProps.get_vanue != this.props.get_vanue) {                
                if (this.props.get_vanue.status) {
                    this.setState({
                        dataArray: this.props.get_vanue.data_array
                    })
                  
                }else{
                    this.setState({
                        dataArray: this.props.get_vanue.data_array
                    })
                }
                this.setState({
                    loading:false
                })
            }
            if (prevProps.create_vanue != this.props.create_vanue) {                
                if (this.props.create_vanue.status) {
                    message.success("Vanue is inserted successfully");
                    this.props.form.resetFields();
                    const { dispatch } = this.props;
                    dispatch(getVanue());
                }else{
                  message.error(this.props.create_vanue.errors.msg);
                }
            }
            

            if (prevProps.put_vanue != this.props.put_vanue) {                
                if (this.props.put_vanue.status) {
                    const { dispatch } = this.props;
                    dispatch(getVanue());
                }else{
                  message.error(this.props.put_vanue.errors.msg);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleTableRowChanges=(e)=>{
        this.setState({
            loading:true
        })
        // console.log(e);
        try {
            e.is_Selected=true;
            const { dispatch } = this.props;
            dispatch(putVanue(e));
        } catch (error) {
            console.log(error);
        }
    }
    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (err) {
                return;
            }
             let root =  JSON.parse(cookies.get('root'));
             const {dispatch} = this.props;
             values.companyId=root.companyId;
             if(this.state.dataArray.length === 0){
                values.is_Selected=true;
             }
             dispatch(createVanue(values));
        });
    };

    handleCancel = () => {
        this.setState({ visible: false });
        this.props.form.resetFields();
      }
      handleTableChange = (pagination, filters, sorter) => {
        let colome=sorter.columnKey;
        let order=sorter.order;
        let orderopp=0;
        let data=this.state.dataArray;
        if(order!=undefined){
          if(order==="descend"){
            order=-1;
            orderopp=1;
          }else{
            order=1;
            orderopp=-1;
          }
          data.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
        }else{
          const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
          shuffleArray(data);
        }
     };
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 14 },
            },
          };
          
          const columns = [
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              sorter: (a, b) => {},
            },
             {
                title: 'ADDRESS 1',
                dataIndex: 'address1',
                sorter: (a, b) => {},
                render: (text, record) => {
                    let length = 15;
                    let trimmedString = text.length > length ? 
                    text.substring(0, length - 3) + "..." : 
                    text;
                    return (
                        <span>
                        {trimmedString}
                    </span>
                    );
                },
            },
            {
                title: 'ADDRESS 2',
                dataIndex: 'address2',
                sorter: (a, b) => {},
                render: (text, record) => {
                    let length = 15;
                    let trimmedString = text.length > length ? 
                    text.substring(0, length - 3) + "..." : 
                    text;
                    return (
                        <span>
                        {trimmedString}
                    </span>
                    );
                },
            },
            {
                title: 'CITY',
                dataIndex: 'city',
                sorter: (a, b) => {},
                key: 'city',
              },
              {
                title: 'STATE',
                dataIndex: 'state',
                sorter: (a, b) => {},
                key: 'state',
              },
              {
                title: 'COUNTRY',
                dataIndex: 'country',
                sorter: (a, b) => {},
                key: 'country',
              },
              {
                title: 'ZIP',
                dataIndex: 'zip',
                sorter: (a, b) => {},
                key: 'zip',
              },
              {
                title: 'CODE',
                dataIndex: 'code',
                sorter: (a, b) => {},
                key: 'code',
              },
              {
                title: '',
                dataIndex: 'is_Selected',
                render: (text, record) => {
                    return (
                       <React.Fragment>
                          {text === true ?
                          <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{fontSize:"24px"}}/>    
                          :
                          <Icon type="check-circle" style={{fontSize:"24px"}}/>
                        }
                       </React.Fragment>                   
                      );
              },
            }
          ];
        return (
            <Row>
            <Col md={24} sm={24} xs={24} type="flex" >
            <Card  bodyStyle={{paddingBottom:"0px"}}>
               
                <Card style={{ borderRadius: "7px" }} title="Add Venue | Location " bodyStyle={{paddingBottom:"0px"}}>
                            <Form {...formItemLayout}  onSubmit={this.handleSubmit}>
                            
                            <Row>
                                <Col md={12} sm={24} xs={24}>
                                    <Form.Item label="Name" className="customFormItem">
                                        {getFieldDecorator('name', {
                                        // initialValue: this.state.currency_symbol,
                                            rules: [{ required: true, message: 'Please input name !' }],
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>     

                                <Col md={12} sm={24} xs={24}>
                                     <Form.Item label="Address 1" className="customFormItem">
                                        {getFieldDecorator('address1', {
                                        // initialValue: this.state.currency_symbol,
                                            rules: [{ required: true, message: 'Please input address 1!' }],
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                 </Col>                          
                            </Row>
                          

                            <Row>
                                <Col md={12} sm={24} xs={24}>
                                <Form.Item label="Address 2" className="customFormItem">
                                    {getFieldDecorator('address2', {
                                    // initialValue: this.state.currency_symbol,
                                        rules: [{ required: true, message: 'Please input address 2!' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                </Col>      
                                <Col md={12} sm={24} xs={24}>
                                <Form.Item label="City" className="customFormItem">
                                    {getFieldDecorator('city', {
                                    // initialValue: this.state.currency_symbol,
                                        rules: [{ required: true, message: 'Please input city !' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                </Col>

                                                   
                            </Row>

                            <Row>
                            <Col md={12} sm={24} xs={24}>
                            <Form.Item label="State" className="customFormItem">
                                {getFieldDecorator('state', {
                                // initialValue: this.state.currency_symbol,
                                    rules: [{ required: true, message: 'Please input state !' }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                            </Col>
                               
                            <Col md={12} sm={24} xs={24}>
                            <Form.Item label="Country" className="customFormItem">
                                {getFieldDecorator('country', {
                                // initialValue: this.state.currency_symbol,
                                    rules: [{ required: true, message: 'Please input country !' }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                            </Col>      
     

                                               
                        </Row>



                        <Row>
                                <Col md={12} sm={24} xs={24}>
                                <Form.Item label="Zip" className="customFormItem">
                                    {getFieldDecorator('zip', {
                                    // initialValue: this.state.currency_symbol,
                                        rules: [{ required: true, message: 'Please input zip !' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                </Col>
                                <Col md={12} sm={24} xs={24}>
                                <Form.Item label="Code" className="customFormItem">
                                    {getFieldDecorator('code', {
                                    // initialValue: this.state.currency_symbol,
                                        rules: [{ required: true, message: 'Please input code !' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                </Col>      
                        </Row>  


                        <Row>
                            <Divider/>
                            <Col md={24} sm={24} xs={24} style={{textAlign:"center"}}>                                
                                <Button key="back" onClick={this.handleCancel} style={{ minWidth: "77px" }} ghost>Cancel</Button>
                                <Button key="submit" type="primary" htmlType="submit" style={{ minWidth: "77px" }}>
                                Save
                            </Button>
                            </Col>      
                       </Row>
                             
                            </Form>
                            </Card>
                            
                <Table dataSource={this.state.dataArray} columns={columns} 
                        className="gx-table-responsive" 
                        size="small"
                        onRowClick ={this.handleTableRowChanges}
                        onChange={this.handleTableChange}
                        loading={this.state.loading}               
                        bodyStyle={{margin:0}}         
                />
                </Card>
                </Col>    
            </Row>
        );
    }
}


vanue = Form.create()(vanue);
function mapStateToProps(state) {
    const { Common } = state
    const { get_vanue: get_vanue,create_vanue:create_vanue,put_vanue:put_vanue} = Common
    return { get_vanue,create_vanue,put_vanue}
}

const AppContainer = connect(
    mapStateToProps,
    //mapDispatchToProps
)(vanue);
export default AppContainer;
