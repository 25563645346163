import React, {Component} from "react";
import {Button, Form, Input,message} from "antd";
import IntlMessages from "util/IntlMessages";
import { resetPassword }  from "../actions/index";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import CircularProgress from "components/CircularProgress/index";
const FormItem = Form.Item;


class ResetPassword extends Component {

    constructor() {
        super();
        this.state = {
          loader:false,
          token:''
        }
      }

      componentWillMount() {
        try {
          let url = window.location.href; 
          //let arr = url.split("/");
         // console.log(arr);
          let cType=url.split("?");
          if(cType.length>1){
            this.setState({
              token:cType[1]
            });
          }
        } catch (error) {
            console.log(error);
        }
      }
      componentDidUpdate(prevProps, prevState) {
        try {
        
        if(prevProps.reset_pass!=this.props.reset_pass)
        {
          // console.log(this.props.reset_pass);
           if(this.props.reset_pass.status)
           {
            message.success("Your password has been reset successfully! For Generate your new password link has been sent to email address");
            setTimeout(function(){
              window.location.href = '/signin';
           }, 2000);
          }else{
            message.error(this.props.reset_pass.errors.msg);
           }
           this.setState({
            loader:false
          });
        }
      }catch (error) {
        console.log(error);
        }
      }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //console.log('Received values of form: ', values);
        const {dispatch} = this.props;
        let resetDetails={
            "password" : values.confirm,
            "token":this.state.token,
        }
        this.setState({
          loader:true
        });
        dispatch(resetPassword(resetDetails));
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], {force: true});
    }
    callback();
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const {loader} = this.state;
    return (
    
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content" style={{paddingBottom:"35px"}}>
            <div className="gx-app-logo-content">
            <h1>Reset Password</h1>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/jewell-trace-logo.png")}/>
              </div>
            </div>

            <div className="gx-app-login-content" style={{marginTop:"40px"}}>
          
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

              <FormItem>
              {getFieldDecorator('password', {
                rules: [{
                  required: true, message: 'Please input your password!',
                }, {
                  validator: this.validateToNextPassword,
                }],
              })(
                <Input type="password" placeholder="New Password"/>
              )}
            </FormItem>

            <FormItem>
              {getFieldDecorator('confirm', {
                rules: [{
                  required: true, message: 'Please confirm your password!',
                }, {
                  validator: this.compareToFirstPassword,
                }],
              })(
                <Input placeholder="Retype New Password" type="password" onBlur={this.handleConfirmBlur}/>
              )}
            </FormItem>

            <FormItem style={{marginBottom:"0px"}}>
              <Button type="primary" htmlType="submit">
               Reset
              </Button>
            </FormItem>
              </Form>              
               
              <Link to="/signin">Sing In</Link>
            </div>

            


            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}           
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword = Form.create()(ResetPassword);

function mapStateToProps(state) {
  const {Common } = state
  const {reset_pass:reset_pass  } =  Common
  return {reset_pass}
}

const AppContainer = connect( 
  mapStateToProps 
)(ResetPassword);
export default AppContainer;