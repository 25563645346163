import React, { Component } from 'react';
import {connect} from "react-redux";
import {Card,Table,Icon,message,Popconfirm,Row,Col} from "antd";
import {getIncerementMaster,getCustomerMaster,updateCustomerMasterType} from "../../../actions/index";
import AddIncrementMasster from "./addincrementMaster";
import EditIncrementMasster from "./editincrementMaster";
import EditIncrementMassterCase2 from "./editIncrementMasterCase2";

class incrementMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          table_loading:true,
          pagination:'',
          remove_id:'',
          customer_type_array:[]
        };
      }
      componentDidMount()
      {
        const { dispatch } = this.props;  
        dispatch(getCustomerMaster());
      }
      componentDidUpdate(prevProps, prevState)
      {

          if (prevProps.customer_master != this.props.customer_master) {
            // console.log("customer_master_master_info");
            // console.log(this.props.customer_master);
              if(this.props.customer_master.status)
              {
                let json=this.props.customer_master.data_array;      
                var incrementArray=[];
                var customer_type_array=[];
                try {
                  for(let i=0; i<=json.length; i++)
                  {
                    if(json[i].increment_master.length == 0){
                    customer_type_array.push({value:json[i].customer_type,id:json[i]._id});
                    }
                    if(json[i].increment_master.length > 0)
                    {                       
                      incrementArray.push({incrementArray:json[i].increment_master[0],customer_type:json[i].customer_type,id:json[i]._id});
                    //  incrementArray.increment_master.customer_type=json[i].customer_type;
                    }
                  }
                } catch (error) {
                 // console.log(error);
                }
                  this.setState({
                    data:incrementArray,
                    customer_type_array:customer_type_array
                  })
              } 
              this.setState({
                table_loading:false
               })
               
            }
          
          
          if (prevProps.update_customer_master != this.props.update_customer_master) {
            if(this.props.update_customer_master.status)
            {
              message.success("Update cutomer type sucessfully");
              const {dispatch} = this.props;
              dispatch(getCustomerMaster()); 
            } 
          } 

          if (prevProps.remove_customer_master != this.props.remove_customer_master) {
            if(this.props.remove_customer_master.status)
            {
              message.success("cutomer type remove sucessfully");
              let data=this.state.data;
              this.setState({ data: data.filter(item => item._id !== this.state.remove_id) });
            } 
          } 
      }
     
    handleDelete = (id) => {
        const { dispatch } = this.props;
        dispatch(updateCustomerMasterType({_id:id,increment_master:[]}));
      }
   

    render() {
        const {data} =this.state;
        
const columns = [  
    {
      title: 'CUSTOMER TYPE',
      dataIndex: 'customer_type',
     // width: '20%',
      render: (text, record) => {
        const { finger_index } = record;
        return (
          <span>
              {text}
          </span>
        );
      }
    },
    {
      title: 'GOLD VALUE',
      dataIndex: 'incrementArray',
      //width: '10%',
      render: (text, record) => {
        const { incrementArray } = record;
        return (
          <span>
              {incrementArray.goldValue}
          </span>
        );
      }
    },
    {
        title: 'DIAMOND VALUE',
        dataIndex: 'isactive',
      //  width: '15%',
        render: (text, record) => {
          const { incrementArray } = record;
          const {diamondValue} = incrementArray;
          return (
            <span>
                {diamondValue}
            </span>
          );
        },
      },
      {
        title: 'COLOR STONE VALUE',
        dataIndex: 'desc1',
        //width: '15%',
        render: (text, record) => {
          const { incrementArray } = record;
          const {colorStoneValue} = incrementArray;
          return (
            <span>
                {colorStoneValue}
            </span>
          );
        }
      },
      {
        title: 'FINDING VALUE',
        dataIndex: 'desc2',
      //  width: '15%',
        render: (text, record) => {
          const { incrementArray } = record;
          const {findingValue} = incrementArray;
          return (
            <span>
                {findingValue}
            </span>
          );
        }
      },
      {
        title: 'LABOUR',
        dataIndex: 'desc3',
     //   width: '10%',
        render: (text, record) => {
          const { incrementArray } = record;
          const {labourValue} = incrementArray;
          return (
            <span>
                {labourValue}
            </span>
          );
        }
      },
      {
        title: 'ACTION',
        dataIndex: 'status',
      //  width: '10%',
        render: (text, record) => {
          const {id,customer_type,incrementArray} = record;
          var {goldValue,diamondValue,colorStoneValue,findingValue,labourValue} =incrementArray;
          var visibilrFor=false;
          var incementPer='';
         if(incrementArray.hasOwnProperty('incementPer'))
         {
          visibilrFor=true;
          incementPer=incrementArray.incementPer;
          if(goldValue==1){
            goldValue=true
          }else{
            goldValue=false
          }
          if(diamondValue==1){
            diamondValue=true
          }else{
            diamondValue=false
          }
          if(colorStoneValue==1){
            colorStoneValue=true
          }else{
            colorStoneValue=false
          }
          if(findingValue==1){
            findingValue=true
          }else{
            findingValue=false
          }
          if(labourValue==1){
            labourValue=true
          }else{
            labourValue=false
          }

         }else{
          visibilrFor=false;
         }
        
          return (
            <span>
            {visibilrFor? 
            <EditIncrementMassterCase2 incementPer={incementPer} id={id} cust_type={customer_type} goldValue={goldValue} labourValue={labourValue} findingValue={findingValue} diamondValue={diamondValue} colorStoneValue={colorStoneValue} />:
            <EditIncrementMasster id={id} cust_type={customer_type} goldValue={goldValue} labourValue={labourValue} findingValue={findingValue} diamondValue={diamondValue} colorStoneValue={colorStoneValue} />          
            }
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.id)}>
                <Icon type="delete" style={{fontSize:"18px",color:"red",paddingLeft:"15px",cursor:"pointer"}}/>  
              </Popconfirm>
              </span>
          );
        },
        
      },
    ];
        return (
            <div>
            <Row>
            <Col md={18} sm={24} xs={24}> 
                <h2 className="title gx-mb-4" style={{float:"left"}}>Increment Master</h2>
            </Col>
            <Col md={6} sm={24} xs={24}>
                 <AddIncrementMasster text="Add Increment master" customer_type_array={this.state.customer_type_array}/>
             </Col>
            </Row>
            
           {/*  <EditCust/>*/}
            <Card bodyStyle={{padding:"10px 0px"}}>
                    <Table className="gx-table-responsive" 
                    loading={this.state.table_loading} 
                    columns={columns} 
                    dataSource={data}
                    size={"small"}
                    rowKey={record => record.id}
                    //pagination={this.state.pagination}
                    onChange={this.handleTableChange}    
                />
             </Card> 
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {Common } = state
    const {increment_master_info:increment_master_info,customer_master:customer_master,update_customer_master:update_customer_master} =  Common
    return {increment_master_info,customer_master,update_customer_master}
}
const AppContainer = connect( 
mapStateToProps 
)(incrementMaster);
export default AppContainer;