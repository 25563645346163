import React, { Component } from 'react';
import { Card, Form, Button, message, Input,Spin,InputNumber, Row, Col,Radio, Upload, Icon ,Table,Select, Divider} from 'antd';
import { connect } from 'react-redux';
import {createCurrancy,getCurrancy, putCurrancy} from '../../actions/index';
import moment from "moment";
import {currencyConverter} from "./../../containers/App/commanFunction";
var cookies = require('browser-cookies');
const { Option } = Select;


class currancy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataArray:[],
            loading:true,
            spiningloading:false,
            currancyCode:'USD'
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getCurrancy());
    }
    componentDidUpdate(prevProps, prevState) {
        try {
            if (prevProps.get_currancy != this.props.get_currancy) {                
                if (this.props.get_currancy.status) {
                    let dataArray=this.props.get_currancy.data_array;
                    dataArray.sort((a, b) => (a[`createdAt`] > b[`createdAt`]) ? -1 : 1);
                    this.setState({
                        dataArray: dataArray,

                    });
                }else{
                    this.setState({
                        dataArray: this.props.get_currancy.data_array
                    })
                }
                this.setState({
                    loading:false
                })
            }
            if (prevProps.create_currancy != this.props.create_currancy) {                
                if (this.props.create_currancy.status) {
                    message.success("Currancy is inserted successfully");
                    this.props.form.resetFields();
                    const { dispatch } = this.props;
                    this.setState({spiningloading:false,currancyCode:''});
                    localStorage.setItem('currancy',this.props.create_currancy.data.symbol);
                    //localStorage.setItem(this.props.create_currancy.data.symbol);
                    dispatch(getCurrancy());
                }else{
                  message.error(this.props.create_currancy.errors.msg);
                }
            }
            

            if (prevProps.put_currancy != this.props.put_currancy) {                
                if (this.props.put_currancy.status) {
                    const { dispatch } = this.props;
                    dispatch(getCurrancy());
                }else{
                  message.error(this.props.put_currancy.errors.msg);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleTableRowChanges=(e)=>{
        this.setState({
            loading:true
        })
        console.log(e);
        try {
            e.is_Selected=true;
            const { dispatch } = this.props;
            dispatch(putCurrancy(e));
        } catch (error) {
            console.log(error);
        }
    }
    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            // console.log(values);
            try {
            if (err) {
                return;
            }
             this.setState({
                spiningloading:true
             })
             let root =  JSON.parse(cookies.get('root'));
             const {dispatch} = this.props;
             values.companyId=root.companyId;
             dispatch(createCurrancy(values));
            } catch (error) {
                console.log(error);
            }
        });
    };
    changeCurrancy=(e)=>{
        let amt='';
        if(e=="$"){
            amt='USD';
        }else if(e == "₹"){
            amt='INR';
        }else if(e == "€"){
            amt='EUR';
        }else if(e == "£"){
            amt='GBP';
        }
        this.setState({
            currancyCode:amt
        })
    }
    handleCancel = () => {
        this.setState({ visible: false, currancyCode:'USD' });
        this.props.form.resetFields();
      }
      handleTableChange = (pagination, filters, sorter) => {
        let colome=sorter.columnKey;
        let order=sorter.order;
        let orderopp=0;
        let data=this.state.dataArray;
        if(order!=undefined){
          if(order==="descend"){
            order=-1;
            orderopp=1;
          }else{
            order=1;
            orderopp=-1;
          }
          data.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
        }else{
          const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
          shuffleArray(data);
        }
     };
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 14 },
            },
          };
          
          const columns = [   
            {
                title: 'DATE',
                dataIndex: 'createdAt',
                sorter: (a, b) => {},
                render: (text, record) => {
                  return <span>{moment(text).format("DD-MM-YYYY hh:mm A")}</span>;
                }
              },      
               {
                title: 'SYMBOL',
                dataIndex: 'symbol',
                sorter: (a, b) => {},
              },
              {
                title: 'CONVERSION RATE',
                dataIndex: 'conversion_rate',
                sorter: (a, b) => {},
                // render: (text, record) => {
                //     return (
                //       <span>
                //        {currencyConverter(text)}
                //       </span>
                //     );
                //   }
              },
              {
                title: 'CURRENCY CODE',
                dataIndex: 'currency_code',
                sorter: (a, b) => {},
              }
            //   {
            //     title: 'COUNTRY',
            //     dataIndex: 'country',
            //     sorter: (a, b) => {},
            //     key: 'country',
            //   },
            //   {
            //     title: 'CURRANCY',
            //     dataIndex: `currancy_info[0]['currancy_per']`,
            //     sorter: (a, b) => {},
            //   },
            //   {
            //     title: '',
            //     dataIndex: 'is_Selected',
            //     render: (text, record) => {
            //         return (
            //            <React.Fragment>
            //               {text === true ?
            //               <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{fontSize:"24px"}}/>    
            //               :
            //               <Icon type="check-circle" style={{fontSize:"24px"}}/>
            //             }
            //            </React.Fragment>                   
            //           );
            //   },
            // }
          ];
        return (
            <Row>
            <Col md={24} sm={24} xs={24} type="flex" >
            <Card  bodyStyle={{paddingBottom:"0px"}}>
                    <Spin tip="Loading..." spinning={this.state.spiningloading}>
                <Card style={{ borderRadius: "7px" }} title="Add Currency " bodyStyle={{paddingBottom:"0px"}} >
                            <Form {...formItemLayout}  onSubmit={this.handleSubmit} >
                            
                            <Row>
                            <Col md={13} sm={24} xs={24}>
                                <Form.Item label="Currency Symbol" className="customFormItem">
                                {getFieldDecorator('symbol', {
                                    initialValue: '$',
                                    rules: [{ required: true, message: 'Please input currency symbols !' }],
                                })(
                                    <Select placeholder="Please select currency symbols" style={{ minWidth: "180px" }} onChange={this.changeCurrancy}>                           
                                    <Option key={"$"} value={"$"}>Us Doller ($)</Option>
                                {/** 
                                    <Option key={"€"} value={"€"}>Euro (€) </Option>
                                    <Option key={"£"} value={"£"}>Pounds (£) </Option>
                                    <Option key={"₹"} value={"₹"}>Rupia (₹) </Option>*/}                                 
                                </Select>
                                )}
                                </Form.Item>        
                            </Col>     
                            </Row>
                                 <Row>
                                <Col md={13} sm={24} xs={24}>
                                <Form.Item label="1 USD to INR" className="customFormItem">
                                    {getFieldDecorator('conversion_rate', {
                                    // initialValue: this.state.currency_symbol,
                                        rules: [{ required: true, message: 'Please input convesation rate !' }],
                                    })(
                                        <InputNumber prefix="￥" formatter={value => `₹ ${value}`}  min={0} placeholder="Convesation rate" style={{width:"100%"}} />
                                    )}
                                </Form.Item>    
                                </Col>     
                                </Row>
                        
                                <Row>    
                                <Col md={13} sm={24} xs={24}>
                                        <Form.Item label="Currency Code" className="customFormItem">
                                        {getFieldDecorator('currency_code', {
                                         initialValue: this.state.currancyCode,
                                            rules: [{ required: true, message: 'Please input currancy code !' }],
                                        })(
                                            <Input   placeholder="Curency code" readOnly/>
                                        )}
                                    </Form.Item>
                                 </Col>                          
                                 </Row>
           
                        
                        <Row>
                            <Divider/>
                            <Col md={13} sm={24} xs={24} style={{textAlign:"center"}}>                                
                                <Button key="back" onClick={this.handleCancel} style={{ minWidth: "77px" }} ghost>Cancel</Button>
                                <Button key="submit" type="primary" htmlType="submit" style={{ minWidth: "77px" }}>
                                Save
                            </Button>
                            </Col>      
                       </Row>
                             
                            </Form>
                            </Card>
                   </Spin>         
                <Table dataSource={this.state.dataArray} columns={columns} 
                        className="gx-table-responsive" 
                        size="small"
                     //   onRowClick ={this.handleTableRowChanges}
                        onChange={this.handleTableChange}
                        loading={this.state.loading}               
                        bodyStyle={{margin:0}}         
                />
                </Card>
                </Col>    
            </Row>
        );
    }
}


currancy = Form.create()(currancy);
function mapStateToProps(state) {
    const { Common } = state
    const { get_currancy: get_currancy,create_currancy:create_currancy,put_currancy:put_currancy} = Common
    return { get_currancy,create_currancy,put_currancy}
}

const AppContainer = connect(
    mapStateToProps,
    //mapDispatchToProps
)(currancy);
export default AppContainer;
