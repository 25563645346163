import React, {Component} from 'react';
import {Button, Card, Col, Divider, Form, Input, message, Row, Select, Spin, Table} from 'antd';
import {connect} from 'react-redux';
import {createMetal, getMetal, getPurities, putMetal} from '../../actions/index';
import moment from "moment";

var cookies = require('browser-cookies');
const {Option} = Select;


class metal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataArray: [],
            loading: true,
            spiningloading: false,
            fields: [],
        };
    }

    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(getPurities("GOLD"));
        dispatch(getMetal());
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (prevProps.get_purity != this.props.get_purity) {
                if (this.props.get_purity.status) {
                    let responseArray = this.props.get_purity.data.main_type;
                    if (responseArray != null) {
                        let array = responseArray;
                        for (let i = 0; i < array.length; i++) {
                            array[i].header = array[i].purity;
                            array[i].header = (array[i].header.toString()).replace('.', '_');

                        }
                        this.setState({fields: array});
                    }

                }
            }

            if (prevProps.get_metal != this.props.get_metal) {
                if (this.props.get_metal.status) {
                    let data = this.props.get_metal.data_array;
                    let dataArray = [];
                    for (const iterator of data) {
                        let currencyType = iterator.main_type;
                        // let objetcCurrancy={};
                        // for (let index = 0; index < currancyType.length; index++) {
                        //     const element = currancyType[index];
                        //     console.log(element);
                        //     objetcCurrancy={}
                        // }

                        if (currencyType.length > 0) {
                            let obj = {};
                            for (let i = 0; i < currencyType.length; i++) {
                                obj[currencyType[i].kt] = currencyType[i].price;
                            }
                            obj['currency_code'] = iterator.currency_code;
                            obj['createdAt'] = iterator.createdAt;
                            dataArray.push(obj);
                        }
                    }
                    dataArray.sort((a, b) => (a[`createdAt`] > b[`createdAt`]) ? -1 : 1);

                    this.setState({
                        dataArray: dataArray
                    });
                } else {
                    this.setState({
                        dataArray: this.props.get_metal.data_array
                    })
                }
                this.setState({
                    loading: false
                })
            }
            if (prevProps.create_metal != this.props.create_metal) {
                if (this.props.create_metal.status) {
                    message.success("Metal is inserted successfully");
                    this.props.form.resetFields();
                    const {dispatch} = this.props;
                    this.setState({spiningloading: false});
                    dispatch(getMetal());
                } else {
                    this.setState({
                        loading: false
                    })
                    message.error(this.props.create_metal.errors.msg);
                }
            }


            if (prevProps.put_metal != this.props.put_metal) {
                if (this.props.put_metal.status) {
                    const {dispatch} = this.props;
                    dispatch(getMetal());
                } else {
                    message.error(this.props.put_metal.errors.msg);
                }
            }
        } catch
            (error) {
            console.log(error);
        }
    }

    handleTableRowChanges = (e) => {
        this.setState({
            loading: true
        })
        // console.log(e);
        try {
            e.is_Selected = true;
            const {dispatch} = this.props;
            dispatch(putMetal(e));
        } catch (error) {
            console.log(error);
        }
    }
    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            try {
                if (err) {
                    return;
                }
                var result = Object.keys(values).map((key) => [key, values[key]]);
                let metalType = "";
                let mainType = [];
                for (let i = 0; i < result.length; i++) {
                    if (result[i][0] !== 'metal_type') {
                        result[i][0] = result[i][0].replace('_', '.');
                        let obj = {};
                        obj['kt'] = result[i][0];
                        obj['price'] = result[i][1];
                        mainType.push(obj);
                    } else
                        metalType = result[i][1];
                }
                // const data = Object.fromEntries(result);
                // const main_type = result;

                this.setState({
                    spiningloading: true
                })

                let root = JSON.parse(cookies.get('root'));
                const {dispatch} = this.props;

                let currancy = localStorage.getItem('currancy');
                let amt = 'INR';
                /*if(currancy=="$"){
                   amt='USD';
               }else{
                   amt='INR';
               }*/
                // }else if(currancy == "€"){
                //     amt=new Intl.NumberFormat('de-DE').format(amt);
                // }else if(currancy == "£"){
                //     amt=new Intl.NumberFormat('en-US').format(amt);
                // }
                let data = {
                    "main_type": mainType,
                    "metal_type": values.metal_type,
                    "companyId": root.companyId,
                    "currency_code": amt
                }
                // console.log(data);
                dispatch(createMetal(data));

            } catch (error) {
                console.log(error);
            }
        });
    };

    toObject(arr) {
        var rv = {};
        for (var i = 0; i < arr.length; ++i)
            rv[i] = arr[i];
        return rv;
    }

    handleCancel = () => {
        this.setState({visible: false});
        this.props.form.resetFields();
    }
    handleTableChange = (pagination, filters, sorter) => {
        let colome = sorter.columnKey;
        let order = sorter.order;
        let orderopp = 0;
        let data = this.state.dataArray;
        if (order != undefined) {
            if (order === "descend") {
                order = -1;
                orderopp = 1;
            } else {
                order = 1;
                orderopp = -1;
            }
            data.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
        } else {
            const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
            shuffleArray(data);
        }
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const formItems = (this.state.fields != null) ? this.state.fields.map((k, index) => (
                (k.purity.length > 0) ?
                    <React.Fragment>
                        {
                            <Row>
                                <Col md={12} sm={24} xs={24}>
                                    <Form.Item
                                        label={k.purity}
                                        key={k}
                                    >
                                        {getFieldDecorator(`${k.header}`, {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `Please input ${k.purity}`,
                                                },
                                            ],
                                        })(<Input placeholder={`Enter ${k.purity}`}/>)}
                                    </Form.Item>

                                </Col>
                            </Row>
                        }
                    </React.Fragment> : ""
            )) :
            "";

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 14},
            },
        };

        let columns = [
            {
                title: 'DATE',
                dataIndex: 'createdAt',
                width: '12.5%',
                sorter: (a, b) => {
                },
                render: (text, record) => {
                    return <span>{moment(text).format("DD-MM-YYYY")}</span>;
                }
            },
        ];
        this.state.fields.map((k, index) => (columns.push({
            title: k.purity, dataIndex: k.purity, sorter: (a, b) => {
            }
        })))

        //   {
        //     title: 'COUNTRY',
        //     dataIndex: 'country',
        //     sorter: (a, b) => {},
        //     key: 'country',
        //   },
        //   {
        //     title: 'METAL',
        //     dataIndex: `metal_info[0]['metal_per']`,
        //     sorter: (a, b) => {},
        //   },
        //   {
        //     title: '',
        //     dataIndex: 'is_Selected',
        //     render: (text, record) => {
        //         return (
        //            <React.Fragment>
        //               {text === true ?
        //               <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{fontSize:"24px"}}/>
        //               :
        //               <Icon type="check-circle" style={{fontSize:"24px"}}/>
        //             }
        //            </React.Fragment>
        //           );
        //   },
        // }

        return (
            <Row>
                <Col md={24} sm={24} xs={24} type="flex">
                    <Card bodyStyle={{paddingBottom: "0px"}}>
                        <Spin tip="Loading..." spinning={this.state.spiningloading}>
                            <Card style={{borderRadius: "7px"}} title="Add Metal (In Indian Rupee) "
                                  bodyStyle={{paddingBottom: "0px"}}>
                                <Form {...formItemLayout} onSubmit={this.handleSubmit}>

                                    <Row>
                                        <Col md={12} sm={24} xs={24}>
                                            <Form.Item label="Metal Type" className="customFormItem">
                                                {getFieldDecorator('metal_type', {
                                                    initialValue: 'GOLD',
                                                    rules: [{required: true, message: 'Please metal type !'}],
                                                })(
                                                    <Select>
                                                        <Select.Option value="GOLD">
                                                            Gold
                                                        </Select.Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {/*<Row>
                                        <Col md={12} sm={24} xs={24}>
                                            <Form.Item label="14K" className="customFormItem">
                                                {getFieldDecorator('k14', {
                                                    // initialValue: this.state.currency_symbol,
                                                    rules: [{required: true, message: 'Please input price !'}],
                                                })(
                                                    <InputNumber min={0} placeholder="Price" style={{width: "100%"}}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} sm={24} xs={24}>
                                            <Form.Item label="18K" className="customFormItem">
                                                {getFieldDecorator('k18', {
                                                    // initialValue: this.state.currency_symbol,
                                                    rules: [{required: true, message: 'Please input price !'}],
                                                })(
                                                    <InputNumber min={0} placeholder="Price" style={{width: "100%"}}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} sm={24} xs={24}>
                                            <Form.Item label="22K" className="customFormItem">
                                                {getFieldDecorator('k22', {
                                                    // initialValue: this.state.currency_symbol,
                                                    rules: [{required: true, message: 'Please input price !'}],
                                                })(
                                                    <InputNumber min={0} placeholder="Price" style={{width: "100%"}}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} sm={24} xs={24}>
                                            <Form.Item label="24K" className="customFormItem">
                                                {getFieldDecorator('k24', {
                                                    // initialValue: this.state.currency_symbol,
                                                    rules: [{required: true, message: 'Please input price !'}],
                                                })(
                                                    <InputNumber min={0} placeholder="Price" style={{width: "100%"}}/>
                                                )}
                                            </Form.Item>
                                        </Col>

                                    </Row>*/}

                                    {/*<Row>*/}
                                    {formItems}
                                    {/*</Row>*/}
                                    <Row>
                                        <Divider/>
                                        <Col md={12} sm={24} xs={24} style={{textAlign: "center"}}>
                                            <Button key="back" onClick={this.handleCancel} style={{minWidth: "77px"}}
                                                    ghost>Cancel</Button>
                                            <Button key="submit" type="primary" htmlType="submit"
                                                    style={{minWidth: "77px"}}>
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </Card>
                        </Spin>
                        <Table dataSource={this.state.dataArray} columns={columns}
                               className="gx-table-responsive"
                               size="small"
                            //   onRowClick ={this.handleTableRowChanges}
                               onChange={this.handleTableChange}
                               loading={this.state.loading}
                               bodyStyle={{margin: 0}}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}


metal = Form.create()(metal);

function mapStateToProps(state) {
    const {Common} = state
    const {get_metal: get_metal, create_metal: create_metal, put_metal: put_metal, get_purity: get_purity} = Common
    return {get_metal, create_metal, put_metal, get_purity}
}

const AppContainer = connect(
    mapStateToProps,
    //mapDispatchToProps
)(metal);
export default AppContainer;
