import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getAllBrachList,
    getAllFloorList,
    getAllSectionList,
    getAllSubSectionList,
    getSKUbyID,
    getSoldSKUByRootInfoAndId,
    getSoldSKUExport
} from '../../../actions/index';
import {
    Alert,
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Icon,
    Modal,
    Row,
    Select,
    Spin,
    Table
} from "antd";
import ReactExport from "react-data-export";
import Gears from "../../../assets/images/gears.gif";
import ExportIcon from "./../../../assets/images/icons/export.svg";
import CountUp from 'react-countup';
import socket from './../../../containers/App/global';
import moment from "moment";
import StockViewPage from "./stockviewInDetailsJT";
import FilterIcon from '../../../assets/images/icons/filter-icon.svg';
import UTILS from "../../../util/utils";

const { RangePicker } = DatePicker;
var cookies = require('browser-cookies');
message.config({
    duration: 1,
    maxCount: 3,
});
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Option } = Select;
let usertype = localStorage.getItem('type_user');



class Soldstock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selectedRowKeys: [],
            selectAll: [],
            table_loading: true,
            Spinloading: false,
            pagination: {},
            total_stock: 0,
            btndisabled: true,
            loading: true,
            dataforExport: [],
            selected_id: '',
            textForSelect: '',
            floor_id: '',
            branch_id: '',
            section_id: '',
            selection_const: true,
            visibleStockDelete: false,
            display: false,
            serach: '',
            btnsearch: true,
            ctgArray: [],
            counterArray: [],
            locationArray: [],
            batchArray: [],
            floor_list: [],
            floorList: [],
            sectionList: [],
            subSectionList: [],
            fromDate: '',
            toDate: '',
            d_cat: '',
            counter: '',
            location: '',
            batchno: ''
        };
    }
    componentDidMount() {
        const { dispatch } = this.props;
        let usertype = localStorage.getItem('type_user');
        let root = JSON.parse(cookies.get('root'));

        if (usertype === "branchAdmin") {
            this.setState({
                rootInfo: "floor",
                rootInfoEqual: "branch",
                textForSelect: "Select Floor",
                textForSelect1: "Select Section",
                id: root.branchId,
                selected_id: root.branchId,
                selectedRootInfoEqual: "branch",
                rootLess: "section",
            });
            dispatch(getAllFloorList("id"));
        }
        if (usertype === "companyAdmin") {
            this.setState({
                rootInfo: "branch",
                rootInfoEqual: "company",
                textForSelect: "Select Branch",
                textForSelect1: "Select Floor",
                id: root.companyId,
                selected_id: root.companyId,
                rootLess: "floor",
                selectedRootInfoEqual: "company"
            });
            dispatch(getAllBrachList("id"));
        }
        if (usertype === "floorAdmin") {
            this.setState({
                rootInfo: "section",
                rootInfoEqual: "floor",
                rootLess: "subSection",
                textForSelect: "Select Section",
                textForSelect1: "Select SubSection",
                id: root.floorId,
                selected_id: root.floorId,
                selectedRootInfoEqual: "floor"
            });
            dispatch(getAllSectionList("id"));
        }

        socket.on('Notification', (col) => {
            console.log(col);
            try {
                if (col.data_code === "401") {
                    const { dispatch } = this.props;
                    if (this.state.selected_id == this.state.id) {
                        dispatch(getSoldSKUByRootInfoAndId(this.state.selected_id, this.state.rootInfoEqual, 0, 10, "", "", "", "", "", "", "", "", ""));
                    } else {
                        dispatch(getSoldSKUByRootInfoAndId(this.state.selected_id, this.state.rootInfo, 0, 10, "", "", "", "", "", "", "", "", ""));
                    }
                } else
                    if (col.data_code === "106") {

                    }


            } catch (e) {
                console.log("error", e);
            }
        })

    }
    componentDidUpdate(prevProps, prevState) {
        try {



            if (prevProps.branch_list != this.props.branch_list) {

                if (this.props.branch_list.status) {
                    if (this.state.selection_const) {
                        this.setState({
                            branch_id: this.props.branch_list.data_array[0]._id,
                            branch_list: this.props.branch_list.data_array,
                        });
                        if (usertype === "companyAdmin") {
                            this.setState({
                                selection_const: false
                            })
                        }
                        if (this.props.branch_list.data_array.length >= 1) {
                            const { dispatch } = this.props;
                            dispatch(getSoldSKUByRootInfoAndId(this.state.id, this.state.rootInfoEqual, 0, 10, "", "", "", "", "", "", "", "", ""));
                        }
                    }
                } else {
                    this.setState({
                        branch_list: []
                    });
                }
            }

            if (prevProps.floor_list != this.props.floor_list) {
                if (this.props.floor_list.status) {
                    if (this.state.selection_const) {
                        this.setState({
                            floor_id: this.props.floor_list.data_array[0]._id,
                            floor_list: this.props.floor_list.data_array,
                            //selected_id:this.props.floor_list.data_array[0]._id,

                        });
                        if (usertype === "branchAdmin") {
                            this.setState({
                                selection_const: false
                            })
                        }
                        if (this.props.floor_list.data_array.length >= 1) {
                            const { dispatch } = this.props;
                            dispatch(getSoldSKUByRootInfoAndId(this.state.id, this.state.rootInfoEqual, 0, 10, "", "", "", "", "", "", "", "", ""));
                        }
                    }
                    this.setState({
                        floorList: this.props.floor_list.data_array
                    })
                } else {
                    this.setState({
                        floor_list: []
                    });
                }
            }

            if (prevProps.section_list != this.props.section_list) {

                if (this.props.section_list.status) {
                    if (this.state.selection_const) {
                        this.setState({
                            section_id: this.props.section_list.data_array[0]._id,
                            section_list: this.props.section_list.data_array,
                            //selected_id:this.props.section_list.data_array[0]._id,       
                        });
                        if (usertype === "floorAdmin") {
                            this.setState({
                                selection_const: false
                            })
                        }
                        if (this.props.section_list.data_array.length >= 1) {
                            const { dispatch } = this.props;
                            dispatch(getSoldSKUByRootInfoAndId(this.state.id, this.state.rootInfoEqual, 0, 10, "", "", "", "", "", "", "", "", ""));
                        }
                    }
                    this.setState({
                        sectionList: this.props.section_list.data_array
                    })
                } else {
                    this.setState({
                        section_list: [],
                        sectionList: []
                    });
                }
            }

            if (prevProps.sub_section_list != this.props.sub_section_list) {
                if (this.props.sub_section_list.status) {
                    console.log(this.props.sub_section_list.data_array);
                    this.setState({
                        subSectionList: this.props.sub_section_list.data_array
                    });
                    console.log(this.state.subSectionList);
                } else {
                    this.setState({
                        subSectionList: [],
                    });
                }
            }

            if (prevProps.get_sold_stock != this.props.get_sold_stock) {

                if (this.props.get_sold_stock.status) {
                    let stock = this.props.get_sold_stock.data_array;
                    let selectedRowKeys = [];
                    let pagination = {};
                    pagination.total = this.props.get_sold_stock.data.totalSku;
                    pagination.showQuickJumper = true;
                    pagination.showSizeChanger = true;
                    pagination.pageSizeOptions = ['25', '50', '75', '100'];
                    this.setState({
                        ctgArray: this.props.get_sold_stock.data.d_cat,
                        counterArray: this.props.get_sold_stock.data.counter,
                        batchArray: this.props.get_sold_stock.data.batchno,
                        locationArray: this.props.get_sold_stock.data.location,
                        data: this.props.get_sold_stock.data_array,
                        selectAll: selectedRowKeys,
                        total_stock: this.props.get_sold_stock.data.totalSku,
                        pagination
                    })
                } else {
                    this.setState({
                        data: [],
                        total_stock: 0
                    });
                }
                this.setState({
                    table_loading: false
                })
            }


            if (prevProps.get_sold_stock != this.props.get_sold_stock) {
                if (this.props.get_sold_stock !== undefined) {
                    this.setState({
                        dataforExport: this.props.get_sold_stock,
                        loading: false
                    });
                }
            }

            if (prevProps.get_sold_stock_xl != this.props.get_sold_stock_xl) {
                if (this.props.get_sold_stock_xl !== undefined) {
                    this.setState({
                        dataforExport: this.props.get_sold_stock_xl,
                        loading: false
                    });
                }
            }

        } catch (error) {
            console.log(error);
        }

    }
    onSelectChange = (selectedRowKeys) => {

        this.setState({ selectedRowKeys });
        if (selectedRowKeys.length > 0) {
            this.setState({ btndisabled: false });
        } else {
            this.setState({ btndisabled: true });
        }

    };

    handleTableChange = (pagination, filters, sorter) => {


        this.props.form.validateFields((err, values) => {
            console.log(values);
            const pager = { ...this.state.pagination };
            pager.current = pagination.current;
            pager.pageSize = pagination.pageSize;
            this.setState({
                pagination: pager,
                selectedRowKeys: [],
                table_loading: true
            });
            let fromDate = "";
            let toDate = "";
            let d_cat = "";
            let counter = "";
            let location = "";
            let batchno = "";

            let root = this.state.rootInfo;
            let id = id = values.rootid;
            if (values.rootid === undefined) {
                root = "";
                id = "";
            }
            if (values.secondid != undefined) {
                id = values.secondid;
                root = this.state.rootLess;
            }

            if (values.d_cat != undefined) {
                d_cat = values.d_cat
            }

            if (values.counter != undefined) {
                counter = values.counter
            }

            if (values.batchno != undefined) {
                batchno = values.batchno
            }

            if (values.location != undefined) {
                location = values.location
            }
            if (values.date != undefined) {
                if (values.date.length > 0) {
                    let fromDate1 = moment(values.date[0]).set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
                    let toDate1 = moment(values.date[1]).set({ 'hour': 23, 'minute': 59, 'second': 0, 'millisecond': 0 });
                    fromDate = fromDate1.toISOString();
                    toDate = toDate1.toISOString();
                }
            }
            const { dispatch } = this.props;
            if (this.state.selected_id === this.state.id) {
                dispatch(getSoldSKUByRootInfoAndId(this.state.selected_id, this.state.rootInfoEqual, pager.current - 1, pagination.pageSize, this.state.search, fromDate, toDate, sorter.columnKey, sorter.order, d_cat, counter, location, batchno));
            } else {

                dispatch(getSoldSKUByRootInfoAndId(id, root, pager.current - 1, pagination.pageSize, this.state.search, fromDate, toDate, sorter.columnKey, sorter.order, d_cat, counter, location, batchno));
            }
        });
    };

    showModal = () => {
        this.setState({
            visible: true,
            loading: true,
            dataforExport: [],
        });
        const { dispatch } = this.props;
        dispatch(getSoldSKUExport(this.state.selected_id, this.state.selectedRootInfoEqual, 0, this.state.total_stock, "", this.state.fromDate, this.state.toDate, "", "", this.state.d_cat, this.state.counter, this.state.location, this.state.batchno));
    };
    handleCancel = () => {
        this.setState({
            visible: false,
            loading: true
        });

    };

    downLoad = () => {
        const url = window.URL.createObjectURL(new Blob([this.state.dataforExport], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Sold Stock - ${new Date()}`); //or any other extension
        document.body.appendChild(link);
        link.click();
    };


    onSerach = (e) => {
        try {

            if (this.state.search.length > 1) {


                this.props.form.validateFields((err, values) => {
                    if (err) {
                        return;
                    }
                    const { dispatch } = this.props;
                    let fromDate = "";
                    let toDate = "";
                    let d_cat = "";
                    let counter = "";
                    let location = "";
                    let batchno = "";

                    let root = this.state.rootInfo;
                    let id = id = values.rootid;
                    if (values.rootid === undefined) {
                        root = "";
                        id = "";
                    }
                    if (values.secondid != undefined) {
                        id = values.secondid;
                        root = this.state.rootLess;
                    }

                    if (values.date != undefined) {

                        if (values.date.length > 0) {
                            let fromDate1 = moment(values.date[0]).utc().set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
                            let toDate1 = moment(values.date[1]).utc().set({ 'hour': 23, 'minute': 59, 'second': 0, 'millisecond': 0 });
                            fromDate = fromDate1.toISOString();
                            toDate = toDate1.toISOString();
                        }
                    }
                    if (values.d_cat != undefined) {
                        d_cat = values.d_cat;
                    }


                    if (values.counter != undefined) {
                        counter = values.counter;
                    }

                    if (values.location != undefined) {
                        location = values.location;
                    }

                    if (values.batchno != undefined) {
                        batchno = values.batchno;
                    }
                    this.setState({
                        table_loading: true
                    });

                    if (this.state.selected_id === this.state.id) {
                        dispatch(getSoldSKUByRootInfoAndId(this.state.selected_id, this.state.rootInfoEqual, 0, 10, this.state.search, fromDate, toDate, "", "", "", counter, location, batchno));
                    } else {
                        dispatch(getSoldSKUByRootInfoAndId(id, root, 0, 10, this.state.search, fromDate, toDate, "", "", "", counter, location, batchno));
                    }
                });
            }

        } catch (error) {

        }
    };
    onChangeBranchRadio = (e) => {
        // this.setState({
        // table_loading:true,
        // selected_id:e
        //})
        const { dispatch } = this.props;
        console.log(e);
        if (this.state.rootInfoEqual === "company") {
            dispatch(getAllFloorList('bybranchId', e));
        } else if (this.state.rootInfoEqual === "branch") {
            dispatch(getAllSectionList('byFloorId', e));
        } else if (this.state.rootInfoEqual === "floor") {
            dispatch(getAllSubSectionList('bySectionId', e));
        }
        this.props.form.resetFields('secondid');
    };




    filterClear = (e) => {
        //alert("clrea");
        this.setState({
            selected_id: this.state.id,
            search: '',
            floorList: [],
            sectionList: [],
            subSectionList: [],
            fromDate: "",
            toDate: "",
            counter: "",
            location: ""
        });
        const { dispatch } = this.props;
        dispatch(getSoldSKUByRootInfoAndId(this.state.id, this.state.rootInfoEqual, 0, 10, "", "", "", "", "", "", "", "", ""));
        this.props.form.resetFields();
    };


    btnFiltter = () => {


        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            console.log("btn Fillter", values);
            const { dispatch } = this.props;
            // let date=values.date;
            // if(date===undefined){
            //   date=""
            // }else
            // {
            //  date=moment(values.date).format("YYYY-MM-DD")
            // }

            let fromDate = "";
            let toDate = "";
            let d_cat = "";
            let counter = "";
            let location = "";
            let batchno = "";

            let root = this.state.rootInfo;
            let id = id = values.rootid;
            if (values.rootid === undefined) {
                root = "";
                id = "";
            }
            if (values.secondid != undefined) {
                id = values.secondid;
                root = this.state.rootLess;
            }

            if (values.date != undefined) {
                if (values.date.length > 0) {
                    // fromDate=values.date[0].toISOString();
                    // toDate=values.date[1].toISOString();
                    let fromDate1 = moment(values.date[0]).set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
                    let toDate1 = moment(values.date[1]).set({ 'hour': 23, 'minute': 59, 'second': 0, 'millisecond': 0 });
                    const dates = UTILS.fromDateToDate(fromDate1, toDate1);
                    // fromDate = fromDate1.toISOString();
                    // toDate = toDate1.toISOString();
                    // let todate = new Date(toDate);
                    // let fromdate = new Date(fromDate);
                    // let toOffset = new Date(toDate).getTimezoneOffset() / 60;
                    // let fromOffset = new Date(fromDate).getTimezoneOffset() / 60;
                    // console.log(fromOffset + " " + toOffset);
                    // fromdate.setHours(fromdate.getHours() + fromOffset);
                    // todate.setHours(todate.getHours() + toOffset);
                    // console.log(fromdate + " " + todate);
                    fromDate = dates.fromDate;
                    toDate = dates.toDate;
                }

            }
            if (values.d_cat != undefined) {
                d_cat = values.d_cat
            }

            if (values.location != undefined) {
                location = values.location
            }

            if (values.counter != undefined) {
                counter = values.counter
            }

            if (values.batchno != undefined) {
                batchno = values.batchno
            }

            console.log("d_cat");
            console.log(root, id);
            this.setState({
                table_loading: true,
                fromDate,
                toDate,
                d_cat,
                counter,
                location,
                batchno
            });

            if (values.rootid == this.state.id) {
                //d,rootInfo,page_no,limit,q,fromDate,toDate,orderkey,order,d_cat
                this.setState({
                    selectedRootInfoEqual: this.state.rootInfoEqual,
                    selected_id: values.rootid
                });
                dispatch(getSoldSKUByRootInfoAndId(values.rootid, this.state.rootInfoEqual, 0, 10, this.state.search, fromDate, toDate, "", "", d_cat, counter, location, batchno));
            } else {
                this.setState({
                    selectedRootInfoEqual: root,
                    selected_id: id
                });
                dispatch(getSoldSKUByRootInfoAndId(id, root, 0, 10, this.state.search, fromDate, toDate, "", "", d_cat, counter, location, batchno));
            }


        });


    };

    setLocalText = (e) => {
        const { dispatch } = this.props;
        this.setState({ display: true });
        dispatch(getSKUbyID(e, "sold"));
    };

    closeStockView = (e) => {
        this.setState({ display: false });
    };

    searchOnchange = (e) => {
        // console.log(e.target.value.length);
        this.setState({ search: e.target.value, btnsearch: false });

        if (e.target.value.length == 0) {
            this.setState({
                table_loading: true,
                btnsearch: true
            });
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                const { dispatch } = this.props;
                let fromDate = "";
                let toDate = "";
                let d_cat = "";
                let counter = "";
                let location = "";
                let batchno = "";

                if (values.date != undefined) {
                    if (values.date.length > 0) {
                        // fromDate=values.date[0].toISOString();
                        // toDate=values.date[1].toISOString();
                        let fromDate1 = moment(values.date[0]).utc().set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 });
                        let toDate1 = moment(values.date[1]).utc().set({ 'hour': 23, 'minute': 59, 'second': 0, 'millisecond': 0 });
                        fromDate = fromDate1.toISOString();
                        toDate = toDate1.toISOString();

                    }
                }
                if (values.d_cat != undefined) {
                    d_cat = values.d_cat
                }

                if (values.counter != undefined) {
                    counter = values.counter
                }

                if (values.location != undefined) {
                    location = values.location
                }

                if (values.batchno != undefined) {
                    batchno = values.batchno
                }

                if (values.rootid == this.state.id) {
                    dispatch(getSoldSKUByRootInfoAndId(values.rootid, this.state.rootInfoEqual, 0, 10, '', fromDate, toDate, "", "", "", d_cat, counter, location, batchno));
                } else {
                    dispatch(getSoldSKUByRootInfoAndId(values.rootid, this.state.rootInfo, 0, 10, '', fromDate, toDate, "", "", "", d_cat, counter, location, batchno));
                }

            });
        }

    };


    render() {
        const { selectedRowKeys, loading, search } = this.state;
        const { getFieldDecorator } = this.props.form;
        let data = this.state.data;
        const formItemLayout = {
            labelCol: { span: 24 },
            //   labalAlign:{marginLeft: "15px"},
            wrapperCol: { span: 24 },
        };
        this.columns = [
            {
                title: 'SKU',
                dataIndex: 'sku_number',
                width: '12%',
                sorter: (a, b) => { },
                render: (text, record) => {
                    return <a onClick={() => this.setLocalText(text)} to={`/StocksViewById/${text}`}>{text}</a>;
                }
            },
            {
                title: 'DSG CODE',
                dataIndex: 'design_code',
                width: '12%',
                sorter: (a, b) => { },
            },

            {
                title: 'MATERIAL',
                dataIndex: 'metal_type',
                width: '12%',
                sorter: (a, b) => { },
            },


            {
                title: 'DESIGN CTG',
                dataIndex: 'design_category',
                width: '12%',
                sorter: (a, b) => { },
            },
            {
                title: 'DIA CT',
                dataIndex: 'diamond_weight',
                width: '11%',
                sorter: (a, b) => { },
            },
            {
                title: 'NET WGT',
                dataIndex: 'net_weight',
                width: '11%',
                sorter: (a, b) => { },
                render: (value) => {
                    return parseFloat(value).toFixed(2);
                },
            },
            {
                title: 'PRICE',
                dataIndex: 'sales_value',
                width: '11%',
                sorter: (a, b) => { },
                render: (text, record) => {
                    return <span>{text}</span>;
                }
            },
            {
                title: 'DATE',
                dataIndex: 'createdAt',
                sorter: (a, b) => { },
                width: '11%',
                render: (text, record) => {
                    return <span>{moment(text).format("DD-MM-YYYY")}</span>;
                }
            },
            {
                title: "ACTION",
                dataIndex: "_id",
                width: "10%",
                render: (text, record) => (
                    <>
                        <Icon type="delete"
                            //style={{ marginLeft: 8, marginRight: 8, cursor: "pointer" }}
                            //onClick={() => this.editSku(record)}
                        />
                    </>
                ),
            }

        ];
        return (
            <div >


                <div style={{ display: this.state.display ? "none" : "" }}>

                    <Card className="custome-top-menu-card" bordered={false}>
                        <Row>
                            <Col lg={5} md={5} sm={24} xs={24} style={{
                                textAlign: "center"
                            }}>
                                {/** <h1 className="stock-title">Total Inventory</h1>*/}
                                <p className="transaction-title">Total</p>
                                <h1 className="transaction-sub-title">Sold Inventory</h1>
                            </Col>


                            <Col lg={4} md={4} sm={24} xs={24} style={{ textAlign: "center" }}>
                                <Divider type="vertical" className="vertical-divider-stock" />
                                <h1 className="total-text" style={{ fontSize: "40px" }}><CountUp start={0} end={this.state.total_stock} /><span style={{ fontSize: "19px", color: "#7e7d7d" }}>Items</span></h1>

                            </Col>


                            <Col lg={1} md={1} sm={24} xs={24} style={{ textAlign: "center" }}>
                                <Divider type="vertical" className="vertical-divider-customer1" />
                            </Col>
                            <Col lg={9} md={9} sm={24} xs={24}>


                                <p style={{ marginBottom: "5px" }} className="transaction-serach-text text-color-8c8c8c">Search</p>
                                <Input
                                    value={this.state.search}
                                    placeholder="-- Search SKU--"
                                    style={{ float: "left", width: "60%" }}
                                    // onChange={e => this.setState({ search: e.target.value })}
                                    onChange={this.searchOnchange}
                                />
                                <Button onClick={this.onSerach} style={{ marginLeft: "11px" }} type="primary" disabled={this.state.btnsearch}>
                                    Search
                                </Button>
                            </Col>

                            <Col lg={1} md={1} sm={24} xs={24} style={{ textAlign: "center" }} className="cursor-pointer">
                                <Divider type="vertical" style={{
                                    display: "inline-block", width: '3px', height: '85%', marginTop: "24%", marginBottom: '24%',
                                    verticalAlign: 'middle',
                                    backgroundColor: '#e3e3e3',
                                    float: 'left'
                                }} />


                            </Col>

                            <Col lg={4} md={4} sm={24} xs={24} style={{ textAlign: "center" }} className="cursor-pointer">
                                <img className="export-icon" onClick={this.showModal} src={ExportIcon} />
                                <h5 className="text-color-8c8c8c">Export Sold Inventory</h5>
                            </Col>
                        </Row>

                    </Card>

                    <Form hideRequiredMark={true} colon={false}>
                        <Card className="filtter-card" bordered={false} bodyStyle={{ padding: "10px", paddingBottom: "0px" }}>
                            <Row>

                                <Col lg={2} md={2} sm={24} xs={24} className="text-aligin-center">
                                    <img src={FilterIcon} className="filter-icon" />
                                </Col>

                                <Col lg={4} md={4} sm={24} xs={24} style={{ paddingLeft: "0px" }}>
                                    <Form.Item label={`Date`}  {...formItemLayout}>
                                        {getFieldDecorator(`date`, {
                                            rules: [
                                                {
                                                    required: false,
                                                },
                                            ],
                                        })(
                                            <RangePicker
                                                format="DD-MM-YYYY"
                                                placeholder={['From ', 'To']}
                                                separator="-"
                                                style={{ minWidth: "100%" }}
                                                allowClear={false}
                                            />
                                        )}
                                    </Form.Item>


                                </Col>
                                <Col lg={0} md={0} sm={0} xs={0}>
                                    {this.state.rootInfo === "branch" && this.state.branch_id != '' ?

                                        <Form.Item label={this.state.textForSelect} {...formItemLayout}>
                                            {getFieldDecorator(`rootid`, {
                                                initialValue: this.state.id,
                                                rules: [
                                                    {
                                                        required: false,
                                                    },
                                                ],
                                            })(<Select placeholder="Please select branch" onChange={this.onChangeBranchRadio} style={{ minWidth: "100%" }} >
                                                <Option key={this.state.id} value={this.state.id}>All Branches</Option>
                                                {this.state.branch_list.map((data, index) =>
                                                    <Option key={data._id} value={data._id}>{data.name}</Option>
                                                )}
                                            </Select>
                                            )}
                                        </Form.Item>


                                        : ""}

                                    {this.state.rootInfo === "floor" && this.state.floor_id != '' ?



                                        <Form.Item label={this.state.textForSelect} {...formItemLayout}>
                                            {getFieldDecorator(`rootid`, {
                                                initialValue: this.state.id,
                                                rules: [
                                                    {
                                                        required: false,
                                                    },
                                                ],
                                            })(
                                                <Select placeholder="Please select floor" onChange={this.onChangeBranchRadio} style={{ minWidth: "100%" }}   >
                                                    <Option key={this.state.id} value={this.state.id}>All Floors</Option>
                                                    {this.state.floor_list.map((data, index) =>
                                                        <Option key={data._id} value={data._id}>{data.name}</Option>
                                                    )}
                                                </Select>




                                            )}
                                        </Form.Item>


                                        : ""}

                                    {this.state.rootInfo === "section" && this.state.section_id != '' ?

                                        <Form.Item label={this.state.textForSelect}  {...formItemLayout} >
                                            {getFieldDecorator(`rootid`, {
                                                initialValue: this.state.id,
                                                rules: [
                                                    {
                                                        required: false,
                                                    },
                                                ],
                                            })(
                                                <Select placeholder="Please select section" onChange={this.onChangeBranchRadio} style={{ minWidth: "100%" }} >
                                                    <Option key={this.state.id} value={this.state.id}>All Sections</Option>
                                                    {this.state.section_list.map((data, index) =>
                                                        <Option key={data._id} value={data._id}>{data.name}</Option>
                                                    )}
                                                </Select>

                                            )}
                                        </Form.Item>



                                        : ""}

                                </Col>

                                <Col lg={0} md={0} sm={0} xs={0}>
                                    {this.state.rootInfo === "branch" ?
                                        <Form.Item label={this.state.textForSelect1} {...formItemLayout}>
                                            {getFieldDecorator(`secondid`, {
                                                // initialValue: this.state.id,
                                                rules: [
                                                    {
                                                        required: false,
                                                    },
                                                ],
                                            })(
                                                <Select placeholder="Please select floor" style={{ minWidth: "100%" }}   >
                                                    {this.state.floorList.map((data, index) =>
                                                        <Select.Option key={data._id} value={data._id}>{data.name}</Select.Option>
                                                    )}
                                                </Select>
                                            )}
                                        </Form.Item>
                                        : ""}

                                    {this.state.rootInfo === "floor" ?
                                        <Form.Item label={this.state.textForSelect1}  {...formItemLayout} >
                                            {getFieldDecorator(`secondid`, {
                                                //initialValue: this.state.id,
                                                rules: [
                                                    {
                                                        required: false,
                                                    },
                                                ],
                                            })(
                                                <Select placeholder="Please select section" style={{ minWidth: "100%" }} >

                                                    {this.state.sectionList.map((data, index) =>
                                                        <Select.Option key={data._id} value={data._id}>{data.name}</Select.Option>
                                                    )}
                                                </Select>

                                            )}
                                        </Form.Item>
                                        : ""}


                                    {this.state.rootInfo === "section" ?
                                        <Form.Item label={this.state.textForSelect1}  {...formItemLayout} >
                                            {getFieldDecorator(`secondid`, {
                                                // initialValue: this.state.id,
                                                rules: [
                                                    {
                                                        required: false,
                                                    },
                                                ],
                                            })(
                                                <Select placeholder="Please select sub section" style={{ minWidth: "100%" }} >
                                                    {this.state.subSectionList.map((data, index) =>
                                                        <Select.Option key={data._id} value={data._id}>{data.name}</Select.Option>
                                                    )}
                                                </Select>
                                            )}
                                        </Form.Item>
                                        : ""}


                                </Col>


                                <Col lg={3} md={4} sm={24} xs={24} >
                                    <Form.Item label="Design CTG"  {...formItemLayout} >
                                        {getFieldDecorator(`d_cat`, {

                                            rules: [
                                                {
                                                    required: false,
                                                },
                                            ],
                                        })(<Select
                                            //mode="tags"
                                            placeholder="- - Select - -" style={{ minWidth: "100%" }} >

                                            {this.state.ctgArray.map(user => (
                                                <Option key={user} value={user}>{user}</Option>
                                            ))}

                                        </Select>)}
                                    </Form.Item>

                                </Col>

                                <Col lg={3} md={4} sm={24} xs={24}>
                                    <Form.Item label="Counter"  {...formItemLayout} >
                                        {getFieldDecorator(`counter`, {

                                            rules: [
                                                {
                                                    required: false,
                                                },
                                            ],
                                        })(<Select
                                            //mode="tags"
                                            placeholder="- - Select - -" style={{ minWidth: "100%" }}>

                                            {this.state.counterArray.map(user => (
                                                <Option key={user} value={user}>{user}</Option>
                                            ))}

                                        </Select>)}
                                    </Form.Item>

                                </Col>


                                <Col lg={3} md={4} sm={24} xs={24}>
                                    <Form.Item label="Location"  {...formItemLayout} >
                                        {getFieldDecorator(`location`, {

                                            rules: [
                                                {
                                                    required: false,
                                                },
                                            ],
                                        })(<Select
                                            //mode="tags"
                                            placeholder="- - Select - -" style={{ minWidth: "100%" }}>

                                            {this.state.locationArray.map(user => (
                                                <Option key={user} value={user}>{user}</Option>
                                            ))}

                                        </Select>)}
                                    </Form.Item>

                                </Col>


                                <Col lg={3} md={4} sm={24} xs={24}>
                                    <Form.Item label="Batch No"  {...formItemLayout} >
                                        {getFieldDecorator(`batchno`, {

                                            rules: [
                                                {
                                                    required: false,
                                                },
                                            ],
                                        })(<Select
                                            //mode="tags"
                                            placeholder="- - Select - -" style={{ minWidth: "100%" }}>

                                            {this.state.batchArray.map(user => (
                                                <Option key={user} value={user}>{user}</Option>
                                            ))}

                                        </Select>)}
                                    </Form.Item>

                                </Col>


                                <Col lg={5} md={5} sm={24} xs={24}>

                                    <div style={{ marginTop: "22px", paddingRight: "9px", float: "right" }}>
                                        <Button style={{ float: "left" }} onClick={this.btnFiltter} type="primary">
                                            Filter
                                        </Button>
                                        <Button onClick={this.filterClear} className="transaction-clear-btn" ghost>
                                            Clear
                                        </Button>
                                    </div>
                                </Col>

                            </Row>

                        </Card>
                    </Form>

                    <Card bodyStyle={{ padding: "10px 10px" }} bordered={false}>
                        <Spin spinning={this.state.Spinloading} >
                            <Table loading={this.state.table_loading}
                                className="gx-table-responsive"
                                //rowSelection={rowSelection} 
                                columns={this.columns} dataSource={data}
                                size={"small"}
                                rowKey={record => record.sku_number}
                                pagination={this.state.pagination}
                                onChange={this.handleTableChange}
                                scroll={{ y: 400 }}
                            />
                        </Spin>
                    </Card>
                </div>


                <Modal
                    title={loading ? `Data is processing please wait ...` : `Inventory File Created`}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel} ghost>Cancel</Button>,
                        <Button type="primary" onClick={this.downLoad}>Download</Button>,
                    ]}
                >
                    {loading ?
                        <div style={{ minHeight: "150px", backgroundImage: `url(${Gears})` }}>
                        </div>
                        :
                        <Alert
                            message="Success"
                            description="Sold Stock file is ready you can download."
                            type="success"
                            showIcon
                        />
                    }
                </Modal>

                <div style={{ display: this.state.display ? "" : "none" }}>
                    <StockViewPage backttoStock={this.closeStockView} display={this.state.display} />
                </div>
            </div>
        )
    }
}

Soldstock = Form.create()(Soldstock);
function mapStateToProps(state) {
    const { Common } = state;
    const { get_sold_stock_xl: get_sold_stock_xl, sub_section_list: sub_section_list, get_sold_stock: get_sold_stock, get_stock_export: get_stock_export, branch_list: branch_list, floor_list: floor_list, section_list: section_list } = Common;
    return { get_sold_stock_xl, sub_section_list, get_sold_stock, get_stock_export, branch_list, floor_list, section_list }
}

const AppContainer = connect(
    mapStateToProps
)(Soldstock);

export default AppContainer;


