import React, { Component } from 'react'
import IntlMessages from "util/IntlMessages";
import {connect} from "react-redux";
import moment from 'moment';
import {Card, Table,Form,Row,Col,DatePicker,Select,InputNumber,Button,AutoComplete,Input,Icon,Upload} from "antd";
const OptGroup = AutoComplete.OptGroup;
const Option = Select.Option;
const FormItem = Form.Item;
const CustomerList=[];

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
const { TextArea } = Input;


class support extends Component {
  constructor(props) {
    super(props);
   
   
  }
  componentDidMount() {
  //  const { dispatch } = this.props;  
    
          this.setState({
            checkNick:false
          })
        
   // dispatch(getCustomer());
 //   dispatch(getQuotation());
  }

  handleSearch = (e) => {
     
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {dispatch} = this.props;
       // let userdata=values;
        
      }
    });
  }
  
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
      <h2 className="title gx-mb-4"><IntlMessages id="sidebar.support"/></h2>
     
        <Card>
        <div>
                <Form.Item {...formItemLayout} label="Subject">
                  {getFieldDecorator('username', {
                    rules: [{
                      required: true,
                      message: 'Please input subject',
                    }],
                  })(
                    <Input 
                    //placeholder="Please input subject"
                     />
                  )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Description">
                  {getFieldDecorator('nickname', {
                    rules: [{
                      required: true,
                      message: 'Please input description',
                    }],
                  })(
                    <TextArea rows={4} />
                  )}
                </Form.Item>
               
                <Form.Item {...formItemLayout} label="Attachments">
                {getFieldDecorator('attachments', {
                  rules: [{
                   // required: true,
                    message: 'Please  Attachments',
                  }],
                })(
                  <Upload name="logo" action="/upload.do" listType="picture">
                    <Button>
                      <Icon type="upload" /> Click to upload
                    </Button>
                  </Upload>
                )}
              </Form.Item>

                <Form.Item {...formTailLayout}>
                  <Button type="primary" onClick={this.check}>
                    Submit
                  </Button>
                </Form.Item>
              </div>
        </Card>
    
      </div>
    )
  }
}

support = Form.create()(support);
function mapStateToProps(state) {
  const {Common } = state
  const {quotation_details:quotation_details  } =  Common
  return {quotation_details}
}

const AppContainer = connect( 
  mapStateToProps 
)(support);


export default AppContainer;


