import React, {Component} from 'react';
import {Button, Divider, Form, Input, message, Modal} from "antd";
import {connect} from 'react-redux';
import {deleteAllStockWithRootInfo, getOtpForStockDelete, verifyOtp} from '../../../actions/index';

message.config({
    duration: 1,
    maxCount: 3,
});

class passwordResetFrom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visibleStockDelete: false,
            visibleOtpForm: false,
        };
    }


    componentDidUpdate(prevProps, prevState) {

        if (prevProps.delete_all_stock_root_info != this.props.delete_all_stock_root_info) {
            try {
                if (this.props.delete_all_stock_root_info.status) {
                    this.setState({
                        Spinloading: false,
                        selectedRowKeys: [],
                        isDeleteAllRequested : false,
                        visibleStockDelete: false,
                        visibleOtpForm: false,
                    });
                } else {
                    message.error(this.props.delete_all_stock_root_info.errors.msg);
                }
            } catch (error) {
                console.log(error);
            }
        }

        if (prevProps.otp_stock_delete != this.props.otp_stock_delete && this.state.isDeleteAllRequested) {
            this.setState({
                Spinloading: false,
            })
            if (this.props.otp_stock_delete.status) {
                message.success(this.props.otp_stock_delete.data.msg);
                // show message and show otp dialog
                // localStorage.setItem("otp_from_reset_stock", true);
                this.setState({
                    visibleOtpForm: true,
                })
                // console.log("--- Make otp form visible");
            } else {
                message.error(this.props.otp_stock_delete.errors.msg);
            }
        }
        if (prevProps.verify_otp_stock_delete != this.props.verify_otp_stock_delete) {
            // console.log("----- verify all otp response")
            // console.log(this.props.verify_otp_stock_delete);
            this.setState({
                Spinloading: false,
            })
            if (this.props.verify_otp_stock_delete.status) {
                // console.log("Verify otp for reset stock status true");
                deleteAllStockWithRootInfo(this.props.selected_id, this.props.rootInfo)
                this.setState({
                    visibleOtpForm: false,
                })
                // localStorage.setItem("otp_from_reset_stock", false);
            } else {
                // console.log("Verify otp for reset stock status false");
                message.error(this.props.verify_otp_stock_delete.errors.msg);
                // localStorage.setItem("otp_from_reset_stock", false);
            }

        }
    }

    showModalStockDelete = (e) => {
        this.setState({
            visibleStockDelete: true,
            visibleOtpForm: false,
        })
        this.props.onDeleteAllRequested(true);
        this.props.form.resetFields();
    }


    handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields(['password'], (err, values) => {
            if (err) {
                return;
            }
            if (values.password === "spacecode") {
                this.setState({
                    Spinloading: true,
                    isDeleteAllRequested : true,
                });
                this.props.onDeleteAllRequested(true);
                // console.log("----------- Requesting Otp ---------")
                const {dispatch} = this.props;
                dispatch(getOtpForStockDelete());
                this.setState(({
                    visibleStockDelete: false,
                }));

                // dispatch(deleteAllStockWithRootInfo(this.props.selected_id, this.props.rootInfo));
            } else {
                message.error("Password is wrong");
            }
            // let root =  JSON.parse(cookies.get('root'));
            // const {dispatch} = this.props;
            // values.root=root;
            // console.log(values);
            // dispatch(createCustomer(values));
        });
    }


    verifyOtp = (e) => {
        e.preventDefault();
        this.props.form.validateFields(['otp'], (err, values) => {
            if (err) {
                return;
            }

            this.setState({
                Spinloading: true,
            });
            const {dispatch} = this.props;
            dispatch(verifyOtp(values));
            // deleteAllStockWithRootInfo(this.props.selected_id, this.props.rootInfo)
        });
    }

    hideModalStockDelete = (e) => {
        this.setState({
            visibleStockDelete: false,
            visibleOtpForm: false,
        })
        this.props.form.resetFields();
    }

    hideModalOtpForm = (e) => {
        this.setState({
            visibleStockDelete: false,
            visibleOtpForm: false,
        })
        this.props.form.resetFields();
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <React.Fragment>
                <Modal
                    title="Are you sure delete this all Inventory ?"
                    visible={this.state.visibleStockDelete}
                    bodyStyle={{paddingBottom: "48px"}}
                    //  onOk={this.handleOk}
                    onCancel={this.hideModalStockDelete}
                    // footer={[
                    //   <Button key="back" onClick={this.hideModalStockDelete}>Cancel</Button>,
                    //   <Button key="submit" type="primary"  onClick={this.handleOk}>
                    //      Delete
                    //   </Button>,
                    // ]}
                    footer={null}
                >
                    <Form layout="vertical" onSubmit={this.handleOk}>
                        <Form.Item label="Password" className="customFormItem">
                            {getFieldDecorator('password', {
                                rules: [{required: true, message: 'Please input password !'}],
                            })(
                                <Input.Password autoFocus={true}/>
                            )}
                        </Form.Item>
                        <Divider/>
                        <div style={{float: "right"}}>
                            <Button key="back" onClick={this.hideModalStockDelete}>Cancel</Button>
                            <Button key="submit" type="primary" onClick={this.handleOk}>
                                Delete
                            </Button>
                        </div>
                    </Form>
                </Modal>

                <Modal
                    title="Verify Otp"
                    className="password-reset-form-otp"
                    visible={this.state.visibleOtpForm}
                    bodyStyle={{paddingBottom: "48px"}}
                    onCancel={this.hideModalOtpForm}
                    footer={null}
                >
                    <Form layout="vertical" onSubmit={this.verifyOtp}>
                        <Form.Item label="otp" className="customFormItem1">
                            {getFieldDecorator('otp', {
                                rules: [{required: true, message: 'Please input otp !'}],
                            })(
                                <Input autoFocus={true}/>
                            )}
                        </Form.Item>
                        <Divider/>
                        <div style={{float: "right"}}>
                            <Button key="back" onClick={this.hideModalOtpForm}>Cancel</Button>
                            <Button key="submit" type="primary" onClick={this.verifyOtp}>
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <Button style={{marginBottom: "5px"}} onClick={this.showModalStockDelete} ghost>Delete ALL</Button>
            </React.Fragment>
        )
    }
}

passwordResetFrom = Form.create()(passwordResetFrom);

function mapStateToProps(state) {
    const {Common} = state
    const {
        delete_all_stock_root_info: delete_all_stock_root_info,
        otp_stock_delete: otp_stock_delete,
        verify_otp_stock_delete: verify_otp_stock_delete,
    } = Common
    return {delete_all_stock_root_info, otp_stock_delete, verify_otp_stock_delete}
}

const AppContainer = connect(
    mapStateToProps
)(passwordResetFrom);

export default AppContainer;
