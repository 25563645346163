import { connect } from "react-redux";
import React, { Component } from "react";
import {
    Modal,
    Form,
    Table,
    List,
    Col,
    Icon,
    DatePicker,
    Card,
    Button,
    Radio,
    message,
    Row,
    Switch,
    Select,
    AutoComplete,
    Divider,
    Input,
} from "antd";
import {
    getAllBrachList,
    getAllFloorList,
    getAllSectionList,
    getAllSubSectionList,
    getAllUserList,
    getSKUbyID,
    getSKUByRootInfoAndId,
} from "../../../actions/index";
import socketIOClient from "socket.io-client";
import moment from "moment";

var cookies = require("browser-cookies");
message.config({
    top: 100,
    duration: 10,
    maxCount: 3,
});
const { TextArea } = Input;

class AddIssue extends Component {
    constructor(props) {
        super(props);
        let root = JSON.parse(cookies.get("root"));
        this.state = {
            data: [],
            loading: false,
            visible: false,
            username: root.companyId,
            isCountryIndia: false,
            customerType: true,
            serach: "",
            pageSizelimit: 10,
            currantPage: 0,
            btnsearch: true,
            isPrivateCustomer: false,
        };
    }


    componentDidMount() {
        const { dispatch } = this.props;
        let usertype = localStorage.getItem("type_user");
        let root = JSON.parse(cookies.get("root"));

        if (usertype === "branchAdmin") {
            this.setState({
                rootInfo: "floor",
                rootInfoEqual: "branch",
                textForSelect: "Select Floor",
                textForSelect1: "Select Section",
                id: root.branchId,
                selected_id: root.branchId,
                selectedRootInfoEqual: "branch",
                rootLess: "section",
            });
            dispatch(getAllFloorList("bybranchId", root.branchId));
            dispatch(getAllUserList("branch", root.branchId));
        }
        if (usertype === "companyAdmin") {
            this.setState({
                rootInfo: "branch",
                rootInfoEqual: "company",
                textForSelect: "Select Branch",
                textForSelect1: "Select Floor",
                id: root.companyId,
                selected_id: root.companyId,
                rootLess: "floor",
                selectedRootInfoEqual: "company",
            });
            dispatch(getAllBrachList("id"));
            dispatch(getAllUserList("company", root.companyId));
        }
        if (usertype === "floorAdmin") {
            this.setState({
                rootInfo: "section",
                rootInfoEqual: "floor",
                rootLess: "subSection",
                textForSelect: "Select Section",
                textForSelect1: "Select SubSection",
                id: root.floorId,
                selected_id: root.floorId,
                selectedRootInfoEqual: "floor",
            });
            dispatch(getAllUserList("floor", root.floorId));
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.branch_list !== this.props.branch_list) {
            if (this.props.branch_list.status) {
                if (this.state.selection_const) {
                    this.setState({
                        branch_id: this.props.branch_list.data_array[0]._id,
                        branch_list: this.props.branch_list.data_array,

                        //selected_id:this.props.branch_list.data_array[0]._id,
                    });
                    if (this.props.branch_list.data_array.length >= 1) {
                        const { dispatch } = this.props;
                        //dispatch(getSKUByRootInfoAndId(this.props.branch_list.data_array[0]._id,this.state.rootInfo,0,10));
                        //  dispatch(getSKUByRootInfoAndId(this.state.id, this.state.rootInfoEqual,0,10));
                        // console.log('dispatch(getSKUByRootInfoAndId...............................');
                        dispatch(
                            getSKUByRootInfoAndId(
                                this.state.id,
                                this.state.rootInfoEqual,
                                0,
                                10,
                                "",
                                "",
                                "",
                                "",
                                "",
                                ""
                            )
                        );
                    }
                }
            } else {
                this.setState({
                    branch_list: [],
                });
            }
        }

        if (prevProps.floor_list !== this.props.floor_list) {
            // console.log('prevProps.floor_list', prevProps.floor_list, 'this.props.floor_list', this.props.floor_list);
            if (this.props.floor_list.status) {
                if (this.state.selection_const) {
                    this.setState({
                        floor_id: this.props.floor_list.data_array[0]._id,
                        floor_list: this.props.floor_list.data_array,
                        //selected_id:this.props.floor_list.data_array[0]._id,
                    });
                    console.log("surat:", this.props.floor_list.data_array);
                    if (this.props.floor_list.data_array.length >= 1) {
                        const { dispatch } = this.props;
                        // dispatch(getSKUByRootInfoAndId(this.props.floor_list.data_array[0]._id,this.state.rootInfo,0,10));
                        //dispatch(getSKUByRootInfoAndId(this.state.id, this.state.rootInfoEqual,0,10));
                        // console.log('dispatch(getSKUByRootInfoAndId...............................');
                        dispatch(
                            getSKUByRootInfoAndId(
                                this.state.id,
                                this.state.rootInfoEqual,
                                0,
                                10,
                                "",
                                "",
                                "",
                                "",
                                "",
                                ""
                            )
                        );
                    }
                }
                this.setState({
                    floorList: this.props.floor_list.data_array,
                    floor_list: this.props.floor_list.data_array,
                });
                console.log("floor:", this.props.floor_list.data_array);
            } else {
                this.setState({
                    floor_list: [],
                    floorList: [],
                    ctgArray: "",
                    onlyCtgArray: [],
                });
            }
        }

        if (prevProps.section_list != this.props.section_list) {
            if (this.props.section_list.status) {
                this.setState({
                    sectionList: this.props.section_list.data_array,
                    section_list: this.props.section_list.data_array,
                });
                console.log("sec:", this.props.section_list.data_array);
            } else {
                this.setState({
                    sectionList: [],
                    section_list: [],
                });
            }
        }

        if (prevProps.sub_section_list != this.props.sub_section_list) {
            if (this.props.sub_section_list.status) {
                this.setState({
                    subSectionList: this.props.sub_section_list.data_array,
                    sub_section_list: this.props.sub_section_list.data_array,
                });
                console.log("sub:", this.props.sub_section_list.data_array);
            } else {
                this.setState({
                    subSectionList: [],
                    sub_section_list: [],
                });
            }
        }


        if (prevProps.create_customer != this.props.create_customer) {
            try {
                if (this.props.create_customer.status) {
                    message.success("Add customer sucessfully");
                    this.setState({
                        visible: false,
                    });
                    this.props.form.resetFields();
                    this.props.createIssueClose();
                    // const { dispatch } = this.props;
                    //  dispatch(getCustomer(0,10));
                } else {
                    message.error(this.props.create_customer.errors.msg);
                }
            } catch (error) {
                console.log(error);
            }
        }


        if (prevProps.get_stock != this.props.get_stock) {
            if (this.props.get_stock.data_array.length > 0) {
                if (this.props.get_stock.status) {
                    let stockData = this.props.get_stock.data;
                    let stock = this.props.get_stock.data_array;
                    let totalSkuCount = this.props.get_stock.data.totalSku;
                    let totalPcs = this.props.get_stock.data.totalPcs;
                    let totalGrossWeight = this.props.get_stock.data.totalGrossWeight;
                    let selectedRowKeys = [];
                    let pagination = {};
                    pagination.total = totalSkuCount;
                    pagination.showQuickJumper = true;
                    pagination.showSizeChanger = true;
                    pagination.pageSize = this.state.pageSizelimit;
                    pagination.pageSizeOptions = [
                        "25",
                        "50",
                        "75",
                        "100",
                        "500",
                        "1000",
                        "2000",
                    ];
                    this.setState({
                        ctgArray: this.props.get_stock.data.d_cat,
                        onlyCtgArray: this.props.get_stock.data.counters || [],
                        data: stock,
                        dataObj: stockData,
                        selectAll: selectedRowKeys,
                        total_stock: totalSkuCount,
                        totalPcs: totalPcs,
                        totalGrossWeight: totalGrossWeight,
                        pagination,
                    });
                } else {
                    this.setState({
                        data: [],
                        dataObj: {},
                        total_stock: 0,
                        totalPcs: 0,
                        ctgArray: [],
                        onlyCtgArray: [],
                    });
                }
            } else {
                this.setState({
                    data: [],
                    dataObj: {},
                    total_stock: 0,
                    totalPcs: 0
                });
            }
            this.setState({
                table_loading: false,
            });
        }

    }


    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    onSerach = (e) => {
        this.setState({
            data: [],
            dataObj: {},
        });
        try {
            if (this.state.search.length > 1) {
                this.props.form.validateFields(
                    ["rootid", "secondid", "date", "d_cat", "counter"],
                    (err, values) => {
                        if (err) {
                            return;
                        }

                        const { dispatch } = this.props;
                        let fromDate = "";
                        let toDate = "";
                        let d_cat = "";
                        let counter = "";
                        let root = this.state.rootInfo;
                        let id = (id = values.rootid);
                        if (values.rootid === undefined) {
                            root = "";
                            id = "";
                        }
                        if (values.secondid != undefined) {
                            id = values.secondid;
                            root = this.state.rootLess;
                        }

                        if (values.date != undefined) {
                            if (values.date.length > 0) {
                                let fromDate1 = moment(values.date[0])
                                    .utc()
                                    .set({
                                        hour: 0,
                                        minute: 0,
                                        second: 0,
                                        millisecond: 0,
                                    });
                                let toDate1 = moment(values.date[1])
                                    .utc()
                                    .set({
                                        hour: 23,
                                        minute: 59,
                                        second: 0,
                                        millisecond: 0,
                                    });
                                fromDate = fromDate1.toISOString();
                                toDate = toDate1.toISOString();
                            }
                        }
                        if (values.d_cat != undefined) {
                            d_cat = values.d_cat;
                        }
                        if (values.counter != undefined) {
                            counter = values.counter;
                        }

                        this.setState({
                            table_loading: true,
                        });

                        dispatch(
                            getSKUByRootInfoAndId(
                                this.state.selected_id,
                                this.state.selectedRootInfoEqual,
                                0,
                                this.state.pageSizelimit,
                                this.state.search,
                                fromDate,
                                toDate,
                                "",
                                "",
                                ""
                            )
                        );
                        
                    }
                );
               
            }
        } catch (error) { }
    };


    searchOnchange = (e) => {
        this.setState({ search: e.target.value, btnsearch: false });

        if (e.target.value.length == 0) {
            this.setState({
                table_loading: true,
                btnsearch: true,
            });
            this.props.form.validateFields(
                ["rootid", "secondid", "date", "d_cat", "counter"],
                (err, values) => {
                    if (err) {
                        return;
                    }
                    const { dispatch } = this.props;
                    let fromDate = "";
                    let toDate = "";
                    let d_cat = "";
                    let counter = "";
                    if (values.date != undefined) {
                        if (values.date.length > 0) {
                            let fromDate1 = moment(values.date[0])
                                .utc()
                                .set({
                                    hour: 0,
                                    minute: 0,
                                    second: 0,
                                    millisecond: 0,
                                });
                            let toDate1 = moment(values.date[1])
                                .utc()
                                .set({
                                    hour: 23,
                                    minute: 59,
                                    second: 0,
                                    millisecond: 0,
                                });
                            fromDate = fromDate1.toISOString();
                            toDate = toDate1.toISOString();
                        }
                    }
                    if (values.d_cat != undefined) {
                        d_cat = values.d_cat;
                    }
                    if (values.counter != undefined) {
                        counter = values.counter;
                    }

                    if (values.rootid == this.state.id) {
                        dispatch(
                            getSKUByRootInfoAndId(
                                values.rootid,
                                this.state.rootInfoEqual,
                                this.state.currantPage,
                                this.state.pageSizelimit,
                                "",
                                fromDate,
                                toDate,
                                "",
                                "",
                                "",
                                d_cat,
                                counter
                            )
                        );
                    } else {
                        dispatch(
                            getSKUByRootInfoAndId(
                                values.rootid,
                                this.state.rootInfo,
                                this.state.currantPage,
                                this.state.pageSizelimit,
                                "",
                                fromDate,
                                toDate,
                                "",
                                "",
                                "",
                                d_cat,
                                counter
                            )
                        );
                    }
                }
            );
        }
    };

    handleCancel = () => {
        this.setState({ visible: false });
        this.props.form.resetFields();
        this.props.createIssueClose();
    };

    handleOk = (e) => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            let root = JSON.parse(cookies.get("root"));
            const { dispatch } = this.props;
            values.root = root;
            // console.log(values);
            values.isPrivateCustomer = this.state.isPrivateCustomer;
            //dispatch(createCustomer(values));
        });
    };

    handleOnChangeCountry = (country) => {
        // console.log(country);
        if (country === "India") {
            this.setState({
                isCountryIndia: true,
            });
        } else {
            this.setState({
                isCountryIndia: false,
            });
        }
    };

    onChangeCustomerType = (e) => {
        if (e.target.value === "Corporate") {
            this.setState({
                customerType: true,
                isPrivateCustomer: false,
            });
        } else {
            this.setState({
                customerType: false,
                isPrivateCustomer: true,
            });
        }
    };

    render() {
        const { selectedRowKeys, loading, search, visible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout1 = {
            labelCol: { span: 24 },
            //   labalAlign:{marginLeft: "15px"},
            wrapperCol: { span: 24 },
        };

        this.columns = [
            {
                title: "SKU",
                dataIndex: "sku_number",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: "DESIGN CODE",
                dataIndex: "design_code",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: "NET WEIGHT",
                dataIndex: "net_weight",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return (
                        <span>
                            {text != null
                                ? !Number.isInteger(text)
                                    ? text.toFixed(3)
                                    : text
                                : ""}
                        </span>
                    );
                },
            },
            {
                title: "GROSS WEIGHT",
                dataIndex: "gross_weight",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return (
                        <span>
                            {text != null
                                ? !Number.isInteger(text)
                                    ? text.toFixed(3)
                                    : text
                                : ""}
                        </span>
                    );
                },
            },
            {
                title: "SKU QUANTITY",
                dataIndex: "sku_quantity",
                sorter: (a, b) => { },
                render: (text, record) => {
                    return <span>{text}</span>;
                },
            },
        ];
        let data = this.state.data;
        const formItemLayout = {
            //labelCol: { span: 24 },
            //   labalAlign:{marginLeft: "15px"},
            //wrapperCol: { span: 24 },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutHorizan = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };
        return (
            <React.Fragment>
                <Card
                    bodyStyle={{ padding: "0" }}
                    style={{ borderRadius: "4px" }}
                    bordered={false}>
                    <Card
                        style={{
                            borderLeft: "9px solid",
                            marginBottom: "5px",
                            color: "#4285F4",
                            borderRadius: "4px",
                        }}
                        bodyStyle={{ padding: "0" }}
                        bordered={false}>
                        <Row>
                            <Col
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                style={{ paddingLeft: "40px" }}>
                                <h6
                                    className='customer-report-title-back icon-charvlet-back'
                                    onClick={() => this.props.createIssueClose()}>
                                    <Icon type='left' className='text-color-4285F4' />Back to Issue
                                </h6>
                                <h1 className='customer-report-title'>Add Issue</h1>
                            </Col>
                        </Row>
                    </Card>

                    <Row>
                    <Col lg={7} md={7} sm={24} xs={24}   style={{ paddingLeft: "40px" }}>
                        <p
                            style={{ marginBottom: "5px" }}
                            className='transaction-serach-text text-color-8c8c8c'>
                            Search Sku
                        </p>
                        <Input
                            placeholder='-- Search Sku--'
                            style={{ float: "left", width: "70%" }}
                            onChange={this.searchOnchange}
                        />
                        <Button
                            onClick={this.onSerach}
                            style={{ marginLeft: "11px" }}
                            type='primary'
                            disabled={this.state.btnsearch}>
                            Search
                        </Button>
                    </Col>
                    </Row>

                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <Card bodyStyle={{ padding: "10px 0px" }} bordered={false}>
                                <Table
                                    className='gx-table-responsive'
                                    loading={this.state.table_loading}
                                    columns={this.columns}
                                    dataSource={data}
                                    size={"small"}
                                    //rowKey={(record) => record._id}
                                    //pagination={this.state.pagination}
                                    //onChange={this.handleTableChange}
                                />
                                <Col lg={23} md={23} sm={24} xs={24}>
                                    <Button
                                        //onClick={this.onSerach}
                                        style={{ marginLeft: "11px", float: "right" }}
                                        type='primary'
                                        disabled={this.state.btnsearch}>
                                        Cancel
                                    </Button>
                                    <Button
                                        //onClick={this.onSerach}
                                        style={{ marginLeft: "11px", float: "right" }}
                                        type='primary'
                                        disabled={this.state.btnsearch}>
                                        Save
                                    </Button>
                                </Col>
                            </Card>
                        </Col>
                    </Row>

                </Card>
            </React.Fragment>
        );
    }
}

AddIssue = Form.create()(AddIssue);
function mapStateToProps(state) {
    const { Common } = state;
    const { create_customer: create_customer,
        get_stock: get_stock, branch_list: branch_list,
        floor_list: floor_list,
        section_list: section_list, sub_section_list: sub_section_list,    } = Common;
    return { create_customer, get_stock, branch_list, floor_list, section_list, sub_section_list };
}
const AppContainer = connect(mapStateToProps)(AddIssue);
export default AppContainer;
