// import {getStockfromRfid} from '../../actions/actions';
import { connect } from "react-redux";
import React, { Component } from "react";
import {
  Modal,
  Form,
  Table,
  Upload,
  Card,
  Col,
  Icon,
  InputNumber,
  Button,
  message,
  Row,
  Radio,
  Switch,
  Select,
  AutoComplete,
  Input,
  Divider,
  Checkbox,
  List,
  Typography,
  Empty
} from "antd";
import { UpdateCompanyAccess,getAccessInfo, getCompany } from "../../../actions/scadmin";
import './style.css';
const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
let id = 0;

class AccessControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_info:[],
      access_info1:[],
      loading:true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    var that = this;
    try {
      if (prevProps.update_company_access != that.props.update_company_access) {
        if (that.props.update_company_access.status) {
          this.setState({
            visible: false,
            loading:false
          });
        } else {
          message.error(that.props.update_company_access.errors.msg);
        }
      }

      if (prevProps.access_info != that.props.access_info) {
        if (that.props.access_info.status) {
          // console.log(that.props.access_info);
          let accessInfoOrinal= that.props.access_info.data;
          this.setState({
            access_info1:accessInfoOrinal,
            id:that.props.access_info.data.companyId,
            companyId:that.props.access_info.data.companyId,
            phone:that.props.access_info.data.username,
            // v:that.props.access_info.data.__v
          });
          const newconst= that.props.access_info.data;
          let accessInfootherdata=newconst;
          delete accessInfootherdata.companyId;
          delete accessInfootherdata.username;
          delete accessInfootherdata._id;
          delete accessInfootherdata.__v;
          this.setState({
            access_info: accessInfootherdata
          });
          // console.log(accessInfoOrinal);
        }
      }

      


    } catch (error) {}
  }

  state = {
    visible: false,
    copied: false,
    companyType: "Retailers",
    ValidUservisible: false,
    ValidCompanyvisible: false,
    moduleList: []
  };

  showModal = () => {
    let phone=this.props.phone;
    const { dispatch } = this.props;
    dispatch(getAccessInfo(phone));
    this.setState({
      visible: true
    });

  };
  handleCancel = () => {
    this.props.form.resetFields();
    this.setState({ visible: false ,loading:true, access_info:[],
      access_info1:[]});
  };

  
  

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log(values);
        let accessInfo1=this.state.access_info1;
         if(JSON.stringify(values)==JSON.stringify(accessInfo1)){
            message.error("Please update data");
            return
        }
        // console.log(accessInfo1);
        // console.log(this.state.phone);
        // console.log(this.state.companyId);
        let result=values;
         result.companyId=this.state.companyId;
         result.username=this.props.phone;
        // result.__v=this.state.v;
        //  console.log(result);
        // if(JSON.stringify(mkey)==JSON.stringify(this.props.accessInfo)){
        //     message.error("Please add or update machine id");
        //     return
        // }
        // let result = this.props.accessInfo.includes(values.mkey);
        // if (result) {
        //   message.error("Machine key already exists");
        //   return;
        // }

        const { dispatch } = this.props;
        dispatch(
            UpdateCompanyAccess(result)
        );
      }
    });
  };

  render() {
    const { visible } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    function onChange(checkedValues) {
        // console.log('checked = ', checkedValues);
      }
   
    
    const lableList=["user",
    "branch",
    "floor",
    "section",
    "subSection"];
    // const HeaderForCheckbox=(e)=>{
    //     return(
    //         <Row>
    //              <Col span={4}></Col>
    //             {Object.keys(accessInfo).map((key,index)=>(
    //                 <Col span={5} key={key} style={{textTransform: "capitalize"}}>
    //                     {key}
    //                 </Col> 
    //             ))}

    //         </Row>
    //     )
    
    // }
    

    return (
      <div>
        {this.props.text}
        <Icon
          type="unlock"
          onClick={this.showModal}
          style={{ fontSize: 20, cursor: "pointer", paddingLeft: "10px",float:"left"}}
        />
        <Modal
          title="Company Access"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width={800}
          loading={this.state.loading}
          // style={{ paddingBottom: "166px" }}
        >
          <span>
          
            {/* <CheckboxGroup
                options={plainOptions}
                value={this.state.checkedList}
                //onChange={this.onChange}
            />
             <Checkbox.Group
      options={plainOptions}
      disabled
      defaultValue={['Apple']}
      onChange={onChange}
    /> */}
             
                    {/* <HeaderForCheckbox/> */}
                    {/* <valueofCheckbox/>     */}
                
                    {/* {lableList.map(data => (
                             <Row key={data}>
                               <Col span={4}  style={{textTransform: "capitalize"}}>{data}</Col>
                               <Col span={20} style={{textTransform: "capitalize"}}>
                            
                                </Col>
                             </Row>

                        ))} */}
                        {/* {this.state.access_info.length===0 ?<Empty/> : */}
                         <Form onSubmit={this.handleSubmit} formitemlayout="inline">
                        {Object.keys(this.state.access_info).map((key,index)=>(
                            
                            <React.Fragment>
                               
                                 <Row key={key}>
                                 <Col span={3}  style={{textTransform: "capitalize",paddingTop:"8px"}} >{key}</Col>
                                 <Col span={21}  style={{textTransform: "capitalize"}}> 
                                 <FormItem 
                                  //label={key}
                                 >
                                 {getFieldDecorator(`${key}`, {
                                   initialValue: this.state.access_info[key] ,
                                  rules: [{ required: false, message: 'Please input machine id!' }],
                                  })(
                                 <Checkbox.Group
                                    options={lableList}
                                    ///defaultValue={accessInfo[key]}
                                    onChange={onChange}
                                   /> )}
                                   </FormItem>
                                    </Col>
                                </Row>
                                
                                </React.Fragment>


                                ))}
                                
                                <Row >
                                <Col span={22} >
                                 <Form.Item  style={{float:"right"}}>
                                 
                                 {/* <Col span={4} ></Col>
                                 <Col span={20} > */}
                                  <Button type="primary" htmlType="submit" style={{float:"left"}}>
                                      Save
                                  </Button>
                                  <Button style={{float:"left"}} onClick={this.handleCancel}>
                                      Cancle
                                  </Button>
                                 
                              </Form.Item>
                              </Col>
                              <Col span={2} ></Col>
                                  </Row>
                          </Form>
                      {/* } */}
                    {/* <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                        <Row>
                        <Col span={4}></Col>
                        {Object.keys(accessInfo).map((key,index)=>(
                            <React.Fragment>
                            <Row>
                            <Col span={5} style={{textTransform: "capitalize"}}>
                                <Checkbox.Group
                                options={lableList}
                                defaultValue={accessInfo[key]}
                                onChange={onChange}
                                />
                            </Col> 
                            </Row>
                            </React.Fragment>
                        ))}
                        </Row>
                    </Checkbox.Group> */}

           
            {/* <List
              header={null}
              footer={null}
              bordered
              dataSource={this.props.accessInfo}
              renderItem={(item, index) => (
                <List.Item>
                  <Typography.Text mark> </Typography.Text>
                  {index + 1}. {item}
                </List.Item>
              )}
            /> */}

            
          </span>
        </Modal>
      </div>
    );
  }
}

AccessControl = Form.create()(AccessControl);

function mapStateToProps(state) {
  const { scadmin } = state;
  const { update_company_access: update_company_access ,access_info:access_info} = scadmin;
  return { update_company_access,access_info };
}

const AppContainer = connect(mapStateToProps)(AccessControl);
export default AppContainer;
