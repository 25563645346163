import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from '../../reducers/common_reducers';
import Dashboard from '../../reducers/dashboard_reducers';
import Dataexport from '../../reducers/data_export';
import branchReducer  from '../../reducers/Branch/index';
import scadmin  from '../../reducers/scadmin';
import customerTypeListReducer from "../../routes/companyAdmin/customertypeConfiguration/Reducers/ListCustomerType";
import listConfigurationDisplay from "../../routes/companyAdmin/configurationDisplay/REDUCER/ListConfigurationDisplay";
const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  Common:Common,
  Dashboard:Dashboard,
  Dataexport:Dataexport,
  branch:branchReducer,
  scadmin:scadmin,
  customerTypeListReducer:customerTypeListReducer,
  listConfigurationDisplay:listConfigurationDisplay

});

export default reducers;
