import {updateSkuDetails} from '../../../actions/index';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Button, Col, Form, Input, message, Modal, Row, Spin} from 'antd';
//import "./custome.css";
//import {countryList} from "./country";

const {TextArea} = Input;


class EditSku extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            keys: []
        };
    }

    componentDidMount() {
        const {dispatch} = this.props;

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.sku_update != this.props.sku_update) {
            try {
                if (this.props.sku_update.status) {
                    message.success("Update sku details successfully")
                    this.props.closeEditSku(true);
                } else {
                    message.error(this.props.sku_update.errors.msg);
                }
            } catch (error) {
                console.log(error);
            }
        }

        if (prevProps.skulabel_details != this.props.skulabel_details) {
            // console.log("this.props.skulabel_details");
            // console.log(this.props.skulabel_details);
            if (this.props.skulabel_details.status) {
                this.setState({
                    loading: false,
                    keys: this.props.skulabel_details.data.labels
                })
            }

        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.props.closeEditSku();
    }


    onChangeCustomerType = (e) => {
        if (e.target.value === false) {
            this.setState({
                customerType: true,
                isPrivateCustomer: false
            })
        } else {
            this.setState({
                customerType: false,
                isPrivateCustomer: true
            })
        }
    }


    handleOk = (e) => {

        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            const {dispatch} = this.props;

            // console.log(this.props.record);
            let record = this.props.editRecord;
            let finalResult = Object.assign(record, values);
            dispatch(updateSkuDetails(finalResult));
        });
    }


    render() {
        const {visible} = this.state;
        const {getFieldDecorator} = this.props.form;
        const {editRecord} = this.props;
        //   let {firstName,familyName,email,companyName,birthDate,gender,street,streetNumber,street2,city,pin,customerType,country,contactNumber,contactPerson,pan,gst,roundOffSale,status,isPrivateCustomer}= this.props.record;
        //birthDate=moment(birthDate, 'DD/MM/YYYY');
        const formItems = this.state.keys.map((k, index) => (
            // <Col span={12} style={{display:"block"}}>
            <React.Fragment>
                {k.label === "rm_value" ? "" :
                    <Col span={12}>


                        <Form.Item
                            label={k.labelvalue}
                            //required={k.label === "rfid_number" || k.label === "sku_number" ? true : false}
                            key={k}
                            // style={{float:"left"}}
                        >
                            {getFieldDecorator(`${k.label}`, {
                                //    validateTrigger: ['onChange', 'onBlur'],
                                initialValue: editRecord[`${k.label}`],
                                rules: [
                                    {
                                        required: k.label === "rfid_number",
                                        // whitespace: true,
                                        // required:false,
                                        message: `Please input ${k.label}`,
                                    },
                                ],
                            })(<Input placeholder={`Enter ${k.label}`}
                                      disabled={(k.label === "rfid_number" || k.label === "sku_number")}/>)}
                        </Form.Item>
                    </Col>
                }
            </React.Fragment>
        ));
        const formItemLayout = {
            labelCol: {span: 24},
            //   labalAlign:{marginLeft: "15px"},
            wrapperCol: {span: 24},
        };
        return (
            <Modal
                title="Update sku details"
                visible={this.props.visibleForEditSku}
                onOk={this.uploadhandleSyncOk}
                //     confirmLoading={confirmLoading}
                style={{paddingBottom: "0px"}}
                //width={650}
                onCancel={this.handleCancel}
                footer={[
                    <span style={{marginBottom: "20px"}}>
               <Button key="back" onClick={this.handleCancel} style={{minWidth: "77px"}} ghost>Cancel</Button>
              <Button key="submit" type="primary" onClick={this.handleOk} style={{minWidth: "77px"}}>
                  Update
           </Button>
              </span>
                ]}

            >
                <React.Fragment>
                    {/**<img src={EditIcon} onClick={this.showModal} style={{paddingLeft:"15px",cursor:"pointer"}}></img>*/}

                    <Form onSubmit={this.FormSubmit}  {...formItemLayout} >
                        <Spin spinning={this.state.loading}>
                            <Row gutter={24}>

                                {formItems}

                            </Row>
                        </Spin>
                    </Form>

                </React.Fragment>
            </Modal>
        )
    }
}


EditSku = Form.create()(EditSku);

function mapStateToProps(state) {
    const {Common} = state
    const {sku_update: sku_update, skulabel_details: skulabel_details} = Common
    return {sku_update, skulabel_details}
}

const AppContainer = connect(
    mapStateToProps
)(EditSku);
export default AppContainer;
