import axios from "axios";

const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var cookies = require('browser-cookies');

function getloginInfo(){
    let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    let headers = {
      'Content-Type': 'application/json',
      'type': 'Web',
      'x-access-token': webtoken,
    }
    return headers;
  }

// const host="http://api.jeweltrace.in"
export function AddCustomerType(data) {
    return dispatch => {
        // return axios.post(host+"/customerstypes?=x-access-token",data,
        return axios.post("/customerstypes?=x-access-token",data,
          { headers: getloginInfo()}
          )
            .then(response => {  
                       
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status === 403) {
                  window.location.href='/';
                } else {
                    console.log(error);
                }
            }
            });
    }
  }