import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAllFloorList,
  getAllBrachList,
  getAllSectionList,
  getAllSubSectionList,
  getAllUserList,
  updateUserDetails,
} from "../../../actions/index";
import {
  Card,
  Table,
  Form,
  Row,
  Col,
  Modal,
  Select,
  Input,
  Button,
  Icon,
  Popconfirm,
  Radio,
  Alert,
  message,
} from "antd";
import { Link } from "react-router-dom";
import ReactExport from "react-data-export";
import AddUser from "./add";
import EditUser from "./edit";
import EditIcon from "../../../assets/images/icons/edit-icon.svg";
import { userAccess } from "../../../containers/App/commanFunction";

var cookies = require("browser-cookies");
const { TextArea } = Input;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Option } = Select;

class ManageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      table_loading: true,
      search: "",
      visible: false,
      loading: true,
      branch_list: [],
      branch_id: "",
      floor_list: [],
      floor_id: "",
      section_id: "",
      section_list: [],
      sub_section_list: [],
      sub_section_id: "",
      record: [],
    };
  }
  componentWillMount() {
    let usertype = localStorage.getItem("type_user");
    if (usertype != "companyAdmin") {
      window.location.href = "/dashboard";
    }
  }
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAllBrachList());
    let root = JSON.parse(cookies.get("root"));
    this.setState({
      companyId: root.companyId,
    });
    dispatch(getAllUserList("company", root.companyId));
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.branch_list != this.props.branch_list) {
      if (this.props.branch_list.status) {
        this.setState({
          branch_list: this.props.branch_list.data_array,
        });

        if (this.props.branch_list.data_array.length >= 1) {
          const { dispatch } = this.props;
          this.setState({
            branch_id: this.props.branch_list.data_array[0]._id,
          });
          dispatch(
            getAllFloorList(
              "bybranchId",
              this.props.branch_list.data_array[0]._id
            )
          );
        }
      } else {
        this.setState({
          branch_list: [],
        });
      }
    }

    if (prevProps.floor_list != this.props.floor_list) {
      // console.log("this.props.floor_list");
      if (this.props.floor_list.status) {
        this.setState({
          floor_list: this.props.floor_list.data_array,
        });

        if (this.props.floor_list.data_array.length >= 1) {
          this.setState({
            floor_id: this.props.floor_list.data_array[0]._id,
          });
          const { dispatch } = this.props;
          dispatch(
            getAllSectionList(
              "byFloorId",
              this.props.floor_list.data_array[0]._id
            )
          );
        }
      } else {
        this.setState({
          floor_list: [],
        });
      }
      this.setState({
        table_loading: false,
      });
    }

    if (prevProps.section_list != this.props.section_list) {
      // console.log(this.props.section_list);
      if (this.props.section_list.status) {
        this.setState({
          section_list: this.props.section_list.data_array,
        });

        if (this.props.section_list.data_array.length >= 1) {
          this.setState({
            section_id: this.props.section_list.data_array[0]._id,
          });
          const { dispatch } = this.props;
          dispatch(
            getAllSubSectionList(
              "user",
              this.props.section_list.data_array[0]._id
            )
          );
        }
      } else {
        this.setState({
          section_list: [],
        });
      }
      this.setState({
        table_loading: false,
      });
    }

    if (prevProps.sub_section_list != this.props.sub_section_list) {
      // console.log(this.props.sub_section_list);
      if (this.props.sub_section_list.status) {
        this.setState({
          sub_section_list: this.props.sub_section_list.data_array,
        });

        if (this.props.sub_section_list.data_array.length >= 1) {
          this.setState({
            sub_section_id: this.props.sub_section_list.data_array[0]._id,
          });
          const { dispatch } = this.props;
          // dispatch(getAllUserList("subSection",this.props.sub_section_list.data_array[0]._id));
        }
      } else {
        this.setState({
          sub_section_list: [],
        });
      }
      this.setState({
        table_loading: false,
      });
    }

    if (prevProps.user_list != this.props.user_list) {
      if (this.props.user_list.status) {
        this.setState({
          data: this.props.user_list.data_array,
        });
      } else {
        this.setState({
          data: [],
        });
      }
      this.setState({
        table_loading: false,
      });
    }

    if (prevProps.update_user_data != this.props.update_user_data) {
      if (this.props.update_user_data.status) {
        message.success("Update sucessfully");
        const { dispatch } = this.props;
        dispatch(getAllUserList("company", this.state.companyId));
      } else {
        message.error(this.props.update_user_data.error.msg);
      }
    }
  }

  onChangeBranchRadio = (e) => {
    this.setState({
      table_loading: true,
      branch_id: e,
      data: [],
    });
    const { dispatch } = this.props;
    dispatch(getAllUserList("branch", e));
  };

  onChangeFloor = (e) => {
    this.setState({
      table_loading: true,
      floor_id: e,
      data: [],
    });
    const { dispatch } = this.props;
    dispatch(getAllUserList("floor", e));
  };

  onChangeSection = (e) => {
    this.setState({
      table_loading: true,
      section_id: e,
      data: [],
    });
    const { dispatch } = this.props;
    dispatch(getAllUserList("section", e));
  };

  onChangeSubSection = (e) => {
    this.setState({
      table_loading: true,
      sub_section_id: e,
      data: [],
    });
    const { dispatch } = this.props;
    dispatch(getAllUserList("subSection", e));
  };

  deleteActiveUser = (e) => {
    e.isActive = !e.isActive;
    const { dispatch } = this.props;
    dispatch(updateUserDetails(e));
  };

  showModal = (record) => {
    // console.log("recprd");
    // console.log(record);
    this.setState({
      visible: true,
      record: record,
      userType: record.usertype,
    });
    const { dispatch } = this.props;
    dispatch(getAllBrachList());
  };

  handleCancel = () => {
    this.setState({ visible: false });
    // this.props.form.resetFields();
    // this.props.handleCancel();
  };
  filterClear = (e) => {
    this.setState({
      branch_list: [],
      floor_list: [],
      section_list: [],
      sub_section_list: [],
    });
    // console.log("this.props.form", this.props.form);
    this.props.form.resetFields();
    const { dispatch } = this.props;
    let root = JSON.parse(cookies.get("root"));
    dispatch(getAllUserList("company", root.companyId));
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let data = this.state.data;
    let { loading } = this.state;
    const columns = [
      {
        title: "FIRST NAME",
        dataIndex: "firstname",
        // width: '10%',
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "LAST NAME",
        dataIndex: "lastname",
        //    width: '10%',
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "E-MAIL",
        dataIndex: "email",
        //    width: '10%',
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "USER TYPE",
        dataIndex: "usertype",
        //    width: '10%',
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "USERNAME",
        dataIndex: "username",
        //    width: '10%',
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "Action",
        //    width: '10%',
        render: (text, record) => {
          let { isActive } = record;
          return (
            <span>
              {/* <EditUser record={record} />  */}
              {userAccess("edit", "user") === "true" ? (
                <img
                  src={EditIcon}
                  onClick={() => this.showModal(record)}
                  style={{ paddingLeft: "10px", cursor: "pointer" }}></img>
              ) : (
                ""
              )}
              {userAccess("delete", "user") === "true" ? (
                <Popconfirm
                  title={
                    isActive
                      ? "Are you sure you want to deactivate this user?"
                      : "Are you sure you want to activate this user?"
                  }
                  onConfirm={() => this.deleteActiveUser(record)}
                  //onCancel={cancel}
                  okText='Yes'
                  cancelText='No'>
                  {isActive ? (
                    <Icon
                      type='check-circle'
                      theme='filled'
                      theme='twoTone'
                      twoToneColor='#52c41a'
                      style={{
                        fontSize: 20,
                        paddingLeft: "20px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <Icon
                      type='check-circle'
                      styl
                      e={{
                        fontSize: 20,
                        paddingLeft: "20px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </Popconfirm>
              ) : (
                ""
              )}
            </span>
          );
        },
      },
    ];
    try {
      if (this.state.search) {
        data = data.filter((row) => {
          // console.log(row);

          // return row.name.includes(this.state.search) ||  String(row.phone).includes(this.state.search)
          return (
            row.firstname
              .toUpperCase()
              .includes(this.state.search.toUpperCase()) ||
            row.lastname
              .toUpperCase()
              .includes(this.state.search.toUpperCase()) ||
            row.email.toUpperCase().includes(this.state.search.toUpperCase()) ||
            row.usertype
              .toUpperCase()
              .includes(this.state.search.toUpperCase()) |
              row.username
                .toUpperCase()
                .includes(this.state.search.toUpperCase())
          );
          //row.name.toUpperCase().includes(this.state.search.toUpperCase())
        });
      }
    } catch (error) {
      console.log(error);
    }
    const userAccess1 = userAccess("add", "user");
    return (
      <div>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <h2
              className='title gx-mb-4'
              style={{ float: "left", paddingRight: "12px" }}>
              Manage User
            </h2>
          </Col>
        </Row>

        {this.state.visible ? (
          <EditUser
            handleCancel={this.handleCancel}
            record={this.state.record}
            userType={this.state.userType}
            visible={this.state.visible}
          />
        ) : (
          ""
        )}
        <div className='certain-category-search-wrapper'>
          {userAccess1 == "true" ? (
            <Row>
              <Col lg={21} md={20} sm={24} xs={24}>
                <Input
                  value={this.state.search}
                  placeholder='Search ..'
                  style={{ width: "100%", float: "left" }}
                  onChange={(e) => this.setState({ search: e.target.value })}
                  suffix={
                    <Icon type='search' className='certain-category-icon' />
                  }
                />
              </Col>
              <Col lg={3} md={4} sm={24} xs={24}>
                <AddUser
                  branchId={this.state.branch_id}
                  floorId={this.state.floor_id}
                  sectionId={this.state.section_id}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{ paddingBottom: "15px" }}>
                <Input
                  value={this.state.search}
                  placeholder='Search ..'
                  style={{ width: "100%", float: "left" }}
                  onChange={(e) => this.setState({ search: e.target.value })}
                  suffix={
                    <Icon type='search' className='certain-category-icon' />
                  }
                />
              </Col>
            </Row>
          )}
        </div>

        <Row>
          <Col lg={19} md={19} sm={24} xs={24}>
            <Card bodyStyle={{ padding: "10px 0px" }}>
              <Table
                className='gx-table-responsive'
                loading={this.state.table_loading}
                columns={columns}
                dataSource={data}
                size={"small"}
                rowKey={(record) => record._id}
                onChange={this.handleTableChange}
              />
            </Card>
          </Col>

          <Col lg={5} md={5} sm={24} xs={24}>
            <Card bodyStyle={{ padding: "10px 10px" }} title='Filter'>
              <p
                style={{
                  marginTop: "5px",
                  marginRight: "9px",
                  marginLeft: "8px",
                }}>
                Select Branch
              </p>

              {this.state.branch_id != "" ? (
                <Form.Item>
                  {getFieldDecorator(`branch_id`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      placeholder='Please select branch'
                      onChange={this.onChangeBranchRadio}
                      style={{ width: "200px", marginLeft: "15px" }}>
                      {this.state.branch_list.map((data, index) => (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              ) : (
                ""
              )}

              <p
                style={{
                  marginRight: "9px",
                  marginLeft: "8px",
                }}>
                Select Floor
              </p>
              {this.state.floor_id != "" ? (
                <Form.Item>
                  {getFieldDecorator(`floor_id`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      placeholder='Please Select Floor'
                      onChange={this.onChangeFloor}
                      style={{ width: "200px", marginLeft: "15px" }}>
                      {this.state.floor_list.map((data, index) => (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              ) : (
                ""
              )}

              <p
                style={{
                  marginRight: "9px",
                  marginLeft: "8px",
                }}>
                Select Section
              </p>
              {this.state.section_id != "" ? (
                <Form.Item>
                  {getFieldDecorator(`section_id`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      placeholder='Please Select Section'
                      onChange={this.onChangeSection}
                      style={{ width: "200px", marginLeft: "15px" }}>
                      {this.state.section_list.map((data, index) => (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              ) : (
                ""
              )}

              <p
                style={{
                  marginRight: "9px",
                  marginLeft: "8px",
                }}>
                Select SubSection
              </p>
              {this.state.sub_section_id != "" ? (
                <Form.Item>
                  {getFieldDecorator(`sub_section_id`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      placeholder='Please select sub section'
                      onChange={this.onChangeSubSection}
                      style={{ width: "200px", marginLeft: "15px" }}>
                      {this.state.sub_section_list.map((data, index) => (
                        <Option key={data._id} value={data._id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              ) : (
                ""
              )}
              <Button
                onClick={this.filterClear}
                style={{ marginLeft: "140px" }}
                ghost>
                Clear
              </Button>
            </Card>
          </Col>

          {/* <select id="selected_device" onchange={()=>window.onDeviceSelected(this)}></select>
         <Button onClick={()=>window.writeToSelectedPrinter()}>Print Sample Tags</Button> */}
          {/* <input type="button" value="Print Sample Tags" onclick={()=>window.writeToSelectedPrinter()}/><br/><br/> */}
        </Row>
      </div>
    );
  }
}

ManageUser = Form.create()(ManageUser);
function mapStateToProps(state) {
  const { Common } = state;
  const {
    update_user_data: update_user_data,
    floor_list: floor_list,
    branch_list: branch_list,
    section_list: section_list,
    sub_section_list: sub_section_list,
    user_list: user_list,
  } = Common;
  return {
    update_user_data,
    floor_list,
    branch_list,
    section_list,
    sub_section_list,
    user_list,
  };
}

const AppContainer = connect(mapStateToProps)(ManageUser);

export default AppContainer;
