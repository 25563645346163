import React, { Component } from 'react'
import IntlMessages from "util/IntlMessages";
import {connect} from "react-redux";
import moment from 'moment';
import {Card, Table,Form,Row,Col,DatePicker,Select,InputNumber,Button,AutoComplete,Input,Icon} from "antd";
import {sortDirections} from "../../../containers/App/commanFunction";
import FilterIcon from '../../../assets/images/icons/filter-icon.svg';
const { MonthPicker, RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;
const CustomerList=[];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const columns = [  
  {
    title: 'Sales Person',
    dataIndex: 'sales_person',
    width: '10%',
    render: (text, record) => {
      const { finger_index } = record
      
      return (
        <span>
            {text}
        </span>
      );
    }
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    width: '10%',
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
            {text}
        </span>
      );
    },
    
  },
  {
    title: 'Amount',
    dataIndex: 'amt',
    width: '10%',
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
            {text}
        </span>
      );
    }
  },
];



const top5salesDesigncolumns = [  
    {
      title: 'DSG Code',
      dataIndex: 'dsg_code',
      width: '10%',
      render: (text, record) => {
        const { finger_index } = record
        
        return (
          <span>
              {text}
          </span>
        );
      }
    },
    {
      title: 'DESIGN CTG',
      dataIndex: 'ctg',
      width: '10%',
      render: (text, record) => {
        const { customer } = record;
        return (
          <span>
              {text}
          </span>
        );
      },
      
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      width: '10%',
      render: (text, record) => {
        const { customer } = record;
        return (
          <span>
              {text}
          </span>
        );
      }
    },
    {
        title: 'Amount',
        dataIndex: 'amt',
        width: '10%',
        render: (text, record) => {
          const { customer } = record;
          return (
            <span>
                {text}
            </span>
          );
        }
      },
  ];

  
  const category_wise_sales_columns = [  
    {
      title: 'CTG',
      dataIndex: 'ctg',
      width: '10%',
      render: (text, record) => {
        const { customer } = record;
        return (
          <span>
              {text}
          </span>
        );
      },
      
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      width: '10%',
      render: (text, record) => {
        const { customer } = record;
        return (
          <span>
              {text}
          </span>
        );
      }
    },
    {
        title: 'Amount',
        dataIndex: 'amt',
        width: '10%',
        render: (text, record) => {
          const { customer } = record;
          return (
            <span>
                {text}
            </span>
          );
        }
      },
  ];



class Sales extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
       data:[{"sales_person":"Hitesh","qty":"10","amt":"123456.5"},{"sales_person":"Rajesh","qty":"15","amt":"385000"},{"sales_person":"Pankaj","qty":"41","amt":"125000"},{"sales_person":"Hitesh","qty":"31","amt":"123456.5"},{"sales_person":"Rakesh","qty":"21","amt":"25600.5"}],
       top5salesDesign:[{"dsg_code":"RNG01","ctg":"RNG","qty":"34","amt":"12345.50"},
       {"dsg_code":"RNG01","ctg":"RNG","qty":"34","amt":"19545.50"},
       {"dsg_code":"PND05","ctg":"RNG","qty":"44","amt":"22245.50"},
       {"dsg_code":"RNG05","ctg":"RNG","qty":"33","amt":"33345.50"},
       {"dsg_code":"BNG01","ctg":"RNG","qty":"24","amt":"12545.50"}
    ],
    category_wise_sales:[{"ctg":"RNG","qty":"34","amt":"50000"},
    {"ctg":"BNG","qty":"34","amt":"10000"},
    {"ctg":"PND","qty":"44","amt":"23000"},
    {"ctg":"BRC","qty":"33","amt":"44000"},
    {"ctg":"NCK","qty":"24","amt":"24500"}],
       table_loading:false
    };
   
  }
  componentDidMount() {
  }

  handleSearch = (e) => {
     
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {dispatch} = this.props;
       // let userdata=values;
        
      }
    });
  }
  
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
      <h2 className="title gx-mb-4"><IntlMessages id="sidebar.SalesPage"/></h2>
     
      <Row>
        <Col lg={12} md={12} sm={12} xs={24}>   
            <Card title= {<span><span>Top 5 Sales Person</span><Icon type="cloud-download" style={{float:"right",fontSize:"27px",cursor:"pointer"}}  className="certain-category-icon"/> </span>}  className="certain-category-icon" 
            >
                        <Table className="gx-table-responsive"
                            loading={this.state.table_loading} 
                            columns={columns} 
                            dataSource={this.state.data}
                            size={"small"}
                            rowKey={record => record._id}
                            
                        />
                        </Card>
                </Col>
                
        <Col lg={12} md={12} sm={12} xs={24}>   
        <Card  title=
        {<span><span>Web - Top 5 Sales - Design wise</span><Icon type="cloud-download" style={{float:"right",fontSize:"27px",cursor:"pointer"}}  className="certain-category-icon"/> </span>}  className="certain-category-icon" 

        >
              
                        <Table className="gx-table-responsive"
                            loading={this.state.table_loading} 
                            columns={top5salesDesigncolumns} 
                            dataSource={this.state.top5salesDesign}
                            size={"small"}
                            rowKey={record => record._id}
                        />
            </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={24}>   
            <Card title=
            {<span><span>Category wise Sales</span><Icon type="cloud-download" style={{float:"right",fontSize:"27px",cursor:"pointer"}}  className="certain-category-icon"/> </span>}  className="certain-category-icon"
            >
                        <Table className="gx-table-responsive"
                            //loading={this.state.category_wise_sales} 
                            columns={category_wise_sales_columns} 
                            dataSource={this.state.category_wise_sales}
                            size={"small"}
                            rowKey={record => record._id}
                        />
                        </Card>
                </Col>
        </Row>
      </div>
    )
  }
}

Sales = Form.create()(Sales);
function mapStateToProps(state) {
  const {Common } = state
  const {quotation_details:quotation_details,customer_details:customer_details  } =  Common
  return {quotation_details,customer_details}
}

const AppContainer = connect( 
  mapStateToProps 
)(Sales);


export default AppContainer;


