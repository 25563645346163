// import {getStockfromRfid} from '../../actions/actions';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Modal,Form,Table ,List,Col, Icon, Button,message, Row,Switch } from 'antd';
import moment from 'moment';
import {currencyConverter} from "../../../containers/App/commanFunction";
message.config({
  top: 100,
  duration: 10,
  maxCount: 3,
});


class SalesDetails extends Component {
      state = {
        get_stock_from_rfid: null

      }
      constructor(props) 
      {
        super(props);
      }
      componentDidMount() {
        const { dispatch } = this.props;
      }
   

    state = {
      loading: false,
      visible: false,
       copied: false
    }

    showModal = () => {
      this.setState({
        visible: true,
      });
    }
    handleOk = () => {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false, visible: false });
      }, 3000);
    }
    handleCancel = () => {
      this.setState({ visible: false });
    }
  


    render () {
   
        const { visible } = this.state;
        const columns = [
          {
            title: 'Sr No',
            dataIndex: 'uniqeId',
            key: 'uniqeId',            
            render: (text, record) => {
              // const { uniqeId } = record;
              // const {uniqeId}=skuItem;
              return (
                <span>
                 {text}
                </span>
              );
            }
          }, 
          {
            title: 'Design Code',
            dataIndex: 'design_code',
            key: 'design_code',            
            render: (text, record) => {
              //const { skuItem } = record;
             // const {design_code}=skuItem;
              return (
                <span>
                 {text}
                </span>
              );
            }
          }, 
          {
            title: 'Design Color',
            dataIndex: 'design_color',
            key: 'design_color',            
            render: (text, record) => {
            //  const { skuItem } = record;
            //  const {design_color}=skuItem;
              return (
                <span>
                 {text}
                </span>
              );
            }
          }, 
          {
            title: 'Diamond Qty',
            dataIndex: 'diamond_quantity',
            key: 'diamond_quantity',            
            render: (text, record) => {
             // const { skuItem } = record;
              //const {diamond_quantity}=skuItem;
              return (
                <span>
                 {text}
                </span>
              );
            }
          }, 
          {
            title: 'Color Stone Wt',
            dataIndex: 'color_stone_weight',
            key: 'color_stone_weight',            
            render: (text, record) => {
            //  const { skuItem } = record;
           //   const {color_stone_weight}=skuItem;
              return (
                <span>
                {text.toFixed(3)}
                </span>
              );
            }
          }, 
          {
            title: 'Color Stone Qty',
            dataIndex: 'color_stone_quantity',
            key: 'color_stone_quantity',            
            render: (text, record) => {
          //    const { skuItem } = record;
             // const {color_stone_quantity}=skuItem;
              return (
                <span>
                {text}
                </span>
              );
            }
          }, 
          {
            title: 'Gross Wt',
            dataIndex: 'gross_weight',
            key: 'gross_weight',            
            render: (text, record) => {
             // const { skuItem } = record;
            //  const {gross_weight}=skuItem;
              return (
                <span>
                {text.toFixed(3)}
                </span>
              );
            }
          }, 
          {
            title: 'Net Wt',
            dataIndex: 'net_weight',
            key: 'net_weight',            
            render: (text, record) => {
             // const { skuItem } = record;
             // const {net_weight}=skuItem;
              return (
                <span>
                {text.toFixed(3)}
                </span>
              );
            }
          }, 
          {
            title: 'Purity',
            dataIndex: 'purity',
            key: 'purity',            
            render: (text, record) => {
             // const { skuItem } = record;
            //  const {purity}=skuItem;
              return (
                <span>
                {text}
                </span>
              );
            }
          }, 
          {
            title: 'Labour Value',
            dataIndex: 'labour_value',
            key: 'labour_value',            
            render:(text,record)=>{
              return <span>{currencyConverter(text)}</span>;
            }
          }, 
          {
            title: 'Sales Value',
            dataIndex: 'sales_value',
            key: 'sales_value',            
            render:(text,record)=>{
              return <span>{currencyConverter(text)}</span>;
            }
          }
        
      ];
        return (
            <div>
      
              {this.props.text}
           
           <Icon type="eye" theme="filled"  style={{ cursor: 'pointer', color:'#595B62',fontSize: 20,float:"left"}} onClick={this.showModal} />
            <Modal
              visible={visible}
              title={this.props.modeltitle}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={null}
              width={1150}
              style={{paddingBottom:"166px"}}
            >
              <span>
                 <Row>
                    <Col span={12}>
                        <p style={{marginTop:"-5"}}>Customer Name : {this.props.customername} </p>                     
                    </Col>
                    <Col span={11} >
                        <p style={{marginTop:"-5",float:"right"}}>Date : {this.props.created_at}</p>
                    </Col>
                    <Col span={1}></Col>
                  </Row>
                  <Row >
                    <Col span={12}>
                       <p>Company : {this.props.companyname}</p>
                    </Col>
                   
                   </Row>
                  <Row>
                      <Col span={12}>
                        <p>Description : {this.props.description} </p>
                      </Col>
                  </Row>
          
                 <Table
                    columns={columns}
                    dataSource={this.props.skuitemsissue}
                   // bordered
                    size={"small"}
                    rowKey={record => record.uniqeId}
                  />
                  <Row>
                  <Col span={8}>
                  </Col>
                  <Col span={16}>
                     <Button style={{float:'right'}} type="primary"   onClick={this.handleCancel}>Close</Button>
                  </Col>
                  </Row>
                 
              </span>
            </Modal>
          </div>
        )
    }
}

export default SalesDetails
