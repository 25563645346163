import React, { Component } from 'react';
import {Card, Table,Form,Row,Col,Modal,Select,Input,Button,Icon,Popconfirm,Alert,message,Divider,DatePicker} from "antd";
import {connect} from "react-redux";
import moment from 'moment';
import ExportIcon from "./../../assets/images/icons/download-xls-icon.svg";
import ReactExport from "react-data-export";
import {currencyConverter} from "../../containers/App/commanFunction";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class CustomerReport extends Component {
    constructor(props) {
        super(props);
        this.state={
            total:0,
            data:{},
            dataArray:[],
            customerName:'',
            totalVisit:0,
            tableLoading:true,
            btn1:false,
            btn2:false,
            btn3:false,
            btn4:false,
            lastBtnClick:'',
            pagination:{}
        }
    }
    componentDidMount(){
        let pagination = {};
        pagination.current = 1;
        this.setState({
            pagination:pagination
        })
    }
    componentDidUpdate(prevProps, prevState){
        try {
        if (prevProps.get_cutomer_report != this.props.get_cutomer_report) {
            if (this.props.get_cutomer_report.status) {
              let data = this.props.get_cutomer_report.data_array;
              
              data.sort((a, b) => (a[`date`] > b[`date`]) ? -1 : 1);
              let firstname=this.props.get_cutomer_report.data.customer.firstName;
              let familyName=this.props.get_cutomer_report.data.customer.familyName;
              if(familyName===undefined){
                familyName=''
              }
              if(firstname===undefined){
                firstname=''
              }
               let name=familyName+" "+firstname;
              // if(this.props.get_cutomer_report.data.customer.familyName === undefined && this.props.get_cutomer_report.data.customer.firstName === undefined){
                
              //   if(this.props.get_cutomer_report.data.customer.familyName === undefined){
              //     name=this.props.get_cutomer_report.data.customer.firstName;
              //   }else if(this.props.get_cutomer_report.data.customer.firstName === undefined){
              //     name=this.props.get_cutomer_report.data.customer.familyName;
              //   }else{
              //     name=""
              //   }
              // }else{
              //   name=this.props.get_cutomer_report.data.customer.familyName+" "+this.props.get_cutomer_report.data.customer.firstName;
              //}
              
              let address=`${this.props.get_cutomer_report.data.customer.streetNumber} ${this.props.get_cutomer_report.data.customer.street} ${this.props.get_cutomer_report.data.customer.street2} ${this.props.get_cutomer_report.data.customer.city} - ${this.props.get_cutomer_report.data.customer.pin} ${this.props.get_cutomer_report.data.customer.country}`
              let customertype=this.props.get_cutomer_report.data.customer.customerType;
              let cust="";
              if(customertype==="1" || customertype===1){
                cust="S - Sales Value Customer";
              }else if(customertype=="2" || customertype===2){
                cust="A - Asking Value Customer"
              }else if(customertype=="3" || customertype===3){
                cust="R - Retail Value Customer"
              }else{
                cust=""
              }
              this.setState({                
                dataArray: data,
                allArray: data,
                data: this.props.get_cutomer_report.data,
                customerName:name,
                customerType:cust,
                contactNumber:this.props.get_cutomer_report.data.customer.contactNumber,
                email:this.props.get_cutomer_report.data.customer.email,
                address:address,
                totalVisit:this.props.get_cutomer_report.data["TOTAL VISITS"],
                tableLoading:false
              });      
            } else {
              this.setState({
                dataArray: [],     
                tableLoading:false           
              });
            }
          }
                
        } catch (error) {
            
        }
    }
    handleTableChange = (pagination, filters, sorter) => {
        let colome=sorter.columnKey;
        let order=sorter.order;
        let orderopp=0;
        let data=this.state.dataArray;
        if(order!=undefined){
          if(order==="descend"){
            order=-1;
            orderopp=1;
          }else{
            order=1;
            orderopp=-1;
          }
          data.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
        }else{
          const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
          shuffleArray(data);
        }
        let pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
        });
     };

     btnFiltter=(e)=>{
        let pagination = {};
        pagination.current = 1;
         this.setState({
            btn1:false,
            btn2:false,
            btn3:false,
            btn4:false,
            btn5:false,
            lastBtnClick:e,
            pagination:pagination
        })
        switch (e) {
            case "Viewed":
                this.setState({
                    btn1:true
                })       
                break;
            case "Shared":
                this.setState({
                    btn2:true
                })
                break;
            case "Purchased":
                this.setState({
                    btn3:true
                })
                break;
            case "Quoted":
                this.setState({
                    btn4:true
                })
                break;
            case "total":
            this.setState({
                btn5:true
            })
            break;
            default:
                break;
        }
        let array=this.state.allArray;
        if(e != "total"){
        this.setState({
            dataArray:array.filter(data => data.status === e)
        });
        }else{
          this.setState({
            dataArray:array
         });
        }
        if(this.state.lastBtnClick===e){
            let array=this.state.allArray;
            this.setState({
                dataArray:array,
                btn1:false,
                btn2:false,
                btn3:false,
                btn4:false,
                btn5:false,
                lastBtnClick:''
            });
        }
     }

    render() {
        this.columns = [  
            {
                title: 'DATE',
                dataIndex: 'date',
                defaultSortOrder: 'descend',
                render: (text, record) => {
                    var datef = moment(text).utc(false).format("DD/MM/YYYY");
                    return (
                        <span>
                            {datef}
                        </span>
                    );
                }
            },
            {
              title: 'EMPLOYEE',
              dataIndex: 'salePerson',
             // width: '10%',
             sorter: (a, b) => {},
            },
            {
              title: 'SKU',
              dataIndex: 'sku_number',
             sorter: (a, b) => {},    
            },
            {
                title: 'DESIGN CODE',
                dataIndex: 'design_code',
               sorter: (a, b) => {},    
              },
              {
                title: 'DESIGN CAT',
                dataIndex: 'design_category',
               sorter: (a, b) => {},    
              },
              {
                title: 'SALES CAT',
                dataIndex: 'sales_category',
               sorter: (a, b) => {},    
              },
            //   {
            //     title: 'BRAND',
            //     dataIndex: 'design_category',
            //    sorter: (a, b) => {},    
            //   },
              {
                title: 'MATERIAL',
                dataIndex: 'metal_type',
               sorter: (a, b) => {},    
              },
            //   {
            //     title: 'CARATS',
            //     dataIndex: 'design_category',
            //    sorter: (a, b) => {},    
            //   },
            //   {
            //     title: 'GEMSTONES',
            //     dataIndex: 'design_category',
            //    sorter: (a, b) => {},    
            //   },
              {
                title: 'DIAMONDS',
                dataIndex: 'diamond_quantity',
               sorter: (a, b) => {},    
              },
              {
                title: 'PRICE',
                dataIndex: 'sales_value',
               sorter: (a, b) => {},    
               render: (text, record) => {
                return (
                  <span>
                  {currencyConverter(text)}
                  </span>
                );
              }
              },
              {
                title: 'STATUS',
                dataIndex: 'status',
               sorter: (a, b) => {},    
              }]
        return (
            <div>
           
            <Card className="customer-top-menu-card" bodyStyle={{ padding: "0" }} bordered={false}>
    
      <Row>
        <Col lg={4} md={4} sm={24} xs={24} style={{
          textAlign: "center"
        }}>
          <h6 className="customer-report-title-back icon-charvlet-back" onClick={()=>this.props.showReportView("back")}><Icon type="left" className="text-color-4285F4"/>  Back to Customers</h6>
          <h1 className="customer-report-title">Reports</h1>
        </Col>
        
        
         
         {/** <Input
            value={this.state.search}
            placeholder="-- Search  Customer Name, Email,Contact No--"
            style={{ float: "left", width: "90%" }}
            onChange={this.searchOnchange}
          />
           {/** <Button onClick={this.onSerach} style={{ marginLeft: "11px" }} type="primary">
            Search
           </Button>   */}      
            
        <Col lg={1} md={1} sm={24} xs={24} style={{textAlign: "center"}}>
        <Divider type="vertical" className="vertical-divider-customer1" />
        </Col>
        <Col lg={5} md={5} sm={24} xs={24} style={{paddingLeft:"0px"}} >
          <h3 style={{marginTop:"bolder",marginTop:"11px", fontWeight:"bolder" }} className="capitalize">{this.state.customerName}</h3>
          <h6>Customer Type: {this.state.isPrivateCustomer ? "Private"  : "Corporate"}</h6>
        </Col>
        <Col lg={5} md={5} sm={24} xs={24}  >
        <h6 style={{marginTop:"11px"}} >Phone: {this.state.contactNumber}</h6>
        <h6 style={{marginTop:"11px"}} >E-mail: {this.state.email}</h6>
        </Col>
        <Col lg={9} md={9} sm={24} xs={24}  >
        <h6 style={{marginTop:"11px"}} >Address: {this.state.address}</h6>
        
        </Col>
      </Row>
     </Card>
        <Card className="filtter-card" bordered={false} bodyStyle={{ padding: "10px", paddingBottom: "0px" }}>
           <Row>
           {/** 
             <Col lg={3} md={3} sm={24} xs={24} style={{textAlign: "center",paddingLeft:'2%'}}>
                <h1 className="total-text-customer-report">{this.state.totalVisit} </h1>
                <h5>TOTAL VISITS</h5>
            </Col>
            <Col lg={1} md={1} sm={24} xs={24} style={{textAlign: "center"}}>
                <Divider type="vertical" className="vertical-divider-customer1" />
            </Col>
            <Col lg={3} md={3} sm={24} xs={24}  style={{textAlign: "center",paddingLeft:'0%'}}>
                <h1 className="text-color-4285F4" style={{paddingTop:"9%",fontSize:"27px"}}> {this.state.data['LAST VISIT']}</h1>
                <h5>LAST VISIT</h5>
            </Col>
            <Col lg={1} md={1} sm={24} xs={24} style={{textAlign: "center"}}>
               <Divider type="vertical" className="vertical-divider-customer1" />
           </Col>
            <Col lg={3} md={3} sm={24} xs={24} style={{textAlign: "center"}}>
                <h1 className="text-color-4285F4" style={{paddingTop:"9%",fontSize:"27px"}}>{this.state.data['Total Purchase Amount']}</h1>
                <h5>TOTAL PURCHASE AMOUNT</h5>
            </Col>
          */}
            <Col lg={3} md={3} sm={24} xs={24} style={{textAlign: "center",paddingLeft:'2%'}}>
                <h1 className="text-color-4285F4" style={{fontSize:"27px"}}>{this.state.totalVisit} </h1>
                <h5>TOTAL VISITS</h5>
            </Col>
            <Col lg={1} md={1} sm={24} xs={24} style={{textAlign: "center"}}>
                <Divider type="vertical" className="vertical-divider-customer1" />
            </Col>
            <Col lg={3} md={3} sm={24} xs={24}  style={{textAlign: "center",paddingLeft:'0%'}}>
                <h1 className="text-color-4285F4" style={{fontSize:"27px"}}> {this.state.data['LAST VISIT']}</h1>
                <h5>LAST VISIT</h5>
            </Col>
            <Col lg={1} md={1} sm={24} xs={24} style={{textAlign: "center"}}>
               <Divider type="vertical" className="vertical-divider-customer1" />
           </Col>
            <Col lg={5} md={5} sm={24} xs={24} style={{textAlign: "center"}}>
                <h1 className="text-color-4285F4" style={{fontSize:"27px"}}>{currencyConverter(this.state.data['Total Purchase Amount'])}</h1>
                <h5>TOTAL PURCHASE HISTORY</h5>
            </Col>
            <Col lg={11} md={11} sm={24} xs={24} style={{textAlign: "right"}}>    
                          
                {/* <Button className={this.state.btn1 ? "btn-for-total-text-customer-report-selected" :"btn-for-total-text-customer-report"} ghost onClick={()=> this.btnFiltter("Viewed")}>
                <h1 className= {this.state.btn1 ? "total-btn-customer-report-selected" :"total-btn-customer-report"} >{this.state.data['VIEWED']}</h1>
                <h6 className={this.state.btn1 ? "text-color-h6-selected" :"text-color-h6-btn"}>VIEWED</h6>
                </Button> */}
                <Button className={this.state.btn5 ? "btn-for-total-text-customer-report-selected" :"btn-for-total-text-customer-report"} ghost onClick={()=> this.btnFiltter("total")}>
                <h1 className= {this.state.btn5 ? "total-btn-customer-report-selected" :"total-btn-customer-report"} >{this.state.data['Total No. Items']} </h1>
                <h6 className={this.state.btn5 ? "text-color-h6-selected" :"text-color-h6-btn"}>VIEWED</h6>
                </Button>
                <Button className={this.state.btn2 ? "btn-for-total-text-customer-report-selected" :"btn-for-total-text-customer-report"} ghost onClick={()=> this.btnFiltter("Shared")}>
                <h1 className= {this.state.btn2 ? "total-btn-customer-report-selected" :"total-btn-customer-report"} >{this.state.data['SHARED']}</h1>
                <h6 className={this.state.btn2 ? "text-color-h6-selected" :"text-color-h6-btn"}>SHARED</h6>
                </Button>
                <Button className={this.state.btn3 ? "btn-for-total-text-customer-report-selected" :"btn-for-total-text-customer-report"} ghost onClick={()=> this.btnFiltter("Purchased")}>
                <h1 className= {this.state.btn3 ? "total-btn-customer-report-selected" :"total-btn-customer-report"} >{this.state.data['PURCHASED']}</h1>
                <h6  className={this.state.btn3 ? "text-color-h6-selected" :"text-color-h6-btn"} >PURCHASED</h6>
                </Button>
                <Button className={this.state.btn4 ? "btn-for-total-text-customer-report-selected" :"btn-for-total-text-customer-report"} ghost onClick={()=> this.btnFiltter("Quoted")}>
                <h1 className= {this.state.btn4 ? "total-btn-customer-report-selected" :"total-btn-customer-report"} >{this.state.data['QUOTED']} </h1>
                <h6 className={this.state.btn4 ? "text-color-h6-selected" :"text-color-h6-btn"}>QUOTED</h6>
                </Button>
                
            </Col>
            </Row>
        </Card>
         <Row>
         <Col lg={24} md={24} sm={24} xs={24} style={{textAlign: "right"}}>   
         
            <ExcelFile 
            element={<React.Fragment><p style={{paddingTop:"3px",float:"right"}}>Download Report</p><img style={{height:"42px",width:"41px",paddingBottom:"15px",cursor:"pointer",float:"right"}} src={ExportIcon}/></React.Fragment> }
         >
         <ExcelSheet data={this.state.allArray} name="Report">
               <ExcelColumn label="Date Time" value="date"/>
               <ExcelColumn label="RFID_Number" value="rfid_number"/>
               <ExcelColumn label="Bag_Number" value="sku_number"/>
               <ExcelColumn label="Dsg_Code" value="design_code"/>
               <ExcelColumn label="Suffix" value="suffix" />
               <ExcelColumn label="Size" value="sku_size" />
               <ExcelColumn label="Cust_Dsg_Cd" value="customer_design_category"/>
               <ExcelColumn label="Dsg_Ctg" value="design_category"/>
               <ExcelColumn label="Dsg_Col" value="design_color"/>
               <ExcelColumn label="Bag_Qty" value="sku_quantity"/>
               <ExcelColumn label="Sales_Ctg" value="sales_category"/>
               <ExcelColumn label="Set_Code" value="set_code" />
               <ExcelColumn label="Collection" value="sku_collection"/>
               <ExcelColumn label="Project" value="project" />
               <ExcelColumn label="Metal_Type" value="metal_type"/>
               <ExcelColumn label="Karatage" value="purity" />
               <ExcelColumn label="Unit_Of_Measurement" value="unit_of_measurement"/>
               <ExcelColumn label="Gross_Wgt" value="gross_weight"/>
               <ExcelColumn label="Net_Wgt" value="net_weight"/>
               <ExcelColumn label="Dia_Wgt" value="diamond_weight"/>
               <ExcelColumn label="Dia_Qty" value="diamond_quantity"/>
               <ExcelColumn label="Col_Stn_Wgt" value="color_stone_weight"/>
               <ExcelColumn label="Col_Stn_Qty" value="color_stone_quantity"/>
               <ExcelColumn label="Fnd_Wgt" value="finding_weight"/>
               <ExcelColumn label="Fnd_Qty" value="finding_quantity"/>
               <ExcelColumn label="Sales_Value" value="sales_value"/>
               <ExcelColumn label="Dia_Value" value="diamond_value"/>
               <ExcelColumn label="Col_Stn_Value" value="color_stone_value"/>
               <ExcelColumn label="Gold_Value" value="gold_value"/>
               <ExcelColumn label="Finding_Value" value="finding_value"/>
               <ExcelColumn label="Labour_Value" value="labour_value"/>
               <ExcelColumn label="Gold_Rate" value="gold_rate"/>
               <ExcelColumn label="Silver_Rate" value="silver_rate"/>
               <ExcelColumn label="Platinum_Rate" value="platinum_rate"/>
               <ExcelColumn label="Counter" value="counter" />
               <ExcelColumn label="Tray_Number" value="tray_number"/>
               <ExcelColumn label="Retail_Dia_Value" value="retail_diamond_value"/>
               <ExcelColumn label="Retail_Value" value="retail_value"/>
               <ExcelColumn label="Asking_Value" value="asking_value"/>
               <ExcelColumn label="Asking_Dia_Value" value="asking_diamond_value"/>
               <ExcelColumn label="Status" value="status"/>
         </ExcelSheet>
         </ExcelFile>
          
        
         </Col>
         </Row>
        <Card bodyStyle={{ padding: "10px 0px" }} bordered={false}>
         <Table className="gx-table-responsive"
                loading={this.state.tableLoading} 
                columns={this.columns} 
                dataSource={this.state.dataArray}
                size={"small"}
                rowKey={record => record._id}
                pagination={this.state.pagination}
               onChange={this.handleTableChange}    
            />
         </Card>


    </div>

        )
    }
}

CustomerReport = Form.create()(CustomerReport);
function mapStateToProps(state) {
  const {Common } = state
  const {get_cutomer_report:get_cutomer_report  } =  Common
  return {get_cutomer_report}
}

const AppContainer = connect( 
  mapStateToProps 
)(CustomerReport);


export default AppContainer;