import React, { Component } from 'react'
import IntlMessages from "util/IntlMessages";
import {connect} from "react-redux";
import moment from 'moment';
import {Card, Table,Form,Row,Col,DatePicker,Select,message,Divider,Modal,Button,AutoComplete,Input,Icon,Checkbox} from "antd";
//import AddCompany from "./addCompany1";
import AddCompany from "./addCompany";
import CountUp from 'react-countup';
import EditCompany from "./editCompany";
import {getCompany,
  downloadTextFile,
  //GetModuleDetails,
  //getUserWithLiancedDetailsExport
} from "../../../actions/scadmin";
import ReactExport from "react-data-export";
import AddImg from "./../../../assets/images/icons/add-customer.svg";
import EditMachineId from "./editMachineId";
import Gears from "../../../assets/images/gears.gif";
import AccessControl from "./accessControl"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CheckboxGroup = Checkbox.Group;
const OptGroup = AutoComplete.OptGroup;
const { MonthPicker, RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;
const CustomerList=[];
const plainOptions = ['Active','Deactive'];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};
const formItemLayout1 = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 11 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};
function compareByAlph (a, b) { if (a > b) { return -1; } if (a < b) { return 1; } return 0; }

class companyManagement extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      data:[],
      table_loading:true,
      search:'',
      loading:false,
      Export_data:[],
      tablevisible:true,
      companyEditVisible:false,
      addCompanyVisible:true
    };
   
  }
  componentDidMount() {
    const { dispatch } = this.props;  
    dispatch(getCompany());
    //dispatch(GetModuleDetails());
  }

  componentDidUpdate(prevProps, prevState) {
    var that = this;
    if (prevProps.company_list != that.props.company_list) {
      if(that.props.company_list.status)
      {
          this.setState({
            table_loading:false,
            data:that.props.company_list.data_array
          });
      }
    }
    if (prevProps.update_company_info != that.props.update_company_info) {
    //  console.log(that.props.update_company_details);
      if (that.props.update_company_info.status) {
        message.success("Company detalis update successfully");
    
        this.setState({
          companyEditVisible:false,
          tablevisible:true
        })
        const { dispatch } = this.props;
          dispatch(getCompany());
      } else {
        message.error("Company detalis not update");
      }
    }

    if (prevProps.user_license_details != that.props.user_license_details) {
      //  console.log(that.props.update_company_details);
        if (that.props.user_license_details.status) {          
          this.setState({
            loading:false,
            Export_data:that.props.user_license_details.data_array
          });
        } 
      }

      if (prevProps.update_company_access != that.props.update_company_access) {
        if (that.props.update_company_access.status) {
          message.success("Company access update successfully");
          const { dispatch } = this.props;
          dispatch(getCompany());
        } else {
          message.error(that.props.update_company_access.errors.msg);
        }
      }
    
  }

  showModal = () => {
    this.setState({
      visible: true,
      loading:true
    });
    const { dispatch } = this.props;
    //dispatch(getUserWithLiancedDetailsExport());  
  }

  downloadTextFile=(id)=>{
    const { dispatch} =this.props;
    // console.log(id);
    dispatch(downloadTextFile(id));  
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {dispatch} = this.props;
        this.setState({
          table_loading:true
         })
         let status=values.status;
         let companyType=values.companyType;
         if(values.status != undefined)
         {
           if(values.status.length === 0)
           {
            status= undefined
           }
         }
         if(values.companyType != undefined)
         {
           if(values.companyType.length === 0)
           {
             companyType= undefined
           }
         }
        let filtter=
          {
            "companyType": companyType,
            "status": status
        }
      // console.log(filtter);
       dispatch(getCompany(filtter));
      }
    });
  }

  clearfillter = (e) => {
    this.setState({
      table_loading:true
     })
    const {dispatch} = this.props;
    dispatch(getCompany());
    this.props.form.resetFields();
  }


  showEditModel =(e) => {
    this.setState({
      companyEditVisible:true,
      tablevisible: false,
      companyRecord: e
    })
  }  

  handleTableChange = (pagination, filters, sorter) => {
    let colome=sorter.columnKey;
    let order=sorter.order;
    let orderopp=0;
    let data=this.state.data;
    if(order!=undefined){
      if(order==="descend"){
        order=-1;
        orderopp=1;
      }else{
        order=1;
        orderopp=-1;
      }
     
      data.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
    }else{
      const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
      shuffleArray(data);
    }
   

   
    //temp1.sort((a, b) => (a.cName > b.cName) ? -1 : 1);
 
   };

   compantTableVisibility=(state)=>{
    this.setState({
      tablevisible: state,
      addCompanyVisible: state,
      companyEditVisible:false
    });
   }

   editComapanyVisible=(state)=>{
    this.setState({
      companyEditVisible:false,
      tablevisible:true
    });
   }

  handleCancel = () => {
    this.setState({
      visible: false,
      loading:true
    });
    
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    let data = this.state.data;
    let {loading} =this.state;
    const columns = [  
      {
        title: 'NAME',
        dataIndex: 'name',
        width: '10%',
        sorter: (a, b) => {},
      },
      {
        title: 'NO OF USER ALLOWED',
        dataIndex: 'noOfUserAllowed',
        width: '10%',
        sorter: (a, b) => {},
        render: (text, record) => {
          const { customer } = record;
          return (
            <span>
                {text}
            </span>
          );
        },
        
      },
      {
        title: 'LICENCE START DATE',
        dataIndex: 'licenseStartedDate',
        width: '10%',
        sorter: (a, b) => {},
      //  sorter: (a, b) => a.licenseStartedDate - b.licenseStartedDate,
        render: (text, record) => {
          const { customer } = record;
          return (
            <span>
                {moment(text).format("DD-MM-YYYY")}
            </span>
          );
        }
      },
      {
        title: 'LICENCE DAYS',
        dataIndex: 'licenseDays',
        width: '10%',
        sorter: (a, b) => {},
      // sorter: (a, b) => a.licenseDays - b.licenseDays,
        render: (text, record) => {
          const { customer } = record;
          return (
            <span>
            {text}
            </span>
          );
        }
        
      },
      {
        title: 'COMPANY TYPE',
        dataIndex: 'companyType',
        width: '10%',  
        sorter: (a, b) => {},
      },
      {
        title: 'PHONE',
        dataIndex: 'phone',
        width: '10%',   
        sorter: (a, b) => {}, 
      },
      // {
      //   title: 'license Days',
      //   dataIndex: 'licenseDays',
      //   width: '10%',    
      // },
      // {
      //   title: 'Address',
      //   dataIndex: 'address',
      //   width: '10%',
      //  // sorter: (a, b) => a.name - b.name,
      //   render: (text, record) => {
      //     const { customer } = record;
      //     return (
      //       <span>
      //        {text}
      //       </span>
      //     );
      //   }
      // },
      {
        title: 'Action',
        dataIndex: '_id',
        width: '10%',
        render: (text, record) => {
          const {phone,machineId,accessInfo,username,_id} = record;
          return (
            <span>
                  <Icon type="file" onClick={()=>this.downloadTextFile(phone)} style={{fontSize: 20,cursor:"pointer",float:"left"}}/>  
                  
                  <EditMachineId machineId={machineId} phone={phone}/>
                  <AccessControl phone={phone} />
                  <Icon type="edit" onClick={()=>this.showEditModel(record)} style={{fontSize: 20,cursor:"pointer",paddingLeft: "10px"}}/>  
            </span>
          );
        },
      }
    ];
		if (this.state.search) {
			data = data.filter(row => {
        //let datef=moment(row.licenseStartedDate).format("DD-MM-YYYY");
        return row.name.toUpperCase().includes(this.state.search.toUpperCase()) || row.phone.includes(this.state.search) 
        //datef.toUpperCase().includes(this.state.search.toUpperCase()) || row.name.toUpperCase().includes(this.state.search.toUpperCase()) ||   String(row.companyType).toUpperCase().includes(this.state.search.toUpperCase())  || row.phone.includes(this.state.search) 
        //||  row.noOfUserAllowed.includes(this.state.search)
        //row.name.toUpperCase().includes(this.state.search.toUpperCase()) row.noOfUserAllowed.includes(this.state.search) ||
        // /|| row.licenseDays.includes(this.state.search)
      })
		}
    return (
      <div>
      <Card className="customer-top-menu-card" bodyStyle={{ padding: "0" }} bordered={false}>
      <Row>
        <Col lg={4} md={4} sm={24} xs={24} style={{
          textAlign: "center"
        }}>
          <h1 className="customer-title">Company</h1>
        </Col>
      
        <Col lg={3} md={3} sm={24} xs={24} style={{textAlign: "center"}}>
          <Divider type="vertical" className="vertical-divider-customer" />
          <h1 className="total-text"><CountUp start={0}  end={data.length}/> </h1>
        </Col>
        <Col lg={1} md={1} sm={24} xs={24} style={{textAlign: "center"}}>
            <Divider type="vertical" className="vertical-divider-customer1" />
       </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <p style={{ marginBottom: "5px" }} className="transaction-serach-text text-color-8c8c8c">Search</p>
          <Input
            value={this.state.search}
            placeholder="-- Search Name, Phone No --"
            style={{ float: "left", width: "85%" }}
            onChange={e => this.setState({ search: e.target.value })}
         //  onChange={this.searchOnchange}
          />
         {/**    <Button onClick={this.onSerach} style={{ marginLeft: "11px" }} type="primary" disabled={this.state.btnsearch}>
            Search
     </Button>
   */}

        </Col>
        <Col lg={1} md={1} sm={24} xs={24} style={{textAlign: "center"}}>
        <Divider type="vertical" className="vertical-divider-customer1" />
        </Col>
        <Col lg={3} md={3} sm={24} xs={24} style={{textAlign: "center"}} >
            {/**    <AddCompany compantTableVisibility={this.compantTableVisibility}/>*/}
            <div style={{float:"left"}}>
             <img className="add-customer-icon cursor-pointer" onClick={()=> this.compantTableVisibility(false)} src={AddImg} />
             <h5 className="text-color-8c8c8c cursor-pointer" onClick={()=> this.compantTableVisibility(false)}>Add Company</h5>
            </div>
            </Col>
      </Row>
    </Card>

               
            {/**     <Form onSubmit={this.handleSearch} >
                 <Button onClick={this.showModal} style={{marginLeft:"10px"}} >Export Users</Button>
        <Modal
          title={loading ?`Data is processing please wait ...` :`User File Created`}  
         visible={this.state.visible}
         //onOk={this.handleOk}
       //  confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        // style={{backgroundImage: `url('https://www.codeproject.com/KB/aspnet/1095434/gears.gif')`}}
          footer={[
            <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
            <ExcelFile filename={`User Details - ${new Date()}`} element={
              loading ? <Button key="submit" type="primary" onClick={this.handleOk} disabled>Wait..  </Button>:
              <Button key="submit" type="primary" onClick={this.handleOk} > Download</Button>
            } 
              >
            <ExcelSheet data={this.state.Export_data} name="Users">
                      <ExcelColumn label="firstname" value="firstname"/>
                      <ExcelColumn label="lastname" value="lastname"/>
                      <ExcelColumn label="UserName" value="username"/>
                      <ExcelColumn label="password" value="password"/>
                      <ExcelColumn label="usertype" value="usertype"/>                     
                      <ExcelColumn label="Company" value="company"/>
                      <ExcelColumn label="Branch" value="Branch"/>
                      <ExcelColumn label="license Start Date" value="LocenseStartDate"/>
                      <ExcelColumn label="license Days" value="licenseDays"/>
                      <ExcelColumn label="address" value="address"/>
                      <ExcelColumn label="location" value="location"/>
                                               
              </ExcelSheet>
            </ExcelFile>
            ,
          ]}
        >
        
        {loading ?
          <div style={{minHeight:"150px" ,backgroundImage: `url(${Gears})`}}>          
          </div>
          :
          <Alert
            message="Success"
            description="Users file is ready you can download."
            type="success"
            showIcon
          />
         }
        </Modal>
                  <Card  size="small"
                  title="Filter">
                    <FormItem
                   // style={{marginLeft:"0px"}}
                    label="By Status"
                    {...formItemLayout}
                    >
                      {getFieldDecorator('status', {
                        rules: [{ required: false,  message: 'Please select status!' },      
                    ],
                    },                            
                    )(
                        <Checkbox.Group style={{ width: '100%' }}>                         
                            <Col span={24}><Checkbox value="Active">Active</Checkbox></Col>
                            <Col span={24}><Checkbox value="Deactive">Deactive</Checkbox></Col>
                          
                        </Checkbox.Group>
                    )}
                    </FormItem>
                      

                    <FormItem
                  //  style={{marginLeft:"0px"}}
                    label="By Company Type"
                    {...formItemLayout1}
                    >
                      {getFieldDecorator('companyType', {
                        rules: [{ required: false,  message: 'Please select status!' },      
                    ],
                    },                            
                    )(
                          <Select
                         mode="multiple"
                          placeholder="Please select company type"
                          style={{ width: '100%' }}
                        >
                            <Option key="Retailers">Retailers</Option>
                            <Option key="Wholesaler">Wholesaler</Option>
                            <Option key="Manufacturer">Manufacturer</Option>
                        </Select>
                    )}
                    </FormItem>
             
                  <FormItem  style={{marginLeft:"5px"}}>
                  <Button  type="default" onClick={this.clearfillter}>Clear All</Button>
                  <Button  type="primary"  htmlType="submit">Apply</Button>
                 </FormItem>
              </Card> 
              </Form>
               */}
             {this.state.tablevisible === true?
               <Card bodyStyle={{padding:"0px",paddingTop:"10px"}} bordered={false} >               
                    <Table className="gx-table-responsive" 
                        loading={this.state.table_loading} 
                        columns={columns} 
                        dataSource={data}
                        size={"small"}
                        rowKey={record => record._id}
                        pagination={15}
                        onChange={this.handleTableChange}
                        
                      />
                  </Card>
                  
                  :
                  <React.Fragment>
                  {this.state.addCompanyVisible ? "" :<AddCompany compantTableVisibility={this.compantTableVisibility}/> }
                  </React.Fragment>
                }
               {this.state.companyEditVisible === true ? <EditCompany editComapanyVisible={this.editComapanyVisible} companyRecord={this.state.companyRecord} /> : ""}
              {/* <EditCompany/> */}
      </div>
    )
  }
}

companyManagement = Form.create()(companyManagement);
function mapStateToProps(state) {
  const {scadmin } = state
  const {company_list:company_list,update_company_details:update_company_details,user_license_details:user_license_details,update_company_access:update_company_access,
    update_company_info:update_company_info} =  scadmin
  return {company_list,update_company_details,user_license_details,update_company_access,update_company_info}
}

const AppContainer = connect( 
  mapStateToProps 
)(companyManagement);


export default AppContainer;


