import React, { Component } from 'react'
import { Modal,Icon,List, Button } from 'antd';
export default class View extends Component {
    state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    // console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    // console.log(e);
    this.setState({
      visible: false,
    });
  };
    render() {
        return (
            <div>
            <Icon type="eye" theme="filled" onClick={this.showModal} style={{fontSize: 20,float:"left"}} />
            <Modal
            title="Customer  Details"
            visible={this.state.visible}
            // okButtonDisabled={true}
            //cancelText="Close"
             onCancel={this.handleCancel}
            footer={[
                <Button key="submit" type="primary"  onClick={this.handleCancel}>
                   Close
                </Button>
              ]}
          >
                <List
               // header={<div>Header</div>}
               // footer={<div>Footer</div>}
               size="small"
                bordered
                dataSource={this.props.record}
                renderItem={item => (
                    <React.Fragment>
                    <List.Item><span style={{color:"#313030"}}>Name</span>:- {item.name}</List.Item>
                    <List.Item><span style={{color:"#313030"}}>Contact No</span>    :- {item.contactNumber}</List.Item>
                    <List.Item><span style={{color:"#313030"}}>E-mail</span>       :- {item.email}</List.Item>
                    <List.Item><span style={{color:"#313030"}}>Company Name</span>  :- {item.companyName}</List.Item>
                    <List.Item><span style={{color:"#313030"}}>GST No</span>          :- {item.gst}</List.Item>
                    <List.Item><span style={{color:"#313030"}}>PAN No</span>        :- {item.pan}</List.Item>
                    <List.Item><span style={{color:"#313030"}}>Address</span>      :- {item.address}</List.Item>

                    </React.Fragment>
                )}
                />
          </Modal>
            </div>
        )
    }
}
