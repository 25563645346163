import React, { Component } from 'react'
import {Button, Checkbox, Form, Icon, Input, message} from "antd";
import {connect} from "react-redux";
import IntlMessages from "util/IntlMessages";
import {forgotPassword }  from "../actions/index";
import CircularProgress from "components/CircularProgress/index";
const FormItem = Form.Item;
class Forgotpass extends Component {

  constructor() {
    super();
    this.state={
      loader:false
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    try {
    
    if(prevProps.forgot_pass!=this.props.forgot_pass)
    {
     // console.log(this.props.forgot_pass);
       if(this.props.forgot_pass.status)
       {
        message.success("Your password has been reset successfully! For Generate your new password link has been sent to email address");
       }else{
        message.error(this.props.forgot_pass.errors.msg);
       }
       this.setState({
        loader:false
      });
    }
  }   catch (error) {
    console.log(error);
    }
  }

  forgotpass=(e)=>{
    console.log()
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {    
        this.setState({
          loader:true
        });
        const {dispatch} = this.props;
        dispatch(forgotPassword(values)); 
      }
    });
  };

    render() {
      const {getFieldDecorator} = this.props.form;
      const {loader} = this.state;
        return (
          <React.Fragment>
          <div className="gx-mb-4">
            <h2>Forgot Your Password ?</h2>
            <p>Don't worry. Recovering the password is easy.Just tell us the email you have registered with JewelTrace</p>
          </div>
        
            <Form layout="vertical" onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0">
            <FormItem>
            {getFieldDecorator('username', {
            initialValue: this.props.username,
              rules: [{
                required: true, message: 'Please input username',
              }],
            })(
              <Input type="text" placeholder="Username" />
            )}
          </FormItem>

            <FormItem>
              {getFieldDecorator('email', {
                rules: [{
                  type: 'email', message: 'The input is not valid E-mail!',
                }, {
                  required: true, message: 'Please input your E-mail!',
                }],
              })(
                <Input type="email" placeholder="E-Mail Address"/>
              )}
            </FormItem>
           
            <FormItem style={{marginBottom:"0px"}}>
              <Button type="primary" htmlType="submit" className="loginbtn"style={{width:"100%",borderRadius:"5px",minHeight:"40px",marginTop:"9px"}}>
                SEND
              </Button>
            </FormItem>
            <center style={{paddingBotton:"7%",marginBottom:"25px",marginTop:"40px"}}> <a  onClick={this.props.forgotpass} >Sign In</a>  </center>
          </Form>
          {loader ?
            <div className="gx-loader-view">
              <CircularProgress/>
            </div> : null}    
          </React.Fragment>
        )
    }
}

Forgotpass = Form.create()(Forgotpass);

function mapStateToProps(state) {
  const {Common } = state
  const {forgot_pass:forgot_pass  } =  Common
  return {forgot_pass}
}

const AppContainer = connect( 
  mapStateToProps 
)(Forgotpass);
export default AppContainer;