import { addSingleStock } from "../../../actions/index";
import { connect } from "react-redux";
import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Upload,
} from "antd";

class AddSku extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
      keys: [],
      fields: [],
      showAllFields: false,
      imageName: "",
      imageMappingField: localStorage.getItem("imageMapping"),
    };
  }

  componentDidMount() {
    let newFields = JSON.parse(localStorage.getItem("DetailView"));
    newFields.filter((field) => {
      if (field.name === "rfid_number") {
        field.isSelected = true;
      }
    });
    this.setState({
      fields: newFields,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.add_single_stock != this.props.add_single_stock) {
      try {
        if (this.props.add_single_stock.status) {
          message.success("Added sku details successfully");
          this.setState({
            loading: false,
          });
          this.props.closeAddSku(true);
        } else {
          message.error(this.props.add_single_stock.errors.msg);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleChange = (info) => {
    const { form } = this.props;
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // console.log("Picked file details ---- ", info.file.originFileObj);
      // Get this url from response in real world.
      this.setState({ imageName: info.file.originFileObj.name });
      this.getBase64(info.file.originFileObj, (imageBase64) => {
        form.setFieldsValue({ imageBase64 });
        this.setState({
          loading: false,
        });
      });
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.props.form.resetFields();
    this.props.closeAddSku();
    this.setState({
      showAllFields: false,
    });
  };

  handleAddSku = (e) => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        // console.log("Error Adding sku ", err);
        return;
      }

      const { dispatch } = this.props;
      values.root = this.props.rootInfo;
      // console.log("image path --- ", values.imageBase64);
      if (values.imageBase64 !== undefined) {
        let imgPath = values.imageBase64;
        values.imageBase64 = imgPath.split(",")[1]; //().substring(1);
        values.imageName = this.state.imageName;
      } else {
        values.imageBase64 = "";
        values.imageName = "";
      }
      this.setState({
        loading: true,
      });
      const dataArray = [];
      dataArray.push(values);
      dispatch(addSingleStock(dataArray));
    });
  };

  moreFieldsClicked = (e) => {
    if (this.state.showAllFields)
      this.setState({
        showAllFields: false,
      });
    else
      this.setState({
        showAllFields: true,
      });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { loading, imageMappingField } = this.state;
    const imageBase64 = getFieldValue("imageBase64");
    const uploadButton = (
      <div>
        {loading ? (
          <Icon type='loading' />
        ) : (
          <div>
            {imageBase64 ? (
              <img src={imageBase64} alt='avatar' style={{ width: "100%" }} />
            ) : (
              <React.Fragment>
                <Icon type='plus' />
                <div className='ant-upload-text'>Upload</div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );

    // console.log("this.state.fields",this.state.fields)
    const formItems = this.state.fields.map((k, index) => (
      <React.Fragment>
        {(!this.state.showAllFields &&
          !k.isSelected &&
                (k.name === "sku_number" || k.name !== "rfid_number" || k.name !== imageMappingField)) ||
        k.name === "rm_value" ? (
          ""
        ) : (
          <Col span={6}>
            {/* {console.log("k.name", k.name)} */}
            <Form.Item
              label={k.header}
              key={k}
              // hidden={!this.state.showAllFields && !k.isSelected && k.name != "rfid_number"}
            >
              {getFieldDecorator(`${k.name}`, {
                rules: [
                  {
                    required:
                          k.name === "sku_number" || k.name === "rfid_number" || k.name == imageMappingField
                        ? true
                        : false,
                    message: `Please input ${k.name}`,
                  },
                ],
              })(<Input placeholder={`Enter ${k.name}`} />)}
            </Form.Item>
          </Col>
        )}
      </React.Fragment>
    ));
    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return (
      <Modal
        title='Insert sku details'
        visible={this.props.visibleForAddSku}
        onOk={this.uploadhandleSyncOk}
        //     confirmLoading={confirmLoading}
        style={{ paddingBottom: "0px" }}
        width='50%'
        onCancel={this.handleCancel}
        footer={[
          <span style={{ marginBottom: "20px" }}>
            {/* <h6
              className='customer-report-title-back icon-charvlet-back'
              onClick={this.moreFieldsClicked}>
              {" "}
              {this.state.showAllFields ? "Show Less" : "More Fields"}
            </h6> */}
            <Button
              key='back'
              onClick={this.handleCancel}
              style={{ minWidth: "77px" }}
              ghost>
              Cancel
            </Button>
            <Button
              key='submit'
              type='primary'
              onClick={this.handleAddSku}
              style={{ minWidth: "77px" }}>
              Add
            </Button>
          </span>,
        ]}>
        <React.Fragment>
          <Form onSubmit={this.FormSubmit} {...formItemLayout}>
            <Spin spinning={this.state.loading}>
              <Row type='flex'>
                <Col md={8} sm={24} xs={24}>
                  <Form.Item
                    wrapperCol={24}
                    className={`height-${100}`}
                    label='Inventory Image'>
                    {getFieldDecorator("imageBase64", {
                      rules: [
                        {
                          required: false,
                          message: "Please select image!",
                        },
                      ],
                    })(
                      <Upload
                        name='avatar'
                        listType='picture-card'
                        className='avatar-uploader'
                        style={{ paddingLeft: "10px" }}
                        showUploadList={false}
                        onChange={this.handleChange}>
                        {uploadButton}
                      </Upload>
                    )}
                    {imageBase64 && (
                      <Upload
                        showUploadList={false}
                        style={{ width: "128px", height: "128px" }}
                        onChange={this.handleChange}>
                        <Button> Replace Image</Button>
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row> {formItems} </Row>
            </Spin>
          </Form>
        </React.Fragment>
      </Modal>
    );
  }
}

AddSku = Form.create()(AddSku);

function mapStateToProps(state) {
  const { Common } = state;
  const { add_single_stock: add_single_stock } = Common;
  return { add_single_stock };
}

const AppContainer = connect(mapStateToProps)(AddSku);
export default AppContainer;
