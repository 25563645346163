import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { getStockTally, getAllUserList, getStockTallyExport } from "../../../actions/index";
import moment from "moment";
import {
  Card,
  Table,
  Form,
  Row,
  Col,
  DatePicker,
  Badge,
  Input,
    Button,
    Alert,
    Icon,
    Modal,
  Divider,
  message,
  Select,
} from "antd";
import { sortDirections } from "../../../containers/App/commanFunction";
import Gears from "../../../assets/images/gears.gif";
import ViewItem from "./viewItem";
import "./style.css";
import ReactExport from "react-data-export";
import socket from "./../../../containers/App/global";
import CountUp from "react-countup";
import ExportIcon from "./../../../assets/images/icons/export.svg";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";

const Option = Select.Option;
const { RangePicker } = DatePicker;
var cookies = require("browser-cookies");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Stocktally extends Component {
    constructor(props) {
    super(props);
    this.state = {
      data: [],
      table_loading: true,
      search: "",
        dataExport: [],
      totalData: 0,
        userList: [],
        loading: true,
      empName: "",
      scanSessionName: "",
      scanSessions: [],
    };
    }

  componentDidMount() {
    const { dispatch } = this.props;
    let now = new Date();
    let starDate = (fromDate = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ).subtract(30, "days"));
    let fromDate = starDate.toISOString();
    let toDate = new Date().toISOString();
    let empName = "";
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
    });
      let page_no = 0;
      let limit = 10;
      dispatch(getStockTally(page_no, limit, fromDate, toDate, empName));

    let usertype = localStorage.getItem("type_user");
    let root = JSON.parse(cookies.get("root"));
    if (usertype === "branchAdmin") {
      dispatch(getAllUserList("branch", root.branchId));
    } else if (usertype === "companyAdmin") {
      dispatch(getAllUserList("company", root.companyId));
    } else if (usertype === "floorAdmin") {
      dispatch(getAllUserList("floor", root.floorId));
    }
    socket.on("Notification", (col) => {
      try {
        if (col.data_code === "501") {
          let newData = col.data_obj;
          let Data = this.state.data;
          Data.unshift(newData);
          let pagination = {};
          pagination.total = this.state.pagination.total + 1;
          this.setState({
            data: Data,
            totalData: this.state.totalData + 1,
            pagination,
          });
        }
      } catch (e) {
        console.log("error", e);
      }
    });
  }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.stocktally_exportdetails !== this.props.stocktally_exportdetails) {
            const url = this.props.stocktally_exportdetails.fileUrl;
            console.log("urladsrds:", url);

            window.open(url, '_blank');

            this.setState({
                table_loading: false,
            });
        }

      if (prevProps.stocktally_details !== this.props.stocktally_details) {
          if (this.props.stocktally_details.status) {
              let json = this.props.stocktally_details.data_array;
              let pagination = {};
              pagination.total = this.props.stocktally_details.data.totalData;
              pagination.pageSize = 10;
              this.setState({
                  data: json,
                  pagination,
                  totalData: this.props.stocktally_details.data.totalData,
              });

              let dataExport = this.props.stocktally_details.data_array;
              let skuItemsFoundArray = [];
              let skuItemsNotFoundArray = [];
              let extraTagArray = [];
              let skuItemsSoldArray = [];

              for (let index = 0; index < dataExport.length; index++) {
                  let element = dataExport[index];
                  let date = moment(element.createdAt).format("DD-MM-YYYY HH:mm A");

                  // For skuItemsFound
                  let skuItemsFound = element.skuItemsFound;
                  skuItemsFound.forEach((sku) => {
                      skuItemsFoundArray.push({
                          date: date,
                          skuItemsFound: sku.trim(),
                      });
                  });

                  // For skuItemsNotFound
                  let skuItemsNotFound = element.skuItemsNotFound;
                  skuItemsNotFound.forEach((sku) => {
                      skuItemsNotFoundArray.push({
                          date: date,
                          skuItemsNotFound: sku.trim(),
                      });
                  });

                  // For extraTag
                  let extraTag = element.extraTag;
                  extraTag.forEach((tag) => {
                      extraTagArray.push({
                          date: date,
                          extraTag: tag.trim(),
                      });
                  });

                  // For skuItemsSold
                  let skuItemsSold = element.skuItemsSold;
                  skuItemsSold.forEach((sku) => {
                      skuItemsSoldArray.push({
                          date: date,
                          skuItemsSold: sku.trim(),
                      });
                  });
              }

              this.setState({
                  dataExportArray: {
                      skuItemsFound: skuItemsFoundArray,
                      skuItemsNotFound: skuItemsNotFoundArray,
                      extraTag: extraTagArray,
                      skuItemsSold: skuItemsSoldArray,
                  },
                  table_loading: false,
                  loading: false,
              });
          }
      }



    if (prevProps.user_list != this.props.user_list) {
      if (this.props.user_list.status) {
        this.setState({
          userList: this.props.user_list.data_array,
        });
      } else {
        this.setState({
          userList: [],
        });
      }
    }
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dispatch } = this.props;
        // let userdata=values;
      }
    });
  };


    showModal = () => {
        this.setState({
            visible: true,
        });
        const { dispatch } = this.props;
        let now = new Date();
        let starDate = (fromDate = moment(
            new Date(now.getFullYear(), now.getMonth(), now.getDate())
        ).subtract(30, "days"));
        let fromDate = starDate.toISOString();
        let toDate = new Date().toISOString();
        let empName = "";
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
        });
        let page_no = 0;
        let limit = 10;
        dispatch(getStockTally(page_no, limit, fromDate, toDate, empName));
    };

    openPdf = (record) => {
        const { dispatch } = this.props;
        console.log("record:", record);
        dispatch(getStockTallyExport(record));
        this.setState({
            table_loading: true,
        });
        //window.open(record.historypath);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            loading: true,
        });
    };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      table_loading: true,
      pagination: pager,
    });
      const { dispatch } = this.props;
    dispatch(
      getStockTally(
          pager.current - 1,
          10,
        this.state.fromDate,
        this.state.toDate,
        this.state.empName,
        sorter.columnKey,
        sorter.order
      )
    );

    // let tableViewdata=this.state.TableViewData;
    // if(order!=undefined){
    //   if(order==="descend"){
    //     order=-1;
    //     orderopp=1;
    //   }else{
    //     order=1;
    //     orderopp=-1;
    //   }
    //   tableViewdata.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
    // }else{
    //   const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
    //   shuffleArray(tableViewdata);
    // }
    };

  btnFiltter = () => {
    this.setState({
      table_loading: true,
    });

    this.props.form.validateFields((err, values) => {
      // console.log("Values --", values);
      if (err) {
        // console.log("Error --", err);
        return;
      }
      const { dispatch } = this.props;
      const pager = { ...this.state.pagination };
      pager.current = 1;
      pager.pageSize = 10;

      this.setState({
        pagination: pager,
        table_loading: true,
        pageSizelimit: 10,
        currantPage: pager.current - 1,
      });
      let fromDate = "";
      let toDate = "";
      let empName = "";
      if (values.date.length > 0) {
        fromDate = values.date[0].toISOString();
        toDate = values.date[1].toISOString();
        this.setState({
          fromDate: fromDate,
          toDate: toDate,
        });
      }
      if (values.empName != undefined && values.empName.length > 0) {
        empName = values.empName;
        this.setState({ empName: empName });
      }

      dispatch(getStockTally(0, 10, fromDate, toDate, empName));
    });
  };

  onClearFiltter = (e) => {
    this.setState({
      table_loading: true,
      search: "",
    });
    const { dispatch } = this.props;
    let now = new Date();
    let starDate = (fromDate = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ).subtract(30, "days"));
    let fromDate = starDate.toISOString();
    let toDate = new Date().toISOString();
    let empName = "";
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
      empName: empName,
    });
    dispatch(getStockTally(0, 10, fromDate, toDate, empName));
    this.props.form.resetFields();
  };

  render() {
    var heightd = window.innerHeight - 480;
      const { getFieldDecorator } = this.props.form;
      const { loading } = this.state;
    const now = new Date();
    const fromDate = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ).subtract(30, "days");
    const formItemLayout = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
  let data = this.state.data;

      this.columns = [
          {
              title: "DATE",
              dataIndex: "createdAt",
              width: "12%",
              sorter: (a, b) => { },
              render: (text, record) => {
                  var datef = moment(text).format("DD-MM-YYYY hh:mm A");
                  //  s = s.substring(0, s.lastIndexOf('/'));
                  {
                      /* var datef=moment(text).utc(false).format("DD-MM-YYYY hh:mm A");
                    var datef1=moment(text).format("DD-MM-YYYY hh:mm A");
                      //  s = s.substring(0, s.lastIndexOf('/'));
                    return (
                      <span>
                          UTC:-{datef}  || Local {datef1}        
                      </span>
                    );*/
                  }
                  return <span>{datef}</span>;
              },
          },
          {
              title: "DETECTED",
              dataIndex: "skuItemsFound",
              width: "10%",
              sorter: (a, b) => { },
              render: (text, record) => {
                  const { createdAt } = record;
                  let datef = moment(createdAt).format("DD-MM-YYYY hh:mm A");
                  let newO = Object.assign({}, text);
                  var result = Object.keys(newO).map(function (key) {
                      return [newO[key]];
                  });
                  const multiDataSet = [
                      {
                          columns: ["RFID No"],
                          data: result,
                      },
                  ];
                  return (
                      <span>
                          <ViewItem
                              date={datef}
                              name={"Detected"}
                              multiDataSet={multiDataSet}
                              itemArray={text}
                              itemArray={text}
                              stylu={{
                                  backgroundColor: "#1F628E",
                                  minWidth: "45px",
                                  boxShadow: "0 0 0 1px #d9d9d9 inset",
                                  marginTop: "2px",
                                  cursor: "pointer",
                              }}
                          />
                      </span>
                  );
              },
          },
          {
              title: "UNDETECTED",
              dataIndex: "skuItemsNotFound",
              width: "10%",
              sorter: (a, b) => { },
              render: (text, record) => {
                  const { createdAt } = record;
                  let datef = moment(createdAt).format("DD-MM-YYYY hh:mm A");
                  let newO = Object.assign({}, text);
                  var result = Object.keys(newO).map(function (key) {
                      return [newO[key]];
                  });
                  const multiDataSet = [
                      {
                          columns: ["RFID No"],
                          data: result,
                      },
                  ];
                  return (
                      <span>
                          <ViewItem
                              date={datef}
                              name={"Undetected"}
                              multiDataSet={multiDataSet}
                              itemArray={text}
                              stylu={{
                                  backgroundColor: "#76B9F0",
                                  minWidth: "45px",
                                  marginTop: "2px",
                                  cursor: "pointer",
                              }}
                          />
                      </span>
                  );
              },
          },
          {
              title: "ADDITIONAL",
              dataIndex: "extraTag",
              width: "10%",
              sorter: (a, b) => { },
              render: (text, record) => {
                  const { createdAt } = record;
                  let datef = moment(createdAt).format("DD-MM-YYYY hh:mm A");
                  let newO = Object.assign({}, text);
                  var result = Object.keys(newO).map(function (key) {
                      return [newO[key]];
                  });
                  const multiDataSet = [
                      {
                          columns: ["RFID No"],
                          data: result,
                      },
                  ];
                  return (
                      <span>
                          <ViewItem
                              date={datef}
                              name={"Additional"}
                              multiDataSet={multiDataSet}
                              itemArray={text}
                              stylu={{
                                  backgroundColor: "#CADEEF",
                                  marginTop: "2px",
                                  minWidth: "45px",
                                  cursor: "pointer",
                              }}
                          />
                      </span>
                  );
              },
          },
          {
              title: "SOLD",
              dataIndex: "skuItemsSold",
              width: "10%",
              sorter: (a, b) => { },
              render: (text, record) => {
                  const { createdAt } = record;
                  let datef = moment(createdAt).format("DD-MM-YYYY hh:mm A");
                  let newO = Object.assign({}, text);
                  var result = Object.keys(newO).map(function (key) {
                      return [newO[key]];
                  });
                  const multiDataSet = [
                      {
                          columns: ["RFID No"],
                          data: result,
                      },
                  ];
                  return (
                      <span>
                          <ViewItem
                              date={datef}
                              name={"Sold"}
                              multiDataSet={multiDataSet}
                              itemArray={text}
                              stylu={{
                                  backgroundColor: "#9BD4E4",
                                  marginTop: "2px",
                                  minWidth: "45px",
                                  cursor: "pointer",
                              }}
                          />
                      </span>
                  );
              },
          },
          {
              title: "ACTION",
              dataIndex: "_id",
              width: "8%",
              render: (text, record) => {
                  console.log("recd:", record.historyId);
                  return (
                      <Icon
                          type="file-pdf"
                          onClick={() => this.openPdf(record.historyId)}
                          style={{
                              cursor: "pointer",
                              fontSize: 20,
                              paddingLeft: "10px",
                          }}
                      />
                  );
              },
          },
      ];

    if (this.state.search) {
      data = data.filter((row) => {
        let datef = moment(row.createdAt).format("DD-MM-YYYY HH:mm A");
        return datef.toUpperCase().includes(this.state.search.toUpperCase());
        //row.name.toUpperCase().includes(this.state.search.toUpperCase())
      });
    }
    return (
      <div>
        <Card className='custome-top-menu-card' bordered={false}>
          <Row>
            <Col
              lg={5}
              md={5}
              sm={24}
              xs={24}
              style={{
                textAlign: "center",
              }}>
              <p className='transaction-title'>Reports</p>
              <h1 className='transaction-sub-title'>Inventory Scan</h1>
            </Col>

            <Col lg={3} md={3} sm={24} xs={24} style={{ textAlign: "center" }}>
              <Divider type='vertical' className='vertical-divider-stock' />
              <h1 className='total-text'>
                <CountUp start={0} end={this.state.totalData} />
              </h1>
            </Col>

            <Col lg={1} md={1} sm={24} xs={24} style={{ textAlign: "center" }}>
              <Divider type='vertical' className='vertical-divider-customer1' />
            </Col>
            <Col lg={10} md={10} sm={24} xs={24}>
              {/**

                             <p style={{ marginBottom: "5px" }} className="transaction-serach-text text-color-8c8c8c">Search</p>
                             <Input
                             value={this.state.search}
                             placeholder="-- Search --"
                             style={{ float: "left", width: "50%" }}
                             onChange={e => this.setState({ search: e.target.value })}
                             />
                             <Button onClick={this.onSerach} style={{ marginLeft: "11px" }} type="primary">
                             Search
                             </Button>    */}
            </Col>

            <Col
              lg={1}
              md={1}
              sm={24}
              xs={24}
              style={{ textAlign: "center" }}
              className='cursor-pointer'></Col>

            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}
              style={{ textAlign: "center" }}
              className='cursor-pointer'>
                        <img
                            className='export-icon'
                            onClick={this.showModal}
                            src={ExportIcon}
                        />
                        <h5 className='text-color-8c8c8c'>Export Inventory Scan</h5>
            </Col>
          </Row>
        </Card>

        <Form hideRequiredMark={true} colon={false} labelAlign={"left"}>
          <Card
            className='filtter-card'
            bordered={false}
            bodyStyle={{
              padding: "10px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}>
            <Row>
              <Col lg={2} md={2} sm={24} xs={24} className='text-aligin-center'>
                <img src={FilterIcon} className='filter-icon' />
              </Col>
              <Col lg={5} md={5} sm={24} xs={24} style={{ paddingLeft: "0px" }}>
                <Form.Item label={`Date`} {...formItemLayout}>
                  {getFieldDecorator(`date`, {
                    initialValue: [
                      moment(fromDate, "DD-MM-YYYY"),
                      moment(new Date(), "DD-MM-YYYY"),
                    ],
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <RangePicker
                      format='DD-MM-YYYY'
                      placeholder={["From ", "To"]}
                      style={{ minWidth: "100%" }}
                      separator='-'
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label='Select User' {...formItemLayout}>
                  {getFieldDecorator(`empName`, {
                    rules: [{ required: false }],
                  })(
                    <Select
                      placeholder='Please Select user'
                      style={{ minWidth: "100%" }}>
                      {this.state.userList.map((data, index) => (
                        <Option key={data.empId} value={data._id}>
                          {data.lastname} {data.firstname}
                        </Option>
                      ))}
                    </Select>
                    /*<Select
                                        //mode="tags"
                                        placeholder="- - Select - -" style={{minWidth: "100%"}}>

                                        {this.state.userList.map(user => (
                                            <Option key={data.username}
                                                    value={data.username}>{data.lastname} {data.firstname}</Option>
                                        ))}

                                    </Select>*/
                  )}
                </Form.Item>
              </Col>
              {/* <Col lg={4} md={4} sm={24} xs={24}>
                <Form.Item label='Select Scan Session' {...formItemLayout}>
                  {getFieldDecorator(`empName`, {
                    rules: [{ required: false }],
                  })(
                    <Select
                      placeholder='Please Select Scan Session'
                      style={{ minWidth: "100%" }}>
                      {this.state.userList.map((data, index) => (
                        <Option key={data.empId} value={data._id}>
                          {data.lastname} {data.firstname}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col> */}

              <Col lg={1} md={1} sm={24} xs={24}></Col>
              <Col lg={1} md={1} sm={24} xs={24}></Col>
              <Col lg={1} md={1} sm={24} xs={24}></Col>
              <Col lg={1} md={1} sm={24} xs={24}></Col>
              <Col lg={5} md={5} sm={24} xs={24}>
                <div
                  style={{
                    marginTop: "31px",
                    paddingRight: "9px",
                    float: "right",
                  }}>
                  <Button
                    style={{ float: "left" }}
                    onClick={this.btnFiltter}
                    type='primary'>
                    Filter
                  </Button>
                  <Button
                    onClick={this.onClearFiltter}
                    className='transaction-clear-btn'
                    ghost>
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Form>

        <Card
          bordered={false}
          style={{ marginBottom: "0px" }}
          bodyStyle={{ padding: "10px 10px", paddingBottom: "0px" }}>
          <Table
            className='gx-table-responsive'
            loading={this.state.table_loading}
             columns={this.columns}
            dataSource={data}
            size={"small"}
            scroll={{ y: heightd }}
            rowKey={(record) => record._id}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            sortDirections={sortDirections}
          />
            </Card>

            <Modal
                title={
                    loading
                        ? `Data is processing please wait ...`
                        : `InventoryScan File Created`
                }
                visible={this.state.visible}
                onCancel={this.handleCancel}
                // style={{backgroundImage: `url('https://www.codeproject.com/KB/aspnet/1095434/gears.gif')`}}
                footer={[
                    <Button key='back' onClick={this.handleCancel} ghost>
                        Cancel
                    </Button>,
                    <ExcelFile
                        filename={`Inventory Scan - ${new Date()}`}
                        element={
                            loading ? (
                                <Button key='submit1' type='primary' disabled>
                                    Wait..{" "}
                                </Button>
                            ) : (
                                <Button type='primary'>Download</Button>
                            )
                        }>
                        <ExcelSheet data={this.state.dataExportArray && this.state.dataExportArray.skuItemsFound ? this.state.dataExportArray.skuItemsFound : []} name='Detected'>
                            <ExcelColumn label='Date Time' value='date' />
                            <ExcelColumn label='Detected' value='skuItemsFound' />
                        </ExcelSheet>
                        <ExcelSheet data={this.state.dataExportArray && this.state.dataExportArray.skuItemsNotFound ? this.state.dataExportArray.skuItemsNotFound : []} name='Undetected'>
                            <ExcelColumn label='Date Time' value='date' />
                            <ExcelColumn label='Undetected' value='skuItemsNotFound' />
                        </ExcelSheet>
                        <ExcelSheet data={this.state.dataExportArray && this.state.dataExportArray.extraTag ? this.state.dataExportArray.extraTag : []} name='Additional'>
                            <ExcelColumn label='Date Time' value='date' />
                            <ExcelColumn label='Additional' value='extraTag' />
                        </ExcelSheet>
                        <ExcelSheet data={this.state.dataExportArray && this.state.dataExportArray.skuItemsSold ? this.state.dataExportArray.skuItemsSold : []} name='Sold'>
                            <ExcelColumn label='Date Time' value='date' />
                            <ExcelColumn label='Sold' value='skuItemsSold' />
                        </ExcelSheet>
                    </ExcelFile>,
                ]}>
                {loading ? (
                    <div
                        style={{
                            minHeight: "150px",
                            backgroundImage: `url(${Gears})`,
                        }}></div>
                ) : (
                    <Alert
                        message='Success'
                        description='Inventory Scan file is ready you can download.'
                        type='success'
                        showIcon
                    />
                )}
            </Modal>

        </div>
    );
  }
}

Stocktally = Form.create()(Stocktally);

function mapStateToProps(state) {
  const { Common } = state;
  const {
      stocktally_details: stocktally_details,
      stocktally_exportdetails: stocktally_exportdetails,
    user_list: user_list,
  } = Common;
    return { stocktally_details, user_list, stocktally_exportdetails };
}

const AppContainer = connect(mapStateToProps)(Stocktally);

export default AppContainer;
