import React, { Component } from 'react'
import IntlMessages from "util/IntlMessages";
import {connect} from "react-redux";
import moment from 'moment';
import {Card, Table,Form,Row,Col,DatePicker,Select,InputNumber,Button,AutoComplete,Input,Icon,Tag } from "antd";
import {sortDirections} from "../../../containers/App/commanFunction";
import FilterIcon from '../../../assets/images/icons/filter-icon.svg';
const { MonthPicker, RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;
const CustomerList=[];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const columns = [  
  {
    title: 'Date',
    dataIndex: 'date',
    width: '10%',
    render: (text, record) => {
      const { finger_index } = record
      
      return (
        <span>
            {text}
        </span>
      );
    }
  },
  {
    title: 'Inventory Scan',
    dataIndex: 'scan',
    width: '10%',
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
            {text}
        </span>
      );
    },
    
    
  },
  {
    title: 'Time',
    dataIndex: 'time',
    width: '10%',
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
            {text}
        </span>
      );
    }
  },
  {
    title: 'Addition',
    dataIndex: 'addition',
    width: '10%',
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
        {text > 0 ? <Tag color="green">{text}</Tag>:<span>{text}</span>}
        </span>
      );
    }
  },
  {
    title: 'Detected',
    dataIndex: 'detected',
    width: '10%',
    render: (text, record) => {
      const { finger_index } = record
      
      return (
        <span>
        {text}
        </span>
      );
    }
  },
  {
    title: 'Undetected',
    dataIndex: 'undetected',
    width: '10%',
    render: (text, record) => {
      const { customer } = record;
      return (
        <span>
            {text > 0 ? <Tag color="red">{text}</Tag>:<span>{text}</span>}
        </span>
      );
    },
    
    
  },
  
  {
    title: 'Sold',
    dataIndex: 'sold',
    width: '10%',
    render: (text, record) => {
      const { finger_index } = record
      
      return (
        <span>
            {text}
        </span>
      );
    }
  },
  // {
  //   title: 'Status',
  //   dataIndex: 'status',
  //   width: '10%',
  //   render: (text, record) => {
  //     const { customer } = record;
  //     return (
  //       <span>
  //           {text}
  //       </span>
  //     );
  //   },
    
    
  // },

];




class Sales extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
       data:[{"date":"4/17/2018","scan":"1","time":"9:30","detected":"2590","undetected":"0","addition":"0","sold":"0"},
              {"date":"4/17/2018","scan":"2","time":"17:30","detected":"2540","undetected":"0","addition":"0","sold":"50"},
              {"date":"4/18/2018","scan":"1","time":"9:35","detected":"2640","undetected":"0","addition":"100","sold":"0"},
              {"date":"4/18/2018","scan":"2","time":"17:30","detected":"2615","undetected":"0","addition":"0","sold":"25"},
              {"date":"4/19/2018","scan":"1","time":"9:30","detected":"2613","undetected":"2","addition":"0","sold":"0"}
            ],
    
      
       table_loading:false
    };
   
  }
  componentDidMount() {
  }

  handleSearch = (e) => {
     
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {dispatch} = this.props;
       // let userdata=values;
        
      }
    });
  }
  
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
      <h2 className="title gx-mb-4"> Stock Scan History</h2>
      <Card>
         <Row>
            <Col lg={24} md={24} sm={24} xs={24}>   
            <RangePicker style={{marginBottom:"5px"}}/>
            <Icon type="cloud-download" style={{float:"right",fontSize:"27px",cursor:"pointer"}}  className="certain-category-icon"/> 
                  <div className="certain-category-search-wrapper">
                  <AutoComplete
                      className="certain-category-search"
                      dropdownClassName="certain-category-search-dropdown"
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{width: 300}}
                      size="large"
                      style={{width: '100%',marginBottom:'5px'}}
                  //  dataSource={opt   ions}
                      placeholder="Search .."
                      optionLabelProp="value"
                  >
                      <Input suffix={<Icon type="search" className="certain-category-icon"/>}/>
                  </AutoComplete>     
                    </div>
                          <Table className="gx-table-responsive"
                              loading={this.state.table_loading} 
                              columns={columns} 
                              dataSource={this.state.data}
                              size={"small"}
                              rowKey={record => record._id}
                              sortDirections={sortDirections}
                          />
                        
                  </Col>
                
       
          </Row>
      </Card>
     
      </div>
    )
  }
}

Sales = Form.create()(Sales);
function mapStateToProps(state) {
  const {Common } = state
  const {quotation_details:quotation_details,customer_details:customer_details  } =  Common
  return {quotation_details,customer_details}
}

const AppContainer = connect( 
  mapStateToProps 
)(Sales);


export default AppContainer;


