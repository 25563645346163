
let initialState = {
  customerTypeList: [],
};

export default function customerTypeListReducer(state = initialState, action) {
  if (action.type === "LIST_CUSTOMER_TYPE"){
    return { ...state, customerTypeList: action.data };
  }else {
      return state;
  }
}