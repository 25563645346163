let UTILS = {};

UTILS.fromDateToDate = (fromDate, toDate) => {
    fromDate = fromDate.toISOString();
    toDate = toDate.toISOString();
    /* let todate = new Date(toDate);
     let fromdate = new Date(fromDate);
     let toOffset = new Date(toDate).getTimezoneOffset() / 60;
     let fromOffset = new Date(fromDate).getTimezoneOffset() / 60;
     console.log(fromOffset + " " + toOffset);
     fromdate.setHours(fromdate.getHours() + fromOffset);
     todate.setHours(todate.getHours() + toOffset);
     console.log(fromdate + " " + todate);
     fromDate = fromdate.toISOString();
     toDate = todate.toISOString();*/
    return { toDate, fromDate }
};

export default UTILS;
