import {getUserDetails, updateUserDetails} from '../../actions/index';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Button, Card, Col, Form, Input, message, Row} from 'antd';
import UpdatePassWord from './updatePassword';
import CompanyInfo from './companyInfo';
import PRN from './prn1';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
        const { dispatch } = this.props;
        let usename= localStorage.getItem('usename');
        dispatch(getUserDetails(usename));
    }
    componentDidUpdate(prevProps, prevState) {
        try {
            if (prevProps.user_data != this.props.user_data) {
                // console.log(this.props.user_data);
                
                if (this.props.user_data.status) {
                    this.setState({
                        data: this.props.user_data.data,
                        username: this.props.user_data.data.username,
                        firstname: this.props.user_data.data.firstname,
                        lastname: this.props.user_data.data.lastname,
                        email: this.props.user_data.data.email,
                        password: this.props.user_data.data.password,
                        mRootName:this.props.user_data.data.mRootName,
                        usertype:this.props.user_data.data.usertype,
                       currency_symbol:this.props.user_data.data.currency_symbol,
                    })
                }
            }
            if (prevProps.update_user_data != this.props.update_user_data) {
                if (this.props.update_user_data.status) {
                    message.success("Update sucessfully");
                    localStorage.setItem('currancy',this.props.user_data.data.currency_symbol);
                    this.setState({
                        username: this.props.update_user_data.data.username,
                        firstname: this.props.update_user_data.data.firstname,
                        lastname: this.props.update_user_data.data.lastname,
                        email: this.props.update_user_data.data.email,
                        password: this.props.user_data.data.password,
                        mRootName:this.props.user_data.data.mRootName,
                        usertype:this.props.user_data.data.usertype,
                        currency_symbol:this.props.user_data.data.currency_symbol
                    })
                } else {
                    message.error(this.props.update_user_data.error.msg);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (err) {
                return;
            }
            const { dispatch } = this.props;
            let data = this.state.data;
            data.firstname = values.firstname;
            data.lastname = values.lastname;
            data.username = values.username;
            data.email = values.email;
            data.currency_symbol = values.currency_symbol;
            dispatch(updateUserDetails(data));
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
            <Row>
            <Col md={12} sm={24} type="flex" >
                <Card title="Profile Details" style={{ borderRadius: "7px" }} bodyStyle={{paddingBottom:"0px"}}>
                            <Form layout="vertical" onSubmit={this.handleSubmit}>
                                <Form.Item label="Username" className="customFormItem">
                                    {getFieldDecorator('username', {
                                        initialValue: this.state.username,
                                        rules: [{ required: false, message: 'Please input gst!' }],
                                    })(
                                        <Input disabled={true}/>
                                    )}
                                </Form.Item>
                                        <Form.Item label="User Level" className="customFormItem">
                                        {getFieldDecorator('mRootName', {
                                            initialValue: this.state.mRootName,
                                            rules: [{ required: false, message: 'Please input gst!' }],
                                        })(
                                            <Input disabled={true}/>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="User Type" className="customFormItem">
                                    {getFieldDecorator('usertype', {
                                        initialValue: this.state.usertype,
                                        rules: [{ required: false, message: 'Please input gst!' }],
                                    })(
                                        <Input disabled={true}/>
                                    )}
                                </Form.Item>

                                 <Form.Item label="Currency Symbol" className="customFormItem">
                                {getFieldDecorator('currency_symbol', {
                                    initialValue: this.state.currency_symbol,
                                    rules: [{ required: true, message: 'Please input surrency symbols !' }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>

                                <Form.Item label="First Name" className="customFormItem">
                                    {getFieldDecorator('firstname', {
                                        initialValue: this.state.firstname,
                                        rules: [{ required: true, message: 'Please input firstname !' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                <Form.Item label="Last name" className="customFormItem">
                                    {getFieldDecorator('lastname', {
                                        initialValue: this.state.lastname,
                                        rules: [{ required: true, message: 'Please input last name !' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>

                                    <Form.Item label="E-mail">
                                    {getFieldDecorator('email', {
                                        initialValue: this.state.email,
                                        rules: [
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!',
                                        },
                                        ],
                                    })(<Input />)}
                                    </Form.Item>
                                   
                                <Form.Item >
                                    <Button type="primary" htmlType="submit">
                                        Update
                                    </Button>
                                </Form.Item>
                            </Form>
                </Card>

                <PRN password={this.state.password} userdata={this.state.data}/>
                

                </Col>
                <Col md={12} sm={24} >
           
                <UpdatePassWord password={this.state.password} userdata={this.state.data}/>
                {this.state.usertype ==="COMPANY ADMIN" ? 
                <CompanyInfo password={this.state.password} userdata={this.state.data}/>
                :""}

                </Col>
                
            </Row>
           <Row style={{float:"right",paddingRight:"8px"}}>
              <a href="https://www.dropbox.com/s/agidlx02b9wlcoz/Zebra%20Dirver%20%26%20Setup.zip?dl=0" target="_blank" >Download utility for printer</a>
            
          </Row>
          </React.Fragment>
           
        )
    }
}


Profile = Form.create()(Profile);
function mapStateToProps(state) {
    const { Common } = state
    const { user_data: user_data, update_user_data: update_user_data } = Common
    return { user_data, update_user_data }
}
const AppContainer = connect(
    mapStateToProps
)(Profile);
export default AppContainer;
