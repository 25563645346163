import {updateEmployee,getAllFloorList,getAllBrachList,getEmployees,getAllSectionList,getAllSubSectionList,getAllUserList,checkUserName} from '../../actions/index';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Modal,Form,Table ,List,Col, Icon, Button,message, Row,Switch,Select,AutoComplete,Input} from 'antd';
import EditIcon from "../../assets/images/icons/edit-icon.svg";
//import "../../customer/custome.css";
import AddCustomerImg from "./../../assets/images/icons/add-customer.svg";

var cookies = require('browser-cookies');
message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});
const { TextArea } = Input;
const { Option } = Select;
class AddUser extends Component {
      constructor(props) 
      {
        super(props);
        let {usertype,_id}= this.props.record;
        this.state = {
            data:[],
            loading: false,
        visible: false,
        userType:usertype,
        branch_list:[],
        branch_id:'',
        floor_list:[],
        floor_id:'',
        section_id:'',
        section_list:[],
        sub_section_list:[],
        sub_section_id:'',     
        ValidUservisible: false,
        userId:_id   
          };
      
      }

      componentDidMount() {
        const { dispatch } = this.props; 
        let {root}= this.props.record;
        this.setState({
          companyId:root.companyId,       
          branchId:root.branchId,
          floorId:root.floorId,
          sectionId:root.sectionId,
          subSectionId:root.subSectionId
        })
        // dispatch(getAllBrachList(root.companyId));   
        // dispatch(getAllFloorList("bybranchId",root.branchId)); 
        // dispatch(getAllSectionList("byFloorId",root.floorId));
        // dispatch(getAllSubSectionList("bySectionId",root.sectionId));
      }
      componentDidUpdate(prevProps, prevState) {

        if (prevProps.update_user != this.props.update_user) {
         try {
        
            if(this.props.update_user.status)
            {
              
                this.setState({
                    visible:false,
                })
                this.props.form.resetFields();
            }else{
            } 
              
            } catch (error) {
                console.log(error);
            }
           
        } 

        if (prevProps.check_user_data != this.props.check_user_data) {
          // console.log(this.props.check_user_data);
          if (this.props.check_user_data.status) {
              this.setState({
                  ValidUservisible: false,
              });
          } else {
              this.setState({
                  ValidUservisible: true,
              });
          }
      }

        if (prevProps.branch_list != this.props.branch_list) 
    {  
      if(this.props.branch_list.status)
      {  
       this.setState({        
        branch_list:this.props.branch_list.data_array
       });

       if(this.props.branch_list.data_array.length >= 1)
       {
        const { dispatch } = this.props;
        this.setState({
          branch_id:this.props.branch_list.data_array[0]._id,
        });
       // dispatch(getAllFloorList("bybranchId",this.props.branch_list.data_array[0]._id));
       }
      }else{
        this.setState({
          branch_list:[]
         });
      }
    }

    if (prevProps.floor_list != this.props.floor_list) {
    //  console.log(this.props.floor_list);
      if(this.props.floor_list.status)
      {
          this.setState({
            floor_list:this.props.floor_list.data_array,            
          })

          if(this.props.floor_list.data_array.length >= 1)
          {
            this.setState({
            floor_id:this.props.floor_list.data_array[0]._id,
           })
           const { dispatch } = this.props; 
          // dispatch(getAllSectionList("byFloorId",this.props.floor_list.data_array[0]._id));
          }
      }else{
        this.setState({
          floor_list:[]
        })
      } 
      this.setState({
        table_loading:false
       })
       
    } 


    if (prevProps.section_list != this.props.section_list) {
      // console.log(this.props.section_list);
      if(this.props.section_list.status)
      {
          this.setState({
            section_list:this.props.section_list.data_array,            
          })

          if(this.props.section_list.data_array.length >= 1)
          {
            this.setState({
            section_id:this.props.section_list.data_array[0]._id,
           })
           const { dispatch } = this.props; 
          // dispatch(getAllSubSectionList("user",this.props.section_list.data_array[0]._id));
          }
      }else{
        this.setState({
          section_list:[]
        })
      } 
          this.setState({
            table_loading:false
           })
     } 

     if (prevProps.sub_section_list != this.props.sub_section_list) {
      //  console.log(this.props.sub_section_list);
       if(this.props.sub_section_list.status)
       {
        this.setState({
          sub_section_list:this.props.sub_section_list.data_array,            
        })

        if(this.props.sub_section_list.data_array.length >= 1)
        {
          this.setState({
               sub_section_id:this.props.sub_section_list.data_array[0]._id,
           })
         const { dispatch } = this.props; 
         //dispatch(getAllUserList("subSection",this.props.sub_section_list.data_array[0]._id));
        }
      }else{
        this.setState({
          sub_section_list:[]
        })
      } 
          this.setState({
            table_loading:false
           })

      } 

    }
    showModal = () => {
      this.setState({
        visible: true,
      });
      const { dispatch } = this.props; 
      let {root}= this.props.record;
      dispatch(getAllBrachList(root.companyId));   
      dispatch(getAllFloorList("bybranchId",root.branchId)); 
      dispatch(getAllSectionList("byFloorId",root.floorId));
      dispatch(getAllSubSectionList("bySectionId",root.sectionId));
    }
   
    handleCancel = () => {
      this.setState({ visible: false });
      this.props.form.resetFields();
      let {root}= this.props.record;
      this.setState({    
        branchId:root.branchId,
        floorId:root.floorId,
        sectionId:root.sectionId,
        subSectionId:root.subSectionId
      })
    }

    checkUser = (e) => {
      this.props.form.validateFields(['username'], (err, values) => {
          const { dispatch } = this.props;         
          dispatch(checkUserName(values['username']));
      })
  }
        checkuserofff = (e) => {
          this.setState({
              ValidUservisible: false,
          });
      }
  
    handleOk = (e) => {
            if (this.state.ValidUservisible == true) {
              return;
          }
         this.props.form.validateFields((err, values) => {          
           if (err) {
             return;
           }
           let root =  JSON.parse(cookies.get('root'));
           const {dispatch} = this.props;
           if(values.floorId===undefined)
           {
            values.floorId=values.branchId
           }
           if(values.sectionId===undefined)
           {
            values.sectionId=values.branchId
           }
           if(values.subSectionId===undefined)
           {
            values.subSectionId=values.branchId
           }
           root={
            companyId:root.companyId,       
            branchId:values.branchId,
            floorId:values.floorId,
            sectionId:values.sectionId,
            subSectionId:values.subSectionId
           }
           delete values.companyId;
           delete values.branchId;
           delete values.floorId;
           delete values.sectionId;
           delete values.subSectionId;
           delete values.confirm;
           values.root=root;
           values._id=this.state.userId;
           //console.log(values);
           let test={"mUser":values}
          dispatch(updateEmployee(test)); 
         });
       }


       handleConfirmBlur = e => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
      };
    
       compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
          callback('Two passwords that you enter is inconsistent!');
        } else {
          callback();
        }
      };
    
      validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
          form.validateFields(['confirm'], { force: true });
        }
        callback();
      };


      selectUserType=(e)=>{
        this.setState({
          userType:e
        })
        this.setState({    
          branchId:"",
          floorId:"",
          sectionId:"",
          subSectionId:""
        })
        this.props.form.resetFields(['branchId']);
        this.props.form.resetFields(['floorId']);

        // if(e==="FLOOR MANAGER"){
        //   this.props.form.resetFields(['floorId']);
        // }
       
      }

      onChangeBranchRadio=(e)=> {
        // console.log(`radio checked:${e}`);
        this.setState({    
          branchId:"",
          floorId:"",
          sectionId:"",
          subSectionId:""
        })
       this.setState({
        table_loading:true,
        branch_id:e,
        data:[]
       })
       const {dispatch} = this.props;
       dispatch(getAllFloorList("bybranchId",e));
       this.props.form.resetFields(['floorId']);
      }
    
      onChangeFloor=(e)=> {
        // console.log(`radio floor:${e}`);
       this.setState({
        table_loading:true,
        floor_id:e,
        data:[]
       })
       const {dispatch} = this.props;
       dispatch(getAllSectionList("byFloorId",e));
       this.props.form.resetFields(['sectionId']);
      }
      
      onChangeSection=(e)=> {
        // console.log(`radio section:${e}`);
       this.setState({
        table_loading:true,
        section_id:e,
        data:[]
       })
       const {dispatch} = this.props;
       dispatch(getAllSubSectionList("bySectionId",e));
       this.props.form.resetFields(['subSectionId']);
      }
      

    render () {
        const { visible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const {firstname,lastname,email,phone,address,usertype,root}= this.props.record;
      
        return (
          <div style={{float:"left"}}>
          {/** 
          <Icon type="edit"  theme="filled" onClick={this.showModal} style={{fontSize: 20,paddingLeft:"15px"}} />*/}
          <img src={EditIcon} onClick={this.showModal} style={{paddingLeft:"15px",cursor:"pointer"}}></img>
            <Modal
              title="Update Employee"
              visible={visible}
              onCancel={this.handleCancel}
              footer={[
                <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                <Button key="submit" type="primary"  onClick={this.handleOk}>
                   Update
                </Button>,
              ]}
             // width={1250}
              style={{paddingBottom:"166px"}}
            >
            
            <Form layout="vertical" onSubmit={this.FormSubmit}>
                  <Form.Item label="First Name" className="customFormItem" style={{marginTop:"-16px"}}>
                  {getFieldDecorator('firstname', 
                  {
                    initialValue: firstname,
                    rules: [{ required: true, message: 'Please input first name !' }],
                  })(
                    <Input />
                  )}
                </Form.Item>

                <Form.Item label="Last Name" className="customFormItem">
                {getFieldDecorator('lastname', {
                  initialValue: lastname,
                  rules: [{ required: true, message: 'Please input last name !' }],
                })(
                  <Input />
                )}
              </Form.Item>

              <Form.Item label="E-mail" className="customFormItem">
              {getFieldDecorator('email', {
                initialValue: email,
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ],
              })(<Input />)}
            </Form.Item>

      
          <Form.Item label="Select Usertype" className="customFormItem">
          {getFieldDecorator('usertype', {
            initialValue: usertype,
            rules: [{ required: true, message: 'Please select usertype!' }],
          })(
            <Select placeholder="Please select user type" onChange={this.selectUserType}>
                  {/**  <Option value="SALE PERSON">SALE PERSON</Option>
          <Option value="INCHARGE">INCHARGE</Option> */}
              <Option value="FLOOR MANAGER">FLOOR MANAGER</Option>
              <Option value="MANAGER">BRANCH MANAGER</Option>
            </Select>
          )}
        </Form.Item>
        {this.state.userType==="SALE PERSON" || this.state.userType==="INCHARGE" || this.state.userType==="FLOOR MANAGER" || this.state.userType==="MANAGER" ? 
          
                <Form.Item label="Select branch" className="customFormItem">
                        {getFieldDecorator('branchId', {
                          initialValue: this.state.branchId,
                          rules: [{ required: true, message: 'Please select branch!' }],
                        })(   
                            <Select  placeholder="Please select branch" onChange={this.onChangeBranchRadio}  >         
                                  {this.state.branch_list.map((data, index) =>
                                  <Option key={data._id} value={data._id}>{data.name}</Option>         
                                )}
                          </Select>   
                      )}
                </Form.Item>
                :""}
                {this.state.userType==="SALE PERSON" || this.state.userType==="INCHARGE" || this.state.userType==="FLOOR MANAGER" ? 
         
                <Form.Item label="Select floor" className="customFormItem">
                {getFieldDecorator('floorId', {
                  initialValue: this.state.floorId,
                  rules: [{ required: true, message: 'Please select floor!' }],
                })(   
                        <Select  placeholder="Please select floor" onChange={this.onChangeFloor}   >         
                        {this.state.floor_list.map((data, index) =>
                        <Option key={data._id} value={data._id}>{data.name}</Option>         
                      )}
                      </Select>   
                )}
                </Form.Item>
                :""}
                {this.state.userType==="SALE PERSON" || this.state.userType==="INCHARGE" ? 
         
                <Form.Item label="Select Section " className="customFormItem">
                {getFieldDecorator('sectionId', {
                  initialValue: this.state.sectionId,
                  rules: [{ required: true, message: 'Please select section!' }],
                })(   
                      <Select  placeholder="Please select section"  onChange={this.onChangeSection}  >         
                      {this.state.section_list.map((data, index) =>
                      <Option key={data._id} value={data._id}>{data.name}</Option>         
                    )}
                    </Select>   
                )}
                </Form.Item>
                :""}
                {this.state.userType==="SALE PERSON"? 

                <Form.Item label="Select SubSection" className="customFormItem">
                {getFieldDecorator('subSectionId', {
                  initialValue: this.state.subSectionId,
                  rules: [{ required: true, message: 'Please select subsection!' }],
                })( 
                      <Select placeholder="Please select subsection"  onChange={this.onChangeSubSection}  >         
                      {this.state.sub_section_list.map((data, index) =>
                      <Option key={data._id} value={data._id}>{data.name}</Option>         
                    )}
                    </Select>   
                )}
                </Form.Item>               
              :""}
                        
            </Form>
            </Modal>
          </div>
        )
    }
}


AddUser= Form.create()(AddUser);
function mapStateToProps(state) {
    const {Common } = state
    const {floor_list:floor_list,branch_list:branch_list,section_list:section_list,sub_section_list:sub_section_list,update_user:update_user,check_user_data:check_user_data } =  Common
    return {floor_list,branch_list,section_list,sub_section_list,update_user,check_user_data}
}
const AppContainer = connect( 
mapStateToProps 
)(AddUser);
export default AppContainer;