import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import moment from "moment";
import { getViewOnDemand } from "../../../actions/index";
import {
  Card,
  Table,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  InputNumber,
  Button,
  AutoComplete,
  Input,
  Icon,
  Slider,
  Divider,
} from "antd";
import CountUp from "react-countup";
import { sortDirections } from "../../../containers/App/commanFunction";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
const { MonthPicker, RangePicker } = DatePicker;
const Option = Select.Option;

const columns = [
  {
    title: "DATE",
    dataIndex: "date",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      var datef = moment(text).format("DD-MM-YYYY");
      const { finger_index } = record;
      return <span>{datef}</span>;
    },
  },
  {
    title: "MONTH",
    dataIndex: "month",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { finger_index } = record;

      return <span>{text}</span>;
    },
  },
  {
    title: "CUSTOMERS",
    dataIndex: "totalCustCount",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { customer } = record;
      return <span>{text}</span>;
    },
  },
  {
    title: "%SPLIT",
    dataIndex: "per",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { customer } = record;
      return <span>{text}</span>;
    },
  },
  {
    title: "CUSTOMERS COUNT",
    dataIndex: "catCustCount",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { customer } = record;
      return <span>{text}</span>;
    },
  },
  {
    title: "DEMAND CTG",
    dataIndex: "catName",
    width: "10%",
    sorter: (a, b) => {},
    render: (text, record) => {
      const { customer } = record;
      return <span>{text}</span>;
    },
  },
  // {
  //   title: 'Demand Nt wt',
  //   dataIndex: 'demand_Nt_wt',
  //   width: '10%',
  //   render: (text, record) => {
  //     const { customer } = record;
  //     return (
  //       <span>
  //           {text}
  //       </span>
  //     );
  //   }
  // },
];

class Viewdemand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      category: [],
      table_loading: true,
      priceStart: 0,
      priceEnd: 0,
      selectdCtg: "",
      fromDate: "",
      toDate: "",
      update: 1,
      maxVal: 0,
      minVal: 0,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    let now = new Date();
    let starDate = (fromDate = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ).subtract(30, "days"));
    let fromDate = starDate.toISOString();
    let toDate = new Date().toISOString();
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
    });
    dispatch(getViewOnDemand(0, fromDate, toDate, this.state.selectdCtg));
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevProps.view_on_demand_view != this.props.view_on_demand_view) {
        // console.log(this.props.view_on_demand_view);

        if (this.props.view_on_demand_view.status) {
          let dataArray = this.props.view_on_demand_view.data_array;
          let category = [];
          let maxCount = [];
          for (let index = 0; index < dataArray.length; index++) {
            const element = dataArray[index];
            // console.log(element.catName);
            category.push(element.catName);
            maxCount.push(element.catCustCount);
          }
          // console.log(category);
          let maxVal = Math.max.apply(null, maxCount);
          let minVal = Math.min.apply(null, maxCount);
          // console.log(maxVal);
          let nsNamecategory = [...new Set(category)];
          let categoryOptionArray = [];
          for (let index1 = 0; index1 < nsNamecategory.length; index1++) {
            const element = nsNamecategory[index1];
            categoryOptionArray.push(
              <Option key={index1.toString(36) + index1} value={element}>
                {element}
              </Option>
            );
          }
          if (this.state.update == 1) {
            this.setState({
              category: categoryOptionArray,
              maxVal: maxVal,
              minVal: minVal,
            });
          }
          this.setState({
            data: this.props.view_on_demand_view.data_array,
            update: 2,
          });
        } else {
          this.setState({
            data: [],
          });
        }
        this.setState({
          table_loading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dispatch } = this.props;
        // let userdata=values;
      }
    });
  };
  onChangePrice = (value) => {
    this.setState({
      priceStart: value[0],
      priceEnd: value[1],
    });
  };

  onChange = (date, dateString) => {
    // this.setState({
    //   table_loading:true
    // })
    // if(date.length==2)
    // {
    //   try {
    //     console.log(date);
    //     const { dispatch } = this.props;
    //     let fromDate=date[0].toISOString();
    //     let toDate=date[1].toISOString();
    //     this.setState({
    //       fromDate:fromDate,
    //       toDate:toDate
    //     });
    //     dispatch(getViewOnDemand(0,fromDate,toDate,this.state.selectdCtg));
    //    } catch (error) {
    //      console.log(error);
    //    }
    // }else{
    //   this.setState({
    //     data:[],
    //     table_loading:false
    //    });
    // }
  };

  btnFiltter = () => {
    this.setState({
      table_loading: true,
    });

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const { dispatch } = this.props;
      const pager = { ...this.state.pagination };
      pager.current = 1;
      pager.pageSize = 10;

      this.setState({
        pagination: pager,
        table_loading: true,
        pageSizelimit: 10,
        currantPage: pager.current - 1,
      });
      let fromDate = "";
      let toDate = "";
      if (values.date.length > 0) {
        fromDate = values.date[0].toISOString();
        toDate = values.date[1].toISOString();
      }
      let dsg = values.ctg;
      if (dsg === undefined) {
        dsg = "";
      }
      dispatch(getViewOnDemand(0, fromDate, toDate, dsg));
    });
  };

  onClearFiltter = (e) => {
    this.setState({
      table_loading: true,
      search: "",
    });
    const { dispatch } = this.props;
    dispatch(getViewOnDemand(0, this.state.fromDate, this.state.toDate, ""));
    this.props.form.resetFields();
  };

  handleChangeCtg = (value) => {
    //  console.log(`Selected: ${value}`);
    // this.setState({
    //   table_loading:true,
    //   selectdCtg:value
    // });
    // const { dispatch } = this.props;
    // dispatch(getViewOnDemand(0,this.state.fromDate,this.state.toDate,value));
  };

  handleTableChange = (pagination, filters, sorter) => {
    let colome = sorter.columnKey;
    let order = sorter.order;
    let orderopp = 0;
    let data = this.state.data;
    if (order != undefined) {
      if (order === "descend") {
        order = -1;
        orderopp = 1;
      } else {
        order = 1;
        orderopp = -1;
      }
      data.sort((a, b) => (a[`${colome}`] > b[`${colome}`] ? order : orderopp));
    } else {
      const shuffleArray = (arr) => arr.sort(() => Math.random() - 0.5);
      shuffleArray(data);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let now = new Date();
    const fromDate = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ).subtract(30, "days");
    let data = this.state.data;
    if (this.state.search) {
      try {
        data = data.filter((row) => {
          let datef = moment(row.date).format("DD-MM-YYYY");
          return (
            datef.toUpperCase().includes(this.state.search.toUpperCase()) ||
            row.month.toUpperCase().includes(this.state.search.toUpperCase()) ||
            row.per.includes(this.state.search.toUpperCase()) ||
            row.catName.includes(this.state.search.toUpperCase())
          ); //||row.total.includes(this.state.search.toUpperCase())
          //row.name.toUpperCase().includes(this.state.search.toUpperCase())
        });
      } catch (error) {
        console.log(error);
      }
    }
    const formItemLayout = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    return (
      <div>
        <Card
          className='transaction-top-menu-card'
          bodyStyle={{ padding: "0" }}
          bordered={false}>
          <Row>
            <Col
              lg={5}
              md={5}
              sm={24}
              xs={24}
              style={{
                textAlign: "center",
              }}>
              <p className='transaction-title'>Reports</p>
              <h1 className='transaction-sub-title'>View Demand</h1>
            </Col>

            <Col lg={11} md={11} sm={24} xs={24}>
              <Divider
                type='vertical'
                className='vertical-divider-transaction'
              />
              <h1 className='total-text'>
                <CountUp start={0} end={this.state.data.length} />{" "}
              </h1>
            </Col>

            <Col lg={8} md={8} sm={24} xs={24}>
              <p
                style={{ marginBottom: "5px" }}
                className='transaction-serach-text text-color-8c8c8c'>
                Search
              </p>
              <Input
                value={this.state.search}
                placeholder='-- Search --'
                style={{ float: "left", width: "70%" }}
                onChange={(e) => this.setState({ search: e.target.value })}
              />
              <Button
                onClick={this.handleReset}
                style={{ marginLeft: "11px" }}
                type='primary'>
                Search
              </Button>
            </Col>
          </Row>
        </Card>

        <Form hideRequiredMark={true} colon={false} labelAlign={"left"}>
          <Card
            className='filtter-card'
            bordered={false}
            bodyStyle={{
              padding: "10px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}>
            <Row>
              <Col lg={2} md={2} sm={24} xs={24} className='text-aligin-center'>
                <img src={FilterIcon} className='filter-icon' />
              </Col>
              <Col lg={5} md={5} sm={24} xs={24} style={{ paddingLeft: "0px" }}>
                <Form.Item label={`Date`} {...formItemLayout}>
                  {getFieldDecorator(`date`, {
                    initialValue: [
                      moment(fromDate, "DD-MM-YYYY"),
                      moment(new Date(), "DD-MM-YYYY"),
                    ],
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <RangePicker
                      // showTime={{ format: 'HH:mm' }}
                      format='DD-MM-YYYY'
                      placeholder={["From ", "To"]}
                      //  defaultValue={[moment(fromDate, "DD-MM-YYYY"),moment(new Date(), "DD-MM-YYYY")]}
                      //  size="small"
                      // style={{width:"245px"}}
                      style={{ minWidth: "100%" }}
                      //   onChange={this.onChange}
                      // onOk={onOk}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={5} md={5} sm={24} xs={24}>
                <Form.Item label={"By Design CTG"} {...formItemLayout}>
                  {getFieldDecorator(`ctg`, {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                      mode='multiple'
                      //  size={size}
                      placeholder='Please select design CTG'
                      //  defaultValue={this.state.category}
                      //  onChange={this.handleChangeCtg}
                      style={{ width: "100%" }}>
                      {this.state.category}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col lg={5} md={5} sm={24} xs={24}></Col>

              <Col lg={2} md={2} sm={24} xs={24}></Col>
              <Col lg={5} md={5} sm={24} xs={24}>
                <div
                  style={{
                    marginTop: "31px",
                    paddingRight: "9px",
                    float: "right",
                  }}>
                  <Button
                    style={{ float: "left" }}
                    onClick={this.btnFiltter}
                    type='primary'>
                    Filter
                  </Button>
                  <Button
                    onClick={this.onClearFiltter}
                    className='transaction-clear-btn'
                    ghost>
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Form>

        <Card bodyStyle={{ padding: "10px 0px" }} bordered={false}>
          <Table
            className='gx-table-responsive'
            //loading={this.state.category_wise_sales}
            columns={columns}
            dataSource={data}
            size={"small"}
            rowKey={(record) => record._id}
            onChange={this.handleTableChange}
            sortDirections={sortDirections}
          />
        </Card>
      </div>
    );
  }
}

Viewdemand = Form.create()(Viewdemand);
function mapStateToProps(state) {
  const { Common } = state;
  const { view_on_demand_view: view_on_demand_view } = Common;
  return { view_on_demand_view };
}

const AppContainer = connect(mapStateToProps)(Viewdemand);

export default AppContainer;
