import axios from "axios";
const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var cookies = require('browser-cookies');

function getloginInfo(){
    let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    let headers = {
      'Content-Type': 'application/json',
      'type': 'Web',
      'x-access-token': webtoken,
    }
    return headers;
  }

const host="http://api.jeweltrace.in"
export function listCustomerType(listCustomerTypeCallback) {
    return dispatch => {
        return axios.get(host+"/customerstypes",
          { headers: getloginInfo()}
          )
            .then(response => {  
                if (response.data !== "") {             
                    dispatch({
                        type: "LIST_CUSTOMER_TYPE",
                        data: response.data.data_array
                      }); 
                         
                } 
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                      listCustomerTypeCallback(error);
                      window.location.href='/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
  }