// import {getStockfromRfid} from '../../actions/actions';
import { connect } from "react-redux";
import React, { Component } from "react";
import {
  Modal,
  Form,
  Table,
  Upload,
  Card,
  Col,
  Icon,
  InputNumber,
  Button,
  message,
  Row,
  Radio,
  Switch,
  Select,
  AutoComplete,
  Input,
  Divider,
  Checkbox
} from "antd";
import {
  UpdateCompany,
  getCompany,
  imageUpload
} from "../../../actions/scadmin";
import "./style.css";
import  nodataImage from "../../../assets/images/nodata.png";
import moment from "moment";
message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});
const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

class EditComapany extends Component {
  constructor(props) {
    super(props);
    let {imgLogo}=this.props.companyRecord;
     this.state={
      img_url:imgLogo,
      file_upload_percentage: 0,
      moduleList:[]
     }
  }

  componentDidMount() {
    const { dispatch } = this.props;
  }
  componentDidUpdate(prevProps, prevState) {
    var that = this;

    try {
   
    if (
      prevProps.file_upload_stock_response !=
      this.props.file_upload_stock_response
    ) {
      // console.log("success i");
      // console.log(this.props.file_upload_stock_response);
      if (this.props.file_upload_stock_response.status) {
        message.success("Image upload successfully");
        if (this.props.file_upload_stock_response.data.imgUrl != undefined) {
          this.setState({
            img_url: this.props.file_upload_stock_response.data.imgUrl
          });
        }
      } else {
        message.error(this.props.file_upload_stock_response.errors.msg);
      }
    }

    if (prevProps.update_company_details != that.props.update_company_details) {
      if (that.props.update_company_details.status) {
        this.setState({
          visible: false
        });
      }else {
       
      }
    }

    if (prevProps.check_company_exit != that.props.check_company_exit) {
      if (that.props.check_company_exit.status) {
        this.setState({
          ValidCompanyvisible: false
        });
      } else {
        this.setState({
          ValidCompanyvisible: true
        });
      }
    }

    if (prevProps.module_list != that.props.module_list) {
      this.setState({
        moduleList: that.props.module_list.data_array
      });
    }
    if (
      prevProps.file_upload_percentage != this.props.file_upload_percentage
    ) {
      // console.log("file_upload_percentage");
      // console.log(this.props.file_upload_percentage);
      this.setState({
        file_upload_percentage: this.props.file_upload_percentage
      });
    }
       
  } catch (error) {
      
  }
  }

  state = {
    loading: false,
    visible: false,
    copied: false,
    companyType: "Retailers",
    ValidUservisible: false,
    ValidCompanyvisible: false,
    moduleList: []
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };
  handleCancel = () => {
    this.setState({ visible: false });
    this.props.editComapanyVisible(false);
  };
  onChangeCompanyType = e => {
    this.setState({
      companyType: e.target.value
    });
  };
  checkCompanyoff = e => {
    this.setState({
      ValidCompanyvisible: false
    });
  };

  checkuserofff = e => {
    this.setState({
      ValidUservisible: false
    });
  };

  handlePhoneInput = event => {
    const target = event.target;
    const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      target.value = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      target.value = `(${zip}`;
    }

    /*
        let data = e.target.value;
        formatPhoneNumber
        data = data.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
       e.target.value = data;
       */
  };

  onError = () => {
         
    this.setState({
      img_url:nodataImage
    });
}

    SingupSubmit = e => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                // const {dispatch} = this.props;
                // let userdata=values;

                let updateCom = {
                    //    "name": values.compnayname,
                    companyType: values.companyType,
                    tax_no: values.tax_no,
                    address: values.address,
                    phone: values.phone,
                    noOfUserAllowed: values.noOfUserAllowed,
                    server_url: values.server_url,
                    module: values.module,
                    status: values.status
                };
                // console.log(creatCom);
                const { dispatch } = this.props;
                dispatch(UpdateCompany(updateCom, values.id));
            }
        });
    };

  handleSingleUpload = list => {
    const formData = new FormData();
    formData.append("images", list);
    this.setState({
      uploading: true,
      isUploadLoading: true,
      singleFileUpload: true
    });
    // console.log("upload formData");
    // console.log(formData);
    const { dispatch } = this.props;
    const {_id}=this.props.companyRecord;
    dispatch(imageUpload(formData, true,_id));
  };

  onChangeUrl=(e)=>{
    // console.log(e.target.value);
     this.setState({
         imgLogo:e.target.value,
         img_url:e.target.value
     });

 }

  render() {
    const { visible ,img_url} = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const {name,address,_id,phone,tax_no,server_url,noOfUserAllowed,companyType,licenseDays,type}=this.props.companyRecord;
    const props1 = {
      multiple: false,
      beforeUpload: file => {
        // console.log("file");
        // console.log(file);
        // console.log([file]);
        const isJPG = file.type === "image/jpg";
        const isJPEG = file.type === "image/jpeg";
        const isPNG = file.type === "image/png";
        if (!(isJPG || isJPEG || isPNG)) {
          Modal.error({
            title: "Upload only JPG ,JPEG and PNG Image format"
          });
          return;
        }
        // console.log("file handleSingleUpload");
        // console.log(file);
        this.handleSingleUpload(file);
        return false;
      },
      showUploadList: false,
      accept: "image/jpg, image/jpeg,image/png"
    };
    return (
      <div>
        {/* {this.props.text}
        <Icon
          type="edit"
          onClick={this.showModal}
          style={{ fontSize: 20, float: "left", cursor: "pointer" }}
        /> */}
         <Card title="Update Company information" bordered={false}>
          <span>
            <Form onSubmit={this.SingupSubmit}>
              <Row>
                   <Col md={7} sm={24} >
                            {/* <Card
                                style={{ width: 300, borderRadius: "3px" }}
                                bodyStyle={{ padding: "0px" }}
                                cover={
                                    <img
                                        alt="example"
                                        src={img_url}
                                        onError={this.onError}
                                    />
                                }
                                //actions={[<Upload
                                  //  {...props}
                                //>
                                  //  {uploadButton}
                               // </Upload>]}
                            ></Card> */}
                           <center> <img
                              className="imgCompanyLogo"
                              src={img_url}
                              onError={this.onError}
                            />
                            <br/>
                            {/* <p className="pCompanyName">{this.state.companyName}</p> */}
                         <Upload {...props1} className="floatLeftImageview" >
                                <p  className="p2CompanyName"><Icon type="edit" style={{fontSize:"15px"}}/>&nbsp; &nbsp; Update logo</p>
                            </Upload>
                            </center> 
                    </Col>
                    <Col md={17} sm={24} >
                 
              <Row>
                <Col md={11} sm={12} xs={24}>
                  <FormItem {...formItemLayout} label="Company Name">
                    {getFieldDecorator(
                      "name",
                      { initialValue: name},
                      {
                        rules: [
                          {
                            required: true,
                            message: "Please input your company name!"
                          }
                        ]
                      }
                    )(
                      <Input
                        disabled
                        size="default"
                        placeholder="Company Name"
                        onChange={this.checkCompanyoff}
                        onBlur={this.checkCompayName}
                      />
                    )}
                    {this.state.ValidCompanyvisible ? (
                      <div className="has-error" style={{ color: "#f5222d" }}>
                        Company name already in use
                      </div>
                    ) : (
                      ""
                    )}
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label="Company Name"
                    style={{ display: "none" }}
                  >
                    {getFieldDecorator(
                      "id",
                      { initialValue: _id },
                      {
                        rules: [
                          {
                            required: true,
                            message: "Please input your company name!"
                          }
                        ]
                      }
                    )(
                      <Input
                        size="default"
                        placeholder="Company Name"
                        onChange={this.checkCompanyoff}
                        onBlur={this.checkCompayName}
                      />
                    )}
                  </FormItem>
                </Col>

                <Col md={1} sm={12} xs={24} />
                <Col md={11} sm={12} xs={24}>
                  <FormItem {...formItemLayout} label="Address">
                    {getFieldDecorator(
                      "address",
                      { initialValue: address },
                      {
                        rules: [
                          { required: true, message: "Please input address!" }
                        ]
                      }
                    )(<TextArea rows={2} placeholder="Address" />)}
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <Col md={11} sm={12} xs={24}>
                  <FormItem {...formItemLayout} label="Contact Number">
                    {getFieldDecorator(
                      "phone",
                      { initialValue: phone },
                      {
                        rules: [
                          {
                            required: true,
                            message: "Please input contact number!"
                          }
                        ]
                      }
                    )(<Input size="default" placeholder="Contact Number" />)}
                  </FormItem>
                </Col>
                <Col md={1} sm={12} xs={24} />
                <Col md={11} sm={12} xs={24}>
                  <FormItem {...formItemLayout} label="TAX No">
                    {getFieldDecorator(
                      "tax_no",
                      { initialValue: tax_no },
                      {
                        rules: [
                          { required: true, message: "Please input tax no!" }
                        ]
                      }
                    )(<Input size="default" placeholder="Tax no" />)}
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <Col md={11} sm={12} xs={24}>
                  <FormItem {...formItemLayout} label="Server Ip">
                    {getFieldDecorator(
                      "server_url",
                      { initialValue: server_url },
                      {
                        rules: [
                          {
                            required: true,
                            message: "Please enter server ip address!"
                          }
                        ]
                      }
                    )(<Input size="default" placeholder="Server IP Address" />)}
                  </FormItem>
                </Col>
                <Col md={1} sm={12} xs={24} />
                <Col md={11} sm={12} xs={24}>
                <FormItem {...formItemLayout} label="No of Users">
                    {getFieldDecorator(
                      "noOfUserAllowed",
                      { initialValue: noOfUserAllowed },
                      {
                        rules: [
                          {
                            required: true,
                            message: "Please input no of users!"
                          }
                        ]
                      }
                    )(
                      <InputNumber
                        size="default"
                        placeholder="No of Users"
                        width="100%"
                      />
                    )}
                  </FormItem>
                  {/* <FormItem {...formItemLayout} label="Module">
                    {getFieldDecorator(
                      "module",
                      { initialValue: module },
                      {
                        rules: [
                          { required: true, message: "Please select module!" }
                        ]
                      }
                    )(
                      <Select
                        mode="multiple"
                        // size={size}
                        placeholder="Please select module "
                        //   defaultValue={['production', 'analytics']}
                        //  onChange={handleChange}
                        style={{ width: "100%" }}
                      >
                        {this.state.moduleList.map(moduleList => (
                          <Option key={moduleList._id}>
                            {moduleList.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem> */}
                </Col>
              </Row>

              <Row>
                <Col md={11} sm={12} xs={24}>
                  {/* <FormItem {...formItemLayout} label="Status">
                    {getFieldDecorator(
                      "status",
                      { initialValue: this.props.status },
                      {
                        rules: [
                          { required: true, message: "Please select status" }
                        ]
                      }
                    )(
                      <Select
                        //mode="multiple"
                        // size={size}
                        placeholder="Please select status"
                        style={{ width: "100%" }}
                      >
                        <Option key="Active">Active</Option>
                        <Option key="Deactive">Deactive</Option>
                      </Select>
                    )}
                  </FormItem> */}
                </Col>

                <Col md={1} sm={12} xs={24} />
                <Col md={11} sm={12} xs={24}>
                  {/* <FormItem {...formItemLayout} label="No of Users">
                    {getFieldDecorator(
                      "noOfUserAllowed",
                      { initialValue: noOfUserAllowed },
                      {
                        rules: [
                          {
                            required: true,
                            message: "Please input no of users!"
                          }
                        ]
                      }
                    )(
                      <InputNumber
                        size="default"
                        placeholder="No of Users"
                        width="100%"
                      />
                    )}
                  </FormItem> */}
                </Col>
              </Row>
              <Row>
                <Col md={11} sm={12} xs={24}>
                  <FormItem
                                    {...formItemLayout}
                                    label="Company Type"
                                >
                                    {getFieldDecorator('companyType', 
                                     { initialValue: parseInt(companyType) },
                                     {
                                       rules: [
                                         { required: true, message: "Please input Type!" }
                                       ]
                                     }
                                    )(
                                        <Select placeholder="Please select company type">
                                            <Option value={0}>Normal</Option>
                                            <Option value={1}>Offline no sync</Option>
                                            <Option value={2}>Fully Online</Option>
                                            <Option value={3}>FTP Sync</Option>
                                            <Option value={4}>ERP Sync</Option>
                                        </Select>
                                    )}
                                </FormItem>

                </Col>

                <Col md={1} sm={12} xs={24} />
                <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="License Days"
                                >
                                    {getFieldDecorator('licenseDays',
                                        {
                                            initialValue: licenseDays,
                                            rules: [{ required: true, message: 'Please input license days!' }],
                                        })(
                                            <InputNumber min={0} size="default" placeholder="license Days" style={{ width: '100%' }} />
                                        )}
                                </FormItem>

                </Col>

              </Row>
              <Row>
                            <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Type"
                                >
                                    {getFieldDecorator('type', {
                                        initialValue:type,
                                        rules: [{ required: true, message: 'Please input Type!' }],
                                    })(
                                        <RadioGroup onChange={this.onChangeCompanyType} >
                                            <Radio value={0}>Retailers</Radio>
                                            <Radio value={1}>Wholesaler</Radio>
                                            <Radio value={2}>Manufacturer</Radio>
                                        </RadioGroup>
                                    )}
                                </FormItem>
                </Col>
                <Col md={1} sm={12} xs={24} />
                <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Logo URL"
                                >
                                    {getFieldDecorator('imgLogo', {
                                        initialValue:this.state.img_url,
                                        rules: [{ required: true, message: 'Please input img logo url!' }],
                                    })(
                                      <Input  onChange={this.onChangeUrl}/>
                                    )}
                                </FormItem>
                               

                </Col>



                </Row>
              <Row>
                <Col md={18} sm={12} xs={24} />
                <Col md={6} sm={12} xs={24}>
                  <FormItem>
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                    <Button type="default" onClick={this.handleCancel}>
                      Cancel
                    </Button>
                  </FormItem>
                </Col>
              </Row>
                
              </Col>
              </Row>
            </Form>
          </span>
        </Card>
        {/* </Modal> */}
      </div>
    );
  }
}

EditComapany = Form.create()(EditComapany);

function mapStateToProps(state) {
  const { Common } = state;
  const {
    update_company_details: update_company_details,
    check_company_exit: check_company_exit,
    module_list: module_list,
    file_upload_stock_response: file_upload_stock_response,
    file_upload_percentage: file_upload_percentage
  } = Common;
  return { update_company_details, check_company_exit, module_list ,file_upload_stock_response,
    file_upload_percentage };
}

const AppContainer = connect(mapStateToProps)(EditComapany);
export default AppContainer;
