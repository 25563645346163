import {
    STOCKTALLY_DETAILS,TOP_SALES_PERSON_DETAILS,TOP_SALES_CATEGORY_DETAILS,TOP_SALES_DESIGN_DETAILS,
    CUSTOMER_CONVERSATION_RATIO,DESIGN_ANALYTICS_DETAILS,SALESPERSON_WISE_CONVERSATION_RATIO,ALL_SALES_PERSONS,VIEW_DEMAND,
    TOTAL_DETAILS
} from '../actions/dashboard';
function dashboardReducer(state = {
    stocktally_details: [],top_sales_design_details:[],top_sales_category_details:[],top_sales_person_details:[],
    customer_conversation_ratio:[],design_analytics_details:[],salesperson_wise_conversation_ratio:[],all_sales_persons:[],view_demand:[],
    total_details:[]
}, action) {

    switch (action.type) {
        case STOCKTALLY_DETAILS:
            return Object.assign({}, state,
                {
                    stocktally_details: action.stocktally_details
                })
                case TOP_SALES_DESIGN_DETAILS:
                return Object.assign({}, state,
                {
                    top_sales_design_details: action.top_sales_design_details
                })
                case TOP_SALES_CATEGORY_DETAILS:
                return Object.assign({}, state,
                {
                     top_sales_category_details: action.top_sales_category_details
                })
                case TOP_SALES_PERSON_DETAILS:
                return Object.assign({}, state,
                {
                     top_sales_person_details: action.top_sales_person_details
                })

                case CUSTOMER_CONVERSATION_RATIO:
                return Object.assign({}, state,
                {
                    customer_conversation_ratio: action.customer_conversation_ratio
                })
                case DESIGN_ANALYTICS_DETAILS:
                return Object.assign({}, state,
                {
                    design_analytics_details: action.design_analytics_details
                })
                case SALESPERSON_WISE_CONVERSATION_RATIO:
                return Object.assign({}, state,
                {
                    salesperson_wise_conversation_ratio: action.salesperson_wise_conversation_ratio
                })
                case ALL_SALES_PERSONS:
                return Object.assign({}, state,
                {
                    all_sales_persons: action.all_sales_persons
                })
                case VIEW_DEMAND:
                return Object.assign({}, state,
                {
                    view_demand: action.view_demand
                })

                case TOTAL_DETAILS:
                    return Object.assign({}, state,
                    {
                        total_details: action.total_details
                    })



        default:
            return state
    }
}
export default  dashboardReducer