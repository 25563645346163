import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Modal,Form,Radio ,List,Col, Icon, Button,message,Tabs , Row,Switch,Select,AutoComplete,Input,Divider,Checkbox} from 'antd';
import {addIncrementMasterPost,getCustomerMaster}from "../../../actions/index";
import Case2Form from './case2Form';
import "./Custome.css";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const { TextArea } = Input;
class addIncrementMaster extends Component {
    constructor(props) 
    {
      super(props);
        this.state = {
            loading: false,
            visible: false,
            copied: false,
            table_loading:false
        }
  };
     
    componentDidMount() {
      const { dispatch } = this.props;
    }

    
    componentDidUpdate(prevProps, prevState)
    {
      if (prevProps.add_increment_master != this.props.add_increment_master) {
          if(this.props.add_increment_master.status)
          {
            message.success("Add increment master sucessfully");
            const {dispatch} = this.props;
            dispatch(getCustomerMaster()); 
            this.setState({ visible: false });
            this.props.form.resetFields();
          } 
        } 
    }
 
    showModal = () => {
        this.setState({
          visible: true,
        });
      }
      handleOk = () => {

        this.props.form.validateFields((err, values) => {
          // console.log("values ok sfysdy");
          // console.log(values);
          if (err) {
            return;
          }
          const {dispatch} = this.props;
          let ctype=values.ctype;
          delete values.ctype;
          let increment_master=values;
         // console.log({ctype,increment_master});
          dispatch(addIncrementMasterPost({ctype,increment_master})); 
        });
      }

      handleCancel = () => {
        this.setState({ visible: false });
        this.props.form.resetFields();
      }
     
      onUpdate (data) { this.setState({ visible:false }) }
    render() {
        const {visible}= this.state; 
        const { getFieldDecorator } = this.props.form;
        
        return (
            <div>
         <Button type="primary" style={{float:"right"}}  onClick={this.showModal}>{this.props.text}</Button>
          <Modal
            title="Add Incement Master"
            visible={visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
            bodyStyle={{padding: "12px 24px"}}
            //footer={[
            // <Form.Item >
            //     <Button type="primary" htmlType="submit">Submit</Button>
            //  </Form.Item>
        
           // width={500}
           // style={{paddingBottom:"166px"}}
          >
          <Tabs defaultActiveKey="1" >
          <TabPane tab="Case 1" key="1">

            <Form layout="vertical">  
            <Form.Item label="Customer Type" className="customFormItem" >
            {getFieldDecorator('ctype', {
              rules: [{ required: true, message: 'Please select customer type !' }],
            })(
                    <Select
                    showSearch
                    //style={{ width: 200 }}
                    placeholder="Select a customer type"
                    optionFilterProp="children"
                  //  onChange={handleChange}
                  //  onFocus={handleFocus}
                  //  onBlur={handleBlur}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                  {this.props.customer_type_array.map(city => <Option key={city.id}>{city.value}</Option>)}
                  </Select>
            )}
          </Form.Item>

            <Form.Item label="Gold Value" className="customFormItem">
              {getFieldDecorator('goldValue', {
                rules: [{ required: true, message: 'Please input gold value !' }],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item label="Diamond Value" className="customFormItem">
              {getFieldDecorator('diamondValue', {
                rules: [{ required: true, message: 'Please input dimonds value !' }],
              })(<Input type="number" />)}
            </Form.Item>

            <Form.Item label="Color Stone Value" className="customFormItem">
            {getFieldDecorator('colorStoneValue', {
              rules: [{ required: true, message: 'Please input dimonds value !' }],
            })(<Input type="number" />)}
          </Form.Item>

          <Form.Item label="Finding Value" className="customFormItem">
          {getFieldDecorator('findingValue', {
            rules: [{ required: true, message: 'Please input finding Value !' }],
          })(<Input type="number" />)}
        </Form.Item>
          <Form.Item label="Labour" className="customFormItem">
          {getFieldDecorator('labourValue', {
            rules: [{ required: true, message: 'Please input labour!' }],
          })(<Input type="number" />)}
        </Form.Item>


        

        <div className="ant-modal-footer">
         <Button key="back" onClick={this.handleCancel}>Cancle</Button>
        <Button key="white" type="primary" htmlType="submit" onClick={this.handleOk}>  Submit </Button>
        </div>         
        </Form>

          </TabPane>
          <TabPane tab="Case 2" key="2">
          <Case2Form customer_type_array={this.props.customer_type_array} onUpdate={this.onUpdate.bind(this)}/>
          </TabPane>
          </Tabs>
        
        </Modal>
        </div>
        )
    }
}

addIncrementMaster= Form.create()(addIncrementMaster);
function mapStateToProps(state) {
    const {Common } = state
    const {add_increment_master:add_increment_master} =  Common
    return {add_increment_master}
}
const AppContainer = connect( 
mapStateToProps 
)(addIncrementMaster);
export default AppContainer;