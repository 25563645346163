import axios from "axios";

const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var cookies = require('browser-cookies');

function getloginInfo() {
    let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    let headers = {
        'Content-Type': 'application/json',
        'type': 'Web',
        'x-access-token': webtoken,
    }
    return headers;
}

// const host="http://api.jeweltrace.in"
export function EditCustomerType(data, editCustomerTypeCallback) {
    // console.log("data in action :", data)
    let errorMessage = ""
    return dispatch => {
        // return axios.put(host+"/customerstypes",data,
        return axios.put("/customerstypes", data,
            {headers: getloginInfo()}
        )
            .then(response => {
                // console.log("response :", response)
                if (response.data !== "") {
                    this.props.listCustomerType()
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                    } else {
                        console.log(error);
                    }
                }
            });
    }
}

  