import React, { Component } from 'react'
import {connect} from "react-redux";
import {getSkuLabel,postSkuLabel,getAppFiltter,postFiltter} from '../../../actions/index';
import {Card, Table,Form,Row,Col,Modal,Select,Transfer,Input,Button,Icon,Divider,Popconfirm,Radio,Alert,message,List, Spin} from "antd";
import "./style.css";
var cookies = require('browser-cookies');
class ManageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      keys:[],
      mockData: [],
      targetKeys: [],
      showForm:false,
      currentKey:'sku_number',
      moveKeys:[],
      updateDisbale:false
    };
  }
  componentWillMount(){  
    let usertype=localStorage.getItem('type_user');
    if(usertype!="companyAdmin"){
      window.location.href="/dashboard"
    }
   }
  componentDidMount() {
    const { dispatch } = this.props; 
    //getSkuLabel
    dispatch(getAppFiltter());
   // this.getMock();
  }
  componentDidUpdate(prevProps, prevState) {
//get_filtter
if (prevProps.get_filtter != this.props.get_filtter) {
  // console.log("this.props.get_filtter");
  // console.log(this.props.get_filtter);
   if(this.props.get_filtter.status)
   {
       this.setState({
         loading:false,
        
       })
      let dataArray=this.props.get_filtter.data.filters;
      // console.log(dataArray);
       const targetKeys = [];
       const mockData = [];
       for (let i = 0; i < dataArray.length; i++) {
        //  const data = {
        //    key: i.toString(),
        //    title: `${dataArray[i].key}`,
        //    description: `d${<Icon type="edit" />}`,
        //    chosen: true,
        //  };
        
         if (dataArray[i].isEnabled) {
           targetKeys.push(dataArray[i].key);
         }
        // mockData.push(data);
       }
       this.setState({ mockData, targetKeys });
      this.setState({
        mockData:dataArray,
        
      })

   } 
   
  }

  if (prevProps.post_filtter != this.props.post_filtter) {
    // console.log("this.props.get_filtter");
    // console.log(this.props.post_filtter);
     if(this.props.post_filtter.status)
     {
         this.setState({
           loading:true,
         })
         const { dispatch } = this.props; 
         //getSkuLabel
         dispatch(getAppFiltter());
     } 
    }
  }
  

  
  handleOk = (targetKeys) => {
//  console.log("targetKeys=================++++++++++++++");
//  console.log(this.state.targetKeys);

//  console.log(this.state.mockData);
 let data=this.state.mockData.filter(x =>  targetKeys.includes(x.key));
//  console.log(data);
 for (let index = 0; index < data.length; index++) {
   const element = data[index];
   data[index].isEnabled=true
 //\\  data[index].value=data[index].key
 }
//  console.log("isEnabled=true");
//  console.log(data);
 let Notdata=this.state.mockData.filter(x =>  !targetKeys.includes(x.key));
 for (let index = 0; index < Notdata.length; index++) {
  const element = Notdata[index];
  Notdata[index].isEnabled=false
//  data[index].value=data[index].key
}

//  console.log("isEnabled=false");
//  console.log(Notdata);
//  console.log("concaett=false-------------------");
//  console.log(data.concat(Notdata));
 let finalData={"filters":data.concat(Notdata)};
  const { dispatch } = this.props;  
  this.setState({
    selectedKeys:[]
  })
  dispatch(postFiltter(finalData)); 
 
}

handleCancel = () => {
   this.setState({ showForm: false });
   this.props.form.resetFields();

 }

getMock = () => {
  const targetKeys = [];
  const mockData = [];
  for (let i = 0; i < 20; i++) {
    const data = {
      key: i.toString(),
      title: `content${i + 1}`,
      description: `description of content${i + 1}`,
      chosen: Math.random() * 2 > 1,
    };
    if (data.chosen) {
      targetKeys.push(data.key);
    }
    mockData.push(data);
  }
  this.setState({ mockData, targetKeys });
};

handleChange = (targetKeys, direction, moveKeys) => {
  // console.log(targetKeys, direction, moveKeys);
  this.setState({ targetKeys,  loading:true });
  this.handleOk(targetKeys);
};
editIcon=(e)=>{
  // console.log(e);
  this.setState({
    showForm:true,
    updateObject:e,
    currentKey:e.key,
    currentValue:e.value,
  })
}



updateValue = (e) => {

this.props.form.validateFields((err, values) => {          
      if (err) {
        return;
      }
      // console.log(Object.keys(values));
      let obj=Object.keys(values);
      //Object.keys(user)
      let valueid=obj.length === 2 ? obj[1] : obj[0]; 
      let objValue=Object.values(values);
      let valueValue=obj.length === 2 ? objValue[1] : objValue[0]; 
      // console.log(valueValue);
   //   console.log(values`${valueid}`);
     let editData=[];
      editData=this.state.mockData.filter(x =>  x.key == valueid);
    //  console.log(editData);
     editData[0].value=valueValue;
     
     

function getUnique(arr, comp) {

                    // store the comparison  values in array
   const unique =  arr.map(e => e[comp])

                  // store the indexes of the unique objects
                  .map((e, i, final) => final.indexOf(e) === i && i)

                  // eliminate the false indexes & return unique objects
                 .filter((e) => arr[e]).map(e => arr[e]);

   return unique;
}

    let finaldata1=this.state.mockData.concat(editData);
    let uniqData= getUnique(finaldata1,'key');
     this.setState({
      mockData:uniqData,
      showForm:false,
      loading:true
     })

     let finalData={"filters":uniqData};
     const { dispatch } = this.props;  
     dispatch(postFiltter(finalData)); 

   });
}
onSelectChange=(sourceSelectedKeys, targetSelectedKeys)=>{
  
}

renderItem = item => {
  const customLabel = (
    <span className="custom-item">
      {item.key}  <Icon type="edit" onClick={()=>this.editIcon(item)}/> 
    </span>
  );

  return {
    label: customLabel, // for displayed item
    value: item.title, // for title and filter matching
  };
};

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
   
    // const keys = getFieldValue('keys');
    // console.log("=======console.log(keys)-------------");
    // console.log(keys);
    const formItems = this.state.keys.map((k, index) => (
      // <Col span={12} style={{display:"block"}}>
      <Col span={12}>
      <Form.Item
        label={k.label}
        required={true}
        key={k}
        // style={{float:"left"}}
      >
        {getFieldDecorator(`${k.label}`, {
      //    validateTrigger: ['onChange', 'onBlur'],
          initialValue: k.labelvalue == '' ? k.label : k.labelvalue,
          rules: [
            {
              required: true,
          //    whitespace: true,
              message: `Please input ${k.label}`,
            },
          ],
        })(<Input placeholder={`Enter new filed name`}  />)}
      </Form.Item>
       </Col>
    ));

    return (
      <div>
     
  
      <div className="certain-category-search-wrapper">
   
       </div>

        <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
           <Card bordered={false} title="Inventory filter Configuration" > 
             <Spin spinning={this.state.loading}>
              <center>
             <Transfer
                  dataSource={this.state.mockData}
                  titles={['All Filtter', 'Selected Filtter']}
                  listStyle={{
                    width: "40%",
                    height: 300,
                    textAlign:"left"
                  }}
                  className="transfer"
                  targetKeys={this.state.targetKeys}
                  //  selectedKeys={this.state.selectedKeys}
                  onChange={this.handleChange}
                  render={this.renderItem}
                  onSelectChange={this.onSelectChange}
              />
               </center>
              {/* <Row gutter={24}>
                <Divider/>
                <Col lg={24} md={24} sm={24} xs={24}>
                 <center> 
                <Button key="submit" type="primary"  onClick={this.handleOk} disabled={this.state.updateDisbale} >
                   Update
                </Button>
                </center>
                </Col>
              </Row> */}
              </Spin>
            
               </Card> 
               {/* <Form  onSubmit={this.FormSubmit}  {...formItemLayout}>
            </Form> */}
                      <Modal
              title={`Update vaule for ${this.state.currentKey}`} 
              visible={this.state.showForm}
              onCancel={this.handleCancel}
              footer={[
                <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                <Button key="submit" type="primary"  onClick={this.updateValue}>
                   Update
                </Button>,
              ]}
             // width={1250}
              style={{paddingBottom:"166px"}}
            >
            
            <Form layout="vertical" onSubmit={this.FormSubmit}>
                  <Form.Item label={this.state.currentKey} className="customFormItem" style={{marginTop:"-16px"}}>
                  {getFieldDecorator(`${this.state.currentKey}`, {
                    initialValue: this.state.currentValue,
                    rules: [{ required: false, message: 'Please input first name !' }],
                  })(
                    <Input />
                  )}
                </Form.Item>
            </Form>
            
           </Modal> 
        </Col>
       
     
        </Row>
        
        
      </div>
    )
  }
}

ManageUser = Form.create()(ManageUser);
function mapStateToProps(state) {
  const {Common } = state
  const {get_filtter:get_filtter,post_filtter:post_filtter} =  Common
  return {get_filtter,post_filtter}
}

const AppContainer = connect( 
  mapStateToProps 
)(ManageUser);


export default AppContainer;



