import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getScanSession,
  postScanSession,
  updateScanSession,
} from "../../../actions/index";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
} from "antd";

var cookies = require("browser-cookies");
const Option = Select.Option;

class InventoryScan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sessionData: null,
      isMorning: false,
      isNoon: false,
      isEvening: false,
    };
  }

  componentWillMount() {
    let usertype = localStorage.getItem("type_user");
    if (usertype != "companyAdmin") {
      window.location.href = "/dashboard";
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getScanSession());
  }

  componentDidUpdate(prevProps, prevState) {
    //get_scan_session
    if (prevProps.get_scan_session != this.props.get_scan_session) {
    //   console.log("this.props.get_scan_session");
    //   console.log(this.props.get_scan_session);
      if (this.props.get_scan_session.status) {
        if (this.props.get_scan_session.data != null) {
          let data = this.props.get_scan_session.data;

          this.setState({
            sessionData: data,
            isMorning: data.isMorning,
            isNoon: data.isNoon,
            isEvening: data.isEvening,
          });
        }
      } else {
        this.setState({
          isMorning: false,
          isNoon: false,
          isEvening: false,
        });
      }
    }

    if (prevProps.post_scan_session != this.props.post_scan_session) {
      
      if (this.props.post_scan_session.status) {
        this.setState({
          sessionData: this.props.post_scan_session.data,
          isMorning: this.props.post_scan_session.data.isMorning,
          isNoon: this.props.post_scan_session.data.isNoon,
          isEvening: this.props.post_scan_session.data.isEvening,
        });
        message.success("Update sucessfully");
      }
    }
    if (prevProps.update_scan_session != this.props.update_scan_session) {
      // console.log('this.props.update_scan_session', this.props.update_scan_session);
      if (this.props.update_scan_session.status) {
        this.setState({
          sessionData: this.props.update_scan_session.data,
          isMorning: this.props.update_scan_session.data.isMorning,
          isNoon: this.props.update_scan_session.data.isNoon,
          isEvening: this.props.update_scan_session.data.isEvening,
        });
        message.success("Update sucessfully");
      }
    }
  }

  updateValue = (e) => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (
        this.state.sessionData !== null &&
        this.state.sessionData !== undefined
      ) {
        let tempData = this.state.sessionData;

        tempData.isMorning = this.state.isMorning;
        tempData.isNoon = this.state.isNoon;
        tempData.isEvening = this.state.isEvening;
        // console.log("Requesting update ------", tempData);
        const { dispatch } = this.props;
        dispatch(updateScanSession(tempData));
      } else {
        let root = JSON.parse(cookies.get("root"));
        values.companyId = root.companyId;
        // console.log("Requesting post ------", values);
        const { dispatch } = this.props;
        dispatch(postScanSession(values));
      }
    });
  };
  onMorningChange = (checked) => {
    this.setState(
      {
        isMorning: checked,
      },
      this.updateValue
    );
  };
  onNoonChange = (checked) => {
    this.setState(
      {
        isNoon: checked,
      },
      this.updateValue
    );
  };
  onEveningChange = (checked) => {
    this.setState(
      {
        isEvening: checked,
      },
      this.updateValue
    );
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div>
        <div className='certain-category-search-wrapper' />

        <Row>
          <Col lg={24} md={24} sm={24} xs={24} />
          <Col lg={12} md={12} sm={24} xs={24}>
            <Card bordered={false} title='Scan Session'>
              <Form layout='horizontal' onSubmit={this.updateValue}>
                {getFieldDecorator("isMorning", {
                  valuePropName: "checked",
                  initialValue: this.state.isMorning,
                })(
                  <Checkbox onChange={this.onMorningChange}> Morning</Checkbox>
                )}
                {getFieldDecorator("isNoon", {
                  valuePropName: "checked",
                  initialValue: this.state.isNoon,
                })(<Checkbox onChange={this.onNoonChange}> Noon </Checkbox>)}
                {getFieldDecorator("isEvening", {
                  valuePropName: "checked",
                  initialValue: this.state.isEvening,
                })(
                  <Checkbox onChange={this.onEveningChange}> Evening</Checkbox>
                )}
                <Form.Item>
                  <Button
                    type='primary'
                    onClick={this.updateValue}
                    style={{
                      marginBottom: "-13px",
                      marginTop: "15px",
                      marginLeft: "15px",
                    }}>
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

InventoryScan = Form.create()(InventoryScan);

function mapStateToProps(state) {
  const { Common } = state;
  const { get_scan_session, post_scan_session, update_scan_session } = Common;
  return { get_scan_session, post_scan_session, update_scan_session };
}

const AppContainer = connect(mapStateToProps)(InventoryScan);

export default AppContainer;
