import axios from "axios";

const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var cookies = require('browser-cookies');

function getloginInfo() {
    let webtoken = cryptr.decrypt(cookies.get('wteoben'));
    let headers = {
        'Content-Type': 'application/json',
        'type': 'Web',
        'x-access-token': webtoken,
    }
    return headers;
}

// const host = "http://api.jeweltrace.in"

export function listConfigurationDisplay(listConfigurationDisplayCallback) {
    return dispatch => {
        // return axios.get(host+"/displayConfig",
        return axios.get("/displayConfig",
            {headers: getloginInfo()}
        )
            .then(response => {
                if (response.data !== "") {
                    dispatch({
                        type: "LIST_CONFIGURATION_DISPLAY",
                        data: response.data.data.displayConfig
                    });

                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        listConfigurationDisplayCallback(error);
                        window.location.href = '/';
                    } else {
                        console.log(error);
                    }
                }
            });
    }
}