import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import IntlMessages from "util/IntlMessages";
import { NAV_STYLE_MINI, THEME_TYPE_LITE } from "constants/ThemeSetting";
import { getSyncData } from "./../../actions/index";
import Auxiliary from "util/Auxiliary";
import DasbhoardIcon from "./../../assets/images/icons/sidebarIcon/icon-dashboard.svg";
import DasbhoardIconActive from "./../../assets/images/icons/sidebarIcon/icon-dashboard-active.svg";
import JtstockViewIcon from "./../../assets/images/icons/sidebarIcon/icon-stock.svg";
import JtstockViewIconActive from "./../../assets/images/icons/sidebarIcon/icon-stock-active.svg";
import QuotationIcon from "./../../assets/images/icons/sidebarIcon/icon-transactions.svg";
import QuotationIconActive from "./../../assets/images/icons/sidebarIcon/icon-transactions-active.svg";
import CustomerIcon from "./../../assets/images/icons/sidebarIcon/icon-customers.svg";
import CustomerIconActive from "./../../assets/images/icons/sidebarIcon/icon-customers-active.svg";
import EmployeesIcon from "./../../assets/images/icons/sidebarIcon/icon-employees.svg";
import EmployeesIconActive from "./../../assets/images/icons/sidebarIcon/icon-employees-active.svg";
import ReportIcon from "./../../assets/images/icons/sidebarIcon/icon-reports.svg";
import ReportIconActive from "./../../assets/images/icons/sidebarIcon/icon-reports-active.svg";
import moment from "moment";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  constructor() {
    super();
    this.state = {
      dasbhoardIcon: DasbhoardIcon,
      jtstockViewIcon: JtstockViewIcon,
      quotationIcon: QuotationIcon,
      customerIcon: CustomerIcon,
      employeesIcon: EmployeesIcon,
      reportIcon: ReportIcon,
      lastSyncTime: "",
      btnLoading: false,
      positionSyncBtn: "absolute",
      isClient: localStorage.getItem("isClient"),
    };
  }

  componentWillMount() {
    // let user= localStorage.getItem('user_id');

    try {
      let { pathname } = this.props;
      // console.log("onSelects1");
      // console.log(pathname);
      // console.log("onSelects2");
      let key = pathname.split("/")[1];
      this.activeIcon(key);
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pathname != this.props.pathname) {
      try {
        let { pathname } = this.props;
        let key = pathname.split("/")[1];
        this.activeIcon(key);
      } catch (error) {
        console.log(error);
      }
    }

    if (prevProps.get_sync != this.props.get_sync) {
      if (this.props.get_sync.status) {
        let lastSyncTime = this.props.get_sync.data.time;
        this.setState({
          lastSyncTime: moment(lastSyncTime).format("DD-MM-YYYY hh:mm A"),
          btnLoading: false,
        });
      } else {
        this.setState({
          lastSyncTime: "",
          btnLoading: false,
        });
      }
    }
  }

  onOpenChange = (e) => {
    if (e.length > 1) {
      if (e[1] == "reports" || e[2] == "reports") {
        this.setState({
          positionSyncBtn: "unset",
        });
      } else {
        this.setState({
          positionSyncBtn: "absolute",
        });
      }
    } else {
      this.setState({
        positionSyncBtn: "absolute",
      });
    }
  };

  onSelect = (e) => {
    this.activeIcon(e.key);
  };

  SyncData = (e) => {
    //console.log(e);
    this.setState({
      btnLoading: true,
    });
    const { dispatch } = this.props;
    dispatch(getSyncData());
  };

  activeIcon = (key) => {
    this.setState({
      dasbhoardIcon: DasbhoardIcon,
      jtstockViewIcon: JtstockViewIcon,
      quotationIcon: QuotationIcon,
      customerIcon: CustomerIcon,
      employeesIcon: EmployeesIcon,
      reportIcon: ReportIcon,
    });

    if (key === "dashboard") {
      this.setState({
        dasbhoardIcon: DasbhoardIconActive,
      });
    } else if (key === "jtstockView") {
      this.setState({
        jtstockViewIcon: JtstockViewIconActive,
      });
    } else if (
      key === "quotationView" ||
      key === "saleView" ||
      key === "orderView" ||
      key === "billingView"
    ) {
      this.setState({
        quotationIcon: QuotationIconActive,
      });
    } else if (key === "customerView") {
      this.setState({
        customerIcon: CustomerIconActive,
      });
    } else if (key === "employees") {
      this.setState({
        employeesIcon: EmployeesIconActive,
      });
    } else if (
      key === "TrayActivitiesReport" ||
      key === "conversionRatio" ||
      key === "SalesAudit" ||
      key === "actionReport" ||
      key === "deadStock" ||
      key === "stocktallyView" ||
      key === "agingreportView"
    ) {
      this.setState({
        reportIcon: ReportIconActive,
      });
    }
  };

  render() {
    const { navStyle, themeType, verticalNavStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    const usertype = localStorage.getItem("type_user");
    return (
      <Auxiliary>
        <SidebarLogo />
        <CustomScrollbars className='gx-layout-sider-scrollbar'>
          {usertype === "companyAdmin" ? (
            <React.Fragment>
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                inlineCollapsed={verticalNavStyle === NAV_STYLE_MINI}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode='inline'
                onOpenChange={this.onOpenChange}
                onSelect={this.onSelect}
                //  style={{color:"#595B62 !important"}}
              >
                <MenuItemGroup key='main'>
                  <Menu.Item key='dashboard'>
                    <Link to='/dashboard'>
                      <img
                        src={this.state.dasbhoardIcon}
                        className='sidebar-icon'></img>
                      <IntlMessages id='sidebar.dashboard' />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='jtstockView'>
                    <Link to='/jtstockView'>
                      {/**  <Icon type="unordered-list" /> */}
                      <img
                        src={this.state.jtstockViewIcon}
                        className='sidebar-icon'></img>
                      <IntlMessages id='sidebar.Stocks' />
                    </Link>
                  </Menu.Item>

                                <Menu.Item key='soldstockView'>
                                    <Link to='/soldstockView'>
                                        <img
                                            src={this.state.jtstockViewIcon}
                                            className='sidebar-icon'></img>
                                        <IntlMessages id='sidebar.soldStock' />
                                    </Link>
                                </Menu.Item>

                  <SubMenu
                    key='transaction'
                    title={
                      <span>
                        {/**   <Icon type="dollar-circle" theme="filled" /> */}
                        <img
                          src={this.state.quotationIcon}
                          className='sidebar-icon'></img>
                        <IntlMessages id='sidebar.transactions' />
                      </span>
                    }>
                    <Menu.Item key='quotationView'>
                      <Link to='/quotationView'>
                        <i className='icon icon-sweet-alert1' />
                        <IntlMessages id='sidebar.quotationPage' />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key='saleView'>
                      <Link to='saleView'>
                        <i className='icon icon-badge1' />
                        <IntlMessages id='sidebar.SalesPage' />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key='memoView'>
                      <Link to='memoView'>
                        <i className='icon icon-product-list1' />
                        Memo
                      </Link>
                    </Menu.Item>

                                    <Menu.Item key='issueView'>
                                        <Link to='issueView'>
                                            <i className='icon icon-badge1' />
                                            Issued
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key='receiveView'>
                                        <Link to='receiveView'>
                                            <i className='icon icon-shopping-cart1' />
                                            Received
                                        </Link>
                                    </Menu.Item>

                    <Menu.Item key='orderView'>
                      <Link to='orderView'>
                        <i className='icon icon-shopping-cart1' />
                        Order
                      </Link>
                    </Menu.Item>
                    <Menu.Item key='billingView'>
                      <Link to='billingView'>
                        <i className='icon icon-badge1' />
                        <IntlMessages id='sidebar.BillingPage' />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key='transferView'>
                      <Link to='transferView'>
                        <i className='icon icon-badge1' />
                        Transfer
                      </Link>
                    </Menu.Item>
                  </SubMenu>


                                <SubMenu
                                    key='master'
                                    title={
                                        <span>
                                            <img
                                                src={this.state.reportIcon}
                                                className='sidebar-icon'></img>
                                            Master
                                        </span>
                                    }>
                                    <Menu.Item key='statusView'>
                                        <Link to='/statusView'>
                                            <i className='icon icon-sweet-alert1' />
                                           Status
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>



                  <Menu.Item key='customerView'>
                    <Link to='customerView'>
                      {/**   <Icon type="usergroup-add" /> */}
                      <img
                        src={this.state.customerIcon}
                        className='sidebar-icon'></img>
                      <IntlMessages id='sidebar.customer' />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='employees'>
                    <Link to='employees'>
                      <img
                        src={this.state.employeesIcon}
                        className='sidebar-icon'
                        style={{ height: "20px" }}></img>
                      <IntlMessages id='sidebar.employees' />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key='reports'
                    title={
                      <span>
                        <img
                          src={this.state.reportIcon}
                          className='sidebar-icon'></img>
                        <IntlMessages id='sidebar.reports' />
                      </span>
                    }>
                    <Menu.Item key='TrayActivitiesReport'>
                      <Link to='/TrayActivitiesReport'>
                        <i className='icon icon-area-chart1' />
                        <IntlMessages id='sidebar.trayActivitiesPage' />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key='conversionRatio'>
                      <Link to='/conversionRatio'>
                        <i className='icon icon-radiobutton1' />
                        <IntlMessages id='sidebar.conversionRatioPage' />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key='SalesAudit'>
                      <Link to='/SalesAudit'>
                        <i className='icon icon-progress1' />
                        Sales Audit
                      </Link>
                    </Menu.Item>

                    <Menu.Item key='actionReport'>
                      <Link to='/actionReport'>
                        <i className='icon icon-chart-line1' />
                        Design Demand
                      </Link>
                    </Menu.Item>

                    <Menu.Item key='deadStock'>
                      <Link to='/deadStock'>
                        <i className='icon icon-product-list1' />
                        Find Dead Inventory
                      </Link>
                    </Menu.Item>

                    <Menu.Item key='stocktallyView'>
                      <Link to='/stocktallyView'>
                        <i className='icon icon-chart-radial1' />
                        Inventory Scan
                      </Link>
                    </Menu.Item>

                                    <Menu.Item key='agingreportView'>
                                        <Link to='/agingreportView'>
                                            <i className='icon icon-chart-radial1' />
                                            Aging Report
                                        </Link>
                                    </Menu.Item>
                  </SubMenu>
                </MenuItemGroup>
              </Menu>
              {this.state.isClient == "true" ? (
                <div
                  style={{
                    bottom: 0,
                    position: this.state.positionSyncBtn,
                    width: "90%",
                    marginLeft: "5%",
                    marginTop: "10%",
                  }}>
                  <p>Last Sync : {this.state.lastSyncTime}</p>
                  <Button
                    loading={this.state.btnLoading}
                    onClick={this.SyncData}
                    style={{ width: "100%" }}
                    ghost>
                    Sync
                  </Button>
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          ) : (
            ""
          )}
          {usertype === "branchAdmin" ? (
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              inlineCollapsed={verticalNavStyle === NAV_STYLE_MINI}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode='inline'
              //   onOpenChange={this.onOpenChange}
              onSelect={this.onSelect}
              //  style={{color:"#595B62 !important"}}
            >
              <MenuItemGroup key='main'>
                <Menu.Item key='dashboard'>
                  <Link to='/dashboard'>
                    <img
                      src={this.state.dasbhoardIcon}
                      className='sidebar-icon'></img>
                    <IntlMessages id='sidebar.dashboard' />
                  </Link>
                </Menu.Item>

                <Menu.Item key='jtstockView'>
                  <Link to='/jtstockView'>
                    {/**  <Icon type="unordered-list" /> */}
                    <img
                      src={this.state.jtstockViewIcon}
                      className='sidebar-icon'></img>
                    <IntlMessages id='sidebar.Stocks' />
                  </Link>
                </Menu.Item>

                            <Menu.Item key='soldstockView'>
                                <Link to='/soldstockView'>
                                    <img
                                        src={this.state.jtstockViewIcon}
                                        className='sidebar-icon'></img>
                                    <IntlMessages id='sidebar.soldStock' />
                                </Link>
                            </Menu.Item>

                <SubMenu
                  key='transaction'
                  title={
                    <span>
                      {/**   <Icon type="dollar-circle" theme="filled" /> */}
                      <img
                        src={this.state.quotationIcon}
                        className='sidebar-icon'></img>
                      <IntlMessages id='sidebar.transactions' />
                    </span>
                  }>
                  <Menu.Item key='quotationView'>
                    <Link to='/quotationView'>
                      <i className='icon icon-sweet-alert1' />
                      <IntlMessages id='sidebar.quotationPage' />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='saleView'>
                    <Link to='saleView'>
                      <i className='icon icon-badge1' />
                      <IntlMessages id='sidebar.SalesPage' />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='memoView'>
                    <Link to='memoView'>
                      <i className='icon icon-product-list1' />
                      Memo
                    </Link>

                                    <Menu.Item key='issueView'>
                                        <Link to='issueView'>
                                            <i className='icon icon-badge1' />
                                            Issued
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key='receiveView'>
                                        <Link to='receiveView'>
                                            <i className='icon icon-shopping-cart1' />
                                            Received
                                        </Link>
                                    </Menu.Item>

                  </Menu.Item>
                  <Menu.Item key='orderView'>
                    <Link to='orderView'>
                      <i className='icon icon-shopping-cart1' />
                      Order
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='billingView'>
                    <Link to='billingView'>
                      <i className='icon icon-badge1' />
                      <IntlMessages id='sidebar.BillingPage' />
                    </Link>
                  </Menu.Item>
                </SubMenu>

                            <SubMenu
                                key='master'
                                title={
                                    <span>
                                        <img
                                            src={this.state.reportIcon}
                                            className='sidebar-icon'></img>
                                        Master
                                    </span>
                                }>
                                <Menu.Item key='statusView'>
                                    <Link to='/statusView'>
                                        <i className='icon icon-sweet-alert1' />
                                        Status
                                    </Link>
                                </Menu.Item>
                            </SubMenu>

                <Menu.Item key='customerView'>
                  <Link to='customerView'>
                    {/**   <Icon type="usergroup-add" /> */}
                    <img
                      src={this.state.customerIcon}
                      className='sidebar-icon'></img>
                    <IntlMessages id='sidebar.customer' />
                  </Link>
                </Menu.Item>

                <SubMenu
                  key='reports'
                  title={
                    <span>
                      <img
                        src={this.state.reportIcon}
                        className='sidebar-icon'></img>
                      <IntlMessages id='sidebar.reports' />
                    </span>
                  }>
                  <Menu.Item key='TrayActivitiesReport'>
                    <Link to='/TrayActivitiesReport'>
                      <i className='icon icon-area-chart1' />
                      <IntlMessages id='sidebar.trayActivitiesPage' />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='conversionRatio'>
                    <Link to='/conversionRatio'>
                      <i className='icon icon-radiobutton1' />
                      <IntlMessages id='sidebar.conversionRatioPage' />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='SalesAudit'>
                    <Link to='/SalesAudit'>
                      <i className='icon icon-progress1' />
                      Sales Audit
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='actionReport'>
                    <Link to='/actionReport'>
                      <i className='icon icon-chart-line1' />
                      Design Demand
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='deadStock'>
                    <Link to='/deadStock'>
                      <i className='icon icon-product-list1' />
                      Find Dead Inventory
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='stocktallyView'>
                    <Link to='/stocktallyView'>
                      <i className='icon icon-chart-radial1' />
                      Inventory Scan
                    </Link>
                  </Menu.Item>

                                <Menu.Item key='agingreportView'>
                                    <Link to='/agingreportView'>
                                        <i className='icon icon-chart-radial1' />
                                        Aging Report
                                    </Link>
                                </Menu.Item>

                </SubMenu>
              </MenuItemGroup>
            </Menu>
          ) : (
            ""
          )}

          {usertype === "floorAdmin" ? (
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              inlineCollapsed={verticalNavStyle === NAV_STYLE_MINI}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode='inline'
              //   onOpenChange={this.onOpenChange}
              onSelect={this.onSelect}
              //  style={{color:"#595B62 !important"}}
            >
              <MenuItemGroup key='main'>
                <Menu.Item key='dashboard'>
                  <Link to='/dashboard'>
                    <img
                      src={this.state.dasbhoardIcon}
                      className='sidebar-icon'></img>
                    <IntlMessages id='sidebar.dashboard' />
                  </Link>
                </Menu.Item>

                <Menu.Item key='jtstockView'>
                  <Link to='/jtstockView'>
                    {/**  <Icon type="unordered-list" /> */}
                    <img
                      src={this.state.jtstockViewIcon}
                      className='sidebar-icon'></img>
                    <IntlMessages id='sidebar.Stocks' />
                  </Link>
                </Menu.Item>

                            <Menu.Item key='soldstockView'>
                                <Link to='/soldstockView'>
                                    <img
                                        src={this.state.jtstockViewIcon}
                                        className='sidebar-icon'></img>
                                    <IntlMessages id='sidebar.soldStock' />
                                </Link>
                            </Menu.Item>

                <SubMenu
                  key='transaction'
                  title={
                    <span>
                      {/**   <Icon type="dollar-circle" theme="filled" /> */}
                      <img
                        src={this.state.quotationIcon}
                        className='sidebar-icon'></img>
                      <IntlMessages id='sidebar.transactions' />
                    </span>
                  }>
                  <Menu.Item key='quotationView'>
                    <Link to='/quotationView'>
                      <i className='icon icon-sweet-alert1' />
                      <IntlMessages id='sidebar.quotationPage' />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='saleView'>
                    <Link to='saleView'>
                      <i className='icon icon-badge1' />
                      <IntlMessages id='sidebar.SalesPage' />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key='memoView'>
                    <Link to='memoView'>
                      <i className='icon icon-product-list1' />
                      Memo
                    </Link>
                  </Menu.Item>

                                <Menu.Item key='issueView'>
                                    <Link to='issueView'>
                                        <i className='icon icon-badge1' />
                                        Issued
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key='receiveView'>
                                    <Link to='receiveView'>
                                        <i className='icon icon-shopping-cart1' />
                                        Received
                                    </Link>
                                </Menu.Item>

                  <Menu.Item key='orderView'>
                    <Link to='orderView'>
                      <i className='icon icon-shopping-cart1' />
                      Order
                    </Link>
                  </Menu.Item>
                  <Menu.Item key='billingView'>
                    <Link to='billingView'>
                      <i className='icon icon-badge1' />
                      <IntlMessages id='sidebar.BillingPage' />
                    </Link>
                  </Menu.Item>
                </SubMenu>


                            <SubMenu
                                key='master'
                                title={
                                    <span>
                                        <img
                                            src={this.state.reportIcon}
                                            className='sidebar-icon'></img>
                                       Master
                                    </span>
                                }>
                                <Menu.Item key='statusView'>
                                    <Link to='/statusView'>
                                        <i className='icon icon-sweet-alert1' />
                                       Status
                                    </Link>
                                </Menu.Item>
                            </SubMenu>


                <Menu.Item key='customerView'>
                  <Link to='customerView'>
                    {/**   <Icon type="usergroup-add" /> */}
                    <img
                      src={this.state.customerIcon}
                      className='sidebar-icon'></img>
                    <IntlMessages id='sidebar.customer' />
                  </Link>
                </Menu.Item>

                <SubMenu
                  key='reports'
                  title={
                    <span>
                      <img
                        src={this.state.reportIcon}
                        className='sidebar-icon'></img>
                      <IntlMessages id='sidebar.reports' />
                    </span>
                  }>
                  <Menu.Item key='TrayActivitiesReport'>
                    <Link to='/TrayActivitiesReport'>
                      <i className='icon icon-area-chart1' />
                      <IntlMessages id='sidebar.trayActivitiesPage' />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='conversionRatio'>
                    <Link to='/conversionRatio'>
                      <i className='icon icon-radiobutton1' />
                      <IntlMessages id='sidebar.conversionRatioPage' />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='SalesAudit'>
                    <Link to='/SalesAudit'>
                      <i className='icon icon-progress1' />
                      Sales Audit
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='actionReport'>
                    <Link to='/actionReport'>
                      <i className='icon icon-chart-line1' />
                      Design Demand
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='deadStock'>
                    <Link to='/deadStock'>
                      <i className='icon icon-product-list1' />
                      Find Dead Inventory
                    </Link>
                  </Menu.Item>

                  <Menu.Item key='stocktallyView'>
                    <Link to='/stocktallyView'>
                      <i className='icon icon-chart-radial1' />
                      Inventory Scan
                    </Link>
                  </Menu.Item>

                                <Menu.Item key='agingreportView'>
                                    <Link to='/agingreportView'>
                                        <i className='icon icon-chart-radial1' />
                                        Aging Report
                                    </Link>
                                </Menu.Item>
                </SubMenu>
              </MenuItemGroup>
            </Menu>
          ) : (
            ""
          )}

          {usertype === "superAdmin" ? (
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              inlineCollapsed={verticalNavStyle === NAV_STYLE_MINI}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode='inline'>
              <MenuItemGroup key='main'>
                <Menu.Item key='companyManagement'>
                  <Link to='/companyManagement'>
                    <i className='icon icon-dasbhoard' />
                    Company
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
            </Menu>
          ) : (
            ""
          )}
        </CustomScrollbars>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, Common }) => {
  const { navStyle, verticalNavStyle, themeType, locale, pathname } = settings;
  const { get_sync: get_sync } = Common;
  return { navStyle, verticalNavStyle, themeType, locale, pathname, get_sync };
};
export default connect(mapStateToProps)(SidebarContent);
