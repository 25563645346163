import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Modal, Form, Table, Upload, Card, Col, Icon, InputNumber, Button, message, Row, Radio, Switch, Select, AutoComplete, Input, Divider, Checkbox } from 'antd';
import {
    AddCompany,
    // GetModuleDetails,checkUserName,checkCompanyName,
    getCompany
} from "../../../actions/scadmin";
message.config({
    top: 100,
    duration: 1,
    maxCount: 1,
});
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
const Option = Select.Option;
class AddComapany extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            copied: false,
            companyType: 0,
            Type:0,
            ValidUservisible: false,
            ValidCompanyvisible: false,
            moduleList: []
        }
    };
    componentDidMount() {
        const { dispatch } = this.props;
        // dispatch(GetModuleDetails());
    }
    componentDidUpdate(prevProps, prevState) {
        var that = this;

        try {

            if (prevProps.add_company != that.props.add_company) {
                if (that.props.add_company.status) {
                    message.success("Company added successfully");
                    this.setState({
                        visible: false,
                    });
                    this.props.compantTableVisibility(true);
                    const { dispatch } = this.props;
                    dispatch(getCompany());

                } else {
                    message.error(that.props.add_company.errors.message);
                }
            }

            if (prevProps.module_list != that.props.module_list) {
                this.setState({
                    moduleList: that.props.module_list.data_array
                })
            }

            if (prevProps.check_user_exit != that.props.check_user_exit) {
                if (that.props.check_user_exit.status) {
                    this.setState({
                        ValidUservisible: false,
                    });
                } else {
                    this.setState({
                        ValidUservisible: true,
                    });
                }

            }

            if (prevProps.check_company_exit != that.props.check_company_exit) {
                if (that.props.check_company_exit.status) {
                    this.setState({
                        ValidCompanyvisible: false,
                    });
                } else {
                    this.setState({
                        ValidCompanyvisible: true,
                    });
                }

            }


        } catch (error) {
            console.log(error);
        }




    }
    handleCancel = () => {
        this.setState({ visible: false });
        this.props.compantTableVisibility(true);
    }
    onChangeCompanyType = (e) => {
        this.setState({
            companyType: e.target.value,
        });
    }
    checkCompayName = (e) => {
        this.props.form.validateFields(['compnayname'], (err, values) => {
            const { dispatch } = this.props;
            // dispatch(checkCompanyName(values['compnayname']));
        })

    }
    checkUserName = (e) => {
        this.props.form.validateFields(['username'], (err, values) => {
            // console.log("onBlure");
            // console.log(values['username']);
            let username = values['username'];
            const { dispatch } = this.props;
            // dispatch(checkUserName(username));
        })

    }
    checkCompanyoff = (e) => {
        this.setState({
            ValidCompanyvisible: false,
        });
    }

    checkuserofff = (e) => {
        this.setState({
            ValidUservisible: false,
        });
    }

    handlePhoneInput = (event) => {
        const target = event.target;
        const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        if (input.length > 6) { target.value = `(${zip}) ${middle}-${last}`; }
        else if (input.length > 3) { target.value = `(${zip}) ${middle}`; }
        else if (input.length > 0) { target.value = `(${zip}`; }

        /*
        let data = e.target.value;
        formatPhoneNumber
        data = data.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
       e.target.value = data;
       */
    }

    SingupSubmit = (e) => {
        e.preventDefault();
                
        /*companyType
        0 : Online Company 1 : Offline Company NO API 2 : Fully Online No database 3 : FTP Sync*/

        /*type
        0 :retailer, 1:wholesaler, 2:manufacturer*/

        this.props.form.validateFields((err, values) => {
            if (!err) {
                let creatCom = {
                    "userInfo": {
                        "phone": values.phone,
                        "firstname": values.firstname,
                        "lastname": values.lastname,
                        //"password": 'admin',
                        "usertype": "ADMIN",
                        "type": 0,
                        "email": values.email,
                    },
                    "branch": {
                        "name": "Branch",
                        "address": "",
                        "phone": ""
                    },
                    "mfloor": {
                        "name": "Floor",
                    },
                    "section": {
                        "name": "section",
                    },
                    "subSection": {
                        "name": "subsection",
                    },
                    "company": {
                        "name": values.compnayname,
                        "address": values.address,
                        "phone": values.phone,
                        "type": values.type,
                        "noOfUserAllowed": values.noOfUserAllowed,
                        "companyType": values.companyType,
                        "tax_no": values.tax_no,
                        "licenseStartedDate": new Date().toISOString(),
                        "licenseDays": values.licenseDays,
                        //"server_url":values.server_url,
                        "imgLogo": "https://iili.io/H0l1Mx.png"
                    },
                }
                // console.log(creatCom);
                const { dispatch } = this.props;
                dispatch(AddCompany(creatCom));
            }
        });
    }

    render() {
        const { visible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        return (
            <React.Fragment>
                <Card title="Add Company" bordered={false}>
                    <Form onSubmit={this.SingupSubmit} >
                        <Row>
                            <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Company Name"
                                >
                                    {getFieldDecorator('compnayname', {
                                        rules: [{ required: true, message: 'Please input your company name!' }],
                                    })(
                                        <Input size="default" placeholder="Company Name" onChange={this.checkCompanyoff} onBlur={this.checkCompayName} />
                                    )}
                                    {this.state.ValidCompanyvisible ? <div className="has-error" style={{ color: "#f5222d" }}>Company name already in use</div> : ""}
                                </FormItem>

                            </Col>

                            <Col md={1} sm={12} xs={24}></Col>
                            <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Address"
                                >
                                    {getFieldDecorator('address', {
                                        rules: [{ required: true, message: 'Please input address!' }],
                                    })(
                                        <TextArea rows={2} placeholder="Address" />
                                    )}
                                </FormItem>

                            </Col>
                        </Row>

                        <Row>
                            <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Contact Number"
                                >
                                    {getFieldDecorator('phone', {
                                        rules: [{ required: true, message: 'Please input contact number!' }],
                                    })(
                                        <Input size="default" placeholder="Contact Number / User Name" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col md={1} sm={12} xs={24}></Col>
                            <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Tax No"
                                >
                                    {getFieldDecorator('tax_no', {
                                        rules: [{ required: true, message: 'Please input tax no!' }],
                                    })(
                                        <Input size="default" placeholder="Tax No" />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Type"
                                >
                                    {getFieldDecorator('type', {
                                        initialValue:0,
                                        rules: [{ required: true, message: 'Please input Type!' }],
                                    })(
                                        <RadioGroup onChange={this.onChangeCompanyType} >
                                            <Radio value={0}>Retailers</Radio>
                                            <Radio value={1}>Wholesaler</Radio>
                                            <Radio value={2}>Manufacturer</Radio>
                                        </RadioGroup>
                                    )}
                                </FormItem>
                            </Col>
                            <Col md={1} sm={12} xs={24}></Col>
                            <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="No of Users"
                                >
                                    {getFieldDecorator('noOfUserAllowed', {
                                        initialValue: 5,
                                        rules: [{ required: true, message: 'Please input no of users!' }],
                                    })(
                                        <InputNumber size="default" min={0} max={1000} placeholder="No of Users" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>                    
                        <Row>
                            <Col md={11} sm={12} xs={24}>

                                <FormItem
                                    {...formItemLayout}
                                    label="First Name"
                                >
                                    {getFieldDecorator('firstname', {
                                        rules: [{ required: true, message: 'Please input first name!' }],
                                    })(
                                        <Input size="default" placeholder="First Name" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col md={1} sm={12} xs={24}></Col>
                            <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Last Name"
                                >
                                    {getFieldDecorator('lastname', {
                                        rules: [{ required: true, message: 'Please input last name!' }],
                                    })(
                                        <Input size="default" placeholder="Last Name" />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="E-mail"
                                >
                                    {getFieldDecorator('email', {
                                        rules: [{ required: true, message: 'Please input E-mail!' },
                                        {
                                            type: 'email', message: 'The input is not valid E-mail!',
                                        }
                                        ],
                                    },

                                    )(
                                        <Input size="default" placeholder="E-mail" />
                                    )}
                                </FormItem>


                            </Col>
                            <Col md={1} sm={12} xs={24}></Col>
                            <Col md={11} sm={12} xs={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label="License Days"
                                >
                                    {getFieldDecorator('licenseDays',
                                        {
                                            initialValue: 365,
                                            rules: [{ required: true, message: 'Please input license days!' }],
                                        })(
                                            <InputNumber min={0} max={1000} size="default" placeholder="license Days" style={{ width: '100%' }} />
                                        )}
                                </FormItem>

                            </Col>
                        </Row>

                        <Row>
                            <Col md={11} sm={12} xs={20}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Company Type"
                                >
                                    {getFieldDecorator('companyType', {
                                        initialValue:0,
                                        rules: [{ required: true, message: 'Please select companyType!' }],
                                    })(
                                        <Select placeholder="Please select compaany type">
                                            <Option value={0}>Normal</Option>
                                            <Option value={1}>Offline no sync</Option>
                                            <Option value={2}>Fully Online</Option>
                                            <Option value={3}>FTP Sync</Option>
                                            <Option value={4}>ERP Sync</Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                          
                    
                        </Row> 
                    
                        <Row>
                            <Col md={18} sm={12} xs={24}></Col>
                            <Col md={5} sm={12} xs={24} style={{ textAlign: "right" }}>
                                <Button type="primary" htmlType="submit">Submit</Button>
                                <Button type="default" onClick={this.handleCancel} >Cancel</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </React.Fragment>
        )
    }
}

AddComapany = Form.create()(AddComapany);

function mapStateToProps(state) {
    const { scadmin } = state
    const { add_company: add_company, module_list: module_list, check_user_exit: check_user_exit, check_company_exit: check_company_exit } = scadmin
    return { add_company, module_list, check_user_exit, check_company_exit }
}

const AppContainer = connect(
    mapStateToProps
)(AddComapany);
export default AppContainer
