import { createCustomer, getCustomer } from "../../actions/index";
import { connect } from "react-redux";
import React, { Component } from "react";
import {
  Modal,
  Form,
  Table,
  List,
  Col,
  Icon,
  DatePicker,
  Card,
  Button,
  Radio,
  message,
  Row,
  Switch,
  Select,
  AutoComplete,
  Divider,
  Input,
} from "antd";
import "./custome.css";
import socketIOClient from "socket.io-client";
import { countryList } from "./country";
var cookies = require("browser-cookies");
message.config({
  top: 100,
  duration: 10,
  maxCount: 3,
});
const { TextArea } = Input;

class AddCustomer extends Component {
  constructor(props) {
    super(props);
    let root = JSON.parse(cookies.get("root"));
    this.state = {
      data: [],
      loading: false,
      visible: false,
      username: root.companyId,
      isCountryIndia: false,
      customerType: true,
      isPrivateCustomer: false,
    };

    // let root =  JSON.parse(cookies.get('root'));
    // console.log("root.companyId");
    // console.log({"root": root,"username":'neha'});

    //  socket.emit("Notification", {"root": root});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.create_customer != this.props.create_customer) {
      try {
        if (this.props.create_customer.status) {
          message.success("Add customer sucessfully");
          this.setState({
            visible: false,
          });
          this.props.form.resetFields();
          this.props.createCustomerClose();
          // const { dispatch } = this.props;
          //  dispatch(getCustomer(0,10));
        } else {
          message.error(this.props.create_customer.errors.msg);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
    this.props.form.resetFields();
    this.props.createCustomerClose();
  };

  handleOk = (e) => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      let root = JSON.parse(cookies.get("root"));
      const { dispatch } = this.props;
      values.root = root;
      // console.log(values);
      values.isPrivateCustomer = this.state.isPrivateCustomer;
      dispatch(createCustomer(values));
    });
  };

  handleOnChangeCountry = (country) => {
    // console.log(country);
    if (country === "India") {
      this.setState({
        isCountryIndia: true,
      });
    } else {
      this.setState({
        isCountryIndia: false,
      });
    }
  };

  onChangeCustomerType = (e) => {
    if (e.target.value === "Corporate") {
      this.setState({
        customerType: true,
        isPrivateCustomer: false,
      });
    } else {
      this.setState({
        customerType: false,
        isPrivateCustomer: true,
      });
    }
  };

  render() {
    const { visible } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout1 = {
      labelCol: { span: 24 },
      //   labalAlign:{marginLeft: "15px"},
      wrapperCol: { span: 24 },
    };
    const formItemLayout = {
      //labelCol: { span: 24 },
      //   labalAlign:{marginLeft: "15px"},
      //wrapperCol: { span: 24 },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    const formItemLayoutHorizan = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };
    return (
      <React.Fragment>
        <Card
          bodyStyle={{ padding: "0" }}
          style={{ borderRadius: "4px" }}
          bordered={false}>
          <Card
            style={{
              borderLeft: "9px solid",
              marginBottom: "5px",
              color: "#4285F4",
              borderRadius: "4px",
            }}
            bodyStyle={{ padding: "0" }}
            bordered={false}>
            <Row>
              <Col
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{ paddingLeft: "60px" }}>
                <h6
                  className='customer-report-title-back icon-charvlet-back'
                  onClick={() => this.props.createCustomerClose()}>
                  <Icon type='left' className='text-color-4285F4' /> Back to
                  Customers
                </h6>
                <h1 className='customer-report-title'>Add Customer</h1>
              </Col>
            </Row>
          </Card>
          <Form
            onSubmit={this.FormSubmit}
            hideRequiredMark={false}
            colon={false}
            style={{ paddingLeft: "15%", paddingRight: "10%" }}
            labelAlign={"left"}>
            <Row>
              <Col span={24}>
                <Form.Item
                  className='customFormItem'
                  style={{ paddingLeft: "14px" }}
                  label='Type of Customer :'>
                  {getFieldDecorator("isPrivateCustomer", {
                    initialValue: "Corporate",
                    rules: [
                      {
                        required: false,
                        message: "Please select type of customer !",
                      },
                    ],
                  })(
                    <Radio.Group
                      buttonStyle='solid'
                      onChange={this.onChangeCustomerType}>
                      <Radio value='Corporate'>Corporate</Radio>
                      <Radio value='Private'>Private</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <React.Fragment>
              <Row>
                <Col span={12}>
                  <Form.Item
                    className='customFormItem'
                    {...formItemLayout}
                    label='First Name'>
                    {getFieldDecorator("firstName", {
                      rules: [
                        {
                          required: true,
                          message: "Please input first name !",
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className='customFormItem'
                    {...formItemLayout}
                    label='Family Name'>
                    {getFieldDecorator("familyName", {
                      rules: [
                        {
                          required: true,
                          message: "Please input family name !",
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
              {this.state.customerType ? (
                <React.Fragment>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        className='customFormItem'
                        label='Email Address'
                        {...formItemLayout}>
                        {getFieldDecorator("email", {
                          rules: [
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: false,
                              message: "Please input your E-mail!",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className='customFormItem'
                        label='Company Name'
                        {...formItemLayout}>
                        {getFieldDecorator("companyName", {
                          rules: [
                            {
                              required: false,
                              message: "Please input company name!",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        className='customFormItem'
                        label='Email Address'
                        {...formItemLayout}>
                        {getFieldDecorator("email", {
                          rules: [
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: false,
                              message: "Please input your E-mail!",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
              {this.state.customerType ? (
                <React.Fragment></React.Fragment>
              ) : (
                <Row>
                  <Col span={12}>
                    <Form.Item
                      className='customFormItem'
                      label='Date of Birth'
                      {...formItemLayout}>
                      {getFieldDecorator("birthDate", {
                        rules: [
                          {
                            required: false,
                            message: "Please input date of birth!",
                          },
                        ],
                      })(
                        <DatePicker
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className='customFormItem'
                      label='Gender'
                      {...formItemLayout}>
                      {getFieldDecorator("gender", {
                        initialValue: "Male",
                        rules: [
                          { required: false, message: "Please select gender!" },
                        ],
                      })(
                        <Radio.Group>
                          <Radio value='Male'>Male</Radio>
                          <Radio value='Female'>Female</Radio>
                          <Radio value='Other'>Other</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row>
                <Col span={24}>
                  <Form.Item
                    className='customFormItem'
                    label='Street'
                    {...formItemLayout}>
                    {getFieldDecorator("street", {
                      rules: [
                        { required: false, message: "Please input street!" },
                      ],
                    })(<TextArea rows={1} />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Form.Item
                    className='customFormItem'
                    label='Street No'
                    {...formItemLayout}>
                    {getFieldDecorator("streetNumber", {
                      rules: [
                        { required: false, message: "Please input street no!" },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className='customFormItem'
                    label='Street 2'
                    {...formItemLayout}>
                    {getFieldDecorator("street2", {
                      rules: [
                        { required: false, message: "Please input street 2!" },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Form.Item
                    className='customFormItem'
                    label='City'
                    {...formItemLayout}>
                    {getFieldDecorator("city", {
                      rules: [
                        { required: false, message: "Please input city!" },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className='customFormItem'
                    label='Zip Code'
                    {...formItemLayout}>
                    {getFieldDecorator("pin", {
                      rules: [
                        { required: false, message: "Please input zip code!" },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                {this.state.customerType ? (
                  <Col span={12}>
                    <Form.Item
                      className='customFormItem'
                      label='Customer Type'
                      {...formItemLayout}>
                      {getFieldDecorator("customerType", {
                        rules: [
                          { required: false, message: "Please input street!" },
                        ],
                      })(
                        <Select
                          showSearch={true}
                          style={{ width: "100%" }}
                          // onChange={this.handleOnChangeCountry}
                        >
                          <Select.Option key={"1"} value={0}>
                            S - Sales Value Customer
                          </Select.Option>
                          <Select.Option key={"2"} value={1}>
                            A - Asking Value Customer
                          </Select.Option>
                          <Select.Option key={"3"} value={2}>
                            R - Retail Value Customer
                          </Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                <Col span={12}>
                  <Form.Item
                    className='customFormItem'
                    label='Country'
                    {...formItemLayout}>
                    {getFieldDecorator("country", {
                      rules: [
                        { required: false, message: "Please input street!" },
                      ],
                    })(
                      <Select
                        showSearch={true}
                        style={{ width: "100%" }}
                        onChange={this.handleOnChangeCountry}>
                        {countryList.map((data, index) => (
                          <Select.Option key={data} value={data}>
                            {data}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {this.state.customerType ? (
                  <React.Fragment>
                    <Col span={12}>
                      <Form.Item
                        className='customFormItem'
                        {...formItemLayout}
                        label='Contact No'>
                        {getFieldDecorator("contactNumber", {
                          rules: [
                            {
                              required: true,
                              message: "Please input contact number!",
                            },
                            {
                              pattern: /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/,
                              message: "Please input valid contact number!",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className='customFormItem'
                        {...formItemLayout}
                        label='Office No'>
                        {getFieldDecorator("contactPerson", {
                          rules: [
                            {
                              required: false,
                              message: "Please input office no !",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                {this.state.customerType ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <React.Fragment>
                    <Col span={12}>
                      <Form.Item
                        className='customFormItem'
                        {...formItemLayout}
                        label='Contact No'>
                        {getFieldDecorator("contactNumber", {
                          rules: [
                            {
                              required: true,
                              message: "Please input contact number!",
                            },
                            {
                              pattern: /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/,
                              message: "Please input valid contact number!",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </React.Fragment>
                )}
              </Row>
              {this.state.isCountryIndia ? (
                <Row>
                  <Col span={12}>
                    <Form.Item
                      className='customFormItem'
                      label='Customer GST No'
                      {...formItemLayout}>
                      {getFieldDecorator("gst", {
                        rules: [
                          { required: false, message: "Please input gst no !" },
                          //   { max: 15, message: "Please input valid gst no" },
                          //     { min: 15, message: "Please input valid gst no" }
                        ],
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className='customFormItem'
                      {...formItemLayout}
                      label='Customer PAN No'>
                      {getFieldDecorator("pan", {
                        rules: [
                          { required: false, message: "Please input pan no !" },
                        ],
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <React.Fragment></React.Fragment>
              )}

              <Row>
                <Col span={24}>
                  <Form.Item
                    className='customFormItem'
                    label='Rounded Pricing :'
                    style={{ paddingLeft: "14px" }}>
                    {getFieldDecorator("roundOffSale", {
                      initialValue: "0",
                      rules: [
                        {
                          required: false,
                          message: "Please input rounded price!",
                        },
                      ],
                    })(
                      <Radio.Group buttonStyle='solid'>
                        <Radio value='0'>Simple</Radio>
                        <Radio value='1'>Round Up</Radio>
                        <Radio value='2'>Round Down</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    className='customFormItem'
                    label='Status :'
                    style={{ paddingLeft: "14px" }}>
                    {getFieldDecorator("status", {
                      initialValue: 0,
                      rules: [
                        { required: false, message: "Please input zip code!" },
                      ],
                    })(
                      <Radio.Group buttonStyle='solid'>
                        <Radio value={0}>Active</Radio>
                        <Radio value={1}>Inactive</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                {/**
                <Divider style={{ marginBottom: "16px" }} />*/}
                <Button
                  key='back'
                  onClick={this.handleCancel}
                  style={{ minWidth: "77px" }}
                  ghost>
                  Cancel
                </Button>
                <Button
                  key='submit'
                  type='primary'
                  onClick={this.handleOk}
                  style={{ minWidth: "77px" }}>
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </React.Fragment>
    );
  }
}

AddCustomer = Form.create()(AddCustomer);
function mapStateToProps(state) {
  const { Common } = state;
  const { create_customer: create_customer } = Common;
  return { create_customer };
}
const AppContainer = connect(mapStateToProps)(AddCustomer);
export default AppContainer;
