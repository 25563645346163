import {updateSubSectionDetails,getAllBrachList} from '../../../actions/index';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Modal,Form,Table ,List,Col, Icon, Button,message, Row,Switch,Select,AutoComplete,Input} from 'antd';

import socketIOClient from "socket.io-client";
var cookies = require('browser-cookies');
message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});
const { TextArea } = Input;

class Update extends Component {
      constructor(props) 
      {
        super(props);
        this.state = {
            data:[],
            loading: false,
        visible: false
          };
      
      }

      componentWillMount(){  
        let usertype=localStorage.getItem('type_user');
        if(usertype!="companyAdmin"){
          window.location.href="/dashboard"
        }
       }
      componentDidUpdate(prevProps, prevState) {

    
         try {
            if (prevProps.update_subsection_data != this.props.update_subsection_data) {
                 if(this.props.update_subsection_data.status)
                 {
                    this.setState({
                        visible:false,
                    })
                 }
               } 

            } catch (error) {
                console.log(error);
            }
           
        
    }
    showModal = () => {
      this.setState({
        visible: true,
      });
    }

   
    handleCancel = () => {
      this.setState({ visible: false });
      this.props.form.resetFields();
    }
  
    handleOk = (e) => {
      
         this.props.form.validateFields((err, values) => {          
           if (err) {
             return;
           }
           let record=this.props.record;
            if(record.address===values.address && record.phone===values.phone && record.name===values.name){
                message.error("No data change");
                return;
            }
              record.address=values.address;
              record.phone=values.phone;
              record.name=values.name;
              const { dispatch } = this.props;
            
            //  console.log(values);
           dispatch(updateSubSectionDetails(record)); 
         });
       }

       
    render () {
        
        const { visible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const {name,phone,address}= this.props.record;
        return (
            <div>
               <Icon type="edit" onClick={this.showModal} style={{fontSize: 20, paddingLeft:"10px",cursor:"pointer",float:"left"}} />
            <Modal
              title="Update Sub Section"
              visible={visible}
              onCancel={this.handleCancel}
              footer={[
                <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                <Button key="submit" type="primary"  onClick={this.handleOk}>
                   Update
                </Button>,
              ]}
             // width={1250}
              style={{paddingBottom:"166px"}}
            >
            
            <Form layout="vertical" onSubmit={this.FormSubmit}>
                  <Form.Item label="Name" className="customFormItem">
                  {getFieldDecorator('name', {
                    initialValue: name,
                    rules: [{ required: true, message: 'Please input name !' }],
                  })(
                    <Input />
                  )}
                </Form.Item>
                <Form.Item label="Contact No" className="customFormItem">
                {getFieldDecorator('phone', {
                    initialValue: phone,
                  rules: [{ required: false, message: 'Please input contact number !' }],
                })(
                  <Input />
                )}
              </Form.Item>

           

          

            <Form.Item label="Address" className="customFormItem">
            {getFieldDecorator('address', {
                initialValue: address,
              rules: [{ required: false, message: 'Please input address!' }],
            })(
                <TextArea rows={3} />
            )}
          </Form.Item>

  
             

         
             
            </Form>
            </Modal>
          </div>
        )
    }
}


Update= Form.create()(Update);
function mapStateToProps(state) {
    const {Common } = state
    const {create_branch:create_branch,update_subsection_data:update_subsection_data } =  Common
    return {create_branch,update_subsection_data}
}
const AppContainer = connect( 
mapStateToProps 
)(Update);
export default AppContainer;
