import React, { Component } from 'react'
import {connect} from "react-redux";
import {Table,Button,Modal,Form,Input,Icon,Popconfirm} from "antd";
import EditIcon from "../../../assets/images/icons/edit-icon.svg";
import Edit from "./edit";
import "./customerTypeConfig.css";
import {listCustomerType} from "./Actions/ListCustomerType";
import { bindActionCreators } from "redux";
import {AddCustomerType} from "./Actions/AddCustomerType";
import {DeleteCustomerType} from "./Actions/DeleteCustomerType";
const { TextArea } = Input;
class customerTypeConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      updateModal:false,
      customerType:[],
      customerTypeObj : {
        customer_type:"",
        desc:""
      }
    };
  }
  componentDidMount(){
    this.props.listCustomerTypeAction(this.listCustomerTypeCallback);

  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  }
  showUpdateModal = () => {
    this.setState({
      updateModal: true,
    });
  }
  handleCancel = () => {
    this.setState({ visible: false });
  }
  onCustomerTypeChange = ({ target: { value } }) => {
    let customer_type = value;
    let customerTypeObj = this.state.customerTypeObj
    this.setState({
      customerTypeObj: {
        ...customerTypeObj,
        customer_type: customer_type
      }
    });
  };
  onDescriptionChange = ({ target: { value } }) => {
    let description = value;
    let customerTypeObj = this.state.customerTypeObj
   
    this.setState({
      customerTypeObj: {
        ...customerTypeObj,
        desc: description
      }
    });
  };
  handleCreateClick = () => {
    this.setState({ visible: false });
    let customerObj = this.state.customerTypeObj;

    let customer_type = customerObj.customer_type;
    let description = customerObj.description;

      let request = this.state.customerTypeObj
      this.props.AddCustomerTypeAction(
        request
      );
      this.props.listCustomerTypeAction(this.listCustomerTypeCallback);
      
  };

  deleteCustomerType = (e) => {
    this.props.deleteCustomerTypeAction(e.id,this.deleteCustomerTypeCallback)
  }

  deleteCustomerTypeCallback = (errorMessage) => {
    if (errorMessage) {
      console.log(errorMessage)
    } else {
      this.props.listCustomerTypeAction(
        this.listCustomerTypeCallback
      );
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visible } = this.state;
    
    let customerTypeArray = this.props.customerType.customerTypeList;
    let dataSource = []
    
    customerTypeArray.map((person, index) => (
     
        dataSource.push({
          id: person._id,
          type: person.customer_type,
           description: person.desc
        })
  ))
    const columns = [
      {
        title: 'Customer Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '70%',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
          let {isActive }=record;
          return (
            <span>
                  <Edit record={record}/>
                  <Popconfirm
                    title="Are you sure delete branch ?"
                    onConfirm={()=>this.deleteCustomerType(record)}
                    //onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >      
                    <Icon type="delete" theme="filled"  style={{fontSize: 20,marginLeft: 11,cursor:"pointer"}} />           
                  </Popconfirm>
                 
            </span>
          );
        },
      },
    ];
    return (
      <div>
            <Modal
              title="Add Customer Type"
              visible={visible}
              onCancel={this.handleCancel}
              footer={[
                <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                <Button key="submit" type="primary"  onClick={this.handleCreateClick}>
                   Create
                </Button>,
              ]}
             // width={1250}
              style={{paddingBottom:"166px"}}
            >
       
                <Form layout="vertical" onSubmit={this.FormSubmit}>
                  <Form.Item label="Customer Type" className="customFormItem">
                  {getFieldDecorator('Customer Type', {
                    rules: [{ required: true, message: 'Please input Customer Type !' }],
                  })(
                    <Input onChange={this.onCustomerTypeChange}/>
                  )}
                </Form.Item>
            <Form.Item label="Description" className="customFormItem">
            {getFieldDecorator('Description', {
              rules: [{ required: false, message: 'Please input Description!' }],
            })(
                <TextArea rows={3} onChange={this.onDescriptionChange}/>
            )}
          </Form.Item>
            </Form>
            </Modal>
        <div className="j-content-flex-end d-flex">
          <Button type="primary" onClick={this.showModal}>
            Add
          </Button>
        </div>
        <Table dataSource={dataSource} columns={columns}></Table>
      </div>
    )
  }
}
customerTypeConfig= Form.create()(customerTypeConfig);

const mapStateToProps = state => {
  return {
    customerType: state.customerTypeListReducer
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      listCustomerTypeAction: listCustomerType,
      AddCustomerTypeAction:AddCustomerType,
      deleteCustomerTypeAction:DeleteCustomerType
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(customerTypeConfig);


