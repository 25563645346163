// import {getStockfromRfid} from '../../actions/actions';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Modal,Form,Table ,List,Col, Icon, Button,message, Row,Switch,Select,AutoComplete,Input} from 'antd';
import moment from 'moment';
message.config({
  top: 100,
  duration: 10,
  maxCount: 3,
});

const Option = Select.Option;
class EditAllocation extends Component {
      state = {
        get_stock_from_rfid: null

      }
      constructor(props) 
      {
        super(props);
        this.state = {
            data:[{sr_no:"1",RFID_Device_Id:"SPACECODE – UB11"},
          ],
            table_loading:false
          };
      }
      componentDidMount() {
        const { dispatch } = this.props;
      }
   

    state = {
      loading: false,
      visible: false,
       copied: false
    }

    showModal = () => {
      this.setState({
        visible: true,
      });
    }
    handleOk = () => {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false, visible: false });
      }, 3000);
    }
    handleCancel = () => {
      this.setState({ visible: false });
    }
  


    render () {
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
        }
        const { visible } = this.state;
        const columns = [  
            {
              title: 'Sr No',
              dataIndex: 'sr_no',
              width: '35%',
              sorter: (a, b) => a.name - b.name,
              render: (text, record) => {
               
                return (
                  <span>
                      {text}
                  </span>
                );
              }
            },
            {
              title: 'RFID Device Id',
              dataIndex: 'RFID_Device_Id',
              width: '60%',
              sorter: (a, b) => a.name - b.name,
              render: (text, record) => {
                const { customer } = record;
                return (
                  <span>
                      {text}
                  </span>
                );
              },
              
            },
           
            
          ];
      
        return (
            <div>
      
              {this.props.text}
           
          
           <Button type="primary"  onClick={this.showModal}>Edit Allocation</Button>
            <Modal
              title="Edit Allocation"
              visible={visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={null}
              width={1250}
              style={{paddingBottom:"166px"}}
            >
              <span>
              <Row>
                <Col span={10}>
                      <div style={{marginBottom:"10px"}}>
                      <p style={{float:"left",marginRight:"12px"}}>Branch</p>
                        <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Select branch"
                        optionFilterProp="children"
                    // onChange={handleChange}
                    // onFocus={handleFocus}
                    // onBlur={handleBlur}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option value="TJ01">FL01</Option>
                        <Option value="TJ02">FL02</Option>
                        <Option value="TJ03">FL03</Option>
                    </Select>  
                    </div>  

                    <AutoComplete
                    className="certain-category-search"
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{width: 300}}
                    size="large"
                    style={{width: '100%',marginBottom:'5px'}}
                //  dataSource={opt   ions}
                    placeholder="Search .."
                    optionLabelProp="value"
                    >
                    <Input suffix={<Icon type="search" className="certain-category-icon"/>}/>
                    </AutoComplete>   

                        <Table 
                        loading={this.state.table_loading} 
                        columns={columns} 
                        dataSource={this.state.data}
                        size={"small"}
                        rowKey={record => record.name}
                        rowSelection={rowSelection}
                         />
                </Col>  
                <Col span={4} style={{marginTop:"45px"}}>
                        <center>
                        <Col span={24}><Button><Icon type="right"/></Button></Col>                                           
                        <Col span={24}><Button><Icon type="left"/></Button></Col>
                        </center>
                </Col>
              <Col span={10}>
                    <div style={{marginBottom:"10px",marginTop:"40px"}}>
                   
                    <AutoComplete
                    className="certain-category-search"
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{width: 300}}
                    size="large"
                    style={{width: '100%',marginBottom:'5px'}}
                //  dataSource={opt   ions}
                    placeholder="Search .."
                    optionLabelProp="value"
                >
                    <Input suffix={<Icon type="search" className="certain-category-icon"/>}/>
                </AutoComplete>      
                  </div>  
                      <Table 
                      loading={this.state.table_loading} 
                      columns={columns} 
                      dataSource={this.state.data}
                      size={"small"}
                      rowKey={record => record.name}
                      rowSelection={rowSelection}
                       />
              </Col>  
            </Row>
          
                  <Row>
                    <Col span={8}>
                   

                    </Col>
                    <Col span={16}>
                        <Button 
                        style={{float:'right'}}
                        type="primary"   onClick={this.handleCancel}>Close</Button>
                    </Col>
                  </Row>
        
              </span>
            </Modal>
          </div>
        )
    }
}

export default EditAllocation
