import React, { Component } from "react";
import { connect } from "react-redux";
import { getSkuLabel, postSkuLabel } from "../../../actions/index";
import {
  Card,
  Table,
  Form,
  Row,
  Col,
  Modal,
  Select,
  Input,
  Button,
  Icon,
  Divider,
  Popconfirm,
  Radio,
  Alert,
  message,
  List,
  Spin,
  Progress,
} from "antd";
var cookies = require("browser-cookies");
class DiskUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      keys: [],
    };
  }
  componentWillMount() {
    let usertype = localStorage.getItem("type_user");
    if (usertype != "companyAdmin") {
      window.location.href = "/dashboard";
    }
  }
  componentDidMount() {
    const { dispatch } = this.props;
    //getSkuLabel
    // dispatch(getSkuLabel());
  }
  componentDidUpdate(prevProps, prevState) {
    //skulabel_details
    if (prevProps.skulabel_details != this.props.skulabel_details) {
      if (this.props.skulabel_details.status) {
        this.setState({
          loading: false,
          keys: this.props.skulabel_details.data.labels,
        });
      }
    }

    if (prevProps.post_skulable != this.props.post_skulable) {
      if (this.props.post_skulable.status) {
        this.setState({
          loading: true,
        });
        const { dispatch } = this.props;
        //getSkuLabel
        dispatch(getSkuLabel());
      }
    }
  }

  render() {
    const deviceInfo = [
      { name: "Application", value: "Desktop App" },
      { name: "Device Platform", value: "Win 10" },
      { name: "User", value: "J Smith" },
      { name: "Software Version", value: "3.36" },
      { name: "Memory", value: "256 GB" },
      { name: "Last Access", value: "June 05, 2021 11:45 AM" },
    ];
    return (
      <div>
        <div>
          <h6 style={{ fontWeight: 600, color: "#A2A2A2" }}>
            Last Updated : July 15, 2021 11:42 AM
          </h6>
        </div>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card bordered={false} title='Device Status'>
              <Row layout='flex'>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Icon
                      type='exclamation-circle'
                      style={{ color: "#FF0000" }}
                    />
                    <h5
                      style={{
                        fontWeight: "bold",
                        marginLeft: "10px",
                        marginTop: "8px",
                      }}>
                      Out of disk space.
                    </h5>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                    }}>
                    <Icon
                      type='hdd'
                      style={{ fontSize: 90, color: "#76B9F0" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                      }}>
                      <span
                        style={{
                          color: "#34D602",
                        }}>
                        ●
                      </span>

                      <h6 style={{ padding: 5 }}>Online</h6>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={8} md={8} sm={8} xs={8}></Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <Button type='primary'>View Issues(1)</Button>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}></Col>
              </Row>
            </Card>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card bordered={false} title='Disk Usage'>
              <Row>
                <Col span={12}>
                  <React.Fragment>
                    <Progress
                      strokeColor='#76B9F0'
                      width={180}
                      style={{ marginBottom: "-13px" }}
                      type='circle'
                      percent='95'
                      // format={(e) => `${e}%`}
                    />
                  </React.Fragment>
                </Col>
                <Col span={12}>
                  <div style={{ marginTop: 50 }}>
                    <h1 style={{ fontWeight: "bold" }}>25 GB Available</h1>
                    <h6 style={{ color: "#A2A2A2", fontWeight: "bold" }}>
                      256 GB Total
                    </h6>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card bordered={false} title='Device Information'>
              <div style={{ display: "flex" }}>
                <table style={{ width: "100%" }}>
                  {deviceInfo.map((d) => (
                    <React.Fragment>
                      <tr>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "600" }}>{d.name}:</td>
                        <td>{d.value}</td>
                      </tr>
                    </React.Fragment>
                  ))}
                </table>
              </div>
            </Card>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card bordered={false} title='Device Management'>
              <div>
                <h5>Delete the locally stored Egnyte data on the device.</h5>
                <Button ghost>Remote Wipe</Button>
              </div>
              <div>
                <h5>Force logout and remove from device list.</h5>
                <Button ghost>Remove Device</Button>
              </div>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Card bordered={false} title='Disk Backup Log'>
              <h5>No logs found.</h5>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Common } = state;
  const {
    skulabel_details: skulabel_details,
    post_skulable: post_skulable,
  } = Common;
  return { skulabel_details, post_skulable };
}

const AppContainer = connect(mapStateToProps)(DiskUsage);

export default AppContainer;
