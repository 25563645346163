import React, { Component } from 'react';
import { Card, Form, Button, message, Input, Row, Col,Radio, Upload, Icon ,Table,Select, Divider} from 'antd';
import { connect } from 'react-redux';
import {createTax,getTax, putTax} from '../../actions/index';
var cookies = require('browser-cookies');
const { Option } = Select;


class tax extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataArray:[],
            loading:false
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getTax());
    }
    componentDidUpdate(prevProps, prevState) {
        try {
            if (prevProps.get_tax != this.props.get_tax) {                
                if (this.props.get_tax.status) {
                    this.setState({
                        dataArray: this.props.get_tax.data_array
                    })
                  
                }else{
                    this.setState({
                        dataArray: this.props.get_tax.data_array
                    })
                }
                this.setState({
                    loading:false
                })
            }
            if (prevProps.create_tax != this.props.create_tax) {                
                if (this.props.create_tax.status) {
                    message.success("Tax is inserted successfully");
                    this.props.form.resetFields();
                    const { dispatch } = this.props;
                    dispatch(getTax());
                }else{
                  message.error(this.props.create_tax.errors.msg);
                }
            }
            

            if (prevProps.put_tax != this.props.put_tax) {                
                if (this.props.put_tax.status) {
                    const { dispatch } = this.props;
                    dispatch(getTax());
                }else{
                  message.error(this.props.put_tax.errors.msg);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleTableRowChanges=(e)=>{
        this.setState({
            loading:true
        })
        // console.log(e);
        try {
            e.is_Selected=true;
            const { dispatch } = this.props;
            dispatch(putTax(e));
        } catch (error) {
            console.log(error);
        }
    }
    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (err) {
                return;
            }
             let root =  JSON.parse(cookies.get('root'));
             const {dispatch} = this.props;
             values.companyId=root.companyId;
             if(this.state.dataArray.length === 0){
                values.is_Selected=true;
             }
             values.tax_info=[{
                "tax_type":"US",
                "tax_per":values.tax_info
             }]
             dispatch(createTax(values));
        });
    };

    handleCancel = () => {
        this.setState({ visible: false });
        this.props.form.resetFields();
      }
      handleTableChange = (pagination, filters, sorter) => {
        let colome=sorter.columnKey;
        let order=sorter.order;
        let orderopp=0;
        let data=this.state.dataArray;
        if(order!=undefined){
          if(order==="descend"){
            order=-1;
            orderopp=1;
          }else{
            order=1;
            orderopp=-1;
          }
          data.sort((a, b) => (a[`${colome}`] > b[`${colome}`]) ? order : orderopp);
        }else{
          const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);
          shuffleArray(data);
        }
     };
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 14 },
            },
          };
          
          const columns = [         
            {
                title: 'CITY',
                dataIndex: 'city',
                sorter: (a, b) => {},
                key: 'city',
              },
            //   {
            //     title: 'ADDRESS 2',
            //     dataIndex: 'address2',
            //     sorter: (a, b) => {},
            //     render: (text, record) => {
            //         let length = 15;
            //         let trimmedString = text.length > length ? 
            //         text.substring(0, length - 3) + "..." : 
            //        text;
            //         return (
            //             <span>
            //             {trimmedString}
            //         </span>
            //         );
            //     },
            // },
              
              {
                title: 'STATE',
                dataIndex: 'state',
                sorter: (a, b) => {},
                key: 'state',
              },
              {
                title: 'COUNTY',
                dataIndex: 'county',
                sorter: (a, b) => {},
                key: 'county',
              },
              {
                title: 'COUNTRY',
                dataIndex: 'country',
                sorter: (a, b) => {},
                key: 'country',
              },
              {
                title: 'TAX',
                dataIndex: `tax_info[0]['tax_per']`,
                sorter: (a, b) => {},
              },
              {
                title: '',
                dataIndex: 'is_Selected',
                render: (text, record) => {
                    return (
                       <React.Fragment>
                          {text === true ?
                          <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{fontSize:"24px"}}/>    
                          :
                          <Icon type="check-circle" style={{fontSize:"24px"}}/>
                        }
                       </React.Fragment>                   
                      );
              },
            }
          ];
        return (
            <Row>
            <Col md={24} sm={24} xs={24} type="flex" >
            <Card  bodyStyle={{paddingBottom:"0px"}}>
               
                <Card style={{ borderRadius: "7px" }} title="Add Tax " bodyStyle={{paddingBottom:"0px"}}>
                            <Form {...formItemLayout}  onSubmit={this.handleSubmit}>
                            
                            <Row>
                                <Col md={12} sm={24} xs={24}>
                                    <Form.Item label="City" className="customFormItem">
                                    {getFieldDecorator('city', {
                                    // initialValue: this.state.currency_symbol,
                                        rules: [{ required: true, message: 'Please input city !' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>    
                                </Col>     

                                <Col md={12} sm={24} xs={24}>
                                        <Form.Item label="State" className="customFormItem">
                                        {getFieldDecorator('state', {
                                        // initialValue: this.state.currency_symbol,
                                            rules: [{ required: true, message: 'Please input state !' }],
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                 </Col>                          
                            </Row>
                          

                            <Row>
                                <Col md={12} sm={24} xs={24}>
                                <Form.Item label="County" className="customFormItem">
                                {getFieldDecorator('county', {
                                // initialValue: this.state.currency_symbol,
                                    rules: [{ required: true, message: 'Please input county !' }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                                </Col>      
                                <Col md={12} sm={24} xs={24}>
                                    <Form.Item label="Tax Per(US)" className="customFormItem">
                                    {getFieldDecorator('tax_info', {
                                    // initialValue: this.state.currency_symbol,
                                        rules: [{ required: true, message: 'Please input per. !' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                </Col>

                                                   
                            </Row>

                            <Row>
                        
                            <Col md={12} sm={24} xs={24}>
                            <Form.Item label="Country" className="customFormItem">
                            {getFieldDecorator('country', {
                            // initialValue: this.state.currency_symbol,
                                rules: [{ required: true, message: 'Please input country !' }],
                            })(
                                <Input />
                            )}
                             </Form.Item>
                             </Col>
                            </Row>


                        <Row>
                            <Divider/>
                            <Col md={24} sm={24} xs={24} style={{textAlign:"center"}}>                                
                                <Button key="back" onClick={this.handleCancel} style={{ minWidth: "77px" }} ghost>Cancel</Button>
                                <Button key="submit" type="primary" htmlType="submit" style={{ minWidth: "77px" }}>
                                Save
                            </Button>
                            </Col>      
                       </Row>
                             
                            </Form>
                            </Card>
                            
                <Table dataSource={this.state.dataArray} columns={columns} 
                        className="gx-table-responsive" 
                        size="small"
                        onRowClick ={this.handleTableRowChanges}
                        onChange={this.handleTableChange}
                        loading={this.state.loading}               
                        bodyStyle={{margin:0}}         
                />
                </Card>
                </Col>    
            </Row>
        );
    }
}


tax = Form.create()(tax);
function mapStateToProps(state) {
    const { Common } = state
    const { get_tax: get_tax,create_tax:create_tax,put_tax:put_tax} = Common
    return { get_tax,create_tax,put_tax}
}

const AppContainer = connect(
    mapStateToProps,
    //mapDispatchToProps
)(tax);
export default AppContainer;
