import {updateCustomer,getCustomer,getSkuLabel,updateSkuDetails} from '../../../actions/index';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Modal, Form, Table,Card, List,Spin, Col, InputNumber,Icon,DatePicker, Button,Radio, message, Row, Switch, Select, AutoComplete, Divider, Input } from 'antd';
//import "./custome.css";
//import {countryList} from "./country";
import moment from 'moment';
const { TextArea } = Input;


class EditRm extends Component {
      constructor(props) 
      {
        super(props);
       // let root =  JSON.parse(cookies.get('root'));
    //   console.log(this.props.visibleForEditSku);
        this.state = {
            data:[],
            loading: false,
            keys:[]
           
        };
      }

      componentDidMount(){
       // const { dispatch } = this.props;
       // dispatch(getSkuLabel());
   }
      componentDidUpdate(prevProps, prevState) {

        if (prevProps.sku_update != this.props.sku_update) {
         try {
        
            if(this.props.sku_update.status)
            {
                message.success("Update sku details successfully")
                this.props.closeEditSku(true);
            }else{
                message.error(this.props.sku_update.errors.msg);
            } 
              
            } catch (error) {
                console.log(error);
            }
           
        } 

    }
    showModal = () => {
      this.setState({
        visible: true,
      });
    }
   
    handleCancel = () => {
      this.props.form.resetFields();
      this.props.closeEditSku();
    }
    closeEditSku =(record)=>{
      this.setState({
        visibleForEditSku: false,
      });
    }


  onChangeCustomerType=(e)=>{
    if(e.target.value===false){
      this.setState({
        customerType:true,
        isPrivateCustomer:false
      })
    }else{
      this.setState({
        customerType:false,
        isPrivateCustomer:true
      })
    }
  }

  
    handleOk = (e) => {
      
         this.props.form.validateFields((err, values) => {          
           if (err) {
             return;
           }
           const {dispatch} = this.props;
           let finalResult=this.props.editRecord;
          //  console.log("finalResult--------------------++++++++++++++++++==");
          //  console.log(values);
            finalResult.rm=values.rm;
          //  console.log("finalResult");
          //  console.log(finalResult);
           dispatch(updateSkuDetails(finalResult)); 
         });
       }


    render () {
        const { visible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const {editRecord} =this.props;
        const {rm} =editRecord;
        // console.log("=============rm_value=============");
        // console.log(rm);
     //   let {firstName,familyName,email,companyName,birthDate,gender,street,streetNumber,street2,city,pin,customerType,country,contactNumber,contactPerson,pan,gst,roundOffSale,status,isPrivateCustomer}= this.props.record;
        //birthDate=moment(birthDate, 'DD/MM/YYYY');
        const formItems = rm.map((k, index) => (
            // <Col span={12} style={{display:"block"}}>
           <React.Fragment>
           
                 <Col span={12}>
                 <Form.Item
                   label={"Reference"}
                 >
                   {getFieldDecorator(`rm['${index}'].reference`, {
                     initialValue: k.reference,
                     rules: [
                       {
                         required: true ,
                         message: `Please input reference`,
                       },
                     ],
                   })(<Input placeholder={`Enter reference`}  />)}
                 </Form.Item>
              
                  </Col>

                  <Col span={12}>
                 <Form.Item
                   label={"Rm wt"}
                 >
                   {getFieldDecorator(`rm['${index}'].rm_wt`, {
                     initialValue: k.rm_wt,
                     rules: [
                       {
                         required: true ,
                         message: `Please input rm wt`,
                       },
                     ],
                   })(<InputNumber placeholder={`Enter rm wt`} style={{width:"100%"}} />)}
                 </Form.Item>
              
                  </Col>
             
             </React.Fragment>
          ));
        const formItemLayout = {
          labelCol: { span: 24 },
          //   labalAlign:{marginLeft: "15px"},
          wrapperCol: { span: 24 },
        };
        return (
            <Modal
            title="Update Raw Material details"
            visible={this.props.visibleForEditSku}
            onOk={this.uploadhandleSyncOk}
       //     confirmLoading={confirmLoading}
            style={{paddingBottom:"0px"}}
            //width={650}
            onCancel={this.handleCancel}
            footer={[
              <span style={{marginBottom:"20px"}} >
               <Button key="back" onClick={this.handleCancel} style={{ minWidth: "77px" }} ghost>Cancel</Button>
              <Button key="submit" type="primary" onClick={this.handleOk} style={{ minWidth: "77px" }}>
                  Update
           </Button>
              </span>                    
            ]}
           
          >
            <React.Fragment>
            {/**<img src={EditIcon} onClick={this.showModal} style={{paddingLeft:"15px",cursor:"pointer"}}></img>*/}
         
            <Form  onSubmit={this.FormSubmit}  {...formItemLayout} >
             <Spin spinning={this.state.loading}>
             <Row gutter={24}>
               
                {formItems}
            
              </Row>
              </Spin>
            </Form>
         
          </React.Fragment>
          </Modal>
        )
    }
}


EditRm= Form.create()(EditRm);
function mapStateToProps(state) {
    const {Common } = state
    const {sku_update:sku_update,skulabel_details:skulabel_details } =  Common
    return {sku_update,skulabel_details}
}
const AppContainer = connect( 
mapStateToProps 
)(EditRm);
export default AppContainer;
